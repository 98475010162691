import { createGlobalStyle } from "styled-components";
import { typography } from "./typography";

const GlobalStyles = createGlobalStyle`
  ${typography};

  :root {
    --font-paragraph-default: "interRegular";
    --font-paragraph-bold: "interBold";
    --font-title-default: "montserratRegular";
    --font-title-bold: "montserratBold";

    --color-gradient-rose: linear-gradient(180deg, #FF3171 0%, #DA3063 100%);
    --color-gradient-blue: linear-gradient(180deg, #009EDB 0%, #0077CD 100%);
    --color-gradient-aux-success: linear-gradient(180deg, #2CF193 0%, #0DBC68 100%);
    --color-gradient-aux-warning: linear-gradient(180deg, #FFB84E 0%, #F29914 100%);
    --color-gradient-aux-danger: linear-gradient(180deg, #FF5454 0%, #DE3939 100%);

    
    --color-aux-warning-100: hsla(36, 100%, 93%, 1); // #FFF1DC 
    --color-aux-warning-700: hsla(36, 90%, 51%, 1); // #F29914
    --color-aux-warning-800: hsla(36, 100%, 41%, 1); // #D37F00
    --color-aux-success-100: hsla(151, 100%, 96%, 1); // #EAFFF5
    --color-aux-success-700: hsla(151, 87%, 39%, 1); // #0DBC68
    --color-aux-success-800: hsla(151, 92%, 34%, 1); // #07A65A
    --color-aux-orange-100: hsla(54, 100%, 91%, 1); // #FFFAD1
    --color-aux-orange-800: hsla(54, 98%, 44%, 1); // #DEC802
    --color-aux-danger-100: hsla(0, 100%, 96%, 1); // #FFEBEB 
    --color-aux-danger-700: hsla(0, 71%, 55%, 1); // #DE3939 
    --color-aux-danger-800: hsla(0, 75%, 46%, 1); // #CD1D1D
    --color-aux-purple-100: hsla(289, 100%, 92%, 1); // #F8D8FF
    --color-aux-purple-800: hsla(289, 96%, 34%, 1); // #8C04A9

    --color-light-0: hsla(0, 0%, 100%, 1); // #FFF
    --color-light-50: hsla(0, 0%, 98%, 1); // #393C4D
    --color-light-100: hsl(0deg 0% 96.08%); // #F5F5F5
    --color-light-200: hsl(240deg 2.22% 91.18%); // #E8E8E9

    --color-blue-100: hsla(206, 100%, 96%, 1); // #EDF7FF 
    --color-blue-600: hsl(202.68deg 70.17% 64.51%); // #65b4e4
    --color-blue-800: hsla(197, 100%, 38%, 1); // #008BC0
    --color-dark-blue-300: hsla(255, 6%, 73%, 1); // #b9b7bf
    --color-dark-blue-500: hsla(240, 8%, 39%, 1); // #5C5C6B
    --color-dark-blue-700: hsla(220, 41%, 15%, 1); // #162034

    --color-rose-100: hsla(342, 100%, 96%, 1); // ##FECF2
    --color-rose-500: hsla(354, 64%, 76%, 1); // #E99AA2
    --color-rose-800: hsla(341, 66%, 45%, 1); // #BD2756
    --color-main-rose: hsl(342deg 69.67% 52.16%); // #DA3063

    --color-text-input: hsla(240, 2%, 91%, 1); // #E8E8E9

    --color-text-labels: hsl(249.23deg 5.58% 54.31%); // #868491
    --color-dark-blue-400: hsl(249.23deg 5.58% 54.31%); // #868491

    --color-text-paragraph: hsl(231deg 14.93% 26.27%); // #393C4D
    --color-dark-blue-600: hsl(231deg 14.93% 26.27%); // #393C4D

    --color-text-title: hsl(220deg 40.54% 14.51%); // #162034
    --color-main-dark-blue: hsl(220deg 40.54% 14.51%); // #162034

    --color-text-link: hsla(197, 100%, 43%, 1); // #009EDB
    --color-main-blue: hsla(197, 100%, 43%, 1); // #009EDB
  }

  html {
    // overflow-y: hidden;
  }

  html, body {
    margin: 0;
    font-family: var(--font-paragraph-default);
    overflow-x: hidden;
  }

  h1, h2, h3, h4, h5, p{
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  a {
    text-decoration: none;
  }

  * {
    outline-color: var(--color-blue-600);
    outline-width: 0.1px;
  }

  li {
    list-style-type: none;
  }

  input{
    padding: 0;
  }

  b {
    font-family: var(--font-paragraph-bold);
  }

  button {
    border: 0;  
    background: transparent;
  }

  button:hover{
    background: var(--color-light-100);
  }
`;

export default GlobalStyles;
