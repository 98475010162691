import { keyframes } from "styled-components";

export const fullRotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

export const shimmer = keyframes`

from {
  // left: 0%;
  width: 0%;
  // transform: translateX(0%);
}

to {
  // left: 100%;
  width: 100%;
  // transform: translateX(90px);
}

`;
