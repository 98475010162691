import styled from "styled-components";
import spinnerImg from "../../assets/images/spinner.svg";
import SVG from "../ui/SVG";
import { fullRotate } from "../../utils/animations";

const Container = styled(SVG)/* <{ $loading: boolean }> */ `
  width: 20px;
  height: 20px;

  animation: ${fullRotate} 1s linear infinite;
`;

type TSpinnerProps = {
  // loading: boolean;
  id?: string;
  className?: string;
  style?: React.CSSProperties;
};

const Spinner: React.FC<TSpinnerProps> = ({
  /* loading */
  className,
  id,
  style,
}) => {
  return (
    <Container
      style={style}
      className={className}
      id={id}
      src={spinnerImg} /* $loading={loading} */
    />
  );
};

export default Spinner;
