import { useState } from "react";
import styled, { keyframes } from "styled-components";

const borderAnimation = keyframes`
  0% {    
    width: calc(100% + 2px);
    height: calc(100% - 1px);
    left: 0px;
    top: -1px;
    visibility: visible;
  }
  100% {
    width: calc(100% + -2px);
    height: calc(100% - 2px);
    left: 2px;
    top: 0px;
  }
`;

const Container = styled.button`
  width: 20px;
  height: 20px;

  cursor: pointer;
  background: var(--color-light-0);
  border: 0px solid var(--color-main-blue);

  border-radius: 100%;

  position: relative;

  & .external-border {
    position: absolute;
    width: calc(100% + 2px);
    height: calc(100% - 1px);
    left: 0px;
    top: -1px;
    border: 1px solid var(--color-main-blue);
    border-radius: 100%;
    visibility: hidden;
  }

  & .inner-circle {
    position: absolute;
    background: var(--color-light-0);
    width: 8px;
    height: 8px;
    top: 6px;
    left: 8px;
    border-radius: 100%;
    z-index: 1;
    visibility: hidden;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    margin-left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
  }

  &::before {
    background: var(--color-light-0);
    border: 1px solid var(--color-text-input);
  }

  &::after {
    background: var(--color-main-blue);
    border: 1px solid var(--color-main-blue);

    font-family: "unicons";
    color: var(--color-light-0);

    display: flex;
    align-items: center;
    justify-content: center;

    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }

  &.active,
  &.inactive {
    & .external-border {
      animation: ${borderAnimation} 0.15s ease-in-out;
    }
  }

  &.active {
    &::after {
      content: "";
      visibility: visible;
      opacity: 1;
    }
    & .inner-circle {
      visibility: visible;
    }
  }

  &:hover {
    background: var(--color-light-0);
  }
`;

type TRadioProps = {
  onClick?: (
    isClicked: boolean,
    setClickState: React.Dispatch<
      React.SetStateAction<"" | "active" | "inactive">
    >
  ) => void;
  setSelectedRef?: (
    setClickState: React.Dispatch<
      React.SetStateAction<"" | "active" | "inactive">
    >,
    clickState: "" | "active" | "inactive"
  ) => void;
  initialState?: boolean;
  isActive?: "active" | "inactive";
  id?: string;
};

const Radio: React.FC<TRadioProps> = ({
  onClick,
  setSelectedRef,
  id,
  initialState,
  isActive,
}) => {
  const [clickState, setClickState] = useState<"" | "active" | "inactive">(
    initialState !== undefined && initialState === true ? "active" : ""
  );

  if (setSelectedRef) setSelectedRef(setClickState, clickState);

  const clickHandler = () => {
    if (onClick)
      onClick(clickState === "active" ? !true : !false, setClickState);
    setClickState((prev) => {
      const value = prev === "active" ? "inactive" : "active";
      return value;
    });
  };

  return (
    <Container
      id={id}
      role="button"
      className={isActive ?? clickState}
      onClick={clickHandler}
    >
      <div className="external-border" key={clickState}></div>
      <div className="inner-circle"></div>
    </Container>
  );
};

export default Radio;
