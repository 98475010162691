import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";

import {
  TInvoice,
  TNexaasProduct,
} from "../../../../redux/reducers/globalState";

import { TButtonIconVariants, TButtonProps } from "../../Button";
import Spinner from "../../Spinner";
import { UilCheckCircle, UilTimesCircle } from "@iconscout/react-unicons";
// import apiService from "../../../../services/api";
import { getQuerystringParams } from "../../../../utils/miscellaneous";
import axios, { CancelTokenSource } from "axios";
import apiService from "../../../../services/api";
import { TMockResponse } from "../../../../utils/mocks";
import { format } from "date-fns/format";
import { parseISO } from "date-fns";

const Container = styled.form``;

const HoldingStepContainer = styled.div`
  display: flex;
  margin-bottom: 12px;

  p {
    font-family: var(--font-paragraph-default);
    font-size: 16px;
    color: var(--color-text-paragraph);
  }

  svg {
    margin-right: 8px;
  }
`;

const IconAndBlockStepContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 12px;

  svg {
    margin-right: 8px;
  }

  p.subtitle1,
  a,
  .triggerModal {
    font-family: var(--font-paragraph-default);
  }

  p.subtitle1 {
    font-size: 16px;
    color: var(--color-text-paragraph);
  }

  a,
  .triggerModal {
    font-size: inherit;
    color: var(--color-main-blue);
    cursor: pointer;
  }

  .triggerModal {
    padding: 0;

    &:hover {
      background: none;
    }
  }

  p.subtitle2 {
    color: var(--color-text-labels);
    font-size: 12px;
    font-family: var(--font-paragraph-default);
  }

  span {
    color: var(--color-main-rose);
    font-family: var(--font-paragraph-bold);
    font-size: 12px;
  }

  .success-icon {
    color: var(--color-aux-success-700);
  }

  .error-icon {
    color: var(--color-aux-danger-700);
  }
`;

export type TPayableObjectForm = {
  amount: string;
  category_id: number | null;
  classification_center_id: number | null;
  description: string;
  due_date: string;
  mode: null | "in_cash" | "recurrent" | "installments";
  supplier_id: number | null;

  paid_amount?: string;
  fine_amount?: string;
  interest_amount?: string;
  discount_amount?: string;
  payment_date?: string;
  period?:
    | "weekly"
    | "biweekly"
    | "monthly"
    | "bimonthly"
    | "quarterly"
    | "semiannual"
    | "annual";
  installments?: number;
  income_tax_relevant?: boolean;
};

type TGoodsProps = {
  invoice: TInvoice;
  footer: { buttons: TButtonProps<TButtonIconVariants>[] };
};

const GoodsAutomated: React.FC<TGoodsProps> = ({ invoice, footer }) => {
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const intervalId = useRef<NodeJS.Timeout | null>(null);

  const [currentStep, setCurrentStep] = useState<
    | "creating"
    | "created"
    | "checking"
    | "failed"
    | "succeeded"
    | "entering"
    | "entered"
  >("created");

  const [steps, setSteps] = useState<{ step: String; element: JSX.Element }[]>(
    []
  );

  const [enteredStockTimestamp, setEnteredStockTimestamp] = useState("");

  const updatedInvoiceRef = useRef(invoice);

  const failedRef = useRef<TNexaasProduct[]>([]);

  const succeededRef = useRef<TNexaasProduct[]>([]);

  // const creatingStep = {
  //   step: "creating",
  //   element: (
  //     <HoldingStepContainer>
  //       <Spinner />
  //       <p>Criando entrada</p>
  //     </HoldingStepContainer>
  //   ),
  // };

  const checkingStep = {
    step: "checking",
    element: (
      <HoldingStepContainer>
        <Spinner />
        <p>Realizando conferência de produtos</p>
      </HoldingStepContainer>
    ),
  };

  const createdStep = {
    step: "created",
    element: (
      <IconAndBlockStepContainer>
        <UilCheckCircle className="success-icon" />
        <div>
          <p className="subtitle1">
            Entrada criada com sucesso!&nbsp;
            <a
              target="_blank"
              href={updatedInvoiceRef.current.goods_receipt_url ?? ""}
            >
              Clique aqui para acessar no Omni
            </a>
          </p>
          <p className="subtitle2">
            <span>Novo</span>&nbsp; Fornecedor:&nbsp;
            {updatedInvoiceRef.current.issuer.name}
          </p>
        </div>
      </IconAndBlockStepContainer>
    ),
  };

  const failedCheckStep = {
    step: "failed",
    element: (
      <IconAndBlockStepContainer>
        <UilTimesCircle className="error-icon" />
        <div>
          <p className="subtitle1">Erros encontrados na conferência</p>
          <p className="subtitle2">
            <button
              className="triggerModal"
              role="button"
              onClick={() => {
                if (footer.buttons && footer.buttons[0]?.onClick)
                  footer.buttons[0].onClick({
                    data: {
                      success: succeededRef.current,
                      error: failedRef.current,
                    },
                    shouldUpdate: true,
                  });
              }}
            >
              Visualizar conferência
            </button>
          </p>
        </div>
      </IconAndBlockStepContainer>
    ),
  };

  const succeededCheckStep = {
    step: "succeeded",
    element: (
      <IconAndBlockStepContainer>
        <UilCheckCircle className="success-icon" />
        <div>
          <p className="subtitle1">Conferência realizada</p>
          <p className="subtitle2">
            <button
              className="triggerModal"
              role="button"
              onClick={() => {
                if (footer.buttons && footer.buttons[0]?.onClick)
                  footer.buttons[0].onClick({
                    data: {
                      success: succeededRef.current,
                      error: failedRef.current,
                    },
                    shouldUpdate: true,
                  });
              }}
            >
              Visualizar conferência e lançar estoques
            </button>
          </p>
        </div>
      </IconAndBlockStepContainer>
    ),
  };

  const enteringStockStep = {
    step: "entering",
    element: (
      <IconAndBlockStepContainer>
        <Spinner />
        <div>
          <p className="subtitle1">Lançando estoques</p>
          <p className="subtitle2">Isso pode levar alguns minutos</p>
        </div>
      </IconAndBlockStepContainer>
    ),
  };

  const enteredStockStep = {
    step: "entered",
    element: (
      <React.Fragment key={enteredStockTimestamp}>
        <IconAndBlockStepContainer>
          <UilCheckCircle className="success-icon" />
          <div>
            <p className="subtitle1">Estoques lançados</p>
            {updatedInvoiceRef.current.nexaas_finished_at && (
              <p className="subtitle2">{enteredStockTimestamp}</p>
            )}
          </div>
        </IconAndBlockStepContainer>
      </React.Fragment>
    ),
  };

  const fetchData = async () => {
    const querystrings = getQuerystringParams();

    if (querystrings.organizationId) {
      cancelTokenSource.current = axios.CancelToken.source();

      apiService
        .getInvoice(
          invoice.uuid,
          cancelTokenSource.current.token /* {
          type: "resolve",
          nexaasState: mockNexaasStateRef.current,
        } */
        )
        .then((response: any) => {
          if (response) {
            updatedInvoiceRef.current = response.data;

            handleNexaasState(updatedInvoiceRef.current);
          }
        });

      if (invoice.nexaas_state < 50) {
        handleNexaasState(invoice);
      }
    }
  };

  const handleNexaasState = (data: TInvoice) => {
    // console.log(
    //   "from automated, handle nexaas state data: ",
    //   data,
    //   ", current step: ",
    //   currentStep
    // );
    switch (data.nexaas_state) {
      case 20:
        window.location.reload();
        break;
      case 10:
        handleInitialAnimations();
        break;
      case 30:
      case 40:
        setSteps([createdStep, checkingStep]);
        handleSetStep("checking");
        break;
      case 50:
        if (data.nexaas_finished_at) {
          setTimeout(() => {
            setSteps([createdStep, succeededCheckStep, enteredStockStep]);

            handleSetStep("entered");
          }, 100);

          setEnteredStockTimestamp(
            format(parseISO(data.nexaas_finished_at as string), "dd/MM/yyyy") +
              " - " +
              format(parseISO(data.nexaas_finished_at as string), "HH:mm")
          );
        } else {
          setSteps([createdStep, succeededCheckStep, enteringStockStep]);
          handleSetStep("entering");
        }
        break;
      case 60:
        if (intervalId.current && currentStep === "entered") {
          // console.log("clearing interval 5");
          clearInterval(intervalId.current);
        }

        setTimeout(() => {
          setSteps([createdStep, succeededCheckStep, enteredStockStep]);

          handleSetStep("entered");
        }, 100);

        if (data.nexaas_finished_at) {
          setEnteredStockTimestamp(
            format(parseISO(data.nexaas_finished_at as string), "dd/MM/yyyy") +
              " - " +
              format(parseISO(data.nexaas_finished_at as string), "HH:mm")
          );
        }
        break;
    }
  };

  const handleInitialAnimations = () => {
    intervalId.current = setInterval(() => {
      setCurrentStep((before) => {
        if (before === "creating") return "created";
        if (before === "created") return "checking";
        return "checking";
        // switch (before) {
        //   case "creating":
        //     return "created";
        //   case "created":
        //     return "checking";
        //   case "checking":
        //     return "failed";
        //   case "failed":
        //     return "succeeded";
        //   case "succeeded":
        //     return "entering";
        //   case "entering":
        //     return "entered";
        //   case "entered":
        //     return "entered";
        // }
      });
    }, 1500);
  };

  // const handleCurrentStep = () => {
  //   switch (currentStep) {
  //     case "creating":
  //       return creatingStep;
  //     case "created":
  //       return createdStep;
  //     case "checking":
  //       return checkingStep;
  //     case "failed":
  //       return failedCheckStep;
  //     case "succeeded":
  //       return succeededCheckStep;
  //     case "entering":
  //       return enteringStockStep;
  //     case "entered":
  //       if (intervalId.current) {
  //         console.log("clearing interval 6");
  //         clearInterval(intervalId.current);
  //       }
  //       return enteredStockStep;
  //   }
  // };

  const handlePopulateGoodsStockCheckData = async () => {
    const queryStrings = getQuerystringParams();

    const handleResponse = (value: any) => {
      // console.log("clearing interval 1");
      clearInterval(intervalId.current as NodeJS.Timeout);

      const response = value.data as TMockResponse;

      // console.log("POST response: ", response);

      failedRef.current = response.errors;
      succeededRef.current = response.success;

      return response;
    };

    const handleResponse1 = (value: any) => {
      // console.log("clearing interval 1");
      clearInterval(intervalId.current as NodeJS.Timeout);

      const response = value.data as TMockResponse;

      // console.log("GET response: ", response);

      failedRef.current = response.errors;
      succeededRef.current = response.success;

      return response;
    };

    if (cancelTokenSource.current) {
      return apiService
        .getNonCachedGoodsExpressModeStatus(
          queryStrings.organizationId,
          invoice.uuid,
          cancelTokenSource.current.token
          // { type: "resolve", model: "mock2" }
        )
        .then(handleResponse1)
        .catch((e) => {
          // console.log("clearing interval 2");
          clearInterval(intervalId.current as NodeJS.Timeout);

          if (cancelTokenSource.current && e.status === 400) {
            return apiService
              .getNonCachedGoodsExpressModeStatus(
                queryStrings.organizationId,
                invoice.uuid,
                cancelTokenSource.current.token
                // { type: "resolve", model: "mock2" }
              )
              .then(handleResponse)
              .catch(() => {
                // console.log("clearing interval 2");
                clearInterval(intervalId.current as NodeJS.Timeout);
              });
          }
        });
    }
  };

  const fetchVerificationStatus = async () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);

      // console.log("clearing interval 3");
    }

    // console.log(
    //   "failed ref: ",
    //   failedRef.current,
    //   ", succeeded ref: ",
    //   succeededRef.current
    // );

    if (failedRef.current && succeededRef.current) {
      if (failedRef.current.length === 0 && succeededRef.current.length === 0) {
        handlePopulateGoodsStockCheckData().then(() => {
          if (!["entering", "entered"].includes(currentStep)) {
            if (
              failedRef.current.length > 0 &&
              failedRef.current.filter(
                (failed) => !failed.reason?.includes("conversion")
              ).length > 0
            ) {
              setSteps([createdStep, failedCheckStep]);
              handleSetStep("failed");
            } else if (
              succeededRef.current.length > 0 ||
              failedRef.current.filter((failed) =>
                failed.reason?.includes("conversion")
              ).length > 0
            ) {
              setSteps([createdStep, succeededCheckStep]);
              handleSetStep("succeeded");
            }
          } else {
            // console.log("fetching data?");
            fetchData();
          }
        });
      } else {
        if (!["entering", "entered"].includes(currentStep)) {
          if (
            failedRef.current.length > 0 &&
            failedRef.current.filter(
              (failed) => !failed.reason?.includes("conversion")
            ).length > 0
          ) {
            if (footer.buttons[0].onClick) {
              footer.buttons[0].onClick({ shouldUpdate: true });
            }
            setSteps([createdStep, failedCheckStep]);
            handleSetStep("failed");
            return;
          } else if (
            succeededRef.current.length > 0 ||
            failedRef.current.filter((failed) =>
              failed.reason?.includes("conversion")
            ).length > 0
          ) {
            setSteps([createdStep, succeededCheckStep]);
            handleSetStep("succeeded");
            return;
          }
        } else {
          // console.log("current step: ", currentStep);
          fetchData();
        }
        if (currentStep === "entered") {
        }
      }
    }
  };

  const handleSetStep = (
    value:
      | "creating"
      | "created"
      | "checking"
      | "failed"
      | "succeeded"
      | "entering"
      | "entered"
  ) => {
    if (value !== currentStep) {
      setCurrentStep(value);
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel("from automated, canceling");
      }

      if (intervalId.current) {
        // console.log("clearing interval 4");
        clearInterval(intervalId.current);
      }
    };
  }, []);

  useEffect(() => {
    // const step = handleCurrentStep();
    // let temp = steps;
    // if (currentStep === "created") temp = [];

    // if (!["failed", "succeeded", "entered"].includes(currentStep)) {
    //   if (["checking", "entering"].includes(currentStep)) {
    fetchVerificationStatus().then(() => {
      // const tempSteps = new Set(temp);
      // if (!temp.some((value) => value.step === step.step)) {
      //   tempSteps.add(step);
      // }
      // setSteps(() => Array.from(tempSteps));
    });
    // }

    // }
  }, [currentStep]);

  return (
    <Container>
      {steps.map((step, index) => (
        <React.Fragment key={index}>{step.element}</React.Fragment>
      ))}
    </Container>
  );
};

export default GoodsAutomated;
