import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ROUTES } from "./router";
import { TAccount, TAccountData } from "./redux/reducers/account";
import { localStorageConstants } from "./utils/constants";
import apiService from "./services/api";
import {
  handleGetDataFromLocalStorage,
  handleSetDataToLocalStorage,
} from "./utils/miscellaneous";
import Modal from "./components/ui-v2/Modal";
import { useTypedSelector } from "./hooks/useTypedSelector";
import { useTypedDispatch } from "./hooks/useTypedDispatch";
import { setLogoutModalState } from "./redux/reducers/globalState";
import Header from "./components/Header";
import axios, { CancelTokenSource } from "axios";

type TAppProps = {
  component: JSX.Element;
  style?: React.CSSProperties;
};

const Container = styled.div<{ $shouldDecreaseHeight?: boolean }>`
  height: ${({ $shouldDecreaseHeight }) =>
    $shouldDecreaseHeight ? "calc(100vh - 80px)" : "100vh"};

  @media (max-width: 960px) {
    display: none;
  }
`;

const App: React.FC<TAppProps> = ({ component, style }) => {
  const navigate = useNavigate();

  const dispatch = useTypedDispatch();

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const logoutModal = useTypedSelector(
    (state) => state.globalState.data.logoutModal
  );

  // const selectedOrganization = useTypedSelector(
  //   (state) => state.globalState.data.selectOrganization
  // );

  // const [shouldDecreaseHeight, setShouldDecreaseHeight] = useState(false);

  const fetchData = async () => {
    try {
      cancelTokenSource.current = axios.CancelToken.source();

      const localUserData = localStorage.getItem(
        localStorageConstants.USER_DATA
      );
      if (!localUserData) {
        apiService.getUserData(cancelTokenSource.current.token);
      }

      let localAccountCount = handleGetDataFromLocalStorage(
        localStorageConstants.ACCOUNTS_COUNT
      ) as number;

      // gonna eliminate for localstorage account attribute change
      let accounts;

      if (!localAccountCount) {
        const accountsResponse = (
          await apiService.getAccounts(cancelTokenSource.current.token)
        ).data;
        accounts = accountsResponse as TAccountData;
        handleSetDataToLocalStorage(
          localStorageConstants.ACCOUNTS_COUNT,
          accounts.count
        );
        localAccountCount = accounts.count;
      }

      const localSelectAccount = handleGetDataFromLocalStorage(
        localStorageConstants.SELECT_ACCOUNT
      ) as TAccount;

      if (
        (!localSelectAccount && localAccountCount > 1) ||
        localAccountCount === 0
      ) {
        navigate(ROUTES.ACCOUNTS + window.location.search);
      } /* else if (localSelectAccount && !localOrganizations) {
         await apiService.getOrganizations(localSelectAccount.uuid);
      } */ else if (
        [ROUTES.ACCOUNTS, ROUTES.LOGIN, ROUTES.HOME].includes(
          window.location.pathname
        )
      ) {
        if (
          [ROUTES.ACCOUNTS].includes(window.location.pathname) &&
          window.location.search.length > 0
        ) {
          navigate(ROUTES.ACCOUNTS + window.location.search);
        } else navigate(ROUTES.HOME + window.location.search);
      }
    } catch (e) {}
  };

  useEffect(() => {}, [logoutModal.shouldShow]);

  useEffect(() => {
    const authorization = localStorage.getItem("Authorization");
    const isAllowed = authorization && authorization.length > 0;

    if (!isAllowed) {
      navigate(ROUTES.LOGIN);
    } else {
      fetchData();
    }

    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
      Object.values(localStorageConstants).forEach((value) => {
        if (
          !Array.isArray(value) &&
          !localStorageConstants.CACHE_CLEAR_WHITE_LIST.includes(value)
        ) {
          localStorage.removeItem(value);
        }
      });
    };
  }, []);

  // useEffect(() => {
  //   setShouldDecreaseHeight(false);
  //   setTimeout(() => {
  //     const bodyElement = document.getElementsByTagName("body")[0];
  //     if (bodyElement) {
  //       if (bodyElement.scrollHeight > bodyElement.clientHeight) {
  //         setShouldDecreaseHeight(true);
  //       }
  //     }
  //   }, 300);
  // }, [selectedOrganization, window.location.href]);

  return (
    <Container
      $shouldDecreaseHeight={
        !window.location.href.includes(
          ROUTES.ACCOUNTS
        ) /*  && shouldDecreaseHeight */
      }
      style={style}
    >
      {window.location.pathname !== ROUTES.ACCOUNTS && <Header />}
      <div style={{ margin: `0 24px` }}>{component}</div>
      <Modal
        shouldOpen={logoutModal.shouldShow}
        onClose={() => {
          dispatch(setLogoutModalState({ shouldShow: false }));
        }}
        title="Sair da sua conta"
        icon={{ type: "exclamationTriangle", color: "orange" }}
        main={{
          style: "textOnly",
          content: [
            // {
            //   style: "default",
            //   text: "",
            //   shouldBreakLine: true,
            // },
            {
              style: "default",
              text: "Tem certeza que deseja sair?",
              shouldBreakLine: true,
            },
            // {
            //   style: "default",
            //   text: "",
            //   shouldBreakLine: true,
            // },
          ],
        }}
        footer={{
          style: "default",
          buttons: [
            {
              icon: "noIcon",
              text: "Cancelar",
              variant: "secondary",
              onClick() {
                dispatch(setLogoutModalState({ shouldShow: false }));
              },
            },
            {
              iconPosition: "left",
              icon: "logout",
              text: "Sim, sair",
              variant: "orange",
              onClick() {
                localStorage.clear();
                dispatch(setLogoutModalState({ shouldShow: false }));
                navigate(ROUTES.LOGIN);
              },
            },
          ],
        }}
      />
    </Container>
  );
};

export default App;
