import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchMemberships } from "../../actions";

export type TMembershipRole = 10 | 20 | 30;

export type TMembershipStatus = 0 | 1 | 2;

export type TMembership = {
  account: {
    uuid: string;
  };
  created_at: string;
  email: string;
  is_active: boolean;
  // organizations: string[];
  organizations_count: number;
  role: TMembershipRole;
  status: TMembershipStatus;
  updated_at: string;
  user: {
    email: string;
    first_name: string;
    last_name: string;
    uuid: string;
  };
  uuid: string;
};

export type TMembershipData = {
  count: number;
  current_page: number;
  pages: [{ [key: number]: string }];
  results: TMembership[];
};

type TInitialState = {
  loading: boolean;
  error: null | string;
  data: TMembershipData;
};

const initialState: TInitialState = {
  loading: false,
  error: "",
  data: {
    count: 0,
    current_page: 0,
    pages: [{}],
    results: [],
  },
};

const membershipDataSlice = createSlice({
  name: "memberships",
  initialState: initialState,
  reducers: {
    setMemberships: (state, action: PayloadAction<TMembershipData>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMemberships.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMemberships.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload ?? state.data;
      })
      .addCase(fetchMemberships.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "error";
      });
  },
});

export const { setMemberships } = membershipDataSlice.actions;
export default membershipDataSlice.reducer;
