import { useCallback, useEffect, useId, useRef, useState } from "react";
import {
  Container,
  MobileSupportContainer,
  SearchBarSection,
  SearchBarSectionSubcontainer,
  SummarySection,
} from "./styles";
import SearchBar from "../../components/ui-v2/Inputs/Search";
import TabsGroup from "../../components/ui/groups/TabsGroup";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import DropdownFilter from "../../components/ui-v2/DropdownFilter";
import {
  /* Table , */ TCellNotTagMiscellaneous,
  TCellStyleVariants,
  TTableBody,
  TText,
} from "../../components/ui-v2/Table";
import {
  breakStringPreserveSpaces,
  cnpjMask,
  // handleFormatDateAndTime,
  handleSortQuerystring,
} from "../../utils/formatters";

import {
  TInvoice,
  TInvoiceEntryStatus,
  TInvoiceManifestastionStatus,
  TNexaasProduct,
  // TNexaasState,
  TPaymentSlip,
  setAttachedPaymentSlips,
  setInvoicesUpdatedAt,
  setPaymentSlipsUpdatedAt,
  setToastState,
} from "../../redux/reducers/globalState";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { fetchInvoices, fetchPaymentSlips } from "../../redux/actions";
import {
  CALENDAR_CONTENT_OPTIONS,
  TCalendarMenuOptions,
} from "../../components/ui-v2/DropdownFilter/Menu";
import { sub } from "date-fns/sub";
import { format } from "date-fns/format";
import { parse } from "date-fns/parse";
import {
  backendErrorMessageHandler,
  getQuerystringParams,
  handleParseQuerystrings,
  handleParseToDateCalendarOptions,
  handlePopulateQuerystring,
  handleRevertTranslateCalendarOptions,
  handleSetDataToLocalStorage,
  handleSetEndDate,
  handleTranslateCalendarOptions,
} from "../../utils/miscellaneous";
import { localStorageConstants } from "../../utils/constants";
import Modal from "../../components/ui-v2/Modal";
import TableGrid from "../../components/ui-v2/TableGrid";
import apiService from "../../services/api";
import { TPayableAccount } from "../../components/ui-v2/Modal/Forms/LinkPayable";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../router";
import { UilAngleDown, UilCopyAlt, UilLink } from "@iconscout/react-unicons";
import AttachmentMenu from "../../components/ui-v2/Menus/AttachmentMenu";
import Tooltip from "../../components/ui-v2/Tooltip";
import axios, { CancelTokenSource } from "axios";
import Button from "../../components/ui-v2/Button";

function Home() {
  const dispatch = useTypedDispatch();

  const cancelTokenSource = useRef<CancelTokenSource>(
    axios.CancelToken.source()
  );

  const {
    invoiceState,
    invoiceUpdatedAt: invoicesUpdatedAt,
    selectOrganization,
    selectedOrganizationWithPermissions,
    pendingInvoicesCount: pendingEntryStatusCount,
    doneInvoicesCount: doneEntryStatusCount,
    rejectInvoicesCount: rejectEntryStatusCount,
    attachedPaymentSlips,
  } = useTypedSelector((state) => state.globalState.data);

  const filesInputRef = useRef<HTMLInputElement | null>(null);

  const startDateRef = useRef<string | undefined>(
    format(sub(new Date(), { days: 30 }), "yyyy-MM-dd")
  );
  const endDateRef = useRef<string | undefined>(
    format(new Date(), "yyyy-MM-dd")
  );
  const entryStatusRef = useRef<TInvoiceEntryStatus | undefined>(10);
  const payablesRef = useRef<boolean | undefined>();
  const goodsEntryRef = useRef<boolean | undefined>();
  const pageRef = useRef(1);
  const itemsPerPageRef = useRef(100);
  const searchRef = useRef<string | undefined>();

  const positionRef = useRef<DOMRect | undefined>();

  // const pollingIntervalIdRef = useRef<number | null>(null);

  const clearSelectedPaymentSlipsRef = useRef<(() => void) | null>(null);

  const selectPayableRef = useRef<TPayableAccount>();

  const payableModalInvoiceRef = useRef<TInvoice | undefined>();
  const allInvoicesRef = useRef<TInvoice[]>([]);

  const goodsStockCheckDataRef = useRef<{
    success: TNexaasProduct[];
    error: TNexaasProduct[];
  } | null>(null);

  const searchBarContainerId = useId();

  // const mockNexaasStateRef = useRef<TNexaasState>(10);

  const [invoiceToAttachLinks, setInvoiceToAttachLinks] =
    useState<TInvoice | null>(null);

  const [paymentSlipToBreakLink, setPaymentSlipToBreakLink] = useState<
    { target: TPaymentSlip; origin: TInvoice } | undefined
  >();

  const [paymentSlipToRename, setPaymentSlipToRename] = useState<
    TPaymentSlip | undefined
  >();

  const [shouldShowAttachmentMenu, setShouldShowAttachmentMenu] = useState("");

  const [shouldOpenPayable, setShouldOpenPayable] = useState(false);

  const [shouldOpenPayableModal, setShouldOpenPayableModal] = useState(false);
  const [shouldOpenLinkPayableModal, setShouldOpenLinkPayableModal] =
    useState(false);
  const [isLinkPayableLoading, setIsLinkPayableLoading] = useState(false);
  const [shouldUpdateComponent, setShouldUpdateComponent] = useState(false);

  const [shouldOpenLinkPaymentSlipModal, setShouldOpenLinkPaymentSlipModal] =
    useState(false);

  const [
    shouldOpenInstallmentAccountModal,
    setShouldOpenInstallmentAccountModal,
  ] = useState(false);

  const [shouldRejectFilledEntryStatus /* setShouldRejectFilledEntryStatus */] =
    useState(false);

  const [shouldOpenGoodsModal, setShouldOpenGoodsModal] = useState(false);

  const [shouldOpenGoodsAutomatedModal, setShouldOpenGoodsAutomatedModal] =
    useState(false);

  const [shouldOpenGoodsStockCheckModal, setShouldOpenGoodsStockCheckModal] =
    useState(false);

  const navigate = useNavigate();

  const entryStatusOptions = [
    "Sem lançamentos",
    "Contas a pagar lançada",
    "Entrada de mercadorias lançada",
  ];

  const SHOULD_DISABLE_FINTERA_ACTIONS =
    selectedOrganizationWithPermissions &&
    !selectedOrganizationWithPermissions.permissions.fintera;

  const SHOULD_DISABLE_NEXAAS_ACTIONS =
    selectedOrganizationWithPermissions &&
    !selectedOrganizationWithPermissions.permissions.nexaas_omni;

  // const handleStringDateAndTime = (stringDate: string) => {
  //   if (stringDate?.length > 0) {
  //     const { formatDate, formatTime } = handleFormatDateAndTime(stringDate);
  //     return formatDate + " - " + formatTime;
  //   }
  // };

  const handleFormatStatusCell = (
    status: TInvoiceManifestastionStatus | null
  ) => {
    switch (status) {
      case 10:
        return { style: "blue", value: "Ciente", icon: "noIcon" };
      case 20:
        return { style: "green", value: "Confirmada", icon: "noIcon" };
      case 30:
        return { style: "gray", value: "Desconhecida", icon: "noIcon" };
      case 40:
        return { style: "red", value: "Recusada", icon: "noIcon" };
      case null:
        return { style: "default", value: " - " };
    }
  };

  const rowFormatter = (invoice: TInvoice, index: number) => {
    return [
      { style: "default", value: invoice?.number ? invoice.number : "-" },
      {
        style: "default",
        value: invoice.date_issue
          ? format(invoice?.date_issue, "dd/MM/yyyy")
          : "-",
      },
      {
        style: "miscellaneous",
        tooltip: invoice.issuer.name && {
          text: (() => {
            const values = breakStringPreserveSpaces(invoice.issuer.name, 24);

            return values.map((value, index) => ({
              style: "default",
              text: value,
              shouldBreakLine: index !== values.length - 1,
            }));
          })(),
          customTopPosition: invoice.issuer.name
            ? index * 97 +
              (215 -
                (breakStringPreserveSpaces(invoice.issuer.name, 24).length -
                  1) *
                  20)
            : "",
          hasScrollableRef: true,
        },
        values: [
          {
            style: "bold",
            text: invoice.issuer.name
              ? invoice?.issuer.name.length > 20
                ? invoice.issuer.name.slice(0, 20) + "..."
                : invoice.issuer.name
              : "-",
            shouldBreakLine: true,
          },
          {
            style: "default",
            text: invoice.issuer.document_number
              ? cnpjMask(invoice?.issuer.document_number)
              : "-",
            shouldBreakLine: false,
          },
        ],
      },
      {
        style: "default",
        value: invoice.amount ? "R$ " + invoice?.amount.replace(".", ",") : "-",
      },
      handleFormatStatusCell(invoice?.manifestation),
      [
        {
          style: "button",
          icon: "nfe",
          isDisabled: invoice.file_nfe === null,
          onClick() {
            window.open(invoice.file_nfe ? invoice.file_nfe : "", "_blank");
          },
          tooltip: {
            text: [
              invoice.file_nfe !== null
                ? {
                    style: "default",
                    text: "Baixar DANFE",
                    shouldBreakLine: false,
                  }
                : {
                    style: "default",
                    text: "DANFE indisponível",
                    shouldBreakLine: false,
                  },
            ],
            customTopPosition: index * 97 + 223,
            hasScrollableRef: true,
          },
        },
        {
          style: "button",
          icon: "xml",
          isDisabled: invoice.file_xml === null,
          onClick() {
            window.open(invoice.file_xml ? invoice.file_xml : "", "_blank");
          },
          tooltip: {
            text: [
              invoice.file_xml !== null
                ? {
                    style: "default",
                    text: "Baixar XML",
                    shouldBreakLine: false,
                  }
                : {
                    style: "default",
                    text: "XML indisponível",
                    shouldBreakLine: false,
                  },
            ],
            customTopPosition: index * 97 + 223,
            hasScrollableRef: true,
          },
        },
      ],
      [
        {
          style: "button",
          icon:
            invoice.goods_receipt_url === null
              ? "goodsReceiptOrange"
              : invoice.nexaas_state === 10
              ? "goodsReceiptOrange"
              : invoice.nexaas_state === 20
              ? "goodsReceiptGreen"
              : invoice.nexaas_state === 30
              ? "goodsExpressBlue"
              : invoice.nexaas_state === 40
              ? "goodsExpressRed"
              : invoice.nexaas_state === 50
              ? "goodsExpressBlueAndClock"
              : invoice.nexaas_state === 60 && "goodsExpressGreen",
          isDisabled:
            (invoice.goods_receipt_url !== null &&
              invoice.goods_receipt_url.length === 0) ||
            invoice.invoice_type === 20 ||
            invoice.file_xml === null ||
            SHOULD_DISABLE_NEXAAS_ACTIONS,
          tooltip:
            !SHOULD_DISABLE_NEXAAS_ACTIONS &&
            invoice.invoice_type !== 20 &&
            invoice.goods_receipt_url === null &&
            invoice.file_xml !== null
              ? {
                  text: [
                    {
                      style: "default",
                      text: "Clique para criar uma ",
                      shouldBreakLine: false,
                    },
                    {
                      style: "bold",
                      text: "entrada de mercadorias ",
                      shouldBreakLine: false,
                    },
                    {
                      style: "default",
                      text: "no Nexaas Omni",
                      shouldBreakLine: false,
                    },
                  ],
                  customTopPosition: 97 * index + 208.5,
                  hasScrollableRef: true,
                }
              : SHOULD_DISABLE_NEXAAS_ACTIONS ||
                invoice.invoice_type === 20 ||
                invoice.file_xml === null ||
                (invoice.goods_receipt_url as string).length === 0
              ? {
                  text: [
                    {
                      style: "default",
                      text: "Criar entrada indisponível",
                      shouldBreakLine: false,
                    },
                  ],
                  customTopPosition: index * 97 + 223,
                  hasScrollableRef: true,
                }
              : invoice.nexaas_state === 20
              ? {
                  text: [
                    {
                      style: "default",
                      text: "Entrada de mercadorias criada.",
                      shouldBreakLine: true,
                    },
                    {
                      style: "default",
                      text: "Clique para visualizar no Nexaas Omni",
                      shouldBreakLine: false,
                    },
                  ] as TText[],
                  customTopPosition: (4270 / 44) * (index + 1) + 88.865,
                  // hasScrollableRef: true,
                }
              : {
                  text: [
                    {
                      style: "default",
                      text:
                        invoice.nexaas_state === 30
                          ? "Entrada de mercadorias criada"
                          : invoice.nexaas_state === 40
                          ? "Entrada criada."
                          : invoice.nexaas_state === 50
                          ? "Lançando estoques."
                          : invoice.nexaas_state === 60 &&
                            "Entrada finalizada e estoques lançados",
                      shouldBreakLine: true,
                    },
                    invoice.nexaas_state > 30 &&
                      invoice.nexaas_state < 60 && {
                        style: "default",
                        text:
                          invoice.nexaas_state === 40
                            ? "Corrija os erros para finalizar"
                            : invoice.nexaas_state === 50 &&
                              "Pode levar alguns minutos",
                        shouldBreakLine: false,
                      },
                  ] as TText[],
                  customTopPosition:
                    97 * index + (invoice.nexaas_state > 30 ? 208.5 : 223),
                  hasScrollableRef: true,
                },
          onClick() {
            payableModalInvoiceRef.current = invoice;
            if (invoice.goods_receipt_url === null) {
              if (invoice.nexaas_state === 10) {
                setShouldOpenGoodsModal(true);
              }
            } else if (invoice.goods_receipt_url) {
              if (![20 /* , 60 */].includes(invoice.nexaas_state)) {
                setShouldOpenGoodsAutomatedModal(true);
              } else {
                window.open(invoice.goods_receipt_url, "_blank");
              }
            }
          },
        },
        {
          style: "button",
          icon:
            invoice.accounts_payable_url === null
              ? "payableOrange"
              : invoice.accounts_payable_url.length > 0
              ? "payableGreen"
              : "payableOrange",
          isDisabled:
            (invoice.accounts_payable_url !== null &&
              invoice.accounts_payable_url.length === 0) ||
            invoice.file_xml === null ||
            SHOULD_DISABLE_FINTERA_ACTIONS,
          tooltip:
            !SHOULD_DISABLE_FINTERA_ACTIONS &&
            invoice.accounts_payable_url === null &&
            invoice.file_xml !== null
              ? {
                  text: [
                    {
                      style: "default",
                      text: "Clique para criar uma ",
                      shouldBreakLine: false,
                    },
                    {
                      style: "bold",
                      text: "conta a pagar ",
                      shouldBreakLine: false,
                    },
                    {
                      style: "default",
                      text: "na Fintera",
                      shouldBreakLine: false,
                    },
                  ],
                  customTopPosition: 97 * index + 208.5,
                  hasScrollableRef: true,
                }
              : SHOULD_DISABLE_FINTERA_ACTIONS ||
                invoice.file_xml === null ||
                (invoice.accounts_payable_url as string).length === 0
              ? {
                  text: [
                    {
                      style: "default",
                      text: "Criar conta a pagar indisponível",
                      shouldBreakLine: false,
                    },
                  ],
                  customTopPosition: index * 97 + 223,
                  hasScrollableRef: true,
                }
              : {
                  text: [
                    {
                      style: "default",
                      text: "Conta a pagar criada.",
                      shouldBreakLine: true,
                    },
                    {
                      style: "default",
                      text: "Clique para visualizar na Fintera",
                      shouldBreakLine: false,
                    },
                  ],
                  customTopPosition: 97 * index + 208.5,
                  hasScrollableRef: true,
                },

          onClick() {
            if (invoice.accounts_payable_url === null) {
              payableModalInvoiceRef.current = invoice;
              setShouldOpenPayable(true);
            } else {
              window.open(
                invoice.accounts_payable_url
                  ? invoice.accounts_payable_url
                  : "",
                "_blank"
              );
            }
          },
        },
      ],
      {
        style: "button",
        icon: "ellipsisV",
        hasScrollableRef: true,
        isDisabled:
          invoice.manifestation !== null &&
          ![10, 20].includes(invoice.launch_status) &&
          invoice.goods_receipt_url !== null &&
          invoice.accounts_payable_url !== null,
        content: [
          /* invoice.manifestation === null && */
            invoice.invoice_type !== 20 &&
            "editStatus",
          invoice.launch_status === 10 && "markAsFinished",
          invoice.launch_status === 20 && "markAsPending",

          invoice.invoice_type !== 20 &&
            invoice.file_xml !== null &&
            !SHOULD_DISABLE_NEXAAS_ACTIONS &&
            invoice.goods_receipt_url === null &&
            "openGoodsModal",

          invoice.file_xml !== null &&
            !SHOULD_DISABLE_FINTERA_ACTIONS &&
            invoice.accounts_payable_url === null &&
            "openPayableModal",
        ],
        onClick(select: string) {
          if (select === "openGoodsModal") {
            payableModalInvoiceRef.current = invoice;
            setShouldOpenGoodsModal(true);
          }

          if (select === "openPayableModal") {
            payableModalInvoiceRef.current = invoice;
            setShouldOpenPayable(true);
          }

          handleSetDataToLocalStorage(
            localStorageConstants.SELECT_INVOICE,
            invoice
          );
          handleSetDataToLocalStorage(
            localStorageConstants.CURRENT_ENTRY_STATUS,
            entryStatusRef.current === undefined ? 40 : entryStatusRef.current
          );
        },
      },
    ] as TTableBody<TCellStyleVariants>[];
  };

  const handleSecondRowLeftSideCustomNodeRender = (
    invoice: TInvoice,
    index: number
  ) => {
    return (
      <span>
        <Tooltip
          text={[{ text: "Copiar", style: "default", shouldBreakLine: false }]}
          customTopPosition={275 + 98 * index}
        >
          <button
            role="button"
            onClick={() => {
              navigator.clipboard.writeText(invoice.access_key).then(() => {
                dispatch(
                  setToastState({
                    variant: "success",
                    message: {
                      title: "Sucesso",
                      description: "Chave de acesso copiada",
                    },
                    shouldShow: true,
                  })
                );
              });
            }}
          >
            <UilCopyAlt />
          </button>
        </Tooltip>
      </span>
    );
  };

  const handleSecondRowRightSideCustomNodeRender = (
    invoice: TInvoice,
    rowIndex: number
  ) => {
    return invoice.boletos_exists ? (
      <span>
        <button
          role="button"
          onClick={() => {
            if (attachedPaymentSlips.length > 0) {
              dispatch(setAttachedPaymentSlips([]));
              setShouldShowAttachmentMenu("");
            } else {
              apiService
                .getInvoiceAttachedPaymentSlips(
                  invoice.uuid,
                  cancelTokenSource.current.token
                )
                .then((response: any) => {
                  dispatch(setAttachedPaymentSlips(response.data));
                  setShouldShowAttachmentMenu(`${rowIndex}`);
                  positionRef.current = document
                    .getElementById("link-container-" + rowIndex)
                    ?.getBoundingClientRect();
                });
            }
          }}
        >
          Ver boletos <UilAngleDown />
        </button>

        <AttachmentMenu
          position={{ top: 340 + 100 * rowIndex + "px" }}
          shouldShowMenu={
            shouldShowAttachmentMenu === `${rowIndex}` &&
            attachedPaymentSlips.length > 0
          }
          onClickAway={() => {
            dispatch(setAttachedPaymentSlips([]));
            setShouldShowAttachmentMenu("");
          }}
          // items={attachedInvoices.map((item) =>
          //   test(item, rowIndex, secondRow)
          // )}
          items={attachedPaymentSlips.map((paymentSlip: TPaymentSlip) => {
            return {
              onRename() {
                setPaymentSlipToRename(paymentSlip);
              },
              onDelete:
                paymentSlip.fintera_id && paymentSlip.fintera_id.length > 0
                  ? undefined
                  : () => {
                      setPaymentSlipToBreakLink({
                        target: paymentSlip,
                        origin: invoice,
                      });
                    },
              name: paymentSlip.label,
              searchParam: paymentSlip.bar_code,
              url:
                ROUTES.PAYMENT_SLIP +
                window.location.search +
                "&period=all-time",
            };
          })}
          onAction={{
            callback: () => {
              setInvoiceToAttachLinks(invoice);
              setShouldOpenLinkPaymentSlipModal(true);
            },
            text: "Vincular boleto",
          }}
        />
      </span>
    ) : (
      <span>
        <p>Nenhum boleto vinculado</p>
        <button
          role="button"
          onClick={() => {
            setInvoiceToAttachLinks(invoice);
            setShouldOpenLinkPaymentSlipModal(true);
          }}
        >
          <UilLink />
          &nbsp;Vincular
        </button>
      </span>
    );
  };

  const secondRowFormatter = (invoice: TInvoice) => ({
    style: "miscellaneous",
    values: [
      {
        style: "default",
        text: "Chave de acesso: ",
        shouldBreakLine: false,
      },
      {
        style: "faded",
        text: invoice.access_key,
        shouldBreakLine: false,
      },
    ],
  });

  const handleCalendarOnSelect = (selected: string[]) => {
    const select = selected as TCalendarMenuOptions[];

    const searchString = handlePopulateQuerystring();

    if (CALENDAR_CONTENT_OPTIONS.includes(select[0])) {
      const tempStartDate = handleParseToDateCalendarOptions(select[0]);

      if (selectOrganization) {
        if (tempStartDate === undefined) {
          startDateRef.current = undefined;
          endDateRef.current = undefined;

          handleSetDataToLocalStorage(
            localStorageConstants.INVOICE_FILTER_START_DATE,
            undefined
          );

          handleSetDataToLocalStorage(
            localStorageConstants.INVOICE_FILTER_END_DATE,
            undefined
          );

          searchString.delete("start_date");
          searchString.delete("end_date");

          searchString.set("period", "all-time");

          updateAllInvoicesRef();
          fetchInvoicesByAllEntryStatus();
          navigate("./" + handleSortQuerystring(searchString));
        } else {
          const formatTempStartDate = format(tempStartDate, "yyyy-MM-dd");
          if (formatTempStartDate !== startDateRef.current) {
            const formatEndDate = handleSetEndDate(select[0]);

            startDateRef.current = formatTempStartDate;
            // if (formatEndDate !== endDateRef.current)

            endDateRef.current = formatEndDate;

            handleSetDataToLocalStorage(
              localStorageConstants.INVOICE_FILTER_START_DATE,
              formatTempStartDate
            );

            handleSetDataToLocalStorage(
              localStorageConstants.INVOICE_FILTER_END_DATE,
              formatEndDate
            );

            searchString.set(
              "period",
              handleTranslateCalendarOptions(
                select[0] as TCalendarMenuOptions
              ) as string
            );

            searchString.delete("start_date");
            searchString.delete("end_date");

            updateAllInvoicesRef(undefined, formatEndDate);
            fetchInvoicesByAllEntryStatus(undefined, formatEndDate);

            navigate("./" + handleSortQuerystring(searchString));
          }
        }
      }
    } else {
      const dates = select[0].split(" - ");
      const tempStartDate = format(
        parse(dates[0], "dd/MM/yyyy", new Date()),
        "yyyy-MM-dd"
      );
      const tempEndDate = format(
        parse(dates[1], "dd/MM/yyyy", new Date()),
        "yyyy-MM-dd"
      );

      handleSetDataToLocalStorage(
        localStorageConstants.INVOICE_FILTER_START_DATE,
        tempStartDate
      );

      handleSetDataToLocalStorage(
        localStorageConstants.INVOICE_FILTER_END_DATE,
        tempEndDate
      );

      if (tempStartDate !== startDateRef.current)
        startDateRef.current = tempStartDate;
      if (tempEndDate !== endDateRef.current) endDateRef.current = tempEndDate;

      searchString.set("end_date", tempEndDate);
      searchString.set("start_date", tempStartDate);

      navigate("./" + handleSortQuerystring(searchString));

      setTimeout(() => {
        updateAllInvoicesRef();
        fetchInvoicesByAllEntryStatus();
      }, 100);
    }
  };

  const handleEntryStatusCustomFilter = (
    index: string,
    _selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
    selectDataRef: React.MutableRefObject<string[]>,
    prevSelectDataRef: React.MutableRefObject<string[]>,
    setShouldUpdate: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    let prevSelect = [];
    if (goodsEntryRef.current) {
      prevSelect.push("2");
    }
    if (payablesRef.current) {
      prevSelect.push("1");
    }
    if (payablesRef.current === false && goodsEntryRef.current === false) {
      prevSelect = ["0"];
    }

    prevSelectDataRef.current = prevSelect.join().split(",");

    setSelected((prev) => {
      let value = prev;
      if (value.includes(index)) {
        const elementToRemoveIndex = value.indexOf(index);
        value.splice(elementToRemoveIndex, 1);
      } else if (prevSelectDataRef.current.includes(index)) {
        const elementToRemoveIndex = prevSelectDataRef.current.indexOf(index);
        prevSelectDataRef.current.splice(elementToRemoveIndex, 1);
        value = [...prev, ...prevSelectDataRef.current];
      } else {
        value.push(index);
      }

      if (value.length > 1 && value.includes("0")) {
        if (index === "0") value = ["0"];
        else {
          value.splice(value.indexOf("0"), 1);
        }
      }

      if (value.includes("")) value.splice(0, 1);

      selectDataRef.current = value;
      setShouldUpdate(true);
      setTimeout(() => {
        setShouldUpdate(false);
      }, 20);

      return value;
    });
  };

  const handleEntryStatusOnAction = (
    selectDataRef: React.MutableRefObject<string[]>
  ) => {
    let hasPayable, hasGoods;

    const queryStrings = handleParseQuerystrings(window.location.search);

    const searchString = handlePopulateQuerystring();

    if (queryStrings.organization_id) {
      searchString.set("organization_id", queryStrings.organization_id[0]);
    }

    if (selectDataRef.current.length) {
      if (selectDataRef.current.includes("2")) {
        hasGoods = true;
      }
      if (selectDataRef.current.includes("1")) {
        hasPayable = true;
      }
      if (
        selectDataRef.current.includes("0") &&
        selectDataRef.current.length === 1
      ) {
        hasPayable = false;
        hasGoods = false;
      }
      payablesRef.current = hasPayable;
      goodsEntryRef.current = hasGoods;

      if (hasPayable !== undefined)
        searchString.set("has_accounts_payable", `${payablesRef.current}`);

      if (hasGoods !== undefined)
        searchString.set("has_goods_receipt", `${goodsEntryRef.current}`);

      if (entryStatusRef.current) {
        searchString.set("launch_status", `${entryStatusRef.current ?? 40}`);
      } /* else {
        searchString.delete("launch_status");
      } */

      navigate("./" + handleSortQuerystring(searchString));
    } else {
      payablesRef.current = undefined;
      goodsEntryRef.current = undefined;

      searchString.delete("has_accounts_payable");
      searchString.delete("has_goods_receipt");

      if (entryStatusRef.current) {
        searchString.set("launch_status", `${entryStatusRef.current ?? 40}`);
      } /* else {
        searchString.delete("launch_status");
      } */

      navigate("./" + handleSortQuerystring(searchString));
    }
    updateAllInvoicesRef();
    fetchInvoicesByAllEntryStatus();
  };

  const handleEntryStatusOnCancel = () => {
    // payablesRef.current = undefined;
    // goodsEntryRef.current = undefined;
    // setShouldRejectFilledEntryStatus(true);
    // navigate("./");
    // fetchInvoicesByAllEntryStatus();
  };

  const fetchPaymentSlipsByAllEntryStatus = async () => {
    if (selectOrganization) {
      // let entryStatus1, entryStatus2;

      // switch (entryStatusRef.current) {
      //   case 10:
      //     entryStatus1 = 20;
      //     entryStatus2 = 30;
      //     break;
      //   case 20:
      //     entryStatus1 = 10;
      //     entryStatus2 = 30;
      //     break;
      //   case 30:
      //     entryStatus1 = 20;
      //     entryStatus2 = 10;
      //     break;
      // }

      // await dispatch(
      //   fetchPaymentSlips({
      //     // page: pageRef.current,
      //     organizationUuid: selectOrganization.uuid,
      //     dateIssueStart: startDateRef.current,
      //     dateIssueEnd: endDateRef.current,
      //     launch_status: entryStatus1 as TInvoiceEntryStatus,
      //     has_accounts_payable: payablesRef.current,
      //     has_goods_receipt: goodsEntryRef.current,
      //     search: searchRef.current,
      //     cancelToken: cancelTokenSource.current.token,
      //   })
      // );
      // await dispatch(
      //   fetchPaymentSlips({
      //     // page: pageRef.current,
      //     organizationUuid: selectOrganization.uuid,
      //     dateIssueStart: startDateRef.current,
      //     dateIssueEnd: endDateRef.current,
      //     launch_status: entryStatus2 as TInvoiceEntryStatus,
      //     has_accounts_payable: payablesRef.current,
      //     has_goods_receipt: goodsEntryRef.current,
      //     search: searchRef.current,
      //     cancelToken: cancelTokenSource.current.token,
      //   })
      // );
      // setShouldUpdateComponent(true);
      setTimeout(async () => {
        await dispatch(
          fetchPaymentSlips({
            page: pageRef.current,
            organizationUuid: selectOrganization.uuid,
            dateIssueStart: startDateRef.current,
            dateIssueEnd: endDateRef.current,
            launch_status: entryStatusRef.current,
            has_accounts_payable: payablesRef.current,
            has_goods_receipt: goodsEntryRef.current,
            search: searchRef.current,
            cancelToken: cancelTokenSource.current.token,
          })
        ).then(() => {
          apiService
            .getPaymentSlipUpdatedAt(
              selectOrganization.uuid,
              cancelTokenSource.current.token
            )
            .then((response) => {
              dispatch(
                setPaymentSlipsUpdatedAt({
                  responseStatus: response.status,
                  date: response.data.updated_at,
                })
              );
            });
        });
        setShouldUpdateComponent(false);
      }, 50);
    }
  };

  const fetchInvoicesByAllEntryStatus = async (
    _nexaasState?: number,
    endDate?: string
  ) => {
    if (selectOrganization) {
      // let entryStatus1, entryStatus2;

      // switch (entryStatusRef.current) {
      //   case 10:
      //     entryStatus1 = 20;
      //     entryStatus2 = 30;
      //     break;
      //   case 20:
      //     entryStatus1 = 10;
      //     entryStatus2 = 30;
      //     break;
      //   case 30:
      //     entryStatus1 = 20;
      //     entryStatus2 = 10;
      //     break;
      // }

      // await dispatch(
      //   fetchInvoices({
      //     // page: pageRef.current,
      //     organizationUuid: selectOrganization.uuid,
      //     dateIssueStart: startDateRef.current,
      //     dateIssueEnd: endDateRef.current,
      //     launch_status: entryStatus1 as TInvoiceEntryStatus,
      //     has_accounts_payable: payablesRef.current,
      //     has_goods_receipt: goodsEntryRef.current,
      //     search: searchRef.current,
      //     cancelToken: cancelTokenSource.current.token,
      //   })
      // );
      // await dispatch(
      //   fetchInvoices({
      //     // page: pageRef.current,
      //     organizationUuid: selectOrganization.uuid,
      //     dateIssueStart: startDateRef.current,
      //     dateIssueEnd: endDateRef.current,
      //     launch_status: entryStatus2 as TInvoiceEntryStatus,
      //     has_accounts_payable: payablesRef.current,
      //     has_goods_receipt: goodsEntryRef.current,
      //     search: searchRef.current,
      //     cancelToken: cancelTokenSource.current.token,
      //   })
      // );
      setTimeout(async () => {
        if (endDate) {
          endDateRef.current = endDate;
        }
        console.log(
          "end date ref on fetch after timeout: ",
          endDateRef.current
        );
        await dispatch(
          fetchInvoices({
            page: pageRef.current,
            per_page: itemsPerPageRef.current,
            organizationUuid: selectOrganization.uuid,
            dateIssueStart: startDateRef.current,
            dateIssueEnd: endDateRef.current,
            launch_status: entryStatusRef.current,
            has_accounts_payable: payablesRef.current,
            has_goods_receipt: goodsEntryRef.current,
            search: searchRef.current,
            cancelToken: cancelTokenSource.current.token,
            // mock: nexaasState
            //   ? { type: "resolve", nexaasState: nexaasState }
            //   : undefined,
          })
        ).then(() => {
          apiService
            .getInvoiceUpdatedAt(
              selectOrganization.uuid,
              cancelTokenSource.current.token
            )
            .then((response) => {
              dispatch(
                setInvoicesUpdatedAt({
                  responseStatus: response.status,
                  date: response.data.updated_at,
                })
              );
            });
        });
      }, 50);
    }
  };

  const handleEntryStatusForceSelectOptions = () => {
    const queryStrings = handleParseQuerystrings(window.location.search);

    const hasPayableCondition =
      queryStrings.has_accounts_payable &&
      queryStrings.has_accounts_payable[0] === "true";

    const hasGoodsCondition =
      queryStrings.has_goods_receipt &&
      queryStrings.has_goods_receipt[0] === "true";

    const hasNotEntryCondition =
      queryStrings.has_accounts_payable &&
      queryStrings.has_accounts_payable[0] === "false" &&
      queryStrings.has_goods_receipt &&
      queryStrings.has_goods_receipt[0] === "false";

    if (hasNotEntryCondition) {
      payablesRef.current = false;
      goodsEntryRef.current = false;
      return [0];
    }

    if (hasPayableCondition && hasGoodsCondition) {
      payablesRef.current = true;
      goodsEntryRef.current = true;

      return [1, 2];
    }

    if (hasPayableCondition) {
      payablesRef.current = true;
      goodsEntryRef.current = undefined;

      return [1];
    }

    if (hasGoodsCondition) {
      goodsEntryRef.current = true;
      payablesRef.current = undefined;

      return [2];
    }

    if (queryStrings.period) {
      const tempStartDate = handleParseToDateCalendarOptions(
        queryStrings.period[0] as TCalendarMenuOptions
      );
      if (tempStartDate === undefined) {
        startDateRef.current = undefined;
        endDateRef.current = undefined;
      } else {
        startDateRef.current = format(tempStartDate, "yyyy-MM-dd");
      }

      if (queryStrings.period[0] === "yesterday") {
        endDateRef.current = format(sub(new Date(), { days: 1 }), "yyyy-MM-dd");
      } else {
        endDateRef.current = format(new Date(), "yyyy-MM-dd");
      }
    }

    if (queryStrings.start_date && queryStrings.end_date) {
      // const temp1 = parse(queryStrings.start_date[0], "dd/MM/yyyy", new Date());
      // const temp2 = parse(queryStrings.end_date[0], "dd/MM/yyyy", new Date());

      // startDateRef.current = format(temp1, "yyyy-MM-dd");
      // endDateRef.current = format(temp2, "yyyy-MM-dd");

      startDateRef.current = queryStrings.start_date[0];
      endDateRef.current = queryStrings.end_date[0];
    }

    if (queryStrings.search) searchRef.current = queryStrings.search[0];

    return undefined;
  };

  const handlePeriodForceSelectOptions = () => {
    const queryStrings = handleParseQuerystrings(window.location.search);

    const calendarOptions = [
      ...CALENDAR_CONTENT_OPTIONS.map((string) => string.toLowerCase()),
    ];

    if (
      queryStrings.period &&
      calendarOptions.includes(
        handleRevertTranslateCalendarOptions(
          queryStrings.period[0] as TCalendarMenuOptions
        ) as string
      )
    ) {
      return [
        calendarOptions.indexOf(
          handleRevertTranslateCalendarOptions(
            queryStrings.period[0] as TCalendarMenuOptions
          ) as string
        ),
      ];
    } else if (queryStrings.start_date && queryStrings.end_date) {
      // const temp1 = parse(queryStrings.start_date[0], "dd/MM/yyyy", new Date());
      // const temp2 = parse(queryStrings.end_date[0], "dd/MM/yyyy", new Date());

      // startDateRef.current = format(temp1, "yyyy-MM-dd");
      // endDateRef.current = format(temp2, "yyyy-MM-dd");

      startDateRef.current = queryStrings.start_date[0];
      endDateRef.current = queryStrings.end_date[0];

      return [
        queryStrings.start_date[0].replace(
          /(\d{4})-(\d{2})-(\d{2})/,
          "$3/$2/$1"
        ),
        queryStrings.end_date[0].replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1"),
      ];
    }

    return undefined;
  };

  // const pollVerificationStatus = async (_invoiceId: string) => {
  //   // const poll = () => {
  //   //   cancelTokenSource.current.cancel("from home, canceling");
  //   //   cancelTokenSource.current = axios.CancelToken.source();

  //   //   if (selectOrganization && pollingIntervalIdRef.current) {
  //   //     apiService
  //   //       .getNonCachedGoodsExpressModeStatus(
  //   //         selectOrganization.uuid,
  //   //         invoiceId,
  //   //         cancelTokenSource.current.token,
  //   //         { type: "resolve", model: "mock1" }
  //   //       )
  //   //       .then(() => {
  //   //         fetchInvoicesByAllEntryStatus(mockNexaasStateRef.current);
  //   //         clearInterval(pollingIntervalIdRef.current as number);
  //   //       })
  //   //       .catch(() => {
  //   //         clearInterval(pollingIntervalIdRef.current as number);
  //   //       });
  //   //   }
  //   // };

  //   if (pollingIntervalIdRef.current)
  //     clearInterval(pollingIntervalIdRef.current);
  //   // pollingIntervalIdRef.current = setInterval(poll, 2000);
  // };

  const updateAllInvoicesRef = useCallback(
    async (entryStatus?: number, endDate?: string) => {
      const queryStringParams = getQuerystringParams();

      endDateRef.current = endDate ?? queryStringParams.endDate;
      startDateRef.current = queryStringParams.startDate;
      payablesRef.current = queryStringParams.hasPayable;
      goodsEntryRef.current = queryStringParams.hasGoods;
      pageRef.current = queryStringParams.page;
      itemsPerPageRef.current = queryStringParams.itemsPerPage;
      entryStatusRef.current = queryStringParams.launchStatus as
        | TInvoiceEntryStatus
        | undefined;

      if (selectOrganization) {
        await apiService
          .getAllInvoices(
            {
              organizationUuid: selectOrganization.uuid,
              launch_status: entryStatus ?? entryStatusRef.current,
              date_issue_after: endDateRef.current,
              date_issue_before: startDateRef.current,
              has_accounts_payable: payablesRef.current,
              has_goods_receipt: goodsEntryRef.current,
              page: pageRef.current,
              per_page: itemsPerPageRef.current,
            },
            cancelTokenSource.current.token
          )
          .then((response) => {
            allInvoicesRef.current = response;
            setShouldUpdateComponent(true);
            setTimeout(() => {
              // console.log(
              //   "updating component, all invoices ref: ",
              //   allInvoicesRef.current
              // );
              setShouldUpdateComponent(false);
            }, 40);
          });
      }
    },
    [selectOrganization, window.location.search]
  );

  const handleFilesChange = () => {
    if (selectOrganization) {
      const globalErrors: string[] = [];

      const handleImport = (file: File, type: "danfe" | "xml") => {
        const formData = new FormData();
        formData.append(type, file);
        formData.append("file_name", file.name);

        apiService
          .invoiceImportFiles(
            selectOrganization.uuid,
            formData,
            type,
            (cancelTokenSource.current as CancelTokenSource).token
          )
          .then(() => {
            if (
              globalErrors.length === 0 &&
              filesInputRef.current &&
              filesInputRef.current.files
            ) {
              dispatch(
                setToastState({
                  variant: "success",
                  shouldShow: true,
                  message: {
                    title: "Sucesso",
                    description:
                      filesInputRef.current.files.length > 1
                        ? "Documentos importados"
                        : "Documento importado",
                  },
                })
              );
            }
          })
          .catch((e) => {
            const errorMessage = backendErrorMessageHandler(e);

            if (typeof errorMessage === "string") {
              globalErrors.push(errorMessage);
            }

            dispatch(
              setToastState({
                variant: "error",
                shouldShow: true,
                message: {
                  title: "Erro",
                  description: globalErrors.join("\n"),
                  // typeof errorMessage === "string"
                  //   ? errorMessage
                  //   : "Não foi possível importar o documento, tente novamente",
                },
              })
            );
          });
      };

      if (filesInputRef.current && filesInputRef.current.files) {
        const xmlFiles: File[] = [];
        const pdfFiles: File[] = [];

        Array.from(filesInputRef.current.files).forEach((file) => {
          if (file.type.includes("xml")) {
            xmlFiles.push(file);
          } else if (file.type.includes("pdf")) {
            pdfFiles.push(file);
          }
        });

        Promise.all(
          xmlFiles.map((xml) => {
            handleImport(xml, "xml");
          })
        ).then(() => {
          Promise.all(
            pdfFiles.map((pdf) => {
              handleImport(pdf, "danfe");
            })
          ).then(() => {
            setTimeout(() => {
              fetchInvoicesByAllEntryStatus();
              updateAllInvoicesRef();
            }, 1000);
          });
        });
      }
    }
  };

  useEffect(() => {
    updateAllInvoicesRef();
  }, [updateAllInvoicesRef]);

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate(ROUTES.INVOICES + window.location.search);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (invoiceState) {
      if (payableModalInvoiceRef.current) {
        payableModalInvoiceRef.current = invoiceState.results.find(
          (invoice) =>
            invoice.uuid === (payableModalInvoiceRef.current as TInvoice).uuid
        );
      }

      // invoiceState.results
      //   .filter(
      //     (invoice) => invoice.nexaas_state > 20 && !invoice.nexaas_finished_at
      //   )
      //   .forEach((invoice) => {
      //     pollVerificationStatus(invoice.uuid);
      //   });
    }
  }, [invoiceState, selectedOrganizationWithPermissions]);

  useEffect(() => {
    // return () => {
    //   cancelTokenSource.current.cancel();
    // };
  }, []);

  return (
    <Container>
      <SummarySection>
        <div>
          <div>
            <h1>Notas fiscais emitidas</h1>
            <p>
              <b>CNPJ:&nbsp;</b>
              {selectOrganization && cnpjMask(selectOrganization.cnpj)}
            </p>
          </div>
          {invoicesUpdatedAt.responseStatus !== 204 &&
            invoicesUpdatedAt.date.length > 0 && (
              <p>
                Atualizado em {format(invoicesUpdatedAt.date, "dd/MM/yyyy")} -{" "}
                {format(invoicesUpdatedAt.date, "HH:mm")}
              </p>
            )}
        </div>
        <div className="buttons-container">
          {/* <Button
            icon="plus"
            iconPosition="left"
            variant="outline"
            text="Importar"
            onClick={() => {
              if (filesInputRef.current) filesInputRef.current.click();
            }}
          /> */}
          <div></div>
          <Button
            icon="plus"
            iconPosition="left"
            variant="primary"
            text="Importar notas Fiscais"
            onClick={() => {
              if (filesInputRef.current) filesInputRef.current.click();
            }}
          />
          <input
            type="file"
            multiple
            ref={filesInputRef}
            accept=".pdf, .xml"
            style={{ display: "none" }}
            onChange={handleFilesChange}
          />
        </div>
      </SummarySection>
      <SearchBarSection>
        <SearchBar
          // timeoutAmount={1000}
          onClear={() => {
            searchRef.current = undefined;
            if (selectOrganization) {
              const queryStrings = handleParseQuerystrings(
                window.location.search
              );
              const searchString = handlePopulateQuerystring();
              if (queryStrings.organization_id) {
                searchString.set(
                  "organization_id",
                  queryStrings.organization_id[0]
                );
              }
              searchString.delete("search");

              if (entryStatusRef.current) {
                searchString.set(
                  "launch_status",
                  `${entryStatusRef.current ?? 40}`
                );
              } /* else {
                searchString.delete("launch_status");
              } */

              navigate("./" + handleSortQuerystring(searchString));

              fetchInvoicesByAllEntryStatus();

              // dispatch(
              //   fetchInvoices({
              //     search: searchRef.current,
              //     // page: pageRef.current,
              //     organizationUuid: selectOrganization.uuid,
              //     dateIssueStart: startDateRef.current,
              //     dateIssueEnd: endDateRef.current,
              //     has_accounts_payable: payablesRef.current,
              //     has_goods_receipt: goodsEntryRef.current,
              //     launch_status: entryStatusRef.current,
              //   })
              // );
            }
          }}
          $ref={(ref, setSearchString) => {
            const queryStrings = handleParseQuerystrings(
              window.location.search
            );
            if (queryStrings.search && ref.current) {
              ref.current.value = queryStrings.search[0];
              setSearchString(queryStrings.search[0]);
            }
          }}
          onSearch={(searchPhrase) => {
            searchRef.current = searchPhrase;
            // setTimeout(() => {
            if (selectOrganization) {
              const queryStrings = handleParseQuerystrings(
                window.location.search
              );
              const searchString = handlePopulateQuerystring();
              if (queryStrings.organization_id) {
                searchString.set(
                  "organization_id",
                  queryStrings.organization_id[0]
                );
              }
              if (searchRef.current.length > 0) {
                searchString.set("search", searchRef.current);
              } else {
                searchString.delete("search");
              }

              if (entryStatusRef.current) {
                searchString.set(
                  "launch_status",
                  `${entryStatusRef.current ?? 40}`
                );
              } /* else {
                searchString.delete("launch_status");
              }
 */
              navigate("./" + handleSortQuerystring(searchString));

              fetchInvoicesByAllEntryStatus();

              // dispatch(
              //   fetchInvoices({
              //     search: searchRef.current,
              //     // page: pageRef.current,
              //     organizationUuid: selectOrganization.uuid,
              //     dateIssueStart: startDateRef.current,
              //     dateIssueEnd: endDateRef.current,
              //     has_accounts_payable: payablesRef.current,
              //     has_goods_receipt: goodsEntryRef.current,
              //     launch_status: entryStatusRef.current,
              //   })
              // );
            }
            // }, 500);
          }}
          variant="default"
          placeholder="Buscar por NF-e ou Emitente"
        />
        <SearchBarSectionSubcontainer id={searchBarContainerId}>
          <DropdownFilter
            buttonVariant="calendar"
            menuVariant="calendar"
            onSelect={handleCalendarOnSelect}
            // key={shouldRejectFilledEntryStatus + ""}
            forceSelectOptionsByIndex={(() => {
              const result = handlePeriodForceSelectOptions();
              return shouldRejectFilledEntryStatus ||
                (result && typeof result[0] === "string")
                ? []
                : (result as number[]);
            })()}
            filled={(() => {
              const result = handlePeriodForceSelectOptions();

              return result === undefined
                ? result
                : typeof result[0] === "string"
                ? (result as string[])
                : [CALENDAR_CONTENT_OPTIONS[result[0]]];
            })()}
          />
          <DropdownFilter
            buttonVariant="checkCircle"
            placeholder="Lançamentos"
            content={entryStatusOptions}
            menuVariant="unlabeled"
            search={false}
            customFilter={handleEntryStatusCustomFilter}
            onAction={handleEntryStatusOnAction}
            onCancel={handleEntryStatusOnCancel}
            hasBottomBar
            key={shouldRejectFilledEntryStatus + ""}
            forceSelectOptionsByIndex={(() => {
              const result = handleEntryStatusForceSelectOptions();
              return shouldRejectFilledEntryStatus ? undefined : result;
            })()}
            filled={(() => {
              const result = handleEntryStatusForceSelectOptions();
              return result === undefined
                ? result
                : [...result.toString().replaceAll(",", "")];
            })()}
          />
        </SearchBarSectionSubcontainer>
      </SearchBarSection>
      <MobileSupportContainer>
        <TabsGroup
          initialActiveTabIndex={(() => {
            const queryStrings = handleParseQuerystrings(
              window.location.search
            );

            if (queryStrings.launch_status) {
              switch (queryStrings.launch_status[0]) {
                case "10":
                  entryStatusRef.current = 10;
                  return 1;
                case "20":
                  entryStatusRef.current = 20;
                  return 2;
                case "30":
                  entryStatusRef.current = 30;
                  return 3;
                case "40":
                  entryStatusRef.current = undefined;
                  return 0;
              }
            }

            return 1;
          })()}
          onClickItems={[
            () => {
              handleSetDataToLocalStorage(
                localStorageConstants.CURRENT_ENTRY_STATUS,
                40
              );

              const searchString = handlePopulateQuerystring();
              if (selectOrganization)
                searchString.set("organization_id", selectOrganization.uuid);

              entryStatusRef.current = undefined;
              searchString.set("launch_status", `40`);
              navigate("./" + handleSortQuerystring(searchString));

              const queryStringParams = getQuerystringParams();

              startDateRef.current = queryStringParams.startDate;
              endDateRef.current = queryStringParams.endDate;
              itemsPerPageRef.current = queryStringParams.itemsPerPage;
              pageRef.current = queryStringParams.page;
              searchRef.current = queryStringParams.search;
              payablesRef.current = queryStringParams.hasPayable;
              goodsEntryRef.current = queryStringParams.hasGoods;

              updateAllInvoicesRef();
              if (selectOrganization) {
                dispatch(
                  fetchInvoices({
                    search: searchRef.current,
                    page: pageRef.current,
                    per_page: itemsPerPageRef.current,
                    organizationUuid: selectOrganization.uuid,
                    dateIssueStart: startDateRef.current,
                    dateIssueEnd: endDateRef.current,
                    has_accounts_payable: payablesRef.current,
                    has_goods_receipt: goodsEntryRef.current,
                    cancelToken: cancelTokenSource.current.token,
                  })
                );
              }
            },
            () => {
              if (selectOrganization) {
                updateAllInvoicesRef(10);

                handleSetDataToLocalStorage(
                  localStorageConstants.CURRENT_ENTRY_STATUS,
                  10
                );

                const searchString = handlePopulateQuerystring();
                if (selectOrganization)
                  searchString.set("organization_id", selectOrganization.uuid);

                entryStatusRef.current = 10;
                searchString.set("launch_status", `${entryStatusRef.current}`);
                navigate("./" + handleSortQuerystring(searchString));

                const queryStringParams = getQuerystringParams();

                startDateRef.current = queryStringParams.startDate;
                endDateRef.current = queryStringParams.endDate;
                itemsPerPageRef.current = queryStringParams.itemsPerPage;
                pageRef.current = queryStringParams.page;
                searchRef.current = queryStringParams.search;
                payablesRef.current = queryStringParams.hasPayable;
                goodsEntryRef.current = queryStringParams.hasGoods;

                dispatch(
                  fetchInvoices({
                    search: searchRef.current,
                    page: pageRef.current,
                    per_page: itemsPerPageRef.current,
                    organizationUuid: selectOrganization.uuid,
                    launch_status: 10,
                    dateIssueStart: startDateRef.current,
                    dateIssueEnd: endDateRef.current,
                    has_accounts_payable: payablesRef.current,
                    has_goods_receipt: goodsEntryRef.current,
                    cancelToken: cancelTokenSource.current.token,
                  })
                );
              }
            },
            () => {
              if (selectOrganization) {
                updateAllInvoicesRef(20);

                handleSetDataToLocalStorage(
                  localStorageConstants.CURRENT_ENTRY_STATUS,
                  20
                );

                const searchString = handlePopulateQuerystring();

                if (selectOrganization)
                  searchString.set("organization_id", selectOrganization.uuid);

                entryStatusRef.current = 20;
                searchString.set("launch_status", `${entryStatusRef.current}`);
                navigate("./" + handleSortQuerystring(searchString));

                const queryStringParams = getQuerystringParams();

                startDateRef.current = queryStringParams.startDate;
                endDateRef.current = queryStringParams.endDate;
                itemsPerPageRef.current = queryStringParams.itemsPerPage;
                pageRef.current = queryStringParams.page;
                searchRef.current = queryStringParams.search;
                payablesRef.current = queryStringParams.hasPayable;
                goodsEntryRef.current = queryStringParams.hasGoods;

                dispatch(
                  fetchInvoices({
                    search: searchRef.current,
                    page: pageRef.current,
                    per_page: itemsPerPageRef.current,
                    organizationUuid: selectOrganization.uuid,
                    launch_status: 20,
                    dateIssueStart: startDateRef.current,
                    dateIssueEnd: endDateRef.current,
                    has_accounts_payable: payablesRef.current,
                    has_goods_receipt: goodsEntryRef.current,
                    cancelToken: cancelTokenSource.current.token,
                  })
                );
              }
            },
            () => {
              if (selectOrganization) {
                updateAllInvoicesRef(30);

                handleSetDataToLocalStorage(
                  localStorageConstants.CURRENT_ENTRY_STATUS,
                  30
                );

                const searchString = handlePopulateQuerystring();
                if (selectOrganization)
                  searchString.set("organization_id", selectOrganization.uuid);

                entryStatusRef.current = 30;
                searchString.set("launch_status", `${entryStatusRef.current}`);
                navigate("./" + handleSortQuerystring(searchString));

                const queryStringParams = getQuerystringParams();

                startDateRef.current = queryStringParams.startDate;
                endDateRef.current = queryStringParams.endDate;
                itemsPerPageRef.current = queryStringParams.itemsPerPage;
                pageRef.current = queryStringParams.page;
                searchRef.current = queryStringParams.search;
                payablesRef.current = queryStringParams.hasPayable;
                goodsEntryRef.current = queryStringParams.hasGoods;

                dispatch(
                  fetchInvoices({
                    search: searchRef.current,
                    page: pageRef.current,
                    per_page: itemsPerPageRef.current,
                    organizationUuid: selectOrganization.uuid,
                    launch_status: 30,
                    dateIssueStart: startDateRef.current,
                    dateIssueEnd: endDateRef.current,
                    has_accounts_payable: payablesRef.current,
                    has_goods_receipt: goodsEntryRef.current,
                    cancelToken: cancelTokenSource.current.token,
                  })
                );
              }
            },
          ]}
          stringIcon={[
            { index: 1, value: `${pendingEntryStatusCount}` },
            { index: 2, value: `${doneEntryStatusCount}` },
            { index: 3, value: `${rejectEntryStatusCount}` },
          ]}
        >
          <>
            <h2>Todas</h2>
            <div></div>
          </>
          <>
            <h2>Pendentes</h2>
          </>
          <>
            <h2>Finalizadas</h2>
            <div></div>
          </>
          <>
            <h2>Recusadas</h2>
            <div></div>
          </>
        </TabsGroup>
      </MobileSupportContainer>

      {invoiceState && (
        <TableGrid
          key={shouldUpdateComponent + "-invoices-tablegrid"}
          row="doubleRow"
          columns={{
            tableHead: {
              // 22px 77px 160px 342px 356px 185px 346px 400px 400px 0px
              width: [
                `${(22 * 10) / 258}vw ${(77 * 10) / 258}vw ${
                  (160 * 10) / 258
                }vw ${(342 * 10) / 258}vw ${(356 * 10) / 258}vw ${
                  (185 * 10) / 258
                }vw ${(346 * 10) / 258}vw ${(400 * 10) / 258}vw ${
                  (400 * 10) / 258
                }vw ${(0 * 10) / 258}vw`,
              ],
            },
            tableBody: {
              width: [
                `${(22 * 10) / 258}vw ${(77 * 10) / 258}vw ${
                  (160 * 10) / 258
                }vw ${(342 * 10) / 258}vw ${(356 * 10) / 258}vw ${
                  (185 * 10) / 258
                }vw ${(346 * 10) / 258}vw ${(400 * 10) / 258}vw ${
                  (400 * 10) / 258
                }vw ${(0 * 10) / 258}vw`,
              ],
            },
          }}
          onAction={() => {
            window.location.reload();
          }}
          emptyState={{
            isEmptyState: invoiceState?.results.length === 0,
            title: "Nenhuma nota encontrada",
            description: "Altere os filtros e realize a busca novamente",
          }}
          secondRow={{
            params: invoiceState?.results.map((invoice, index) => ({
              data: secondRowFormatter(invoice) as TCellNotTagMiscellaneous,
              leftSideCustomNode: (
                <>{handleSecondRowLeftSideCustomNodeRender(invoice, index)}</>
              ),
              rightSideCustomNode: (
                <>{handleSecondRowRightSideCustomNodeRender(invoice, index)}</>
              ),
            })),
          }}
          selectType={{ type: "multiSelect", fullData: allInvoicesRef.current }}
          tableHead={{
            value: [
              { style: "noIcon", value: "Número" },
              { style: "noIcon", value: "Data emissão" },
              { style: "noIcon", value: "Emitente" },
              { style: "noIcon", value: "Valor" },
              {
                style: "noIcon",
                value: "Status manifestação",
              },
              { style: "noIcon", value: "Arquivos" },
              { style: "noIcon", value: "Lançamentos" },
              { style: "noIcon", value: "Opções" },
            ],
          }}
          tableBody={(invoiceState?.results ?? []).map(
            (invoice: TInvoice, index: number) => ({
              elementId: invoice.uuid,
              file_nfe: invoice.file_nfe,
              file_xml: invoice.file_xml,
              body: rowFormatter(invoice, index),
            })
          )}
          footer={{
            // pagination: {
            //   buttonsAmount: 30,
            //   onClick(activeIndex) {
            //     // console.log("active index: ", activeIndex);
            //   },
            // },
            leftSideDescription:
              " | Total de " +
              invoiceState.count +
              (invoiceState.count > 1
                ? " registros encontrados"
                : " registro encontrado"),
            // description:
            //   "Mostrando " +
            //   invoiceState.results.length +
            //   " de " +
            //   invoiceState.results.length +
            //   " resultado" +
            //   (invoiceState.results.length > 1 ? "s" : ""),
            onSelectItemsPerPage(amount) {
              const searchString = handlePopulateQuerystring();
              const queryStrings = handleParseQuerystrings(
                window.location.search
              );

              if (queryStrings.page) {
                pageRef.current = parseInt(queryStrings.page[0]);
              }

              if (pageRef.current)
                searchString.set("page", pageRef.current.toString());

              searchString.set("items_per_page", amount.toString());

              navigate("./" + handleSortQuerystring(searchString));

              if (selectOrganization) {
                dispatch(
                  fetchInvoices({
                    search: searchRef.current,
                    page: pageRef.current,
                    per_page: amount,
                    organizationUuid: selectOrganization.uuid,
                    launch_status: entryStatusRef.current,
                    dateIssueStart: startDateRef.current,
                    dateIssueEnd: endDateRef.current,
                    has_accounts_payable: payablesRef.current,
                    has_goods_receipt: goodsEntryRef.current,
                    cancelToken: cancelTokenSource.current.token,
                  })
                );
              }
            },
            customPagination: invoiceState
              ? {
                  isFixedAtTheBottom: true,
                  currentPage: `${invoiceState?.current_page}`,
                  buttonBackward: {
                    disabled: invoiceState.current_page === 1,
                    onClick() {
                      // if (invoiceState.current_page === 1) {
                      //   pageRef.current = undefined;
                      // } else {
                      pageRef.current = invoiceState.current_page - 1;
                      // }

                      const queryStrings = handleParseQuerystrings(
                        window.location.search
                      );

                      if (queryStrings.items_per_page) {
                        itemsPerPageRef.current = parseInt(
                          queryStrings.items_per_page[0]
                        );
                      }

                      handleSetDataToLocalStorage(
                        localStorageConstants.CURRENT_PAGE,
                        pageRef.current
                      );

                      if (
                        selectOrganization &&
                        invoiceState.current_page !== 1
                      ) {
                        const searchString = handlePopulateQuerystring();
                        if (pageRef.current)
                          searchString.set("page", pageRef.current.toString());
                        navigate("./" + handleSortQuerystring(searchString));

                        dispatch(
                          fetchInvoices({
                            search: searchRef.current,
                            page: pageRef.current,
                            per_page: itemsPerPageRef.current,
                            organizationUuid: selectOrganization.uuid,
                            launch_status: entryStatusRef.current,
                            dateIssueStart: startDateRef.current,
                            dateIssueEnd: endDateRef.current,
                            has_accounts_payable: payablesRef.current,
                            has_goods_receipt: goodsEntryRef.current,
                            cancelToken: cancelTokenSource.current.token,
                          })
                        );
                      }
                    },
                  },
                  buttonForward: {
                    disabled:
                      invoiceState.current_page === invoiceState.pages.length,
                    onClick() {
                      if (
                        invoiceState.current_page === invoiceState.pages.length
                      ) {
                        pageRef.current = invoiceState.current_page;
                      } else {
                        pageRef.current = invoiceState.current_page + 1;
                      }

                      const queryStrings = handleParseQuerystrings(
                        window.location.search
                      );

                      if (queryStrings.items_per_page) {
                        itemsPerPageRef.current = parseInt(
                          queryStrings.items_per_page[0]
                        );
                      }

                      handleSetDataToLocalStorage(
                        localStorageConstants.CURRENT_PAGE,
                        pageRef.current
                      );

                      if (
                        selectOrganization &&
                        invoiceState.current_page !== invoiceState.pages.length
                      ) {
                        const searchString = handlePopulateQuerystring();
                        if (pageRef.current)
                          searchString.set("page", pageRef.current.toString());
                        navigate("./" + handleSortQuerystring(searchString));

                        dispatch(
                          fetchInvoices({
                            search: searchRef.current,
                            page: pageRef.current,
                            per_page: itemsPerPageRef.current,
                            organizationUuid: selectOrganization.uuid,
                            launch_status: entryStatusRef.current,
                            dateIssueStart: startDateRef.current,
                            dateIssueEnd: endDateRef.current,
                            has_accounts_payable: payablesRef.current,
                            has_goods_receipt: goodsEntryRef.current,
                            cancelToken: cancelTokenSource.current.token,
                          })
                        );
                      }
                    },
                  },
                  pages: invoiceState.pages.map((page) => {
                    return {
                      value: Object.keys(page)[0],
                      onClick() {
                        let temp =
                          Object.values(page)[0].match(/.*?page=(\d+)&per_/);

                        if (temp !== null && temp[1]) {
                          pageRef.current = parseInt(temp[1]);
                        } else {
                          pageRef.current = 1;
                        }

                        const queryStrings = handleParseQuerystrings(
                          window.location.search
                        );
                        if (queryStrings.items_per_page) {
                          itemsPerPageRef.current = parseInt(
                            queryStrings.items_per_page[0]
                          );
                        }

                        handleSetDataToLocalStorage(
                          localStorageConstants.CURRENT_PAGE,
                          pageRef.current
                        );

                        if (selectOrganization) {
                          const searchString = handlePopulateQuerystring();
                          if (pageRef.current)
                            searchString.set(
                              "page",
                              pageRef.current.toString()
                            );
                          navigate("./" + handleSortQuerystring(searchString));

                          dispatch(
                            fetchInvoices({
                              search: searchRef.current,
                              page: pageRef.current,
                              per_page: itemsPerPageRef.current,
                              organizationUuid: selectOrganization.uuid,
                              launch_status: entryStatusRef.current,
                              dateIssueStart: startDateRef.current,
                              dateIssueEnd: endDateRef.current,
                              has_accounts_payable: payablesRef.current,
                              has_goods_receipt: goodsEntryRef.current,
                              cancelToken: cancelTokenSource.current.token,
                            })
                          );
                        }
                      },
                    };
                  }),
                }
              : undefined,
          }}
        />
      )}

      {shouldOpenPayable && (
        <Modal
          key={shouldOpenPayable + "-payable"}
          shouldOpen={shouldOpenPayable}
          onClose={() => {
            setShouldOpenPayable(false);
          }}
          icon={{ type: "dolarSign", color: "rose" }}
          title="Lançar contas a pagar"
          main={{
            style: "textOnly",
            content: [
              // { style: "default", text: "", shouldBreakLine: true },
              {
                style: "default",
                text: "Deseja criar uma nova conta a pagar ou vincular a nota fiscal a uma conta já criada na Fintera?",
                shouldBreakLine: true,
              },
              // { style: "default", text: "", shouldBreakLine: true },
            ],
          }}
          footer={{
            style: "default",
            buttons: [
              {
                iconPosition: "left",
                icon: "link",
                variant: "outline",
                text: "Vincular",
                onClick() {
                  setShouldOpenPayable(false);
                  setShouldOpenLinkPayableModal(true);
                },
              },
              {
                iconPosition: "left",
                icon: "plus",
                variant: "primary",
                text: "Criar nova",
                onClick() {
                  setShouldOpenPayable(false);
                  setShouldOpenPayableModal(true);
                },
              },
            ],
          }}
        />
      )}
      {shouldOpenPayableModal && (
        <Modal
          key={shouldOpenPayableModal + "-payableModal"}
          shouldOpen={shouldOpenPayableModal}
          icon={{ type: "dolarSign", color: "rose" }}
          title="Lançar nota como contas a pagar"
          onClose={() => {
            setShouldOpenPayableModal(false);
          }}
          main={{
            style: "form",
            content: {
              preset: "addPayable",
              invoice: payableModalInvoiceRef.current as TInvoice,
              footer: {
                buttons: [
                  {
                    icon: "noIcon",
                    text: "Cancelar",
                    variant: "secondary",
                    onClick() {
                      setShouldOpenPayableModal(false);
                    },
                  },
                  {
                    iconPosition: "left",
                    icon: "checkmark",
                    text: "Lançar conta a pagar",
                    variant: "primary",
                    onClick() {
                      fetchInvoicesByAllEntryStatus();
                      setShouldOpenPayableModal(false);
                    },
                  },
                ],
              },
            },
          }}
        />
      )}
      {shouldOpenLinkPayableModal && (
        <Modal
          key={shouldOpenLinkPayableModal + "-linkPayableModal"}
          shouldOpen={shouldOpenLinkPayableModal}
          icon={{ type: "link", color: "rose" }}
          title="Vincular nota a conta já lançada"
          onClose={() => {
            setShouldOpenLinkPayableModal(false);
          }}
          main={{
            style: "form",
            content: {
              preset: "linkPayable",
              invoice: payableModalInvoiceRef.current as TInvoice,
              footer: {
                buttons: [
                  {
                    icon: "noIcon",
                    text: "Cancelar",
                    variant: "secondary",
                    onClick() {
                      setShouldOpenLinkPayableModal(false);
                    },
                  },
                  {
                    iconPosition: "left",
                    icon: "checkmark",
                    text: "Vincular nota como anexo",
                    variant: "primary",
                    isLoading: isLinkPayableLoading,

                    onClick(selectRow) {
                      if (selectRow.hasInstallments) {
                        selectPayableRef.current = selectRow.selectPayable;
                        setShouldOpenLinkPayableModal(false);
                        setShouldOpenInstallmentAccountModal(true);
                      } else {
                        if (payableModalInvoiceRef.current) {
                          setIsLinkPayableLoading(true);

                          apiService
                            .setLinkInvoiceToPayable(
                              payableModalInvoiceRef.current.uuid,
                              selectRow.selectPayable.id,
                              cancelTokenSource.current.token
                            )
                            .then((_response) => {
                              setIsLinkPayableLoading(false);
                              fetchInvoicesByAllEntryStatus();
                              dispatch(
                                setToastState({
                                  variant: "success",
                                  shouldShow: true,
                                  message: {
                                    title: "Sucesso",
                                    description:
                                      "Nota vinculada à contas a pagar",
                                  },
                                })
                              );
                              setShouldOpenLinkPayableModal(false);
                            })
                            .catch((e) => {
                              const errorMessage =
                                backendErrorMessageHandler(e);
                              console.log("error: ", e);
                              setIsLinkPayableLoading(false);
                              dispatch(
                                setToastState({
                                  variant: "error",
                                  shouldShow: true,
                                  message: {
                                    title: "Erro ao vincular nota",
                                    description:
                                      typeof errorMessage === "string"
                                        ? errorMessage
                                        : "Ocorreu um erro ao vincular a nota à Contas a Pagar, tente novamente",
                                  },
                                })
                              );
                              setShouldOpenLinkPayableModal(false);
                            });
                        }
                      }
                    },
                  },
                ],
              },
            },
          }}
        />
      )}
      {shouldOpenInstallmentAccountModal && (
        <Modal
          shouldOpen={shouldOpenInstallmentAccountModal}
          onClose={() => {
            setShouldOpenInstallmentAccountModal(false);
          }}
          icon={{ type: "link", color: "rose" }}
          title="Vincular à conta parcelada"
          main={{
            style: "form",
            content: {
              preset: "installmentAccount",
              invoice: payableModalInvoiceRef.current as TInvoice,
              footer: {
                buttons: [
                  {
                    variant: "secondary",
                    icon: "noIcon",
                    text: "Cancelar",
                    onClick() {
                      setShouldOpenInstallmentAccountModal(false);
                    },
                  },
                  {
                    variant: "primary",
                    iconPosition: "left",
                    icon: "checkmark",
                    text: "Vincular nota como anexo",
                    isLoading: isLinkPayableLoading,
                    onClick() {
                      if (
                        payableModalInvoiceRef.current &&
                        selectPayableRef.current
                      ) {
                        setIsLinkPayableLoading(true);

                        apiService
                          .setLinkInvoiceToPayable(
                            payableModalInvoiceRef.current.uuid,
                            `${selectPayableRef.current.id}`,
                            cancelTokenSource.current.token
                          )
                          .then((_response) => {
                            setIsLinkPayableLoading(false);
                            fetchInvoicesByAllEntryStatus();
                            dispatch(
                              setToastState({
                                variant: "success",
                                shouldShow: true,
                                message: {
                                  title: "Sucesso",
                                  description:
                                    "Nota vinculada à contas a pagar",
                                },
                              })
                            );
                            setShouldOpenInstallmentAccountModal(false);
                          })
                          .catch((e) => {
                            const errorMessage = backendErrorMessageHandler(e);
                            console.log("error: ", e);
                            setIsLinkPayableLoading(false);
                            dispatch(
                              setToastState({
                                variant: "error",
                                shouldShow: true,
                                message: {
                                  title: "Erro ao vincular nota",
                                  description:
                                    typeof errorMessage === "string"
                                      ? errorMessage
                                      : "Ocorreu um erro ao vincular a nota à Contas a Pagar, tente novamente",
                                },
                              })
                            );
                            setShouldOpenInstallmentAccountModal(false);
                          });
                      }
                    },
                  },
                ],
              },
            },
          }}
        />
      )}
      {shouldOpenGoodsModal && (
        <Modal
          key={shouldOpenGoodsModal + "-goodsModal"}
          shouldOpen={shouldOpenGoodsModal}
          onClose={() => {
            setShouldOpenGoodsModal(false);
          }}
          icon={{ type: "import", color: "rose" }}
          title="Criar entrada de mercadorias"
          main={{
            style: "form",
            content: {
              preset: "goods",
              invoice: payableModalInvoiceRef.current as TInvoice,
              footer: {
                buttons: [
                  {
                    variant: "secondary",
                    icon: "noIcon",
                    text: "Cancelar",
                    onClick() {
                      setShouldOpenGoodsModal(false);
                    },
                  },
                  {
                    variant: "primary",
                    iconPosition: "left",
                    icon: "checkmark",
                    text: "Criar entrada de mercadorias",
                    onClick(isExpressMode: boolean) {
                      setShouldOpenGoodsModal(false);

                      if (isExpressMode) {
                        setShouldOpenGoodsAutomatedModal(true);
                        setTimeout(() => {
                          // mockNexaasStateRef.current = 30;
                          fetchInvoicesByAllEntryStatus();
                          // mockNexaasStateRef.current
                        }, 100);
                      } else {
                        fetchInvoicesByAllEntryStatus();
                      }
                    },
                  },
                ],
              },
            },
          }}
        />
      )}
      {shouldOpenGoodsAutomatedModal && (
        <Modal
          key={shouldOpenGoodsAutomatedModal + "-goodsAutomatedModal"}
          shouldOpen={shouldOpenGoodsAutomatedModal}
          onClose={() => {
            setShouldOpenGoodsAutomatedModal(false);
          }}
          icon={{ type: "import", color: "rose" }}
          title="Criar entrada de mercadorias"
          main={{
            style: "form",
            content: {
              preset: "goodsAutomated",
              invoice: payableModalInvoiceRef.current as TInvoice,
              footer: {
                buttons: [
                  {
                    variant: "white",
                    icon: "noIcon",
                    text: "",
                    onClick(response) {
                      if (response.shouldUpdate) {
                        fetchInvoicesByAllEntryStatus();
                      }

                      if (response.data) {
                        goodsStockCheckDataRef.current = response.data;
                        setShouldOpenGoodsAutomatedModal(false);
                        setShouldOpenGoodsStockCheckModal(true);
                      }
                    },
                  },
                ],
              },
            },
          }}
        />
      )}
      {goodsStockCheckDataRef.current &&
        payableModalInvoiceRef.current &&
        shouldOpenGoodsStockCheckModal && (
          <Modal
            key={shouldOpenGoodsStockCheckModal + "-goodsStockCheckModal"}
            shouldOpen={shouldOpenGoodsStockCheckModal}
            onClose={() => {
              setShouldOpenGoodsStockCheckModal(false);
            }}
            icon={{ type: "import", color: "rose" }}
            title="Conferência de quantidades"
            main={{
              style: "form",
              content: {
                preset: "goodsStockCheck",
                data: {
                  ...goodsStockCheckDataRef.current,
                  invoiceId: payableModalInvoiceRef.current.uuid,
                },
                footer: {
                  buttons: [
                    {
                      variant: "white",
                      icon: "sync",
                      iconPosition: "left",
                      text: "Atualizar lista",
                      style: {
                        padding: "8px",
                        color: "var(--color-main-blue)",
                        fontFamily: "var(--font-paragraph-default)",
                      },
                      onClick() {},
                    },
                    {
                      variant: "secondary",
                      icon: "noIcon",
                      text: "Cancelar",
                      onClick() {
                        setShouldOpenGoodsStockCheckModal(false);
                      },
                    },
                    {
                      variant: "primary",
                      iconPosition: "left",
                      icon: "checkmark",
                      text: "Confirmar entrada",
                      onClick() {
                        // mockNexaasStateRef.current = 50;

                        setTimeout(() => {
                          setShouldOpenGoodsStockCheckModal(false);
                          setShouldOpenGoodsAutomatedModal(true);
                          fetchInvoicesByAllEntryStatus();
                        }, 4000);

                        if (
                          selectOrganization &&
                          payableModalInvoiceRef.current
                        ) {
                          apiService
                            .createExpressGoodsStockEntry(
                              selectOrganization.uuid,
                              payableModalInvoiceRef.current.uuid,
                              cancelTokenSource.current.token
                              // { type: "resolve" }
                            )
                            .then(() => {
                              dispatch(
                                setToastState({
                                  variant: "information",
                                  shouldShow: true,
                                  message: {
                                    title: "Lançamento em andamento",
                                    description:
                                      "O estoque está sendo lançado em segundo plano e pode levar alguns minutos",
                                  },
                                })
                              );
                            })
                            .catch((e) => {
                              const errorMessage =
                                backendErrorMessageHandler(e);

                              dispatch(
                                setToastState({
                                  variant: "error",
                                  shouldShow: true,
                                  message: {
                                    title: "Erro ao lançar estoque",
                                    description:
                                      typeof errorMessage === "string"
                                        ? errorMessage
                                        : "Ocorreu um erro ao lançar o estoque, tente novamente",
                                  },
                                })
                              );

                              fetchInvoicesByAllEntryStatus();
                            });
                        }
                      },
                    },
                  ],
                },
              },
            }}
          />
        )}
      {shouldOpenLinkPaymentSlipModal && (
        <Modal
          shouldOpen={shouldOpenLinkPaymentSlipModal}
          onClose={() => {
            if (clearSelectedPaymentSlipsRef.current) {
              clearSelectedPaymentSlipsRef.current();
            }
            setInvoiceToAttachLinks(null);
            setShouldOpenLinkPaymentSlipModal(false);
          }}
          icon={{ type: "link", color: "rose" }}
          title="Selecionar boletos"
          main={{
            style: "form",
            content: {
              preset: "linkPaymentSlip",
              footer: {
                buttons: [
                  {
                    variant: "secondary",
                    icon: "noIcon",
                    text: "Cancelar",
                    onClick() {
                      if (clearSelectedPaymentSlipsRef.current) {
                        clearSelectedPaymentSlipsRef.current();
                      }
                      setInvoiceToAttachLinks(null);
                      setShouldOpenLinkPaymentSlipModal(false);
                    },
                  },
                  {
                    variant: "primary",
                    iconPosition: "left",
                    icon: "checkmark",
                    text: "Confirmar selecionado(s)",
                    isLoading: isLinkPayableLoading,
                    onClick(data: {
                      selectedPaymentSlips: TPaymentSlip[];
                      clearSelectedPaymentSlips: () => void;
                    }) {
                      clearSelectedPaymentSlipsRef.current =
                        data.clearSelectedPaymentSlips;

                      data.selectedPaymentSlips.forEach(
                        (paymentSlip, index) => {
                          if (invoiceToAttachLinks) {
                            setIsLinkPayableLoading(true);

                            apiService
                              .attachPaymentSlipToInvoice(
                                invoiceToAttachLinks.uuid,
                                paymentSlip.uuid,
                                cancelTokenSource.current.token
                              )
                              .then((_response) => {
                                if (
                                  index ===
                                  data.selectedPaymentSlips.length - 1
                                ) {
                                  setIsLinkPayableLoading(false);
                                  fetchInvoicesByAllEntryStatus();
                                  dispatch(
                                    setToastState({
                                      variant: "success",
                                      shouldShow: true,
                                      message: {
                                        title: "Sucesso",
                                        description:
                                          data.selectedPaymentSlips.length > 1
                                            ? "Notas vinculadas à conta a pagar"
                                            : "Nota vinculada à contas a pagar",
                                      },
                                    })
                                  );
                                  setInvoiceToAttachLinks(null);
                                  data.clearSelectedPaymentSlips();
                                  setShouldOpenLinkPaymentSlipModal(false);
                                }
                              })
                              .catch((e) => {
                                const errorMessage =
                                  backendErrorMessageHandler(e);
                                console.log("error: ", e);
                                setIsLinkPayableLoading(false);
                                dispatch(
                                  setToastState({
                                    variant: "error",
                                    shouldShow: true,
                                    message: {
                                      title: "Erro ao vincular nota",
                                      description:
                                        typeof errorMessage === "string"
                                          ? errorMessage
                                          : "Ocorreu um erro ao vincular a nota à Contas a Pagar, tente novamente",
                                    },
                                  })
                                );
                                setInvoiceToAttachLinks(null);
                                data.clearSelectedPaymentSlips();
                                setShouldOpenLinkPaymentSlipModal(false);
                              });
                          }
                        }
                      );
                    },
                  },
                ],
              },
            },
          }}
        />
      )}
      {paymentSlipToBreakLink !== undefined && (
        <Modal
          key={paymentSlipToBreakLink?.target.uuid}
          title="Remover vínculo"
          icon={{ type: "breakLink", color: "red" }}
          main={{
            style: "textOnly",
            content: [
              {
                text: "Tem certeza que deseja remover o vínculo com este boleto?",
                style: "default",
                shouldBreakLine: false,
              },
            ],
          }}
          footer={{
            style: "default",
            buttons: [
              {
                variant: "secondary",
                icon: "noIcon",
                text: "Cancelar",
                onClick() {
                  setPaymentSlipToBreakLink(undefined);
                },
              },
              {
                variant: "red",
                iconPosition: "left",
                icon: "checkmark",
                text: "Sim, remover",
                onClick() {
                  if (paymentSlipToBreakLink) {
                    apiService
                      .detachPaymentSlipFromInvoice(
                        paymentSlipToBreakLink.origin.uuid,
                        paymentSlipToBreakLink.target.uuid,
                        cancelTokenSource.current.token
                      )
                      .then(() => {
                        setPaymentSlipToBreakLink(undefined);
                        dispatch(
                          setToastState({
                            variant: "success",
                            shouldShow: true,
                            message: {
                              title: "Sucesso",
                              description: "Vínculo removido",
                            },
                          })
                        );
                        fetchInvoicesByAllEntryStatus();
                        fetchPaymentSlipsByAllEntryStatus();
                      })
                      .catch((e) => {
                        const errorMessage = backendErrorMessageHandler(e);

                        setPaymentSlipToBreakLink(undefined);
                        dispatch(
                          setToastState({
                            variant: "error",
                            shouldShow: true,
                            message: {
                              title: "Erro",
                              description:
                                typeof errorMessage === "string"
                                  ? errorMessage
                                  : "Não foi possível remover o vínculo, tente novamente",
                            },
                          })
                        );
                      });
                  }
                },
              },
            ],
          }}
          shouldOpen={paymentSlipToBreakLink !== undefined}
          onClose={() => {
            setPaymentSlipToBreakLink(undefined);
          }}
        />
      )}
      {paymentSlipToRename !== undefined && (
        <Modal
          key={paymentSlipToRename?.uuid}
          title="Renomear arquivo"
          icon={{ type: "edit", color: "rose" }}
          main={{
            style: "form",
            content: {
              preset: "renameDocument",
              data: paymentSlipToRename,
              footer: {
                buttons: [
                  {
                    variant: "secondary",
                    icon: "noIcon",
                    text: "Cancelar",
                    onClick() {
                      setPaymentSlipToRename(undefined);
                    },
                  },
                  {
                    variant: "primary",
                    iconPosition: "left",
                    icon: "checkmark",
                    text: "Salvar",
                    onClick(label: string) {
                      if (paymentSlipToRename) {
                        apiService
                          .renamePaymentSlipLabel(
                            paymentSlipToRename.uuid,
                            label,
                            cancelTokenSource.current.token
                          )
                          .then(() => {
                            setPaymentSlipToRename(undefined);
                            dispatch(
                              setToastState({
                                variant: "success",
                                shouldShow: true,
                                message: {
                                  title: "Sucesso",
                                  description: "Boleto renomeado",
                                },
                              })
                            );
                            fetchInvoicesByAllEntryStatus();
                            fetchPaymentSlipsByAllEntryStatus();
                          })
                          .catch((e) => {
                            const errorMessage = backendErrorMessageHandler(e);
                            setPaymentSlipToRename(undefined);
                            dispatch(
                              setToastState({
                                variant: "error",
                                shouldShow: true,
                                message: {
                                  title: "Erro",
                                  description:
                                    typeof errorMessage === "string"
                                      ? errorMessage
                                      : "Não foi possível renomear o boleto, tente novamente",
                                },
                              })
                            );
                          });
                      }
                    },
                  },
                ],
              },
            },
          }}
          shouldOpen={paymentSlipToRename !== undefined}
          onClose={() => {
            setPaymentSlipToRename(undefined);
          }}
        />
      )}
    </Container>
  );
}

export default Home;
