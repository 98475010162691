import styled from "styled-components";
import Dropdown from "../../Dropdown";
import apiService from "../../../../services/api";
import { useEffect, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { TOrganization } from "../../../../redux/reducers/organizations";
import { backendErrorMessageHandler } from "../../../../utils/miscellaneous";
import { setToastState } from "../../../../redux/reducers/globalState";
import { useTypedDispatch } from "../../../../hooks/useTypedDispatch";
import Button from "../../Button";

const Container = styled.div`
  width: 350px;
  height: 150px;
  max-height: 150px;
  overflow-y: auto;
  padding: 16px;
  margin: 0px;
  position: absolute;
  z-index: 2;
  transition: z-index 0.15s ease-in-out;
  background: var(--color-light-0);
  border-radius: 8px;
  border: 1px solid var(--color-dark-blue-300);

  & > h1 {
    font-size: 14px;
    font-family: var(--font-paragraph-bold);
    color: var(--color-text-paragraph);

    margin-bottom: 12px;
  }

  & button p.placeholder {
    color: var(--color-text-paragraph);
  }

  & button p.content {
    top: 14px;
  }

  & button::before {
    visibility: hidden;
  }

  & > div:first-of-type {
    width: 100%;
  }
`;

const ActionArea = styled.div`
  display: flex;
  border-top: 1px solid var(--color-light-200);
  margin-top: 12px;
  justify-content: flex-end;

  button {
    margin-top: 16px;
    margin-left: 10px;
  }
`;

type TSetDefaultGoodsStockMenuProps = {
  style: React.CSSProperties;
  selectOrganization: TOrganization;
  onCancel: () => void;
};

const SetDefaultGoodsStockMenu: React.FC<TSetDefaultGoodsStockMenuProps> = ({
  style,
  selectOrganization,
  onCancel,
}: TSetDefaultGoodsStockMenuProps) => {
  const axiosCancelTokenRef = useRef<CancelTokenSource | null>(null);

  const [goodsStockList, setGoodsStockList] = useState<
    { id: number; name: string }[]
  >([]);

  const selectedGoodsStockRef = useRef<(typeof goodsStockList)[0] | null>(null);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [shouldUpdateComponent, setShouldUpdateComponent] = useState(false);

  const dispatch = useTypedDispatch();

  const fetchData = () => {
    axiosCancelTokenRef.current = axios.CancelToken.source();

    apiService
      .getGoodsStock(selectOrganization.uuid, axiosCancelTokenRef.current.token)
      .then((response) => {
        if (response) {
          setGoodsStockList(response.data);
          // setGoodsStockList([
          //   {
          //     id: 890,
          //     name: "DEPOSITO PERDA",
          //   },
          //   {
          //     id: 821,
          //     name: "Depósito - BISCOITE MORUMBI QSQ",
          //   },
          // ]);
        }
      });
  };

  useEffect(() => {
    fetchData();

    return () => {
      if (axiosCancelTokenRef.current) {
        axiosCancelTokenRef.current.cancel();
      }
    };
  }, []);

  useEffect(() => {}, [selectOrganization]);

  return (
    <Container style={style}>
      <h1>Alterar estoque padrão de mercadoria</h1>
      <Dropdown
        key={goodsStockList.length || `${shouldUpdateComponent}`}
        button={{
          variant: "labeled",
          labels: [""],
          placeholder:
            /* selectedGoodsStockRef.current &&
            selectedGoodsStockRef.current.name.length > 0
              ? ""
              : */ goodsStockList.length > 0
              ? goodsStockList[
                  goodsStockList.findIndex(
                    (element) =>
                      `${element.id}` ===
                      selectOrganization.default_nexaas_stock_id
                  )
                ]?.name ?? goodsStockList[0].name
              : "",
          setData(_setSelectedData) {
            if (!selectedGoodsStockRef.current && goodsStockList.length > 0) {
              selectedGoodsStockRef.current =
                goodsStockList[
                  goodsStockList.findIndex(
                    (element) =>
                      `${element.id}` ===
                      selectOrganization.default_nexaas_stock_id
                  )
                ] ?? goodsStockList[0];

              setTimeout(() => {
                setShouldUpdateComponent(true);
              }, 100);
              setTimeout(() => {
                setShouldUpdateComponent(false);
              }, 150);
            }
          },
        }}
        menu={{
          variant: "unlabeled",
          options: goodsStockList.map((stock) => stock.name),
          search: false,
          onSelect(selected) {
            selectedGoodsStockRef.current =
              goodsStockList[
                goodsStockList.findIndex(
                  (element) => `${element.name}` === selected
                )
              ];

            setTimeout(() => {
              setShouldUpdateComponent(true);
            }, 100);
            setTimeout(() => {
              setShouldUpdateComponent(false);
            }, 150);
          },
        }}
      />
      <ActionArea>
        <Button
          variant="outline"
          text="Cancelar"
          icon="noIcon"
          onClick={() => {
            onCancel();
          }}
        />
        <Button
          variant="primary"
          text="Salvar alteração"
          icon="checkmark"
          iconPosition="left"
          disabled={
            selectedGoodsStockRef.current
              ? parseInt(selectOrganization.default_nexaas_stock_id) ===
                selectedGoodsStockRef.current.id
              : false
          }
          loading={isButtonLoading}
          onClick={() => {
            setIsButtonLoading(true);

            if (axiosCancelTokenRef.current && selectedGoodsStockRef.current) {
              apiService
                .updateDefaultGoodsStockId(
                  selectOrganization.uuid,
                  selectedGoodsStockRef.current.id,
                  axiosCancelTokenRef.current.token
                )
                .then((response) => {
                  if (response?.data)
                    dispatch(
                      setToastState({
                        variant: "success",
                        shouldShow: true,
                        message: {
                          title: "Sucesso",
                          description: "Estoque padrão alterado",
                        },
                      })
                    );
                  setTimeout(() => {
                    window.location.reload();
                  }, 1700);
                })
                .catch((e) => {
                  const errorMessage = backendErrorMessageHandler(e);

                  dispatch(
                    setToastState({
                      variant: "error",
                      shouldShow: true,
                      message: {
                        title: "Erro ao alterar estoque padrão",
                        description:
                          typeof errorMessage === "string"
                            ? errorMessage
                            : "Ocorreu um erro ao alterar estoque padrão, tente novamente",
                      },
                    })
                  );
                })
                .finally(() => {
                  setIsButtonLoading(false);
                  onCancel();
                });
            }
          }}
        />
      </ActionArea>
    </Container>
  );
};

export default SetDefaultGoodsStockMenu;
