import styled from "styled-components";
import Button from "./Button";
import { useCallback, useEffect, useRef, useState } from "react";
import Menu, { TOption } from "./Menu";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../router";
import {
  handleGetDataFromLocalStorage,
  handleParseQuerystrings,
} from "../../../utils/miscellaneous";
import { localStorageConstants } from "../../../utils/constants";
import { useTypedDispatch } from "../../../hooks/useTypedDispatch";
import { setLogoutModalState } from "../../../redux/reducers/globalState";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

type TDropdownUserProps = {
  name: string;
  email: string;
  icon: string;
};

const DropdownUser: React.FC<TDropdownUserProps> = ({ name, email, icon }) => {
  const dispatch = useTypedDispatch();

  const { selectedOrganizationWithPermissions } = useTypedSelector(
    (state) => state.globalState.data
  );

  const componentRef = useRef<HTMLDivElement | null>(null);

  const [accountsCount, setAccountsCount] = useState<number | null>(null);

  const [shouldShow, setShouldShow] = useState<"show" | "hide">("hide");

  const [dropDownUserOptions, setDropdownUserOptions] = useState<TOption[]>([
    "id",
    "config",
    "logout",
  ]);
  const navigate = useNavigate();

  const handleRenderDropdown = useCallback(() => {
    const handleOnClickButton = () => {
      setShouldShow((prev) => (prev === "hide" ? "show" : "hide"));
    };

    const handleOnBlur = () => {
      setShouldShow("hide");
    };

    const handleOnClickMenu = (
      index: string,
      _selected: string[],
      setSelected: React.Dispatch<React.SetStateAction<string[]>>
    ) => {
      const value = dropDownUserOptions[parseInt(index)];

      switch (value) {
        case "id":
          window.open("https://id.nexaas.com/", "_blank");
          break;
        case "config":
          const queryStrings = handleParseQuerystrings(window.location.search);
          let searchParam = "";
          if (queryStrings.organization_id)
            searchParam = "?organization_id=" + queryStrings.organization_id[0];
          navigate(ROUTES.SETTINGS + searchParam);
          break;
        case "swap":
          // localStorage.removeItem(localStorageConstants.SELECT_ORGANIZATION);
          // localStorage.removeItem(localStorageConstants.SELECT_ACCOUNT);
          navigate(ROUTES.ACCOUNTS);
          break;
        case "logout":
          dispatch(setLogoutModalState({ shouldShow: true }));
          break;
      }
      handleOnBlur();
      setSelected([index]);
    };

    return (
      <Container ref={componentRef}>
        <Button
          onBlur={handleOnBlur}
          onClick={handleOnClickButton}
          name={name}
          email={email}
          icon={icon}
          componentRef={componentRef}
        />
        <Menu
          accountsCount={accountsCount ?? 0}
          shouldShow={shouldShow}
          options={dropDownUserOptions}
          onClick={handleOnClickMenu}
        />
      </Container>
    );
  }, [shouldShow, name]);

  useEffect(() => {
    if (
      selectedOrganizationWithPermissions &&
      !selectedOrganizationWithPermissions.permissions
        .organization_management &&
      !selectedOrganizationWithPermissions.permissions.user_management
    ) {
      setDropdownUserOptions((prev) => {
        // console.log("prev: ", prev);
        if (prev.includes("config")) prev.splice(1, 1);
        return [...prev];
      });
    }
  }, [selectedOrganizationWithPermissions]);

  useEffect(() => {
    setTimeout(() => {
      const localAccountCount = handleGetDataFromLocalStorage(
        localStorageConstants.ACCOUNTS_COUNT
      ) as number;

      if (localAccountCount) {
        if (localAccountCount > 1)
          setDropdownUserOptions(["id", "config", "swap", "logout"]);

        setAccountsCount(localAccountCount);
      }
    }, 1000);
  }, []);

  return handleRenderDropdown();
};

export default DropdownUser;
