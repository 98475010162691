import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchOrganizations } from "../../actions";

export type TOrganization = {
  account: { uuid: string };
  cnpj: string;
  created_at: string;
  is_active: boolean;
  default_nexaas_stock_id: string;
  company_name: string;
  updated_at: string;
  uuid: string;
  permissions: {
    fintera: boolean;
    nexaas_omni: boolean;
    organization_management: boolean;
    user_management: boolean;
  };
};

export type TOrganizationData = {
  count: number;
  current_page: number;
  pages: { [key: number]: string }[];
  results: TOrganization[];
};

type TInitialState = {
  loading: boolean;
  error: null | string;
  data: TOrganizationData;
};

const initialState: TInitialState = {
  loading: false,
  error: "",
  data: {
    count: -1,
    current_page: 0,
    pages: [],
    results: [],
  },
};

const organizationsDataSlice = createSlice({
  name: "organizations",
  initialState: initialState,
  reducers: {
    setOrganizations: (state, action: PayloadAction<TOrganizationData>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizations.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchOrganizations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "error";
      });
  },
});

export const { setOrganizations } = organizationsDataSlice.actions;
export default organizationsDataSlice.reducer;
