import { UilBuilding, UilUsersAlt } from "@iconscout/react-unicons";
import styled from "styled-components";

const Container = styled.button<{ $variant: TVariants }>`
  width: calc(548vw / 14.4);
  max-width: 550px;
  height: 146px;

  cursor: pointer;

  overflow: hidden;

  position: relative;

  border-radius: 16px;
  border: 1px solid var(--color-dark-blue-300);

  background: var(--color-light-0);

  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #f8f8f8 100%
    );
  }

  p {
    margin-left: 24px;
    text-align: left;
  }

  p.title {
    font-family: var(--font-title-bold);
    font-size: 18px;
    color: ${({ $variant }) =>
      $variant === "orgs" ? `var(--color-main-rose)` : `var(--color-main-blue)`};
  }

  p.description {
    font-family: var(--font-paragraph-default);
    font-size: 12px;
    color: var(--color-dark-blue-400);
  }

  svg {
    position: absolute;

    ${({ $variant }) =>
      $variant === "orgs"
        ? `fill: var(--color-main-rose);` +
          ` height: 150px;` +
          ` width: 150px;` +
          `top: 20px;` +
          `right: -40px;`
        : `fill: var(--color-main-blue);` +
          ` height: 150px;` +
          ` width: 150px;` +
          `top: 20px;` +
          `right: -40px;`};
  }
`;

type TVariants = "orgs" | "users";

type TExtraLargeButtonProps = {
  variant: TVariants;
  onClick: () => void;
};

const ExtraLargeButton: React.FC<TExtraLargeButtonProps> = ({
  variant,
  onClick,
}) => {
  const handleVariant = () => {
    switch (variant) {
      case "orgs":
        return (
          <>
            <p className="title">Gerenciar organizações</p>
            <p className="description">Suas unidades de negócio no Portal</p>
            <UilBuilding />
          </>
        );
      case "users":
        return (
          <>
            <p className="title">Gerenciar usuários</p>
            <p className="description">Usuários que têm acesso ao Portal</p>
            <UilUsersAlt />
          </>
        );
    }
  };

  return (
    <Container type="button" onClick={onClick} $variant={variant}>
      {handleVariant()}
    </Container>
  );
};

export default ExtraLargeButton;
