import styled from "styled-components";
import svg from "react-inlinesvg";

const Container = styled(svg)``;

type SVGProps = {
  src: string;
  id?: string;
  className?: string;
  style?: React.CSSProperties;
};

const SVG: React.FC<SVGProps> = ({
  src,
  id,
  className,
  style,
}: SVGProps) => (
  <Container
    className={className && className}
    style={style && style}
    src={src}
    id={id && id}
  />
);

export default SVG;
