import { UilEditAlt, UilLinkBroken } from "@iconscout/react-unicons";
import styled from "styled-components";
import Button from "../../Button";
import useDetectOutsideClick from "../../../../hooks/useDetectOutsideClick";
import ReactDOM from "react-dom";
import { useEffect, useRef, useState } from "react";

const Container = styled.div<{
  $shouldShow: boolean;
  $hasActionButton: boolean;
  $menuItemAmount: number;
  // $position?: DOMRect;
  $position?: { top: string };
}>`
  position: absolute;
  right: 0px;
  top: ${({ $position }) => $position?.top};

  z-index: 1;

  height: ${({ $shouldShow }) => ($shouldShow ? "auto" : "0px")};
  width: 415px;
  padding: 12px;

  visibility: ${({ $shouldShow }) => ($shouldShow ? "visible" : "hidden")};

  border: 1px solid var(--color-dark-blue-300);
  border-radius: 8px;

  transition: height 0.3s ease-in-out;

  display: flex;
  flex-direction: column;

  background: var(--color-light-0);

  .file-container {
    position: relative;
    // max-height: 109px;
    height: ${({ $hasActionButton }) =>
      $hasActionButton ? "calc(100% - 40px)" : "calc(100% - 10px)"};
    // min-height: ${({ $menuItemAmount }) => $menuItemAmount * 34}px;
    overflow: auto;
  }

  & > button {
    margin-top: 8px;
    color: var(--color-main-rose) !important;

    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }
`;

const MenuItem = styled.div<{ $isTooltipVisible?: boolean }>`
  display: flex;
  // justify-content: space-between;
  align-items: center;
  flex-direction: column;

  width: 100%;

  border-radius: 8px;

  &:first-child {
    // margin-top: 25px;
  }

  span {
    margin-right: 0 !important;
    border-radius: 6px;

    width: calc(100% - 16px);
    justify-content: space-between;

    padding: 0px 8px;

    &:hover {
      background: var(--color-light-100);

      button.ellipsisButton:not(button.ellipsisButton:active) {
        background: var(--color-light-0);
      }
    }
  }

  a {
    color: var(--color-main-blue);
    font-size: 14px;
    font-family: var(--font-paragraph-default);

    display: flex;
    align-items: center;

    padding: 8px 0;
    // padding-left: 12px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  button.ellipsisButton {
    width: 24px;
    height: 24px;

    padding: 0;
    margin: 0;

    outline: 0;

    svg {
      width: 16px;
      height: 16px;

      fill: var(--color-text-labels);
    }

    &:active {
      background: var(--color-main-blue);

      svg {
        width: 16px;
        height: 16px;

        fill: var(--color-light-0);
      }
    }
  }

  // & button {
  //   padding: 0;
  //   margin: 0;

  //   & > svg {
  //     padding: 4px;
  //     border-radius: 4px;
  //     margin-right: 8px;
  //     background: var(--color-light-0);
  //     width: 16px;
  //     height: 16px;
  //     fill: var(--color-text-labels);
  //   }
  // }

  // & button:hover {
  //   & > svg {
  //     background: var(--color-main-rose);
  //     fill: var(--color-light-0);
  //   }
  // }
`;

const MenuItemMenu = styled.div<{
  $shouldShow: boolean;
  $itemsAmount: number;
  $topPosition: number;
}>`
  position: absolute;
  right: 45px;
  top: ${({ $topPosition }) => $topPosition}px;

  background: var(--color-light-0);

  visibility: ${({ $shouldShow }) => ($shouldShow ? "visible" : "hidden")};
  height: ${({ $shouldShow }) => ($shouldShow ? "auto" : "0px")};
  width: 184px;
  transition: visibility 0.2s ease-in, height 0.2s ease-in;

  padding: 8px 0;

  border-radius: 8px;
  border: 1px solid var(--color-dark-blue-300);
  box-shadow: 2px 4px 8px 0px hsla(231, 15%, 26%, 0.1);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    border-radius: 6px;

    color: var(--color-text-paragraph) !important;

    font-family: var(--font-paragraph-default);

    height: 33px;
    width: 168px;

    & > svg {
      fill: var(--color-text-labels) !important;

      margin-right: 8px;
    }
  }
`;

type TAttachmentMenuItem = {
  name: string;
  url: string;
  searchParam: string;
  onDelete?: () => void;
  onRename?: () => void;
};

type TAttachmentMenuProps = {
  items: TAttachmentMenuItem[];
  onAction?: { text: string; callback: () => void };
  shouldShowMenu: boolean;
  onClickAway: () => void;
  position?: { top: string };
};

const AttachmentMenu: React.FC<TAttachmentMenuProps> = ({
  items,
  onAction,
  shouldShowMenu,
  onClickAway,
  position,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const menuItemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const fileContainerRef = useRef<HTMLDivElement | null>(null);

  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const [shouldShowMenuItemMenu, setShouldShowMenuItemMenu] = useState<
    boolean[]
  >(new Array(items.length).fill(false));

  useDetectOutsideClick({
    isClicked: shouldShowMenu,
    componentRef: menuRef,
    onOutside() {
      if (shouldShowMenu) {
        setShouldShowMenuItemMenu(new Array(items.length).fill(false));
        onClickAway();
      }
    },
  });

  const urlHandler = (menuItem: TAttachmentMenuItem) => {
    const url = new URL(window.location.origin + menuItem.url);

    url.searchParams.set("search", menuItem.searchParam);

    return url.toString();
  };

  useEffect(() => {
    setShouldShowMenuItemMenu(new Array(items.length).fill(false));
  }, [items.length]);

  return (
    <Container
      $position={position}
      $hasActionButton={onAction !== undefined}
      ref={menuRef}
      $shouldShow={shouldShowMenu}
      $menuItemAmount={items.length > 3 ? 3 : items.length}
    >
      <div ref={fileContainerRef} className="file-container">
        {items.map((item, index) => {
          return (
            <MenuItem
              ref={(ref) => {
                menuItemRefs.current[index] = ref;
              }}
              key={index}
            >
              <span>
                <a
                  href={(() => {
                    return urlHandler(item); //"#";
                  })()}
                  target="_blank"
                >
                  {item.name.replace(/ - (.*?) - /, (_match, capture1) => {
                    if (capture1.length > 20)
                      return ` - ${capture1.substring(0, 20)}... - `;
                    return ` - ${capture1} - `;
                  })}
                  &nbsp;
                  {/* <UilExternalLinkAlt /> */}
                </a>
                {item.onRename && (
                  <Button
                    icon="onlyIcon"
                    variant="white"
                    iconType="ellipsisV"
                    id={"ellipsisButton-" + index}
                    className="ellipsisButton"
                    onClick={() => {
                      setShouldShowMenuItemMenu((prev) => {
                        const temp = new Array(items.length).fill(false);
                        temp[index] = !prev[index];

                        return temp;
                      });
                    }}
                  />
                )}
              </span>
              {menuRef.current &&
                ReactDOM.createPortal(
                  <MenuItemMenu
                    $topPosition={12 + index * 33.33}
                    $shouldShow={shouldShowMenuItemMenu[index]}
                    $itemsAmount={item.onDelete !== undefined ? 1 : 2}
                  >
                    <button
                      ref={(ref) => (buttonRefs.current[index] = ref)}
                      role="button"
                      onClick={() => {
                        setShouldShowMenuItemMenu((prev) => {
                          const temp = new Array(items.length).fill(false);
                          temp[index] = !prev[index];

                          return temp;
                        });
                        if (item.onRename) item.onRename();
                      }}
                    >
                      <UilEditAlt /> Renomear
                    </button>
                    {item.onDelete && (
                      <button
                        ref={(ref) => (buttonRefs.current[index] = ref)}
                        role="button"
                        onClick={() => {
                          setShouldShowMenuItemMenu((prev) => {
                            const temp = new Array(items.length).fill(false);
                            temp[index] = !prev[index];

                            return temp;
                          });
                          if (item.onDelete) item.onDelete();
                        }}
                      >
                        <UilLinkBroken /> Remover vínculo
                      </button>
                    )}
                  </MenuItemMenu>,
                  menuRef.current
                )}
              {/* {item.onDelete && (
              <Tooltip
                text={[
                  { text: "Remover", style: "default", shouldBreakLine: false },
                ]}
                // customTopPosition={index * 30}
                onMouseEnter={() => {
                  setIsTooltipVisible(true);
                }}
                arrowPosition="left"
                customPosition={{
                  pMarginLeft: 0,
                  pMarginRight: 0,
                  // right: 0,
                  left:
                    (menuItemRefs.current[index]?.getBoundingClientRect()
                      .width ?? 0) + 5,
                  top: index * 34,
                }}
                onMouseLeave={() => {
                  setIsTooltipVisible(false);
                }}
              >
                <button
                  ref={(ref) => (buttonRefs.current[index] = ref)}
                  role="button"
                  onClick={item.onDelete}
                >
                  <UilLinkBroken />
                </button>
              </Tooltip>
            )} */}
            </MenuItem>
          );
        })}
      </div>
      {onAction && (
        <Button
          variant="outline"
          icon="link"
          iconPosition="left"
          text={onAction.text}
          onClick={onAction.callback}
        />
      )}
    </Container>
  );
};

export default AttachmentMenu;
