import styled from "styled-components";
import { useCallback, useRef, useState } from "react";
import useDetectOutsideClick from "../../../hooks/useDetectOutsideClick";

const Container = styled.button<{ $icon: string }>`
  background: var(--color-light-0);
  border: 1px solid var(--color-light-200);
  border-top: 0px;
  border-bottom: 0px;

  cursor: pointer;

  height: 72px;

  display: flex;
  align-items: center;

  text-align: left;

  padding: 8px 16px;

  &::before {
    content: "${({ $icon }) => $icon}";

    max-width: 40px;
    max-height: 40px;
    border-radius: 8px;
    padding: 9px 8px;

    background: var(--color-gradient-rose);

    font-family: var(--font-paragraph-bold);
    font-size: 16px;
    color: var(--color-light-0);
  }

  &::after {
    content: "";
    font-family: unicons;
    color: var(--color-dark-blue-400);
    font-size: 22px;

    transform: rotateZ(0deg);
    transition: transform 0.25s ease-in-out;
  }

  &:not(&:focus) {
    &::after {
      transform: rotateZ(0deg);
    }
  }

  &.active {
    &::after {
      transform: rotateZ(180deg);
    }
  }

  &:hover {
    background: var(--color-light-0);
  }
`;

const ContentArea = styled.div`
  p.name {
    color: var(--color-text-title);
    font-family: var(--font-paragraph-default);
    font-size: 16px;
  }

  p.email {
    color: var(--color-text-labels);
    font-family: var(--font-paragraph-default);
    font-size: 10px;
  }

  padding: 0 8px;
`;

// const LabelArea = styled.div``;

type TButtonProps = {
  name: string;
  email: string;
  icon: string;
  onClick?: () => void;
  onBlur?: () => void;
  componentRef?: React.MutableRefObject<HTMLDivElement | null>;
};

const Button: React.FC<TButtonProps> = ({
  name,
  email,
  icon,
  onBlur,
  onClick,
  componentRef,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  useDetectOutsideClick({
    isClicked: isClicked,
    componentRef,
    onOutside() {
      setIsClicked(false);
      if (onBlur) onBlur();
    },
  });

  const handleRenderButton = useCallback(() => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const handleOnClickButton = () => {
      buttonRef.current?.focus();
      setIsClicked((prev) => !prev);
      if (onClick) onClick();
    };

    // const handleOnBlur = () => {
    //   if (onBlur) onBlur();
    // };

    return (
      <Container
        ref={buttonRef}
        $icon={icon}
        role="button"
        // onBlur={handleOnBlur}
        onClick={handleOnClickButton}
        className={isClicked ? "active" : ""}
      >
        <ContentArea>
          <p className="name">{name}</p>

          <p className="email">{email}</p>
        </ContentArea>
      </Container>
    );
  }, [name]);

  return handleRenderButton();
};

export default Button;
