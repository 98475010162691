import styled from "styled-components";

import {
  getDay,
  getDaysInMonth,
  format,
  addDays,
  sub,
  compareAsc,
  add,
  isBefore,
} from "date-fns";

import { ptBR } from "date-fns/locale";
import { useEffect, useState } from "react";
import Button from "../Button";
import { isWithinInterval } from "date-fns/isWithinInterval";
import { parse } from "date-fns/parse";

const Container = styled.div`
  // height: 315px;
  max-width: 270px;
  // border: 1px solid var(--color-text-input);

  table {
    border-collapse: collapse;
  }
`;

const TableHead = styled.thead`
  background: var(--color-light-100);
  font-family: var(--font-paragraph-text);
  color: var(--color-text-paragraph);
`;

const TitleContainer = styled.tr`
  height: 40px;
  width: 100%;

  font-size: 14px;

  & button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    box-shadow: none;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  & button :hover {
    background: transparent;
    border: unset;
    box-shadow: unset;
  }

  th div b {
    font-family: var(--font-paragraph-bold);
  }
`;

const SubtitleContainer = styled.tr`
  height: 40px;
  width: 100%;

  th {
    width: 40px;
    border-top: 1px solid var(--color-text-input);

    &:first-child {
      border-left: 1px solid var(--color-text-input);
    }

    &:last-child {
      border-right: 1px solid var(--color-text-input);
    }

    b {
      font-family: var(--font-paragraph-bold);
    }
  }

  font-size: 12px;
`;

const TableBody = styled.tbody`
  background: var(--color-light-0);

  td {
    padding: 0;
  }

  td button {
    height: 38px;
    width: 38px;

    font-family: var(--font-paragraph-default);
    font-size: 12px;
    text-align: center;

    border: 1px solid var(--color-text-input);
    border-right: 0;
    border-bottom: 0;
    border-radius: 0px;

    box-shadow: none;

    padding: 0;

    &:disabled {
      border: 1px solid var(--color-text-input);
      border-right: 0;
      border-bottom: 0;
      border-radius: 0px;
    }

    &.active {
      font-family: var(--font-paragraph-bold);
      background: var(--color-main-blue);
      color: var(--color-light-0);

      &:hover {
        border: 1px solid var(--color-text-input);
      }
    }

    &.intervalChild {
      background: var(--color-blue-100);

      &:hover {
        font-family: var(--font-paragraph-bold);
        border: 1px solid var(--color-main-blue);
      }
    }
  }

  td.faded button {
    color: var(--color-text-input);
    cursor: default;

    &:hover {
      background: var(--color-light-0);
    }
  }

  td.currentDate button:not(.active) {
    color: var(--color-main-blue);
  }

  td button:not(td.faded button) {
    cursor: pointer;
  }

  tr td:last-child button {
    border-right: 1px solid var(--color-text-input);
  }

  tr:last-child td button {
    border-bottom: 1px solid var(--color-text-input);
  }
`;

type TDatePicker = {
  id: string;
  setSelectedDatesRef?: React.MutableRefObject<
    | React.Dispatch<
        React.SetStateAction<{
          startDate: string;
          endDate: string;
        }>
      >
    | undefined
  >;
  onSelect?: (
    a: { startDate: string; endDate: string },
    setSelectedDates: React.Dispatch<
      React.SetStateAction<{
        startDate: string;
        endDate: string;
      }>
    >
  ) => void;
  shouldClearSelected?: boolean;
  filled?: { startDate: string; endDate: string };
};

const DatePicker: React.FC<TDatePicker> = ({
  id,
  onSelect,
  shouldClearSelected,
  setSelectedDatesRef,
  filled,
}: TDatePicker) => {
  const [today, setToday] = useState(new Date());
  const [selectedDates, setSelectedDates] = useState({
    startDate: filled ? filled.startDate : "",
    endDate: filled ? filled.endDate : "",
  });

  const getDaysInMonthByDate = (date: Date) => {
    const LIMIT = getDaysInMonth(date);

    const startDate = date;
    startDate.setDate(1);

    const dates = [startDate];
    for (let index = 1; index < LIMIT; index++) {
      const nextDay = addDays(startDate, index);

      dates.push(nextDay);
    }

    return dates;
  };

  const fromPrevMonthRowFiller = (finalStep: number, refDate: Date) => {
    const dates = [refDate];
    dates.pop();

    for (let i = 1; i <= finalStep; i++) {
      const prevDay = sub(refDate, { days: i });

      dates.push(prevDay);
    }

    return dates;
  };

  const fromNextMonthRowFiller = (step: number, refDate: Date) => {
    const dates = [refDate];
    dates.pop();

    for (let i = 1; i < 7 - step; i++) {
      const nextDay = add(refDate, { days: i });

      dates.push(nextDay);
    }

    return dates;
  };

  const getCalendarData = (startDate: Date) => {
    const refDate = new Date(startDate);
    refDate.setDate(1);
    const starterDayOfWeek = getDay(refDate);

    const nextDate = new Date(startDate);
    nextDate.setDate(getDaysInMonth(nextDate));
    const latterDayOfWeek = getDay(nextDate);

    return [
      fromPrevMonthRowFiller(starterDayOfWeek, refDate).sort(compareAsc),
      getDaysInMonthByDate(refDate),
      fromNextMonthRowFiller(latterDayOfWeek, nextDate).sort(compareAsc),
    ];
  };

  const capitalizeFirstLetter = (word: string) =>
    word[0].toLocaleUpperCase() + word.substring(1);

  const headerTitle = `${capitalizeFirstLetter(
    format(today, "MMMM", { locale: ptBR })
  )} ${today.getFullYear()}`;

  const referenceResult = getCalendarData(today);
  const result = [
    ...referenceResult[0],
    ...referenceResult[1],
    ...referenceResult[2],
  ];

  const classNameHandler = (date: Date) => {
    if (format(date, "dd-MM-y") === format(new Date(), "dd-MM-y")) {
      return "currentDate";
    }

    return referenceResult[0].includes(date) ||
      referenceResult[2].includes(date)
      ? "faded"
      : "";
  };

  const resultRender = () => {
    const renderResult = [<></>];
    renderResult.pop();

    const decideClassName = (currentDate: string) => {
      const startDate = selectedDates.startDate,
        endDate = selectedDates.endDate;

      const dateParser = (date: string) =>
        parse(date, "dd/MM/yyyy", new Date());

      if (startDate === currentDate || endDate === currentDate) {
        return "active";
      } else if (
        isWithinInterval(dateParser(currentDate), {
          start: dateParser(startDate),
          end: dateParser(endDate),
        })
      ) {
        return "intervalChild";
      } else return "";
    };

    for (let indexLine = 0; indexLine < result.length / 7; indexLine++) {
      const temp = [<></>];
      temp.pop();
      for (
        let indexCol = 7 * indexLine;
        indexCol < 7 * (indexLine + 1);
        indexCol++
      ) {
        const currentIndex = indexLine + indexCol - 1 * indexLine;
        const date = result[currentIndex];
        if (date !== undefined) {
          const className = classNameHandler(date);
          temp.push(
            <td className={className}>
              <Button
                icon="noIcon"
                variant="secondary"
                disabled={className === "faded"}
                className={
                  decideClassName(format(date, "dd/MM/yyyy")) + " calendar"
                }
                text={format(date, "dd")}
                onClick={() => {
                  if (selectedDates.startDate.length === 0) {
                    const value = {
                      startDate: format(date, "dd/MM/yyyy"),
                      endDate: "",
                    };
                    setSelectedDates(value);
                  }
                  if (selectedDates.startDate.length > 0) {
                    if (
                      isBefore(
                        parse(
                          selectedDates.startDate,
                          "dd/MM/yyyy",
                          new Date()
                        ),
                        date
                      )
                    ) {
                      const value = {
                        endDate: format(date, "dd/MM/yyyy"),
                        startDate: selectedDates.startDate,
                      };
                      setSelectedDates(value);
                      if (onSelect) onSelect(value, setSelectedDates);
                    } else {
                      setSelectedDates({ endDate: "", startDate: "" });
                    }
                  }
                }}
              />
            </td>
          );
        }
      }
      renderResult.push(<tr>{...temp}</tr>);
    }
    return renderResult;
  };

  useEffect(() => {
    // if (
    //   shouldClearSelected &&
    //   (selectedDates.startDate.length > 0 || selectedDates.endDate.length > 0)
    // ) {
    //   setSelectedDates({ endDate: "", startDate: "" });
    // }
    
  }, [shouldClearSelected, selectedDates]);

  useEffect(() => {
    if (setSelectedDatesRef) {
      setSelectedDatesRef.current = setSelectedDates;
    }
  }, []);

  return (
    <Container id={id}>
      <table>
        <TableHead>
          <TitleContainer>
            <th colSpan={7}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Button
                  icon="onlyIcon"
                  iconType="angleLeftB"
                  variant="secondary"
                  className="calendar"
                  onClick={() => {
                    setToday(new Date(sub(today, { months: 1 })));
                  }}
                />
                <b>{headerTitle}</b>
                <Button
                  icon="onlyIcon"
                  iconType="angleRightB"
                  variant="secondary"
                  className="calendar"
                  onClick={() => {
                    setToday(new Date(add(today, { months: 1 })));
                  }}
                />
              </div>
            </th>
          </TitleContainer>
          <SubtitleContainer>
            {["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"].map(
              (dayOfWeek, index) => (
                <th key={index}>
                  <b>{dayOfWeek}</b>
                </th>
              )
            )}
          </SubtitleContainer>
        </TableHead>
        <TableBody>{...resultRender()}</TableBody>
      </table>
    </Container>
  );
};

export default DatePicker;
