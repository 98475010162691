import { TInvoiceData, TNexaasProduct } from "../redux/reducers/globalState";

export type TMockResponse = {
  errors: TNexaasProduct[];
  success: TNexaasProduct[];
};

export const mockResponse: TMockResponse = {
  errors: [
    {
      ean: "SEM GTIN",
      sku: null,
      // batch: null,
      index: 1,
      reason: "sku_not_found",
      measure: "UN",
      quantity: "50.0",
      description: "Sacola Papelao Roxa - 18x25x9cm",
      supplier_code: "252071123",
      supplier_sku: "",
      conversion_factor: null,
      verified_quantity: null,
      verified_measure: null,
    },
    {
      ean: "SEM GTIN",
      sku: null,
      // batch: null,
      index: 2,
      reason: "sku_not_found",
      measure: "UN",
      quantity: "25.0",
      description: "Fita institucional Lote: NF24456 x25",
      supplier_code: "252071123",
      supplier_sku: "",
      conversion_factor: null,
      verified_quantity: null,
      verified_measure: null,
    },
    {
      ean: "SEM GTIN",
      sku: null,
      // batch: null,
      index: 3,
      reason: "sku_not_found",
      measure: "UN",
      quantity: "100.0",
      description: "Amostra Base Matte Cappucino Lote: 0004/1123 x100",
      supplier_code: "252071123",
      supplier_sku: "",
      conversion_factor: null,
      verified_quantity: null,
      verified_measure: null,
    },
  ],
  success: [],
};
export const mockResponse2: TMockResponse = {
  errors: [],
  success: [
    {
      index: 1,
      sku: "3338",
      supplier_code: "252071123",
      supplier_sku: "",
      description: "Sacola Papelao Roxa - 18x25x9cm",
      ean: "SEM GTIN",
      quantity: "50.0",
      measure: "UN",
      verified_quantity: null,
      verified_measure: null,
      conversion_factor: null,
      batch_name: "Goias",
      batch_manufacturing_date: "2024-07-05",
      batch_expiration_date: "2025-07-05",
    },
    {
      index: 2,
      sku: "4449",
      supplier_code: "252071123",
      supplier_sku: "",
      description: "Fita institucional Lote: NF24456 x25",
      ean: "SEM GTIN",
      quantity: "25.0",
      measure: "UN",
      verified_quantity: null,
      verified_measure: null,
      conversion_factor: null,
      batch_name: "Goias",
      batch_manufacturing_date: "2024-07-05",
      batch_expiration_date: "2025-07-05",
    },
    {
      index: 3,
      sku: "5166",
      supplier_code: "252071123",
      supplier_sku: "",
      description: "Amostra Base Matte Cappucino Lote: 0004/1123 x100",
      ean: "SEM GTIN",
      quantity: "100.0",
      measure: "UN",
      verified_quantity: null,
      verified_measure: null,
      conversion_factor: null,
      batch_name: null,
      batch_manufacturing_date: null,
      batch_expiration_date: null,
    },
  ],
};

export const mockResponse3: TMockResponse = {
  success: [
    // ...mockResponse.success,
    // ...mockResponse2.success,
    {
      index: 2,
      sku: "4449",
      supplier_code: "252071123",
      supplier_sku: "",
      description: "Fita institucional Lote: NF24456 x25",
      ean: "SEM GTIN",
      quantity: "25.0",
      measure: "UN",
      verified_quantity: null,
      verified_measure: null,
      conversion_factor: null,
      // batch: null,
    },
    {
      index: 3,
      sku: "5166",
      supplier_code: "252071123",
      supplier_sku: "",
      description: "Amostra Base Matte Cappucino Lote: 0004/1123 x100",
      ean: "SEM GTIN",
      quantity: "100.0",
      measure: "UN",
      verified_quantity: null,
      verified_measure: null,
      conversion_factor: {
        id: 2321,
        sales_measure: "CX",
        sales_quantity: "0.1",
        purchase_measure: "UN",
        purchase_quantity: "1.0",
      },
      // batch: null,
    },
  ],
  errors: [
    // ...mockResponse.errors,
    // ...mockResponse2.errors,
    {
      index: 1,
      sku: "3338",
      supplier_code: "252071123",
      supplier_sku: "",
      description: "Sacola Papelao Roxa - 18x25x9cm",
      ean: "SEM GTIN",
      quantity: "50.0",
      measure: "UN",
      verified_quantity: null,
      verified_measure: null,
      conversion_factor: {
        id: 2321,
        sales_measure: "CX",
        sales_quantity: "0.1",
        purchase_measure: "UN",
        purchase_quantity: "1.0",
      },
      // batch: null,
      reason: "product_conversion_factor",
    },
  ],
};

export const mockInvoiceResponse1: TInvoiceData = {
  current_page: 1,
  pages: [
    {
      "1": "http://localhost:8000/invoices/?date_issue_after=2024-06-25&date_issue_before=2024-07-25&launch_status=10&organization=b08faf86-da16-4368-8c77-997ae2f5914c&per_page=100",
    },
  ],
  count: 1,
  results: [
    {
      uuid: "fc2f6963-994e-48fe-93a7-04e6c3ab29f3",
      issuer: {
        name: "hello world",
        document_number: "01234567891234",
      },
      duplicates: [],
      number: 645,
      date_issue: "2024-07-12T14:39:59Z",
      amount: "365.00",
      manifestation: 10,
      access_key: "6556534236543765",
      file_xml:
        "http://localhost:8000/media/invoices/fc2f6963-994e-48fe-93a7-04e6c3ab29f3/6556534236543765_645.xml",
      file_nfe: null,
      launch_status: 10,
      goods_receipt_url: null,
      accounts_payable_url: null,
      boletos_exists: false,
      fintera_id: null,
      nexaas_finished_at: null,
      nexaas_state: 10,
      invoice_type: 20,
      label: "teste",
    },
  ],
};
