import styled from "styled-components";
import Button from "./Button";
import { useCallback, useEffect, useId, useRef, useState } from "react";
import Menu from "./Menu";
import { TOrganization } from "../../../redux/reducers/organizations";
import { handleGetDataFromLocalStorage } from "../../../utils/miscellaneous";
import { localStorageConstants } from "../../../utils/constants";

const Container = styled.div`
  position: relative;
  width: 280px;
`;

export type TVariants = "labeled" | "unlabeled";

type TLabeledMenu = {
  variant: "labeled";
  labels: string[];
};

type TUnlabeledMenu = {
  variant: "unlabeled";
};

export type TOptGroupOptions = "Últimos acessados" | "Todos";

export type TInfiniteScrollingVariants = "organizations" | "nonPaginated";

export type TInfiniteScroller = {
  variant: TInfiniteScrollingVariants;
  totalElements: number;
  searchParam?: string;
  onIntersect?: (data: any[], elementsAmount: number) => void;
};

type TCommonPropsMenu = {
  search: boolean;
  optGroup?: TOptGroupOptions[];
  onSelect?: (selected: string) => void;
  customFilter?: (
    index: string,
    selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
    selectDataRef: React.MutableRefObject<string[]>,
    setShouldUpdate: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  options: string[];
  forceSelectOptionsByIndex?: number[];
  searchPhraseOnChange?: (searchPhrase: string) => void;
  searchPhraseOnSearch?: (searchPhrase: string) => void;
  hasInfiniteScrolling?: TInfiniteScroller;
  setSelectedIndex?: (
    setSelectedData: React.Dispatch<React.SetStateAction<string[]>>
  ) => void;
};

type TLabeledButton = {
  variant: "labeled";
  labels: string[];
  showPlaceholder?: boolean;
};

type TUnlabeledButton = {
  variant: "unlabeled";
  labels: string[];
};

type TCommonPropsButton = {
  placeholder: string;
  onError?: { hasError: boolean; message: string };
  filled?: string;
  disabled?: boolean;
  setData?: (
    setSelectedData: React.Dispatch<React.SetStateAction<string>>
  ) => void;
  hasMandatoryStyle?: boolean;
};

type TButton<V extends TVariants> = V extends "labeled"
  ? TLabeledButton & TCommonPropsButton
  : TUnlabeledButton & TCommonPropsButton;

type TMenu<V extends TVariants> = V extends "labeled"
  ? TLabeledMenu & TCommonPropsMenu
  : TUnlabeledMenu & TCommonPropsMenu;

type TDropdownProps<V extends TVariants> = {
  button: TButton<V>;
  menu: TMenu<V>;
};

const Dropdown: React.FC<TDropdownProps<TVariants>> = ({ button, menu }) => {
  const [selectedData, setSelectedData] = useState("");
  const [shouldShow, setShouldShow] = useState<"show" | "hide">("hide");

  const [selectOrganization, setSelectOrganization] =
    useState<TOrganization | null>(null);

  const componentRef = useRef<HTMLDivElement | null>(null);
  const buttonSetIsClickedRef = useRef<React.Dispatch<
    React.SetStateAction<boolean>
  > | null>(null);

  const searchId = menu.search ? useId() : "";
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const selectDataRef = useRef([""]);

  const handleButtonRender = useCallback(() => {
    const handleOnClickButton = (
      setIsClicked: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      buttonSetIsClickedRef.current = setIsClicked;
      setShouldShow((prev) => (prev === "hide" ? "show" : "hide"));
    };

    const handleOnBlur = (
      e: React.FocusEvent<HTMLButtonElement, Element>
    ): "hide" | "show" => {
      // console.log("on blur event: ", e);
      if (menu.search) {
        const searchElement = document.getElementById(searchId);
        if (
          searchElement &&
          e.relatedTarget &&
          Array.from(searchElement?.children).includes(e.relatedTarget)
        ) {
          // let returnValue: "show" | "hide" = "hide";
          // const handleSearchOnBlur = (e: FocusEvent) => {
          //   if (
          //     ![
          //       searchElement,
          //       Array.from(searchElement?.children)[0],
          //     ].includes(e.target as Element)
          //   ) {
          //     setShouldShow("hide");
          //   }
          //   returnValue = "show";
          // };

          // document.addEventListener("click", handleSearchOnBlur);

          return "show";
        } else {
          setTimeout(() => {
            setShouldShow("hide");
          }, 20);
          return "hide";
        }
      } else {
        setTimeout(() => {
          setShouldShow("hide");
        }, 20);
        return "hide";
      }
    };

    switch (button.variant) {
      case "labeled":
        const handleNoPlaceholderLabel = () => {
          const selectedLabel = selectedData?.replace(/(.*)?%.*/, "$1");
          if (selectedLabel?.length > 0) {
            return selectedLabel;
          }
          if (
            selectOrganization &&
            button.labels &&
            button.labels.includes(selectOrganization?.company_name)
          ) {
            return selectOrganization.company_name;
          }
          return (button.labels && button.labels[0]) ?? selectedLabel;
        };

        if (button.setData && selectedData.length === 0)
          button.setData(setSelectedData);

        return (
          <Button
            disabled={button.disabled}
            onBlur={handleOnBlur}
            onClick={handleOnClickButton}
            placeholder={button.placeholder}
            variant="labeled"
            onError={button.onError}
            label={
              button.showPlaceholder
                ? button.filled ?? selectedData?.replace(/(.*)?%.*/, "$1")
                : handleNoPlaceholderLabel()
            }
            componentRef={componentRef}
            hasMandatoryStyle={button.hasMandatoryStyle}
          />
        );
      case "unlabeled":
        const handleUnlabeledOnBlur = () => {
          setTimeout(() => {
            setShouldShow("hide");
          }, 20);
          return "hide" as const;
        };

        if (button.setData && selectedData.length === 0)
          button.setData(setSelectedData);

        return (
          <Button
            disabled={button.disabled}
            onBlur={handleUnlabeledOnBlur}
            onClick={handleOnClickButton}
            placeholder={button.placeholder}
            variant="unlabeled"
            label={selectedData?.length > 0 ? selectedData : button.labels[0]}
            componentRef={componentRef}
            hasMandatoryStyle={button.hasMandatoryStyle}
          />
        );
    }
  }, [selectedData, button]);

  const handleMenuRender = useCallback(() => {
    const handleOnClickMenu = (
      index: string,
      selected: string[],
      setSelected: React.Dispatch<React.SetStateAction<string[]>>,
      stringValue?: string
    ) => {
      if (menu.customFilter) {
        menu.customFilter(
          index,
          selected,
          setSelected,
          selectDataRef,
          setShouldUpdate
        );
      } else {
        const value = stringValue
          ? (menu.options.find((option) => option === stringValue) as string)
          : menu.options[parseInt(index)];

        if (button.variant === "labeled") {
          if (menu.variant === "labeled") {
            const temp = button.labels[parseInt(index)];
            setSelectedData(temp);
            setSelected([index]);
          } else {
            setSelectedData(value);
            setSelected([index]);
          }
        } else {
          setSelectedData(value);
          setSelected([index]);
        }

        if (menu.onSelect) menu.onSelect(`${menu.options[parseInt(index)]}`);
      }

      setTimeout(() => {
        if (buttonSetIsClickedRef.current) buttonSetIsClickedRef.current(false);
        setShouldShow("hide");
      }, 20);
    };
    switch (menu.variant) {
      case "labeled":
        return (
          <Menu
            searchId={searchId.length > 0 ? searchId : ""}
            shouldShow={shouldShow}
            variant="labeled"
            label={menu.labels}
            content={menu.options}
            list="default"
            onClick={handleOnClickMenu}
            optGroup={menu.optGroup}
            forceSelectOptionsByIndex={menu.forceSelectOptionsByIndex}
            searchPhraseOnChange={menu.searchPhraseOnChange}
            searchPhraseOnSearch={menu.searchPhraseOnSearch}
            hasInfiniteScrolling={menu.hasInfiniteScrolling}
            setData={(setSelectedData) => {
              if (menu.setSelectedIndex) menu.setSelectedIndex(setSelectedData);
            }}
          />
        );
      case "unlabeled":
        return (
          <Menu
            searchId={searchId.length > 0 ? searchId : ""}
            shouldShow={shouldShow}
            variant="unlabeled"
            content={menu.options}
            list="default"
            onClick={handleOnClickMenu}
            optGroup={menu.optGroup}
            forceSelectOptionsByIndex={menu.forceSelectOptionsByIndex}
            searchPhraseOnChange={menu.searchPhraseOnChange}
            searchPhraseOnSearch={menu.searchPhraseOnSearch}
            setData={(setSelectedData) => {
              if (menu.setSelectedIndex) menu.setSelectedIndex(setSelectedData);
            }}
          />
        );
    }
  }, [shouldShow, menu, shouldUpdate]);

  const handleRenderDropdown = useCallback(() => {
    return (
      <Container ref={componentRef}>
        {handleButtonRender()}
        {!button.disabled && handleMenuRender()}
      </Container>
    );
  }, [handleButtonRender, handleMenuRender]);

  useEffect(() => {
    const localSelectOrganization = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ORGANIZATION
    ) as TOrganization;

    if (localSelectOrganization) setSelectOrganization(localSelectOrganization);
  }, []);

  return handleRenderDropdown();
};

export default Dropdown;
