import styled from "styled-components";
import Button from "./Button";
import { useCallback, useId, useRef, useState } from "react";
import Menu, {
  CALENDAR_CONTENT_OPTIONS,
  CALENDAR_SLIP_CONTENT_OPTIONS,
  TCalendarMenuOptions,
} from "./Menu";
import {
  handleParseQuerystrings,
  handlePopulateQuerystring,
  handleRevertTranslateCalendarOptions,
} from "../../../utils/miscellaneous";

const Container = styled.div`
  position: relative;
  // min-width: 140px;
`;

export type TCommonProps = {
  content: string[];
  placeholder: string;
  search: boolean;
  optGroup?: "Últimos acessados";
  onSelect?: (select: string, allSelect: string[]) => void;
  buttonVariant: TIconVariants;
  customFilter?: (
    index: string,
    selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
    selectDataRef: React.MutableRefObject<string[]>,
    prevSelectDataRef: React.MutableRefObject<string[]>,
    setShouldUpdate: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  onAction?: (selectDataRef: React.MutableRefObject<string[]>) => void;
  onCancel?: (selectDataRef: React.MutableRefObject<string[]>) => void;
  isSingleSelect?: boolean;
  hasBottomBar?: boolean;
  forceSelectOptionsByIndex?: number[];
  filled?: string[];
  prevSelectData?: string[];
};

export type TCalendarProps = {
  content?: never;
  placeholder?: never;
  search?: never;
  optGroup?: never;
  onSelect?: (select: string[]) => void;
  buttonVariant: TIconVariants;
  customFilter?: never;
  onAction?: never;
  onCancel?: never;
  isSingleSelect?: never;
  hasBottomBar?: never;
  forceSelectOptionsByIndex?: number[];
  filled?: string[];
  prevSelectData?: string[];
};

export type TVariants = "labeled" | "unlabeled" | "calendar" | "calendar-slip";

export type TIconVariants = "calendar" | "checkCircle" | "eye";

export type TLabeled = {
  menuVariant: "labeled";
  label: string[];
};

export type TUnlabeled = {
  menuVariant: "unlabeled";
  label?: never;
};

type TCalendar = {
  menuVariant: "calendar" | "calendar-slip";
  label?: never;
};

export type TDropdownProps<Variant extends TVariants> =
  Variant extends "labeled"
    ? TLabeled & TCommonProps
    : Variant extends "calendar"
    ? TCalendar & TCalendarProps
    : TUnlabeled & TCommonProps;

const DropdownFilter: React.FC<TDropdownProps<TVariants>> = ({
  menuVariant,
  content,
  placeholder,
  label,
  search,
  optGroup,
  onSelect,
  onAction,
  // onCancel,
  buttonVariant,
  customFilter,
  isSingleSelect,
  hasBottomBar,
  forceSelectOptionsByIndex,
  filled,
  // prevSelectData,
}) => {
  const [selectedDataLength, setSelectedDataLength] = useState(
    `${filled?.length ?? ""}`
  );
  const [selectedCalendarFilter, setSelectedCalendarFilter] = useState(
    filled
      ? filled.join(" - ") ?? "Últimos 30 dias"
      : menuVariant.includes("slip")
      ? CALENDAR_SLIP_CONTENT_OPTIONS[0]
      : CALENDAR_CONTENT_OPTIONS[0]
  );

  const [shouldShow, setShouldShow] = useState<"show" | "hide">("hide");
  const [isFiltered, setIsFiltered] = useState(
    filled !== undefined && filled.length > 0 ? true : false
  );
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const selectDataRef = useRef([""]);
  const prevSelectDataRef = useRef([""]);

  const setSelectDataRef = useRef<
    React.Dispatch<React.SetStateAction<string[]>> | undefined
  >();

  const setShouldClearCalendarRef = useRef<
    React.Dispatch<React.SetStateAction<boolean>> | undefined
  >();

  const setCalendarSelectedDatesRef = useRef<
    | React.Dispatch<
        React.SetStateAction<{
          startDate: string;
          endDate: string;
        }>
      >
    | undefined
  >();

  const actionButtonIdRef = useRef("");

  // const isOnActionRef = useRef(false);

  const componentRef = useRef<HTMLDivElement | null>(null);
  const buttonSetClickStateRef = useRef<React.Dispatch<
    React.SetStateAction<boolean>
  > | null>(null);

  const searchId = search ? useId() : "";

  const menuId = useId();

  const handleRenderDropdown = useCallback(() => {
    switch (menuVariant) {
      case "labeled":
        const labeledButtonRef = useRef<HTMLButtonElement>(null);

        const handleLabeledOnClickButton = () => {
          // labeledButtonRef.current?.focus();
          if (setSelectDataRef.current) setSelectDataRef.current([]);
          setShouldShow((prev) => (prev === "hide" ? "show" : "hide"));
        };

        // const handleLabeledOnBlur = (
        //   e: React.FocusEvent<HTMLButtonElement, Element>
        // ): "hide" | "show" => {
        //   if (setSelectDataRef.current) setSelectDataRef.current([]);
        //   if (search) {
        //     const searchElement = document.getElementById(searchId);
        //     if (
        //       searchElement &&
        //       e.relatedTarget &&
        //       Array.from(searchElement?.children).includes(e.relatedTarget)
        //     ) {
        //       // let returnValue: "show" | "hide" = "hide";
        //       // const handleSearchOnBlur = (e: FocusEvent) => {
        //       //   if (
        //       //     ![
        //       //       searchElement,
        //       //       Array.from(searchElement?.children)[0],
        //       //     ].includes(e.target as Element)
        //       //   ) {
        //       //     setShouldShow("hide");
        //       //   }
        //       //   returnValue = "show";
        //       // };

        //       // document.addEventListener("click", handleSearchOnBlur);

        //       return "show";
        //     } else {
        //       setShouldShow("hide");
        //       return "hide";
        //     }
        //   } else {
        //     setShouldShow("hide");
        //     return "hide";
        //   }
        // };

        const handleLabeledOnClickMenu = (
          index: string,
          selected: string[],
          setSelected: React.Dispatch<React.SetStateAction<string[]>>
        ) => {
          if (selected.includes(index)) {
            setSelected((prev) => {
              const value = prev.splice(prev.indexOf(index), 1);
              // console.log("value: ", value);
              return value;
            });
          } else {
            setSelected((prev) => {
              const value = [...prev, index];
              // console.log("value: ", value);
              return value;
            });
          }
        };
        return (
          <Container ref={componentRef}>
            <Button
              componentRef={componentRef}
              $ref={labeledButtonRef}
              // onBlur={handleLabeledOnBlur}
              onClick={handleLabeledOnClickButton}
              placeholder={placeholder}
              variant={buttonVariant}
              content={selectedDataLength.replace(/.*?%(.*)/, "$1")}
              isFiltered={isFiltered}
            />
            <Menu
              searchId={searchId}
              shouldShow={shouldShow}
              variant="labeled"
              label={label}
              content={content}
              list="filter"
              onClick={handleLabeledOnClickMenu}
              optGroup={optGroup}
              hasBottomBar={hasBottomBar}
              forceSelectOptionsByIndex={forceSelectOptionsByIndex}
            />
          </Container>
        );
      case "unlabeled":
        const unlabeledButtonRef = useRef<HTMLButtonElement | null>(null);

        const handleUnlabeledOnClickButton = (
          setClickState: React.Dispatch<React.SetStateAction<boolean>>
        ) => {
          buttonSetClickStateRef.current = setClickState;
          // unlabeledButtonRef.current?.focus();

          // if (!search) setIsFiltered(false);
          setShouldShow((prev) => {
            if (prev === "hide") {
              setTimeout(() => {
                unlabeledButtonRef.current?.classList.add("active");
              }, 30);
              return "show";
            } else {
              handleUnlabeledOnBlur();
              setTimeout(() => {
                unlabeledButtonRef.current?.classList.remove("active");
              }, 30);
              return "hide";
            }
          });
        };

        const handleUnlabeledOnActionMenu = (
          _select: string[],
          actionButtonId: string
        ) => {
          // isOnActionRef.current = true;
          setTimeout(() => {
            // isOnActionRef.current = false;
            unlabeledButtonRef.current?.classList.remove("active");
          }, 30);

          if (setSelectDataRef.current)
            setSelectDataRef.current(selectDataRef.current);
          if (actionButtonId) actionButtonIdRef.current = actionButtonId;
          // setShouldShow("hide");
          if (`${selectDataRef.current.length}` !== selectedDataLength)
            setSelectedDataLength(`${selectDataRef.current.length}`);
          if (onAction) onAction(selectDataRef);
          setIsFiltered(true);
          handleUnlabeledOnBlur();
        };

        const handleUnlabeledOnCancelMenu = () => {
          // setShouldShow("hide");
          // console.log("prev selected ref: ", prevSelectDataRef.current);
          handleUnlabeledOnBlur();
        };

        const handleUnlabeledQueryParams = () => {
          const searchStrings = handlePopulateQuerystring();

          const roleArray = Array.from(searchStrings.entries())
            .filter((value) => value[0] === "role")
            .map((value) => value[1]);

          const statusArray = Array.from(searchStrings.entries())
            .filter((value) => value[0] === "status")
            .map((value) => value[1]);

          let qs = [];

          if (
            (searchStrings.has("has_accounts_payable") &&
              searchStrings.get("has_accounts_payable") === "true") ||
            (placeholder.toLowerCase() === "status" &&
              searchStrings.has("status") &&
              statusArray.includes("1")) ||
            (placeholder.toLowerCase() === "perfil de acesso" &&
              searchStrings.has("role") &&
              roleArray.includes("30"))
          ) {
            qs.push("1");
          }

          if (
            (searchStrings.has("has_goods_receipt") &&
              searchStrings.get("has_goods_receipt") === "true") ||
            (placeholder.toLowerCase() === "status" &&
              searchStrings.has("status") &&
              statusArray.includes("2")) ||
            (placeholder.toLowerCase() === "perfil de acesso" &&
              searchStrings.has("role") &&
              roleArray.includes("20"))
          ) {
            qs.push("2");
          }

          if (
            (placeholder.toLowerCase() === "status" &&
              searchStrings.has("status") &&
              statusArray.includes("0")) ||
            (placeholder.toLowerCase() === "perfil de acesso" &&
              searchStrings.has("role") &&
              roleArray.includes("10"))
          ) {
            qs.push("3");
          }

          if (
            (searchStrings.has("has_accounts_payable") &&
              searchStrings.has("has_goods_receipt") &&
              searchStrings.get("has_accounts_payable") === "false" &&
              searchStrings.get("has_goods_receipt") === "false") ||
            (placeholder.toLowerCase() === "status" &&
              searchStrings.has("status") &&
              statusArray.includes("3")) ||
            (placeholder.toLowerCase() === "perfil de acesso" &&
              searchStrings.has("role") &&
              roleArray.includes("40"))
          ) {
            qs.push("0");
          }

          prevSelectDataRef.current = qs;
        };

        const handleUnlabeledOnBlur =
          (): // e: React.FocusEvent<HTMLButtonElement, Element>
          "show" | "hide" => {
            // if (search || hasBottomBar) {
            //   const searchElement = document.getElementById(searchId);
            //   const menuElement = document.getElementById(menuId);

            //   if (
            //     (e.relatedTarget &&
            //       menuElement &&
            //       Array.from(menuElement?.children).includes(e.relatedTarget)) ||
            //     (e.relatedTarget &&
            //       searchElement &&
            //       Array.from(searchElement?.children).includes(e.relatedTarget))
            //   ) {
            //     // unlabeledButtonRef.current?.focus();
            //     return "show";
            //   } else {
            //     setTimeout(() => {
            //       if (!isOnActionRef.current) {
            //         if (setSelectDataRef.current) {
            //           setSelectDataRef.current(prevSelectDataRef.current);
            //         }
            //       }
            //     }, 20);

            //     setShouldShow("hide");
            //     return "hide";
            //   }
            // } else {
            //   setTimeout(() => {
            //     if (!isOnActionRef.current) {
            //       if (setSelectDataRef.current) {
            //         setSelectDataRef.current(prevSelectDataRef.current);
            //       }
            //     }
            //   }, 20);
            //   setShouldShow("hide");
            //   return "hide";
            // }
            handleUnlabeledQueryParams();
            setTimeout(() => {
              // if (!isOnActionRef.current) {
              if (buttonSetClickStateRef.current)
                buttonSetClickStateRef.current(false);
              if (setSelectDataRef.current) {
                setSelectDataRef.current(prevSelectDataRef.current);
              }
              // }
            }, 20);

            setShouldShow("hide");
            return "hide";
          };

        const handleUnlabeledOnClickMenu = (
          index: string,
          selected: string[],
          setSelected: React.Dispatch<React.SetStateAction<string[]>>
        ) => {
          if (customFilter) {
            customFilter(
              index,
              selected,
              setSelected,
              selectDataRef,
              prevSelectDataRef,
              setShouldUpdate
            );
            setSelectDataRef.current = setSelected;
          } else {
            // console.log("index: ", index);
            if (index === `0`) {
              setSelected([""]);
              setSelectedDataLength("");
              if (onSelect) onSelect(content[parseInt(index)], [""]);
            } else if (selected.includes(index)) {
              setSelected((prev) => {
                const deleteItemIndex = prev.indexOf(index);
                prev.splice(deleteItemIndex, deleteItemIndex > -1 ? 1 : 0);

                const value = prev;
                if (value[0] === "") value.splice(0, 1);

                setSelectedDataLength(`${value.length}`);
                if (onSelect) onSelect(content[parseInt(index)], value);
                return value;
              });
            } else {
              setSelected((prev) => {
                let value;
                if (isSingleSelect) {
                  value = [index];
                  setSelectedDataLength(`${value.length}`);
                  if (onSelect) onSelect(content[parseInt(index)], value);
                } else {
                  value = [...prev, index];

                  if (value[0] === "") value.splice(0, 1);

                  if (value.length === content.length - 1) {
                    value = [""];
                    setSelectedDataLength("");
                    if (onSelect) onSelect(content[0], value);
                  } else {
                    setSelectedDataLength(`${value.length}`);
                    if (onSelect) onSelect(content[parseInt(index)], value);
                  }
                }
                return value;
              });
            }
          }
        };

        return (
          <Container ref={componentRef}>
            <Button
              componentRef={componentRef}
              $ref={unlabeledButtonRef}
              onBlur={handleUnlabeledOnBlur}
              onClick={handleUnlabeledOnClickButton}
              placeholder={placeholder}
              variant={buttonVariant}
              content={selectedDataLength}
              isFiltered={isFiltered}
            />
            <Menu
              menuId={menuId}
              searchId={searchId}
              shouldShow={shouldShow}
              variant="unlabeled"
              content={content}
              list="filter"
              onClick={handleUnlabeledOnClickMenu}
              optGroup={optGroup}
              onCancel={handleUnlabeledOnCancelMenu}
              onAction={handleUnlabeledOnActionMenu}
              hasBottomBar={hasBottomBar}
              forceSelectOptionsByIndex={forceSelectOptionsByIndex}
            />
          </Container>
        );
      case "calendar":
      case "calendar-slip":
        const calendarButtonRef = useRef<HTMLButtonElement>(null);

        const handleCalendarOnClickButton = (
          setClickState: React.Dispatch<React.SetStateAction<boolean>>
        ) => {
          buttonSetClickStateRef.current = setClickState;
          calendarButtonRef.current?.classList.toggle("active");
          setShouldShow((prev) => {
            if (prev === "show") {
              handleCalendarOnBlur();
            }
            return prev === "hide" ? "show" : "hide";
          });
        };

        const handleCalendarOnActionMenu = (selected: string[]) => {
          // isOnActionRef.current = true;
          // setTimeout(() => {
          //   isOnActionRef.current = false;
          // }, 20);
          // setTimeout(() => {
          //   calendarButtonRef.current?.blur();
          // }, 1000);

          if (onSelect) onSelect(selected);
          setIsFiltered(true);
          const value =
            selected[0].length > 0
              ? selected[0]
              : menuVariant.includes("slip")
              ? CALENDAR_SLIP_CONTENT_OPTIONS[0]
              : CALENDAR_CONTENT_OPTIONS[0];

          setSelectedCalendarFilter(value);
          setShouldShow("hide");
          handleCalendarOnBlur();
        };

        const handleCalendarOnCancelMenu = () => {
          setShouldShow("hide");
          // calendarButtonRef.current?.blur();

          const queryStrings = handleCalendarQueryParams();

          setTimeout(() => {
            // if (!isOnActionRef.current && setSelectDataRef.current) {
            if (queryStrings.end_date && queryStrings.start_date) {
              prevSelectDataRef.current = [
                queryStrings.end_date[0].replace(
                  /(\d{4})-(\d{2})-(\d{2})/,
                  "$3/$2/$1"
                ),
                queryStrings.start_date[0].replace(
                  /(\d{4})-(\d{2})-(\d{2})/,
                  "$3/$2/$1"
                ),
              ];
            }

            if (setSelectDataRef.current)
              setSelectDataRef.current(prevSelectDataRef.current);

            if (queryStrings.period) {
              if (setCalendarSelectedDatesRef.current) {
                setCalendarSelectedDatesRef.current({
                  endDate: "",
                  startDate: "",
                });
              }
            }

            if (!queryStrings.period && !queryStrings.end_date) {
              if (setCalendarSelectedDatesRef.current) {
                setCalendarSelectedDatesRef.current({
                  endDate: "",
                  startDate: "",
                });
              }
            }
            // }
          }, 20);
        };

        const handleCalendarQueryParams = () => {
          const queryStrings = handleParseQuerystrings(window.location.search);

          const calendarOptions = window.location.pathname.includes(
            "payment-slip"
          )
            ? [
                ...CALENDAR_SLIP_CONTENT_OPTIONS.map((option) =>
                  option.toLowerCase()
                ),
              ]
            : [
                ...CALENDAR_CONTENT_OPTIONS.map((option) =>
                  option.toLowerCase()
                ),
              ];

          if (queryStrings.period) {
            const qs = handleRevertTranslateCalendarOptions(
              queryStrings.period[0] as TCalendarMenuOptions
            );
            if (window.location.pathname.includes("payment-slip")) {
              prevSelectDataRef.current = [
                CALENDAR_SLIP_CONTENT_OPTIONS[
                  calendarOptions.indexOf(qs as string)
                ],
              ];
            } else {
              prevSelectDataRef.current = [
                CALENDAR_CONTENT_OPTIONS[calendarOptions.indexOf(qs as string)],
              ];
            }
          }

          if (queryStrings.end_date && queryStrings.start_date) {
            prevSelectDataRef.current = [
              queryStrings.end_date[0],
              queryStrings.start_date[0],
            ];
          }

          if (!queryStrings.period && !queryStrings.end_date) {
            prevSelectDataRef.current = [CALENDAR_CONTENT_OPTIONS[0]];
          }

          return queryStrings;
        };

        const handleCalendarOnBlur =
          (): // e: React.FocusEvent<HTMLButtonElement, Element>
          "hide" | "show" => {
            // if (!e.relatedTarget?.classList.contains("calendar")) {
            const queryStrings = handleCalendarQueryParams();

            setShouldShow("hide");
            // calendarButtonRef.current?.blur();

            setTimeout(() => {
              // console.log(
              //   "from blur, is on action ref: ",
              //   isOnActionRef.current,
              //   ", prev selected: ",
              //   prevSelectDataRef.current,
              //   ", filled: ",
              //   filled
              // );

              if (/* !isOnActionRef.current && */ setSelectDataRef.current) {
                if (queryStrings.end_date && queryStrings.start_date) {
                  prevSelectDataRef.current = [
                    queryStrings.end_date[0].replace(
                      /(\d{4})-(\d{2})-(\d{2})/,
                      "$3/$2/$1"
                    ),
                    queryStrings.start_date[0].replace(
                      /(\d{4})-(\d{2})-(\d{2})/,
                      "$3/$2/$1"
                    ),
                  ];
                } else if (queryStrings.period) {
                  let calendarOptions;
                  if (menuVariant === "calendar") {
                    calendarOptions = [
                      ...CALENDAR_CONTENT_OPTIONS.map((option) =>
                        option.toLowerCase()
                      ),
                    ];
                  } else {
                    calendarOptions = [
                      ...CALENDAR_SLIP_CONTENT_OPTIONS.map((option) =>
                        option.toLowerCase()
                      ),
                    ];
                  }

                  const period = handleRevertTranslateCalendarOptions(
                    queryStrings.period[0] as TCalendarMenuOptions
                  );
                  if (period)
                    prevSelectDataRef.current =
                      menuVariant === "calendar"
                        ? [
                            CALENDAR_CONTENT_OPTIONS[
                              calendarOptions.indexOf(period)
                            ],
                          ]
                        : [
                            CALENDAR_SLIP_CONTENT_OPTIONS[
                              calendarOptions.indexOf(period)
                            ],
                          ];
                }

                // console.log(
                //   "from blur, prev select data ref: ",
                //   prevSelectDataRef.current
                // );

                setSelectDataRef.current(prevSelectDataRef.current);

                if (queryStrings.period) {
                  if (setCalendarSelectedDatesRef.current) {
                    setCalendarSelectedDatesRef.current({
                      endDate: "",
                      startDate: "",
                    });
                  }
                }

                if (!queryStrings.period && !queryStrings.end_date) {
                  if (setCalendarSelectedDatesRef.current) {
                    setCalendarSelectedDatesRef.current({
                      endDate: "",
                      startDate: "",
                    });
                  }
                }

                if (buttonSetClickStateRef.current)
                  buttonSetClickStateRef.current(false);
              }
            }, 20);
            return "hide";
            // } else {
            //   console.log("focusing");
            //   // calendarButtonRef.current?.focus();
            //   return "show";
            // }
          };

        const handleCalendarOnClickMenu = (
          _index: string,
          selected: string[],
          setSelected: React.Dispatch<React.SetStateAction<string[]>>,
          setShouldClearCalendar: React.Dispatch<React.SetStateAction<boolean>>
        ) => {
          setSelected(selected);

          // console.log(
          //   "from menu click, selected: ",
          //   selected,
          //   ", prev selected ref: ",
          //   prevSelectDataRef.current,
          //   ", filled: ",
          //   filled
          // );

          if (setCalendarSelectedDatesRef.current)
            setCalendarSelectedDatesRef.current((before) => {
              if (
                prevSelectDataRef.current[0] === "" &&
                filled?.length === 2 &&
                selected.length !== 2
              ) {
                return { endDate: "", startDate: "" };
              }

              return before;
            });

          setSelectDataRef.current = setSelected;
          setShouldClearCalendarRef.current = setShouldClearCalendar;
        };

        return (
          <Container ref={componentRef}>
            <Button
              componentRef={componentRef}
              $ref={calendarButtonRef}
              onBlur={handleCalendarOnBlur}
              onClick={handleCalendarOnClickButton}
              placeholder={selectedCalendarFilter}
              variant={buttonVariant}
              content={selectedCalendarFilter}
              isFiltered={isFiltered}
            />
            <Menu
              shouldShow={shouldShow}
              variant={menuVariant}
              list="filter"
              onClick={handleCalendarOnClickMenu}
              optGroup={optGroup}
              onCancel={handleCalendarOnCancelMenu}
              onAction={handleCalendarOnActionMenu}
              forceSelectOptionsByIndex={forceSelectOptionsByIndex}
              filled={
                setShouldClearCalendarRef.current
                  ? undefined
                  : prevSelectDataRef.current.length === 2
                  ? prevSelectDataRef.current
                  : filled
              }
              setSelectedDatesRef={setCalendarSelectedDatesRef}
            />
          </Container>
        );
    }
  }, [
    shouldShow,
    menuVariant,
    selectedDataLength,
    selectedCalendarFilter,
    shouldUpdate,
  ]);

  return handleRenderDropdown();
};

export default DropdownFilter;
