import { format } from "date-fns/format";
import { parse } from "date-fns/parse";

export const formatDate = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month > 9 ? month : `0${month}`}/${year}`;
};

export const formatTime = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours > 9 ? hours : `0${hours}`}:${
    minutes > 9 ? minutes : `0${minutes}`
  }`;
};

export const capitalizeFirstLetter = (word: string) =>
  word[0].toUpperCase() + word.substring(1);

export const cpfMask = (cpf: string) =>
  cpf.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

export const cnpjMask = (cnpj: string) =>
  cnpj
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

export const handleFormatDateAndTime = (dateString: string) => {
  const parsedDate = parse(dateString, "yyyy-MM-dd'T'HH:mm:ssX", new Date());

  const formatDate = format(parsedDate, "dd/MM/yyyy");
  const formatTime = format(parsedDate, "HH:mm");

  return { formatDate, formatTime };
};

export const buildArrayToQueryStringFields = (array: any[], field: string) =>
  array.map((value) => "&" + field + "=" + value).join("");

export const allowNumbersAndSingleCommaOnly = (value: string) =>
  value
    .replace(/[^\d,]|(?<=\d),(?!\d)|^,|,$/g, "")
    .replace(/,/g, (match, index) => {
      return index > value.indexOf(",") ? "" : match;
    });

export const convertNumberToBRL = (value: number) =>
  value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });

export const handleSortQuerystring = (queryString: URLSearchParams) =>
  "?" +
  Array.from(queryString.entries())
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

export const isValidEmail = (email: string) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const breakStringPreserveSpaces = (str: string, maxLength: number) => {
  const words = str.split(" ");
  let lines = [];
  let currentLine = "";

  for (const word of words) {
    if ((currentLine + word).length > maxLength) {
      lines.push(currentLine.trim());
      currentLine = word + " ";
    } else {
      currentLine += word + " ";
    }
  }

  if (currentLine.trim()) {
    lines.push(currentLine.trim());
  }

  return lines;
};
