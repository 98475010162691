import { ThunkDispatch } from "redux-thunk";
import { setToastState } from "../redux/reducers/globalState";
import { TApiError, TApiService } from "../services/api";
import { UnknownAction } from "redux";
import { translation } from "./translate";
import {
  TMembershipRole,
  TMembershipStatus,
} from "../redux/reducers/memberships";
import {
  CALENDAR_CONTENT_OPTIONS,
  CALENDAR_SLIP_CONTENT_OPTIONS,
  TCalendarMenuOptions,
  TCalendarSlipMenuOptions,
} from "../components/ui-v2/DropdownFilter/Menu";
import { sub } from "date-fns/sub";
import { startOfWeek } from "date-fns/startOfWeek";
import { startOfMonth } from "date-fns/startOfMonth";
import { ROUTES } from "../router";
import { add } from "date-fns/add";
import { endOfMonth, endOfWeek, format } from "date-fns";
import { localStorageConstants } from "./constants";
// import { AxiosError } from "axios";

export const isStatusTypeMatch = (value: string) => {
  switch (value.toLowerCase()) {
    case "pendente":
      return true;
    case "confirmada":
      return true;
    case "desconhecida":
      return true;
    case "nao-realizada":
      return true;
    case "recusada":
      return true;
    case "ciente":
      return true;
    default:
      return false;
  }
};

type TDispatch = ThunkDispatch<
  unknown,
  {
    apiService: TApiService;
  },
  UnknownAction
>;

export const errorHandler = (
  dispatch: TDispatch,
  error: TApiError,
  title?: string
) => {
  const errorTitle = error.message.title;
  const errorDescription = error.message.description;

  const translatedTitle = translation[errorTitle?.toLowerCase()] ?? errorTitle;
  const translatedDescription =
    translation[errorDescription?.toLowerCase()] ?? errorDescription;

  dispatch(
    setToastState({
      variant: "error",
      message: {
        title: title ?? translatedTitle,
        description: translatedDescription,
      },
      shouldShow: true,
    })
  );
};

export type TLiteralMembershipRole =
  | "Apenas leitura"
  | "Colaborador"
  | "Administrador"
  | "readOnly"
  | "contributor"
  | "administrator";

export const decideMembershipRole = (
  role: TMembershipRole | TLiteralMembershipRole
) => {
  switch (role) {
    case 10:
      return "Apenas leitura";
    case 20:
      return "Colaborador";
    case 30:
      return "Administrador";
    case "Apenas leitura":
      return 10;
    case "Colaborador":
      return 20;
    case "Administrador":
      return 30;
    case "readOnly":
      return 10;
    case "contributor":
      return 20;
    case "administrator":
      return 30;
  }
};

export type TLiteralMembershipStatus =
  | "Pendente"
  | "Ativo"
  | "Inativo"
  | "pending"
  | "active"
  | "disabled";

export const decideMembershipStatus = (
  status: TMembershipStatus | TLiteralMembershipStatus
) => {
  switch (status) {
    case 0:
      return "pending";
    case 1:
      return "active";
    case 2:
      return "disabled";
    case "Pendente":
      return 0;
    case "Ativo":
      return 1;
    case "Inativo":
      return 2;
    case "pending":
      return 0;
    case "active":
      return 1;
    case "disabled":
      return 2;
  }
};

export const validateEmail = (email: string) =>
  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email);

export const handleSetDataToLocalStorage = (keyName: string, data: any) => {
  localStorage.setItem(keyName, JSON.stringify(data));
};

export const handleGetDataFromLocalStorage = (keyname: string) => {
  let temp = localStorage.getItem(keyname);
  if (temp) {
    temp = JSON.parse(temp);
    const temp2 = temp as any;

    return temp2;
  }
  return null;
};

type TQuerystringParams = {
  organizationId: string;

  startDate?: string;
  endDate?: string;
  page: number;
  itemsPerPage: number;
  hasPayable?: boolean;
  hasGoods?: boolean;
  search?: string;
  launchStatus?: number;
};

export const getQuerystringParams = () => {
  let startDate = format(sub(new Date(), { days: 30 }), "yyyy-MM-dd");
  let endDate = format(new Date(), "yyyy-MM-dd");

  const params: TQuerystringParams = {
    startDate: undefined,
    endDate: undefined,
    page: 1,
    itemsPerPage: 100,
    hasPayable: undefined,
    hasGoods: undefined,
    search: undefined,
    launchStatus: undefined,
    organizationId: "",
  };

  const queryStrings = handleParseQuerystrings(window.location.search);

  if (queryStrings.period) {
    const translatedPeriod = handleRevertTranslateCalendarOptions(
      queryStrings.period[0] as TCalendarMenuOptions
    );

    let tempStartDate: Date | undefined = sub(new Date(), { days: 30 });

    if (window.location.href.includes("/invoices/")) {
      if (
        translatedPeriod &&
        CALENDAR_CONTENT_OPTIONS.some((calendarOption) =>
          calendarOption.toLowerCase().includes(translatedPeriod)
        )
      ) {
        tempStartDate = handleParseToDateCalendarOptions(
          translatedPeriod as TCalendarMenuOptions
        );
      }
    }

    if (window.location.href.includes("/payment-slip/")) {
      if (
        translatedPeriod &&
        CALENDAR_SLIP_CONTENT_OPTIONS.some((calendarOption) =>
          calendarOption.toLowerCase().includes(translatedPeriod)
        )
      ) {
        tempStartDate = handleParseToDateCalendarOptions(
          translatedPeriod as TCalendarMenuOptions
        );
      }
    }

    if (tempStartDate === undefined) {
      startDate = "";
      endDate = "";
    } else {
      startDate = format(tempStartDate, "yyyy-MM-dd");
    }

    endDate = handleSetEndDate(queryStrings.period[0]);
  } else if (queryStrings.start_date && queryStrings.end_date) {
    startDate = queryStrings.start_date[0];
    endDate = queryStrings.end_date[0];
  }

  handleSetDataToLocalStorage(
    localStorageConstants.INVOICE_FILTER_START_DATE,
    startDate
  );

  handleSetDataToLocalStorage(
    localStorageConstants.INVOICE_FILTER_END_DATE,
    endDate
  );

  const hasPayableHandler = () => {
    if (queryStrings.has_accounts_payable) {
      if (
        queryStrings.has_goods_receipt &&
        queryStrings.has_goods_receipt[0] === "false" &&
        queryStrings.has_accounts_payable[0] === "false"
      ) {
        return false;
      } else {
        return queryStrings.has_accounts_payable[0] === "true"
          ? true
          : undefined;
      }
    }
    return undefined;
  };

  const hasGoodsHandler = () => {
    if (queryStrings.has_goods_receipt) {
      if (
        queryStrings.has_accounts_payable &&
        queryStrings.has_accounts_payable[0] === "false" &&
        queryStrings.has_goods_receipt[0] === "false"
      ) {
        return false;
      } else {
        return queryStrings.has_goods_receipt[0] === "true" ? true : undefined;
      }
    }
    return undefined;
  };

  if (queryStrings.organization_id) {
    params.organizationId = queryStrings.organization_id[0];
  }

  if (queryStrings.launch_status) {
    const tempLaunchStatus = parseInt(queryStrings.launch_status[0]);
    params.launchStatus =
      tempLaunchStatus === 40 ? undefined : tempLaunchStatus;
  }

  if (queryStrings.search) {
    params.search = queryStrings.search[0];
  }

  if (queryStrings.page) {
    params.page = parseInt(queryStrings.page[0]);
  }

  if (queryStrings.items_per_page) {
    params.itemsPerPage = parseInt(queryStrings.items_per_page[0]);
  }

  params.startDate = /* endDate === "undefined" ? undefined :  */startDate;
  params.endDate = /* endDate === "undefined" ? undefined :  */endDate;
  params.hasPayable = hasPayableHandler();
  params.hasGoods = hasGoodsHandler();

  return params;
};

export const handleParseQuerystrings = (urlSearchString: string) => {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  let match;
  const queryStrings: {
    [key: string]: string[];
  } = {};

  while ((match = regex.exec(urlSearchString))) {
    if (queryStrings[match[1]]) {
      queryStrings[match[1]].push(window.decodeURI(match[2]));
    } else {
      queryStrings[match[1]] = [window.decodeURI(match[2])];
    }
  }
  return queryStrings;
};

export const handleSetEndDate = (startDate: string) => {
  switch (startDate.toLowerCase()) {
    case "ontem":
      return format(sub(new Date(), { days: 1 }), "yyyy-MM-dd");
    case "semana passada":
      return format(endOfWeek(sub(new Date(), { weeks: 1 })), "yyyy-MM-dd");
    case "mês passado":
      return format(endOfMonth(sub(new Date(), { months: 1 })), "yyyy-MM-dd");
    case "all-time":
    case "todo o período":
      // if (window.location.pathname.includes("/payment-slip/"))
        return "undefined";
      // else return format(new Date(), "yyyy-MM-dd");
    default:
      return format(new Date(), "yyyy-MM-dd");
  }
};

export const handleParseToDateCalendarOptions = (
  value: TCalendarMenuOptions | TCalendarSlipMenuOptions
) => {
  switch (value) {
    case "\u00FAltimos 30 dias":
    case "\u00DAltimos 30 dias":
      return sub(new Date(), { days: 30 });
    case "todo o per\u00EDodo":
    case "Todo o per\u00EDodo":
      return undefined;
    case "hoje":
    case "Hoje":
      return new Date();
    case "ontem":
    case "Ontem":
      return sub(new Date(), { days: 1 });
    case "\u00FAltimos 7 dias":
    case "\u00DAltimos 7 dias":
      return sub(new Date(), { days: 7 });
    case "\u00FAltimos 14 dias":
    case "\u00DAltimos 14 dias":
      return sub(new Date(), { days: 14 });
    case "\u00FAltimos 60 dias":
    case "\u00DAltimos 60 dias":
      return sub(new Date(), { days: 60 });
    case "\u00FAltimos 90 dias":
    case "\u00DAltimos 90 dias":
      return sub(new Date(), { days: 90 });
    case "esta semana":
    case "Esta semana":
      return startOfWeek(new Date());
    case "semana passada":
    case "Semana passada":
      return startOfWeek(sub(new Date(), { days: 7 }));
    case "este m\u00EAs":
    case "Este m\u00EAs":
      return startOfMonth(new Date());
    case "m\u00EAs passado":
    case "M\u00EAs passado":
      return startOfMonth(sub(new Date(), { days: 30 }));

    case "last-30-days":
      return sub(new Date(), { days: 30 });
    case "all-time":
      return undefined;
    case "today":
      return new Date();
    case "yesterday":
      return sub(new Date(), { days: 1 });
    case "last-7-days":
      return sub(new Date(), { days: 7 });
    case "last-14-days":
      return sub(new Date(), { days: 14 });
    case "last-60-days":
      return sub(new Date(), { days: 60 });
    case "last-90-days":
      return sub(new Date(), { days: 90 });
    case "this-week":
      return startOfWeek(new Date());
    case "last-week":
      return startOfWeek(sub(new Date(), { days: 7 }));
    case "this-month":
      return startOfMonth(new Date());
    case "last-month":
      return startOfMonth(sub(new Date(), { days: 30 }));

    case "Pr\u00F3ximos 7 dias":
    case "pr\u00F3ximos 7 dias":
      return add(new Date(), { days: 7 });
    case "Pr\u00F3ximos 14 dias":
    case "pr\u00F3ximos 14 dias":
      return add(new Date(), { days: 14 });
    case "Pr\u00F3ximos 60 dias":
    case "pr\u00F3ximos 60 dias":
      return add(new Date(), { days: 60 });
    case "Pr\u00F3ximos 90 dias":
    case "pr\u00F3ximos 90 dias":
      return add(new Date(), { days: 90 });
    case "Semana que vem":
    case "semana que vem":
      return startOfWeek(add(new Date(), { days: 7 }));
    case "M\u00EAs que vem":
    case "m\u00EAs que vem":
      return startOfMonth(add(new Date(), { days: 30 }));

    case "next-7-days":
      return add(new Date(), { days: 7 });
    case "next-14-days":
      return add(new Date(), { days: 14 });
    case "next-60-days":
      return add(new Date(), { days: 60 });
    case "next-90-days":
      return add(new Date(), { days: 90 });
    case "next-week":
      return startOfWeek(add(new Date(), { days: 7 }));
    case "next-month":
      return startOfMonth(add(new Date(), { days: 30 }));
  }
};

export const handleTranslateCalendarOptions = (
  value: TCalendarMenuOptions | TCalendarSlipMenuOptions
) => {
  switch (value) {
    case "\u00DAltimos 30 dias":
      return "last-30-days";
    case "Todo o per\u00EDodo":
      return "all-time";
    case "Hoje":
      return "today";
    case "Ontem":
      return "yesterday";
    case "\u00DAltimos 7 dias":
      return "last-7-days";
    case "\u00DAltimos 14 dias":
      return "last-14-days";
    case "\u00DAltimos 60 dias":
      return "last-60-days";
    case "\u00DAltimos 90 dias":
      return "last-90-days";
    case "Esta semana":
      return "this-week";
    case "Semana passada":
      return "last-week";
    case "Este m\u00EAs":
      return "this-month";
    case "M\u00EAs passado":
      return "last-month";

    case "\u00FAltimos 30 dias":
      return "last-30-days";
    case "todo o per\u00EDodo":
      return "all-time";
    case "hoje":
      return "today";
    case "ontem":
      return "yesterday";
    case "\u00FAltimos 7 dias":
      return "last-7-days";
    case "\u00FAltimos 14 dias":
      return "last-14-days";
    case "\u00FAltimos 60 dias":
      return "last-60-days";
    case "\u00FAltimos 90 dias":
      return "last-90-days";
    case "esta semana":
      return "this-week";
    case "semana passada":
      return "last-week";
    case "este m\u00EAs":
      return "this-month";
    case "m\u00EAs passado":
      return "last-month";

    case "Pr\u00F3ximos 7 dias":
    case "pr\u00F3ximos 7 dias":
      return "next-7-days";
    case "Pr\u00F3ximos 14 dias":
    case "pr\u00F3ximos 14 dias":
      return "next-14-days";
    case "Pr\u00F3ximos 60 dias":
    case "pr\u00F3ximos 60 dias":
      return "next-60-days";
    case "Pr\u00F3ximos 90 dias":
    case "pr\u00F3ximos 90 dias":
      return "next-90-days";
    case "Semana que vem":
    case "semana que vem":
      return "next-week";
    case "M\u00EAs que vem":
    case "m\u00EAs que vem":
      return "next-month";
  }
};

export const handleRevertTranslateCalendarOptions = (
  value: TCalendarMenuOptions | TCalendarSlipMenuOptions
) => {
  switch (value) {
    case "last-30-days":
      return "\u00FAltimos 30 dias";
    case "all-time":
      return "todo o per\u00EDodo";
    case "today":
      return "hoje";
    case "yesterday":
      return "ontem";
    case "last-7-days":
      return "\u00FAltimos 7 dias";
    case "last-14-days":
      return "\u00FAltimos 14 dias";
    case "last-60-days":
      return "\u00FAltimos 60 dias";
    case "last-90-days":
      return "\u00FAltimos 90 dias";
    case "this-week":
      return "esta semana";
    case "last-week":
      return "semana passada";
    case "this-month":
      return "este m\u00EAs";
    case "last-month":
      return "m\u00EAs passado";

    case "next-7-days":
      return "próximos 7 dias";
    case "next-14-days":
      return "próximos 14 dias";
    case "next-60-days":
      return "próximos 60 dias";
    case "next-90-days":
      return "próximos 90 dias";
    case "next-week":
      return "semana que vem";
    case "next-month":
      return "mês que vem";
  }
};

export const handlePopulateQuerystring = () => {
  const queryStrings = handleParseQuerystrings(window.location.search);

  const searchString = new URLSearchParams();

  if (window.location.href.includes(ROUTES.HOME)) {
    if (queryStrings.organization_id) {
      searchString.set("organization_id", queryStrings.organization_id[0]);
    }

    if (queryStrings.search && queryStrings.search[0].length > 0) {
      searchString.set("search", queryStrings.search[0]);
    }

    if (queryStrings.period && !queryStrings.start_date) {
      searchString.set("period", queryStrings.period[0]);
    }

    if (queryStrings.start_date && queryStrings.end_date) {
      searchString.set("start_date", queryStrings.start_date[0]);
      searchString.set("end_date", queryStrings.end_date[0]);
    }

    if (queryStrings.launch_status) {
      searchString.set("launch_status", queryStrings.launch_status[0]);
    }

    if (queryStrings.has_accounts_payable) {
      searchString.set(
        "has_accounts_payable",
        queryStrings.has_accounts_payable[0]
      );
    }

    if (queryStrings.has_goods_receipt) {
      searchString.set("has_goods_receipt", queryStrings.has_goods_receipt[0]);
    }

    if (queryStrings.page) {
      searchString.set("page", queryStrings.page[0]);
    }

    if (queryStrings.items_per_page) {
      searchString.set("items_per_page", queryStrings.items_per_page[0]);
    }
  }

  if (window.location.href.includes(ROUTES.SETTINGS_USERS)) {
    if (queryStrings.role) {
      if (queryStrings.role[0] === "3") {
        searchString.delete("role");
      } else {
        queryStrings.role.map((value) => searchString.append("role", value));
      }
    }
    if (queryStrings.status) {
      if (queryStrings.status[0] === "40") {
        searchString.delete("status");
      } else {
        queryStrings.status.map((value) =>
          searchString.append("status", value)
        );
      }
    }
  }

  if (window.location.href.includes(ROUTES.SETTINGS_ORGANIZATIONS)) {
    if (queryStrings.visibility) {
      searchString.set("visibility", queryStrings.visibility[0]);
    }
  }

  return searchString;
};

export const calculateInstallments = (
  fullAmount: number,
  installments: number
) => {
  const installmentValue = Math.floor((fullAmount / installments) * 100) / 100; // Garante que tenhamos duas casas decimais
  return Array.from({ length: installments }, (_, index) => {
    if (index === installments - 1) {
      // Última parcela recebe a diferença para ajustar eventuais arredondamentos
      return parseFloat(
        (fullAmount - installmentValue * (installments - 1)).toFixed(2)
      );
    }
    return installmentValue;
  });
};

export const extractErrorMessages = (errorObject: any) => {
  let messages: string[] = [];

  const extractMessages = (obj: any) => {
    if (typeof obj === "string") {
      messages.push(obj);
    } else if (Array.isArray(obj)) {
      obj.forEach((item) => extractMessages(item));
    } else if (typeof obj === "object" && obj !== null) {
      Object.entries(obj).forEach(([key, value]) => {
        extractMessages(
          ["non_field_erros", "non_field_errors"].includes(key)
            ? value
            : key + ": " + value
        );
      });
    }
  };

  extractMessages(errorObject);

  return (
    messages.length > 1
      ? messages.map((message) => `- ${message.trim()}`)
      : messages
  ).join(" ");
};

export const backendErrorMessageHandler = (e: any) => {
  if (e.response) {
    const error = e.response.data as any;
    if (e.status === 400) {
      // if (error.non_field_errors) {
      //   return error.non_field_errors[0] as string;
      // }
      return extractErrorMessages(error);
    }

    if (error && error.detail && typeof error.detail === "string") {
      return error.detail as string;
    }
  }

  return undefined;
};

// export const backendErrorMessageHandler = (e: {
//   response: { data: any };
//   status: number;
// }) => {
//   let error = e.response;
//   if (e.status === 400) {
//     if (error.data) {
//       if (error.data.non_field_errors) {
//         return error.data.non_field_errors[0] as string;
//       }

//       if (error.data.payment_information_attributes) {
//         const key = Object.keys(error.data.payment_information_attributes)[0];

//         return [
//           key,
//           error.data.payment_information_attributes[key],
//         ] as string[];
//       }
//     }
//     if (error.data.detail && typeof error.data.detail === "string") {
//       return error.data.detail as string;
//     }
//   }

//   return undefined;
// };
