import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TAccount } from "../account";
import { TOrganization, TOrganizationData } from "../organizations";
import { handleGetDataFromLocalStorage } from "../../../utils/miscellaneous";
import { localStorageConstants } from "../../../utils/constants";
import { /* TMembership,  */ TMembershipData } from "../memberships";
import { TPaidInstallments } from "../../../components/ui-v2/Modal/Forms/AddPayable";

type TToast = {
  shouldShow: boolean;
  message: { title: string; description: string; link?: { url: string } };
  variant: "success" | "error" | "information" | "loading";
};

type TInvoiceIssuer = {
  name: string;
  document_number: string;
};

export type TInvoiceManifestastionStatus = 10 | 20 | 30 | 40;
// 10 - ciente
// 20 - confirmada
// 30 - desconhecida
// 40 - recusada

export type TInvoiceEntryStatus = 10 | 20 | 30; // 10 - pendente, 20 - finalizada, 30 recusada

export type TInvoiceType = 10 | 20; // 10 - produto, 20 - serviço

export type TNexaasState = 10 | 20 | 30 | 40 | 50 | 60;
// 10 - entrada não criada
// 20 - entrada criada e finalizada
// 30 - entrada criada
// 40 - verificação com erros
// 50 - lançando estoques
// 60 - entrada finalizada

export type TDuplicate = {
  installment_number: number;
  amount: string;
  due_date: string;
};

export type TInvoice = {
  access_key: string;
  amount: string;
  date_issue: string;
  issuer: TInvoiceIssuer;
  launch_status: TInvoiceEntryStatus;
  manifestation: TInvoiceManifestastionStatus;
  number: number;
  uuid: string;
  file_xml: string | null;
  file_nfe: string | null;
  goods_receipt_url: string | null;
  accounts_payable_url: string | null;
  fintera_id: string | null;
  boletos_exists: boolean;
  label: string;
  invoice_type: TInvoiceType;
  nexaas_state: TNexaasState;
  nexaas_finished_at: string | null;
  duplicates: TDuplicate[];
};

export type TInvoiceData = {
  count: number;
  results: TInvoice[];
  current_page: number;
  pages: { [key: string]: string }[];
};

export type TInvoiceUpdatedAt = { responseStatus: number; date: string };

export type TPaymentSlip = {
  uuid: string;
  number: string;
  bar_code: string;
  amount: string;
  payer_name: string;
  payer_document: string;
  provider_name: string;
  provider_document: string;
  process_date: string;
  due_date: string;
  source: string;
  launch_status: TInvoiceEntryStatus;
  invoices_exists: boolean;
  file_boleto: string;
  accounts_payable_url: string | null;
  fintera_id: string | null;
  label: string;
  user: {
    uuid: string;
    email: string;
    first_name: string;
    last_name: string;
  };
};

export type TPaymentSlipData = {
  count: number;
  results: TPaymentSlip[];
  current_page: number;
  pages: { [key: string]: string }[];
};

export type TPaymentSlipUpdatedAt = { responseStatus: number; date: string };

export type TNexaasProduct = {
  index: number;
  sku: string | null;
  supplier_code: string;
  supplier_sku: string;
  description: string;
  ean: string;
  quantity: string;
  measure: string;
  verified_quantity: null | string;
  verified_measure: null | string;
  conversion_factor: {
    id: number;
    sales_measure: string;
    sales_quantity: string;
    purchase_measure: string;
    purchase_quantity: string;
  } | null;
  // batch?: null;
  batch_name?: string | null;
  batch_manufacturing_date?: string | null;
  batch_expiration_date?: string | null;
  reason?: string;
};

// export type TPaidElements = {
//   index: number;
//   fine: string;
//   interest: string;
//   discount: string;
//   paymentDate: string;
//   totalPaid: string;
//   payMethod: string;
// };

export type TGlobalState = {
  toast: TToast;
  logoutModal: { shouldShow: boolean };
  selectedAccount: TAccount | null;
  selectedOrganizationWithPermissions: TOrganization | null;
  selectOrganization: TOrganization | null;
  lastAccessedAccounts: TAccount[];
  lastAccessedOrganizations: TOrganization[];
  allowedOrganizations: TOrganization[] | null;
  organizationData: TOrganizationData | null;
  membershipData: TMembershipData | null;
  paymentSlipState: TPaymentSlipData | null;
  paymentSlipUpdatedAt: TPaymentSlipUpdatedAt;
  pendingPaymentSlipsCount: number;
  donePaymentSlipsCount: number;
  rejectPaymentSlipsCount: number;
  invoiceState: TInvoiceData | null;
  invoiceUpdatedAt: TInvoiceUpdatedAt;
  pendingInvoicesCount: number;
  doneInvoicesCount: number;
  rejectInvoicesCount: number;
  allPaidElements: TPaidInstallments[];
  attachedInvoices: TInvoice[];
  attachedPaymentSlips: TPaymentSlip[];
};

type TInitialState = {
  // loading: boolean;
  // error: null | string;
  data: TGlobalState;
};

const initialState: TInitialState = {
  // loading: false,
  // error: "",
  data: {
    toast: {
      variant: "success",
      shouldShow: false,
      message: { title: "", description: "" },
    },
    logoutModal: { shouldShow: false },
    selectedAccount: null,
    selectedOrganizationWithPermissions: null,
    selectOrganization: null,
    lastAccessedAccounts: [],
    lastAccessedOrganizations: [],
    allowedOrganizations: null,
    organizationData: null,
    membershipData: null,
    paymentSlipState: null,
    paymentSlipUpdatedAt: { responseStatus: 0, date: "" },
    pendingPaymentSlipsCount: 0,
    donePaymentSlipsCount: 0,
    rejectPaymentSlipsCount: 0,
    invoiceState: null,
    invoiceUpdatedAt: { responseStatus: 0, date: "" },
    pendingInvoicesCount: 0,
    doneInvoicesCount: 0,
    rejectInvoicesCount: 0,
    allPaidElements: [],
    attachedInvoices: [],
    attachedPaymentSlips: [],
  },
};

const globalStateSlice = createSlice({
  name: "globalState",
  initialState: initialState,
  reducers: {
    setToastState: (state, action: PayloadAction<TToast>) => {
      state.data.toast = action.payload;
    },
    setLogoutModalState: (
      state,
      action: PayloadAction<{ shouldShow: boolean }>
    ) => {
      state.data.logoutModal.shouldShow = action.payload.shouldShow;
    },
    setSelectedAccountState: (
      state,
      action: PayloadAction<{
        selectedAccount: TAccount;
        lastAccessedAccounts: TAccount[];
      }>
    ) => {
      const { selectedAccount, lastAccessedAccounts } = action.payload;

      const filteredResults = lastAccessedAccounts.filter(
        (account) => account.uuid !== selectedAccount.uuid
      );

      state.data.lastAccessedAccounts = [selectedAccount, ...filteredResults];

      state.data.selectedAccount = selectedAccount;
    },
    clearLastAccessedOrganizations: (state) => {
      state.data.lastAccessedOrganizations = [];
    },
    clearInvoices: (state) => {
      state.data.invoiceState = null;
    },
    clearPaymentSlips: (state) => {
      state.data.paymentSlipState = null;
    },
    setSelectedOrganizationWithPermissionsState: (
      state,
      action: PayloadAction<{
        selectedOrganization: TOrganization;
        // lastAccessedOrganizations: TOrganization[];
      }>
    ) => {
      const { selectedOrganization /* lastAccessedOrganizations */ } =
        action.payload;

      // const filteredResults = lastAccessedOrganizations.filter(
      //   (organization) => organization.uuid !== selectedOrganization.uuid
      // );

      // const lastAccessed = [selectedOrganization, ...filteredResults].filter(
      //   (value) => value !== null && value !== undefined
      // );

      // state.data.lastAccessedOrganizations = lastAccessed;

      // localStorage.setItem(
      //   "lastAccessedOrganizations",
      //   JSON.stringify(lastAccessed)
      // );

      // if (selectedOrganization)
      //   localStorage.setItem(
      //     "selectedOrganization",
      //     JSON.stringify(selectedOrganization)
      //   );

      state.data.selectedOrganizationWithPermissions = selectedOrganization;
    },
    setSelectOrganizationState: (state) => {
      const localSelectOrganization = handleGetDataFromLocalStorage(
        localStorageConstants.SELECT_ORGANIZATION
      ) as TOrganization;

      const localLastAccessedOrganizations = handleGetDataFromLocalStorage(
        localStorageConstants.LAST_ACCESSED_ORGANIZATIONS
      ) as TOrganization[];

      if (localSelectOrganization) {
        state.data.selectOrganization = localSelectOrganization;
      }

      if (localLastAccessedOrganizations) {
        state.data.lastAccessedOrganizations = localLastAccessedOrganizations;
      }
    },
    setOrganizationsState: (
      state,
      action: PayloadAction<{ organizationData: TOrganizationData }>
    ) => {
      state.data.organizationData = action.payload.organizationData;
    },
    setAllPaidElements: (
      state,
      action: PayloadAction<{ allPaidElements: TPaidInstallments[] }>
    ) => {
      state.data.allPaidElements = action.payload.allPaidElements;
    },
    setMembershipsState: (
      state,
      action: PayloadAction<{ membershipData: TMembershipData }>
    ) => {
      state.data.membershipData = action.payload.membershipData;
    },
    setInvoiceCounters: (
      state,
      action: PayloadAction<{
        counters: {
          pending_count: number;
          finished_count: number;
          declined_count: number;
        };
      }>
    ) => {
      state.data.pendingInvoicesCount = action.payload.counters.pending_count;
      state.data.doneInvoicesCount = action.payload.counters.finished_count;
      state.data.rejectInvoicesCount = action.payload.counters.declined_count;
    },
    setInvoices: (
      state,
      action: PayloadAction<{
        invoices: TInvoiceData;
        entryStatus?: TInvoiceEntryStatus;
      }>
    ) => {
      state.data.invoiceState = action.payload.invoices;
      // const mockResult = {
      //   ...action.payload.invoices.results[1],
      //   duplicates: [
      //     {
      //       installment_number: 10,
      //       amount: "50.00",
      //       due_date: "2024-08-31",
      //     },{
      //       installment_number: 10,
      //       amount: "3.00",
      //       due_date: "2024-01-29",
      //     },{
      //       installment_number: 10,
      //       amount: "50.00",
      //       due_date: "2024-02-29",
      //     },{
      //       installment_number: 10,
      //       amount: "30.00",
      //       due_date: "2024-04-29",
      //     },{
      //       installment_number: 10,
      //       amount: "3.00",
      //       due_date: "2024-03-29",
      //     },
      //   ],
      // };
      // const tempResults = [
      //   action.payload.invoices.results[0],
      //   mockResult,
      //   ...action.payload.invoices.results.slice(
      //     2,
      //     action.payload.invoices.results.length - 1
      //   ),
      // ];
      // state.data.invoiceState = {
      //   ...action.payload.invoices,
      //   results: [...tempResults],
      // };
      // if (action.payload.entryStatus) {
      //   switch (action.payload.entryStatus) {
      //     case 10:
      //       state.data.pendingInvoicesCount = action.payload.invoices.count;
      //       break;
      //     case 20:
      //       state.data.doneInvoicesCount = action.payload.invoices.count;
      //       break;
      //     case 30:
      //       state.data.rejectInvoicesCount = action.payload.invoices.count;
      //       break;
      //   }
      // }
    },
    setPaymentSlipCounters: (
      state,
      action: PayloadAction<{
        counters: {
          pending_count: number;
          finished_count: number;
          declined_count: number;
        };
      }>
    ) => {
      state.data.pendingPaymentSlipsCount =
        action.payload.counters.pending_count;
      state.data.donePaymentSlipsCount = action.payload.counters.finished_count;
      state.data.rejectPaymentSlipsCount =
        action.payload.counters.declined_count;
    },
    setPaymentSlips: (
      state,
      action: PayloadAction<{
        paymentSlips: TPaymentSlipData;
        entryStatus?: TInvoiceEntryStatus;
      }>
    ) => {
      state.data.paymentSlipState = action.payload.paymentSlips;
      // if (action.payload.entryStatus) {
      //   switch (action.payload.entryStatus) {
      //     case 10:
      //       state.data.pendingPaymentSlipsCount =
      //         action.payload.paymentSlips.count;
      //       break;
      //     case 20:
      //       state.data.donePaymentSlipsCount =
      //         action.payload.paymentSlips.count;
      //       break;
      //     case 30:
      //       state.data.rejectPaymentSlipsCount =
      //         action.payload.paymentSlips.count;
      //       break;
      //   }
      // }
    },
    setAttachedInvoices: (state, action: PayloadAction<TInvoice[]>) => {
      state.data.attachedInvoices = action.payload;
    },
    setAttachedPaymentSlips: (state, action: PayloadAction<TPaymentSlip[]>) => {
      state.data.attachedPaymentSlips = action.payload;
    },
    setInvoicesUpdatedAt: (state, action: PayloadAction<TInvoiceUpdatedAt>) => {
      state.data.invoiceUpdatedAt = {
        responseStatus: action.payload.responseStatus,
        date: action.payload.date,
      };
    },
    setPaymentSlipsUpdatedAt: (
      state,
      action: PayloadAction<TPaymentSlipUpdatedAt>
    ) => {
      state.data.paymentSlipUpdatedAt = {
        responseStatus: action.payload.responseStatus,
        date: action.payload.date,
      };
    },
    setAllowedOrganizationsState: (
      state,
      action: PayloadAction<{
        allowedOrganizations: TOrganization[];
      }>
    ) => {
      const { allowedOrganizations } = action.payload;

      state.data.allowedOrganizations = allowedOrganizations;
    },
  },
});

export const {
  setToastState,
  setSelectedAccountState,
  setSelectedOrganizationWithPermissionsState,
  setSelectOrganizationState,
  setAllowedOrganizationsState,
  setAllPaidElements,
  setOrganizationsState,
  setMembershipsState,
  setInvoices,
  setLogoutModalState,
  setInvoicesUpdatedAt,
  clearLastAccessedOrganizations,
  clearInvoices,
  clearPaymentSlips,
  setPaymentSlips,
  setAttachedInvoices,
  setAttachedPaymentSlips,
  setPaymentSlipsUpdatedAt,
  setInvoiceCounters,
  setPaymentSlipCounters,
} = globalStateSlice.actions;
export default globalStateSlice.reducer;
