import styled from "styled-components";
import { useCallback, useEffect, useId, useRef, useState } from "react";
import { TInfiniteScroller, TOptGroupOptions, TVariants } from ".";
import Search from "../Inputs/Search";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { TAccount } from "../../../redux/reducers/account";
import { handleGetDataFromLocalStorage } from "../../../utils/miscellaneous";
import { localStorageConstants } from "../../../utils/constants";
import Spinner from "../Spinner";
import InfiniteScroller from "../../InfiniteScroller";
import { cnpjMask } from "../../../utils/formatters";
import { TOrganization } from "../../../redux/reducers/organizations";
import React from "react";

const Container = styled.menu<{
  $shouldshow: "show" | "hide";
  $variant: TVariants;
}>`
  box-sizing: border-box;
  width: 100%;

  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;

  border-radius: 8px;
  border: 1px solid var(--color-dark-blue-300);

  padding: ${({ $variant }) => ($variant === "labeled" ? "16" : "8")}px;

  margin: 0;

  position: absolute;
  height: ${({ $shouldshow }) => ($shouldshow === "show" ? "auto" : "0")};
  transition: visibility 0.1s ease-in-out;
  z-index: ${({ $shouldshow }) => ($shouldshow === "show" ? "2" : "-1")};
  visibility: ${({ $shouldshow }) =>
    $shouldshow === "show" ? "visible" : "hidden"};
  transition: z-index 0.15s ease-in-out, visibility 0.15s ease-in-out,
    height 0.15s ease-in-out;

  background: var(--color-light-0);

  div.spinnerContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  & button:nth-child(1) {
    width: 100%;
    ${({ $variant }) => $variant === "labeled" && "margin-bottom: 10px;"}
    // margin-bottom: 10px;
  }

  & button:nth-child(1),
  & p.optGroup {
    visibility: ${({ $shouldshow }) =>
      $shouldshow === "show" ? "visible" : "hidden"};
    transition: visibility 0.1s ease-in-out;
  }

  & .optGroupContainer {
    position: relative;
    height: 31px;
  }

  & p.optGroup {
    position: absolute;
    z-index: 1;

    top: 25%;
    left: 12px;

    font-family: var(--font-paragraph-default);
    font-size: 12px;
    color: var(--color-dark-blue-400);
  }

  &::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    height: ${({ $shouldshow }) => ($shouldshow === "show" ? "100%" : "0%")};
    width: 100%;

    background: var(--color-light-0);
    border-radius: 8px;
    // border: 1px solid var(--color-dark-blue-300);
    border-width: ${({ $shouldshow }) =>
      $shouldshow === "show" ? "1px" : "0px"};
    transition: height 0.2s ease-in-out;
  }

  p.content,
  & button.active {
    visibility: ${({ $shouldshow }) =>
      $shouldshow === "show" ? "visible" : "hidden"};
  }
`;

const ItemButton = styled.button<{
  $label?: string;
  $value?: string;
  $variant: TVariants;
  $shouldshow: "show" | "hide";
  $beforeClassComponetRef?: HTMLParagraphElement;
}>`
  display: flex;
  align-items: center;

  position: relative;

  cursor: pointer;

  min-height: 46px;
  height: ${({ $beforeClassComponetRef }) =>
    $beforeClassComponetRef &&
    $beforeClassComponetRef.getBoundingClientRect().height > 17
      ? $beforeClassComponetRef.getBoundingClientRect().height + 30
      : 46}px;
  width: 100%;
  text-align: start;

  // padding: 12px;

  background: var(--color-light-0);

  border-radius: 8px;

  visibility: ${({ $shouldshow }) =>
    $shouldshow === "show" ? "visible" : "hidden"};
  transition: visibility 0.1s ease-in-out;

  ${({ $label }) =>
    $label &&
    `&::before, & .before{
      max-width: 230px;
      content:'${$label}';
      font-size: 14px;
      font-family: var(--font-paragraph-bold);
      color: var(--color-text-paragraph);

      position: absolute;
      top: 7px;
      left: 12px;
    }` + `& .before { visibility: hidden;}`}

  p.content {
    ${({ $variant, $beforeClassComponetRef }) =>
      $variant === "unlabeled"
        ? `
        font-family: var(--font-paragraph-default);
        color: var(--color-text-paragraph);
        font-size: 14px;
        // top: 16px;
      `
        : `
        font-family: var(--font-paragraph-default);
        color: var(--color-text-labels);
        font-size: 12px;` +
          `top: ${
            $beforeClassComponetRef &&
            $beforeClassComponetRef.getBoundingClientRect().height > 17
              ? $beforeClassComponetRef.getBoundingClientRect().height + 11
              : 26
          }px;`}

    width: calc(100% - 40px);
    position: absolute;
    left: 12px;
  }

  div.naturalPadding {
    visibility: hidden;

    &::before {
      content: "${({ $value }) => $value}";
      width: calc(100% - 40px);
      font-size: 14px;
    }
  }

  &::after {
    content: "";
    font-family: "unicons";
    font-size: 20px;
    position: absolute;
    right: 12px;
    top: 25%;
    color: var(--color-light-0);
    visibility: hidden;
  }

  &:hover {
    background: var(--color-light-200);
  }

  &.active,
  &:active {
    visibility: ${({ $shouldshow }) =>
      $shouldshow === "show" ? "visible" : "hidden"};
    transition: visibility 0.1s ease-in-out;

    background: var(--color-main-blue);

    &::before,
    p {
      color: var(--color-light-0);
    }

    &::after {
      visibility: visible;
    }
  }
`;

type TCommonProps = {
  list: "default" | "filter";
  content: string[];
  onClick: (
    selectedIndex: string,
    selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
    value?: string
  ) => void;
  shouldShow: "show" | "hide";
  searchId?: string;
  searchPhraseOnChange?: (searchPhrase: string) => void;
  searchPhraseOnSearch?: (searchPhrase: string) => void;
  optGroup?: TOptGroupOptions[];
  forceSelectOptionsByIndex?: number[];
  hasInfiniteScrolling?: TInfiniteScroller;
  setData?: (
    setSelectedData: React.Dispatch<React.SetStateAction<string[]>>
  ) => void;
};

type TLabeled = {
  variant: "labeled";
  label: string[];
};

type TUnlabeled = {
  variant: "unlabeled";
  label?: never;
};

type TMenuProps<Variant extends TVariants> = Variant extends "labeled"
  ? TLabeled & TCommonProps
  : TUnlabeled & TCommonProps;

const Menu: React.FC<TMenuProps<TVariants>> = ({
  variant,
  // list,
  content,
  label,
  onClick,
  shouldShow,
  searchId,
  optGroup,
  forceSelectOptionsByIndex,
  searchPhraseOnChange,
  searchPhraseOnSearch,
  hasInfiniteScrolling,
  setData,
}) => {
  const ITEMS_TO_RENDER_AMOUNT = 10;

  const [selected, setSelected] = useState([""]);

  const selectedValueRef = useRef("");
  const elementsAmountRef = useRef(0);

  const beforeClassComponentRefs = useRef<HTMLParagraphElement[]>([]);
  const beforeClassComponentRefs2 = useRef<HTMLParagraphElement[]>([]);
  const beforeClassComponentRefs3 = useRef<HTMLParagraphElement[]>([]);
  const [beforeClassComponents, setBeforeClassComponents] = useState<
    HTMLParagraphElement[]
  >([]);

  const [contentToRender, setContentToRender] = useState<string[]>([]);
  const [labelToRender, setLabelToRender] = useState<string[]>([]);
  const [searchPhrase, setSearchPhrase] = useState<string | undefined>();

  const forceSelectOptionsByIndexRef = useRef<number[] | undefined>(
    forceSelectOptionsByIndex
  );

  const lastAccessedOrganizations = useTypedSelector(
    (state) => state.globalState.data.lastAccessedOrganizations
  );

  const [, /* selectAccount */ setSelectAccount] = useState<TAccount | null>(
    null
  );
  const selectOrganization = useTypedSelector(
    (state) => state.globalState.data.selectOrganization
  );

  const spinnerId = useId();

  const clickHandler = (
    _e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
    value?: string
  ) => {
    // e.preventDefault();
    onClick(`${index}`, selected, setSelected, value);
  };

  const searchPhraseOnChangeHandler = (searchPhrase: string) => {
    // console.log("search phrase change handler: ", searchPhrase);
    setSelected([]);

    if (searchPhrase.length > 0) {
      // setSelected((prev) => {
      //   console.log(
      //     "content: ",
      //     content,
      //     ", selected value ref: ",
      //     selectedValueRef.current,
      //     ", index: ",
      //     content.indexOf(
      //       content.find((element) =>
      //         element.includes(searchPhrase ?? "")
      //       ) ?? ""
      //     ),
      //     ", selected: ",
      //     prev
      //   );
      //   return [`${content.indexOf(selectedValueRef.current)}`];
      // });

      if (
        hasInfiniteScrolling &&
        hasInfiniteScrolling.variant === "organizations" &&
        [...searchPhrase].some((char) => [".", "/", "-"].includes(char))
      ) {
        setSearchPhrase(searchPhrase.replace(/\D/g, ""));
      } else {
        setSearchPhrase(searchPhrase);
      }

      if (searchPhraseOnChange) searchPhraseOnChange(searchPhrase);
      setContentToRender([]);
      setLabelToRender([]);
    } else {
      // console.log(
      //   "content: ",
      //   content,
      //   ", content to render: ",
      //   contentToRender
      // );
      setSearchPhrase(undefined);
      // if (searchPhraseOnChange) searchPhraseOnChange(undefined);
      setContentToRender([]);
      setLabelToRender([]);
    }
  };

  const handleRenderMenu = useCallback(() => {
    if (
      hasInfiniteScrolling &&
      hasInfiniteScrolling.variant === "organizations"
    ) {
      // setBeforeClassComponents([]);
      setTimeout(() => {
        setBeforeClassComponents(beforeClassComponentRefs2.current);
      }, 50);
      return (
        <>
          <React.Fragment key={hasInfiniteScrolling.totalElements + ""}>
            {contentToRender.length > 0 &&
              contentToRender.map((value, index) => {
                setTimeout(() => {
                  const itemButtonComponent = document.getElementById(
                    "itemButton-" + value
                  );

                  const contentClassElement = document.getElementById(
                    "content-" + value
                  );
                  if (
                    itemButtonComponent &&
                    itemButtonComponent.style.visibility !== "visible" &&
                    shouldShow === "show"
                  ) {
                    itemButtonComponent.style.visibility = "visible";
                  } else if (itemButtonComponent && shouldShow === "hide") {
                    itemButtonComponent.style.visibility = "hidden";
                  }

                  if (
                    beforeClassComponentRefs2.current[index] &&
                    beforeClassComponentRefs2.current[
                      index
                    ].getBoundingClientRect().height > 17
                  ) {
                    if (contentClassElement && itemButtonComponent) {
                      const beforeClassElementHeight =
                        beforeClassComponentRefs2.current[
                          index
                        ].getBoundingClientRect().height;

                      if (
                        itemButtonComponent.getBoundingClientRect().height <
                        beforeClassElementHeight + 30
                      ) {
                        itemButtonComponent.style.height =
                          beforeClassElementHeight + 30 + "px";
                      }

                      if (
                        contentClassElement.getBoundingClientRect().height <
                        beforeClassElementHeight + 11
                      ) {
                        contentClassElement.style.top =
                          beforeClassElementHeight + 11 + "px";
                      }
                    }
                  } else {
                    if (contentClassElement && itemButtonComponent) {
                      // const beforeClassElementHeight =
                      //   beforeClassComponentRefs2.current[
                      //     index
                      //   ].getBoundingClientRect().height;

                      // if (
                      //   itemButtonComponent.getBoundingClientRect().height <
                      //   beforeClassElementHeight + 30
                      // ) {
                      itemButtonComponent.style.height = 46 + "px";
                      // }

                      // if (
                      //   contentClassElement.getBoundingClientRect().height <
                      //   beforeClassElementHeight + 11
                      // ) {
                      contentClassElement.style.top = 26 + "px";
                      // }
                    }
                  }
                }, 50);

                return (
                  <ItemButton
                    $shouldshow={shouldShow}
                    id={"itemButton-" + value}
                    className={
                      forceSelectOptionsByIndexRef.current?.includes(index) ||
                      selected.includes(`${index}`) ||
                      selectOrganization?.cnpj === value
                        ? "active"
                        : ""
                    }
                    $label={
                      labelToRender /* Ref.current */ &&
                      labelToRender /* Ref.current */[index]
                    }
                    $value={value}
                    $variant={variant}
                    key={index}
                    onClick={(e) => {
                      // console.log(
                      //   "e: ",
                      //   e,
                      //   ", index: ",
                      //   index,
                      //   ", selected: ",
                      //   selected
                      // );
                      clickHandler(e, index);
                      // forceSelectOptionsByIndexRef.current = [];
                    }}
                    $beforeClassComponetRef={
                      beforeClassComponentRefs2.current[index]
                    }
                  >
                    {labelToRender && (
                      <p
                        ref={(ref) => {
                          if (ref)
                            beforeClassComponentRefs2.current[index] = ref;
                        }}
                        id={value}
                        className="before"
                      >
                        {labelToRender[index]}
                      </p>
                    )}
                    <p id={"content-" + value} className="content">
                      {optGroup && optGroup.includes("Últimos acessados")
                        ? cnpjMask(value)
                        : value}
                    </p>
                    <div className="naturalPadding">{value}</div>
                  </ItemButton>
                );
              })}
          </React.Fragment>

          <div className="spinnerContainer">
            <Spinner
              style={{
                height:
                  hasInfiniteScrolling.totalElements > contentToRender.length
                    ? "unset"
                    : "0px",
              }}
              id={spinnerId}
            />
          </div>

          <InfiniteScroller
            key={searchPhrase}
            variant="organizations"
            spinnerId={spinnerId}
            children={contentToRender /* Ref.current */}
            itemsToRenderAmount={ITEMS_TO_RENDER_AMOUNT}
            searchParam={searchPhrase}
            onIntersect={(data: TOrganization[], elementsAmount: number) => {
              // console.log("search param: ", searchPhrase, ", data: ", data);

              // console.log(
              //   "data: ",
              //   data,
              //   ", content: ",
              //   content,
              //   ", content to render: ",
              //   contentToRender
              // );

              if (hasInfiniteScrolling.onIntersect) {
                hasInfiniteScrolling.onIntersect(data, elementsAmount);
              }

              // setContentToRender(
              //   data.map((organization) => organization.cnpj)
              // );

              // setLabelToRender(
              //   data.map((organization) => organization.company_name)
              // );
            }}
          />
        </>
      );
    }

    return (
      <>
        {(content.length > ITEMS_TO_RENDER_AMOUNT
          ? contentToRender
          : content
        )?.map((value, index) => {
          setTimeout(() => {
            const itemButtonComponent = document.getElementById(
              "itemButton3-" + value
            );

            const contentClassElement = document.getElementById(
              "content3-" + value
            );
            // if (
            //   itemButtonComponent &&
            //   itemButtonComponent.style.visibility !== "visible" &&
            //   shouldShow === "show"
            // ) {
            //   itemButtonComponent.style.visibility = "visible";
            // }

            if (
              beforeClassComponentRefs3.current[index] &&
              beforeClassComponentRefs3.current[index].getBoundingClientRect()
                .height > 17
            ) {
              if (contentClassElement && itemButtonComponent) {
                const beforeClassElementHeight =
                  beforeClassComponentRefs3.current[
                    index
                  ].getBoundingClientRect().height;

                if (
                  itemButtonComponent.getBoundingClientRect().height <
                  beforeClassElementHeight + 30
                ) {
                  itemButtonComponent.style.height =
                    beforeClassElementHeight + 30 + "px";
                }

                // if (
                //   contentClassElement.getBoundingClientRect().height <
                //   beforeClassElementHeight + 11
                // ) {
                //   contentClassElement.style.top =
                //     beforeClassElementHeight + 11 + "px";
                // }
              }
            } else {
              if (contentClassElement && itemButtonComponent) {
                // const beforeClassElementHeight =
                //   beforeClassComponentRefs2.current[
                //     index
                //   ].getBoundingClientRect().height;

                // if (
                //   itemButtonComponent.getBoundingClientRect().height <
                //   beforeClassElementHeight + 30
                // ) {
                itemButtonComponent.style.height = 46 + "px";
                // }

                // if (
                //   contentClassElement.getBoundingClientRect().height <
                //   beforeClassElementHeight + 11
                // ) {
                // contentClassElement.style.top = 26 + "px";
                // }
              }
            }
          }, 50);
          return (
            <ItemButton
              id={"itemButton3-" + value}
              $shouldshow={shouldShow}
              className={
                forceSelectOptionsByIndexRef.current?.includes(index) ||
                (!(optGroup && optGroup.includes("Últimos acessados")) &&
                  selected.includes(`${index}`)) /* ||
                selectOrganization?.cnpj === value */
                  ? "active"
                  : ""
              }
              $label={label && label[index]}
              $value={value}
              $variant={variant}
              key={index}
              onClick={(e) => {
                selectedValueRef.current = value;
                clickHandler(e, index, value);
                forceSelectOptionsByIndexRef.current = [];
              }}
              $beforeClassComponetRef={beforeClassComponentRefs3.current[index]}
            >
              {/* <p
                ref={(ref) => {
                  if (ref) beforeClassComponentRefs3.current[index] = ref;
                }}
                className="before"
                style={{ visibility: "hidden" }}
              >
                {value}
              </p> */}
              <p
                ref={(ref) => {
                  if (ref) beforeClassComponentRefs3.current[index] = ref;
                }}
                id={"content3-" + value}
                className="content"
              >
                {value}
              </p>
              <div className="naturalPadding">{value}</div>
            </ItemButton>
          );
        })}

        <div className="spinnerContainer">
          <Spinner
            style={(() =>
              (
                searchPhrase === undefined
                  ? content.length > ITEMS_TO_RENDER_AMOUNT &&
                    contentToRender.length < content.length
                  : elementsAmountRef.current > contentToRender.length
              )
                ? { height: "unset", width: "unset" }
                : { height: "0px", width: "0px" })()}
            id={spinnerId}
          />
        </div>
        {content && (
          <InfiniteScroller
            // key={contentToRender.length}
            variant="nonPaginated"
            spinnerId={spinnerId}
            children={content}
            itemsToRenderAmount={ITEMS_TO_RENDER_AMOUNT}
            onIntersect={(data: string[], elementsAmount: number) => {
              // console.log(
              //   "data length: ",
              //   data.length,
              //   ", elements amount: ",
              //   elementsAmount,
              //   ", search phrase: ",
              //   searchPhrase
              // );
              elementsAmountRef.current = elementsAmount;
              // console.log(
              //   "data: ",
              //   data,
              //   ", content: ",
              //   content,
              //   ", content to render: ",
              //   contentToRender
              // );
              // if (searchPhrase && searchPhrase.length > 0) {
              //   setSelected([`${content.indexOf(selectedValueRef.current)}`]);
              // }
              setContentToRender(data);
            }}
            searchParam={searchPhrase}
          />
        )}
      </>
    );
  }, [
    hasInfiniteScrolling,
    beforeClassComponents,
    contentToRender,
    forceSelectOptionsByIndexRef,
    selected,
    selectOrganization,
    labelToRender,
    optGroup,
    content,
    label,
    shouldShow,
  ]);

  useEffect(() => {
    const localSelectAccount = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ACCOUNT
    );
    if (localSelectAccount) setSelectAccount(localSelectAccount);

    if (setData) setData(setSelected);
  }, []);

  useEffect(() => {
    if (
      hasInfiniteScrolling &&
      hasInfiniteScrolling.variant !== "nonPaginated"
    ) {
      // console.log(
      //   "has infinite scrolling count: ",
      //   hasInfiniteScrolling.totalElements
      // );
      if (
        (contentToRender /* Ref.current */.length === 0 &&
          content.length > 0) ||
        (contentToRender.length > 0 && contentToRender.length < content.length)
      ) {
        // contentToRenderRef.current = content;
        setContentToRender(content);
      }
      // console.log(
      //   "content: ",
      //   content,
      //   ", content to render: ",
      //   contentToRender,
      //   ", label: ",
      //   label,
      //   ", label to render: ",
      //   labelToRender
      // );

      if (
        label &&
        ((labelToRender.length === 0 && label.length > 0) ||
          (labelToRender.length > 0 && labelToRender.length < label.length))
      ) {
        // labelToRenderRef.current = label;
        setLabelToRender(label);
      }
    }
  }, [content, label]);

  return (
    <Container $variant={variant} $shouldshow={shouldShow}>
      {searchId && searchId.length > 0 && (
        <Search
          placeholder="Pesquisar"
          id={searchId}
          variant="small"
          onChange={searchPhraseOnChangeHandler}
          onSearch={searchPhraseOnSearch}
          onClear={() => {
            // console.log("selected value ref: ", selectedValueRef.current);
            if (hasInfiniteScrolling?.variant !== "organizations") {
              if (selectedValueRef.current.length > 0) {
                setSelected([`${content.indexOf(selectedValueRef.current)}`]);
              }
            } else {
              setSelected([]);
            }

            setSearchPhrase(undefined);
            setContentToRender([]);
            setLabelToRender([]);
          }}
        />
      )}
      {optGroup &&
        optGroup.includes("Últimos acessados") &&
        lastAccessedOrganizations.length > 0 && (
          <div className="optGroupContainer">
            <p className="optGroup">Últimas acessadas</p>
          </div>
        )}
      {optGroup &&
        optGroup.includes("Últimos acessados") &&
        lastAccessedOrganizations
          ?.filter((organization) => {
            return contentToRender.some(
              (content) => content === organization.cnpj
            );
          })
          // ?.filter(
          //   (organization) => organization.account.uuid === selectAccount?.uuid
          // )
          .map((organization, index) => {
            setTimeout(() => {
              const itemButtonComponent = document.getElementById(
                organization.cnpj + "-itemButton-last"
              );

              const contentClassElement = document.getElementById(
                organization.cnpj + "-content-last"
              );

              if (
                itemButtonComponent &&
                itemButtonComponent.style.visibility !== "visible" &&
                shouldShow === "show"
              ) {
                itemButtonComponent.style.visibility = "visible";
              } else if (itemButtonComponent && shouldShow === "hide") {
                itemButtonComponent.style.visibility = "hidden";
              }

              if (
                beforeClassComponentRefs.current[index] &&
                beforeClassComponentRefs.current[index].getBoundingClientRect()
                  .height > 17
              ) {                

                if (contentClassElement && itemButtonComponent) {
                  const beforeClassElementHeight =
                    beforeClassComponentRefs.current[
                      index
                    ].getBoundingClientRect().height;

                  if (
                    itemButtonComponent.getBoundingClientRect().height <
                    beforeClassElementHeight + 30
                  ) {
                    itemButtonComponent.style.height =
                      beforeClassElementHeight + 30 + "px";
                  }

                  if (
                    contentClassElement.getBoundingClientRect().height <
                    beforeClassElementHeight + 11
                  ) {
                    contentClassElement.style.top =
                      beforeClassElementHeight + 11 + "px";
                  }
                }
              } else {
                if (contentClassElement && itemButtonComponent) {
                  // const beforeClassElementHeight =
                  //   beforeClassComponentRefs2.current[
                  //     index
                  //   ].getBoundingClientRect().height;

                  // if (
                  //   itemButtonComponent.getBoundingClientRect().height <
                  //   beforeClassElementHeight + 30
                  // ) {
                  itemButtonComponent.style.height = 46 + "px";
                  // }

                  // if (
                  //   contentClassElement.getBoundingClientRect().height <
                  //   beforeClassElementHeight + 11
                  // ) {
                  contentClassElement.style.top = 26 + "px";
                  // }
                }
              }
            }, 60);
            return (
              <ItemButton
                id={organization.cnpj + "-itemButton-last"}
                $shouldshow={shouldShow}
                className={
                  selectOrganization?.cnpj === organization.cnpj ? "active" : ""
                }
                $label={organization.company_name}
                $variant={variant}
                key={index}
                onClick={(e) => {
                  selectedValueRef.current = organization.cnpj;
                  clickHandler(
                    e,
                    content.length > ITEMS_TO_RENDER_AMOUNT
                      ? contentToRender.indexOf(organization.cnpj)
                      : content.indexOf(organization.cnpj)
                  );
                }}
                $beforeClassComponetRef={
                  beforeClassComponentRefs.current[index]
                }
              >
                <p
                  ref={(ref) => {
                    if (ref) beforeClassComponentRefs.current[index] = ref;
                  }}
                  className="before"
                >
                  {organization.company_name}
                </p>
                <p id={organization.cnpj + "-content-last"} className="content">
                  {cnpjMask(organization.cnpj)}
                </p>
              </ItemButton>
            );
          })}

      {optGroup &&
        optGroup.includes("Todos") &&
        lastAccessedOrganizations.length > 0 && (
          <div className="optGroupContainer">
            <p className="optGroup">Todas</p>
          </div>
        )}

      {handleRenderMenu()}
    </Container>
  );
};

export default Menu;
