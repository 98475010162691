import styled from "styled-components";
import {
  TTableBody,
  TRow,
  TCellStyleVariants,
  TSelection,
  TCell,
  TCellButton,
  TFullData,
  TSecondRow,
  TText,
} from ".";
import Check from "../Inputs/Check";
import Radio from "../Inputs/Radio";
import { Fragment, useState } from "react";
import Button, { TWithIconVariants } from "../Button";
import Tag from "../Tag";
import DropdownOnlyIcon from "../DropdownOnlyIcon";
import React from "react";
import Skeleton from "../Skeleton";
import Tooltip from "../Tooltip";
import { Property } from "csstype";

const Container = styled.tbody<{
  $row: TRow;
  $hasFooter: "hasFooter" | "noFooter";
  $gridTemplateColumns: string;
}>`
  font-size: 14px;
  font-family: var(--font-paragraph-default);
  color: var(--color-text-paragraph);

  display: block;
  // height: calc(100vh - 433px);
  overflow: auto;

  tr {
    display: grid;
    grid-template-columns: ${({ $gridTemplateColumns }) =>
      $gridTemplateColumns};
    align-items: center;

    height: 56px;
  }
  span {
    color: var(--color-text-labels);
  }

  ${({ $row }) =>
    $row === "doubleRow" &&
    `tr:nth-child(2n) {
    height: 40px;
    background: var(--color-light-100);
    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid var(--color-light-200);  

    td {
      display: flex;
      align-items: center;
      height: 100%;


      & button {
        color: var(--color-main-blue);
        cursor: pointer;
        
        svg {
          width: 14px;
          height: 14px;
        }
      }

      &.link-container {
        span, button {
          display: flex;
          align-items: center;
        }
        span {
          margin-right: 12px;
        }
      }

      // border-bottom: 1px solid var(--color-light-200);
    }

    p {
      margin-left: 15px;
      margin-right: 3px;
      font-size: 12px;
    }

    span {
      font-size: 12px;
    }
  }`}
`;

type TTBodyProps = {
  tableBody: {
    elementId: string;
    file_xml: null | string;
    file_nfe: null | string;
    file_boleto?: string;
    rowTooltip?: {
      size?: "default" | "large";
      text: TText[];
      customTextAlignment?: Property.TextAlign;
      customTopPosition?: number;
    };
    body: TTableBody<TCellStyleVariants>[];
  }[];
  selectType: TFullData<TSelection>;
  gridTemplateColumns: string;
  borderColor?: string[];
  row: TRow;
  hasFooter: "hasFooter" | "noFooter";
  secondRow?: TSecondRow;
  forceSelectElementRef?: React.MutableRefObject<
    {
      elementId: string;
      isClicked: "" | "active" | "inactive";
      setSelectElement: React.Dispatch<
        React.SetStateAction<"" | "active" | "inactive">
      >;
    }[]
  >;
  onRowSelect: (selected: {
    isChecked: boolean;
    clickedElementId: string;
    setClickState?: React.Dispatch<
      React.SetStateAction<"" | "active" | "inactive">
    >;
  }) => void;
  setSelectedRows?: number[];
  tbodyRef?: React.MutableRefObject<HTMLTableSectionElement | null>;
};

const TBody: React.FC<TTBodyProps> = ({
  tableBody,
  selectType,
  borderColor,
  row,
  secondRow,
  onRowSelect,
  forceSelectElementRef,
  hasFooter,
  // setSelectedRows,
  gridTemplateColumns,
  tbodyRef,
}) => {
  // const [selectRowIndex, setSelectRowIndex] = useState("");
  const [shouldUpdateElement /* setShouldUpdateElement */] = useState(false);

  const onClickHandler = (
    isClicked: boolean,
    clickedElementId: string,
    setClickState?: React.Dispatch<
      React.SetStateAction<"" | "active" | "inactive">
    >
  ) => {
    onRowSelect({
      isChecked: isClicked,
      clickedElementId: clickedElementId,
      setClickState: setClickState,
    });
  };

  const handleTableDataStyle = (
    cell:
      | TCell<Exclude<TCellStyleVariants, "button">>
      | TCellButton<TWithIconVariants>
  ) => {
    switch (cell?.style) {
      case "bold":
        return cell.showSkeleton ? (
          <Skeleton variant="box" />
        ) : (
          <>
            <b
              style={{ marginRight: cell.marginRight, fontSize: cell.fontSize }}
            >
              {cell.value}
            </b>
            {cell.shouldBreakLine && <br />}
          </>
        );
      case "default":
        return cell.showSkeleton ? (
          <Skeleton variant="box" />
        ) : (
          <>
            <p
              style={{
                display: "inline",
                marginRight: cell.marginRight,
                fontSize: cell.fontSize,
              }}
            >
              {cell.value}
            </p>
            {cell.shouldBreakLine && <br />}
          </>
        );
      case "faded":
        return cell.showSkeleton ? (
          <Skeleton variant="box" />
        ) : (
          <>
            <span
              style={{ marginRight: cell.marginRight, fontSize: cell.fontSize }}
            >
              {cell.value}
            </span>
            {cell.shouldBreakLine && <br />}
          </>
        );
      case "alert":
        return cell.showSkeleton ? (
          <Skeleton variant="box" />
        ) : (
          <>
            <span
              style={{
                color: "var(--color-aux-danger-700)",
                marginRight: cell.marginRight,
                fontSize: cell.fontSize,
              }}
            >
              {cell.value}
            </span>
            {cell.shouldBreakLine && <br />}
          </>
        );
      case "miscellaneous":
        return cell.showSkeleton ? (
          <Skeleton variant="box" />
        ) : cell.tooltip ? (
          <Tooltip
            justifyContent="flex-start"
            text={cell.tooltip.text}
            customTopPosition={cell.tooltip.customTopPosition}
          >
            <>
              {cell.values.map((value, index) => {
                switch (value.style) {
                  case "bold":
                    return (
                      <React.Fragment key={index}>
                        <b
                          style={{
                            marginRight: value.marginRight,
                            fontSize: value.fontSize,
                          }}
                        >
                          {value.text}
                        </b>
                        {value.shouldBreakLine && <br />}
                      </React.Fragment>
                    );
                  case "default":
                    return (
                      <React.Fragment key={index}>
                        <p
                          style={{
                            display: "inline",
                            marginRight: value.marginRight,
                            fontSize: value.fontSize,
                          }}
                        >
                          {value.text}
                        </p>
                        {value.shouldBreakLine && <br />}
                      </React.Fragment>
                    );
                  case "faded":
                    return (
                      <React.Fragment key={index}>
                        <span
                          style={{
                            marginRight: value.marginRight,
                            fontSize: value.fontSize,
                          }}
                        >
                          {value.text}
                        </span>
                        {value.shouldBreakLine && <br />}
                      </React.Fragment>
                    );
                  case "alert":
                    return (
                      <React.Fragment key={index}>
                        <span
                          style={{
                            color: "var(--color-aux-danger-700)",
                            marginRight: value.marginRight,
                            fontSize: value.fontSize,
                          }}
                        >
                          {value.text}
                        </span>
                        {value.shouldBreakLine && <br />}
                      </React.Fragment>
                    );
                }
              })}
            </>
          </Tooltip>
        ) : (
          cell.values.map((value, index) => {
            switch (value.style) {
              case "bold":
                return (
                  <React.Fragment key={index}>
                    <b
                      style={{
                        marginRight: value.marginRight,
                        fontSize: value.fontSize,
                      }}
                    >
                      {value.text}
                    </b>
                    {value.shouldBreakLine && <br />}
                  </React.Fragment>
                );
              case "default":
                return (
                  <React.Fragment key={index}>
                    <p
                      style={{
                        display: "inline",
                        marginRight: value.marginRight,
                        fontSize: value.fontSize,
                      }}
                    >
                      {value.text}
                    </p>
                    {value.shouldBreakLine && <br />}
                  </React.Fragment>
                );
              case "faded":
                return (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        marginRight: value.marginRight,
                        fontSize: value.fontSize,
                      }}
                    >
                      {value.text}
                    </span>
                    {value.shouldBreakLine && <br />}
                  </React.Fragment>
                );
              case "alert":
                return (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: "var(--color-aux-danger-700)",
                        marginRight: value.marginRight,
                        fontSize: value.fontSize,
                      }}
                    >
                      {value.text}
                    </span>
                    {value.shouldBreakLine && <br />}
                  </React.Fragment>
                );
            }
          })
        );
      case "button":
        switch (cell.icon) {
          case "ellipsisV":
            return (
              <DropdownOnlyIcon
                key={`${cell.isDisabled}`}
                content={cell.content}
                onMenuSelect={cell.onMenuSelect}
                onClick={cell.onClick}
                isDisabled={cell.isDisabled}
                scrollableRef={cell.hasScrollableRef ? tbodyRef : undefined}
              />
            );

          case "arrowRight":
            return (
              <Button
                variant="secondary"
                icon="onlyIcon"
                id={cell.id}
                iconType={cell.icon}
                onClick={cell.onClick}
                disabled={cell.isDisabled}
                style={cell.customCSS}
                tooltip={
                  cell.tooltip && {
                    text: cell.tooltip.text,
                    customTextAlignment: cell.tooltip.customTextAlignment,
                    size: cell.tooltip.size,
                    customTopPosition: cell.tooltip.customTopPosition,
                    scrollableRef: cell.tooltip.hasScrollableRef
                      ? tbodyRef
                      : undefined,
                  }
                }
              />
            );

          default:
            return (
              <Button
                variant="secondary"
                icon="onlyIcon"
                id={cell.id}
                iconType={cell.icon}
                onClick={cell.onClick}
                disabled={cell.isDisabled}
                tooltip={
                  cell.tooltip && {
                    text: cell.tooltip.text,
                    size: cell.tooltip.size,
                    customTextAlignment: cell.tooltip.customTextAlignment,
                    customTopPosition: cell.tooltip.customTopPosition,
                    scrollableRef: cell.tooltip.hasScrollableRef
                      ? tbodyRef
                      : undefined,
                  }
                }
              />
            );
        }
      case "gray":
        return cell.icon === "noIcon" ? (
          <Tag
            icon={cell.icon}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        ) : (
          <Tag
            icon={cell.icon}
            iconPosition={cell.iconPosition}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        );
      case "orange":
        return cell.icon === "noIcon" ? (
          <Tag
            icon={cell.icon}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        ) : (
          <Tag
            icon={cell.icon}
            iconPosition={cell.iconPosition}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        );
      case "green":
        return cell.icon === "noIcon" ? (
          <Tag
            icon={cell.icon}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        ) : (
          <Tag
            icon={cell.icon}
            iconPosition={cell.iconPosition}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        );
      case "red":
        return cell.icon === "noIcon" ? (
          <Tag
            icon={cell.icon}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        ) : (
          <Tag
            icon={cell.icon}
            iconPosition={cell.iconPosition}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        );
      case "purple":
        return cell.icon === "noIcon" ? (
          <Tag
            icon={cell.icon}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        ) : (
          <Tag
            icon={cell.icon}
            iconPosition={cell.iconPosition}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        );
      case "rose":
        return cell.icon === "noIcon" ? (
          <Tag
            icon={cell.icon}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        ) : (
          <Tag
            icon={cell.icon}
            iconPosition={cell.iconPosition}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        );
      case "blue":
        return cell.icon === "noIcon" ? (
          <Tag
            icon={cell.icon}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        ) : (
          <Tag
            icon={cell.icon}
            iconPosition={cell.iconPosition}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        );
      case "darkBlue":
        return cell.icon === "noIcon" ? (
          <Tag
            icon={cell.icon}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        ) : (
          <Tag
            icon={cell.icon}
            iconPosition={cell.iconPosition}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        );
      case "darkGray":
        return cell.icon === "noIcon" ? (
          <Tag
            icon={cell.icon}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        ) : (
          <Tag
            icon={cell.icon}
            iconPosition={cell.iconPosition}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        );
      case "brokenLink":
        return cell.icon === "noIcon" ? (
          <Tag
            icon={cell.icon}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        ) : (
          <Tag
            icon={cell.icon}
            iconPosition={cell.iconPosition}
            size="medium"
            color={cell.style}
            text={cell.value}
          />
        );
    }
  };

  return (
    <Container
      ref={tbodyRef}
      $row={row}
      $hasFooter={hasFooter}
      $gridTemplateColumns={gridTemplateColumns}
    >
      {tableBody.map((bodyData, rowIndex) => {
        return (
          <Fragment key={rowIndex}>
            {bodyData.rowTooltip ? (
              <Tooltip {...bodyData.rowTooltip}>
                <tr
                  style={{
                    height: borderColor && "96px",
                    background:
                      borderColor && borderColor[rowIndex]?.includes("red")
                        ? "var(--color-aux-danger-100)"
                        : "var(--color-light-0)",
                    borderBottom:
                      borderColor &&
                      (borderColor[rowIndex]?.includes("red")
                        ? rowIndex !== tableBody.length - 1
                          ? "1px solid var(--color-aux-danger-700)"
                          : "none"
                        : borderColor[rowIndex]?.includes("light")
                        ? rowIndex !== tableBody.length - 1
                          ? "1px solid var(--color-light-200)"
                          : "none"
                        : "none"),
                  }}
                >
                  <td></td>
                  <td>
                    {selectType.type === "multiSelect" ? (
                      <>
                        <Check
                          key={bodyData.elementId + shouldUpdateElement}
                          // initialState={
                          //   setSelectedRows &&
                          //   setSelectedRows[rowIndex] === rowIndex
                          // }
                          setSelectedRef={(setClickState, clickState) => {
                            if (forceSelectElementRef?.current) {
                              const repeatedElementIndex =
                                forceSelectElementRef.current.findIndex(
                                  (element) =>
                                    element.elementId === bodyData.elementId
                                );
                              if (repeatedElementIndex > -1) {
                                forceSelectElementRef.current.splice(
                                  repeatedElementIndex,
                                  1
                                );
                              }
                              forceSelectElementRef.current.push({
                                elementId: bodyData.elementId,
                                isClicked: clickState,
                                setSelectElement: setClickState,
                              });
                            }
                          }}
                          onClick={(isClicked, setClickState) => {
                            onClickHandler(
                              isClicked,
                              `${bodyData.elementId}`,
                              setClickState
                            );
                          }}
                        />
                        &nbsp;
                      </>
                    ) : selectType.type === "singleSelect" ? (
                      !Array.isArray(bodyData.body[0]) &&
                      bodyData.body[0].showSkeleton ? (
                        <Skeleton variant="radio" />
                      ) : (
                        <>
                          <Radio
                            key={bodyData.elementId + shouldUpdateElement}
                            // isActive={
                            //   `${rowIndex}` === selectRowIndex
                            //     ? "active"
                            //     : "inactive"
                            // }
                            setSelectedRef={(setClickState, clickState) => {
                              if (forceSelectElementRef?.current) {
                                const repeatedElementIndex =
                                  forceSelectElementRef.current.findIndex(
                                    (element) =>
                                      element.elementId === bodyData.elementId
                                  );
                                if (repeatedElementIndex > -1) {
                                  forceSelectElementRef.current.splice(
                                    repeatedElementIndex,
                                    1
                                  );
                                }

                                forceSelectElementRef.current.push({
                                  elementId: bodyData.elementId,
                                  isClicked: clickState,
                                  setSelectElement: setClickState,
                                });
                              }
                            }}
                            onClick={(isClicked, setClickState) => {
                              onClickHandler(
                                isClicked,
                                `${bodyData.elementId}`,
                                setClickState
                              );
                            }}
                          />
                          &nbsp;
                        </>
                      )
                    ) : (
                      <>{""}&nbsp;</>
                    )}
                  </td>
                  {bodyData.body.map((cell, index) => (
                    <td key={index}>
                      {!Array.isArray(cell) && handleTableDataStyle(cell) ? (
                        handleTableDataStyle(cell)
                      ) : (
                        <div style={{ width: 150 }}></div>
                      )}
                      {Array.isArray(cell) && (
                        <div
                          style={
                            !cell.some(
                              (
                                value:
                                  | TCell<Exclude<TCellStyleVariants, "button">>
                                  | TCellButton<TWithIconVariants>
                              ) =>
                                value.style === "bold" ||
                                value.style === "faded" ||
                                (value.style === "default" &&
                                  value.shouldBreakLine)
                            )
                              ? { display: "flex", alignItems: "center" }
                              : undefined
                          }
                        >
                          {cell.map((value, index) => (
                            <React.Fragment key={index}>
                              {handleTableDataStyle(value)}
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              </Tooltip>
            ) : (
              <tr
                style={{
                  height: borderColor && "96px",
                  background:
                    borderColor && borderColor[rowIndex]?.includes("red")
                      ? "var(--color-aux-danger-100)"
                      : "var(--color-light-0)",
                  borderBottom:
                    borderColor &&
                    (borderColor[rowIndex]?.includes("red")
                      ? rowIndex !== tableBody.length - 1
                        ? "1px solid var(--color-aux-danger-700)"
                        : "none"
                      : borderColor[rowIndex]?.includes("light")
                      ? rowIndex !== tableBody.length - 1
                        ? "1px solid var(--color-light-200)"
                        : "none"
                      : "none"),
                }}
              >
                <td></td>
                <td>
                  {selectType.type === "multiSelect" ? (
                    <>
                      <Check
                        key={bodyData.elementId + shouldUpdateElement}
                        // initialState={
                        //   setSelectedRows &&
                        //   setSelectedRows[rowIndex] === rowIndex
                        // }
                        setSelectedRef={(setClickState, clickState) => {
                          if (forceSelectElementRef?.current) {
                            const repeatedElementIndex =
                              forceSelectElementRef.current.findIndex(
                                (element) =>
                                  element.elementId === bodyData.elementId
                              );
                            if (repeatedElementIndex > -1) {
                              forceSelectElementRef.current.splice(
                                repeatedElementIndex,
                                1
                              );
                            }
                            forceSelectElementRef.current.push({
                              elementId: bodyData.elementId,
                              isClicked: clickState,
                              setSelectElement: setClickState,
                            });
                          }
                        }}
                        onClick={(isClicked, setClickState) => {
                          onClickHandler(
                            isClicked,
                            `${bodyData.elementId}`,
                            setClickState
                          );
                        }}
                      />
                      &nbsp;
                    </>
                  ) : selectType.type === "singleSelect" ? (
                    !Array.isArray(bodyData.body[0]) &&
                    bodyData.body[0].showSkeleton ? (
                      <Skeleton variant="radio" />
                    ) : (
                      <>
                        <Radio
                          key={bodyData.elementId + shouldUpdateElement}
                          // isActive={
                          //   `${rowIndex}` === selectRowIndex
                          //     ? "active"
                          //     : "inactive"
                          // }
                          setSelectedRef={(setClickState, clickState) => {
                            if (forceSelectElementRef?.current) {
                              const repeatedElementIndex =
                                forceSelectElementRef.current.findIndex(
                                  (element) =>
                                    element.elementId === bodyData.elementId
                                );
                              if (repeatedElementIndex > -1) {
                                forceSelectElementRef.current.splice(
                                  repeatedElementIndex,
                                  1
                                );
                              }

                              forceSelectElementRef.current.push({
                                elementId: bodyData.elementId,
                                isClicked: clickState,
                                setSelectElement: setClickState,
                              });
                            }
                          }}
                          onClick={(isClicked, setClickState) => {
                            onClickHandler(
                              isClicked,
                              `${bodyData.elementId}`,
                              setClickState
                            );
                          }}
                        />
                        &nbsp;
                      </>
                    )
                  ) : (
                    <>{""}&nbsp;</>
                  )}
                </td>
                {bodyData.body.map((cell, index) => (
                  <td key={index}>
                    {!Array.isArray(cell) && handleTableDataStyle(cell) ? (
                      handleTableDataStyle(cell)
                    ) : (
                      <div style={{ width: 150 }}></div>
                    )}
                    {Array.isArray(cell) && (
                      <div
                        style={
                          !cell.some(
                            (
                              value:
                                | TCell<Exclude<TCellStyleVariants, "button">>
                                | TCellButton<TWithIconVariants>
                            ) =>
                              value.style === "bold" ||
                              value.style === "faded" ||
                              (value.style === "default" &&
                                value.shouldBreakLine)
                          )
                            ? { display: "flex", alignItems: "center" }
                            : undefined
                        }
                      >
                        {cell.map((value, index) => (
                          <React.Fragment key={index}>
                            {handleTableDataStyle(value)}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            )}
            {row === "doubleRow" && secondRow && (
              <tr>
                <td colSpan={tableBody[0].body.length + 3}>
                  {handleTableDataStyle(secondRow.params[rowIndex].data)}
                  {secondRow.params[rowIndex].leftSideCustomNode}
                </td>
                <td
                  key={rowIndex}
                  className="link-container"
                  id={"link-container-" + rowIndex}
                  colSpan={tableBody[0].body.length + 3}
                >
                  {secondRow.params[rowIndex].rightSideCustomNode}
                </td>
              </tr>
            )}
          </Fragment>
        );
      })}
    </Container>
  );
};

export default TBody;
