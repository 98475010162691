import styled from "styled-components";
import { shimmer } from "../../utils/animations";

const Container = styled.div<{ $variant: TSkeletonVariants }>`
  width: ${({ $variant }) => ($variant === "box" ? "100%" : "18px")};
  height: ${({ $variant }) => ($variant === "box" ? "30" : "18")}px;
  background: var(--color-light-100);
  position: relative;

  ${({ $variant }) => $variant === "radio" && "border-radius: 100%;"}

  &::before {
    content: "";
    position: absolute;
    display: block;
    height: ${({ $variant }) => ($variant === "box" ? "30" : "18")}px;
    width: ${({ $variant }) => ($variant === "box" ? "20" : "9")}px;
    background: linear-gradient(
      to right,
      var(--color-light-100) -100%,
      var(--color-light-50) 100%,
      var(--color-light-0) 100%,
      var(--color-light-50) 100%,
      var(--color-light-100) 100%
    );

    ${({ $variant }) => $variant === "radio" && "border-radius: 100%;"}

    animation: ${shimmer} 1s ease-in-out infinite;
  }
`;

type TSkeletonVariants = "radio" | "box";

type TSkeletonProps = {
  variant: TSkeletonVariants;
};

const Skeleton: React.FC<TSkeletonProps> = ({ variant }) => {
  return (
    <div>
      <Container $variant={variant} />
    </div>
  );
};

export default Skeleton;
