import styled from "styled-components";
import { useId, useRef, useState } from "react";
import DatePicker from "./SingleDatePicker";

const Container = styled.div<{ $shouldShowCalendar: "show" | "hide" }>`
  button + div {
    position: absolute;
    z-index: 2;
    ${({ $shouldShowCalendar }) =>
      $shouldShowCalendar === "show"
        ? "opacity: 1; visibility: visible;"
        : "opacity: 0; visibility: hidden;"};

    transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
  }

  .errorContainer {
    position: relative;
  }

  p.error {
    position: absolute;
    color: var(--color-aux-danger-700);
    font-size: 10px;
    font-family: var(--font-paragraph-default);
  }
`;

const Subcontainer = styled.button<{
  $placeholder: string;
  $onError: "hasError" | "noError";
  $hasMandatoryStyle?: { marginLeft: string };
}>`
  position: relative;
  width: 280px;
  // height: max-content;
  height: 47px;
  padding: 14px 12px 14px 38px;

  font-family: var(--font-paragraph-default);

  border-radius: 8px;
  border: 1px solid
    ${({ $onError }) =>
      $onError === "hasError"
        ? `var(--color-aux-danger-700)`
        : `var(--color-dark-blue-300)`};

  background: var(--color-light-0);

  cursor: pointer;

  &:hover {
    background: var(--color-light-0);
  }

  p.date {
    font-size: 14px;
    color: var(--color-text-labels);
    &.active {
      color: var(--color-text-paragraph);
      text-align: left;
      // margin-left: 18px;
      margin-left: 8px;
      padding-top: 5px;
    }
  }

  .clear {
    position: absolute;
    right: 12px;

    top: 12px;

    color: var(--color-text-labels);
    width: 18px;
    height: 18px;
    font-size: 14px;

    cursor: pointer;
    outline: none;
  }

  &::before {
    content: "";
    position: absolute;
    font-family: "unicons";

    color: var(--color-text-labels);

    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-light-100);
    border-radius: 8px 0 0 8px;
    width: 38px;
    height: 100%;
    font-size: 22px;
  }

  &::after {
    content: "${({ $placeholder }) => $placeholder}";
    position: absolute;
    top: 8px;
    // left: 55px;
    left: 47px;
    font-size: 9px;
    color: var(--color-text-labels);
  }

  .mandatory {
    color: var(--color-aux-danger-700);
    ${({ $placeholder, $hasMandatoryStyle }) =>
      $placeholder &&
      `position: absolute;` +
        `font-size: 9px;` +
        `top: 8px;` +
        `left: ${$hasMandatoryStyle?.marginLeft}`}
  }

  &:focus-within {
    border: 1px solid var(--color-main-blue);

    &::before {
      color: var(--color-text-paragraph);
    }
  }

  .clear:focus {
    border: 1px solid var(--color-main-blue);
  }
`;

type TCalendarProps = {
  id?: string;
  placeholder?: string;
  className?: string;
  hasMandatoryStyle?: { marginLeft: string };
  onSelect?: (selectDate: string) => void;
  filled?: string;
  // disabled?: boolean;
  onError?: { hasError: boolean; message: string };
  setDate?: (
    setButtonSelectedDate: React.Dispatch<React.SetStateAction<string>>,
    setDatePickerSelectedDates: React.Dispatch<
      React.SetStateAction<{
        startDate: string;
      }>
    >
  ) => void;
};

const Calendar: React.FC<TCalendarProps> = ({
  id,
  placeholder,
  className,
  // disabled,
  filled,
  onSelect,
  onError,
  setDate,
  hasMandatoryStyle,
}) => {
  const [shouldShowCalendar, setShouldShowCalendar] = useState(false);
  const [selectDate, setSelectDate] = useState(filled ?? "");

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const backwardButtonId = useId();
  const forwardButtonId = useId();

  const datePickerId = useId();

  const handleFocus = () => {
    setShouldShowCalendar(true);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLButtonElement, Element>) => {
    // console.log(e);
    if (
      e.relatedTarget?.id === backwardButtonId ||
      e.relatedTarget?.id === forwardButtonId
    ) {
      buttonRef.current?.focus();
    } else setShouldShowCalendar(false);
  };

  return (
    <Container
      className={className}
      $shouldShowCalendar={shouldShowCalendar ? "show" : "hide"}
    >
      <Subcontainer
        ref={buttonRef}
        $onError={onError && onError.hasError ? "hasError" : "noError"}
        id={id && id}
        role="button"
        onClick={handleFocus}
        onBlur={handleOnBlur}
        $placeholder={selectDate.length > 0 ? placeholder ?? "" : ""}
        $hasMandatoryStyle={hasMandatoryStyle}
      >
        <p className={selectDate.length > 0 ? "date active" : "date"}>
          {selectDate.length > 0 ? selectDate : placeholder}
          {hasMandatoryStyle && <span className="mandatory">&nbsp;*</span>}
        </p>
      </Subcontainer>
      <DatePicker
        id={datePickerId}
        onSelect={({ startDate }) => {
          setSelectDate(startDate);
          if (onSelect) onSelect(startDate);
        }}
        backwardButtonId={backwardButtonId}
        forwardButtonId={forwardButtonId}
        setDate={(setSelectDates) => {
          if (setDate) setDate(setSelectDate, setSelectDates);
        }}
      />
      {onError?.hasError && (
        <div className="errorContainer">
          <p className="error">{onError.message}</p>
        </div>
      )}
    </Container>
  );
};

export default Calendar;
