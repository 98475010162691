import styled from "styled-components";
import {
  TFullData,
  TSelection,
  TTableHead,
  TTableHeadIconStyles,
  TTableHeadIcons,
  TTableHeadWithIcon,
} from ".";
import Check from "../Inputs/Check";
import { UilInfoCircle } from "@iconscout/react-unicons";
import Tooltip from "../Tooltip";
import { useRef } from "react";
// import Radio from "../Input/Radio";

const Container = styled.thead<{ $customTopPosition?: string }>`
  height: 44px;

  display: block;
  background: white;

  top: ${({ $customTopPosition }) => $customTopPosition ?? 0};
  position: sticky;
  z-index: 1;

  // .checkbox-container {
  //   height: 10px;
  //   position: relative;

  //   & *:nth-child(1) {
  //     position: absolute;
  //     z-index: 2;
  //     top: -30px;
  //   }
  // }
`;

const RowContainer = styled.tr<{ $gridTemplateColumns: string }>`
  display: grid;
  grid-template-columns: ${({ $gridTemplateColumns }) => $gridTemplateColumns};
  align-items: center;

  box-sizing: border-box;
  position: relative;
  height: 44px;
  // width: calc(100vw - 50px);

  border-radius: 8px;
  border: 1px solid var(--color-light-200);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const HeadContainer = styled.th<{ $cell?: TTableHeadWithIcon }>`
  display: block;

  font-weight: normal;
  font-size: 14px;
  font-family: var(--font-paragraph-default);
  color: var(--color-text-title);

  &.border {
    display: none;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    left: 0;
    height: 44px;

    border-radius: 8px;
    border: 1px solid var(--color-light-200);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  }

  .headerTooltip {
    position: relative;

    & > p {
      display: inline;
    }

    & > div {
      position: absolute;
      top: 0px;
      left: 75px;

      & > div {
        top: -80px;
        left: -135px;
      }
    }
  }

  svg {
    width: 16px;
    height: 16px;
    ${({ $cell }) =>
      $cell?.style === "icon"
        ? `color: var(${$cell.iconColor});` +
          `${
            $cell.iconPosition === "left"
              ? "padding-right: 5px"
              : "padding-left: 5px"
          }`
        : ""};
    vertical-align: middle;
  }

  // &:nth-child(1),
  // &:nth-child(3) {
  //   min-width: 50px;
  // }

  &:nth-child(2) {
    // padding-left: 25px;
  }

  // &:nth-child(4),
  // &:nth-child(10) {
  //   min-width: 70px;
  // }

  // &:nth-child(5),
  // &:nth-child(6),
  // &:nth-child(8) {
  //   min-width: 155px;
  // }

  // &:nth-child(7),
  // &:nth-child(9) {
  //   min-width: 100px;
  // }
`;

type TTHeadProps = {
  tableHead: {
    customTopPosition?: string;
    value: TTableHead<TTableHeadIconStyles>[];
  };
  selectType: TFullData<TSelection>;
  onSelect?: (
    clickStatus: "all" | "none",
    setSelected: React.Dispatch<
      React.SetStateAction<"" | "active" | "inactive">
    >
  ) => void;
  gridTemplateColumns: string;
};

const THead: React.FC<TTHeadProps> = ({
  tableHead,
  selectType,
  onSelect,
  gridTemplateColumns,
}) => {
  const setSelectedRef = useRef<React.Dispatch<
    React.SetStateAction<"" | "active" | "inactive">
  > | null>(null);

  const handleOnClick = (isClicked: boolean) => {
    if (onSelect && setSelectedRef.current)
      onSelect(isClicked ? "all" : "none", setSelectedRef.current);
  };

  const handleIconPositionLeft = (
    a: JSX.Element,
    b: string
    // hasTooltip?: boolean
  ) => (
    <div>
      {a}
      <p style={{ display: "inline" }}>{b}</p>
    </div>
  );
  const handleIconPositionRight = (
    a: string,
    b: JSX.Element,
    hasTooltip?: boolean
  ) => (
    <div className="headerTooltip">
      <p style={{ display: "inline" }}>{a}</p>
      {hasTooltip ? (
        // <div>
        <Tooltip
          text={[
            {
              style: "default",
              text: "A visibilidade é a permissão que todos",
              shouldBreakLine: true,
            },
            {
              style: "default",
              text: "os usuários do portal têm para acessar",
              shouldBreakLine: true,
            },
            {
              style: "default",
              text: "esta Organização",
              shouldBreakLine: true,
            },
          ]}
          customPosition={{ top: -80 }}
          size="large"
        >
          {b}
        </Tooltip>
      ) : (
        // </div>
        b
      )}
    </div>
  );

  const switchCaseHandler = (icon: TTableHeadIcons) => {
    switch (icon) {
      case "infoCircle":
        return <UilInfoCircle />;
    }
  };

  const renderContentByPickedIcon = (
    cell: TTableHead<TTableHeadIconStyles>
  ) => {
    if (cell.style === "icon") {
      return cell.iconPosition === "left"
        ? handleIconPositionLeft(switchCaseHandler(cell.icon), cell.value)
        : handleIconPositionRight(
            cell.value,
            switchCaseHandler(cell.icon),
            cell.icon === "infoCircle"
          );
    }
    return cell.value;
  };

  return (
    <Container $customTopPosition={tableHead.customTopPosition}>
      <RowContainer $gridTemplateColumns={gridTemplateColumns}>
        {/* <HeadContainer className="border" colSpan={tableHead.length + 1}>
          
        </HeadContainer> */}
        <th>
          <div></div>
        </th>
        <HeadContainer>
          {selectType.type === "multiSelect" ? (
            <Check
              setSelectedRef={(setClickState, _clickState) => {
                setSelectedRef.current = setClickState;
              }}
              onClick={handleOnClick}
            />
          ) : (
            <div style={{ width: 18 }}></div>
          )}
        </HeadContainer>

        {tableHead.value.map((cell, index) => (
          <HeadContainer
            key={index}
            $cell={cell.style === "icon" ? cell : undefined}
          >
            {renderContentByPickedIcon(cell)}
          </HeadContainer>
        ))}
        {/*<HeadContainer>
           <div>
            <div style={{ width: 18 }}></div>
          </div> 
        </HeadContainer>*/}
      </RowContainer>
    </Container>
  );
};

export default THead;
