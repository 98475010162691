import { UilEye, UilEyeSlash, UilLinkBroken } from "@iconscout/react-unicons";
import styled from "styled-components";

const Container = styled.div<{
  $color: TTagColorVariants;
  $size: "small" | "medium";
}>`
  border-radius: 20px;
  width: fit-content;
  padding: 4px 8px;

  display: flex;
  align-items: center;

  font-size: ${({ $size }) => ($size === "medium" ? "14" : "12")}px;

  svg {
    width: ${({ $size }) => ($size === "medium" ? "14" : "12")}px;
    height: ${({ $size }) => ($size === "medium" ? "14" : "12")}px;
    margin-right: 5px;
  }

  ${({ $color }) => {
    switch ($color) {
      case "gray":
        return (
          `background: var(--color-light-100);` +
          `color: var(--color-dark-blue-400);`
        );
      case "orange":
        return (
          `background: var(--color-aux-warning-100);` +
          `color: var(--color-aux-warning-800);`
        );
      case "green":
        return (
          `background: var(--color-aux-success-100);` +
          `color: var(--color-aux-success-800);`
        );
      case "orange":
        return (
          `background: var(--color-aux-orange-100);` +
          `color: var(--color-aux-orange-800);`
        );
      case "red":
        return (
          `background: var(--color-aux-danger-100);` +
          `color: var(--color-aux-danger-800);`
        );
      case "purple":
        return (
          `background: var(--color-aux-purple-100);` +
          `color: var(--color-aux-purple-800);`
        );
      case "rose":
        return (
          `background: var(--color-rose-100);` + `color: var(--color-rose-800);`
        );
      case "blue":
        return (
          `background: var(--color-blue-100);` + `color: var(--color-blue-800);`
        );
      case "darkBlue":
        return (
          `background: var(--color-dark-blue-700);` +
          `color: var(--color-light-100);`
        );
      case "darkGray":
        return (
          `background: var(--color-light-100);` +
          `color: var(--color-dark-blue-600);`
        );
      case "brokenLink":
        return (
          `background: var(--color-aux-danger-100);` +
          `color: var(--color-aux-danger-800);` +
          `border: 1px solid var(--color-aux-danger-800);`
        );
    }
  }}
`;

export type TTagColorVariants =
  | "gray"
  | "orange"
  | "green"
  | "orange"
  | "red"
  | "purple"
  | "rose"
  | "blue"
  | "darkBlue"
  | "darkGray"
  | "brokenLink";

export type TTagWithIconVariants = "eye" | "eyeSlash" | "brokenLink";

export type TTagWithIcon = {
  icon: TTagWithIconVariants;
  iconPosition: "left" | "right";
};

export type TTagNoIcon = {
  icon: "noIcon";
  iconPosition?: never;
};

export type TTagIconVariants = "noIcon" | TTagWithIconVariants;

type TTagCommonProps = {
  text: string;
  size: "small" | "medium";
  color: TTagColorVariants;
};

export type TTagProps<T extends TTagIconVariants> = T extends "noIcon"
  ? TTagNoIcon & TTagCommonProps
  : TTagWithIcon & TTagCommonProps;

const Tag: React.FC<TTagProps<TTagIconVariants>> = ({
  color,
  icon,
  iconPosition,
  size,
  text,
}) => {
  const buttonText = text ?? "";

  const handleIconPositionLeft = (a: JSX.Element, b: string) => (
    <>
      {a}
      <p>{b}</p>
    </>
  );
  const handleIconPositionRight = (a: string, b: JSX.Element) => (
    <>
      <p>{a}</p>
      {b}
    </>
  );

  const switchCaseHandler = (icon: TTagWithIconVariants) => {
    switch (icon) {
      case "eye":
        return <UilEye />;
      case "eyeSlash":
        return <UilEyeSlash />;
      case "brokenLink":
        return <UilLinkBroken />;
    }
  };

  const renderContentByPickedIcon = () => {
    return iconPosition === "left"
      ? handleIconPositionLeft(switchCaseHandler(icon), buttonText)
      : handleIconPositionRight(
          buttonText,
          icon === "noIcon" ? <></> : switchCaseHandler(icon)
        );
  };

  return (
    <Container $size={size} $color={color}>
      {renderContentByPickedIcon()}
    </Container>
  );
};

export default Tag;
