import { combineReducers } from "redux";
import organizationsReducer from "./organizations";
import userDataReducer from "./userData";
import accountReducer from "./account";
import globalStateReducer from "./globalState";
import membershipsReducer from "./memberships";

const rootReducer = combineReducers({
  organizations: organizationsReducer,
  userData: userDataReducer,
  account: accountReducer,
  memberships: membershipsReducer,
  globalState: globalStateReducer,
});

export default rootReducer;
