import { useEffect } from "react";

type TProps = {
  isClicked: boolean | null;
  componentRef?: React.MutableRefObject<HTMLDivElement | HTMLElement | null>;
  onOutside: () => void;
};

const useDetectOutsideClick = ({
  isClicked,
  componentRef,
  onOutside,
}: TProps) => {
  const handleOutsideClick = (e: MouseEvent) => {
    if (
      componentRef?.current &&
      !componentRef.current.contains(e.target as Node)
    ) {
      if (!(e.target as HTMLElement).classList.contains("calendar")) {
        onOutside();
      }
    }
  };

  useEffect(() => {
    if (isClicked) {
      document.addEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isClicked]);
};

export default useDetectOutsideClick;
