import interRegular from "../assets/fonts/inter/Inter-Regular.ttf";
import interBold from "../assets/fonts/inter/Inter-Bold.ttf";
import montserratRegular from "../assets/fonts/montserrat/Montserrat-Regular.ttf";
import montserratBold from "../assets/fonts/montserrat/Montserrat-Bold.ttf";
import unicons from '../assets/fonts/unicons-line.ttf';

export const typography = `
@font-face {
  font-family: interRegular;
  src: url(${interRegular});
}

@font-face {
  font-family: interBold;
  src: url(${interBold});
}

@font-face {
  font-family: montserratRegular;
  src: url(${montserratRegular});
}

@font-face {
  font-family: montserratBold;
  src: url(${montserratBold});
}

@font-face {
  font-family: unicons;
  src: url(${unicons});
}
`;
