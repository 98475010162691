import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Config from "../pages/Config";
import Users from "../pages/Config/Users";
import Account from "../pages/Account";
import UsersForm from "../pages/Config/Users/UsersForm";
import Organizations from "../pages/Config/Organizations";
import Blank from "../pages/Blank";
import PaymentSlip from "../pages/PaymentSlip";
import Error from "../pages/Tests/Error";
import * as Sentry from "@sentry/react";

export const ROUTES = {
  LOGIN: "/login/",
  LOGIN_CBK: "/login/callback/",
  ACCOUNTS: "/accounts/",
  HOME: "/",
  INVOICES: "/invoices/",
  PAYMENT_SLIP: "/payment-slip/",
  SETTINGS: "/settings/",
  SETTINGS_ORGANIZATIONS: "/settings/organizations/",
  SETTINGS_USERS: "/settings/users/",
  SETTINGS_USERS_CREATE: "/settings/users/create/",
  SETTINGS_USERS_EDIT: "/settings/users/edit/",
  TEST: "/test/components/",
};

export const router = createBrowserRouter([
  {
    path: ROUTES.LOGIN,
    element: (
      <Sentry.ErrorBoundary>
        <Login />
      </Sentry.ErrorBoundary>
    ),
  },
  {
    path: ROUTES.LOGIN_CBK,
    element: (
      <Sentry.ErrorBoundary>
        <Blank />
      </Sentry.ErrorBoundary>
    ),
  },
  {
    path: ROUTES.ACCOUNTS,
    element: (
      <Sentry.ErrorBoundary>
        <App component={<Account />} />
      </Sentry.ErrorBoundary>
    ),
  },
  {
    path: ROUTES.HOME,
    element: (
      <Sentry.ErrorBoundary>
        <App component={<Home />} />
      </Sentry.ErrorBoundary>
    ),
  },
  {
    path: ROUTES.INVOICES,
    element: (
      <Sentry.ErrorBoundary>
        <App component={<Home />} />
      </Sentry.ErrorBoundary>
    ),
  },
  {
    path: ROUTES.PAYMENT_SLIP,
    element: (
      <Sentry.ErrorBoundary>
        <App component={<PaymentSlip />} />
      </Sentry.ErrorBoundary>
    ),
  },
  {
    path: ROUTES.SETTINGS,
    element: (
      <Sentry.ErrorBoundary>
        <App component={<Config />} />
      </Sentry.ErrorBoundary>
    ),
  },
  {
    path: ROUTES.SETTINGS_ORGANIZATIONS,
    element: (
      <Sentry.ErrorBoundary>
        <App component={<Organizations />} />
      </Sentry.ErrorBoundary>
    ),
  },
  {
    path: ROUTES.SETTINGS_USERS,
    element: (
      <Sentry.ErrorBoundary>
        <App component={<Users />} />
      </Sentry.ErrorBoundary>
    ),
  },
  {
    path: ROUTES.SETTINGS_USERS_CREATE,
    element: (
      <Sentry.ErrorBoundary>
        <App component={<UsersForm variant="create" />} />
      </Sentry.ErrorBoundary>
    ),
  },
  {
    path: ROUTES.SETTINGS_USERS_EDIT + ":uuid/",
    element: (
      <Sentry.ErrorBoundary>
        <App component={<UsersForm variant="edit" />} />
      </Sentry.ErrorBoundary>
    ),
  },
  {
    path: "/tests/sentry/",
    element: (
      <Sentry.ErrorBoundary>
        <Error />
      </Sentry.ErrorBoundary>
    ),
  },
  {
    path: "*",
    element: (
      <Sentry.ErrorBoundary>
        <App component={<Home />} />
      </Sentry.ErrorBoundary>
    ),
  },
  // { path: ROUTES.TEST, element: <Component /> },
]);
