import styled from "styled-components";
import { useState } from "react";

const Container = styled.menu<{
  $shouldshow: "show" | "hide";
  // $accountsCount: number;
  $optionsCount: number;
}>`
  // display: contents;

  // // width: 248px;
  // padding: 16px;

  // margin: 0;

  // position: absolute;
  // z-index: ${({ $shouldshow }) => ($shouldshow === "show" ? "1" : "0")};
  // transition: z-index 0.15s ease-in-out;

  // background: transparent;

  &/* ::before */ {
    // content: "";

    position: absolute;
    // top: 72px;
    top: 55px;
    left: 0;
    z-index: ${({ $shouldshow }) => ($shouldshow === "show" ? "1" : "0")};
    transition: z-index 0.15s ease-in-out;

    height: ${({ $shouldshow, $optionsCount }) =>
      $shouldshow === "show" ? ($optionsCount + 1) * 35 + "px" : "0px"};
    width: 99%;

    background: var(--color-light-0);

    padding-left: 0px;

    border-radius: 0 0 8px 8px;
    border: 1px solid var(--color-light-200);
    border-top: 0px;
    border-width: ${({ $shouldshow }) =>
      $shouldshow === "show" ? "1px" : "0px"};
    box-shadow: 2px 4px 8px 0px rgba(57, 60, 77, 0.1);

    transition: height 0.2s ease-in-out;
  }
`;

const ItemButton = styled.button<{
  $icon: TOption;
  $shouldshow: "show" | "hide";
}>`
  position: relative;
  height: 36px;
  // height: 44px;
  width: calc(100% - 36px);

  cursor: pointer;

  margin-left: 12px;

  border-radius: 8px;

  visibility: ${({ $shouldshow }) =>
    $shouldshow === "show" ? "visible" : "hidden"};
  transition: visibility 0.1s ease-in-out;

  &:first-child {
    margin-top: 10px;
  }

  &:hover {
    background: var(--color-light-100);
  }

  &:active {
    background: var(--color-main-blue);

    & p,
    & p::before {
      color: var(--color-light-0);
    }

    & p {
      font-family: var(--font-paragraph-bold);
    }
  }

  p {
    position: absolute;
    // top: 12.5%;
    top: 25%;
    z-index: 1;

    font-family: var(--font-paragraph-default);
    font-size: 14px;
    color: var(--color-text-paragraph);

    // height: 44px;
    // width: 100%;
    // background: white;
    // left: 0;
    // text-align: left;
    // padding-left: 16px;
    // padding-top: 8px;
    // border-radius: 8px;
  }

  p::before {
    color: var(--color-dark-blue-400);
    font-size: 17px;
    font-family: unicons;
    margin-right: 8px;

    content: ${({ $icon }) => {
      switch ($icon) {
        case "id":
          return `''`;
        case "config":
          return `''`;
        case "swap":
          return `''`;
        case "logout":
          return `''`;
      }
    }};
    ${({ $icon }) =>
      $icon === "logout" &&
      `display: inline-block;
    transform: scaleX(-1);`}
  }
`;

export type TOption = "id" | "config" | "swap" | "logout";

type TMenuProps = {
  options: TOption[];
  shouldShow: "hide" | "show";
  accountsCount: number;
  onClick: (
    selectedIndex: string,
    selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>
  ) => void;
};

const Menu: React.FC<TMenuProps> = ({
  options,
  shouldShow,
  onClick,
  // accountsCount,
}) => {
  const [selected, setSelected] = useState([""]);

  const handleOptionRender = (option: TOption) => {
    switch (option) {
      case "id":
        return "Ver meu Nexaas ID";
      case "config":
        return "Configurações";
      case "swap":
        return "Trocar de conta";
      case "logout":
        return "Sair";
      //  de [Nome da conta]";
    }
  };

  return (
    <Container
      $optionsCount={options.length}
      $shouldshow={shouldShow}
      // $accountsCount={accountsCount}
    >
      {options.map((value, index) => {
        return (
          <ItemButton
            $shouldshow={shouldShow}
            className={selected.includes(`${index}`) ? "active" : ""}
            $icon={value}
            key={index}
            onClick={() => {
              onClick(`${index}`, selected, setSelected);
            }}
          >
            <p>{handleOptionRender(value)}</p>
          </ItemButton>
        );
      })}
    </Container>
  );
};

export default Menu;
