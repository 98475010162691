import styled from "styled-components";
import Button, { TButtonIconVariants, TButtonProps } from "../Button";
import React, { useEffect, useRef } from "react";
import {
  UilDollarSign,
  UilEditAlt,
  UilExclamationTriangle,
  UilImport,
  UilInfoCircle,
  UilLink,
  UilLinkBroken,
} from "@iconscout/react-unicons";
import TableGrid, { TRow, TTableProps, TText } from "../TableGrid";
import AddPayable from "./Forms/AddPayable";
import {
  TInvoice,
  TNexaasProduct,
  TPaymentSlip,
} from "../../../redux/reducers/globalState";
import LinkPayable from "./Forms/LinkPayable";
import InstallmentAccount from "./Forms/InstallmentAccount";
import Goods from "./Forms/Goods";
import AddPaymentSlip from "./Forms/AddPaymentSlip";
import LinkInvoice from "./Forms/LinkInvoice";
import LinkPaymentSlip from "./Forms/LinkPaymentSlip";
import GoodsAutomated from "./Forms/GoodsAutomated";
import GoodsStockCheck from "./Forms/GoodsStockCheck";
import RenameDocument from "./Forms/RenameDocument";

const Container = styled.div<{ $shouldShow: "show" | "hide" }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;

  width: 100vw;
  height: 100vh;

  display: ${({ $shouldShow }) => ($shouldShow === "show" ? `flex` : "none")};
  align-items: center;
  justify-content: center;
`;

const CustomBackdrop = styled.div`
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: var(--color-dark-blue-700);
  opacity: 0.25;
`;

const Dialog = styled.dialog<{
  $shouldShow: "show" | "hide";
  $childVariant: TMainVariants;
  $formVariant?: TModalFormVariants;
}>`
  z-index: 6;

  padding: 24px;
  background: var(--color-light-0);

  border: 1px solid var(--color-light-200);
  border-radius: 8px;

  max-height: calc(100vh - 50px);
  ${({ $formVariant }) =>
    $formVariant?.includes("goodsStockCheck") && "height: 620px;"}

  overflow-y: auto;

  width: ${({ $childVariant, $formVariant }) =>
    $childVariant === "form"
      ? $formVariant === "addPayable" || $formVariant === "addPaymentSlip"
        ? "925"
        : $formVariant?.includes("link") ||
          $formVariant?.includes("goodsStockCheck")
        ? "1080"
        : "660"
      : "660"}px;

  & > h1 {
    font-size: 24px;
    font-family: var(--font-title-bold);
    color: var(--color-text-title);
  }

  & > p {
    font-size: 12px;
    font-family: var(--font-paragraph-default);
    color: var(--color-text-labels);
  }

  & > svg {
    width: 24px;
    height: 24px;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid var(--color-light-200);
  padding-bottom: 5px;

  .infoArea {
    display: flex;
    align-items: center;

    & .titleArea {
      display: flex;
      align-items: center;

      & > h1 {
        font-size: 24px;
        font-family: var(--font-title-bold);
        color: var(--color-text-title);
      }

      & > *:first-child {
        margin-right: 5px;
      }

      svg {
        width: 29px;
        height: 29px;
      }
    }
  }

  & > button {
    border: none;
    box-shadow: none;
    padding: 0;

    svg {
      color: var(--color-dark-blue-500);
      width: 24px;
      height: 24px;
    }

    &:hover {
      background: transparent;
    }
  }
`;

const Main = styled.main`
  padding: 24px 0;

  & > p,
  & > b {
    color: var(--color-text-paragraph);
    font-size: 16px;
  }

  p {
    font-family: var(--font-paragraph-default);
  }

  b {
    font-family: var(--font-paragraph-bold);
  }

  & > section {
    margin-top: 0;
  }
`;

const Footer = styled.footer<{ $text: string }>`
  border-top: 1px solid var(--color-light-200);
  padding: 16px 0px;

  display: flex;
  justify-content: space-between;

  .textContainer {
    b,
    p {
      font-size: 14px;
      color: var(--color-text-paragraph);
    }

    b {
      font-family: var(--font-paragraph-bold);
    }

    p {
      font-family: var(--font-paragraph-default);
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;

    ${({ $text }) => $text.length === 0 && "width: 100%;"}

    & button:not(& button:last-child) {
      margin-right: 12px;
    }
  }
`;

type TModalIcons =
  | "exclamationTriangle"
  | "infoCircle"
  | "dolarSign"
  | "link"
  | "breakLink"
  | "import"
  | "edit";
type TModalIconsColors = "rose" | "blue" | "red" | "orange" | "green";

type TModalIcon = {
  type: TModalIcons;
  color: TModalIconsColors;
};

type TModalFormVariants =
  | "addPayable"
  | "addPaymentSlip"
  | "linkPayable"
  | "installmentAccount"
  | "goods"
  | "goodsAutomated"
  | "goodsStockCheck"
  | "linkInvoice"
  | "linkPaymentSlip"
  | "renameDocument";

type TModalForm<T extends TModalFormVariants> = T extends "addPayable"
  ? {
      preset: "addPayable";
      invoice: TInvoice;
      footer: {
        // text?: TText[];
        buttons: TButtonProps<TButtonIconVariants>[];
      };
    }
  : T extends "addPaymentSlip"
  ? {
      preset: "addPaymentSlip";
      paymentSlip: TPaymentSlip;
      footer: {
        // text?: TText[];
        buttons: TButtonProps<TButtonIconVariants>[];
      };
    }
  : T extends "linkPayable"
  ? {
      preset: "linkPayable";
      invoice?: TInvoice;
      paymentSlip?: TPaymentSlip;
      footer: {
        // text?: TText[];
        buttons: TButtonProps<TButtonIconVariants>[];
      };
    }
  : T extends "linkInvoice"
  ? {
      preset: "linkInvoice";
      footer: {
        text?: TText[];
        buttons: TButtonProps<TButtonIconVariants>[];
      };
    }
  : T extends "linkPaymentSlip"
  ? {
      preset: "linkPaymentSlip";
      footer: {
        text?: TText[];
        buttons: TButtonProps<TButtonIconVariants>[];
      };
    }
  : T extends "installmentAccount"
  ? {
      preset: "installmentAccount";
      invoice?: TInvoice;
      paymentSlip?: TPaymentSlip;
      footer: {
        // text?: TText[];
        buttons: TButtonProps<TButtonIconVariants>[];
      };
    }
  : T extends "goods"
  ? {
      preset: "goods";
      invoice: TInvoice;
      // paymentSlip?: TPaymentSlip;
      footer: {
        // text?: TText[];
        buttons: TButtonProps<TButtonIconVariants>[];
      };
    }
  : T extends "goodsAutomated"
  ? {
      preset: "goodsAutomated";
      invoice: TInvoice;
      // paymentSlip?: TPaymentSlip;
      footer: {
        // text?: TText[];
        buttons: TButtonProps<TButtonIconVariants>[];
      };
    }
  : T extends "goodsStockCheck"
  ? {
      preset: "goodsStockCheck";
      data: {
        invoiceId: string;
        success: TNexaasProduct[];
        error: TNexaasProduct[];
      };
      // paymentSlip?: TPaymentSlip;
      footer: {
        // text?: TText[];
        buttons: TButtonProps<TButtonIconVariants>[];
      };
    }
  : {
      preset: "renameDocument";
      data: TInvoice | TPaymentSlip;
      footer: { buttons: TButtonProps<TButtonIconVariants>[] };
    };

type TMainVariants = "textOnly" | "table" | "form";

type TMain<T extends TMainVariants> = T extends "textOnly"
  ? { style: "textOnly"; content: TText[] }
  : T extends "table"
  ? { style: "table"; content: TTableProps<TRow> }
  : { style: "form"; content: TModalForm<TModalFormVariants> };

type TFooterVariants = "default" | "form";

type TFooter<T extends TFooterVariants> = T extends "default"
  ? {
      style: "default";
      text?: TText[];
      buttons?: TButtonProps<TButtonIconVariants>[];
    }
  : { style: "form"; text?: never; buttons?: never };

type TModalProps = {
  title: string;
  description?: string;
  icon?: TModalIcon;
  footer?: TFooter<TFooterVariants>;
  main: TMain<TMainVariants>;
  shouldOpen: boolean;
  onClose: () => void;
};

const Modal: React.FC<TModalProps> = ({
  title,
  description,
  icon,
  footer,
  main,
  shouldOpen,
  onClose,
}) => {
  const clearSelectedRef = useRef<(() => void) | null>(null);

  const handleIcon = () => {
    if (icon) {
      let color;
      switch (icon.color) {
        case "rose":
          color = getComputedStyle(document.documentElement).getPropertyValue(
            "--color-main-rose"
          );
          break;
        case "blue":
          break;
        case "red":
          color = getComputedStyle(document.documentElement).getPropertyValue(
            "--color-aux-danger-700"
          );
          break;
        case "orange":
          color = getComputedStyle(document.documentElement).getPropertyValue(
            "--color-aux-warning-700"
          );
          break;
        case "green":
      }
      switch (icon.type) {
        case "exclamationTriangle":
          return <UilExclamationTriangle color={color} />;
        case "infoCircle":
          return <UilInfoCircle color={color} />;
        case "dolarSign":
          return <UilDollarSign color={color} />;
        case "link":
          return <UilLink color={color} />;
        case "import":
          return <UilImport color={color} />;
        case "breakLink":
          return <UilLinkBroken color={color} />;
        case "edit":
          return <UilEditAlt color={color} />;
      }
    }
  };

  const handleMainRender = (main: TMain<TMainVariants>) => {
    switch (main.style) {
      case "textOnly":
        return (
          <>{...main.content.map((content) => handleTextRender(content))}</>
        );
      case "table":
        return <TableGrid {...main.content} />;
      case "form":
        return (
          <>
            {main.content.preset === "addPayable" && (
              <AddPayable
                invoice={main.content.invoice}
                footer={main.content.footer}
              />
            )}
            {main.content.preset === "addPaymentSlip" && (
              <AddPaymentSlip
                paymentSlip={main.content.paymentSlip}
                footer={main.content.footer}
              />
            )}
            {main.content.preset === "linkPayable" && (
              <LinkPayable
                invoice={main.content.invoice}
                paymentSlip={main.content.paymentSlip}
                footer={main.content.footer}
              />
            )}
            {main.content.preset === "linkPaymentSlip" && (
              <LinkPaymentSlip
                onClear={(clearFunction) => {
                  clearSelectedRef.current = clearFunction;
                }}
                footer={main.content.footer}
              />
            )}
            {main.content.preset === "linkInvoice" && (
              <LinkInvoice
                onClear={(clearFunction) => {
                  clearSelectedRef.current = clearFunction;
                }}
                footer={main.content.footer}
              />
            )}
            {main.content.preset === "installmentAccount" && (
              <InstallmentAccount
                // invoice={main.content.invoice}
                footer={main.content.footer}
              />
            )}
            {main.content.preset === "goods" && (
              <Goods
                invoice={main.content.invoice}
                footer={main.content.footer}
              />
            )}
            {main.content.preset === "goodsAutomated" && (
              <GoodsAutomated
                invoice={main.content.invoice}
                footer={main.content.footer}
              />
            )}
            {main.content.preset === "goodsStockCheck" && (
              <GoodsStockCheck
                data={main.content.data}
                footer={main.content.footer}
              />
            )}
            {main.content.preset === "renameDocument" && (
              <RenameDocument
                data={main.content.data}
                footer={main.content.footer}
              />
            )}
          </>
        );
    }
  };

  const handleTextRender = (content: TText) => {
    switch (content.style) {
      case "bold":
        return (
          <>
            <b style={{ display: "inline" }}>{content.text}</b>
            {content.shouldBreakLine && <br />}
          </>
        );
      case "default":
        return (
          <>
            <p style={{ display: "inline" }}>{content.text}</p>
            {content.shouldBreakLine && <br />}
          </>
        );
      case "faded":
        return (
          <>
            <span>{content.text}</span>
            {content.shouldBreakLine && <br />}
          </>
        );
    }
  };

  useEffect(() => {}, [shouldOpen]);

  return (
    <Container $shouldShow={shouldOpen ? "show" : "hide"}>
      <CustomBackdrop />
      <Dialog
        $formVariant={main.style === "form" ? main.content.preset : undefined}
        $childVariant={main.style}
        open={shouldOpen}
        $shouldShow={shouldOpen ? "show" : "hide"}
      >
        <Header>
          <div className="infoArea">
            <div className="titleArea">
              {icon && handleIcon()}
              <h1>{title}</h1>
            </div>
            {description && <p>{description}</p>}
          </div>
          <Button
            icon="onlyIcon"
            iconType="times"
            variant="secondary"
            onClick={() => {
              if (clearSelectedRef.current) {
                clearSelectedRef.current();
              }
              onClose();
            }}
          />
        </Header>
        <Main>{/* shouldOpen &&  */ handleMainRender(main)}</Main>
        {footer && (
          <Footer $text={footer.text ? "true" : ""}>
            {footer.text && (
              <div className="textContainer">
                {footer.text.map((content, index) => (
                  <React.Fragment key={index}>
                    {handleTextRender(content)}
                  </React.Fragment>
                ))}
              </div>
            )}
            <div className="buttonsContainer">
              {footer.buttons?.map((buttonProps, index) => (
                <Button key={index} {...buttonProps} />
              ))}
            </div>
          </Footer>
        )}
      </Dialog>
    </Container>
  );
};

export default Modal;
