import { UilChartLine } from "@iconscout/react-unicons";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import finteraLogo from "../../../../assets/images/fintera.svg";
import nexaasOmniOMSLogoGrey from "../../../../assets/images/nexaasOmniOMS.svg";
import SVG from "../../../ui/SVG";

const QuickAccessMenu = styled.div<{
  $shouldShow: boolean;
  $left?: number;
  $top?: number;
}>`
  padding: 12px;
  margin: 0;
  position: absolute;
  z-index: 2;
  ${({ $left, $top }) => `left: ${$left}px;` + `top: ${$top}px;`}

  visibility: ${({ $shouldShow }) => ($shouldShow ? "visible" : "hidden")};
  height: ${({ $shouldShow }) => ($shouldShow ? "240px" : "0px")};

  transition: height 0.3s ease-in-out, visibility 0.3s ease-in-out,
    left 0.3s ease-in-out, top 0.3s ease-in-out;

  background: var(--color-light-0);
  border: 1px solid var(--color-dark-blue-300);
  border-radius: 8px;
  box-shadow: 2px 4px 8px 0px #393c4d1a;

  h3 {
    font-family: var(--font-paragraph-bold);
    color: var(--color-text-title);
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

const QuickAccessMenuItem = styled.a`
  width: 262px;
  height: 55px;

  display: flex;
  align-items: center;

  border-radius: 8px;
  padding: 8px;

  color: var(--color-text-labels);

  &:hover {
    background: var(--color-light-100);
  }

  &:active {
    background: var(--color-main-blue);
    &,
    h4,
    p {
      color: var(--color-light-0);
    }

    svg .fintera {
      fill: var(--color-light-0);
    }

    svg.nexaasOmni {
      path {
        stroke: var(--color-light-0);

        &.nexaasPath1a,
        &.nexaasPath1b,
        &.nexaasPath2a,
        &.nexaasPath2b {
          fill: var(--color-light-0);
        }
      }
    }

    // pointer-events: none;
  }

  svg {
    width: 32px;
    height: 32px;
  }

  .info-container {
    margin-left: 12px;
  }

  h4 {
    font-family: var(--font-paragraph-bold);
    font-size: 16px;
    color: var(--color-text-paragraph);
  }

  p {
    font-family: var(--font-paragraph-default);
    font-size: 12px;
    color: var(--color-text-labels);
  }
`;

type QuickAccessMenuProps = {
  shouldShow: boolean;
  $ref: HTMLButtonElement | null;
  $left?: number;
  onClickAway?: () => void;
};

const QuickAccessMenuComponent: React.FC<QuickAccessMenuProps> = ({
  shouldShow,
  $ref,
  $left,
  onClickAway,
}) => {
  // const [nexaasOmniOMSLogo, setNexaasOmniOMSLogo] = useState(
  //   nexaasOmniOMSLogoGrey
  // );

  const [top, setTop] = useState<number | undefined>(
    ($ref?.getBoundingClientRect().top ?? 0) + 40
  );

  // const [left, setLeft] = useState<number | undefined>(
  //   $ref?.getBoundingClientRect().left
  // );

  let clickCounter = 0;
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClick = () => {
      if (onClickAway && clickCounter++ > 0) onClickAway();
    };
    if ($ref) setTop($ref.getBoundingClientRect().top + 40);
    setTimeout(() => {
      if ($ref) {
        // console.log("bounding client rect: ", $ref.getBoundingClientRect());
        // setLeft($ref.getBoundingClientRect().left);
      }
      // console.log(
      //   document
      //     .getElementById("quick-access-menu-button")
      //     ?.getBoundingClientRect()
      // );
    }, 1);

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [shouldShow, $ref]);

  return (
    <QuickAccessMenu
      ref={menuRef}
      $left={$left}
      $top={top}
      $shouldShow={shouldShow}
    >
      <h3>Acesso rápido</h3>
      <QuickAccessMenuItem href="https://bi.nexaas.com/" target="_blank">
        <UilChartLine />
        <div className="info-container">
          <h4>BI Analytics</h4>
          <p>Inteligência de dados</p>
        </div>
      </QuickAccessMenuItem>
      <QuickAccessMenuItem
        href={
          `${import.meta.env.VITE_BASE_URL}`.includes("localhost") ||
          `${import.meta.env.VITE_BASE_URL}`.includes(".hml.nexaas")
            ? "https://sandbox.conta.fintera.com.br/"
            : "https://conta.fintera.com.br/"
        }
        target="_blank"
      >
        <SVG src={finteraLogo} />
        <div className="info-container">
          <h4>Fintera</h4>
          <p>Gestão financeira</p>
        </div>
      </QuickAccessMenuItem>
      <QuickAccessMenuItem
        href={
          `${import.meta.env.VITE_BASE_URL}`.includes("localhost") ||
          `${import.meta.env.VITE_BASE_URL}`.includes(".hml.nexaas")
            ? "https://frontend-homologation.oms.nexaas.com/"
            : "https://new-app.oms.nexaas.com/"
        }
        target="_blank"
      >
        <SVG className="nexaasOmni" src={nexaasOmniOMSLogoGrey} />
        <div className="info-container">
          <h4>Nexaas Omni OMS</h4>
          <p>Painel Omnichannel</p>
        </div>
      </QuickAccessMenuItem>
    </QuickAccessMenu>
  );
};

export default QuickAccessMenuComponent;
