import styled from "styled-components";

export const Container = styled.div`
  // margin-top: 24px;
  // overflow: hidden;

  position: relative;

  height: calc(100vh - 151px);

  h1 {
    color: var(--color-text-title);
    font-size: 28px;
    font-family: var(--font-title-bold);
  }

  @media (max-width: 405px) {
    nav {
      height: auto;

      & > div {
        flex-direction: column;
        margin-bottom: 10px;

        p,
        div {
          margin-bottom: 10px;
        }
      }
    }
  }
`;

export const SearchBarSection = styled.section`
  margin-top: 16px;
  display: flex;
  align-items: center;

  & > *:first-child {
    width: 353px;
  }

  @media (max-width: 1080px) {
    align-items: flex-start;
    flex-direction: column;

    & > div {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 455px) {
    & > div {
      max-width: calc(100vw - 100px);
      width: calc(100vw - 143px);
    }
  }
`;

export const SearchBarSectionSubcontainer = styled.div`
  display: flex;

  & > * {
    margin-left: 16px;
  }

  @media (max-width: 1080px) {
    & > *:first-child {
      margin-left: 0;
    }
  }

  @media (max-width: 545px) {
    flex-direction: column;

    & > *:nth-child(2) {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  @media (max-width: 350px) {
    & > *:nth-child(2) div {
      min-width: unset;
    }
  }
`;

export const MobileSupportContainer = styled.div`
  @media (max-width: 460px) {
    & {
      overflow-x: scroll;
    }

    & > * {
      width: fit-content;
      min-width: 100vw;
    }
  }
`;
