import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";

import {
  setToastState,
  TInvoice,
  // TInvoice,
  TNexaasProduct,
} from "../../../../redux/reducers/globalState";

import Button, { TButtonIconVariants, TButtonProps } from "../../Button";
import TableGrid, {
  TCellStyleVariants,
  TTableBody,
  TText,
} from "../../TableGrid";
import TabsGroup from "../../../ui/groups/TabsGroup";
import { format } from "date-fns/format";
import SummaryCard from "../../SummaryCard";
import apiService from "../../../../services/api";
import {
  backendErrorMessageHandler,
  getQuerystringParams,
} from "../../../../utils/miscellaneous";
import axios, { CancelTokenSource } from "axios";
import { useDispatch } from "react-redux";

const Container = styled.form``;

const CardsContainer = styled.div<{ $row: "single" | "double" }>`
  display: flex;

  & > div {
    width: ${({ $row }) => ($row === "single" ? "100%" : "50%")};
  }
`;

const Footer = styled.footer`
  margin-top: 24px;
  border-top: 1px solid var(--color-light-200);
  padding: 16px 0px;
  display: flex;
  justify-content: flex-end;

  .textContainer {
    b,
    p {
      font-size: 14px;
      color: var(--color-text-paragraph);
    }

    b {
      font-family: var(--font-paragraph-bold);
    }

    p {
      font-family: var(--font-paragraph-default);
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;

    & button:not(& button:last-child) {
      margin-right: 12px;
    }
  }
`;

export type TPayableObjectForm = {
  amount: string;
  category_id: number | null;
  classification_center_id: number | null;
  description: string;
  due_date: string;
  mode: null | "in_cash" | "recurrent" | "installments";
  supplier_id: number | null;

  paid_amount?: string;
  fine_amount?: string;
  interest_amount?: string;
  discount_amount?: string;
  payment_date?: string;
  period?:
    | "weekly"
    | "biweekly"
    | "monthly"
    | "bimonthly"
    | "quarterly"
    | "semiannual"
    | "annual";
  installments?: number;
  income_tax_relevant?: boolean;
};

type TGoodsProps = {
  data: {
    invoiceId: string;
    success: TNexaasProduct[];
    error: TNexaasProduct[];
  };
  footer: { buttons: TButtonProps<TButtonIconVariants>[] };
};

const GoodsStockCheck: React.FC<TGoodsProps> = ({ data, footer }) => {
  const errorDataRef = useRef(data.error);
  const successDataRef = useRef(data.success);

  const [isButtonLoading, setIsButtonLoading] = useState(
    Array.from({ length: 3 }, () => false)
  );

  const [shoulUpdateComponent, setShoulUpdateComponent] = useState(false);

  const [errorData, setErrorData] = useState<TNexaasProduct[]>([]);
  const [successData, setSuccessData] = useState<TNexaasProduct[]>([]);

  const [invoice, setInvoice] = useState<TInvoice | null>(null);

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const dispatch = useDispatch();

  const handleCheckNoSku = (data: TNexaasProduct[]) =>
    data?.filter((value) =>
      /* value.sku === null */ value.reason?.includes("sku_not_found")
    );

  const handleCheckNoBatchInfo = (data: TNexaasProduct[]) =>
    data?.filter((value) =>
      /* !value.batch_expiration_date || !value.batch_manufacturing_date */ value.reason?.includes(
        "batch"
      )
    );

  const handleCheckHasConversionFactor = (data: TNexaasProduct[]) =>
    data?.filter((value) =>
      /* value.conversion_factor !== null */ value.reason?.includes(
        "conversion"
      )
    );

  const onSubmitHandler = (
    onClick: ((props?: any) => void) | undefined,
    index: number
  ) => {
    setIsButtonLoading((prev) => {
      const temp = [...prev];
      temp[index] = !temp[index];

      return temp;
    });

    if (index === 0) {
      const queryStrings = getQuerystringParams();

      if (queryStrings.organizationId && cancelTokenSource.current) {
        apiService
          .getNonCachedGoodsExpressModeStatus(
            queryStrings.organizationId,
            data.invoiceId,
            cancelTokenSource.current.token
            // { type: "resolve", model: "mock1" }
          )
          .then((response: any) => {
            setErrorData(response.data.errors);
            setSuccessData(response.data.success);

            errorDataRef.current = response.data.errors;
            successDataRef.current = response.data.success;

            setShoulUpdateComponent(true);
            setTimeout(() => {
              setShoulUpdateComponent(false);
            }, 50);
          })
          .catch((e) => {
            const errorMessage = backendErrorMessageHandler(e);

            dispatch(
              setToastState({
                variant: "error",
                shouldShow: true,
                message: {
                  title: "Erro ao atualizar",
                  description:
                    typeof errorMessage === "string"
                      ? errorMessage
                      : "Não foi possível atualizar os dados, tente novamente",
                },
              })
            );
          })
          .finally(() => {
            setIsButtonLoading((prev) => {
              const temp = [...prev];
              temp[index] = !temp[index];

              return temp;
            });
          });
      }
    } else {
      if (onClick) onClick();
    }
  };

  const rowFormatter = (product: TNexaasProduct, index: number) => {
    return [
      { style: "default", value: product.index.toString() },
      [
        product.sku !== null && {
          style: "default",
          value: product.sku,
          marginRight: "8px",
          shouldBreakLine: true,
        },
        { style: "default", value: product.supplier_sku, marginRight: "8px" },
        product.sku === null && {
          style: "brokenLink",
          icon: "brokenLink",
          iconPosition: "left",
          value: "Não encontrado",
        },
      ],
      {
        style: "miscellaneous",
        values: [
          {
            style: "default",
            text: product.description,
            shouldBreakLine: true,
          },
          {
            style: "bold",
            text: "EAN:",
            shouldBreakLine: false,
            marginRight: "8px",
          },
          {
            style: "default",
            text: product.ean,
            shouldBreakLine: false,
          },
        ],
      },
      {
        style: "miscellaneous",
        values: [
          {
            style: "bold",
            text: product.quantity,
            shouldBreakLine: false,
            marginRight: "8px",
          },
          { style: "default", text: product.measure, shouldBreakLine: false },
        ],
      },
      [
        {
          style: "button",
          icon: "arrowRight",
          isDisabled: true,
          customCSS: { paddingLeft: 0 },
        },
      ],
      [
        {
          style: "miscellaneous",
          values: [
            {
              style: "bold",
              text:
                product.verified_quantity !== null
                  ? product.verified_quantity
                  : " - ",
              shouldBreakLine: false,
              marginRight: "8px",
            },
            {
              style: "default",
              text:
                product.verified_quantity !== null
                  ? product.verified_measure ?? "UN"
                  : "",
              marginRight: "8px",
              shouldBreakLine: false,
            },
          ],
        },
        product.conversion_factor !== null && {
          style: "button",
          icon: "exchange",
          tooltip: {
            customTextAlignment: "left",
            customTopPosition: 100 * index + 175,
            text: [
              { style: "default", text: "", shouldBreakLine: true },
              {
                style: "default",
                text: "Fator de conversão:",
                shouldBreakLine: true,
              },
              {
                style: "default",
                marginRight: "3px",
                text: product.conversion_factor.purchase_quantity,
                shouldBreakLine: false,
              },
              {
                style: "default",
                marginRight: "3px",
                text: product.conversion_factor.purchase_measure,
                shouldBreakLine: false,
              },
              {
                style: "default",
                marginRight: "3px",
                text: "->",
                shouldBreakLine: false,
              },
              {
                style: "default",
                marginRight: "3px",
                text: product.conversion_factor.sales_quantity,
                shouldBreakLine: false,
              },
              {
                style: "default",
                marginRight: "3px",
                text: product.conversion_factor.sales_measure,
                shouldBreakLine: true,
              },
              {
                style: "default",
                text: "Para mudar o fator de conversão, acesse o cadastro do produto no Omni OMS",
                shouldBreakLine: true,
              },
              { style: "default", text: "", shouldBreakLine: true },
            ],
          },
          isDisabled: true,
        },
      ],
      {
        style: "miscellaneous",
        values:
          !product.batch_name &&
          !product.batch_expiration_date &&
          !product.batch_manufacturing_date
            ? [{ style: "faded", text: "Sem lote", shouldBreakLine: false }]
            : [
                {
                  style: "bold",
                  text: product.batch_name ?? " - ",
                  shouldBreakLine: true,
                },
                {
                  style: "bold",
                  fontSize: "12px",
                  text: "Fab:",
                  marginRight: "3px",
                  shouldBreakLine: false,
                },
                {
                  style: "default",
                  fontSize: "12px",
                  text: product.batch_manufacturing_date
                    ? format(product.batch_manufacturing_date, "dd/MM/yyyy")
                    : " - ",
                  marginRight: "3px",
                  shouldBreakLine: true,
                },
                {
                  style: "bold",
                  fontSize: "12px",
                  text: "Val:",
                  marginRight: "3px",
                  shouldBreakLine: false,
                },
                {
                  style: "default",
                  fontSize: "12px",
                  text: product.batch_expiration_date
                    ? format(product.batch_expiration_date, "dd/MM/yyyy")
                    : " - ",
                  marginRight: "3px",
                  shouldBreakLine: true,
                },
              ],
      },
    ] as TTableBody<TCellStyleVariants>[];
  };

  useEffect(() => {
    setTimeout(() => {
      cancelTokenSource.current = axios.CancelToken.source();
      apiService
        .getInvoice(data.invoiceId, cancelTokenSource.current.token)
        .then((response: any) => {
          setInvoice(response.data);
        });
    }, 500);

    if (errorData.length === 0 && successData.length === 0) {
      // console.log("setting initial values: ", data);
      setErrorData(data.error);
      setSuccessData(data.success);
      errorDataRef.current = data.error;
      successDataRef.current = data.success;
    } else {
      // console.log("errors: ", errorData, ", success: ", successData);
    }

    return () => {
      if (cancelTokenSource.current)
        cancelTokenSource.current.cancel("from goods stock check, canceling");
    };
  }, []);

  return (
    <Container>
      <CardsContainer
        $row={
          [...errorData, ...successData].some(
            (value) => value.conversion_factor
          ) &&
          [
            ...errorData.filter(
              (error) => !error.reason?.includes("conversion")
            ),
          ].length > 0
            ? "double"
            : "single"
        }
      >
        {[...errorData.filter((error) => !error.reason?.includes("conversion"))]
          .length > 0 && (
          <SummaryCard
            variant="error"
            messages={[
              handleCheckNoSku(errorData).length > 0
                ? "- Há itens não encontrados/cadastrados no Omni"
                : "",
              [...handleCheckNoBatchInfo(errorData)].length > 0
                ? "- Há itens com controle de lote sem as informações de lote preenchidas"
                : "",
            ]}
          />
        )}
        {[...errorData, ...successData].some(
          (value) => value.conversion_factor
        ) && (
          <SummaryCard
            variant="warning"
            messages={[
              "Alguns produtos possuem fator de conversão, verifique se estão de acordo com o real recebido",
            ]}
          />
        )}
      </CardsContainer>

      <TabsGroup
        key={shoulUpdateComponent + ""}
        initialActiveTabIndex={(() => {
          return 0;
        })()}
        onClickItems={[
          () => {
            setErrorData(errorDataRef.current);
            setSuccessData(successDataRef.current);
          },
          () => {
            setErrorData(handleCheckNoSku(errorDataRef.current));
            setSuccessData(handleCheckNoSku(successDataRef.current));
          },
          () => {
            setErrorData(handleCheckNoBatchInfo(errorDataRef.current));
            setSuccessData(handleCheckNoBatchInfo(successDataRef.current));
          },
          () => {
            setErrorData(handleCheckHasConversionFactor(errorDataRef.current));
            setSuccessData(
              handleCheckHasConversionFactor(successDataRef.current)
            );
          },
        ]}
        stringIcon={[
          {
            index: 1,
            value: [
              ...handleCheckNoSku(errorDataRef.current),
              ...handleCheckNoSku(successDataRef.current),
            ].length.toString(),
          },
          {
            index: 2,
            value: [
              ...handleCheckNoBatchInfo(errorDataRef.current),
              ...handleCheckNoBatchInfo(successDataRef.current),
            ].length.toString(),
          },
          {
            index: 3,
            value: [
              ...handleCheckHasConversionFactor(errorDataRef.current),
              ...handleCheckHasConversionFactor(successDataRef.current),
            ].length.toString(),
          },
        ]}
      >
        <>
          <h2>Todas</h2>
          <div></div>
        </>
        <>
          <h2>SKU não encontrado</h2>
        </>
        <>
          <h2>Lote sem data preenchida</h2>
          <div></div>
        </>
        <>
          <h2>Com fator de conversão</h2>
          <div></div>
        </>
      </TabsGroup>
      <TableGrid
        // key={shouldUpdateComponent + "-invoices-tablegrid"}
        row="singleRow"
        borderColor={[
          ...errorData
            .filter((error) => !error.reason?.includes("conversion"))
            .map(() => "red"),
          ...successData.map(() => "light"),
        ]}
        columns={{
          tableHead: {
            // 0px 12px 100px 100px 100px 100px 100px 0px
            width: [`0px 12px 50px 250px 360px 100px 50px 130px 90px`],
          },
          tableBody: {
            width: [`0px 12px 50px 250px 360px 100px 50px 130px 90px`],
          },
        }}
        onAction={() => {
          window.location.reload();
        }}
        emptyState={{
          isEmptyState: [...errorData, ...successData].length === 0,
          title: "Nenhuma nota encontrada",
          description: "Altere os filtros e realize a busca novamente",
        }}
        selectType={{
          type: "noSelect" /*, fullData: allInvoicesRef.current  */,
        }}
        tableHead={{
          value: [
            { style: "noIcon", value: "Nº" },
            { style: "noIcon", value: "SKU/Cód. Fornecedor" },
            { style: "noIcon", value: "Descrição" },
            { style: "noIcon", value: "Qtde." },
            {
              style: "noIcon",
              value: "",
            },
            {
              style: "noIcon",
              value: "Qtde. Conferida",
            },
            { style: "noIcon", value: "Lote" },
          ],
          customTopPosition: "-25px",
        }}
        tableBody={[
          ...[...errorData]
            .sort((a, b) => a.index - b.index)
            .map((result, index: number) => ({
              elementId: "",
              file_nfe: "",
              file_xml: "",
              rowTooltip:
                [
                  ...errorData.filter(
                    (error) => !error.reason?.includes("conversion")
                  ),
                ].length > index
                  ? {
                      customTopPosition: 95 * index + 265,
                      text: [
                        {
                          style: "default",
                          text: "Corrija os erros no OMS para finalizar a Entrada",
                          shouldBreakLine: false,
                        } as TText,
                      ],
                    }
                  : undefined,
              body: rowFormatter(result, index),
            })),
          ...[...successData]
            .sort((a, b) => a.index - b.index)
            .map((result, index: number) => ({
              elementId: "",
              file_nfe: "",
              file_xml: "",
              body: rowFormatter(result, index),
            })),
        ]}
        // footer={{
        //   customPagination: invoiceState
        //     ? {
        //         isFixedAtTheBottom: true,
        //         currentPage: `${invoiceState?.current_page}`,
        //         buttonBackward: {
        //           disabled: invoiceState.current_page === 1,
        //           onClick() {
        //             if (invoiceState.current_page === 1) {
        //               pageRef.current = undefined;
        //             } else {
        //               pageRef.current = invoiceState.current_page - 1;
        //             }

        //             handleSetDataToLocalStorage(
        //               localStorageConstants.CURRENT_PAGE,
        //               pageRef.current
        //             );

        //             if (
        //               selectOrganization &&
        //               invoiceState.current_page !== 1
        //             ) {
        //               dispatch(
        //                 fetchInvoices({
        //                   search: searchRef.current,
        //                   page: pageRef.current,
        //                   organizationUuid: selectOrganization.uuid,
        //                   launch_status: entryStatusRef.current,
        //                   dateIssueStart: startDateRef.current,
        //                   dateIssueEnd: endDateRef.current,
        //                   has_accounts_payable: payablesRef.current,
        //                   has_goods_receipt: goodsEntryRef.current,
        //                 })
        //               );
        //             }
        //           },
        //         },
        //         buttonForward: {
        //           disabled:
        //             invoiceState.current_page === invoiceState.pages.length,
        //           onClick() {
        //             if (
        //               invoiceState.current_page === invoiceState.pages.length
        //             ) {
        //               pageRef.current = invoiceState.current_page;
        //             } else {
        //               pageRef.current = invoiceState.current_page + 1;
        //             }

        //             handleSetDataToLocalStorage(
        //               localStorageConstants.CURRENT_PAGE,
        //               pageRef.current
        //             );

        //             if (
        //               selectOrganization &&
        //               invoiceState.current_page !== invoiceState.pages.length
        //             ) {
        //               dispatch(
        //                 fetchInvoices({
        //                   search: searchRef.current,
        //                   page: pageRef.current,
        //                   organizationUuid: selectOrganization.uuid,
        //                   launch_status: entryStatusRef.current,
        //                   dateIssueStart: startDateRef.current,
        //                   dateIssueEnd: endDateRef.current,
        //                   has_accounts_payable: payablesRef.current,
        //                   has_goods_receipt: goodsEntryRef.current,
        //                 })
        //               );
        //             }
        //           },
        //         },
        //         pages: invoiceState.pages.map((page) => {
        //           return {
        //             value: Object.keys(page)[0],
        //             onClick() {
        //               let temp =
        //                 Object.values(page)[0].match(/.*?page=(\d+)/);

        //               if (temp !== null && temp[1]) {
        //                 pageRef.current = parseInt(temp[1]);
        //               } else {
        //                 pageRef.current = undefined;
        //               }

        //               handleSetDataToLocalStorage(
        //                 localStorageConstants.CURRENT_PAGE,
        //                 pageRef.current
        //               );

        //               if (selectOrganization) {
        //                 dispatch(
        //                   fetchInvoices({
        //                     search: searchRef.current,
        //                     page: pageRef.current,
        //                     organizationUuid: selectOrganization.uuid,
        //                     launch_status: entryStatusRef.current,
        //                     dateIssueStart: startDateRef.current,
        //                     dateIssueEnd: endDateRef.current,
        //                     has_accounts_payable: payablesRef.current,
        //                     has_goods_receipt: goodsEntryRef.current,
        //                   })
        //                 );
        //               }
        //             },
        //           };
        //         }),
        //       }
        //     : undefined,
        // }}
      />
      <Footer>
        <div className="buttonsContainer">
          {footer.buttons?.map((buttonProps, index) => {
            const { onClick, ...props } = buttonProps;

            return (
              <Button
                key={index + " - " + isButtonLoading[index]}
                role={index === 2 ? "submit" : "button"}
                {...props}
                onClick={(e) => {
                  e.preventDefault();
                  onSubmitHandler(onClick, index);
                }}
                disabled={
                  index === 2 &&
                  ([
                    ...errorDataRef.current.filter(
                      (error) => !error.reason?.includes("conversion")
                    ),
                  ].length > 0 ||
                    [...errorDataRef.current, ...successDataRef.current]
                      .length === 0 ||
                    (invoice !== null && invoice.nexaas_state > 40))
                }
                isLoading={isButtonLoading[index]}
              />
            );
          })}
        </div>
      </Footer>
    </Container>
  );
};

export default GoodsStockCheck;
