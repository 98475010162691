import styled from "styled-components";
import { UilAngleDown } from "@iconscout/react-unicons";
import { useCallback, useRef, useState } from "react";
import { TVariants } from ".";
import useDetectOutsideClick from "../../../hooks/useDetectOutsideClick";
import { handleGetDataFromLocalStorage } from "../../../utils/miscellaneous";
import { localStorageConstants } from "../../../utils/constants";
import { TOrganization } from "../../../redux/reducers/organizations";
// import { AppContext } from "../../../contexts/AppContext";

const Container = styled.div`
  position: relative;

  & > p {
    font-size: 10px;
    font-family: var(--font-paragraph-default);
    color: var(--color-aux-danger-700);

    position: absolute;
  }
`;

const Subcontainer = styled.button<{
  placeholder?: string;
  content: string;
  $onError: "hasError" | "noError";
  $variant: TVariants;
  $disabled?: boolean;
}>`
  position: relative;

  box-sizing: border-box;
  width: 100%;
  min-height: 46px;

  font-family: var(--font-paragraph-default);

  // padding: 12px;

  background: ${({ $disabled }) =>
    $disabled ? `var(--color-light-100)` : `var(--color-light-0)`};

  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};

  border-radius: 8px;
  border: 1px solid
    ${({ $onError }) =>
      $onError === "hasError"
        ? `var(--color-aux-danger-700)`
        : `var(--color-dark-blue-300)`};

  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  &::before {
    font-size: 9px;
    color: var(--color-text-labels);

    position: absolute;
    top: 8px;
    left: 12px;

    ${({ placeholder, content, $variant }) =>
      content?.length > 0 && $variant === "labeled"
        ? `
            content: '${placeholder}';
            visibility: visible;
          `
        : `content:'';
           visibility: hidden;
          `}
  }

  p {
    font-family: var(--font-paragraph-default);
    color: ${({ $disabled }) =>
      $disabled ? `var(--color-text-labels)` : ` var(--color-text-paragraph)`};
    font-size: 14px;

    position: absolute;
    left: 12px;
  }

  p.placeholder {
    color: var(--color-text-labels);
    top: 13px;
    ${({ content }) =>
      content?.length > 0 ? `visibility: hidden` : `visibility: visible`};

    & .mandatory {
      color: var(--color-aux-danger-700);
    }
  }

  p.content {
    top: 18px;
    ${({ content }) =>
      content?.length > 0 ? `visibility: visible` : `visibility: hidden`};
    width: calc(100% - 40px);
    text-align: start;
  }

  div.naturalPadding {
    visibility: hidden;
    padding: 3px 0px;

    &::before {
      content: "${({ content }) => content}";
      display: block;
      width: calc(100% - 40px);
      font-size: 14px;
    }
  }

  svg {
    position: absolute;
    right: 12px;
    top: 12px;

    transform: rotateZ(0deg);

    transition: transform 0.25s ease-in-out;
  }

  &:hover {
    background: ${({ $disabled }) => !$disabled && `var(--color-light-0)`};
  }

  &.active,
  &:active {
    border-color: var(--color-main-blue);

    svg {
      transform: rotateZ(180deg);
    }
  }
`;

// const ContentArea = styled.div``;

// const LabelArea = styled.div``;

type TCommonProps = {
  placeholder: string;
  onClick?: (
    setIsClicked: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  onBlur?: (e: React.FocusEvent<HTMLButtonElement, Element>) => "show" | "hide";
  onError?: {
    hasError: boolean;
    message: string;
  };
  hasMandatoryStyle?: boolean;
  disabled?: boolean;
  label: string;
  componentRef?: React.MutableRefObject<HTMLDivElement | null>;
};

type TLabeled = {
  variant: "labeled";
};

type TUnlabeled = {
  variant: "unlabeled";
};

type TButtonProps<Variant extends TVariants> = Variant extends "labeled"
  ? TLabeled & TCommonProps
  : TUnlabeled & TCommonProps;

const Button: React.FC<TButtonProps<TVariants>> = ({
  variant,
  placeholder,
  label,
  onClick,
  // onBlur,
  onError,
  componentRef,
  disabled,
  hasMandatoryStyle,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  useDetectOutsideClick({
    isClicked: isClicked,
    componentRef,
    onOutside() {
      setIsClicked(false);
      if (onClick) onClick(setIsClicked);
    },
  });

  const handleRenderButton = useCallback(() => {
    const textRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    // const shouldTriggerBlurEventRef = useRef(false);

    switch (variant) {
      case "labeled":
        const localSelectedOrganization = handleGetDataFromLocalStorage(
          localStorageConstants.SELECT_ORGANIZATION
        ) as TOrganization;

        const handleOnClickButtonLabeled = (
          _e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => {
          // e.preventDefault();

          // console.log("**** blur has been applied ****");
          // shouldTriggerBlurEventRef.current = false;
          // buttonRef.current?.blur();

          setIsClicked((prev) => {
            // if (!prev) {
            //   console.log(
            //     "from has not click. Should trigger on blur: ",
            //     shouldTriggerBlurEventRef.current
            //   );
            //   setTimeout(() => {
            //     shouldTriggerBlurEventRef.current = true;
            //     buttonRef.current?.focus();
            //   }, 20);
            //   return true;
            // } else {
            //   console.log("****** from has click *****");
            //   shouldTriggerBlurEventRef.current = true;
            //   //
            //   return false;
            // }

            // if (!prev) buttonRef.current?.focus();
            // else buttonRef.current?.blur();

            return !prev;
          });
          if (onClick) onClick(setIsClicked);
        };

        // const handleOnBlurLabeled = (
        //   e: React.FocusEvent<HTMLButtonElement, Element>
        // ) => {
        //   // console.log(
        //   //   "from blur should trigger on blur: ",
        //   //   shouldTriggerBlurEventRef.current
        //   // );
        //   // if (shouldTriggerBlurEventRef.current) {
        //   if (onBlur && onBlur(e) === "hide") {
        //     setIsClickedLabeled(false);
        //   }
        //   // }
        // };

        return (
          <Container>
            <Subcontainer
              $disabled={disabled}
              ref={buttonRef}
              $variant={variant}
              $onError={(onError?.hasError && "hasError") || "noError"}
              role="button"
              // onBlur={handleOnBlurLabeled}
              onClick={handleOnClickButtonLabeled}
              className={!disabled && isClicked ? "active" : ""}
              placeholder={placeholder}
              content={label}
            >
              {label?.length > 0 ? (
                <>
                  <p ref={textRef} className="content">
                    {placeholder.toLowerCase() === "organização"
                      ? localSelectedOrganization.company_name
                      : label}
                  </p>
                  <div className="naturalPadding">
                    {placeholder.toLowerCase() === "organização"
                      ? localSelectedOrganization.company_name
                      : label}
                  </div>
                </>
              ) : (
                <p className="placeholder">
                  {placeholder} {hasMandatoryStyle && <span>&nbsp;*</span>}
                </p>
              )}
              <UilAngleDown />
            </Subcontainer>
            {onError && onError.hasError && <p>{onError.message}</p>}
          </Container>
        );
      case "unlabeled":
        const handleOnClickButtonUnlabeled = (
          e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => {
          e.preventDefault();
          if (!isClicked) {
            buttonRef.current?.focus();
          }
          setIsClicked((prev) => !prev);
          if (onClick) onClick(setIsClicked);
        };

        // const handleOnBlurUnlabeled = (
        //   e: React.FocusEvent<HTMLButtonElement, Element>
        // ) => {
        //   if (onBlur && onBlur(e) === "hide") {
        //     setIsClicked(false);
        //   }
        // };

        return (
          <Container>
            <Subcontainer
              $disabled={disabled}
              ref={buttonRef}
              $variant={variant}
              $onError={(onError?.hasError && "hasError") || "noError"}
              role="button"
              // onBlur={handleOnBlurUnlabeled}
              onClick={handleOnClickButtonUnlabeled}
              className={isClicked ? "active" : ""}
              placeholder={placeholder}
              content={label ?? ""}
            >
              {label?.length > 0 ? (
                <p className="content">{label}</p>
              ) : (
                <p className="placeholder">
                  {placeholder}
                  {hasMandatoryStyle && (
                    <span className="mandatory">&nbsp;*</span>
                  )}
                </p>
              )}
              <UilAngleDown />
            </Subcontainer>
            {onError && onError.hasError && <p>{onError.message}</p>}
          </Container>
        );
    }
  }, [label, onError, isClicked]);

  return handleRenderButton();
};

export default Button;
