import styled from "styled-components";
import BreadCrumbs from "../../../../components/ui-v2/BreadCrumbs";
import { ROUTES } from "../../../../router";
import LabeledTextInput from "../../../../components/ui-v2/Inputs/LabeledTextInput";
import Dropdown from "../../../../components/ui-v2/Dropdown";
import ToggleSwitch from "../../../../components/ui-v2/Inputs/ToggleSwitch";
import Button from "../../../../components/ui-v2/Button";
import { UilInfoCircle } from "@iconscout/react-unicons";
import Modal from "../../../../components/ui-v2/Modal";
import { useCallback, useEffect, useRef, useState } from "react";
import { TTableSelected } from "../../../../components/ui-v2/TableGrid";
import {
  TOrganization,
  TOrganizationData,
} from "../../../../redux/reducers/organizations";
import {
  decideMembershipRole,
  handleGetDataFromLocalStorage,
  handleSetDataToLocalStorage,
  validateEmail,
} from "../../../../utils/miscellaneous";
import apiService from "../../../../services/api";
import {
  TMembership,
  TMembershipRole,
} from "../../../../redux/reducers/memberships";
import { useNavigate } from "react-router-dom";
import { localStorageConstants } from "../../../../utils/constants";
import { TAccount } from "../../../../redux/reducers/account";
import axios, { AxiosError, CancelTokenSource } from "axios";
import {
  setOrganizationsState,
  setToastState,
} from "../../../../redux/reducers/globalState";
import { useTypedDispatch } from "../../../../hooks/useTypedDispatch";
import Tooltip from "../../../../components/ui-v2/Tooltip";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { cnpjMask } from "../../../../utils/formatters";

const Container = styled.main`
  position: relative;

  & > *:nth-child(1) {
    margin-top: 20px;
  }

  h1 {
    font-family: var(--font-title-bold);
    font-size: 28px;
    color: var(--color-text-title);
    margin-top: 10px;
    margin-bottom: 20px;
  }

  h2:not(h2:first-of-type) {
    font-family: var(--font-title-bold);
    font-size: 21px;
    color: var(--color-text-paragraph);
    margin-top: 16px;
  }

  h3,
  h4 {
    font-family: var(--font-paragraph-default);
  }

  h4 {
    font-size: 14px;
    color: var(--color-text-labels);
  }

  h3 {
    font-size: 16px;
    font-family: var(--font-paragraph-default);
    color: var(--color-text-paragraph);
  }

  h3.bold {
    font-family: var(--font-paragraph-bold);
  }
`;

const ContentContainer = styled.form`
  h2:not(h2:first-child) {
    margin-top: 32px;
  }

  fieldset {
    padding: 16px;
    padding-top: 10px;
    padding-bottom: 30px;
    border: 1px solid var(--color-light-200);
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  }
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  &:first-of-type {
    & > div > button:first-child {
      width: 316px;
    }
    & > *:nth-child(2) {
      margin-left: 16px;
      width: 316px;
    }
    & > *:nth-child(3) {
      width: 20px;
      height: 20px;
      color: var(--color-dark-blue-300);
      margin-left: 10px;
    }
  }

  & > p {
    font-family: var(--font-paragraph-default);
    font-size: 14px;
    color: var(--color-text-paragraph);
  }

  .selected {
    display: flex;
    align-items: center;
    margin-left: 12px;

    output {
      background: var(--color-light-100);
      border-radius: 4px;
      padding: 4px 8px;
    }
  }

  &.lastRow {
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: var(--color-dark-blue-600);
    width: 17px;
    height: 17px;
    margin-right: 5px;
  }

  strong {
    font-family: var(--font-paragraph-bold);
  }

  p,
  a {
    font-family: var(--font-paragraph-default);
  }

  strong,
  p {
    color: var(--color-text-paragraph);
  }

  a {
    color: var(--color-main-blue);
  }

  strong,
  default,
  a {
    font-size: 14px;
  }
`;

const ActionContainer = styled.div`
  display: flex;

  & > *:nth-child(2) {
    margin-left: 16px;
  }
`;

type TUsersFormProps = {
  variant: "create" | "edit";
};

const UsersForm: React.FC<TUsersFormProps> = ({ variant }) => {
  const dispatch = useTypedDispatch();

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const [shouldOpenModal, setShouldOpenModal] = useState(false);

  const [editableUser, setEditableUser] = useState<TMembership | null>(null);
  const [fillAccessProfile, setFillAccessProfile] = useState("");

  const organizationData = useTypedSelector(
    (state) => state.globalState.data.organizationData
  );

  const allOrganizationsRef = useRef<TOrganization[]>([]);
  const searchPhraseRef = useRef<string | undefined>();

  const shouldAllowConfirmModalSelection = useRef(false);

  const [selectAccount, setSelectAccount] = useState<TAccount | null>(null);

  const pageRef = useRef<number | undefined>();

  const [shouldUpdateComponent, setShouldUpdateComponent] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [accessProfile, setAccessProfile] = useState("");
  const [accessProfileError, setAccessProfileError] = useState("");

  const isActiveUserRef = useRef(false);

  const [selectedOrganizations, setSelectedOrganizations] = useState<
    TOrganization[]
  >([]);

  const [tableSelectedOrganizations, setTableSelectedOrganizations] = useState<
    TOrganization[]
  >([]);

  const navigate = useNavigate();

  const handleModalOnSelect = useCallback(
    (selected: TTableSelected[]) => {
      const handleCheckSelected = () => {
        const organizations = allOrganizationsRef.current.filter((element) => {
          return selected.map((select) => {
            return element.uuid === select.elementId;
          });
        });

        const result = organizations.filter(
          (org) => !tableSelectedOrganizations.includes(org)
        );

        return result[0];
      };
      // console.log("selected: ", selected);
      if (!shouldAllowConfirmModalSelection.current)
        shouldAllowConfirmModalSelection.current = true;

      if (selected[0].elementId === "all" && allOrganizationsRef.current)
        setTableSelectedOrganizations(allOrganizationsRef.current);
      else if (selected[0].elementId === "none")
        setTableSelectedOrganizations([]);
      else if (
        (selected[0] as { isChecked: boolean; elementId: string }).isChecked
      ) {
        const value = handleCheckSelected();

        if (value && !tableSelectedOrganizations.includes(value)) {
          setTableSelectedOrganizations([...tableSelectedOrganizations, value]);
        }
      } else {
        const value = tableSelectedOrganizations.filter(
          (organization) =>
            organization.uuid !==
            allOrganizationsRef.current.find(
              (element) => element.uuid === selected[0].elementId
            )?.uuid
        );
        if (value) setTableSelectedOrganizations(value);
      }
    },
    [
      tableSelectedOrganizations,
      organizationData?.results,
      allOrganizationsRef.current,
    ]
  );

  const handleOpenModal = () => {
    setShouldOpenModal(true);
  };

  const handleModalOnCancel = () => {
    shouldAllowConfirmModalSelection.current = false;
    setShouldOpenModal(false);

    setTableSelectedOrganizations(selectedOrganizations);
    setShouldUpdateComponent(true);
    setTimeout(() => {
      setShouldUpdateComponent(false);
    }, 30);
  };

  const handleModalOnSuccess = () => {
    setSelectedOrganizations(tableSelectedOrganizations);
    setShouldOpenModal(false);
  };

  const handleCheckEmail = () => {
    if (email.length === 0) {
      return "Campo obrigatório";
    } else if (variant !== "edit" && !validateEmail(email)) {
      return "E-mail inválido";
    } else {
      return "";
    }
  };

  const handleAccessProfileOnSelect = (selected: string) => {
    setFillAccessProfile("");
    setAccessProfile(selected);
  };

  const onSubmitHandler = () => {
    const onSuccess = async (response: any) => {
      await apiService
        .setOrganizationsByMembership(
          response.data.uuid,
          selectedOrganizations.map(
            (selectedOrganization) => selectedOrganization.uuid
          ),
          (cancelTokenSource.current as CancelTokenSource).token
        )
        .then(() => {
          dispatch(
            setToastState({
              variant: "success",
              shouldShow: true,
              message: {
                title: "Sucesso",
                description:
                  variant === "create"
                    ? "O novo usuário foi criado"
                    : "O cadastro foi atualizado",
              },
            })
          );
          navigate(ROUTES.SETTINGS_USERS);
        })
        .catch((e) => {
          console.error(e);
          dispatch(
            setToastState({
              variant: "error",
              shouldShow: true,
              message: {
                title: "Erro",
                description:
                  variant === "create"
                    ? "Não foi possível criar o usuário, revise os campos e tente novamente"
                    : "Não foi possível atualizar o cadastro, revise os campos e tente novamente",
              },
            })
          );
        });
    };

    const emailError = handleCheckEmail();

    const accessProfileError = accessProfile.length === 0;

    if (emailError.length > 0 && accessProfileError) {
      setEmailError(emailError);
      setAccessProfileError("Campo obrigatório");
    } else if (emailError.length > 0) {
      setEmailError(emailError);
      setAccessProfileError("");
    } else if (accessProfileError) {
      setAccessProfileError("Campo obrigatório");
      setEmailError("");
    } else {
      setEmailError("");
      setAccessProfileError("");
      // console.log("access profile: ", accessProfile);
      let role = decideMembershipRole(
        accessProfile as "Administrador" | "Apenas leitura" | "Colaborador"
      ) as TMembershipRole;

      console.log("account: ", organizationData);

      const newMemberData = {
        account: (organizationData as TOrganizationData).results[0].account
          .uuid,
        email: email,
        role: role,
        is_active: isActiveUserRef.current,
        organizations: selectedOrganizations.map(
          (organization) => organization.uuid
        ),
      };

      // console.log("from table, selected: ", tableSelectedOrganizations);

      if (variant === "create") {
        apiService
          .createMember(
            newMemberData,
            (cancelTokenSource.current as CancelTokenSource).token
          )
          .then((response) => {
            onSuccess(response);
          })
          .catch((e: AxiosError) => {
            console.error(e);
            const error = e.response?.data as { email: string[] };
            const emailInUse = "Already exists";
            if (error.email.includes(emailInUse)) {
              setEmailError("e-mail em uso");
            }
            dispatch(
              setToastState({
                variant: "error",
                shouldShow: true,
                message: {
                  title: "Erro",
                  description:
                    "Não foi possível criar o usuário, revise os campos e tente novamente",
                },
              })
            );
          });
      } else {
        // console.log(editableUser);
        editableUser &&
          apiService
            .editMember(
              editableUser.uuid,
              newMemberData,
              (cancelTokenSource.current as CancelTokenSource).token
            )
            .then((response) => {
              handleSetDataToLocalStorage("member", response.data);
              onSuccess(response);
            })
            .catch(() => {
              dispatch(
                setToastState({
                  variant: "error",
                  shouldShow: true,
                  message: {
                    title: "Erro",
                    description:
                      "Não foi possível atualizar o cadastro, revise os campos e tente novamente",
                  },
                })
              );
            });
      }
    }
  };

  const handlePopulateData = async () => {
    const localstorageMember = handleGetDataFromLocalStorage(
      "member"
    ) as TMembership;

    if (localstorageMember) {
      setEmail(localstorageMember.email ?? localstorageMember.user.email ?? "");

      setFillAccessProfile(
        decideMembershipRole(localstorageMember.role) as string
      );
      setAccessProfile(decideMembershipRole(localstorageMember.role) as string);
      setEditableUser(localstorageMember);
      isActiveUserRef.current = localstorageMember.is_active;

      const membershipOrganizationsResponse = (
        await apiService.getOrganizationsByMembership(
          localstorageMember.uuid,
          (cancelTokenSource.current as CancelTokenSource).token
        )
      ).data as TOrganization[];

      setTableSelectedOrganizations(membershipOrganizationsResponse);
      setSelectedOrganizations(membershipOrganizationsResponse);
    }

    const localAllowedOrganizations = handleGetDataFromLocalStorage(
      localStorageConstants.ALLOWED_ORGANIZATIONS
    ) as TOrganization[];

    if (localAllowedOrganizations) {
      setTableSelectedOrganizations(localAllowedOrganizations);
      setSelectedOrganizations(localAllowedOrganizations);
    }
  };

  const fetchOrganizations = async () => {
    const localSelectAccount = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ACCOUNT
    ) as TAccount;

    cancelTokenSource.current = axios.CancelToken.source();

    if (localSelectAccount) {
      setSelectAccount(localSelectAccount);
      const organizationsResponse = await apiService.getOrganizations(
        localSelectAccount.uuid,
        cancelTokenSource.current.token,
        undefined,
        searchPhraseRef.current
      );
      if (organizationsResponse) {
        dispatch(
          setOrganizationsState({
            organizationData: organizationsResponse.data as TOrganizationData,
          })
        );
      }

      await apiService
        .getAllOrganizations(
          localSelectAccount,
          cancelTokenSource.current.token
        )
        .then((response: TOrganization[]) => {
          allOrganizationsRef.current = response;
        });
    }
  };

  useEffect(() => {
    fetchOrganizations();
    if (variant === "edit") {
      handlePopulateData();
    }
    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  return (
    <Container>
      <BreadCrumbs
        names={[
          "Início",
          "Configurações",
          "Usuários",
          variant === "create" ? "Criar" : "Editar",
        ]}
        urls={[
          ROUTES.HOME,
          ROUTES.SETTINGS,
          ROUTES.SETTINGS_USERS,
          variant === "create"
            ? ROUTES.SETTINGS_USERS_CREATE
            : ROUTES.SETTINGS_USERS_EDIT,
        ]}
      />
      <h1>{variant === "create" ? "Criar usuário" : "Editar usuário"}</h1>
      <ContentContainer
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <fieldset>
          <h2>Dados do usuário</h2>
          <RowContainer>
            <LabeledTextInput
              placeholder="E-mail *"
              onError={{ hasError: emailError.length > 0, message: emailError }}
              onChange={(inputValue) => {
                setTimeout(() => {
                  setEmail(inputValue);
                }, 100);
              }}
              filled={
                editableUser
                  ? editableUser.email ?? editableUser.user.email
                  : undefined
              }
              disabled={variant === "edit"}
            />

            <Dropdown
              button={{
                variant: "labeled",
                labels: ["Perfil de acesso *"],
                placeholder: "Perfil de acesso *",
                showPlaceholder: true,
                filled:
                  fillAccessProfile.length > 0 ? fillAccessProfile : undefined,
                onError: {
                  hasError: accessProfileError.length > 0,
                  message: accessProfileError,
                },
              }}
              menu={{
                variant: "unlabeled",
                options: ["Administrador", "Colaborador", "Apenas leitura"],
                search: false,
                onSelect: handleAccessProfileOnSelect,
                forceSelectOptionsByIndex: [
                  ["Administrador", "Colaborador", "Apenas leitura"].indexOf(
                    fillAccessProfile
                  ),
                ],
                // customFilter: handleAccessProfileCustomFilter,
              }}
            />
            <Tooltip
              text={[
                {
                  style: "default",
                  text: "Tipos de perfis de acesso:",
                  shouldBreakLine: true,
                },
                {
                  style: "default",
                  text: "Administrador: Pode criar, editar e excluir usuários e Organizações.",
                  shouldBreakLine: true,
                },
                {
                  style: "default",
                  text: "",
                  shouldBreakLine: true,
                },
                {
                  style: "default",
                  text: "Colaborador: Pode criar outros usuários do tipo “colaborador” ou “Apenas leitura”, mas não pode editar ou excluir perfis de usuários e pode apenas visualizar as Organizações.",
                  shouldBreakLine: true,
                },
                {
                  style: "default",
                  text: "",
                  shouldBreakLine: true,
                },
                {
                  style: "default",
                  text: "Apenas leitura: Pode apenas visualizar as informações, porém não pode realizar ações.",
                  shouldBreakLine: true,
                },
              ]}
              size="large"
            >
              <UilInfoCircle />
            </Tooltip>
          </RowContainer>
          <RowContainer>
            <ToggleSwitch
              onClick={() => {
                isActiveUserRef.current = !isActiveUserRef.current;
              }}
              id="activeUser"
              key={
                `${
                  editableUser !== null &&
                  editableUser !== undefined &&
                  editableUser.is_active
                }` + "-activeUser"
              }
              initialState={
                editableUser !== null &&
                editableUser !== undefined &&
                editableUser.is_active
              }
            />
            <label htmlFor="activeUser">
              <p>Usuário ativo</p>
            </label>
          </RowContainer>
          <h2>Organizações</h2>
          <h4>Quais organizações o usuário terá acesso</h4>
          <RowContainer>
            <Button
              variant="outline"
              icon="noIcon"
              text="Selecionar organizações"
              onClick={handleOpenModal}
            />
            <div className="selected">
              <output>
                <h3 className="bold">{selectedOrganizations?.length}</h3>
              </output>
              <h3>
                &nbsp;
                {selectedOrganizations?.length === 1
                  ? "selecionada"
                  : "selecionadas"}
              </h3>
            </div>
          </RowContainer>
        </fieldset>
      </ContentContainer>
      <RowContainer className="lastRow">
        <InfoContainer>
          <UilInfoCircle />
          <p>
            <strong>Importante: </strong>O usuário precisa ter uma conta
            no&nbsp;
            <a
              href="https://id.nexaas.com/start_sign_up"
              target="_blank"
              rel="noopener noreferrer"
            >
              Nexaas ID
            </a>
            &nbsp;com o mesmo e-mail para acessar o Portal Fiscal
          </p>
        </InfoContainer>
        <ActionContainer>
          <Button
            variant="secondary"
            icon="noIcon"
            text="Cancelar"
            onClick={() => {
              navigate(ROUTES.SETTINGS_USERS);
            }}
          />
          <Button
            variant="primary"
            icon="checkmark"
            iconPosition="left"
            text={variant === "create" ? "Criar usuário" : "Editar usuário"}
            onClick={onSubmitHandler}
          />
        </ActionContainer>
      </RowContainer>
      <Modal
        key={shouldUpdateComponent + "-selectOrganizationsModal"}
        title="Selecionar Organizações"
        shouldOpen={shouldOpenModal}
        onClose={handleModalOnCancel}
        main={{
          style: "table",
          content: {
            disableBatchOperations: true,
            columns: {
              tableHead: {
                width: "0px 50px 300px 290px".split(" "),
              },
              tableBody: {
                width: "0px 50px 300px 290px".split(" "),
              },
            },
            search: {
              placeholder: "Buscar por Nome ou CNPJ",
              onSearch(searchPrase) {
                searchPhraseRef.current = searchPrase;
                fetchOrganizations();
              },
            },
            row: "singleRow",
            selectType: {
              type: "multiSelect",
              fullData: allOrganizationsRef.current,
              initialSelectedState: tableSelectedOrganizations,
            },
            tableHead: {
              value: [
                { style: "noIcon", value: "Nome" },
                { style: "noIcon", value: "CNPJ" },
              ],
            },
            tableBody: (organizationData?.results ?? []).map(
              (organization: TOrganization) => ({
                elementId: organization.uuid,
                file_nfe: null,
                file_xml: null,
                body: [
                  { style: "default", value: organization.company_name },
                  { style: "default", value: cnpjMask(organization.cnpj) },
                ],
              })
            ),
            onSelect: handleModalOnSelect,
            footer: {
              customPagination: organizationData
                ? {
                    currentPage: `${organizationData?.current_page}`,
                    buttonBackward: {
                      disabled: organizationData.current_page === 1,
                      onClick() {
                        if (organizationData.current_page === 1) {
                          pageRef.current = undefined;
                        } else {
                          pageRef.current = organizationData.current_page - 1;
                        }

                        if (
                          selectAccount &&
                          organizationData.current_page !== 1
                        ) {
                          apiService
                            .getOrganizations(
                              selectAccount.uuid,
                              (cancelTokenSource.current as CancelTokenSource)
                                .token,
                              pageRef.current,
                              searchPhraseRef.current
                            )
                            .then((response) => {
                              dispatch(
                                setOrganizationsState({
                                  organizationData:
                                    response.data as TOrganizationData,
                                })
                              );
                            });
                        }
                      },
                    },
                    buttonForward: {
                      disabled:
                        organizationData.current_page ===
                        organizationData.pages.length,
                      onClick() {
                        if (
                          organizationData.current_page ===
                          organizationData.pages.length
                        ) {
                          pageRef.current = organizationData.current_page;
                        } else {
                          pageRef.current = organizationData.current_page + 1;
                        }

                        if (
                          selectAccount &&
                          organizationData.current_page !==
                            organizationData.pages.length
                        ) {
                          apiService
                            .getOrganizations(
                              selectAccount.uuid,
                              (cancelTokenSource.current as CancelTokenSource)
                                .token,
                              pageRef.current,
                              searchPhraseRef.current
                            )
                            .then((response) => {
                              dispatch(
                                setOrganizationsState({
                                  organizationData:
                                    response.data as TOrganizationData,
                                })
                              );
                            });
                        }
                      },
                    },
                    pages: organizationData.pages.map((page) => {
                      return {
                        value: Object.keys(page)[0],
                        onClick() {
                          let temp =
                            Object.values(page)[0].match(/.*?page=(\d+)/);

                          if (temp !== null && temp[1]) {
                            pageRef.current = parseInt(temp[1]);
                          } else {
                            pageRef.current = undefined;
                          }

                          if (selectAccount) {
                            apiService
                              .getOrganizations(
                                selectAccount.uuid,
                                (cancelTokenSource.current as CancelTokenSource)
                                  .token,
                                pageRef.current
                              )
                              .then((response) => {
                                dispatch(
                                  setOrganizationsState({
                                    organizationData:
                                      response.data as TOrganizationData,
                                  })
                                );
                              });
                          }
                        },
                      };
                    }),
                  }
                : undefined,
            },
          },
        }}
        footer={{
          style: "default",
          text: [
            {
              style: "bold",
              shouldBreakLine: false,
              text: tableSelectedOrganizations?.length + " ",
            },
            {
              style: "default",
              shouldBreakLine: false,
              text:
                tableSelectedOrganizations.length !== 1
                  ? "Organizações selecionadas"
                  : "Organização selecionada",
            },
          ],
          buttons: [
            {
              variant: "secondary",
              icon: "noIcon",
              text: "Cancelar",
              onClick() {
                handleModalOnCancel();
              },
            },
            {
              variant: "primary",
              iconPosition: "left",
              icon: "checkmark",
              text: "Confirmar seleção",
              onClick() {
                handleModalOnSuccess();
              },
              disabled: !shouldAllowConfirmModalSelection.current,
            },
          ],
        }}
      />
    </Container>
  );
};

export default UsersForm;
