import styled from "styled-components";
import { REFERENCE_PAGE_WIDTH } from "../../../utils/constants";
import { ReactNode } from "react";

const Container = styled.button<{ $active: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px 8px 0 0;

  cursor: pointer;

  height: 39px;

  ${({ $active }) =>
    $active === "true"
      ? `border-bottom: ${
          3 / REFERENCE_PAGE_WIDTH
        }vw solid var(--color-main-rose);

        h1,
        h2,
        h3,
        h4,
        h5,
        p {
          font-family: var(--font-paragraph-bold);
          color: var(--color-main-rose);
        }
    
        .box { 
          background: var(--color-rose-100);
        }

    `
      : `
      h1,
      h2,
      h3,
      h4,
      h5,
      p {
        font-family: var(--font-paragraph-default);
        color: var(--color-text-labels);
      }
      
      .box {
        color: var(--color-dark-blue-400);
        background: var(--color-light-200);
      }

      `}

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    font-size: 16px;
  }

  .box {
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      font-family: var(--font-paragraph-bold);
    }

    margin-left: 8px;
    width: 27px;
    height: 24px;
  }

  // @media (min-width: 1900px) {
  //   height: 75px;

  //   .box {
  //     width: 35px;
  //     height: 30px;
  //     border-radius: 5px;
  //   }
  // }
`;

export type TTab = {
  children: ReactNode;
  onClick?: () => void;
  active: boolean;
};

const Tab: React.FC<TTab> = ({ children, active, onClick }: TTab) => {
  return (
    <Container role="button" onClick={onClick} $active={`${active}`}>
      {children}
    </Container>
  );
};

export default Tab;
