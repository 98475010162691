import styled from "styled-components";
import React, { useEffect, /* useRef */ } from "react";
// import { useTypedDispatch } from "../../../../hooks/useTypedDispatch";
// import {
//   TInvoice,
//   setToastState,
// } from "../../../../redux/reducers/globalState";
// import apiService from "../../../../services/api";
import Button, { TButtonIconVariants, TButtonProps } from "../../Button";
// import Dropdown from "../../Dropdown";

const Container = styled.form`
  & > p,
  label,
  b {
    font-size: 16px;
    color: var(--color-text-paragraph);
    font-family: var(--font-paragraph-default);
  }

  b {
    font-family: var(--font-paragraph-bold);
  }

  label {
    font-size: 14px;
  }

  table {
    thead tr:first-child {
      th b {
        font-size: 14px;
      }
    }

    thead tr:nth-child(2) {
      th b {
        font-size: 12px;
      }
    }
  }
`;

// const RowContainer = styled.div`
//   display: flex;

//   margin-top: 16px;

//   position: relative;

//   h2 {
//     font-family: var(--font-paragraph-bold);
//     font-size: 21px;
//     color: var(--color-text-paragraph);
//   }

//   &:nth-of-type(1) {
//     align-items: center;

//     .isPaidContainer {
//       display: flex;
//     }

//     & > *:not(:last-child) {
//       margin-right: 16px;
//     }

//     & > *:not(.isPaidContainer) > button,
//     & > .calendar > button {
//       width: 167px;
//     }

//     & > :last-child.calendar > button {
//       width: calc(100% + 20px);
//     }
//   }

//   &:nth-of-type(2) {
//     & > *:not(:last-child) {
//       margin-right: 16px;
//     }

//     & > div:first-child > button {
//       width: 390px;
//     }

//     & > div:not(:first-child) > button {
//       width: 292px;
//     }
//   }

//   &:nth-of-type(5) {
//     & > section {
//       width: 100%;
//       margin-top: 0px;
//     }
//   }
// `;

const Footer = styled.footer`
  // margin-top: 74px;
  margin-top: 24px;
  border-top: 1px solid var(--color-light-200);
  padding: 16px 0px;
  display: flex;
  justify-content: flex-end;

  .textContainer {
    b,
    p {
      font-size: 14px;
      color: var(--color-text-paragraph);
    }

    b {
      font-family: var(--font-paragraph-bold);
    }

    p {
      font-family: var(--font-paragraph-default);
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;

    & button:not(& button:last-child) {
      margin-right: 12px;
    }
  }
`;

export type TPayableObjectForm = {
  amount: string;
  category_id: number | null;
  classification_center_id: number | null;
  description: string;
  due_date: string;
  mode: null | "in_cash" | "recurrent" | "installments";
  supplier_id: number | null;

  paid_amount?: string;
  fine_amount?: string;
  interest_amount?: string;
  discount_amount?: string;
  payment_date?: string;
  period?:
    | "weekly"
    | "biweekly"
    | "monthly"
    | "bimonthly"
    | "quarterly"
    | "semiannual"
    | "annual";
  installments?: number;
  income_tax_relevant?: boolean;
};

type TInstallmentAccountProps = {
  // invoice: TInvoice;
  footer: { buttons: TButtonProps<TButtonIconVariants>[] };
};

const InstallmentAccount: React.FC<TInstallmentAccountProps> = ({
  // invoice,
  footer,
}) => {
  // const dispatch = useTypedDispatch();

  // const shouldSubmitRef = useRef(false);

  // const submitObjectRef = useRef<TPayableObjectForm>({
  //   amount: "",
  //   category_id: null,
  //   classification_center_id: null,
  //   description: "",
  //   due_date: "",
  //   mode: null,
  //   supplier_id: null,

  //   paid_amount: undefined,
  //   fine_amount: undefined,
  //   interest_amount: undefined,
  //   discount_amount: undefined,
  //   payment_date: undefined,
  //   period: undefined,
  //   installments: undefined,
  //   income_tax_relevant: undefined,
  // });

  // const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   if (submitObjectRef.current) {
  //     console.log(submitObjectRef.current);

  //     if (shouldSubmitRef.current) {
  //       apiService
  //         .createPayable(submitObjectRef.current)
  //         .then((_response) => {
  //           dispatch(
  //             setToastState({
  //               variant: "success",
  //               shouldShow: true,
  //               message: {
  //                 title: "Contas a pagar lançada",
  //                 description: "Clique aqui para acessar na Fintera",
  //                 link: { url: "https://www.google.com.br/" },
  //               },
  //             })
  //           );
  //         })
  //         .catch((_e) => {
  //           dispatch(
  //             setToastState({
  //               variant: "error",
  //               shouldShow: true,
  //               message: {
  //                 title: "Erro ao criar a entrada de mercadorias",
  //                 description:
  //                   "Não foi possível criar uma entrada de mercadorias a partir desta nota fiscal, tente novamente.",
  //               },
  //             })
  //           );
  //         });
  //     }
  //   }
  // };

  const preventSubmitDefaultBehavior = (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    // if (
    //   submitObjectRef.current &&
    //   submitObjectRef.current.amount === undefined
    // ) {
    //   submitObjectRef.current.amount = invoice.amount;
    // }
    // console.log(submitObjectRef.current);
  };

  const fetchData = async () => {
    //
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container onSubmit={preventSubmitDefaultBehavior}>
      <p>A nota será vinculada à todas as parcelas desta conta.</p>
      {/* <RowContainer>
        <Dropdown
          button={{
            variant: "unlabeled",
            labels: ["Apenas esta parcela"],
            placeholder: "",
          }}
          menu={{
            variant: "unlabeled",
            options: ["Apenas esta parcela", "TODAS as parcelas"],
            search: false,
            forceSelectOptionsByIndex: [0],
          }}
        />
      </RowContainer> */}
      <Footer>
        <div className="buttonsContainer">
          {footer.buttons?.map((buttonProps, index) => {
            const { onClick, ...props } = buttonProps;
            return (
              <Button
                key={index}
                role={index === 1 ? "submit" : undefined}
                {...props}
                onClick={onClick}
                // disabled={index === 1}
              />
            );
          })}
        </div>
      </Footer>
    </Container>
  );
};

export default InstallmentAccount;
