import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import tooltipArrow from "../../assets/images/tooltipArrow.svg";
import SVG from "../ui/SVG";
import { Property } from "csstype";
import { TText } from "./TableGrid";

const Container = styled.div<{ $justifyContent?: string }>`
  position: static;

  display: flex;
  justify-content: ${({ $justifyContent }) =>
    $justifyContent ? $justifyContent : "center"};
`;

const ContentContainer = styled.div<{
  $shouldShow: "show" | "hide";
  $size?: "default" | "large";
  $customArrowPosition?: "left";
  $customPosition?: {
    top: number;
    right?: number;
    left?: number;
    pMarginLeft?: number;
    pMarginRight?: number;
  };
}>`
  background: var(--color-dark-blue-700);

  width: max-content;

  position: absolute;
  z-index: ${({ $shouldShow }) => ($shouldShow === "show" ? "1" : "-1")};

  padding: 6px 12px;

  border-radius: 8px;

  display: block;

  ${({ $size, $customPosition }) =>
    $size === "large"
      ? `top: 140px;
         left: 705px;
         max-width: 437px;
         text-align: left;`
      : $customPosition
      ? `top: ${$customPosition.top}px;` +
        `right: ${$customPosition.right}px;` +
        `left: ${$customPosition.left}px;`
      : `top: -45px;
         max-width: 250px;
         text-align: center;`}

  opacity: ${({ $shouldShow }) => ($shouldShow === "show" ? "1" : "0")};

  p,
  b {
    color: var(--color-light-100);
    font-size: 14px;
    text-align: center;
  }
  p {
    font-family: var(--font-paragraph-default);
    ${({ $customPosition }) =>
      `margin-left: ${$customPosition?.pMarginLeft}px !important;` +
      `margin-right: ${$customPosition?.pMarginRight}px !important;`}
  }
  b {
    font-family: var(--font-paragraph-bold);
  }

  svg {
    width: 12px;
    height: 8px;
    position: absolute;
    ${({ $customArrowPosition }) =>
      $customArrowPosition === "left"
        ? `bottom: calc(50% - 6px);` +
          `left: -8px;` +
          `transform: rotate(90deg);`
        : `bottom: -6px;` + `left: calc(50% - 6px);`}
  }
`;

type TTooltipProps = {
  children: JSX.Element;
  text: TText[];
  size?: "large" | "default";
  customTopPosition?: number;
  customTextAlignment?: Property.TextAlign;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  arrowPosition?: "left";
  customPosition?: {
    top: number;
    right?: number;
    left?: number;
    pMarginLeft?: number;
    pMarginRight?: number;
  };
  justifyContent?: string;
  scrollabeRef?: React.MutableRefObject<HTMLTableSectionElement | null>;
};

const Tooltip: React.FC<TTooltipProps> = ({
  children,
  text,
  size,
  customTopPosition,
  onMouseEnter,
  onMouseLeave,
  arrowPosition,
  customPosition,
  justifyContent,
  customTextAlignment,
  // scrollabeRef,
}) => {
  const [shouldShow, setShouldShow] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleOnMouseEnter = () => {
    if (tooltipRef.current && containerRef.current) {
      const tooltipHeight = tooltipRef.current.getBoundingClientRect().height;

      tooltipRef.current.style.top =
        customTopPosition !== undefined
          ? customTopPosition + "px"
          : customPosition !== undefined
          ? customPosition.top + "px"
          : containerRef.current.getBoundingClientRect().top -
            (tooltipHeight < 60 ? 60 : 77) +
            "px";
    }

    // console.log("custom top position: ", customTopPosition);
    if (onMouseEnter) onMouseEnter();
    setShouldShow(true);
  };

  const handleOnMouseLeave = () => {
    if (onMouseLeave) onMouseLeave();
    setShouldShow(false);
  };

  const handleTextRender = (text: TText) => {
    switch (text.style) {
      case "bold":
        return (
          <>
            <b style={{ display: "inline", marginRight: text.marginRight }}>
              {text.text}
            </b>
            {text.shouldBreakLine && <br />}
          </>
        );
      case "default":
        return (
          <>
            <p style={{ display: "inline", marginRight: text.marginRight }}>
              {text.text}
            </p>
            {text.shouldBreakLine && <br />}
          </>
        );
      case "faded":
        return (
          <>
            <span style={{ marginRight: text.marginRight }}>{text.text}</span>
            {text.shouldBreakLine && <br />}
          </>
        );
    }
  };

  useEffect(() => {
    if (
      (!size || size !== "large") &&
      containerRef.current &&
      tooltipRef.current
    ) {
      const tooltipHeight = tooltipRef.current.getBoundingClientRect().height;

      tooltipRef.current.style.top =
        customTopPosition !== undefined
          ? customTopPosition + "px"
          : customPosition !== undefined
          ? customPosition.top + "px"
          : containerRef.current.getBoundingClientRect().top -
            (tooltipHeight < 60 ? 60 : 77) +
            "px";
    }
  }, []);

  // const handleScroll = (_e: any) => {
  //   if (tooltipRef.current && scrollabeRef?.current && customTopPosition) {
  //     tooltipRef.current.style.top =
  //       customTopPosition -
  //       (scrollabeRef?.current?.getBoundingClientRect().y -
  //         (scrollabeRef?.current?.firstChild as any).getBoundingClientRect()
  //           .y) +
  //       "px";
  //   }
  // };

  // useEffect(() => {
  //   if (scrollabeRef?.current) {
  //     scrollabeRef.current.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     if (scrollabeRef?.current) {
  //       scrollabeRef.current.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, [scrollabeRef?.current]);

  return (
    <Container ref={containerRef} $justifyContent={justifyContent}>
      <div
        style={{ width: "100%" }}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        {children}
      </div>
      <ContentContainer
        ref={tooltipRef}
        $shouldShow={shouldShow ? "show" : "hide"}
        $size={size}
        style={{ textAlign: customTextAlignment }}
        $customArrowPosition={arrowPosition}
        $customPosition={customPosition}
      >
        {text.map((obj, index) => (
          <React.Fragment key={index}>{handleTextRender(obj)}</React.Fragment>
        ))}
        {size !== "large" && <SVG src={tooltipArrow} />}
      </ContentContainer>
    </Container>
  );
};

export default Tooltip;
