import styled from "styled-components";
import Radio from "../../Inputs/Radio";
import LabeledTextInput from "../../Inputs/LabeledTextInput";
import Calendar from "../../Inputs/Calendar";
import Check from "../../Inputs/Check";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Dropdown from "../../Dropdown";
import SimpleSlider from "../../SimpleSlider";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useTypedDispatch } from "../../../../hooks/useTypedDispatch";
import {
  TInvoice,
  TInvoiceEntryStatus,
  TPaymentSlip,
  setAllPaidElements,
  setPaymentSlipsUpdatedAt,
  setToastState,
} from "../../../../redux/reducers/globalState";
import apiService from "../../../../services/api";
import Button, { TButtonIconVariants, TButtonProps } from "../../Button";
import { compareAsc } from "date-fns/compareAsc";
import { format } from "date-fns/format";
import {
  backendErrorMessageHandler,
  calculateInstallments,
  handleParseQuerystrings,
} from "../../../../utils/miscellaneous";
import AttachmentListItem from "../../AttachmentListItem";
import Modal from "..";
import { TPayableAccount } from "./LinkPayable";
import { parseISO, sub } from "date-fns";
import { fetchPaymentSlips } from "../../../../redux/actions";
import axios, { CancelTokenSource } from "axios";
import { add } from "date-fns/add";
// import { handleGetDataFromLocalStorage } from "../../../../utils/misc";
// import { localStorageConstants } from "../../../../utils/constants";
// import { TOrganization } from "../../../../redux/reducers/organizations";

const Container = styled.form`
  & > p,
  label,
  b {
    font-size: 16px;
    color: var(--color-text-paragraph);
    font-family: var(--font-paragraph-default);
  }

  b {
    font-family: var(--font-paragraph-bold);
  }

  label {
    font-size: 14px;
  }

  table {
    thead tr:first-child {
      th b {
        font-size: 14px;
      }
    }

    thead tr:nth-child(2) {
      th b {
        font-size: 12px;
      }
    }
  }
`;

const RowContainer = styled.div<{
  $hasModeError?: "hasError" | "noError";
  $isPaid?: boolean;
}>`
  display: flex;

  margin-top: 16px;

  position: relative;

  .radioContainer {
    display: flex;
    align-items: center;
  }

  &.short {
    margin-top: 8px;
  }

  &:first-of-type {
    width: 350px;

    justify-content: space-between;

    ${({ $hasModeError }) =>
      $hasModeError === "hasError" &&
      "border: 1px solid var(--color-aux-danger-700);"}

    p.modeError {
      position: absolute;
      bottom: -12px;
      visibility: visible;
      font-size: 10px;
      color: var(--color-aux-danger-700);

      visibility: ${({ $hasModeError }) =>
        $hasModeError === "hasError" ? "visible" : "hidden"};
    }
  }

  &:nth-of-type(2) {
    & > *:not(:last-child) {
      margin-right: 16px;
    }

    & > *:not(:last-child) > button,
    & > .calendar > button {
      width: 167px;
    }

    & > *:nth-child(3) {
      width: 300px;
    }

    & > *:last-child:not(.calendar) > button {
      width: 234px;
    }
  }

  &.description {
    ${({ $isPaid }) => $isPaid && `margin-top: 24px;`}

    & > div {
      width: 100%;

      & > button {
        width: 100%;
      }
    }
  }

  &.paidContainer {
    flex-wrap: wrap;

    border-bottom: 1px solid var(--color-light-200);
    padding-bottom: 24px;

    & > div:not(div.calendar):not(:last-child) {
      width: 160px;
      margin-right: 12px;

      & > button {
        width: 100%;

        // &::before {
        //   font-size: 14px;
        // }
      }
    }

    & > .calendar {
      width: 200px;
      margin-right: 12px;

      & > button {
        width: 100%;

        & > p {
          font-size: 14px;
        }
      }
    }

    & > div:last-child {
      width: 256px;
      margin-top: 8px;

      button p {
        font-size: 14px;
      }
    }
  }

  &:nth-last-of-type(4) {
    border-top: 1px solid var(--color-light-200);
    padding-top: 24px;
    margin-top: 24px;
    justify-content: space-between;

    div button {
      // height: 50px;
    }

    & div menu {
      max-height: 159px;
    }
  }

  &:last-of-type {
    & div h3 {
      font-family: var(--font-title-bold);
      font-size: 21px;
      color: var(--color-text-paragraph);
    }
  }

  &.attachments-row {
    margin-top: 20px;

    h3 {
      font-size: 21px;
      font-family: var(--font-title-bold);
      color: var(--color-text-paragraph);
    }
  }

  .attachments-container {
    margin-top: 16px;
  }

  .attachments-action-container {
    display: flex;

    margin-top: 20px;

    & button:nth-child(2) {
      margin-left: 12px;
    }
  }
`;

const Footer = styled.footer`
  margin-top: 30px;
  border-top: 1px solid var(--color-light-200);
  padding: 24px 0px 16px;
  display: flex;
  justify-content: flex-end;

  .textContainer {
    b,
    p {
      font-size: 14px;
      color: var(--color-text-paragraph);
    }

    b {
      font-family: var(--font-paragraph-bold);
    }

    p {
      font-family: var(--font-paragraph-default);
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;

    & button:not(& button:last-child) {
      margin-right: 12px;
    }
  }
`;

export type TPayableObjectForm = {
  amount: string;
  mode: null | "in_cash" | "recurrent" | "installments";
  supplier_id: number | null;
  boleto_id: string;

  due_date: string;

  category_id?: number;
  classification_center_id?: number;
  description?: string;
  observation?: string;

  paid_amount?: string;
  payment_date?: string;
  expected_deposit_account_id?: number | null;
  income_tax_relevant?: boolean;

  fine_amount?: string;
  interest_amount?: string;
  discount_amount?: string;
  period?:
    | "weekly"
    | "biweekly"
    | "monthly"
    | "bimonthly"
    | "quarterly"
    | "semiannual"
    | "annual";
  installments?: number;
  paid_installments?: TPaidInstallments[];
};

export type TPaidInstallments = {
  installment: number;
  nominal_amount: string;
  due_date: string;
  payment_date?: string;
  expected_deposit_account_id: number | null;
  paid_amount: string;
  fine_amount: string;
  interest_amount: string;
  discount_amount: string;
  errorMessage?: string;
};

type TAddPaymentSlipProps = {
  paymentSlip: TPaymentSlip;
  footer: { buttons: TButtonProps<TButtonIconVariants>[] };
};

const AddPaymentSlip: React.FC<TAddPaymentSlipProps> = ({
  paymentSlip,
  footer,
}) => {
  const { selectOrganization } = useTypedSelector(
    (state) => state.globalState.data
  );

  const attachedInvoicesRef = useRef<TInvoice[]>([]);

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const pageRef = useRef<number | undefined>();
  const searchRef = useRef<string | undefined>();

  const startDateRef = useRef<string | undefined>(
    format(sub(new Date(), { days: 30 }), "yyyy-MM-dd")
  );
  const endDateRef = useRef<string | undefined>(
    format(new Date(), "yyyy-MM-dd")
  );

  const entryStatusRef = useRef<TInvoiceEntryStatus | undefined>(10);

  const paymentDateErrorMessageRef = useRef("");
  const setPaidAmountRef = useRef<React.Dispatch<
    React.SetStateAction<string>
  > | null>(null);

  const setPaidAmountRefs = useRef<
    React.Dispatch<React.SetStateAction<string>>[]
  >([]);

  const inputElementRef = useRef<HTMLInputElement | null>(null);

  const payableModalPaymentSlipRef = useRef<TPaymentSlip | undefined>();

  const selectPayableRef = useRef<TPayableAccount>();

  const [
    shouldOpenInstallmentAccountModal,
    setShouldOpenInstallmentAccountModal,
  ] = useState(false);

  const [shouldOpenLinkPayableModal, setShouldOpenLinkPayableModal] =
    useState(false);
  const [isLinkPayableLoading, setIsLinkPayableLoading] = useState(false);

  const [activeRadio, setActiveRadio] = useState("full");
  const [isPaid, setIsPaid] = useState(false);
  const [paidInstalments, setPaidInstallments] = useState<number[]>([]);
  const [categories, setCategories] = useState<
    { id: number; full_name: string }[]
  >([]);
  const [classificationCenters, setClassificationCenters] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  // const [suppliers, setSuppliers] = useState<{ id: number; name: string }[]>(
  //   []
  // );

  // const [shouldComponentUpdate, setShouldComponentUpdate] = useState(false);

  const [attachmentFiles, setAttachmentFiles] = useState<
    { name: string; file: File; localUrl: string }[]
  >([]);

  const [supplier, setSupplier] = useState<
    | {
        id: number;
        name: string;
        cnpj: string;
      }
    | undefined
  >();

  const [depositAccounts, setDepositAccounts] = useState<
    { id: number; name: string }[]
  >([]);

  const [shouldShowModeError /* setShouldShowModeError */] = useState(false);
  const [shouldShowCategoryIdError /* setShouldShowCategoryIdError */] =
    useState(false);
  const [
    shouldShowClassificationCenterIdError,
    /* setShouldShowClassificationCenterIdError */
    ,
  ] = useState(false);
  const [shouldShowSupplierIdError /* setShouldShowSupplierIdError */] =
    useState(false);
  const [shouldShowDescriptionError /* setShouldShowDescriptionError */] =
    useState(false);
  const [shouldShowDueDateError, setShouldShowDueDateError] = useState(false);
  const [shouldShowPaymentDateError, setShouldShowPaymentDateError] =
    useState(false);
  const [shouldShowPeriodError, setShouldShowPeriodError] = useState(false);
  const [shouldShowInstallmentsError, setShouldShowInstallmentsError] =
    useState(false);

  const [installmentsErrorMessage, setInstallmentsErrorMessage] = useState<
    "Campo obrigatório" | "O campo deve ser maior que 1"
  >("Campo obrigatório");

  const [
    shouldShowInstallmentsAmountError,
    setShouldShowInstallmentsAmountError,
  ] = useState<boolean[]>([]);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const allPaidElements = useTypedSelector(
    (state) => state.globalState.data.allPaidElements
  );

  const navigateToSlideByIndexRef = useRef<
    React.Dispatch<React.SetStateAction<number>> | undefined
  >();

  // const paidInstallmentsRef = useRef<TPA>(0);

  const dispatch = useTypedDispatch();

  const [installments, setInstallments] = useState(1);

  const shouldSubmitRef = useRef(false);

  const submitObjectInitialState = {
    amount: "",
    due_date: "",
    mode: "in_cash" as const,
    boleto_id: "",
    supplier_id: null,

    category_id: undefined,
    classification_center_id: undefined,
    description: undefined,
    observation: undefined,
    deposit_account_id: undefined,
    paid_amount: undefined,
    fine_amount: undefined,
    interest_amount: undefined,
    discount_amount: undefined,
    payment_date: undefined,
    period: undefined,
    installments: undefined,
    income_tax_relevant: undefined,
    paid_installments: undefined,
  };

  const submitObjectRef = useRef<TPayableObjectForm>(submitObjectInitialState);

  const fetchPaymentSlipsByAllEntryStatus = () => {
    if (selectOrganization && cancelTokenSource.current) {
      let entryStatus1, entryStatus2;

      switch (entryStatusRef.current) {
        case 10:
          entryStatus1 = 20;
          entryStatus2 = 30;
          break;
        case 20:
          entryStatus1 = 10;
          entryStatus2 = 30;
          break;
        case 30:
          entryStatus1 = 20;
          entryStatus2 = 10;
          break;
      }

      dispatch(
        fetchPaymentSlips({
          // page: pageRef.current,
          organizationUuid: selectOrganization.uuid,
          dateIssueStart: startDateRef.current,
          dateIssueEnd: endDateRef.current,
          launch_status: entryStatus1 as TInvoiceEntryStatus,
          // has_accounts_payable: payablesRef.current,
          // has_goods_receipt: goodsEntryRef.current,
          search: searchRef.current,
          cancelToken: cancelTokenSource.current.token,
        })
      );
      dispatch(
        fetchPaymentSlips({
          // page: pageRef.current,
          organizationUuid: selectOrganization.uuid,
          dateIssueStart: startDateRef.current,
          dateIssueEnd: endDateRef.current,
          launch_status: entryStatus2 as TInvoiceEntryStatus,
          search: searchRef.current,
          cancelToken: cancelTokenSource.current.token,
        })
      );
      setTimeout(() => {
        if (cancelTokenSource.current)
          dispatch(
            fetchPaymentSlips({
              page: pageRef.current,
              organizationUuid: selectOrganization.uuid,
              dateIssueStart: startDateRef.current,
              dateIssueEnd: endDateRef.current,
              launch_status: entryStatusRef.current,
              search: searchRef.current,
              cancelToken: cancelTokenSource.current.token,
            })
          ).then(() => {
            apiService
              .getPaymentSlipUpdatedAt(
                selectOrganization.uuid,
                (cancelTokenSource.current as CancelTokenSource).token
              )
              .then((response) => {
                if (response?.data)
                  dispatch(
                    setPaymentSlipsUpdatedAt({
                      responseStatus: response.status,
                      date: response.data.updated_at,
                    })
                  );
              });
          });
      }, 50);
    }
  };

  const handleWhichApiCallByMode = (): Promise<any> => {
    try {
      switch (submitObjectRef.current.mode) {
        case "in_cash":
          return apiService.createInCashPayable(
            submitObjectRef.current,
            (cancelTokenSource.current as CancelTokenSource).token
          );
        case "recurrent":
          return apiService.createRecurringPayable(
            submitObjectRef.current,
            (cancelTokenSource.current as CancelTokenSource).token
          );
        case "installments":
          const temp = { ...submitObjectRef.current } as any;
          temp.installments = submitObjectRef.current.paid_installments
            ? [
                ...submitObjectRef.current.paid_installments.map(
                  (installment) => ({
                    installment: installment.installment,
                    due_date: installment.due_date,
                    amount: installment.nominal_amount,

                    payment_date:
                      installment.paid_amount.length > 0
                        ? installment.payment_date
                        : undefined,
                    paid_amount:
                      installment.paid_amount.length > 0
                        ? installment.paid_amount
                        : "0",
                    fine_amount:
                      installment.fine_amount.length > 0
                        ? installment.fine_amount
                        : "0",
                    interest_amount:
                      installment.interest_amount.length > 0
                        ? installment.interest_amount
                        : "0",
                    discount_amount:
                      installment.discount_amount.length > 0
                        ? installment.discount_amount
                        : "0",
                    expected_deposit_id:
                      installment.expected_deposit_account_id,
                  })
                ),
              ]
            : [];
          temp.paid_installments = undefined;
          // console.log("temp: ", temp);
          return apiService.createInstallmentsPayable(
            temp,
            (cancelTokenSource.current as CancelTokenSource).token
          );
        default:
          throw new Error("invalid mode");
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (submitObjectRef.current) {
      if (submitObjectRef.current.amount?.length === 0) {
        submitObjectRef.current.amount = paymentSlip.amount;
      }

      if (submitObjectRef.current.boleto_id.length === 0) {
        submitObjectRef.current.boleto_id = paymentSlip.uuid;
      }

      // if (submitObjectRef.current.category_id === null) {
      //   setShouldShowCategoryIdError(true);
      //   // shouldSubmitRef.current = false;
      // } else if (shouldShowCategoryIdError) {
      //   // shouldSubmitRef.current = true;
      //   setShouldShowCategoryIdError(false);
      // } else {
      //   // shouldSubmitRef.current = true;
      // }

      // if (submitObjectRef.current.classification_center_id === null) {
      //   setShouldShowClassificationCenterIdError(true);
      //   // shouldSubmitRef.current = false;
      // } else if (shouldShowClassificationCenterIdError) {
      //   setShouldShowClassificationCenterIdError(false);
      //   // shouldSubmitRef.current = true;
      // } else {
      //   // shouldSubmitRef.current = true;
      // }

      // if (submitObjectRef.current.supplier_id === null) {
      //   setShouldShowSupplierIdError(true);
      //   // shouldSubmitRef.current = false;
      // } else if (shouldShowSupplierIdError) {
      //   setShouldShowSupplierIdError(false);
      //   // shouldSubmitRef.current = true;
      // } else {
      //   // shouldSubmitRef.current = true;
      // }

      // if (submitObjectRef.current.description.length === 0) {
      //   setShouldShowDescriptionError(true);
      //   // shouldSubmitRef.current = false;
      // } else if (shouldShowDescriptionError) {
      //   setShouldShowDescriptionError(false);
      //   // shouldSubmitRef.current = true;
      // } else {
      //   // shouldSubmitRef.current = true;
      // }

      if (
        submitObjectRef.current.due_date.length === 0 &&
        submitObjectRef.current.mode !== "installments"
      ) {
        setShouldShowDueDateError(true);
        // console.log("setting should submit to false 1");
        shouldSubmitRef.current = false;
        return;
      } else if (shouldShowDueDateError) {
        setShouldShowDueDateError(false);
        // console.log("setting should submit to true 1");
        shouldSubmitRef.current = true;
      } else {
        // console.log("setting should submit to true 2");
        shouldSubmitRef.current = true;
      }

      // if (submitObjectRef.current.mode === null) {
      //   setShouldShowModeError(true);
      //   // shouldSubmitRef.current = false;
      // } else if (shouldShowModeError) {
      //   setShouldShowModeError(false);
      //   // shouldSubmitRef.current = true;
      // } else {
      //   // shouldSubmitRef.current = true;
      // }

      if (
        ["recurring"].includes(submitObjectRef.current.mode ?? "") &&
        submitObjectRef.current.period === undefined
      ) {
        setShouldShowPeriodError(true);
        // console.log("setting should submit to false 2");
        shouldSubmitRef.current = false;
        return;
      } else if (shouldShowPeriodError) {
        setShouldShowPeriodError(false);
        // console.log("setting should submit to true 3");
        shouldSubmitRef.current = true;
      } else {
        // console.log("setting should submit to true 4");
        shouldSubmitRef.current = true;
      }

      if (
        submitObjectRef.current.mode === "installments" &&
        (isNaN(submitObjectRef.current.installments as number) ||
          Boolean(
            submitObjectRef.current.installments !== undefined &&
              submitObjectRef.current.installments < 2
          ))
      ) {
        if (isNaN(submitObjectRef.current.installments as number)) {
          setInstallmentsErrorMessage("Campo obrigatório");
        }
        if (
          Boolean(
            submitObjectRef.current.installments !== undefined &&
              submitObjectRef.current.installments < 2
          )
        ) {
          submitObjectRef.current.installments = 2;
          setInstallmentsErrorMessage("O campo deve ser maior que 1");
        }

        setShouldShowInstallmentsError(true);
        shouldSubmitRef.current = false;
        return;
        // console.log("setting should submit to false 3");
        return;
      } else if (shouldShowInstallmentsError) {
        setShouldShowInstallmentsError(false);
        // console.log("setting should submit to true 5");
        shouldSubmitRef.current = true;
      } else {
        // console.log("setting should submit to true 6");
        shouldSubmitRef.current = true;
      }

      if (
        submitObjectRef.current.mode === "installments" &&
        submitObjectRef.current.paid_installments &&
        submitObjectRef.current.paid_installments.some(
          (installment) => installment.nominal_amount.length === 0
        )
      ) {
        if (navigateToSlideByIndexRef.current) {
          const emptyInstallmentIndex =
            submitObjectRef.current.paid_installments.findIndex(
              (installment) => installment.nominal_amount.length === 0
            );
          if (emptyInstallmentIndex >= 0) {
            navigateToSlideByIndexRef.current(emptyInstallmentIndex + 1);
          }
        }
        setShouldShowInstallmentsAmountError(
          submitObjectRef.current.paid_installments.map(
            (installment) => installment.nominal_amount.length === 0
          )
        );
        shouldSubmitRef.current = false;
        return;
        // console.log("setting should submit to false 3");
        return;
      } else if (shouldShowInstallmentsAmountError.includes(true)) {
        setShouldShowInstallmentsAmountError([]);
        // console.log("setting should submit to true 5");
        shouldSubmitRef.current = true;
      } else {
        // console.log("setting should submit to true 6");
        shouldSubmitRef.current = true;
      }

      if (isPaid) {
        if (!submitObjectRef.current.payment_date) {
          paymentDateErrorMessageRef.current = "Campo obrigatório";
          setShouldShowPaymentDateError(true);

          // console.log("setting should submit to false 4");
          shouldSubmitRef.current = false;
          return;
        } else if (
          compareAsc(submitObjectRef.current.payment_date, new Date()) === 1
        ) {
          paymentDateErrorMessageRef.current = "Esta data não pode ser futura";
          setShouldShowPaymentDateError(true);
          // console.log("setting should submit to false 5");
          shouldSubmitRef.current = false;
          return;
        } else if (shouldShowPaymentDateError) {
          setShouldShowPaymentDateError(false);
          // console.log("setting should submit to true 7");
          shouldSubmitRef.current = true;
        } else {
          // console.log("setting should submit to true 8");
          shouldSubmitRef.current = true;
        }
      }

      // if (
      //   !(
      //     submitObjectRef.current.category_id === null ||
      //     submitObjectRef.current.classification_center_id === null ||
      //     submitObjectRef.current.supplier_id === null ||
      //     submitObjectRef.current.description.length === 0 ||
      //     submitObjectRef.current.due_date.length === 0 ||
      //     submitObjectRef.current.mode === null ||
      //     (["recurring", "installments"].includes(activeRadio) &&
      //       submitObjectRef.current.period === undefined) ||
      //     (activeRadio === "installments" &&
      //       submitObjectRef.current.installments === undefined) ||
      //     (isPaid &&
      //       submitObjectRef.current.payment_date &&
      //       submitObjectRef.current.payment_date.length === 0)
      //   )
      // ) {
      //   shouldSubmitRef.current = true;
      // } else {
      //   shouldSubmitRef.current = false;
      // }

      if (!submitObjectRef.current.discount_amount) {
        // submitObjectRef.current.discount_amount = "0";
      }
      if (!submitObjectRef.current.fine_amount) {
        // submitObjectRef.current.fine_amount = "0";
      }
      if (!submitObjectRef.current.interest_amount) {
        // submitObjectRef.current.interest_amount = "0";
      }

      // if (/* isPaid && */ activeRadio === "installments") {
      //   submitObjectRef.current.paid_amount = undefined;
      // }

      if (shouldSubmitRef.current) {
        setIsButtonLoading(true);
        handleWhichApiCallByMode()
          .then((_response) => {
            apiService
              .getPaymentSlip(
                submitObjectRef.current.boleto_id,
                (cancelTokenSource.current as CancelTokenSource).token
              )
              .then((response) => {
                if (footer.buttons[1].onClick) {
                  footer.buttons[1].onClick();
                }
                setIsButtonLoading(false);
                if (response?.data)
                  dispatch(
                    setToastState({
                      variant: "success",
                      shouldShow: true,
                      message: {
                        title: "Contas a pagar lançada",
                        description: "Clique aqui para acessar na Fintera",
                        link: { url: response.data.accounts_payable_url },
                      },
                    })
                  );
              });
          })
          .catch((e) => {
            const errorMessage = backendErrorMessageHandler(e);

            setIsButtonLoading(false);

            dispatch(
              setToastState({
                variant: "error",
                shouldShow: true,
                message: {
                  title: "Erro ao lançar nota",
                  description:
                    typeof errorMessage === "string"
                      ? errorMessage
                      : "Ocorreu um erro ao lançar a nota como Contas a Pagar, tente novamente",
                },
              })
            );
          });
      }
    }
  };

  const onCancelHandler = () => {
    if (footer.buttons[0].onClick) {
      footer.buttons[0].onClick();
    }
  };

  const preventSubmitDefaultBehavior = (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    if (
      submitObjectRef.current &&
      submitObjectRef.current.amount === undefined
    ) {
      submitObjectRef.current.amount = paymentSlip.amount;
    }
    // console.log(submitObjectRef.current);
  };

  const simpleSliderChildren = Array.from(
    { length: installments },
    (_, index) =>
      React.cloneElement(
        <React.Fragment key={index}>
          <RowContainer
            style={{ borderBottom: 0, marginBottom: 0, paddingBottom: 0 }}
            key={`slide1-${index}`}
            className="paidContainer"
          >
            <LabeledTextInput
              placeholder="Valor da parcela"
              hasMandatoryStyle={{
                onFocus: { marginLeft: "82px" },
                default: { marginLeft: "120px" },
              }}
              customFilter={(inputRef, setInputString) => {
                const value = inputRef.value;
                inputRef.value = value
                  .replace(/\D/g, "")
                  .slice(0, 10)
                  .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
                setInputString(
                  value
                    .replace(/\D/g, "")
                    .slice(0, 10)
                    .replace(/^(\d+)(\d{2})$/, "R$ $1,$2")
                );
              }}
              onChange={(inputValue) => {
                if (submitObjectRef.current.paid_installments) {
                  const temp = [...submitObjectRef.current.paid_installments];
                  temp[index] = {
                    ...submitObjectRef.current.paid_installments[index],
                    nominal_amount: inputValue
                      .replace(/\D/g, "")
                      .replace(/(\d+)(\d{2})/g, "$1.$2"),
                  };
                  submitObjectRef.current.paid_installments = temp;
                  if (paidInstalments.includes(index)) {
                    setTimeout(() => {
                      handlePaidInstallmentsPaidAmountUpdate(index);
                    }, 300);
                  }
                }
              }}
              setData={(setInputString) => {
                if (submitObjectRef.current.paid_installments) {
                  setInputString(
                    submitObjectRef.current.paid_installments[
                      index
                    ].nominal_amount?.replace(/(\d+).(\d+)/g, "R$ $1,$2") ?? ""
                  );
                }
              }}
              onError={{
                hasError: shouldShowInstallmentsAmountError[index] ?? false,
                message: "Campo obrigatório",
              }}
            />
            <Calendar
              setDate={(setButtonSelectedDate, setDatePickerSelectedDates) => {
                const value = attachedInvoicesRef.current[index]
                  ? format(
                      parseISO(attachedInvoicesRef.current[index].date_issue),
                      "dd/MM/yyyy"
                    )
                  : submitObjectRef.current.paid_installments &&
                    submitObjectRef.current.paid_installments[index]
                  ? format(
                      parseISO(
                        submitObjectRef.current.paid_installments[index]
                          .due_date
                      ),
                      "dd/MM/yyyy"
                    )
                  : format(new Date(), "dd/MM/yyyy");

                setButtonSelectedDate(value);
                setDatePickerSelectedDates({
                  startDate: value,
                });
              }}
              className="calendar"
              placeholder="Vencimento"
              onSelect={(selectDate) => {
                // dueDate
                if (submitObjectRef.current.paid_installments) {
                  const tempPaidInstallments = [
                    ...submitObjectRef.current.paid_installments,
                  ];
                  const newPaidInstallment = {
                    ...tempPaidInstallments[index],
                    due_date: selectDate.replace(
                      /(\d{2})\/(\d{2})\/(\d{4})/g,
                      "$3-$2-$1"
                    ),
                  } as TPaidInstallments;
                  tempPaidInstallments[index] = newPaidInstallment;

                  submitObjectRef.current.paid_installments =
                    tempPaidInstallments;

                  dispatch(
                    setAllPaidElements({
                      allPaidElements:
                        submitObjectRef.current.paid_installments,
                    })
                  );
                }
              }}
              // onError={{
              //   hasError:
              //     allPaidElements[index] &&
              //     allPaidElements[index].errorMessage !== undefined
              //       ? (allPaidElements[index].errorMessage as string).length > 0
              //       : false,
              //   message:
              //     (allPaidElements[index] &&
              //       allPaidElements[index].errorMessage) ??
              //     "",
              // }}
            />
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <Check
                id="paid"
                initialState={paidInstalments.includes(index)}
                onClick={() => {
                  setPaidInstallments((before) => {
                    if (!before.includes(index)) {
                      if (
                        submitObjectRef.current.paid_installments &&
                        !submitObjectRef.current.paid_installments[index]
                          .payment_date
                      ) {
                        const tempPaidInstallments = [
                          ...submitObjectRef.current.paid_installments,
                        ];
                        const newPaidInstallment = {
                          ...tempPaidInstallments[index],
                          payment_date: format(new Date(), "yyyy-MM-dd"),
                        } as TPaidInstallments;
                        tempPaidInstallments[index] = newPaidInstallment;

                        submitObjectRef.current.paid_installments =
                          tempPaidInstallments;
                      }

                      if (
                        submitObjectRef.current.paid_installments &&
                        submitObjectRef.current.paid_installments[index]
                          .nominal_amount.length > 0
                      ) {
                        setTimeout(() => {
                          handlePaidInstallmentsPaidAmountUpdate(index);
                        }, 300);
                      }
                      return [...before, index];
                    } else {
                      if (submitObjectRef.current.paid_installments) {
                        const temp = {
                          ...submitObjectRef.current.paid_installments[index],
                          discount_amount: "0",
                          fine_amount: "0",
                          interest_amount: "0",
                          expected_deposit_account_id: null,
                          paid_amount: "",
                          payment_date: undefined,
                        };

                        const installments = [
                          ...submitObjectRef.current.paid_installments,
                        ];
                        installments[index] = temp;
                        submitObjectRef.current.paid_installments =
                          installments;
                      }
                      return before.filter(
                        (installment) => installment !== index
                      );
                    }
                  });
                }}
              />
              &nbsp;<label htmlFor="paid">Já paga</label>
            </div>
          </RowContainer>
          <RowContainer>
            <Dropdown
              button={{
                variant: "labeled",
                labels: [
                  depositAccounts.find(
                    (depositAccount) =>
                      submitObjectRef.current.paid_installments &&
                      submitObjectRef.current.paid_installments[index]
                        ?.expected_deposit_account_id === depositAccount.id
                  )?.name ?? "",
                ],
                placeholder: "Pagar via",
              }}
              menu={{
                options: depositAccounts.map(
                  (depositAccount) => depositAccount.name
                ),
                variant: "unlabeled",
                search: false,
                onSelect(selected) {
                  const result = depositAccounts.find(
                    (depositAccount) => depositAccount.name === selected
                  );
                  if (result && submitObjectRef.current.paid_installments) {
                    const tempPaidInstallments = [
                      ...submitObjectRef.current.paid_installments,
                    ];
                    const newPaidInstallment = {
                      ...tempPaidInstallments[index],
                      expected_deposit_account_id: result.id,
                    } as TPaidInstallments;
                    tempPaidInstallments[index] = newPaidInstallment;

                    submitObjectRef.current.paid_installments =
                      tempPaidInstallments;
                  }
                },
              }}
            />
          </RowContainer>
          {paidInstalments.includes(index) && (
            <RowContainer key={`slide2-${index}`} className="paidContainer">
              <LabeledTextInput
                placeholder="Multa"
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value
                    .replace(/\D/g, "")
                    .slice(0, 10)
                    .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
                  inputRef.value = value;
                  setInputString(value);
                }}
                onChange={(inputValue) => {
                  if (submitObjectRef.current.paid_installments) {
                    const tempPaidInstallments = [
                      ...submitObjectRef.current.paid_installments,
                    ];
                    const newPaidInstallment = {
                      ...tempPaidInstallments[index],
                      fine_amount: inputValue
                        .replace(/\D/g, "")
                        .replace(/(\d+)(\d{2})/g, "$1.$2"),
                    } as TPaidInstallments;
                    tempPaidInstallments[index] = newPaidInstallment;

                    submitObjectRef.current.paid_installments =
                      tempPaidInstallments;
                  }
                  setTimeout(() => {
                    handlePaidInstallmentsPaidAmountUpdate(index);
                  }, 500);
                }}
                setData={(setInputString) => {
                  if (submitObjectRef.current.paid_installments) {
                    setInputString(
                      submitObjectRef.current.paid_installments[
                        index
                      ].fine_amount?.replace(/(\d+).(\d+)/g, "R$ $1,$2") ?? ""
                    );
                  }
                }}
              />
              <LabeledTextInput
                placeholder="Juros"
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value
                    .replace(/\D/g, "")
                    .slice(0, 10)
                    .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
                  inputRef.value = value;
                  setInputString(value);
                }}
                onChange={(inputValue) => {
                  if (submitObjectRef.current.paid_installments) {
                    const tempPaidInstallments = [
                      ...submitObjectRef.current.paid_installments,
                    ];
                    const newPaidInstallment = {
                      ...tempPaidInstallments[index],
                      interest_amount: inputValue
                        .replace(/\D/g, "")
                        .replace(/(\d+)(\d{2})/g, "$1.$2"),
                    } as TPaidInstallments;
                    tempPaidInstallments[index] = newPaidInstallment;

                    submitObjectRef.current.paid_installments =
                      tempPaidInstallments;
                  }
                  setTimeout(() => {
                    handlePaidInstallmentsPaidAmountUpdate(index);
                  }, 500);
                }}
                setData={(setInputString) => {
                  if (submitObjectRef.current.paid_installments) {
                    setInputString(
                      submitObjectRef.current.paid_installments[
                        index
                      ].interest_amount?.replace(/(\d+).(\d+)/g, "R$ $1,$2") ??
                        ""
                    );
                  }
                }}
              />
              <LabeledTextInput
                placeholder="Desconto"
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value
                    .replace(/\D/g, "")
                    .slice(0, 10)
                    .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
                  inputRef.value = value;
                  setInputString(value);
                }}
                onChange={(inputValue) => {
                  if (submitObjectRef.current.paid_installments) {
                    const tempPaidInstallments = [
                      ...submitObjectRef.current.paid_installments,
                    ];
                    const newPaidInstallment = {
                      ...tempPaidInstallments[index],
                      discount_amount: inputValue
                        .replace(/\D/g, "")
                        .replace(/(\d+)(\d{2})/g, "$1.$2"),
                    } as TPaidInstallments;
                    tempPaidInstallments[index] = newPaidInstallment;

                    submitObjectRef.current.paid_installments =
                      tempPaidInstallments;
                  }

                  setTimeout(() => {
                    handlePaidInstallmentsPaidAmountUpdate(index);
                  }, 500);
                }}
                setData={(setInputString) => {
                  if (submitObjectRef.current.paid_installments) {
                    setInputString(
                      submitObjectRef.current.paid_installments[
                        index
                      ].discount_amount?.replace(/(\d+).(\d+)/g, "R$ $1,$2") ??
                        ""
                    );
                  }
                }}
              />
              <Calendar
                setDate={(
                  setButtonSelectedDate,
                  setDatePickerSelectedDates
                ) => {
                  const value =
                    (submitObjectRef.current.paid_installments &&
                      submitObjectRef.current.paid_installments[
                        index
                      ].payment_date?.replace(
                        /(\d{4})-(\d{2})-(\d{2})/g,
                        "$3/$2/$1"
                      )) ??
                    format(new Date(), "dd/MM/yyyy");
                  setButtonSelectedDate(value);
                  setDatePickerSelectedDates({
                    startDate: value,
                  });
                }}
                className="calendar"
                placeholder="Data do pagamento"
                hasMandatoryStyle={{ marginLeft: "130px" }}
                onSelect={(selectDate) => {
                  // paymentDate
                  if (submitObjectRef.current.paid_installments) {
                    const tempPaidInstallments = [
                      ...submitObjectRef.current.paid_installments,
                    ];
                    const newPaidInstallment = {
                      ...tempPaidInstallments[index],
                      payment_date: selectDate.replace(
                        /(\d{2})\/(\d{2})\/(\d{4})/g,
                        "$3-$2-$1"
                      ),
                    } as TPaidInstallments;
                    tempPaidInstallments[index] = newPaidInstallment;

                    submitObjectRef.current.paid_installments =
                      tempPaidInstallments;

                    if (
                      compareAsc(
                        selectDate.replace(
                          /(\d{2})\/(\d{2})\/(\d{4})/g,
                          "$3-$2-$1"
                        ),
                        new Date()
                      ) === 1
                    ) {
                      const temp = [
                        ...submitObjectRef.current.paid_installments,
                      ];
                      temp[index].errorMessage =
                        "Esta data não pode ser futura";
                      // console.log("setting should submit to false 6");
                      shouldSubmitRef.current = false;

                      dispatch(setAllPaidElements({ allPaidElements: temp }));
                    } else if (
                      allPaidElements[index] &&
                      allPaidElements[index].errorMessage &&
                      (allPaidElements[index].errorMessage as string).length > 0
                    ) {
                      const temp = [
                        ...submitObjectRef.current.paid_installments,
                      ];
                      temp[index].errorMessage = undefined;
                      // console.log("setting should submit to true 9");
                      shouldSubmitRef.current = true;

                      dispatch(setAllPaidElements({ allPaidElements: temp }));
                    } else {
                      // console.log("setting should submit to true 10");
                      shouldSubmitRef.current = true;
                    }
                  }
                }}
                onError={{
                  hasError:
                    allPaidElements[index] &&
                    allPaidElements[index].errorMessage !== undefined
                      ? (allPaidElements[index].errorMessage as string).length >
                        0
                      : false,
                  message:
                    (allPaidElements[index] &&
                      allPaidElements[index].errorMessage) ??
                    "",
                }}
              />
              <LabeledTextInput
                placeholder="Total pago"
                disabled
                setData={(setInputString) => {
                  setPaidAmountRefs.current[index] = setInputString;

                  if (
                    submitObjectRef.current.paid_installments &&
                    submitObjectRef.current.paid_installments[index].paid_amount
                  )
                    setInputString(
                      parseFloat(
                        submitObjectRef.current.paid_installments[index]
                          .paid_amount
                      )
                        .toFixed(2)
                        .replace(/(\d+).(\d+)/g, "R$ $1,$2") ?? ""
                    );
                }}
              />
              <div style={{ display: "none" }}>
                <Dropdown
                  button={{
                    variant: "labeled",
                    labels: [
                      depositAccounts.find(
                        (depositAccount) =>
                          submitObjectRef.current.paid_installments &&
                          submitObjectRef.current.paid_installments[index]
                            ?.expected_deposit_account_id === depositAccount.id
                      )?.name ?? "",
                    ],
                    placeholder: "Pagar via",
                  }}
                  menu={{
                    options: depositAccounts.map(
                      (depositAccount) => depositAccount.name
                    ),
                    variant: "unlabeled",
                    search: false,
                    onSelect(selected) {
                      const result = depositAccounts.find(
                        (depositAccount) => depositAccount.name === selected
                      );
                      if (result && submitObjectRef.current.paid_installments) {
                        const tempPaidInstallments = [
                          ...submitObjectRef.current.paid_installments,
                        ];
                        const newPaidInstallment = {
                          ...tempPaidInstallments[index],
                          expected_deposit_account_id: result.id,
                        } as TPaidInstallments;
                        tempPaidInstallments[index] = newPaidInstallment;

                        submitObjectRef.current.paid_installments =
                          tempPaidInstallments;
                      }
                    },
                  }}
                />
              </div>
              {/* <Check
            id="paid"
            onClick={() => {
              setIsPaid((before) => !before);
              setTimeout(() => {
                if (submitObjectRef.current.mode === "installments") {
                  handleInitializeSetPaidInstallmentRefs(`${installments}`);
                }
              }, 50);
            }}
          />
          &nbsp;<label htmlFor="paid">Já paga</label> */}
            </RowContainer>
          )}
        </React.Fragment>
      )
  );

  const handlePaidAmountUpdate = useCallback(() => {
    // deve ser igual ao valor base mais juros e multa, menos o desconto
    let discountAmount = 0,
      fineAmount = 0,
      interestAmount = 0,
      baseline = parseFloat(paymentSlip.amount);

    if (submitObjectRef.current.discount_amount) {
      discountAmount = parseFloat(submitObjectRef.current.discount_amount);
    }
    if (submitObjectRef.current.fine_amount) {
      fineAmount = parseFloat(submitObjectRef.current.fine_amount);
    }
    if (submitObjectRef.current.interest_amount) {
      interestAmount = parseFloat(submitObjectRef.current.interest_amount);
    }
    if (
      submitObjectRef.current.paid_amount &&
      parseFloat(submitObjectRef.current.paid_amount) !==
        baseline + fineAmount + interestAmount - discountAmount
    ) {
      submitObjectRef.current.paid_amount = `${
        baseline + fineAmount + interestAmount - discountAmount
      }`;

      if (setPaidAmountRef.current) {
        setPaidAmountRef.current(
          `${parseFloat(submitObjectRef.current.paid_amount).toFixed(2)}`
            .replace(/\D/g, "")
            .replace(/(\d+)(\d{2})/g, "R$ $1,$2")
        );
      }
    }
  }, []);

  const handlePaidInstallmentsPaidAmountUpdate = useCallback(
    (index: number) => {
      if (submitObjectRef.current.paid_installments) {
        // deve ser igual ao valor base mais juros e multa, menos o desconto
        // Campo \"paid_amount\" deve ser igual a \"amount\" + \"interest_amount\" + \"fine_amount\" - \"discount_amount\"
        let discountAmount = 0,
          fineAmount = 0,
          interestAmount = 0,
          baseline = parseFloat(
            submitObjectRef.current.paid_installments[index].nominal_amount
          );
        if (submitObjectRef.current.paid_installments[index].discount_amount) {
          discountAmount = parseFloat(
            submitObjectRef.current.paid_installments[index]
              .discount_amount as string
          );
        }
        if (submitObjectRef.current.paid_installments[index].fine_amount) {
          fineAmount = parseFloat(
            submitObjectRef.current.paid_installments[index]
              .fine_amount as string
          );
        }
        if (submitObjectRef.current.paid_installments[index].interest_amount) {
          interestAmount = parseFloat(
            submitObjectRef.current.paid_installments[index]
              .interest_amount as string
          );
        }

        // console.log(
        //   "base line: ",
        //   baseline,
        //   ", installments: ",
        //   submitObjectRef.current.paid_installments[index].installment
        // );
        submitObjectRef.current.paid_installments[index].paid_amount =
          parseFloat(
            `${baseline + fineAmount + interestAmount - discountAmount}`
          ).toFixed(2);

        if (setPaidAmountRefs.current) {
          setPaidAmountRefs.current[index](
            `${parseFloat(
              submitObjectRef.current.paid_installments[index]
                .paid_amount as string
            ).toFixed(2)}`
              .replace(/\D/g, "")
              .replace(/(\d+)(\d{2})/g, "R$ $1,$2")
          );
        }
      }
    },
    []
  );

  const handleInitializeSetPaidInstallmentRefs = (inputValue: string) => {
    if (submitObjectRef.current.mode === "installments") {
      new Array(!isNaN(parseInt(inputValue)) ? parseInt(inputValue) : 2)
        .fill(undefined)
        .forEach((_, index) => {
          if (
            setPaidAmountRefs.current[index] &&
            submitObjectRef.current.paid_installments
          )
            setPaidAmountRefs.current[index](
              parseFloat(
                submitObjectRef.current.paid_installments[index].paid_amount
              )
                .toFixed(2)
                .replace(/(\d+).(\d+)/g, "R$ $1,$2") ?? ""
            );
        });
    }
  };

  const fetchData = async () => {
    const queryStrings = handleParseQuerystrings(window.location.search);

    cancelTokenSource.current = axios.CancelToken.source();

    apiService
      .getCategories(cancelTokenSource.current.token)
      .then((response) => {
        if (response?.data) setCategories(response.data);
      });
    apiService
      .getClassificationCenters(cancelTokenSource.current.token)
      .then((response) => {
        if (response?.data) setClassificationCenters(response.data);
      });
    // apiService.getSuppliers().then((response) => {
    //   setSuppliers(response.data);
    // });
    if (queryStrings.organization_id && paymentSlip) {
      apiService
        .getInvoiceSupplier(
          queryStrings.organization_id[0],
          paymentSlip.uuid,
          cancelTokenSource.current.token
        )
        .then((response: any) => {
          if (response?.data) setSupplier(response.data);
        });
    }
    apiService
      .getDepositAccounts(cancelTokenSource.current.token)
      .then((response) => {
        if (response?.data) setDepositAccounts(response.data);
      });
  };

  const handleInstallmentDueDateInitialState = (index: number) => {
    switch (submitObjectRef.current.period) {
      case "weekly":
        return { weeks: 1 * index };
      case "biweekly":
        return { weeks: 2 * index };
      case "monthly":
        return { months: 1 * index };
      case "bimonthly":
        return { months: 2 * index };
      case "quarterly":
        return { months: 3 * index };
      case "semiannual":
        return { months: 6 * index };
      case "annual":
        return { years: 1 * index };
      default:
        return { months: 1 * index };
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  useEffect(() => {
    // console.log(
    //   "all paid elements has been updated: ",
    //   allPaidElements,
    //   ", submit object ref paid installments: ",
    //   submitObjectRef.current.paid_installments,
    //   ", should submit ref: ",
    //   shouldSubmitRef.current
    // );
  }, [allPaidElements]);

  useEffect(() => {
    if (paymentSlip) {
      if (cancelTokenSource.current) {
        apiService
          .getPaymentSlipAttachedInvoices(
            paymentSlip.uuid,
            cancelTokenSource.current.token
          )
          .then((response) => {
            if (response && selectOrganization) {
              const paymentSlips = response.data as TInvoice[];
              attachedInvoicesRef.current = paymentSlips.sort((a, b) =>
                compareAsc(parseISO(a.date_issue), parseISO(b.date_issue))
              );
              // attachedInvoicesRef.current = attachedInvoicesRef.current.map(
              //   (invoice) => ({
              //     ...invoice,
              //     payer_document: selectOrganization.cnpj,
              //     payer_name: selectOrganization.company_name,
              //   })
              // );
            }
          });
      }

      payableModalPaymentSlipRef.current = paymentSlip;

      submitObjectRef.current.paid_amount = isPaid
        ? paymentSlip.amount
        : undefined;

      setTimeout(() => {
        if (setPaidAmountRef.current && paymentSlip.amount)
          setPaidAmountRef.current(
            paymentSlip.amount
              .replace(/\D/g, "")
              .replace(/(\d+)(\d{2})/g, "R$ $1,$2")
          );
      }, 500);
    }
  }, [paymentSlip, isPaid, activeRadio]);

  return (
    <Container onSubmit={preventSubmitDefaultBehavior}>
      {/* <p>
        Tem certeza que deseja lançar a Nota Fiscal nº {invoice?.number} como
        contas a pagar na <b>Fintera</b>?
      </p> */}
      <RowContainer
        style={{ marginTop: 0 }}
        $hasModeError={shouldShowModeError ? "hasError" : "noError"}
      >
        <p className="modeError">Campo obrigatório</p>
        <div className="radioContainer">
          <Radio
            id="full"
            isActive={activeRadio === "full" ? "active" : "inactive"}
            onClick={() => {
              setActiveRadio("full");
              submitObjectRef.current.mode = "in_cash";
            }}
          />
          &nbsp;<label htmlFor="full">À vista</label>
        </div>
        <div className="radioContainer">
          <Radio
            id="recurring"
            isActive={activeRadio === "recurring" ? "active" : "inactive"}
            onClick={() => {
              setActiveRadio("recurring");
              submitObjectRef.current.mode = "recurrent";
            }}
          />
          &nbsp;<label htmlFor="recurring">Recorrente</label>
        </div>
        <div className="radioContainer">
          <Radio
            id="installments"
            isActive={activeRadio === "installments" ? "active" : "inactive"}
            onClick={() => {
              submitObjectRef.current.due_date = "";
              submitObjectRef.current.mode = "installments";
              submitObjectRef.current.installments =
                attachedInvoicesRef.current.length > 1
                  ? attachedInvoicesRef.current.length + 1
                  : 2;
              submitObjectRef.current.paid_installments = new Array(
                submitObjectRef.current.installments
              )
                .fill({})
                .map(
                  (_, index) =>
                    ({
                      installment: index + 1,
                      discount_amount: "",
                      fine_amount: "",
                      interest_amount: "",
                      expected_deposit_account_id: null,
                      nominal_amount: attachedInvoicesRef.current[index]
                        ? attachedInvoicesRef.current[index].amount
                        : calculateInstallments(
                            parseFloat(paymentSlip.amount),
                            (submitObjectRef.current.installments as number) -
                              attachedInvoicesRef.current.length
                          )[index - attachedInvoicesRef.current.length].toFixed(
                            2
                          ),
                      paid_amount: "",
                      payment_date: format(new Date(), "yyyy-MM-dd"),
                      due_date: attachedInvoicesRef.current[index]
                        ? format(
                            parseISO(
                              attachedInvoicesRef.current[index].date_issue
                            ),
                            "yyyy-MM-dd"
                          )
                        : format(
                            add(
                              new Date(),
                              handleInstallmentDueDateInitialState(index)
                            ),
                            "yyyy-MM-dd"
                          ),
                    } as TPaidInstallments)
                );

              setActiveRadio("installments");
              setInstallments(submitObjectRef.current.installments);
            }}
          />
          &nbsp;<label htmlFor="installments">Parcelado</label>
        </div>
      </RowContainer>
      <RowContainer>
        <LabeledTextInput
          placeholder="Valor a pagar"
          filled={
            paymentSlip?.amount
              ? "R$ " +
                paymentSlip?.amount
                  .replace(/\D/g, "")
                  .replace(/(\d+)(\d{2})/g, "$1,$2")
              : undefined
          }
          disabled
        />
        {activeRadio !== "installments" && (
          <Calendar
            setDate={(setButtonSelectedDate, setDatePickerSelectedDates) => {
              submitObjectRef.current.due_date = format(
                new Date(),
                "yyyy-MM-dd"
              );
              setButtonSelectedDate(format(new Date(), "dd/MM/yyyy"));
              setDatePickerSelectedDates({
                startDate: format(new Date(), "dd/MM/yyyy"),
              });
            }}
            className="calendar"
            placeholder="Vencimento"
            onSelect={(selectDate) => {
              submitObjectRef.current.due_date = selectDate.replace(
                /(\d{2})\/(\d{2})\/(\d{4})/g,
                "$3-$2-$1"
              );
            }}
            onError={{
              hasError: shouldShowDueDateError,
              message: "Campo obrigatório",
            }}
          />
        )}
        {["recurring" /* , "installments" */].includes(activeRadio) && (
          <Dropdown
            button={{
              placeholder: "Período",
              variant: "labeled",
              labels: [""],
              onError: {
                hasError: shouldShowPeriodError,
                message: "Campo obrigatório",
              },
              setData(setSelectedData) {
                submitObjectRef.current.period = "monthly";
                setTimeout(() => {
                  setSelectedData("Mensal");
                }, 50);
              },
            }}
            menu={{
              variant: "unlabeled",
              setSelectedIndex(setSelectedData) {
                setTimeout(() => {
                  setSelectedData(["2"]);
                }, 50);
              },
              options: [
                "Semanal",
                "Quinzenal",
                "Mensal",
                "Bimestral",
                "Trimestral",
                "Semestral",
                "Anual",
              ],
              search: false,
              onSelect(selected) {
                const result = selected as
                  | "Semanal"
                  | "Quinzenal"
                  | "Mensal"
                  | "Bimestral"
                  | "Trimestral"
                  | "Semestral"
                  | "Anual";
                let period;
                switch (result) {
                  case "Semanal":
                    period = "weekly";
                    break;
                  case "Quinzenal":
                    period = "biweekly";
                    break;
                  case "Mensal":
                    period = "monthly";
                    break;
                  case "Bimestral":
                    period = "bimonthly";
                    break;
                  case "Trimestral":
                    period = "quarterly";
                    break;
                  case "Semestral":
                    period = "semiannual";
                    break;
                  case "Anual":
                    period = "annual";
                    break;
                }
                submitObjectRef.current.period = period as
                  | "weekly"
                  | "biweekly"
                  | "monthly"
                  | "bimonthly"
                  | "quarterly"
                  | "semiannual"
                  | "annual";
              },
            }}
          />
        )}
        {activeRadio === "installments" && (
          <LabeledTextInput
            placeholder="Parcelas"
            hasMandatoryStyle={{
              onFocus: { marginLeft: "50px" },
              default: { marginLeft: "70px" },
            }}
            customFilter={(inputRef, setInputString) => {
              let value = inputRef.value
                .replace(/\D/g, "")
                .replace(/(\d{1,2}).*/g, "$1");

              // if (parseInt(value) < 2) {
              //   value = "2";
              // }

              if (isNaN(parseInt(value))) {
                submitObjectRef.current.installments = undefined;
              } else {
                submitObjectRef.current.paid_installments = new Array(
                  parseInt(value)
                )
                  .fill({})
                  .map(
                    (_, index) =>
                      ({
                        installment: index + 1,
                        discount_amount: "",
                        fine_amount: "",
                        interest_amount: "",
                        expected_deposit_account_id: null,
                        nominal_amount: attachedInvoicesRef.current[index]
                          ? attachedInvoicesRef.current[index].amount
                          : calculateInstallments(
                              parseFloat(paymentSlip.amount),
                              parseInt(value)
                            )[index].toFixed(2),
                        paid_amount:
                          submitObjectRef.current.paid_installments &&
                          submitObjectRef.current.paid_installments[index]
                            ?.paid_amount
                            ? calculateInstallments(
                                parseFloat(paymentSlip.amount),
                                parseInt(value)
                              )[index].toFixed(2)
                            : "",
                        payment_date:
                          (submitObjectRef.current.paid_installments &&
                            submitObjectRef.current.paid_installments[index]
                              ?.payment_date) ??
                          undefined,
                        due_date: attachedInvoicesRef.current[index]
                          ? format(
                              parseISO(
                                attachedInvoicesRef.current[index].date_issue
                              ),
                              "yyyy-MM-dd"
                            )
                          : format(
                              add(
                                new Date(),
                                handleInstallmentDueDateInitialState(index)
                              ),
                              "yyyy-MM-dd"
                            ),
                      } as TPaidInstallments)
                  );

                inputRef.value = value;
                setInputString(value);
              }
            }}
            onChange={(inputValue) => {
              if (!isNaN(parseInt(inputValue))) {
                setTimeout(() => {
                  submitObjectRef.current.installments = parseInt(inputValue);

                  handleInitializeSetPaidInstallmentRefs(inputValue);

                  submitObjectRef.current.paid_installments = new Array(
                    !isNaN(parseInt(inputValue)) ? parseInt(inputValue) : 1
                  )
                    .fill({})
                    .map(
                      (_, index) =>
                        ({
                          installment: index + 1,
                          discount_amount: "",
                          fine_amount: "",
                          interest_amount: "",
                          expected_deposit_account_id: null,
                          nominal_amount: attachedInvoicesRef.current[index]
                            ? attachedInvoicesRef.current[index].amount
                            : calculateInstallments(
                                parseFloat(paymentSlip.amount),
                                (submitObjectRef.current
                                  .installments as number) -
                                  attachedInvoicesRef.current.length
                              )[
                                index - attachedInvoicesRef.current.length
                              ].toFixed(2),
                          paid_amount:
                            submitObjectRef.current.paid_installments &&
                            submitObjectRef.current.paid_installments[index]
                              ?.paid_amount
                              ? calculateInstallments(
                                  parseFloat(paymentSlip.amount),
                                  parseInt(inputValue)
                                )[index].toFixed(2)
                              : "",
                          payment_date:
                            (submitObjectRef.current.paid_installments &&
                              submitObjectRef.current.paid_installments[index]
                                ?.payment_date) ??
                            undefined,
                          due_date: attachedInvoicesRef.current[index]
                            ? format(
                                parseISO(
                                  attachedInvoicesRef.current[index].date_issue
                                ),
                                "yyyy-MM-dd"
                              )
                            : format(
                                add(
                                  new Date(),
                                  handleInstallmentDueDateInitialState(index)
                                ),
                                "yyyy-MM-dd"
                              ),
                        } as TPaidInstallments)
                    );

                  setInstallments(parseInt(inputValue));
                }, 20);
              }
            }}
            filled={installments > 0 ? `${installments}` : undefined}
            onError={{
              hasError: shouldShowInstallmentsError,
              message: installmentsErrorMessage,
            }}
          />
        )}
      </RowContainer>
      {activeRadio !== "installments" && (
        <RowContainer>
          <Dropdown
            button={{
              variant: "labeled",
              labels: [""],
              placeholder: "Pagar via",
            }}
            menu={{
              options: depositAccounts.map(
                (depositAccount) => depositAccount.name
              ),
              variant: "unlabeled",
              search: false,
              onSelect(selected) {
                const result = depositAccounts.find(
                  (depositAccount) => depositAccount.name === selected
                );
                if (result)
                  submitObjectRef.current.expected_deposit_account_id =
                    result.id;
              },
            }}
          />
        </RowContainer>
      )}
      {activeRadio !== "installments" && (
        <RowContainer className="short">
          <Check
            id="paid"
            onClick={() => {
              setIsPaid((before) => !before);
            }}
          />
          &nbsp;<label htmlFor="paid">Já paga</label>
        </RowContainer>
      )}
      {activeRadio === "installments" && installments > 0 && (
        <SimpleSlider
          key={installments + "key"}
          style={{ marginTop: 16 }}
          navigateToIndex={(setState) => {
            navigateToSlideByIndexRef.current = setState;
          }}
          children={simpleSliderChildren}
          onBackward={() => {
            dispatch(
              setAllPaidElements({
                allPaidElements:
                  submitObjectRef.current.paid_installments ?? [],
              })
            );
          }}
          onForward={() => {
            dispatch(
              setAllPaidElements({
                allPaidElements:
                  submitObjectRef.current.paid_installments ?? [],
              })
            );
          }}
          setTitle={(currentIndex) => {
            return {
              title: [
                {
                  style: "bold",
                  text: `Parcela ${currentIndex}/${installments}`,
                  shouldBreakLine: false,
                },
              ],
            };
          }}
        />
      )}
      {isPaid && activeRadio !== "installments" && (
        <RowContainer className="paidContainer">
          <LabeledTextInput
            placeholder="Multa"
            customFilter={(inputRef, setInputString) => {
              const value = inputRef.value;
              inputRef.value = value
                .replace(/\D/g, "")
                .slice(0, 10)
                .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
              setInputString(
                value
                  .replace(/\D/g, "")
                  .slice(0, 10)
                  .replace(/^(\d+)(\d{2})$/, "R$ $1,$2")
              );
            }}
            onChange={(inputValue) => {
              submitObjectRef.current.fine_amount = inputValue
                .replace(/\D/g, "")
                .replace(/(\d+)(\d{2})/g, "$1.$2");

              setTimeout(() => {
                handlePaidAmountUpdate();
              }, 500);
            }}
          />
          <LabeledTextInput
            placeholder="Juros"
            customFilter={(inputRef, setInputString) => {
              const value = inputRef.value;
              inputRef.value = value
                .replace(/\D/g, "")
                .slice(0, 10)
                .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
              setInputString(
                value
                  .replace(/\D/g, "")
                  .slice(0, 10)
                  .replace(/^(\d+)(\d{2})$/, "R$ $1,$2")
              );
            }}
            onChange={(inputValue) => {
              submitObjectRef.current.interest_amount = inputValue
                .replace(/\D/g, "")
                .replace(/(\d+)(\d{2})/g, "$1.$2");

              setTimeout(() => {
                handlePaidAmountUpdate();
              }, 500);
            }}
          />
          <LabeledTextInput
            placeholder="Desconto"
            customFilter={(inputRef, setInputString) => {
              const value = inputRef.value;
              inputRef.value = value
                .replace(/\D/g, "")
                .slice(0, 10)
                .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
              setInputString(
                value
                  .replace(/\D/g, "")
                  .slice(0, 10)
                  .replace(/^(\d+)(\d{2})$/, "R$ $1,$2")
              );
            }}
            onChange={(inputValue) => {
              submitObjectRef.current.discount_amount = inputValue
                .replace(/\D/g, "")
                .replace(/(\d+)(\d{2})/g, "$1.$2");

              setTimeout(() => {
                handlePaidAmountUpdate();
              }, 500);
            }}
          />
          <Calendar
            className="calendar"
            hasMandatoryStyle={{ marginLeft: "130px" }}
            placeholder="Data do pagamento"
            onSelect={(selectDate) => {
              submitObjectRef.current.payment_date = selectDate.replace(
                /(\d{2})\/(\d{2})\/(\d{4})/g,
                "$3-$2-$1"
              );
              if (submitObjectRef.current.amount === undefined)
                submitObjectRef.current.amount = paymentSlip.amount;
            }}
            onError={{
              hasError: shouldShowPaymentDateError,
              message: paymentDateErrorMessageRef.current,
            }}
            setDate={(setButtonSelectedDate, setDatePickerSelectedDates) => {
              submitObjectRef.current.payment_date = format(
                new Date(),
                "yyyy-MM-dd"
              );
              setButtonSelectedDate(format(new Date(), "dd/MM/yyyy"));
              setDatePickerSelectedDates({
                startDate: format(new Date(), "dd/MM/yyyy"),
              });
            }}
          />
          <LabeledTextInput
            placeholder="Total pago"
            setData={(setInputString) => {
              setPaidAmountRef.current = setInputString;
            }}
            disabled
          />
          <div style={{ display: "none" }}>
            <Dropdown
              button={{
                variant: "labeled",
                labels: [""],
                placeholder: "Pagar via",
              }}
              menu={{
                options: depositAccounts.map(
                  (depositAccount) => depositAccount.name
                ),
                variant: "unlabeled",
                search: false,
                onSelect(selected) {
                  const result = depositAccounts.find(
                    (depositAccount) => depositAccount.name === selected
                  );
                  if (result)
                    submitObjectRef.current.expected_deposit_account_id =
                      result.id;
                },
              }}
            />
          </div>
        </RowContainer>
      )}
      <RowContainer $isPaid={isPaid} className="description" key={`${isPaid}`}>
        <LabeledTextInput
          placeholder="Descrição"
          onChange={(inputValue) => {
            submitObjectRef.current.description = inputValue;
          }}
          onError={{
            hasError: shouldShowDescriptionError,
            message: "Campo obrigatório",
          }}
        />
      </RowContainer>
      <RowContainer className="description">
        <LabeledTextInput
          placeholder="Observação"
          onChange={(inputValue) => {
            submitObjectRef.current.observation = inputValue;
          }}
          onError={{
            hasError: shouldShowDescriptionError,
            message: "Campo obrigatório",
          }}
        />
      </RowContainer>

      <RowContainer>
        <Check
          id="incomeTaxRelevant"
          onClick={(isClicked) => {
            submitObjectRef.current.income_tax_relevant = isClicked;
          }}
        />
        &nbsp;
        <label htmlFor="incomeTaxRelevant">
          Relevante para Imposto de Renda
        </label>
      </RowContainer>
      <RowContainer>
        <Dropdown
          button={{
            variant: "labeled",
            labels: [""],
            placeholder: "Categoria",
            onError: {
              hasError: shouldShowCategoryIdError,
              message: "Campo obrigatório",
            },
          }}
          menu={{
            variant: "unlabeled",
            options: categories
              .map((category) => category.full_name)
              .sort((a, b) => a.localeCompare(b)),
            search: true,
            onSelect(selected) {
              const result = categories.find(
                (category) => category.full_name === selected
              );
              if (result) submitObjectRef.current.category_id = result.id;
            },
          }}
        />
        <Dropdown
          button={{
            variant: "labeled",
            labels: [""],
            placeholder: "Fornecedor",
            onError: {
              hasError: shouldShowSupplierIdError,
              message: "Campo obrigatório",
            },
            setData(setSelectedData) {
              setTimeout(() => {
                if (supplier) {
                  setSelectedData(supplier.name);
                  submitObjectRef.current.supplier_id = supplier.id;
                }
                // setSelectedData("teste com nome bem grande"), 50;
              });
            },
            disabled: true,
          }}
          menu={{
            variant: "unlabeled",
            // options: suppliers.map((supplier) => supplier.name),
            options: [],
            search: true,
            // onSelect(selected) {
            //   const result = suppliers.find(
            //     (supplier) => supplier.name === selected
            //   );
            //   if (result) submitObjectRef.current.supplier_id = result.id;
            // },
          }}
        />
        <Dropdown
          button={{
            variant: "labeled",
            labels: [""],
            placeholder: "Centro de custo",
            onError: {
              hasError: shouldShowClassificationCenterIdError,
              message: "Campo obrigatório",
            },
          }}
          menu={{
            variant: "unlabeled",
            options: classificationCenters.map(
              (classificationCenter) => classificationCenter.name
            ),
            search: true,
            onSelect(selected) {
              const result = classificationCenters.find(
                (classificationCenter) => classificationCenter.name === selected
              );
              if (result)
                submitObjectRef.current.classification_center_id = result.id;
            },
          }}
        />
      </RowContainer>
      <RowContainer className="attachments-row" style={{ display: "none" }}>
        <div>
          <h3>Anexos</h3>
          <div className="attachments-container">
            {attachmentFiles.map((attachment) => (
              <AttachmentListItem
                localUrl={attachment.localUrl}
                text={attachment.name}
                onClear={() => {
                  if (
                    inputElementRef.current &&
                    inputElementRef.current.files
                  ) {
                    const temp = [...attachmentFiles];
                    const temp2 = Array.from(inputElementRef.current.files);

                    const dataTransfer = new DataTransfer();

                    const clearElementIndex = temp.findIndex(
                      (file) => file.name === attachment.name
                    );
                    temp.splice(clearElementIndex, 1);

                    temp2.forEach((_element, index) => {
                      if (
                        inputElementRef.current &&
                        inputElementRef.current.files &&
                        index !== clearElementIndex
                      ) {
                        const value = inputElementRef.current.files.item(index);
                        if (value) dataTransfer.items.add(value);
                      }
                    });

                    if (inputElementRef.current)
                      inputElementRef.current.files = dataTransfer.files;
                    setAttachmentFiles(temp);
                  }
                }}
              />
            ))}
          </div>
          <div className="attachments-action-container">
            <Button
              icon="link"
              iconPosition="left"
              variant="outline"
              text="Anexar Nota/Boleto"
              onClick={() => {
                setShouldOpenLinkPayableModal(true);
              }}
            />
            <Button
              icon="upload"
              iconPosition="left"
              variant="secondary"
              text="Fazer upload do computador"
              onClick={() => {
                if (inputElementRef.current) {
                  inputElementRef.current.click();
                }
              }}
            />
            <input
              ref={inputElementRef}
              type="file"
              accept=".xml, .pdf"
              style={{ display: "none" }}
              multiple
              onChange={() => {
                // console.log(
                //   "input element ref: ",
                //   inputElementRef.current,
                //   ", input element ref files: ",
                //   inputElementRef.current?.files
                // );
                if (inputElementRef.current && inputElementRef.current.files) {
                  Array.from(inputElementRef.current.files).map((file: File) =>
                    setAttachmentFiles((before) => [
                      ...before,
                      {
                        name: file.name,
                        file: file,
                        localUrl: URL.createObjectURL(file),
                      },
                    ])
                  );
                }
              }}
            />
          </div>
        </div>
      </RowContainer>
      <Footer>
        <div className="buttonsContainer">
          {footer.buttons?.map((buttonProps, index) => {
            const { onClick, ...props } = buttonProps;
            return (
              <Button
                key={index}
                role={index === 1 ? "submit" : undefined}
                {...props}
                onClick={index === 0 ? onCancelHandler : onSubmitHandler}
                isLoading={index === 1 ? isButtonLoading : undefined}
              />
            );
          })}
        </div>
      </Footer>
      <Modal
        key={shouldOpenLinkPayableModal + "-linkPayableModal"}
        shouldOpen={shouldOpenLinkPayableModal}
        icon={{ type: "link", color: "rose" }}
        title="Vincular boleto a conta já lançada"
        onClose={() => {
          setShouldOpenLinkPayableModal(false);
        }}
        main={{
          style: "form",
          content: {
            preset: "linkPayable",
            paymentSlip: payableModalPaymentSlipRef.current as TPaymentSlip,
            footer: {
              buttons: [
                {
                  icon: "noIcon",
                  text: "Cancelar",
                  variant: "secondary",
                  onClick() {
                    setShouldOpenLinkPayableModal(false);
                  },
                },
                {
                  iconPosition: "left",
                  icon: "checkmark",
                  text: "Vincular boleto como anexo",
                  variant: "primary",
                  isLoading: isLinkPayableLoading,

                  onClick(selectRow) {
                    if (selectRow.hasInstallments) {
                      selectPayableRef.current = selectRow.selectPayable;
                      setShouldOpenLinkPayableModal(false);
                      setShouldOpenInstallmentAccountModal(true);
                    } else {
                      if (payableModalPaymentSlipRef.current) {
                        setIsLinkPayableLoading(true);

                        apiService
                          .setLinkInvoiceToPayable(
                            payableModalPaymentSlipRef.current.uuid,
                            selectRow.selectPayable.id,
                            (cancelTokenSource.current as CancelTokenSource)
                              .token
                          )
                          .then((_response) => {
                            setIsLinkPayableLoading(false);
                            fetchPaymentSlipsByAllEntryStatus();
                            dispatch(
                              setToastState({
                                variant: "success",
                                shouldShow: true,
                                message: {
                                  title: "Sucesso",
                                  description:
                                    "Boleto vinculado à contas a pagar",
                                },
                              })
                            );
                            setShouldOpenLinkPayableModal(false);
                          })
                          .catch((e) => {
                            console.log("error: ", e);
                            const errorMessage = backendErrorMessageHandler(e);

                            setIsLinkPayableLoading(false);
                            dispatch(
                              setToastState({
                                variant: "error",
                                shouldShow: true,
                                message: {
                                  title: "Erro ao vincular boleto",
                                  description:
                                    typeof errorMessage === "string"
                                      ? errorMessage
                                      : "Ocorreu um erro ao vincular a nota à Contas a Pagar, tente novamente",
                                },
                              })
                            );
                            setShouldOpenLinkPayableModal(false);
                          });
                      }
                    }
                  },
                },
              ],
            },
          },
        }}
      />
      <Modal
        shouldOpen={shouldOpenInstallmentAccountModal}
        onClose={() => {
          setShouldOpenInstallmentAccountModal(false);
        }}
        icon={{ type: "link", color: "rose" }}
        title="Vincular à conta parcelada"
        main={{
          style: "form",
          content: {
            preset: "installmentAccount",
            paymentSlip: payableModalPaymentSlipRef.current as TPaymentSlip,
            footer: {
              buttons: [
                {
                  variant: "secondary",
                  icon: "noIcon",
                  text: "Cancelar",
                  onClick() {
                    setShouldOpenInstallmentAccountModal(false);
                  },
                },
                {
                  variant: "primary",
                  iconPosition: "left",
                  icon: "checkmark",
                  text: "Vincular nota como anexo",
                  isLoading: isLinkPayableLoading,
                  onClick() {
                    if (
                      payableModalPaymentSlipRef.current &&
                      selectPayableRef.current
                    ) {
                      setIsLinkPayableLoading(true);

                      apiService
                        .setLinkInvoiceToPayable(
                          payableModalPaymentSlipRef.current.uuid,
                          `${selectPayableRef.current.id}`,
                          (cancelTokenSource.current as CancelTokenSource).token
                        )
                        .then((_response) => {
                          setIsLinkPayableLoading(false);
                          fetchPaymentSlipsByAllEntryStatus();
                          dispatch(
                            setToastState({
                              variant: "success",
                              shouldShow: true,
                              message: {
                                title: "Sucesso",
                                description: "Nota vinculada à contas a pagar",
                              },
                            })
                          );
                          setShouldOpenInstallmentAccountModal(false);
                        })
                        .catch((e) => {
                          const errorMessage = backendErrorMessageHandler(e);
                          console.log("error: ", e);
                          setIsLinkPayableLoading(false);
                          dispatch(
                            setToastState({
                              variant: "error",
                              shouldShow: true,
                              message: {
                                title: "Erro ao vincular nota",
                                description:
                                  typeof errorMessage === "string"
                                    ? errorMessage
                                    : "Ocorreu um erro ao vincular a nota à Contas a Pagar, tente novamente",
                              },
                            })
                          );
                          setShouldOpenInstallmentAccountModal(false);
                        });
                    }
                  },
                },
              ],
            },
          },
        }}
      />
    </Container>
  );
};

export default AddPaymentSlip;
