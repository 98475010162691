import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchUserData } from "../../actions";

export type TUserData = {
  email: string;
  first_name: string;
  last_name: string;
  uuid: string;
};

type TData = {
  user: TUserData | null;
};

type TInitialState = {
  loading: boolean;
  error: null | string;
  data: TData;
};

const initialState: TInitialState = {
  loading: false,
  error: "",
  data: {
    user: null,
  },
};

const userDataSlice = createSlice({
  name: "userData",
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ user: TUserData }>) => {
      state.data.user = action.payload.user;
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.data.user = action.payload ?? state.data.user;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "error";
      });
  },
});

export const { setUser } = userDataSlice.actions;
export default userDataSlice.reducer;
