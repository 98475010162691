import styled from "styled-components";
import SVG from "../ui/SVG";
import logo from "../../assets/images/logo.svg";

const Container = styled(SVG)`
  height: 40px;
  width: 191px;
`;

const Logo: React.FC = () => {
  return <Container src={logo}></Container>;
};

export default Logo;
