import styled from "styled-components";
import { UilTimes } from "@iconscout/react-unicons";
import { useEffect, useRef, useState } from "react";

const Container = styled.button<{ $variant: TVariants }>`
  position: relative;
  width: 280px;
  height: max-content;
  padding: ${({ $variant }) =>
    $variant === "small" ? `9px 12px 9px 24px` : `14px 12px 14px 38px`};

  border-radius: 8px;
  border: 1px solid var(--color-dark-blue-300);

  background: var(--color-light-0);

  cursor: text;

  &:hover {
    background: var(--color-light-0);
  }

  .clear {
    position: absolute;
    right: 12px;

    ${({ $variant }) => ($variant === "small" ? `top: 9px;` : `top: 12px;`)}

    color: var(--color-text-labels);
    width: 18px;
    height: 18px;
    font-size: 14px;

    cursor: pointer;
    outline: none;
  }

  &::before {
    content: "";
    position: absolute;
    font-family: "unicons";

    color: var(--color-text-labels);

    ${({ $variant }) =>
      $variant === "small"
        ? `left: 8px;
           top: 11px;
           width: 18px;
           height: 18px;
           font-size: 14px;
           `
        : `left: 0; 
           top: 0; 
           display: flex; 
           justify-content: center; 
           align-items: center;
           background: var(--color-light-100);
           border-radius: 8px 0 0 8px;
           width: 38px;
           height: 100%;
           font-size: 18px;
           `}
  }

  &:focus-within {
    border: 1px solid var(--color-main-blue);

    .clear {
      // visibility: visible;
    }

    &::before {
      color: var(--color-text-paragraph);
    }
  }

  .clear:focus {
    border: 1px solid var(--color-main-blue);
  }
`;

const Input = styled.input`
  width: calc(100% - 30px);
  margin-right: 20px;
  margin-left: 10px;
  border: 0;

  color: var(--color-text-paragraph);

  &,
  &::placeholder {
    font-size: 14px;
  }

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: var(--color-text-labels);
  }
`;

type TVariants = "default" | "small";

type TSearchProps = {
  variant: TVariants;
  id?: string;
  placeholder?: string;
  onChange?: (searchPhrase: string) => void;
  onSearch?: (searchPhrase: string) => void;
  timeoutAmount?: number;
  onClear?: () => void;
  $ref?: (
    inputRef: React.MutableRefObject<HTMLInputElement | null>,
    setSearchString: React.Dispatch<React.SetStateAction<string>>
  ) => void;
};

const Search: React.FC<TSearchProps> = ({
  variant,
  id,
  placeholder,
  onChange,
  onSearch,
  timeoutAmount,
  onClear,
  $ref,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchString, setSearchString] = useState("");

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setSearchString(e.target.value);
      if (onChange) onChange(e.target.value);
    }, timeoutAmount ?? 50);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setTimeout(() => {
      if (onSearch && e.key.toLowerCase() === "enter") {
        onSearch(searchString);
      }
    }, timeoutAmount ?? 50);
  };

  const handleOnClick = () => {
    if (inputRef.current !== null) {
      inputRef.current.value = "";

      setTimeout(() => {
        setSearchString("");
        if (onChange) onChange("");
        if (onClear) onClear();
      }, timeoutAmount ?? 50);
    }
  };

  const handleFocus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    if ($ref && inputRef) $ref(inputRef, setSearchString);
  }, []);

  const handleRenderSmallSearch = () => {
    return (
      <Container
        $variant={variant}
        id={id && id}
        role="button"
        onClick={handleFocus}
      >
        <Input
          onChange={handleOnChange}
          ref={inputRef}
          type="text"
          placeholder={placeholder ?? "placeholder"}
          onKeyDown={handleOnKeyDown}
        />
        {searchString.length > 0 && (
          <UilTimes
            onClick={handleOnClick}
            tabIndex={0}
            role="button"
            className="clear"
          />
        )}
      </Container>
    );
  };

  const handleRenderSearch = () => {
    return (
      <Container
        $variant={variant}
        id={id && id}
        role="button"
        onClick={handleFocus}
      >
        <Input
          onChange={handleOnChange}
          ref={inputRef}
          type="text"
          placeholder={placeholder ?? "placeholder"}
          onKeyDown={handleOnKeyDown}
        />
        {searchString.length > 0 && (
          <UilTimes
            onClick={handleOnClick}
            tabIndex={0}
            role="button"
            className="clear"
          />
        )}
      </Container>
    );
  };

  return variant === "small" ? handleRenderSmallSearch() : handleRenderSearch();
};

export default Search;
