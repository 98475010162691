import styled from "styled-components";
import BreadCrumbs from "../../components/ui-v2/BreadCrumbs";
import ExtraLargeButton from "../../components/ui-v2/ExtraLargeButton";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../router";
import { useEffect, useRef, useState } from "react";
import apiService from "../../services/api";
import { localStorageConstants } from "../../utils/constants";
import { TAccount } from "../../redux/reducers/account";
import {
  handleGetDataFromLocalStorage,
  handleParseQuerystrings,
} from "../../utils/miscellaneous";
import { TMembershipData } from "../../redux/reducers/memberships";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import axios, { CancelTokenSource } from "axios";

const Container = styled.main`
  h1 {
    font-family: var(--font-title-bold);
    font-size: 28px;
    color: var(--color-text-title);
    margin-top: 10px;
  }

  & > *:nth-child(1) {
    margin-top: 20px;
  }
`;

const ContentContainer = styled.section`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;

  .buttonContainer {
    width: calc(100% - 150px);
    display: flex;
    justify-content: center;
  }

  & *:not(& *:last-child) {
    margin-right: 24px;
  }
`;

const AccountCard = styled.article`
  display: flex;
  flex-direction: column;

  width: calc(214vw / 14.4);

  padding: 20px 16px;

  border: 1px solid var(--color-dark-blue-300);
  border-radius: 16px;

  h2 {
    font-family: var(--font-title-bold);
    font-size: 21px;
    color: var(--color-dark-blue-700);
  }

  p {
    color: var(--color-dark-blue-500);
    font-size: 14px;
    font-family: var(--font-paragraph-default);
  }

  p:not(:last-child) {
    margin-bottom: 16px;
  }

  p span {
    color: var(--color-dark-blue-600);
    font-size: 14px;
    font-family: var(--font-paragraph-bold);
  }
`;

const Config: React.FC = () => {
  const navigate = useNavigate();

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const [organizationsCount, setOrganizationsCount] = useState(0);
  const [membershipsCount, setMembershipsCount] = useState(0);
  const [selectAccountName, setSelectAccountName] = useState("");

  const { selectedOrganizationWithPermissions } = useTypedSelector(
    (state) => state.globalState.data
  );

  const queryStrings = handleParseQuerystrings(window.location.search);
  let searchParam = "";
  if (queryStrings.organization_id)
    searchParam = "?organization_id=" + queryStrings.organization_id[0];

  const onClickUsersHandler = () => {
    navigate(ROUTES.SETTINGS_USERS + searchParam);
  };

  const onClickOrgsHandler = () => {
    navigate(ROUTES.SETTINGS_ORGANIZATIONS + searchParam);
  };

  const fetchData = async () => {
    const localSelectAccount = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ACCOUNT
    ) as TAccount;

    if (localSelectAccount) {
      cancelTokenSource.current = axios.CancelToken.source();

      let membershipsResponse = (await apiService.getMemberships(
        localSelectAccount.uuid,
        cancelTokenSource.current.token
      )) as any;
      if (membershipsResponse) {
        membershipsResponse = membershipsResponse.data as TMembershipData;
        setMembershipsCount(membershipsResponse.count);
      }
      setSelectAccountName(localSelectAccount.name);
    }

    const localOrganizationsCount = handleGetDataFromLocalStorage(
      localStorageConstants.ORGANIZATIONS_COUNT
    ) as number;
    console.log("local organization count: ", localOrganizationsCount);
    if (localOrganizationsCount) {
      setOrganizationsCount(localOrganizationsCount);
    }
  };

  useEffect(() => {
    if (
      selectedOrganizationWithPermissions &&
      !selectedOrganizationWithPermissions.permissions
        .organization_management &&
      !selectedOrganizationWithPermissions.permissions.user_management
    ) {
      navigate(ROUTES.HOME + window.location.search);
    }
  }, [selectedOrganizationWithPermissions]);

  useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, 200);

    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  return (
    <Container>
      <BreadCrumbs
        names={["Início", "Configurações"]}
        urls={[ROUTES.HOME, ROUTES.SETTINGS]}
      />
      <h1>Configurações</h1>
      <ContentContainer>
        <AccountCard>
          <h2>Conta</h2>
          <p>{selectAccountName}</p>
          <p>
            Organizações:&nbsp;<span>{organizationsCount}</span>
          </p>
          <p>
            Usuários:&nbsp;<span>{membershipsCount}</span>
          </p>
        </AccountCard>
        {selectedOrganizationWithPermissions &&
          selectedOrganizationWithPermissions.permissions
            .organization_management && (
            <div
              className={
                !selectedOrganizationWithPermissions.permissions.user_management
                  ? "buttonContainer"
                  : ""
              }
            >
              <ExtraLargeButton onClick={onClickOrgsHandler} variant="orgs" />
            </div>
          )}
        {selectedOrganizationWithPermissions &&
          selectedOrganizationWithPermissions.permissions.user_management && (
            <div
              className={
                !selectedOrganizationWithPermissions.permissions
                  .organization_management
                  ? "buttonContainer"
                  : ""
              }
            >
              <ExtraLargeButton onClick={onClickUsersHandler} variant="users" />
            </div>
          )}
      </ContentContainer>
    </Container>
  );
};

export default Config;
