import { UilTimes } from "@iconscout/react-unicons";
import styled from "styled-components";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { setToastState } from "../../redux/reducers/globalState";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import React, { useEffect, useRef, useState } from "react";
import useDetectOutsideClick from "../../hooks/useDetectOutsideClick";
import Spinner from "./Spinner";

const ToastContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Container = styled.div<{ $variant: TVariant }>`
  ${({ $variant }) => {
    switch ($variant) {
      case "success":
        return (
          `border: 1px solid var(--color-aux-success-800);` +
          `background: var(--color-aux-success-100);`
        );
      case "error":
        return (
          `border: 1px solid var(--color-aux-danger-800);` +
          `background: var(--color-aux-danger-100);`
        );
      case "loading":
      case "information":
        return (
          `border: 1px solid var(--color-main-blue);` +
          `background: var(--color-blue-100);`
        );
    }
  }}
  border-radius: 8px;
  padding: 16px;

  svg {
    ${({ $variant }) =>
      $variant === "loading" && "width: 33px;" + "height: 33px;"}
    & .spinner {
      ${({ $variant }) =>
        $variant === "loading" && "fill: var(--color-main-blue);"}
    }
  }

  position: fixed;
  z-index: 6;
  top: 0px;
  opacity: 0;
  transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &.show {
    opacity: 1;
    top: 20px;
  }

  display: flex;
  align-items: center;

  &::before {
    ${({ $variant }) => {
      switch ($variant) {
        case "success":
          return `content: ''; color: var(--color-aux-success-800);`;
        case "error":
          return `content: ''; color: var(--color-aux-danger-800);`;
        case "information":
          return `content: ''; color: var(--color-main-blue); `;
      }
    }}

    font-family: unicons;
    font-size: 33px;
  }

  & > button svg {
    width: 24px;
    height: 24px;
    color: var(--color-dark-blue-500);
  }

  & > button:hover {
    cursor: pointer;
    background: unset;
  }

  & > *,
  &::before,
  &::after {
    margin-left: 10px;
  }
`;

const ContentContainer = styled.div<{
  $variant: TVariant;
}>`
  max-width: 440px;
  max-height: 400px;
  overflow-y: auto;

  ${({ $variant }) => $variant === "loading" && "margin-left: 20px;"}

  h1 {
    font-size: 24px;
    font-family: var(--font-title-bold);
  }

  p {
    font-size: 16px;
    font-family: var(--font-paragraph-default);
    color: var(--color-text-paragraph);
    overflow-wrap: break-word;
  }

  a p {
    color: var(--color-text-link);
  }

  ${({ $variant }) => {
    switch ($variant) {
      case "success":
        return `h1{color: var(--color-aux-success-800);}`;
      case "error":
        return `h1{color: var(--color-aux-danger-800);}`;
      case "loading":
      case "information":
        return `h1{color: var(--color-blue-800);}`;
    }
  }}
`;

type TVariant = "success" | "error" | "information" | "loading";

type TToastProps = {
  variant: TVariant;
  message: { title: string; description: string; link?: { url: string } };
};

const Toast: React.FC<TToastProps> = ({ variant, message }) => {
  const { toast } = useTypedSelector((state) => state.globalState.data);
  const [shouldShow, setShouldShow] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useTypedDispatch();
  const handleOnClose = () => {
    setShouldShow(false);
    setTimeout(() => {
      dispatch(
        setToastState({
          message: { title: "", description: "" },
          shouldShow: false,
          variant: "success",
        })
      );
    }, 300);
  };

  useDetectOutsideClick({
    isClicked: shouldShow,
    componentRef: containerRef,
    onOutside: handleOnClose,
  });

  useEffect(() => {
    if (toast.shouldShow) {
      setTimeout(() => {
        setShouldShow(true);
        if (toast.variant === "success" && !toast.message.link) {
          setTimeout(() => {
            setShouldShow(false);
          }, 1200);
        }
      }, 300);
    }
  }, [toast]);

  return (
    <ToastContainer>
      <Container
        className={shouldShow ? "show" : "hide"}
        $variant={variant}
        ref={containerRef}
        // role="alert"
      >
        {variant === "loading" && <Spinner />}
        <ContentContainer $variant={variant}>
          <h1>{message.title}</h1>
          {message.link ? (
            <a
              onClick={handleOnClose}
              href={message.link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>{message.description}</p>
            </a>
          ) : (
            <p>
              {message.description.includes("\n")
                ? message.description.split("\n").map((description, index) => (
                    <React.Fragment key={index}>
                      {description}
                      <br /><br />
                    </React.Fragment>
                  ))
                : message.description}
            </p>
          )}
        </ContentContainer>
        <button role="button" onClick={handleOnClose}>
          <UilTimes />
        </button>
      </Container>
    </ToastContainer>
  );
};

export default Toast;
