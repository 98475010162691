import styled from "styled-components";
import { TText } from "./Table";
import Button from "./Button";
import React, { useEffect, useState } from "react";

// const Container = styled.div``;

const ControllersArea = styled.div`
  display: flex;
  align-items: center;

  button {
    border: none;
    box-shadow: none;
  }
`;

type SimpleSliderProps = {
  setTitle?: (currentIndex: number) => { title: TText[] };
  children: JSX.Element[];
  style?: React.CSSProperties;
  navigateToIndex?: (
    setState: React.Dispatch<React.SetStateAction<number>>
  ) => void;
  onBackward?: (currentIndex: number) => void;
  onForward?: (currentIndex: number) => void;
};

const SimpleSlider: React.FC<SimpleSliderProps> = ({
  setTitle,
  children,
  style,
  navigateToIndex,
  onBackward,
  onForward,
}) => {
  const [currentIndex, setCurrentIndex] = useState(1);

  const handleTitle = (value: TText) => {
    switch (value.style) {
      case "bold":
        return (
          <>
            <b>{value.text}</b>
            {value.shouldBreakLine && <br />}
          </>
        );
      case "default":
        return (
          <>
            <p>{value.text}</p>
            {value.shouldBreakLine && <br />}
          </>
        );
      case "faded":
        return (
          <>
            <span>{value.text}</span>
            {value.shouldBreakLine && <br />}
          </>
        );
    }
  };

  const handleBackwardNavigation = () => {
    if (currentIndex - 1 > 0) {
      if (onBackward) onBackward(currentIndex - 1);
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const handleForwardNavigation = () => {
    if (currentIndex + 1 <= children.length) {
      if (onForward) onForward(currentIndex + 1);
      setCurrentIndex((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (navigateToIndex) {
      navigateToIndex(setCurrentIndex);
    }
  }, [children, navigateToIndex, currentIndex]);

  return (
    <>
      <ControllersArea style={style}>
        <Button
          variant="secondary"
          icon="onlyIcon"
          iconType="angleLeftB"
          onClick={handleBackwardNavigation}
        />
        {setTitle &&
          setTitle(currentIndex).title.map((value, index) => (
            <React.Fragment key={index}>{handleTitle(value)}</React.Fragment>
          ))}
        {/* <p>{currentIndex}</p> */}
        <Button
          variant="secondary"
          icon="onlyIcon"
          iconType="angleRightB"
          onClick={handleForwardNavigation}
        />
      </ControllersArea>

      <React.Fragment key={currentIndex}>
        {children[currentIndex - 1]}
      </React.Fragment>
    </>
  );
};

export default SimpleSlider;
