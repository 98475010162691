import { useCallback, useEffect, useId, useRef, useState } from "react";
import {
  Container,
  MobileSupportContainer,
  SearchBarSection,
  SearchBarSectionSubcontainer,
  SummarySection,
} from "./styles";
import SearchBar from "../../components/ui-v2/Inputs/Search";
import TabsGroup from "../../components/ui/groups/TabsGroup";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import DropdownFilter from "../../components/ui-v2/DropdownFilter";
import {
  /* Table , */ TCellNotTagMiscellaneous,
  TCellStyleVariants,
  TTableBody,
} from "../../components/ui-v2/Table";
import {
  breakStringPreserveSpaces,
  cnpjMask,
  // handleFormatDateAndTime,
  handleSortQuerystring,
} from "../../utils/formatters";

import {
  TInvoice,
  TInvoiceEntryStatus,
  TPaymentSlip,
  setAttachedInvoices,
  setInvoicesUpdatedAt,
  setPaymentSlipsUpdatedAt,
  setToastState,
} from "../../redux/reducers/globalState";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { fetchInvoices, fetchPaymentSlips } from "../../redux/actions";
import {
  CALENDAR_SLIP_CONTENT_OPTIONS,
  TCalendarMenuOptions,
  TCalendarSlipMenuOptions,
} from "../../components/ui-v2/DropdownFilter/Menu";
import { format } from "date-fns/format";
import { parse } from "date-fns/parse";
import {
  backendErrorMessageHandler,
  getQuerystringParams,
  handleParseQuerystrings,
  handleParseToDateCalendarOptions,
  handlePopulateQuerystring,
  handleRevertTranslateCalendarOptions,
  handleSetDataToLocalStorage,
  handleSetEndDate,
  handleTranslateCalendarOptions,
} from "../../utils/miscellaneous";
import { localStorageConstants } from "../../utils/constants";
import Modal from "../../components/ui-v2/Modal";
import TableGrid from "../../components/ui-v2/TableGrid";
import apiService from "../../services/api";
import { TPayableAccount } from "../../components/ui-v2/Modal/Forms/LinkPayable";
import { useNavigate } from "react-router-dom";
import Button from "../../components/ui-v2/Button";
import { UilAngleDown, UilCopyAlt, UilLink } from "@iconscout/react-unicons";
import AttachmentMenu from "../../components/ui-v2/Menus/AttachmentMenu";
import { ROUTES } from "../../router";
import Tooltip from "../../components/ui-v2/Tooltip";
import axios, { CancelTokenSource } from "axios";
import { parseISO } from "date-fns/parseISO";

function PaymentSlip() {
  const dispatch = useTypedDispatch();

  const invoicesUpdatedAt = useTypedSelector(
    (state) => state.globalState.data.paymentSlipUpdatedAt
  );

  const {
    paymentSlipState,
    selectOrganization,
    selectedOrganizationWithPermissions,
    attachedInvoices,
    pendingPaymentSlipsCount: pendingEntryStatusCount,
    donePaymentSlipsCount: doneEntryStatusCount,
    rejectPaymentSlipsCount: rejectEntryStatusCount,
  } = useTypedSelector((state) => state.globalState.data);

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const startDateRef = useRef<string | undefined>();
  /* format(sub(new Date(), { days: 30 }), "yyyy-MM-dd") */
  const endDateRef = useRef<string | undefined>();
  /* format(new Date(), "yyyy-MM-dd") */
  const entryStatusRef = useRef<TInvoiceEntryStatus | undefined>(10);
  const payablesRef = useRef<boolean | undefined>();
  const goodsEntryRef = useRef<boolean | undefined>();
  const pageRef = useRef(1);
  const itemsPerPageRef = useRef(100);
  const searchRef = useRef<string | undefined>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const clearSelectedInvoicesRef = useRef<(() => void) | null>(null);

  const positionRef = useRef<DOMRect | undefined>();

  const selectPayableRef = useRef<TPayableAccount>();

  const payableModalPaymentSlipRef = useRef<TPaymentSlip | undefined>();
  const allPaymentSlipsRef = useRef<TPaymentSlip[]>([]);

  const searchBarContainerId = useId();

  const [paymentSlipToAttachLinks, setPaymentSlipToAttachLinks] =
    useState<TPaymentSlip | null>(null);

  const [paymentSlipToDelete, setPaymentSlipToDelete] = useState<
    TPaymentSlip | undefined
  >();
  const [invoiceToBreakLink, setInvoiceToBreakLink] = useState<
    { target: TInvoice; origin: TPaymentSlip } | undefined
  >();

  const [invoiceToRename, setInvoiceToRename] = useState<
    TInvoice | undefined
  >();

  const [shouldShowAttachmentMenu, setShouldShowAttachmentMenu] = useState("");

  const [shouldOpenPayable, setShouldOpenPayable] = useState(false);

  const [shouldOpenPayableModal, setShouldOpenPayableModal] = useState(false);
  const [shouldOpenLinkPayableModal, setShouldOpenLinkPayableModal] =
    useState(false);
  const [isLinkPayableLoading, setIsLinkPayableLoading] = useState(false);
  const [shouldUpdateComponent, setShouldUpdateComponent] = useState(false);

  const [
    shouldOpenInstallmentAccountModal,
    setShouldOpenInstallmentAccountModal,
  ] = useState(false);

  const [shouldOpenLinkInvoiceModal, setShouldOpenLinkInvoiceModal] =
    useState(false);

  const [shouldRejectFilledEntryStatus /* setShouldRejectFilledEntryStatus */] =
    useState(false);

  const navigate = useNavigate();

  const entryStatusOptions = [
    "Sem lançamentos",
    "Contas a pagar lançada",
    // "Entrada de mercadorias lançada",
  ];

  const SHOULD_DISABLE_FINTERA_ACTIONS =
    selectedOrganizationWithPermissions &&
    !selectedOrganizationWithPermissions.permissions.fintera;

  const rowFormatter = (paymentSlip: TPaymentSlip, index: number) => {
    return [
      {
        style: "default",
        value: paymentSlip?.process_date
          ? format(parseISO(paymentSlip.process_date), "dd/MM/yyyy")
          : "-",
      },
      {
        style: "default",
        value:
          paymentSlip.source.length > 0
            ? paymentSlip.source
            : paymentSlip.user?.email
            ? paymentSlip.user.email
            : "-",
      },
      {
        style: "miscellaneous",
        tooltip: (() => {
          const name =
            paymentSlip.provider_name.length > 0
              ? paymentSlip.provider_name
              : // : paymentSlip.user &&
                //   (paymentSlip.user.first_name || paymentSlip.user.last_name)
                // ? paymentSlip.user.first_name + " " + paymentSlip.user.last_name
                "-";

          return (
            name && {
              text: (() => {
                const values = breakStringPreserveSpaces(name, 24);

                return values.map((value, index) => ({
                  style: "default",
                  text: value,
                  shouldBreakLine: index !== values.length - 1,
                }));
              })(),
              customTopPosition: name
                ? index * 97 +
                  (215 - (breakStringPreserveSpaces(name, 24).length - 1) * 20)
                : "",
              hasScrollableRef: true,
            }
          );
        })(),
        values: (() => {
          const name =
            paymentSlip.provider_name.length > 0
              ? paymentSlip.provider_name
              : // : paymentSlip.user &&
                //   (paymentSlip.user.first_name || paymentSlip.user.last_name)
                // ? paymentSlip.user.first_name + " " + paymentSlip.user.last_name
                "-";

          return [
            {
              style: "bold",
              text: name
                ? name.length > 20
                  ? name.slice(0, 20) + "..."
                  : name
                : "-",
              shouldBreakLine: true,
            },
            {
              style: "default",
              text: paymentSlip?.provider_document
                ? cnpjMask(paymentSlip?.provider_document)
                : "-",
              shouldBreakLine: false,
            },
          ];
        })(),
      },
      {
        style: "default",
        value:
          paymentSlip?.amount !== null
            ? "R$ " + paymentSlip.amount?.replace(".", ",")
            : "-",
      },
      {
        style: "default",
        value: paymentSlip?.due_date
          ? format(parseISO(paymentSlip?.due_date), "dd/MM/yyyy")
          : "-",
      },
      [
        {
          style: "button",
          icon:
            paymentSlip.accounts_payable_url === null
              ? "payableOrange"
              : paymentSlip.accounts_payable_url.length > 0
              ? "payableGreen"
              : "payableOrange",
          isDisabled:
            (paymentSlip.accounts_payable_url !== null &&
              paymentSlip.accounts_payable_url.length === 0) ||
            SHOULD_DISABLE_FINTERA_ACTIONS,
          tooltip:
            !SHOULD_DISABLE_FINTERA_ACTIONS &&
            paymentSlip.accounts_payable_url === null
              ? {
                  text: [
                    {
                      style: "default",
                      text: "Clique para criar uma ",
                      shouldBreakLine: false,
                    },
                    {
                      style: "bold",
                      text: "conta a pagar ",
                      shouldBreakLine: false,
                    },
                    {
                      style: "default",
                      text: "na Fintera",
                      shouldBreakLine: false,
                    },
                  ],
                  customTopPosition: 97 * index + 208.5,
                  hasScrollableRef: true,
                }
              : SHOULD_DISABLE_FINTERA_ACTIONS ||
                (paymentSlip.accounts_payable_url as string).length === 0
              ? {
                  text: [
                    {
                      style: "default",
                      text: "Criar conta a pagar indisponível",
                      shouldBreakLine: false,
                    },
                  ],
                  customTopPosition: index * 97 + 223,
                  hasScrollableRef: true,
                }
              : {
                  text: [
                    {
                      style: "default",
                      text: "Conta a pagar criada.",
                      shouldBreakLine: true,
                    },
                    {
                      style: "default",
                      text: "Clique para visualizar na Fintera",
                      shouldBreakLine: false,
                    },
                  ],
                  customTopPosition: 97 * index + 208.5,
                  hasScrollableRef: true,
                },

          onClick() {
            if (paymentSlip.accounts_payable_url === null) {
              payableModalPaymentSlipRef.current = paymentSlip;
              setShouldOpenPayable(true);
            } else {
              window.open(
                paymentSlip.accounts_payable_url
                  ? paymentSlip.accounts_payable_url
                  : "",
                "_blank"
              );
            }
          },
        },
      ],
      {
        style: "button",
        icon: "ellipsisV",
        hasScrollableRef: true,
        isDisabled:
          paymentSlip.file_boleto === null &&
          [30].includes(paymentSlip.launch_status) &&
          paymentSlip.accounts_payable_url !== null,
        content: [
          paymentSlip.file_boleto !== null && "downloadFile",
          ![30].includes(paymentSlip.launch_status) && "deletePaymentSlip",
          paymentSlip.accounts_payable_url === null && "openPayableModal",
        ],
        onClick(select: string) {
          handleSetDataToLocalStorage(
            localStorageConstants.SELECT_INVOICE,
            paymentSlip
          );
          handleSetDataToLocalStorage(
            localStorageConstants.CURRENT_ENTRY_STATUS,
            entryStatusRef.current === undefined ? 40 : entryStatusRef.current
          );
          if (select.includes("delete")) {
            setPaymentSlipToDelete(paymentSlip);
          }
          if (select.includes("download")) {
            window.open(paymentSlip.file_boleto, "_blank");
          }

          if (select === "openPayableModal") {
            payableModalPaymentSlipRef.current = paymentSlip;
            setShouldOpenPayable(true);
          }
        },
      },
    ] as TTableBody<TCellStyleVariants>[];
  };

  const handleSecondRowLeftSideCustomNodeRender = (
    paymentSlip: TPaymentSlip,
    index: number
  ) => {
    return (
      <span>
        <Tooltip
          text={[{ text: "Copiar", style: "default", shouldBreakLine: false }]}
          customTopPosition={275 + 98 * index}
        >
          <button
            role="button"
            onClick={() => {
              navigator.clipboard.writeText(paymentSlip.bar_code).then(() => {
                dispatch(
                  setToastState({
                    variant: "success",
                    message: {
                      title: "Sucesso",
                      description: "Linha digitável copiada",
                    },
                    shouldShow: true,
                  })
                );
              });
            }}
          >
            <UilCopyAlt />
          </button>
        </Tooltip>
      </span>
    );
  };

  const handleSecondRowRightSideCustomNodeRender = (
    paymentSlip: TPaymentSlip,
    rowIndex: number
  ) => {
    return paymentSlip.invoices_exists ? (
      <span>
        <button
          role="button"
          onClick={() => {
            if (attachedInvoices.length > 0) {
              dispatch(setAttachedInvoices([]));
              setShouldShowAttachmentMenu("");
            } else {
              apiService
                .getPaymentSlipAttachedInvoices(
                  paymentSlip.uuid,
                  (cancelTokenSource.current as CancelTokenSource).token
                )
                .then((response: any) => {
                  dispatch(setAttachedInvoices(response.data));
                  setShouldShowAttachmentMenu(`${rowIndex}`);
                  positionRef.current = document
                    .getElementById("link-container-" + rowIndex)
                    ?.getBoundingClientRect();
                });
            }
          }}
        >
          Ver notas fiscais <UilAngleDown />
        </button>

        <AttachmentMenu
          position={{ top: 340 + 100 * rowIndex + "px" }}
          shouldShowMenu={
            shouldShowAttachmentMenu === `${rowIndex}` &&
            attachedInvoices.length > 0
          }
          onClickAway={() => {
            dispatch(setAttachedInvoices([]));
            setShouldShowAttachmentMenu("");
          }}
          // items={attachedInvoices.map((item) =>
          //   test(item, rowIndex, secondRow)
          // )}
          items={attachedInvoices.map((invoice: TInvoice) => {
            return {
              onRename() {
                setInvoiceToRename(invoice);
              },
              onDelete:
                invoice.fintera_id && invoice.fintera_id.length > 0
                  ? undefined
                  : () => {
                      setInvoiceToBreakLink({
                        target: invoice,
                        origin: paymentSlip,
                      });
                    },
              name: invoice.label,
              searchParam: invoice.access_key,
              url:
                ROUTES.INVOICES + window.location.search + "&period=all-time",
            };
          })}
          onAction={{
            callback: () => {
              setPaymentSlipToAttachLinks(paymentSlip);
              setShouldOpenLinkInvoiceModal(true);
            },
            text: "Vincular nota fiscal",
          }}
        />
      </span>
    ) : (
      <span>
        <p>Nenhuma nota vinculada</p>
        <button
          role="button"
          onClick={() => {
            setPaymentSlipToAttachLinks(paymentSlip);
            setShouldOpenLinkInvoiceModal(true);
          }}
        >
          <UilLink />
          &nbsp;Vincular
        </button>
      </span>
    );
  };

  const secondRowDataFormatter = (invoice: TPaymentSlip) => ({
    style: "miscellaneous",
    values: [
      {
        style: "default",
        text: "Linha digitável: ",
        shouldBreakLine: false,
      },
      {
        style: "faded",
        text:
          invoice.bar_code.length > 0 ? invoice.bar_code : "(Não identificada)",
        shouldBreakLine: false,
      },
    ],
  });

  const handleCalendarOnSelect = (selected: string[]) => {
    const select = selected as TCalendarSlipMenuOptions[];

    const searchString = handlePopulateQuerystring();

    if (CALENDAR_SLIP_CONTENT_OPTIONS.includes(select[0])) {
      const tempStartDate = handleParseToDateCalendarOptions(select[0]);

      if (selectOrganization) {
        if (tempStartDate === undefined) {
          startDateRef.current = undefined;
          endDateRef.current = undefined;

          handleSetDataToLocalStorage(
            localStorageConstants.INVOICE_FILTER_START_DATE,
            undefined
          );

          handleSetDataToLocalStorage(
            localStorageConstants.INVOICE_FILTER_END_DATE,
            undefined
          );

          searchString.delete("start_date");
          searchString.delete("end_date");

          searchString.set("period", "all-time");

          updateAllPaymentSlipsRef();
          fetchPaymentSlipsByAllEntryStatus();
          navigate("./" + handleSortQuerystring(searchString));
        } else {
          const formatTempStartDate = format(tempStartDate, "yyyy-MM-dd");
          if (formatTempStartDate !== startDateRef.current) {
            const formatEndDate = handleSetEndDate(select[0]);

            startDateRef.current = formatTempStartDate;
            if (formatEndDate !== endDateRef.current)
              endDateRef.current = formatEndDate;

            handleSetDataToLocalStorage(
              localStorageConstants.INVOICE_FILTER_START_DATE,
              formatTempStartDate
            );

            handleSetDataToLocalStorage(
              localStorageConstants.INVOICE_FILTER_END_DATE,
              formatEndDate
            );

            searchString.set(
              "period",
              handleTranslateCalendarOptions(
                select[0] as TCalendarMenuOptions
              ) as string
            );

            searchString.delete("start_date");
            searchString.delete("end_date");

            updateAllPaymentSlipsRef(undefined, formatEndDate);
            fetchPaymentSlipsByAllEntryStatus(formatEndDate);
            navigate("./" + handleSortQuerystring(searchString));
          }
        }
      }
    } else {
      const dates = select[0].split(" - ");
      const tempStartDate = format(
        parse(dates[0], "dd/MM/yyyy", new Date()),
        "yyyy-MM-dd"
      );
      const tempEndDate = format(
        parse(dates[1], "dd/MM/yyyy", new Date()),
        "yyyy-MM-dd"
      );

      handleSetDataToLocalStorage(
        localStorageConstants.INVOICE_FILTER_START_DATE,
        tempStartDate
      );

      handleSetDataToLocalStorage(
        localStorageConstants.INVOICE_FILTER_END_DATE,
        tempEndDate
      );

      if (tempStartDate !== startDateRef.current)
        startDateRef.current = tempStartDate;
      if (tempEndDate !== endDateRef.current) endDateRef.current = tempEndDate;

      searchString.set("end_date", tempEndDate);
      searchString.set("start_date", tempStartDate);

      navigate("./" + handleSortQuerystring(searchString));

      setTimeout(() => {
        updateAllPaymentSlipsRef();
        fetchPaymentSlipsByAllEntryStatus();
      }, 100);
    }
  };

  const handleEntryStatusCustomFilter = (
    index: string,
    _selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
    selectDataRef: React.MutableRefObject<string[]>,
    prevSelectDataRef: React.MutableRefObject<string[]>,
    setShouldUpdate: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    let prevSelect = [];
    if (goodsEntryRef.current) {
      prevSelect.push("2");
    }
    if (payablesRef.current) {
      prevSelect.push("1");
    }
    if (payablesRef.current === false && goodsEntryRef.current === false) {
      prevSelect = ["0"];
    }

    prevSelectDataRef.current = prevSelect.join().split(",");

    setSelected((prev) => {
      let value = prev;
      if (value.includes(index)) {
        const elementToRemoveIndex = value.indexOf(index);
        value.splice(elementToRemoveIndex, 1);
      } else if (prevSelectDataRef.current.includes(index)) {
        const elementToRemoveIndex = prevSelectDataRef.current.indexOf(index);
        prevSelectDataRef.current.splice(elementToRemoveIndex, 1);
        value = [...prev, ...prevSelectDataRef.current];
      } else {
        value.push(index);
      }

      if (value.length > 1 && value.includes("0")) {
        if (index === "0") value = ["0"];
        else {
          value.splice(value.indexOf("0"), 1);
        }
      }

      if (value.includes("")) value.splice(0, 1);

      selectDataRef.current = value;
      setShouldUpdate(true);
      setTimeout(() => {
        setShouldUpdate(false);
      }, 20);

      return value;
    });
  };

  const handleEntryStatusOnAction = (
    selectDataRef: React.MutableRefObject<string[]>
  ) => {
    let hasPayable, hasGoods;

    const queryStrings = handleParseQuerystrings(window.location.search);

    const searchString = handlePopulateQuerystring();

    if (queryStrings.organization_id) {
      searchString.set("organization_id", queryStrings.organization_id[0]);
    }

    if (selectDataRef.current.length) {
      if (selectDataRef.current.includes("2")) {
        hasGoods = true;
      }
      if (selectDataRef.current.includes("1")) {
        hasPayable = true;
      }
      if (
        selectDataRef.current.includes("0") &&
        selectDataRef.current.length === 1
      ) {
        hasPayable = false;
        hasGoods = false;
      }
      payablesRef.current = hasPayable;
      goodsEntryRef.current = hasGoods;

      if (hasPayable !== undefined)
        searchString.set("has_accounts_payable", `${payablesRef.current}`);

      if (hasGoods !== undefined)
        searchString.set("has_goods_receipt", `${goodsEntryRef.current}`);

      if (entryStatusRef.current) {
        searchString.set("launch_status", `${entryStatusRef.current ?? 40}`);
      } /* else {
        searchString.delete("launch_status");
      } */

      navigate("./" + handleSortQuerystring(searchString));
    } else {
      payablesRef.current = undefined;
      goodsEntryRef.current = undefined;

      searchString.delete("has_accounts_payable");
      searchString.delete("has_goods_receipt");

      if (entryStatusRef.current) {
        searchString.set("launch_status", `${entryStatusRef.current ?? 40}`);
      } /* else {
        searchString.delete("launch_status");
      } */

      navigate("./" + handleSortQuerystring(searchString));
    }
    updateAllPaymentSlipsRef();
    fetchPaymentSlipsByAllEntryStatus();
  };

  const handleEntryStatusOnCancel = () => {
    // payablesRef.current = undefined;
    // goodsEntryRef.current = undefined;
    // setShouldRejectFilledEntryStatus(true);
    // navigate("./");
    // fetchPaymentSlipsByAllEntryStatus();
  };

  const fetchInvoicesByAllEntryStatus = async () => {
    if (selectOrganization && cancelTokenSource.current) {
      // let entryStatus1, entryStatus2;

      // switch (entryStatusRef.current) {
      //   case 10:
      //     entryStatus1 = 20;
      //     entryStatus2 = 30;
      //     break;
      //   case 20:
      //     entryStatus1 = 10;
      //     entryStatus2 = 30;
      //     break;
      //   case 30:
      //     entryStatus1 = 20;
      //     entryStatus2 = 10;
      //     break;
      // }

      // await dispatch(
      //   fetchInvoices({
      //     // page: pageRef.current,
      //     organizationUuid: selectOrganization.uuid,
      //     dateIssueStart: startDateRef.current,
      //     dateIssueEnd: endDateRef.current,
      //     launch_status: entryStatus1 as TInvoiceEntryStatus,
      //     has_accounts_payable: payablesRef.current,
      //     has_goods_receipt: goodsEntryRef.current,
      //     search: searchRef.current,
      //     cancelToken: cancelTokenSource.current.token,
      //   })
      // );
      // await dispatch(
      //   fetchInvoices({
      //     // page: pageRef.current,
      //     organizationUuid: selectOrganization.uuid,
      //     dateIssueStart: startDateRef.current,
      //     dateIssueEnd: endDateRef.current,
      //     launch_status: entryStatus2 as TInvoiceEntryStatus,
      //     has_accounts_payable: payablesRef.current,
      //     has_goods_receipt: goodsEntryRef.current,
      //     search: searchRef.current,
      //     cancelToken: cancelTokenSource.current.token,
      //   })
      // );
      setTimeout(async () => {
        if (cancelTokenSource.current)
          await dispatch(
            fetchInvoices({
              page: pageRef.current,
              per_page: itemsPerPageRef.current,
              organizationUuid: selectOrganization.uuid,
              dateIssueStart: startDateRef.current,
              dateIssueEnd: endDateRef.current,
              launch_status: entryStatusRef.current,
              has_accounts_payable: payablesRef.current,
              has_goods_receipt: goodsEntryRef.current,
              search: searchRef.current,
              cancelToken: cancelTokenSource.current.token,
            })
          ).then(() => {
            apiService
              .getInvoiceUpdatedAt(
                selectOrganization.uuid,
                (cancelTokenSource.current as CancelTokenSource).token
              )
              .then((response) => {
                if (response) {
                  dispatch(
                    setInvoicesUpdatedAt({
                      responseStatus: response.status,
                      date: response.data.updated_at,
                    })
                  );
                }
              });
          });
      }, 50);
    }
  };

  const fetchPaymentSlipsByAllEntryStatus = async (endDate?: string) => {
    if (selectOrganization && cancelTokenSource.current) {
      // let entryStatus1, entryStatus2;

      // switch (entryStatusRef.current) {
      //   case 10:
      //     entryStatus1 = 20;
      //     entryStatus2 = 30;
      //     break;
      //   case 20:
      //     entryStatus1 = 10;
      //     entryStatus2 = 30;
      //     break;
      //   case 30:
      //     entryStatus1 = 20;
      //     entryStatus2 = 10;
      //     break;
      // }

      // await dispatch(
      //   fetchPaymentSlips({
      //     // page: pageRef.current,
      //     organizationUuid: selectOrganization.uuid,
      //     dateIssueStart: startDateRef.current,
      //     dateIssueEnd: endDateRef.current,
      //     launch_status: entryStatus1 as TInvoiceEntryStatus,
      //     has_accounts_payable: payablesRef.current,
      //     has_goods_receipt: goodsEntryRef.current,
      //     search: searchRef.current,
      //     cancelToken: cancelTokenSource.current.token,
      //   })
      // );
      // await dispatch(
      //   fetchPaymentSlips({
      //     // page: pageRef.current,
      //     organizationUuid: selectOrganization.uuid,
      //     dateIssueStart: startDateRef.current,
      //     dateIssueEnd: endDateRef.current,
      //     launch_status: entryStatus2 as TInvoiceEntryStatus,
      //     has_accounts_payable: payablesRef.current,
      //     has_goods_receipt: goodsEntryRef.current,
      //     search: searchRef.current,
      //     cancelToken: cancelTokenSource.current.token,
      //   })
      // );
      // setShouldUpdateComponent(true);
      setTimeout(async () => {
        if (cancelTokenSource.current)
          await dispatch(
            fetchPaymentSlips({
              page: pageRef.current,
              organizationUuid: selectOrganization.uuid,
              dateIssueStart: startDateRef.current,
              dateIssueEnd: endDate ?? endDateRef.current,
              launch_status: entryStatusRef.current,
              has_accounts_payable: payablesRef.current,
              has_goods_receipt: goodsEntryRef.current,
              search: searchRef.current,
              cancelToken: cancelTokenSource.current.token,
            })
          ).then(() => {
            apiService
              .getPaymentSlipUpdatedAt(
                selectOrganization.uuid,
                (cancelTokenSource.current as CancelTokenSource).token
              )
              .then((response) => {
                dispatch(
                  setPaymentSlipsUpdatedAt({
                    responseStatus: response.status,
                    date: response.data.updated_at,
                  })
                );
              });
          });
        setShouldUpdateComponent(false);
      }, 50);
    }
  };

  const handleEntryStatusForceSelectOptions = () => {
    const queryStrings = handleParseQuerystrings(window.location.search);

    const hasPayableCondition =
      queryStrings.has_accounts_payable &&
      queryStrings.has_accounts_payable[0] === "true";

    const hasGoodsCondition =
      queryStrings.has_goods_receipt &&
      queryStrings.has_goods_receipt[0] === "true";

    const hasNotEntryCondition =
      queryStrings.has_accounts_payable &&
      queryStrings.has_accounts_payable[0] === "false" &&
      queryStrings.has_goods_receipt &&
      queryStrings.has_goods_receipt[0] === "false";

    if (hasNotEntryCondition) {
      payablesRef.current = false;
      goodsEntryRef.current = false;
      return [0];
    }

    if (hasPayableCondition && hasGoodsCondition) {
      payablesRef.current = true;
      goodsEntryRef.current = true;

      return [1, 2];
    }

    if (hasPayableCondition) {
      payablesRef.current = true;
      goodsEntryRef.current = undefined;

      return [1];
    }

    if (hasGoodsCondition) {
      goodsEntryRef.current = true;
      payablesRef.current = undefined;

      return [2];
    }

    if (queryStrings.start_date && queryStrings.end_date) {
      // const temp1 = parse(queryStrings.start_date[0], "dd/MM/yyyy", new Date());
      // const temp2 = parse(queryStrings.end_date[0], "dd/MM/yyyy", new Date());

      // startDateRef.current = format(temp1, "yyyy-MM-dd");
      // endDateRef.current = format(temp2, "yyyy-MM-dd");

      startDateRef.current = queryStrings.start_date[0];
      endDateRef.current = queryStrings.end_date[0];
    }

    if (queryStrings.search) searchRef.current = queryStrings.search[0];

    return undefined;
  };

  const handlePeriodForceSelectOptions = () => {
    const queryStrings = handleParseQuerystrings(window.location.search);

    if (queryStrings.start_date && queryStrings.end_date) {
      // const temp1 = parse(queryStrings.start_date[0], "dd/MM/yyyy", new Date());
      // const temp2 = parse(queryStrings.end_date[0], "dd/MM/yyyy", new Date());

      // startDateRef.current = format(temp1, "yyyy-MM-dd");
      // endDateRef.current = format(temp2, "yyyy-MM-dd");

      startDateRef.current = queryStrings.start_date[0];
      endDateRef.current = queryStrings.end_date[0];

      return [
        queryStrings.start_date[0].replace(
          /(\d{4})-(\d{2})-(\d{2})/,
          "$3/$2/$1"
        ),
        queryStrings.end_date[0].replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1"),
      ];
    }

    const calendarOptions = [
      ...CALENDAR_SLIP_CONTENT_OPTIONS.map((string) => string.toLowerCase()),
    ];

    if (
      queryStrings.period &&
      calendarOptions.includes(
        handleRevertTranslateCalendarOptions(
          queryStrings.period[0] as TCalendarMenuOptions
        ) as string
      )
    ) {
      // console.log("force select options: ", [
      //   calendarOptions.indexOf(
      //     handleRevertTranslateCalendarOptions(
      //       queryStrings.period[0] as TCalendarMenuOptions
      //     ) as string
      //   ),
      // ]);
      return [
        calendarOptions.indexOf(
          handleRevertTranslateCalendarOptions(
            queryStrings.period[0] as TCalendarMenuOptions
          ) as string
        ),
      ];
    }

    return undefined;
  };

  const updateAllPaymentSlipsRef = useCallback(
    async (entryStatus?: number, endDate?: string) => {
      if (selectOrganization) {
        cancelTokenSource.current = axios.CancelToken.source();

        const queryStringParams = getQuerystringParams();

        endDateRef.current = endDate ?? queryStringParams.endDate;
        startDateRef.current = queryStringParams.startDate;
        payablesRef.current = queryStringParams.hasPayable;
        goodsEntryRef.current = queryStringParams.hasGoods;
        pageRef.current = queryStringParams.page;
        itemsPerPageRef.current = queryStringParams.itemsPerPage;
        entryStatusRef.current = queryStringParams.launchStatus as
          | TInvoiceEntryStatus
          | undefined;

        await apiService
          .getAllPaymentSlips(
            {
              organizationUuid: selectOrganization.uuid,
              launch_status:
                (entryStatus as TInvoiceEntryStatus) ?? entryStatusRef.current,
              due_date_after: startDateRef.current,
              due_date_before: endDateRef.current,
              has_accounts_payable: payablesRef.current,
              has_goods_receipt: goodsEntryRef.current,
              page: pageRef.current,
              per_page: itemsPerPageRef.current,
            },
            (cancelTokenSource.current as CancelTokenSource).token
          )
          .then((response) => {
            allPaymentSlipsRef.current = response;

            setShouldUpdateComponent(true);
            setTimeout(() => {
              // console.log(
              //   "updating component, all invoices ref: ",
              //   allPaymentSlipsRef.current
              // );
              setShouldUpdateComponent(false);
            }, 40);
          });
      }
    },
    [selectOrganization, window.location.search]
  );

  const handleFileChange = () => {
    if (selectOrganization) {
      const globalErrors: string[] = [];

      const handleImport = (file: File) => {
        const formData = new FormData();
        formData.append("boleto", file);
        formData.append("file_name", file.name);

        apiService
          .paymentSlipImportPDF(
            selectOrganization.uuid,
            formData,
            (cancelTokenSource.current as CancelTokenSource).token
          )
          .then(() => {
            if (
              globalErrors.length === 0 &&
              fileInputRef.current &&
              fileInputRef.current.files
            ) {
              dispatch(
                setToastState({
                  variant: "success",
                  shouldShow: true,
                  message: {
                    title: "Sucesso",
                    description:
                      fileInputRef.current.files.length > 1
                        ? "Documentos importados"
                        : "Documento importado",
                  },
                })
              );
            }
          })
          .catch((e) => {
            const errorMessage = backendErrorMessageHandler(e);

            if (typeof errorMessage === "string") {
              globalErrors.push(errorMessage);
            }

            dispatch(
              setToastState({
                variant: "error",
                shouldShow: true,
                message: {
                  title: "Erro",
                  description: globalErrors.join("\n"),
                  // typeof errorMessage === "string"
                  //   ? errorMessage
                  //   : "Não foi possível importar o documento, tente novamente",
                },
              })
            );
          });
      };

      if (fileInputRef.current && fileInputRef.current.files) {
        const pdfFiles: File[] = [];

        Array.from(fileInputRef.current.files).forEach((file) => {
          if (file.type.includes("pdf")) {
            pdfFiles.push(file);
          }
        });

        Promise.all(
          pdfFiles.map((pdf) => {
            handleImport(pdf);
          })
        ).then(() => {
          setTimeout(() => {
            fetchPaymentSlipsByAllEntryStatus();
            updateAllPaymentSlipsRef();
          }, 1000);
        });
      }
    }
  };

  useEffect(() => {
    updateAllPaymentSlipsRef();
  }, [updateAllPaymentSlipsRef]);

  useEffect(() => {}, [
    paymentSlipState,
    selectedOrganizationWithPermissions,
    invoicesUpdatedAt,
    attachedInvoices,
  ]);

  useEffect(() => {
    updateAllPaymentSlipsRef();

    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  return (
    <Container>
      <SummarySection>
        <div>
          <div>
            <h1>Boletos</h1>
            <p>
              <b>CNPJ:&nbsp;</b>
              {selectOrganization && cnpjMask(selectOrganization.cnpj)}
            </p>
          </div>
          {invoicesUpdatedAt.responseStatus !== 204 &&
            invoicesUpdatedAt.date.length > 0 && (
              <p>
                Atualizado em {format(invoicesUpdatedAt.date, "dd/MM/yyyy")} -{" "}
                {format(invoicesUpdatedAt.date, "HH:mm")}
              </p>
            )}
        </div>
        <div className="buttons-container">
          {/* <Button
            icon="noIcon"
            variant="outline"
            text="Notas Fiscais"
            onClick={() => {
              navigate(ROUTES.HOME + window.location.search);
            }}
          /> */}
          <div></div>
          <Button
            icon="plus"
            iconPosition="left"
            variant="primary"
            text="Importar Boleto"
            onClick={() => {
              if (fileInputRef.current) fileInputRef.current.click();
            }}
          />
          <input
            type="file"
            ref={fileInputRef}
            multiple
            accept=".pdf"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
      </SummarySection>
      <SearchBarSection>
        <SearchBar
          // timeoutAmount={1000}
          onClear={() => {
            searchRef.current = undefined;
            if (selectOrganization) {
              const queryStrings = handleParseQuerystrings(
                window.location.search
              );
              const searchString = handlePopulateQuerystring();
              if (queryStrings.organization_id) {
                searchString.set(
                  "organization_id",
                  queryStrings.organization_id[0]
                );
              }
              searchString.delete("search");

              if (entryStatusRef.current) {
                searchString.set(
                  "launch_status",
                  `${entryStatusRef.current ?? 40}`
                );
              } /* else {
                searchString.delete("launch_status");
              } */

              navigate("./" + handleSortQuerystring(searchString));

              fetchPaymentSlipsByAllEntryStatus();

              // dispatch(
              //   fetchInvoices({
              //     search: searchRef.current,
              //     // page: pageRef.current,
              //     organizationUuid: selectOrganization.uuid,
              //     dateIssueStart: startDateRef.current,
              //     dateIssueEnd: endDateRef.current,
              //     has_accounts_payable: payablesRef.current,
              //     has_goods_receipt: goodsEntryRef.current,
              //     launch_status: entryStatusRef.current,
              //   })
              // );
            }
          }}
          $ref={(ref, setSearchString) => {
            const queryStrings = handleParseQuerystrings(
              window.location.search
            );
            if (queryStrings.search && ref.current) {
              ref.current.value = queryStrings.search[0];
              setSearchString(queryStrings.search[0]);
            }
          }}
          onSearch={(searchPhrase) => {
            searchRef.current = searchPhrase;
            // setTimeout(() => {
            if (selectOrganization) {
              const queryStrings = handleParseQuerystrings(
                window.location.search
              );
              const searchString = handlePopulateQuerystring();
              if (queryStrings.organization_id) {
                searchString.set(
                  "organization_id",
                  queryStrings.organization_id[0]
                );
              }
              if (searchRef.current.length > 0) {
                searchString.set("search", searchRef.current);
              } else {
                searchString.delete("search");
              }

              if (entryStatusRef.current) {
                searchString.set(
                  "launch_status",
                  `${entryStatusRef.current ?? 40}`
                );
              } /* else {
                searchString.delete("launch_status");
              } */

              navigate("./" + handleSortQuerystring(searchString));

              fetchPaymentSlipsByAllEntryStatus();

              // dispatch(
              //   fetchInvoices({
              //     search: searchRef.current,
              //     // page: pageRef.current,
              //     organizationUuid: selectOrganization.uuid,
              //     dateIssueStart: startDateRef.current,
              //     dateIssueEnd: endDateRef.current,
              //     has_accounts_payable: payablesRef.current,
              //     has_goods_receipt: goodsEntryRef.current,
              //     launch_status: entryStatusRef.current,
              //   })
              // );
            }
            // }, 500);
          }}
          variant="default"
          placeholder="Pesquisar"
        />
        <SearchBarSectionSubcontainer id={searchBarContainerId}>
          <DropdownFilter
            buttonVariant="calendar"
            menuVariant="calendar-slip"
            onSelect={handleCalendarOnSelect}
            // key={shouldRejectFilledEntryStatus + ""}
            forceSelectOptionsByIndex={(() => {
              const result = handlePeriodForceSelectOptions();
              return shouldRejectFilledEntryStatus ||
                (result && typeof result[0] === "string")
                ? []
                : (result as number[]);
            })()}
            filled={(() => {
              const result = handlePeriodForceSelectOptions();

              return result === undefined
                ? result
                : typeof result[0] === "string"
                ? (result as string[])
                : [CALENDAR_SLIP_CONTENT_OPTIONS[result[0]]];
            })()}
          />
          <DropdownFilter
            buttonVariant="checkCircle"
            placeholder="Lançamentos"
            content={entryStatusOptions}
            menuVariant="unlabeled"
            search={false}
            customFilter={handleEntryStatusCustomFilter}
            onAction={handleEntryStatusOnAction}
            onCancel={handleEntryStatusOnCancel}
            hasBottomBar
            key={shouldRejectFilledEntryStatus + ""}
            forceSelectOptionsByIndex={(() => {
              const result = handleEntryStatusForceSelectOptions();
              return shouldRejectFilledEntryStatus ? undefined : result;
            })()}
            filled={(() => {
              const result = handleEntryStatusForceSelectOptions();
              return result === undefined
                ? result
                : [...result.toString().replaceAll(",", "")];
            })()}
          />
        </SearchBarSectionSubcontainer>
      </SearchBarSection>
      <MobileSupportContainer>
        <TabsGroup
          initialActiveTabIndex={(() => {
            const queryStrings = handleParseQuerystrings(
              window.location.search
            );

            if (queryStrings.launch_status) {
              switch (queryStrings.launch_status[0]) {
                case "10":
                  entryStatusRef.current = 10;
                  return 1;
                case "20":
                  entryStatusRef.current = 20;
                  return 2;
                case "30":
                  entryStatusRef.current = 30;
                  return 3;
                case "40":
                  entryStatusRef.current = undefined;
                  return 0;
              }
            }

            return 1;
          })()}
          onClickItems={[
            () => {
              handleSetDataToLocalStorage(
                localStorageConstants.CURRENT_ENTRY_STATUS,
                40
              );

              const searchString = handlePopulateQuerystring();
              if (selectOrganization)
                searchString.set("organization_id", selectOrganization.uuid);
              searchString.set("launch_status", `40`);
              entryStatusRef.current = undefined;
              navigate("./" + handleSortQuerystring(searchString));

              const queryStringParams = getQuerystringParams();

              startDateRef.current = queryStringParams.startDate;
              endDateRef.current = queryStringParams.endDate;
              itemsPerPageRef.current = queryStringParams.itemsPerPage;
              pageRef.current = queryStringParams.page;
              searchRef.current = queryStringParams.search;
              payablesRef.current = queryStringParams.hasPayable;
              goodsEntryRef.current = queryStringParams.hasGoods;

              updateAllPaymentSlipsRef(entryStatusRef.current);
              if (selectOrganization && cancelTokenSource.current) {
                dispatch(
                  fetchPaymentSlips({
                    search: searchRef.current,
                    page: pageRef.current,
                    per_page: itemsPerPageRef.current,
                    organizationUuid: selectOrganization.uuid,
                    dateIssueStart: startDateRef.current,
                    dateIssueEnd: endDateRef.current,
                    has_accounts_payable: payablesRef.current,
                    has_goods_receipt: goodsEntryRef.current,
                    cancelToken: cancelTokenSource.current.token,
                  })
                );
              }
            },
            () => {
              if (selectOrganization) {
                updateAllPaymentSlipsRef(10);

                handleSetDataToLocalStorage(
                  localStorageConstants.CURRENT_ENTRY_STATUS,
                  10
                );

                const searchString = handlePopulateQuerystring();
                if (selectOrganization)
                  searchString.set("organization_id", selectOrganization.uuid);

                entryStatusRef.current = 10;
                searchString.set("launch_status", `${entryStatusRef.current}`);
                navigate("./" + handleSortQuerystring(searchString));

                const queryStringParams = getQuerystringParams();

                startDateRef.current = queryStringParams.startDate;
                endDateRef.current = queryStringParams.endDate;
                itemsPerPageRef.current = queryStringParams.itemsPerPage;
                pageRef.current = queryStringParams.page;
                searchRef.current = queryStringParams.search;
                payablesRef.current = queryStringParams.hasPayable;
                goodsEntryRef.current = queryStringParams.hasGoods;

                if (cancelTokenSource.current)
                  dispatch(
                    fetchPaymentSlips({
                      search: searchRef.current,
                      page: pageRef.current,
                      per_page: itemsPerPageRef.current,
                      organizationUuid: selectOrganization.uuid,
                      launch_status: 10,
                      dateIssueStart: startDateRef.current,
                      dateIssueEnd: endDateRef.current,
                      has_accounts_payable: payablesRef.current,
                      has_goods_receipt: goodsEntryRef.current,
                      cancelToken: cancelTokenSource.current.token,
                    })
                  );
              }
            },
            () => {
              if (selectOrganization) {
                updateAllPaymentSlipsRef(20);

                handleSetDataToLocalStorage(
                  localStorageConstants.CURRENT_ENTRY_STATUS,
                  20
                );

                const searchString = handlePopulateQuerystring();
                if (selectOrganization)
                  searchString.set("organization_id", selectOrganization.uuid);
                entryStatusRef.current = 20;
                searchString.set("launch_status", `${entryStatusRef.current}`);
                navigate("./" + handleSortQuerystring(searchString));

                const queryStringParams = getQuerystringParams();

                startDateRef.current = queryStringParams.startDate;
                endDateRef.current = queryStringParams.endDate;
                itemsPerPageRef.current = queryStringParams.itemsPerPage;
                pageRef.current = queryStringParams.page;
                searchRef.current = queryStringParams.search;
                payablesRef.current = queryStringParams.hasPayable;
                goodsEntryRef.current = queryStringParams.hasGoods;

                if (cancelTokenSource.current)
                  dispatch(
                    fetchPaymentSlips({
                      search: searchRef.current,
                      page: pageRef.current,
                      per_page: itemsPerPageRef.current,
                      organizationUuid: selectOrganization.uuid,
                      launch_status: 20,
                      dateIssueStart: startDateRef.current,
                      dateIssueEnd: endDateRef.current,
                      has_accounts_payable: payablesRef.current,
                      has_goods_receipt: goodsEntryRef.current,
                      cancelToken: cancelTokenSource.current.token,
                    })
                  );
              }
            },
            () => {
              if (selectOrganization) {
                updateAllPaymentSlipsRef(30);

                handleSetDataToLocalStorage(
                  localStorageConstants.CURRENT_ENTRY_STATUS,
                  30
                );

                const searchString = handlePopulateQuerystring();
                if (selectOrganization)
                  searchString.set("organization_id", selectOrganization.uuid);

                entryStatusRef.current = 30;
                searchString.set("launch_status", `${entryStatusRef.current}`);
                navigate("./" + handleSortQuerystring(searchString));

                const queryStringParams = getQuerystringParams();

                startDateRef.current = queryStringParams.startDate;
                endDateRef.current = queryStringParams.endDate;
                itemsPerPageRef.current = queryStringParams.itemsPerPage;
                pageRef.current = queryStringParams.page;
                searchRef.current = queryStringParams.search;
                payablesRef.current = queryStringParams.hasPayable;
                goodsEntryRef.current = queryStringParams.hasGoods;

                if (cancelTokenSource.current)
                  dispatch(
                    fetchPaymentSlips({
                      search: searchRef.current,
                      page: pageRef.current,
                      per_page: itemsPerPageRef.current,
                      organizationUuid: selectOrganization.uuid,
                      launch_status: 30,
                      dateIssueStart: startDateRef.current,
                      dateIssueEnd: endDateRef.current,
                      has_accounts_payable: payablesRef.current,
                      has_goods_receipt: goodsEntryRef.current,
                      cancelToken: cancelTokenSource.current.token,
                    })
                  );
              }
            },
          ]}
          stringIcon={[
            { index: 1, value: `${pendingEntryStatusCount}` },
            { index: 2, value: `${doneEntryStatusCount}` },
            { index: 3, value: `${rejectEntryStatusCount}` },
          ]}
        >
          <>
            <h2>Todas</h2>
            <div></div>
          </>
          <>
            <h2>Pendentes</h2>
          </>
          <>
            <h2>Finalizadas</h2>
            <div></div>
          </>
          <>
            <h2>Recusadas</h2>
            <div></div>
          </>
        </TabsGroup>
      </MobileSupportContainer>

      {paymentSlipState && (
        <TableGrid
          key={shouldUpdateComponent + "-paymentSlip-tablegrid"}
          row="doubleRow"
          columns={{
            tableHead: {
              // 22px 77px 160px 342px 356px 185px 346px 400px 400px 0px
              width: [
                `${(24 * 10) / 258}vw ${(100 * 10) / 258}vw ${
                  (330 * 10) / 258
                }vw ${(453 * 10) / 258}vw ${(462 * 10) / 258}vw ${
                  (326 * 10) / 258
                }vw ${(301 * 10) / 258}vw ${(304 * 10) / 258}vw ${
                  (100 * 10) / 258
                }vw`,
              ],
            },
            tableBody: {
              width: [
                `${(24 * 10) / 258}vw ${(100 * 10) / 258}vw ${
                  (330 * 10) / 258
                }vw ${(453 * 10) / 258}vw ${(462 * 10) / 258}vw ${
                  (326 * 10) / 258
                }vw ${(301 * 10) / 258}vw ${(304 * 10) / 258}vw ${
                  (100 * 10) / 258
                }vw`,
              ],
            },
          }}
          onAction={() => {
            window.location.reload();
          }}
          emptyState={{
            isEmptyState: paymentSlipState?.results.length === 0,
            title: "Nenhum boleto encontrado",
            description: "Altere os filtros e realize a busca novamente",
          }}
          secondRow={{
            params: (paymentSlipState?.results ?? []).map(
              (paymentSlip, index) => ({
                data: secondRowDataFormatter(
                  paymentSlip
                ) as TCellNotTagMiscellaneous,
                leftSideCustomNode: (
                  <>
                    {paymentSlip.bar_code.length > 0 &&
                      handleSecondRowLeftSideCustomNodeRender(
                        paymentSlip,
                        index
                      )}
                  </>
                ),
                rightSideCustomNode: (
                  <>
                    {handleSecondRowRightSideCustomNodeRender(
                      paymentSlip,
                      index
                    )}
                  </>
                ),
              })
            ),
          }}
          selectType={{
            type: "multiSelect",
            fullData: allPaymentSlipsRef.current,
          }}
          tableHead={{
            value: [
              { style: "noIcon", value: "Enviado em" },
              { style: "noIcon", value: "Origem" },
              { style: "noIcon", value: "Emitente" },
              { style: "noIcon", value: "Valor" },
              {
                style: "noIcon",
                value: "Vencimento",
              },
              { style: "noIcon", value: "Lançamento" },
              { style: "noIcon", value: "Opções" },
            ],
          }}
          tableBody={(paymentSlipState?.results ?? []).map(
            (paymentSlip: TPaymentSlip, index: number) => ({
              elementId: paymentSlip.uuid,
              file_nfe: null,
              file_xml: null,
              file_boleto: paymentSlip.file_boleto,
              body: rowFormatter(paymentSlip, index),
            })
          )}
          footer={{
            // pagination: {
            //   buttonsAmount: 30,
            //   onClick(activeIndex) {
            //     // console.log("active index: ", activeIndex);
            //   },
            // }
            leftSideDescription:
              " | Total de " +
              paymentSlipState.count +
              (paymentSlipState.count > 1
                ? " registros encontrados"
                : " registro encontrado"),
            // description:
            //   "Mostrando " +
            //   paymentSlipState.results.length +
            //   " de " +
            //   paymentSlipState.results.length +
            //   " resultado" +
            //   (paymentSlipState.results.length > 1 ? "s" : ""),
            onSelectItemsPerPage(amount) {
              const searchString = handlePopulateQuerystring();
              const queryStrings = handleParseQuerystrings(
                window.location.search
              );

              if (queryStrings.page) {
                pageRef.current = parseInt(queryStrings.page[0]);
              }

              if (pageRef.current)
                searchString.set("page", pageRef.current.toString());

              searchString.set("items_per_page", amount.toString());

              navigate("./" + handleSortQuerystring(searchString));

              if (selectOrganization && cancelTokenSource.current) {
                dispatch(
                  fetchPaymentSlips({
                    search: searchRef.current,
                    page: pageRef.current,
                    per_page: amount,
                    organizationUuid: selectOrganization.uuid,
                    launch_status: entryStatusRef.current,
                    dateIssueStart: startDateRef.current,
                    dateIssueEnd: endDateRef.current,
                    has_accounts_payable: payablesRef.current,
                    has_goods_receipt: goodsEntryRef.current,
                    cancelToken: cancelTokenSource.current.token,
                  })
                );
              }
            },
            customPagination: paymentSlipState
              ? {
                  isFixedAtTheBottom: true,
                  currentPage: `${paymentSlipState?.current_page}`,
                  buttonBackward: {
                    disabled: paymentSlipState.current_page === 1,
                    onClick() {
                      // if (paymentSlipState.current_page === 1) {
                      //   pageRef.current = undefined;
                      // } else {
                      pageRef.current = paymentSlipState.current_page - 1;
                      // }

                      const queryStrings = handleParseQuerystrings(
                        window.location.search
                      );

                      if (queryStrings.items_per_page) {
                        itemsPerPageRef.current = parseInt(
                          queryStrings.items_per_page[0]
                        );
                      }

                      handleSetDataToLocalStorage(
                        localStorageConstants.CURRENT_PAGE,
                        pageRef.current
                      );

                      if (
                        selectOrganization &&
                        paymentSlipState.current_page !== 1 &&
                        cancelTokenSource.current
                      ) {
                        const searchString = handlePopulateQuerystring();
                        if (pageRef.current)
                          searchString.set("page", pageRef.current.toString());
                        navigate("./" + handleSortQuerystring(searchString));

                        dispatch(
                          fetchPaymentSlips({
                            search: searchRef.current,
                            page: pageRef.current,
                            per_page: itemsPerPageRef.current,
                            organizationUuid: selectOrganization.uuid,
                            launch_status: entryStatusRef.current,
                            dateIssueStart: startDateRef.current,
                            dateIssueEnd: endDateRef.current,
                            has_accounts_payable: payablesRef.current,
                            has_goods_receipt: goodsEntryRef.current,
                            cancelToken: cancelTokenSource.current.token,
                          })
                        );
                      }
                    },
                  },
                  buttonForward: {
                    disabled:
                      paymentSlipState.current_page ===
                      paymentSlipState.pages.length,
                    onClick() {
                      if (
                        paymentSlipState.current_page ===
                        paymentSlipState.pages.length
                      ) {
                        pageRef.current = paymentSlipState.current_page;
                      } else {
                        pageRef.current = paymentSlipState.current_page + 1;
                      }

                      const queryStrings = handleParseQuerystrings(
                        window.location.search
                      );

                      if (queryStrings.items_per_page) {
                        itemsPerPageRef.current = parseInt(
                          queryStrings.items_per_page[0]
                        );
                      }

                      handleSetDataToLocalStorage(
                        localStorageConstants.CURRENT_PAGE,
                        pageRef.current
                      );

                      if (
                        selectOrganization &&
                        paymentSlipState.current_page !==
                          paymentSlipState.pages.length &&
                        cancelTokenSource.current
                      ) {
                        const searchString = handlePopulateQuerystring();
                        if (pageRef.current)
                          searchString.set("page", pageRef.current.toString());
                        navigate("./" + handleSortQuerystring(searchString));

                        dispatch(
                          fetchPaymentSlips({
                            search: searchRef.current,
                            page: pageRef.current,
                            per_page: itemsPerPageRef.current,
                            organizationUuid: selectOrganization.uuid,
                            launch_status: entryStatusRef.current,
                            dateIssueStart: startDateRef.current,
                            dateIssueEnd: endDateRef.current,
                            has_accounts_payable: payablesRef.current,
                            has_goods_receipt: goodsEntryRef.current,
                            cancelToken: cancelTokenSource.current.token,
                          })
                        );
                      }
                    },
                  },
                  pages: paymentSlipState.pages.map((page) => {
                    return {
                      value: Object.keys(page)[0],
                      onClick() {
                        let temp =
                          Object.values(page)[0].match(/.*?page=(\d+)&per_/);

                        if (temp !== null && temp[1]) {
                          pageRef.current = parseInt(temp[1]);
                        } else {
                          pageRef.current = 1;
                        }

                        const queryStrings = handleParseQuerystrings(
                          window.location.search
                        );

                        if (queryStrings.items_per_page) {
                          itemsPerPageRef.current = parseInt(
                            queryStrings.items_per_page[0]
                          );
                        }

                        handleSetDataToLocalStorage(
                          localStorageConstants.CURRENT_PAGE,
                          pageRef.current
                        );

                        if (selectOrganization && cancelTokenSource.current) {
                          const searchString = handlePopulateQuerystring();
                          if (pageRef.current)
                            searchString.set(
                              "page",
                              pageRef.current.toString()
                            );
                          navigate("./" + handleSortQuerystring(searchString));

                          dispatch(
                            fetchPaymentSlips({
                              search: searchRef.current,
                              page: pageRef.current,
                              per_page: itemsPerPageRef.current,
                              organizationUuid: selectOrganization.uuid,
                              launch_status: entryStatusRef.current,
                              dateIssueStart: startDateRef.current,
                              dateIssueEnd: endDateRef.current,
                              has_accounts_payable: payablesRef.current,
                              has_goods_receipt: goodsEntryRef.current,
                              cancelToken: cancelTokenSource.current.token,
                            })
                          );
                        }
                      },
                    };
                  }),
                }
              : undefined,
          }}
        />
      )}

      {shouldOpenPayable && (
        <Modal
          key={shouldOpenPayable + "-payable"}
          shouldOpen={shouldOpenPayable}
          onClose={() => {
            setShouldOpenPayable(false);
          }}
          icon={{ type: "dolarSign", color: "rose" }}
          title="Lançar contas a pagar"
          main={{
            style: "textOnly",
            content: [
              // { style: "default", text: "", shouldBreakLine: true },
              {
                style: "default",
                text: "Deseja criar uma nova conta a pagar ou vincular o boleto a uma conta já criada na Fintera?",
                shouldBreakLine: true,
              },
              // { style: "default", text: "", shouldBreakLine: true },
            ],
          }}
          footer={{
            style: "default",
            buttons: [
              {
                iconPosition: "left",
                icon: "link",
                variant: "outline",
                text: "Vincular",
                onClick() {
                  setShouldOpenPayable(false);
                  setShouldOpenLinkPayableModal(true);
                },
              },
              {
                iconPosition: "left",
                icon: "plus",
                variant: "primary",
                text: "Criar nova",
                onClick() {
                  setShouldOpenPayable(false);
                  setShouldOpenPayableModal(true);
                },
              },
            ],
          }}
        />
      )}

      {shouldOpenPayableModal && (
        <Modal
          key={shouldOpenPayableModal + "-payableModal"}
          shouldOpen={shouldOpenPayableModal}
          icon={{ type: "dolarSign", color: "rose" }}
          title="Lançar como contas a pagar"
          onClose={() => {
            setShouldOpenPayableModal(false);
          }}
          main={{
            style: "form",
            content: {
              preset: "addPaymentSlip",
              paymentSlip: payableModalPaymentSlipRef.current as TPaymentSlip,
              footer: {
                buttons: [
                  {
                    icon: "noIcon",
                    text: "Cancelar",
                    variant: "secondary",
                    onClick() {
                      setShouldOpenPayableModal(false);
                    },
                  },
                  {
                    iconPosition: "left",
                    icon: "checkmark",
                    text: "Lançar conta a pagar",
                    variant: "primary",
                    onClick() {
                      fetchPaymentSlipsByAllEntryStatus();
                      setShouldOpenPayableModal(false);
                    },
                  },
                ],
              },
            },
          }}
        />
      )}
      {shouldOpenLinkPayableModal && (
        <Modal
          key={shouldOpenLinkPayableModal + "-linkPayableModal"}
          shouldOpen={shouldOpenLinkPayableModal}
          icon={{ type: "link", color: "rose" }}
          title="Vincular boleto a conta já lançada"
          onClose={() => {
            setShouldOpenLinkPayableModal(false);
          }}
          main={{
            style: "form",
            content: {
              preset: "linkPayable",
              paymentSlip: payableModalPaymentSlipRef.current as TPaymentSlip,
              footer: {
                buttons: [
                  {
                    icon: "noIcon",
                    text: "Cancelar",
                    variant: "secondary",
                    onClick() {
                      setShouldOpenLinkPayableModal(false);
                    },
                  },
                  {
                    iconPosition: "left",
                    icon: "checkmark",
                    text: "Vincular boleto como anexo",
                    variant: "primary",
                    isLoading: isLinkPayableLoading,

                    onClick(selectRow) {
                      if (selectRow.hasInstallments) {
                        selectPayableRef.current = selectRow.selectPayable;
                        setShouldOpenLinkPayableModal(false);
                        setShouldOpenInstallmentAccountModal(true);
                      } else {
                        if (payableModalPaymentSlipRef.current) {
                          setIsLinkPayableLoading(true);

                          apiService
                            .setLinkPaymentSlipToPayable(
                              payableModalPaymentSlipRef.current.uuid,
                              selectRow.selectPayable.id,
                              (cancelTokenSource.current as CancelTokenSource)
                                .token
                            )
                            .then((_response) => {
                              setIsLinkPayableLoading(false);
                              fetchPaymentSlipsByAllEntryStatus();
                              dispatch(
                                setToastState({
                                  variant: "success",
                                  shouldShow: true,
                                  message: {
                                    title: "Sucesso",
                                    description:
                                      "Boleto vinculado à contas a pagar",
                                  },
                                })
                              );
                              setShouldOpenLinkPayableModal(false);
                            })
                            .catch((e) => {
                              const errorMessage =
                                backendErrorMessageHandler(e);
                              console.log("error: ", e);
                              setIsLinkPayableLoading(false);
                              dispatch(
                                setToastState({
                                  variant: "error",
                                  shouldShow: true,
                                  message: {
                                    title: "Erro ao vincular boleto",
                                    description:
                                      typeof errorMessage === "string"
                                        ? errorMessage
                                        : "Ocorreu um erro ao vincular o boleto à Contas a Pagar, tente novamente",
                                  },
                                })
                              );
                              setShouldOpenLinkPayableModal(false);
                            });
                        }
                      }
                    },
                  },
                ],
              },
            },
          }}
        />
      )}
      {shouldOpenInstallmentAccountModal && (
        <Modal
          shouldOpen={shouldOpenInstallmentAccountModal}
          onClose={() => {
            setShouldOpenInstallmentAccountModal(false);
          }}
          icon={{ type: "link", color: "rose" }}
          title="Vincular à conta parcelada"
          main={{
            style: "form",
            content: {
              preset: "installmentAccount",
              paymentSlip: payableModalPaymentSlipRef.current as TPaymentSlip,
              footer: {
                buttons: [
                  {
                    variant: "secondary",
                    icon: "noIcon",
                    text: "Cancelar",
                    onClick() {
                      setShouldOpenInstallmentAccountModal(false);
                    },
                  },
                  {
                    variant: "primary",
                    iconPosition: "left",
                    icon: "checkmark",
                    text: "Vincular boleto como anexo",
                    isLoading: isLinkPayableLoading,
                    onClick() {
                      if (
                        payableModalPaymentSlipRef.current &&
                        selectPayableRef.current
                      ) {
                        setIsLinkPayableLoading(true);

                        apiService
                          .setLinkPaymentSlipToPayable(
                            payableModalPaymentSlipRef.current.uuid,
                            `${selectPayableRef.current.id}`,
                            (cancelTokenSource.current as CancelTokenSource)
                              .token
                          )
                          .then((_response) => {
                            setIsLinkPayableLoading(false);
                            fetchPaymentSlipsByAllEntryStatus();
                            dispatch(
                              setToastState({
                                variant: "success",
                                shouldShow: true,
                                message: {
                                  title: "Sucesso",
                                  description:
                                    "Boleto vinculado à contas a pagar",
                                },
                              })
                            );
                            setShouldOpenInstallmentAccountModal(false);
                          })
                          .catch((e) => {
                            const errorMessage = backendErrorMessageHandler(e);
                            console.log("error: ", e);
                            setIsLinkPayableLoading(false);
                            dispatch(
                              setToastState({
                                variant: "error",
                                shouldShow: true,
                                message: {
                                  title: "Erro ao vincular boleto",
                                  description:
                                    typeof errorMessage === "string"
                                      ? errorMessage
                                      : "Ocorreu um erro ao vincular o boleto à Contas a Pagar, tente novamente",
                                },
                              })
                            );
                            setShouldOpenInstallmentAccountModal(false);
                          });
                      }
                    },
                  },
                ],
              },
            },
          }}
        />
      )}
      {shouldOpenLinkInvoiceModal && (
        <Modal
          shouldOpen={shouldOpenLinkInvoiceModal}
          onClose={() => {
            if (clearSelectedInvoicesRef.current) {
              clearSelectedInvoicesRef.current();
            }
            setPaymentSlipToAttachLinks(null);
            setShouldOpenLinkInvoiceModal(false);
          }}
          icon={{ type: "link", color: "rose" }}
          title="Selecionar notas fiscais"
          main={{
            style: "form",
            content: {
              preset: "linkInvoice",
              footer: {
                buttons: [
                  {
                    variant: "secondary",
                    icon: "noIcon",
                    text: "Cancelar",
                    onClick() {
                      if (clearSelectedInvoicesRef.current) {
                        clearSelectedInvoicesRef.current();
                      }
                      setPaymentSlipToAttachLinks(null);
                      setShouldOpenLinkInvoiceModal(false);
                    },
                  },
                  {
                    variant: "primary",
                    iconPosition: "left",
                    icon: "checkmark",
                    text: "Confirmar selecionado(s)",
                    isLoading: isLinkPayableLoading,
                    onClick(data: {
                      selectedInvoices: TInvoice[];
                      clearSelectedInvoices: () => void;
                    }) {
                      clearSelectedInvoicesRef.current =
                        data.clearSelectedInvoices;
                      data.selectedInvoices.forEach((invoice, index) => {
                        if (paymentSlipToAttachLinks) {
                          setIsLinkPayableLoading(true);

                          apiService
                            .attachInvoiceToPaymentSlip(
                              paymentSlipToAttachLinks.uuid,
                              invoice.uuid,
                              (cancelTokenSource.current as CancelTokenSource)
                                .token
                            )
                            .then((_response) => {
                              if (index === data.selectedInvoices.length - 1) {
                                setIsLinkPayableLoading(false);
                                fetchPaymentSlipsByAllEntryStatus();
                                dispatch(
                                  setToastState({
                                    variant: "success",
                                    shouldShow: true,
                                    message: {
                                      title: "Sucesso",
                                      description:
                                        data.selectedInvoices.length > 1
                                          ? "Notas vinculadas à conta a pagar"
                                          : "Nota vinculada à contas a pagar",
                                    },
                                  })
                                );
                                data.clearSelectedInvoices();
                                setPaymentSlipToAttachLinks(null);
                                setShouldOpenLinkInvoiceModal(false);
                              }
                            })
                            .catch((e) => {
                              const errorMessage =
                                backendErrorMessageHandler(e);
                              setIsLinkPayableLoading(false);
                              dispatch(
                                setToastState({
                                  variant: "error",
                                  shouldShow: true,
                                  message: {
                                    title: "Erro ao vincular nota",
                                    description:
                                      typeof errorMessage === "string"
                                        ? errorMessage
                                        : "Ocorreu um erro ao vincular a nota à Contas a Pagar, tente novamente",
                                  },
                                })
                              );
                              if (clearSelectedInvoicesRef.current) {
                                clearSelectedInvoicesRef.current();
                              }
                              setPaymentSlipToAttachLinks(null);
                              setShouldOpenLinkInvoiceModal(false);
                            });
                        }
                      });
                    },
                  },
                ],
              },
            },
          }}
        />
      )}
      <Modal
        key={paymentSlipToDelete?.uuid}
        title="Recusar boleto"
        icon={{ type: "exclamationTriangle", color: "red" }}
        main={{
          style: "textOnly",
          content: [
            {
              text: "Tem certeza que deseja recusar o boleto ",

              style: "default",
              shouldBreakLine: false,
            },
            {
              text: paymentSlipToDelete ? paymentSlipToDelete?.bar_code : "",
              style: "bold",
              shouldBreakLine: false,
            },
            { text: "?", style: "default", shouldBreakLine: true },
          ],
        }}
        footer={{
          style: "default",
          buttons: [
            {
              variant: "secondary",
              icon: "noIcon",
              text: "Cancelar",
              onClick() {
                setPaymentSlipToDelete(undefined);
              },
            },
            {
              variant: "red",
              iconPosition: "left",
              icon: "checkmark",
              text: "Sim, recusar",
              onClick() {
                if (paymentSlipToDelete) {
                  apiService
                    .updatePaymentSlipLaunchStatus(
                      paymentSlipToDelete.uuid,
                      "decline",
                      (cancelTokenSource.current as CancelTokenSource).token
                    )
                    .then(() => {
                      setPaymentSlipToDelete(undefined);
                      dispatch(
                        setToastState({
                          variant: "success",
                          shouldShow: true,
                          message: {
                            title: "Sucesso",
                            description: "Boleto recusado",
                          },
                        })
                      );
                      fetchPaymentSlipsByAllEntryStatus();
                    })
                    .catch((e) => {
                      const errorMessage = backendErrorMessageHandler(e);
                      setPaymentSlipToDelete(undefined);
                      dispatch(
                        setToastState({
                          variant: "error",
                          shouldShow: true,
                          message: {
                            title: "Erro",
                            description:
                              typeof errorMessage === "string"
                                ? errorMessage
                                : "Não foi possível recusar o boleto, tente novamente",
                          },
                        })
                      );
                    });
                }
              },
            },
          ],
        }}
        shouldOpen={paymentSlipToDelete !== undefined}
        onClose={() => {
          setPaymentSlipToDelete(undefined);
        }}
      />
      <Modal
        key={invoiceToBreakLink?.target.uuid}
        title="Remover vínculo"
        icon={{ type: "breakLink", color: "red" }}
        main={{
          style: "textOnly",
          content: [
            {
              text: "Tem certeza que deseja remover o vínculo com esta nota fiscal?",

              style: "default",
              shouldBreakLine: false,
            },
          ],
        }}
        footer={{
          style: "default",
          buttons: [
            {
              variant: "secondary",
              icon: "noIcon",
              text: "Cancelar",
              onClick() {
                setInvoiceToBreakLink(undefined);
              },
            },
            {
              variant: "red",
              iconPosition: "left",
              icon: "checkmark",
              text: "Sim, remover",
              onClick() {
                if (invoiceToBreakLink) {
                  apiService
                    .detachInvoiceFromPaymentSlip(
                      invoiceToBreakLink.origin.uuid,
                      invoiceToBreakLink.target.uuid,
                      (cancelTokenSource.current as CancelTokenSource).token
                    )
                    .then(() => {
                      setInvoiceToBreakLink(undefined);
                      dispatch(
                        setToastState({
                          variant: "success",
                          shouldShow: true,
                          message: {
                            title: "Sucesso",
                            description: "Vínculo removido",
                          },
                        })
                      );
                      fetchPaymentSlipsByAllEntryStatus();
                      fetchInvoicesByAllEntryStatus();
                    })
                    .catch((e) => {
                      const errorMessage = backendErrorMessageHandler(e);
                      setInvoiceToBreakLink(undefined);
                      dispatch(
                        setToastState({
                          variant: "error",
                          shouldShow: true,
                          message: {
                            title: "Erro",
                            description:
                              typeof errorMessage === "string"
                                ? errorMessage
                                : "Não foi possível remover o vínculo, tente novamente",
                          },
                        })
                      );
                    });
                }
              },
            },
          ],
        }}
        shouldOpen={invoiceToBreakLink !== undefined}
        onClose={() => {
          setInvoiceToBreakLink(undefined);
        }}
      />
      {invoiceToRename !== undefined && (
        <Modal
          key={invoiceToRename?.uuid}
          title="Renomear arquivo"
          icon={{ type: "edit", color: "rose" }}
          main={{
            style: "form",
            content: {
              preset: "renameDocument",
              data: invoiceToRename,
              footer: {
                buttons: [
                  {
                    variant: "secondary",
                    icon: "noIcon",
                    text: "Cancelar",
                    onClick() {
                      setInvoiceToRename(undefined);
                    },
                  },
                  {
                    variant: "primary",
                    iconPosition: "left",
                    icon: "checkmark",
                    text: "Salvar",
                    onClick(label: string) {
                      if (invoiceToRename && cancelTokenSource.current) {
                        apiService
                          .renameInvoiceLabel(
                            invoiceToRename.uuid,
                            label,
                            cancelTokenSource.current.token
                          )
                          .then(() => {
                            setInvoiceToRename(undefined);
                            dispatch(
                              setToastState({
                                variant: "success",
                                shouldShow: true,
                                message: {
                                  title: "Sucesso",
                                  description: "Nota fiscal renomeada",
                                },
                              })
                            );
                            fetchInvoicesByAllEntryStatus();
                            fetchPaymentSlipsByAllEntryStatus();
                          })
                          .catch((e) => {
                            const errorMessage = backendErrorMessageHandler(e);
                            setInvoiceToRename(undefined);
                            dispatch(
                              setToastState({
                                variant: "error",
                                shouldShow: true,
                                message: {
                                  title: "Erro",
                                  description:
                                    typeof errorMessage === "string"
                                      ? errorMessage
                                      : "Não foi possível renomear a nota fiscal, tente novamente",
                                },
                              })
                            );
                          });
                      }
                    },
                  },
                ],
              },
            },
          }}
          // footer={{
          //   style: "default",
          //   buttons: [
          //     {
          //       variant: "secondary",
          //       icon: "noIcon",
          //       text: "Cancelar",
          //       onClick() {
          //         setInvoiceToRename(undefined);
          //       },
          //     },
          //     {
          //       variant: "red",
          //       iconPosition: "left",
          //       icon: "checkmark",
          //       text: "Sim, remover",
          //       onClick() {
          //         if (invoiceToRename) {
          //           apiService
          //             .renamePaymentSlipLabel(
          //               invoiceToRename.uuid,
          //               cancelTokenSource.current.token
          //             )
          //             .then(() => {
          //               setInvoiceToRename(undefined);
          //               dispatch(
          //                 setToastState({
          //                   variant: "success",
          //                   shouldShow: true,
          //                   message: {
          //                     title: "Sucesso",
          //                     description: "Vínculo removido",
          //                   },
          //                 })
          //               );
          //               fetchInvoicesByAllEntryStatus();
          //               fetchPaymentSlipsByAllEntryStatus();
          //             })
          //             .catch(() => {
          //               setInvoiceToRename(undefined);
          //               dispatch(
          //                 setToastState({
          //                   variant: "error",
          //                   shouldShow: true,
          //                   message: {
          //                     title: "Erro",
          //                     description:
          //                       "Não foi possível remover o vínculo, tente novamente",
          //                   },
          //                 })
          //               );
          //             });
          //         }
          //       },
          //     },
          //   ],
          // }}
          shouldOpen={invoiceToRename !== undefined}
          onClose={() => {
            setInvoiceToRename(undefined);
          }}
        />
      )}
    </Container>
  );
}

export default PaymentSlip;
