import styled from "styled-components";
import watermark from "../../assets/images/watermark.svg";
import SVG from "../../components/ui/SVG";
import Button from "../../components/ui-v2/Button";
import Logo from "../../components/ui-v2/Logo";
import Search from "../../components/ui-v2/Inputs/Search";
import { useEffect, useId, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../router";
import { TAccount, TAccountData } from "../../redux/reducers/account";
import { localStorageConstants } from "../../utils/constants";
import apiService from "../../services/api";
import {
  handleGetDataFromLocalStorage,
  handleParseQuerystrings,
  handleSetDataToLocalStorage,
} from "../../utils/miscellaneous";
import Spinner from "../../components/ui-v2/Spinner";
import InfiniteScroller from "../../components/InfiniteScroller";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import {
  clearInvoices,
  clearLastAccessedOrganizations,
  clearPaymentSlips,
} from "../../redux/reducers/globalState";
import axios, { CancelTokenSource } from "axios";

const Container = styled.main`
  position: relative;

  overflow: hidden;
  height: 100vh;

  svg#watermark {
    position: absolute;
    z-index: -1;
    width: calc(528vw / 14.4);
    height: calc(640vh / 8);
    left: 0;
  }

  @media (max-width: 1170px) {
    height: auto;
    width: 100vw;
    overflow-x: auto;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
`;

const LogoArea = styled.div`
  margin-bottom: 32px;

  svg {
    width: 340px;
    height: 71.58px;
  }
`;

const PanelContainer = styled.section<{ $accountsCount: number }>`
  width: 774px;
  height: ${({ $accountsCount }) => ($accountsCount === 0 ? "228" : "474")}px;

  background: var(--color-light-50);

  border-radius: 4px;
  border: 1px solid var(--color-light-200);
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.05);

  & > * {
    margin: 24px;
  }

  & > h2 {
    text-align: center;
    font-family: var(--font-paragraph-default);
    font-size: 16px;
    color: var(--color-text-labels);
  }
`;

const PanelHeader = styled.div`
  & > * {
    margin-bottom: 10px;
  }

  h2 {
    font-family: var(--font-title-default);
    color: var(--color-dark-blue-600);
    font-size: 16px;
  }

  .separator {
    width: 30px;
    height: 2px;
    border-radius: 4px;
    background: var(--color-main-rose);
  }
`;

const NavigationArea = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > *:first-child {
    width: 625px;
  }

  & > *:nth-child(2) {
    width: 85px;
    padding: 8px 10px;
    justify-content: space-evenly;
  }
`;

const PanelBody = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  max-height: 325px;
  overflow: auto;

  & > button {
    width: 230px;
    height: 54px;
    display: flex;
    justify-content: left;

    border-color: var(--color-light-200);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

    margin-bottom: 15px;

    &:hover {
      border-color: var(--color-main-rose);
      color: var(--color-main-rose);
      background: var(--color-light-0);
      transition: 0.2s ease-in-out;
    }
  }
`;

const Account: React.FC = () => {
  const accountsDataRef = useRef<TAccountData | null>(null);

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const [accounts, setAccounts] = useState<TAccount[]>([]);

  const [searchPhrase, setSearchPhrase] = useState("");

  const spinnerId = useId();

  const navigate = useNavigate();

  const dispatch = useTypedDispatch();

  const handleOnClick = async (account: TAccount) => {
    const localSelectedAccount = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ACCOUNT
    ) as TAccount;

    if (!localSelectedAccount || localSelectedAccount.uuid !== account.uuid) {
      new Promise((resolve) => {
        handleSetDataToLocalStorage(
          localStorageConstants.SELECT_ACCOUNT,
          account
        );

        localStorage.removeItem(localStorageConstants.SELECT_ORGANIZATION);
        localStorage.removeItem(
          localStorageConstants.LAST_ACCESSED_ORGANIZATIONS
        );
        dispatch(clearLastAccessedOrganizations());
        dispatch(clearInvoices());
        dispatch(clearPaymentSlips());
        setTimeout(() => {
          resolve(true);
        }, 100);
      }).then(() => {
        if (account.uuid) {
          apiService
            .getOrganizations(
              account.uuid,
              (cancelTokenSource.current as CancelTokenSource).token
            )
            .then(() => {
              window.location.reload();
              // navigate(ROUTES.HOME);
            });
        }
      });
    } else {
      window.location.reload();
    }
  };

  const handleOnSearch = (search: string) => {
    // const accountsResponse = (await apiService.getAccounts(searchPhrase))
    //   .data as TAccountData;

    // setAccountsData(accountsResponse);

    // setAccountsData(null);
    if (search.length > 0) {
      navigate("./?search=" + search);
      setSearchPhrase(search);
      setAccounts([]);
    } else {
      navigate("./");
      setSearchPhrase(search);
      handlePopulateAccount();
    }
  };

  const handlePopulateAccount = async () => {
    const queryStrings = handleParseQuerystrings(window.location.search);

    cancelTokenSource.current = axios.CancelToken.source();

    const accountsResponse = (
      await apiService.getAccounts(
        cancelTokenSource.current.token,
        queryStrings && queryStrings.search
          ? queryStrings.search[0]
          : undefined,
        undefined,
        1
      )
    )?.data as TAccountData;

    if (accountsResponse) {
      accountsDataRef.current = accountsResponse;

      if (queryStrings.search && queryStrings.search[0].length > 0) {
        setSearchPhrase(queryStrings.search[0]);
        setAccounts([]);
      } else {
        if (accountsResponse.count === 1) {
          await apiService.getOrganizations(
            accountsResponse.results[0].uuid,
            cancelTokenSource.current.token
          );
        }

        setAccounts(accountsResponse.results);
      }
    }
  };

  useEffect(() => {
    // console.log("mounting element. accounts: ", accounts);
    handlePopulateAccount();

    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  // useEffect(() => {
  //   console.log("accounts has changed: ", accounts);
  // }, [accounts]);

  // useEffect(() => {}, [document.getElementById(spinnerId)]);

  return (
    <Container>
      <SVG id="watermark" src={watermark} />
      <ContentContainer>
        <LogoArea>
          <Logo />
        </LogoArea>
        <PanelContainer
          $accountsCount={
            accountsDataRef.current ? accountsDataRef.current.count : 1
          }
        >
          <PanelHeader>
            <h2>Buscar conta por nome</h2>
            <div className="separator"></div>
            <NavigationArea>
              <Search
                placeholder="Buscar conta"
                variant="default"
                onSearch={handleOnSearch}
                $ref={(inputRef, setSearchString) => {
                  const queryStrings = handleParseQuerystrings(
                    window.location.search
                  );
                  if (queryStrings.search && inputRef.current) {
                    inputRef.current.value = queryStrings.search[0];
                    setSearchString(inputRef.current.value);
                  }
                }}
                onClear={() => {
                  navigate("./");
                  setSearchPhrase("");
                  handlePopulateAccount();
                }}
              />
              <Button
                variant="outline"
                icon="logout"
                iconPosition="left"
                text="Sair"
                onClick={() => {
                  localStorage.clear();
                  navigate(ROUTES.LOGIN);
                }}
              />
            </NavigationArea>
          </PanelHeader>
          <PanelBody>
            {accounts.length > 0 &&
              accounts.map((account, index) => (
                <Button
                  key={index}
                  text={account.name}
                  variant="secondary"
                  icon="noIcon"
                  onClick={() => {
                    handleOnClick(account);
                  }}
                />
              ))}

            <div
              style={{
                visibility:
                  accountsDataRef.current &&
                  accountsDataRef.current.count > accounts.length
                    ? "visible"
                    : "hidden",
              }}
              className="spinnerContainer"
            >
              <Spinner
                style={{
                  width:
                    accountsDataRef.current &&
                    accountsDataRef.current.count > accounts.length
                      ? 20
                      : 0,
                  height:
                    accountsDataRef.current &&
                    accountsDataRef.current.count > accounts.length
                      ? 20
                      : 0,
                }}
                id={spinnerId}
              />
            </div>

            {accountsDataRef.current && (
              /*  accountsDataRef.current.count > accounts.length && */ <InfiniteScroller
                children={[]}
                itemsToRenderAmount={10}
                spinnerId={spinnerId}
                spinnerRef={document.getElementById(spinnerId)}
                searchParam={searchPhrase}
                onIntersect={(data, elementsAmount) => {
                  accountsDataRef.current = (() => {
                    const test = accountsDataRef.current;
                    if (test) {
                      test.count = elementsAmount;
                      return test;
                    }
                    return accountsDataRef.current;
                  })();

                  setAccounts((prev) => {
                    const allValues = [...prev, ...data];

                    const temp: any[] = [];
                    allValues.forEach((value) => {
                      if (
                        !temp.some((element) => value.uuid === element.uuid)
                      ) {
                        temp.push(value);
                      }
                    });

                    // console.log("data: ", data, ", temp: ", temp);
                    return temp;
                  });
                }}
                variant="accounts"
              />
            )}
          </PanelBody>
          {accountsDataRef.current && accountsDataRef.current.count === 0 && (
            <h2>Nenhuma conta encontrada</h2>
          )}
        </PanelContainer>
      </ContentContainer>
    </Container>
  );
};

export default Account;
