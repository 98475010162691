import styled from "styled-components";
import { useCallback, useEffect, useRef, useState } from "react";
import Menu from "./Menu";
import Button from "../Button";
import {
  getQuerystringParams,
  handleGetDataFromLocalStorage,
} from "../../../utils/miscellaneous";
import { localStorageConstants } from "../../../utils/constants";
import {
  TInvoice,
  TInvoiceEntryStatus,
  // TInvoiceEntryStatus,
  // setInvoicesUpdatedAt,
} from "../../../redux/reducers/globalState";
// import { TOrganization } from "../../../redux/reducers/organizations";
import apiService from "../../../services/api";
import useDetectOutsideClick from "../../../hooks/useDetectOutsideClick";
import axios, { CancelTokenSource } from "axios";
import { useDispatch } from "react-redux";
import { fetchInvoices } from "../../../redux/actions";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  position: relative;
`;

export type TContent =
  | "editStatus"
  | "markAsFinished"
  | "markAsPending"
  | "downloadFile"
  | "deletePaymentSlip"
  | "openPayableModal"
  | "openGoodsModal";

export type TCommonProps = {
  content: TContent[];
  onMenuSelect?: (selected: string) => void;
  onClick?: (select: TContent) => void;
  isDisabled?: boolean;
  scrollableRef?: React.MutableRefObject<HTMLTableSectionElement | null>;
};

export type TDropdownOnlyIconProps = TCommonProps;

const DropdownOnlyIcon: React.FC<TDropdownOnlyIconProps> = ({
  content,
  onMenuSelect,
  onClick,
  isDisabled,
  // scrollableRef,
}) => {
  const [selectedData, setSelectedData] = useState("");
  const [shouldShow, setShouldShow] = useState<"show" | "hide">("hide");

  const [shouldShowNextMenu, setshouldShowNextMenu] = useState(false);

  const dispatch = useDispatch();

  const componentRef = useRef<HTMLDivElement | null>(null);

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const navigate = useNavigate();

  useDetectOutsideClick({
    isClicked: shouldShow === "show",
    componentRef,
    onOutside() {
      if (selectedData.length === 0) {
        setShouldShow("hide");
        setshouldShowNextMenu(false);
      }
    },
  });

  const handleRenderDropdown = useCallback(() => {
    const handleOnClickButton = () => {
      setShouldShow((prev) => {
        if (prev === "show") {
          setshouldShowNextMenu(false);
        }
        return prev === "hide" ? "show" : "hide";
      });
    };

    const handleOnBlur = (): "hide" | "show" => {
      setShouldShow("hide");
      return "hide";
    };

    cancelTokenSource.current = axios.CancelToken.source();

    const handleMarkAsFinished = () => {
      const localSelectInvoice = handleGetDataFromLocalStorage(
        localStorageConstants.SELECT_INVOICE
      ) as TInvoice;

      const querystrings = getQuerystringParams();

      if (localSelectInvoice) {
        apiService
          .updateInvoiceMarkAsFinished(
            localSelectInvoice.uuid,
            (cancelTokenSource.current as CancelTokenSource).token
          )
          .then(() => {
            navigate(window.location.href);
            if (cancelTokenSource.current) {
              dispatch(
                fetchInvoices({
                  cancelToken: cancelTokenSource.current.token,
                  organizationUuid: querystrings.organizationId,
                  dateIssueEnd: querystrings.endDate,
                  dateIssueStart: querystrings.startDate,
                  has_accounts_payable: querystrings.hasPayable,
                  has_goods_receipt: querystrings.hasGoods,
                  launch_status:
                    querystrings.launchStatus as TInvoiceEntryStatus,
                  page: querystrings.page,
                  per_page: querystrings.itemsPerPage,
                  search: querystrings.search,
                })
              );
            }
          });
      }
    };

    const handleMarkAsPending = () => {
      const localSelectInvoice = handleGetDataFromLocalStorage(
        localStorageConstants.SELECT_INVOICE
      ) as TInvoice;

      const querystrings = getQuerystringParams();

      if (localSelectInvoice) {
        apiService
          .updateInvoiceMarkAsPending(
            localSelectInvoice.uuid,
            (cancelTokenSource.current as CancelTokenSource).token
          )
          .then(() => {
            navigate(window.location.href);
            if (cancelTokenSource.current) {
              dispatch(
                fetchInvoices({
                  cancelToken: cancelTokenSource.current.token,
                  organizationUuid: querystrings.organizationId,
                  dateIssueEnd: querystrings.endDate,
                  dateIssueStart: querystrings.startDate,
                  has_accounts_payable: querystrings.hasPayable,
                  has_goods_receipt: querystrings.hasGoods,
                  launch_status:
                    querystrings.launchStatus as TInvoiceEntryStatus,
                  page: querystrings.page,
                  per_page: querystrings.itemsPerPage,
                  search: querystrings.search,
                })
              );
            }
          });
      }
    };

    const handleOnClickMenu = (index: string, selected: TContent[]) => {
      setSelectedData(content[parseInt(index)]);

      selected.map((option) => {
        switch (option) {
          case "editStatus":
            if (onClick) onClick("editStatus");
            break;
          case "markAsFinished":
            if (onClick) onClick("markAsFinished");
            handleMarkAsFinished();
            break;
          case "markAsPending":
            if (onClick) onClick("markAsPending");
            handleMarkAsPending();
            break;
          case "downloadFile":
            if (onClick) onClick("downloadFile");
            break;
          case "deletePaymentSlip":
            if (onClick) onClick("deletePaymentSlip");
            break;
          case "openGoodsModal":
            if (onClick) onClick("openGoodsModal");
            break;
          case "openPayableModal":
            if (onClick) onClick("openPayableModal");
            break;
        }
      });

      if (onMenuSelect) onMenuSelect(`${content[parseInt(index)]}`);
      setShouldShow("hide");
    };

    return (
      <Container ref={componentRef}>
        <Button
          onClick={handleOnClickButton}
          onBlur={handleOnBlur}
          variant="secondary"
          icon="onlyIcon"
          iconType="ellipsisV"
          disabled={content.length === 0 || isDisabled}
        />

        {content.length > 0 && (
          <Menu
            shouldShow={shouldShow}
            content={content}
            list="default"
            onClick={handleOnClickMenu}
            setShouldShow={setShouldShow}
            setshouldShowNextMenu={setshouldShowNextMenu}
            shouldShowNextMenu={shouldShowNextMenu}
          />
        )}
      </Container>
    );
  }, [shouldShow, selectedData]);

  // const handleScroll = (_e: any) => {
  //   if (componentRef.current && scrollableRef?.current) {
  //     (componentRef.current.children[1] as any).style.top =
  //       (componentRef.current.children[1] as any).getBoundingClientRect().y +
  //       (scrollableRef?.current?.getBoundingClientRect().y -
  //         (scrollableRef?.current?.firstChild as any).getBoundingClientRect()
  //           .y) +
  //       "px";
  //   }
  //   // console.log(
  //   //   "menu top style: ",
  //   //   (componentRef.current?.children[1] as any).style.top,
  //   //   ", scrollable ref y: ",
  //   //   scrollableRef?.current?.getBoundingClientRect().y,
  //   //   ", tr y: ",
  //   //   (scrollableRef?.current?.firstChild as any).getBoundingClientRect().y,
  //   //   ", result: ",
  //   //   (componentRef.current?.children[1] as any).getBoundingClientRect().y +
  //   //     (scrollableRef?.current?.getBoundingClientRect().y -
  //   //       (scrollableRef?.current?.firstChild as any).getBoundingClientRect().y)
  //   // );
  // };

  useEffect(() => {
    // if (scrollableRef?.current) {
    //   scrollableRef.current.addEventListener("scroll", handleScroll);
    // }
    return () => {
      // if (scrollableRef?.current) {
      //   scrollableRef.current.removeEventListener("scroll", handleScroll);
      // }
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  return handleRenderDropdown();
};

export default DropdownOnlyIcon;
