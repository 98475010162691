import styled from "styled-components";
import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import THead from "./THead";
import TBody from "./TBody";
import {
  TTagColorVariants,
  TTagIconVariants,
  TTagNoIcon,
  TTagWithIcon,
} from "../Tag";
import Button, {
  TButtonIconVariants,
  TButtonProps,
  TWithIconVariants,
} from "../Button";
import Search from "../Inputs/Search";
import SVG from "../../ui/SVG";
import emptyStateSVG from "../../../assets/images/emptyState.svg";
import { TContent } from "../DropdownOnlyIcon";
import {
  FilterArea,
  NextContainer as ManifestationModal,
  TManifestationStatusOptions,
  TextArea,
  TextAreaContainer,
} from "../DropdownOnlyIcon/Menu";
import Dropdown from "../Dropdown";
import {
  handleGetDataFromLocalStorage,
  handleParseQuerystrings,
  handlePopulateQuerystring,
} from "../../../utils/miscellaneous";
import { localStorageConstants } from "../../../utils/constants";
import {
  TInvoiceEntryStatus,
  TInvoiceManifestastionStatus,
  setInvoicesUpdatedAt,
  setToastState,
} from "../../../redux/reducers/globalState";
import { TOrganization } from "../../../redux/reducers/organizations";
import apiService from "../../../services/api";
import { useTypedDispatch } from "../../../hooks/useTypedDispatch";
import { fetchInvoices } from "../../../redux/actions";
import axios, { CancelTokenSource } from "axios";
import { Property } from "csstype";
// import { id } from "date-fns/locale";

const Container = styled.section`
  // overflow-x: auto;

  text-align: left;

  background: var(--color-light-0);

  margin-top: 27px;

  // max-height: calc(100vh - 500px);
  // width: calc(100vw - 48px);

  & > button {
    margin-bottom: 18px;
    width: 100%;
  }

  & * tr td > div {
    position: static;

    button + menu {
      right: calc(110vw / 12.86);
    }

    div + *:not(menu, menu *) {
      margin-left: 12px;
    }
  }

  @media (max-width: 1450px) {
    & * tr td > div {
      button + menu {
        right: 50px !important;
      }
    }
  }
`;

const TableContainer = styled.table<{
  $isBatchOperationsContainerVisible?: boolean;
}>`
  display: block;

  border-collapse: collapse;

  th:nth-child(1),
  td:nth-child(1):not(.emptyState td:nth-child(1)):not(tfoot td:nth-child(1)) {
    // padding-left: 20px;
  }

  ${({ $isBatchOperationsContainerVisible }) =>
    $isBatchOperationsContainerVisible &&
    `thead {
      top: 75px;
    tr {
      border-radius: 0 0 8px 8px;
    }
  }`}
`;

const EmptyStateTBody = styled.tbody`
  display: block;

  tr {
    display: block;

    &:first-child div {
      margin-top: 64px;
      margin-bottom: 16px;
    }
  }

  td {
    display: block;

    width: 100%;

    div {
      justify-content: center;
      display: flex;
      width: 100%;

      h2 {
        font-family: var(--font-title-bold);
        color: var(--color-text-title);
        font-size: 21px;
      }

      p {
        font-family: var(--font-paragraph-default);
        color: var(--color-text-paragraph);
        font-size: 14px;
      }
    }
  }
`;

const TableFooter = styled.tfoot<{
  $isFixedAtTheBottom?: boolean;
  $customStyles?: { width: string };
}>`
  ${({ $isFixedAtTheBottom, $customStyles }) =>
    $isFixedAtTheBottom &&
    `position: fixed;` +
      `bottom: 0;` +
      ($customStyles
        ? `width: ${$customStyles.width};`
        : `width: calc(100vw - 48px);`)}

  &,
  tr,
  td {
    display: block;
    background: var(--color-light-0);
  }

  .footerDataContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 74px;

    padding-right: 24px;

    border: 1px solid var(--color-light-200);
    border-radius: 8px 8px 0px 0px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

    & > * {
      margin-left: 12px;
    }

    .leftSideFooterDataContainer,
    .rightSideFooterDataContainer {
      display: flex;
      align-items: center;

      & > * {
        margin-left: 12px;
      }
    }

    .leftSideFooterDataContainer {
      & > div {
        width: 50px;
        div > button {
          height: 30px;
          min-height: unset;

          p.content {
            top: 7px;
            left: 3px;
            font-size: 12px;
          }

          svg {
            top: 4px;
            right: 0px;
          }
        }
        menu {
          top: -156px;

          & > button {
            min-height: unset;
            height: 46px;

            & > p.content {
              font-size: 12px;
              left: 3px;
            }

            &.active {
              & > p.content {
                color: var(--color-light-0);
                top: 10px;
              }
              &::after {
                font-size: 18px;
                top: 50%;
                right: 8px;
              }
            }
          }
        }
      }
    }

    .rightSideFooterDataContainer {
      button.active {
        background: var(--color-main-blue);
        border: 1px solid var(--color-main-blue);
        color: var(--color-light-0);
      }
    }

    p {
      font-size: 14px;
      font-family: var(--font-paragraph-default);
      color: var(--color-text-paragraph);
    }
  }

  .footerFormButtonContainer {
    margin: 24px 0;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 12px;
    }
  }
`;

const BatchOperationsContainer = styled.div`
  height: 76px;
  width: calc(100% - 8px);
  background: var(--color-gradient-blue);
  border-radius: 8px 8px 0px 0px;

  display: flex;
  align-items: center;

  padding-left: 8px;

  // position: relative;
  position: sticky;
  top: 0;
  z-index: 2;

  .selectedInfoArea p {
    font-size: 16px;
    color: var(--color-light-0);
    font-family: var(--font-default-paragraph);
  }

  button {
    padding: 8px 16px;
    font-size: 16px;
  }

  & > * {
    margin-left: 8px;
  }

  & > menu {
    top: 60px;
    left: 410px;
  }
`;

export type TSelection = "multiSelect" | "singleSelect" | "noSelect";

export type TRow = "singleRow" | "doubleRow";

export type TCellStyleVariants =
  | "bold"
  | "default"
  | "miscellaneous"
  | TTagColorVariants
  | "button";

type TCellTag<T extends TTagIconVariants> = T extends "noIcon"
  ? TTagNoIcon & {
      style: TTagColorVariants;
    }
  : TTagWithIcon & {
      style: TTagColorVariants;
    };

type TCellNotTag = {
  style: "bold" | "default" | "faded" | "alert";
};

export type TText = {
  style: "bold" | "default" | "faded" | "alert";
  text: string;
  shouldBreakLine: boolean;
  marginRight?: string;
  fontSize?: string;
};

export type TCellNotTagMiscellaneous = {
  style: "miscellaneous";
  values: TText[];
  showSkeleton?: boolean;
  tooltip?: {
    text: TText[];
    customTextAlignment?: Property.TextAlign;
    size?: "large" | "default";
    customTopPosition?: number;
    hasScrollableRef?: boolean;
  };
};

type TCellCommonProps = {
  value: string;
  showSkeleton?: boolean;
  marginRight?: string;
  fontSize?: string;
  shouldBreakLine?: boolean;
};

export type TCell<T extends Exclude<TCellStyleVariants, "button">> =
  T extends TTagColorVariants
    ? TCellCommonProps & TCellTag<TTagIconVariants>
    : T extends "miscellaneous"
    ? TCellNotTagMiscellaneous
    : TCellCommonProps & TCellNotTag;

type TNoDropdownCellButton = {
  icon: Exclude<TWithIconVariants, "ellipsisV">;
  onClick?: () => void;
};

type TDropdownCellButton = {
  icon: "ellipsisV";
  content: TContent[];
  onMenuSelect?: (selected: string) => void;
  onClick?: () => void;
  hasScrollableRef?: boolean;
};

export type TCellButton<TButtonVariant extends TWithIconVariants> =
  TButtonVariant extends "ellipsisV"
    ? TDropdownCellButton & {
        style: "button";
        isDisabled?: boolean;
        showSkeleton?: boolean;
        id?: string;
      }
    : TNoDropdownCellButton & {
        style: "button";
        isDisabled?: boolean;
        customCSS?: CSSProperties;
        id?: string;
        tooltip?: {
          text: TText[];
          customTextAlignment?: Property.TextAlign;
          size?: "large" | "default";
          customTopPosition?: number;
          hasScrollableRef?: boolean;
        };
        showSkeleton?: boolean;
      };

export type TTableBody<TCellStyle extends TCellStyleVariants> =
  TCellStyle extends "button"
    ? TCellButton<TWithIconVariants>[]
    : TCell<Exclude<TCellStyle, "button">>;

export type TSize = "default" | "small";

export type TTableHeadIcons = "infoCircle";

export type TTableHeadIconColors = "--color-text-labels";

export type TTableHeadIconStyles = "noIcon" | TTableHeadIcons;

type TTableHeadNoIcon = {
  style: "noIcon";
};

export type TFullData<T extends TSelection> = T extends "multiSelect"
  ? { type: "multiSelect"; fullData: any[]; initialSelectedState?: any[] }
  : {
      type: T;
      fullData?: never;
      initialSelectedState?: never;
    };

export type TTableHeadWithIcon = {
  style: "icon";
  icon: TTableHeadIcons;
  iconPosition: "left" | "right";
  iconColor: TTableHeadIconColors;
};

type TTableHeadCommonProps = {
  value: string;
};

export type TTableSelected =
  | { isChecked: boolean; elementId: string }
  | { isChecked?: never; elementId: "all" }
  | { isChecked?: never; elementId: "none" };

export type TTableHead<T extends TTableHeadIconStyles> = T extends "noIcon"
  ? TTableHeadNoIcon & TTableHeadCommonProps
  : TTableHeadWithIcon & TTableHeadCommonProps;

type TCommonProps = {
  tableHead: {
    customTopPosition?: string;
    value: TTableHead<TTableHeadIconStyles>[];
  };
  tableBody: {
    elementId: string;
    file_xml: null | string;
    file_nfe: null | string;
    file_boleto?: string;
    rowTooltip?: {
      size?: "default" | "large";
      text: TText[];
      customTextAlignment?: Property.TextAlign;
      customTopPosition?: number;
    };
    body: TTableBody<TCellStyleVariants>[];
  }[];
  columns: { tableHead: { width: string[] }; tableBody: { width: string[] } };
  selectType: TFullData<TSelection>;
  disableBatchOperations?: boolean;
  onSelect?: (selected: TTableSelected[]) => void;
  onAction?: () => void;
  search?: {
    placeholder: string;
    id?: string;
    onSearch?: (searchPrase: string) => void;
  };
  setSelectedRows?: number[];
  emptyState?: {
    isEmptyState: boolean;
    title: string;
    description: string;
  };
  footer?: {
    customStyle?: { width: string };
    leftSideDescription?: string;
    onSelectItemsPerPage?: (amount: number) => void;
    description?: string;
    formButtons?: {
      buttons: TButtonProps<TButtonIconVariants>[];
    };
    pagination?: {
      buttonsAmount: number;
      onClick: (activeIndex: number) => void;
      isFixedAtTheBottom?: boolean;
    };
    customPagination?: {
      isFixedAtTheBottom?: boolean;
      currentPage: string;
      buttonBackward?: { onClick: () => void; disabled: boolean };
      buttonForward?: { onClick: () => void; disabled: boolean };
      pages: { value: string; onClick: () => void }[];
    };
  };
};

export type TOldSecondRow = {
  params: {
    data: TCellNotTagMiscellaneous;
    onClick?: () => void;
    onDelete?: (selected: any) => void;
  }[];
  customStyles?: {
    disable?: boolean;
    width: string;
  };
};

type TSecondRowCommonProps = {
  params: {
    data: TCellNotTagMiscellaneous;
    leftSideCustomNode?: ReactNode;
    rightSideCustomNode?: ReactNode;
  }[];
};

export type TSecondRow = TSecondRowCommonProps;

export type TTableProps<TRowVariant extends TRow> =
  TRowVariant extends "doubleRow"
    ? TCommonProps & {
        row: "doubleRow";
        borderColor?: never;
        secondRow: TSecondRow;
      }
    : TRowVariant extends "singleRow"
    ? TCommonProps & {
        row: "singleRow";
        borderColor?: string[];
        secondRow?: never;
      }
    : never;

const TableGrid: React.FC<TTableProps<TRow>> = ({
  tableHead,
  tableBody,
  borderColor,
  row,
  secondRow,
  selectType,
  disableBatchOperations,
  search,
  emptyState,
  footer,
  onSelect,
  onAction,
  // setSelectedRows,
  columns,
}) => {
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const tbodyRef = useRef<HTMLTableSectionElement | null>(null);
  const [clickElementListId, setClickElementListId] = useState([""]);
  const clickElementListRef = useRef<
    {
      elementId: string;
      invoiceXml: string | null;
      invoiceNfe: string | null;
      paymentSlipFile?: string;
    }[]
  >([]);

  const theadCheckboxSetSelectedRef = useRef<React.Dispatch<
    React.SetStateAction<"" | "active" | "inactive">
  > | null>(null);
  // const [activePageIndex, setActivePageIndex] = useState(0);

  // const initialFooterArray = Array.from(
  //   { length: footer?.pagination?.buttonsAmount ?? 0 },
  //   (_, index) => index + 1
  // );

  const [selected, setSelected] = useState<TManifestationStatusOptions>(
    "Confirmação da operação (Confirmada)"
  );

  const [shouldDecreaseHeight, setShouldDecreaseHeight] = useState(false);

  const dispatch = useTypedDispatch();

  const [shouldShowManifestationModal, setShouldShowManifestationModal] =
    useState(false);

  const selectedRowsRef = useRef<
    {
      isChecked: boolean;
      clickedElementId: string;
    }[]
  >([]);

  const payablesRef = useRef<boolean | undefined>();
  const goodsEntryRef = useRef<boolean | undefined>();

  const forceSelectElementsRef = useRef<
    | {
        elementId: string;
        isClicked: "" | "active" | "inactive";
        setSelectElement: React.Dispatch<
          React.SetStateAction<"" | "active" | "inactive">
        >;
      }[]
  >([]);

  const selectedOrganizationRef = useRef<TOrganization | null>(null);
  const currEntryStatusRef = useRef<TInvoiceEntryStatus | undefined>();
  const selectedStartDateRef = useRef<string | undefined>();
  const selectedEndDateRef = useRef<string | undefined>();
  const currPageRef = useRef<number | undefined>();
  const fullDataRef = useRef<typeof selectType.fullData>(/* [] */);

  const tableContainerId = useId();
  const tableId = useId();
  const tfootDataId = useId();

  const [justificationText, setJustificationText] = useState<
    string | undefined
  >();
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const TEXT_AREA_MAX_lENGTH = 256;

  const handleTextAreaOnInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setTimeout(() => {
      if (
        textAreaRef.current &&
        textAreaRef.current.value.length <= TEXT_AREA_MAX_lENGTH
      ) {
        setJustificationText((e.target as HTMLTextAreaElement).value);
      }
    }, 1000);
  };

  const multiSelectHandler = (
    selected: {
      isChecked: boolean;
      clickedElementId: string;
      setClickState?: React.Dispatch<
        React.SetStateAction<"" | "active" | "inactive">
      >;
    },
    prev: string[]
  ) => {
    const tableBodyElement = (fullDataRef.current as any[]).find(
      (element) => element.uuid === selected.clickedElementId
    );
    // console.log(
    //   "selected: ",
    //   selected,
    //   ", prev: ",
    //   prev,
    //   ", table body element: ",
    //   tableBodyElement
    // );
    if (!selected.isChecked && !prev.includes("all")) {
      prev.splice(prev.indexOf(selected.clickedElementId), 1);
      if (prev.length === 0) {
        prev = [""];
      }

      clickElementListRef.current.splice(
        clickElementListRef.current.findIndex(
          (element) => element.elementId === tableBodyElement.uuid
        ),
        1
      );

      // console.log(
      //   "first, click element list ref: ",
      //   clickElementListRef.current,
      //   ", click element list id: ",
      //   prev
      // );
    } else {
      if (!prev.includes(selected.clickedElementId))
        prev.push(selected.clickedElementId);
      if (
        tableBodyElement &&
        !clickElementListRef.current.some(
          (element) => element.elementId === tableBodyElement.uuid
        )
      ) {
        clickElementListRef.current.push({
          elementId: tableBodyElement.uuid,
          invoiceNfe: tableBodyElement.file_nfe,
          invoiceXml: tableBodyElement.file_xml,
          paymentSlipFile: tableBodyElement.file_boleto,
        });
      }
      // console.log(
      //   "second, click element list ref: ",
      //   clickElementListRef.current
      // );
    }

    if (
      !selectedRowsRef.current.some(
        (element) => element.clickedElementId === selected.clickedElementId
      )
    ) {
      selectedRowsRef.current.push(selected);
    } else {
      const elementIndex = selectedRowsRef.current.findIndex(
        (element) => element.clickedElementId === selected.clickedElementId
      );

      const temp = [...selectedRowsRef.current];
      temp.splice(elementIndex, 1);
      selectedRowsRef.current = temp;
    }

    if (onSelect)
      onSelect(
        selectedRowsRef.current.map((element) => ({
          elementId: element.clickedElementId,
          isChecked: element.isChecked,
        }))
      );

    return prev;
  };

  const singleSelectHandler = (
    selected: {
      isChecked: boolean;
      clickedElementId: string;
      setClickState?: React.Dispatch<
        React.SetStateAction<"" | "active" | "inactive">
      >;
    },
    prev: string[]
  ) => {
    const tableBodyElement = tableBody.find(
      (element) => `${element.elementId}` === selected.clickedElementId
    );
    // console.log(
    //   "tablebody: ",
    //   tableBody,
    //   ", selected: ",
    //   selected,
    //   ", table body element: ",
    //   tableBodyElement
    // );

    if (!selected.isChecked) {
      // console.log("selected: ", selected, ", prev: ", prev);
      prev.splice(prev.indexOf(selected.clickedElementId), 1);
      if (prev.length === 0) {
        prev = [""];
      }
      clickElementListRef.current.splice(
        clickElementListRef.current.findIndex(
          (element) => element.elementId === tableBodyElement?.elementId
        ),
        1
      );
      // console.log(
      //   "first, click element list ref: ",
      //   clickElementListRef.current
      // );
    } else {
      // console.log(
      //   "click element list id: ",
      //   clickElementListId,
      //   ", selected: ",
      //   selected,
      //   ", click element list ref: ",
      //   clickElementListRef.current,
      //   ", force select elements: ",
      //   forceSelectElementsRef.current
      // );
      prev = [selected.clickedElementId];

      if (tableBodyElement) {
        clickElementListRef.current = [
          {
            elementId: tableBodyElement?.elementId,
            invoiceNfe: tableBodyElement.file_nfe,
            invoiceXml: tableBodyElement.file_xml,
            paymentSlipFile: tableBodyElement.file_boleto,
          },
        ];
      }

      if (forceSelectElementsRef.current) {
        forceSelectElementsRef.current.forEach((element) => {
          if (clickElementListId.includes(element.elementId)) {
            // console.log("setting inactive: ", element.elementId);
            setTimeout(() => {
              element.setSelectElement("inactive");
            }, 30);
          }
        });
      }

      // console.log(
      //   "second, click element list ref: ",
      //   clickElementListRef.current,
      //   ", prev from inside: ",
      //   prev
      // );
    }

    if (onSelect) {
      onSelect([
        {
          isChecked: selected.isChecked,
          elementId: selected.clickedElementId,
        },
      ]);
    }

    return prev;
  };

  const handleOnSelectRow = (selected: {
    isChecked: boolean;
    clickedElementId: string;
    setClickState?: React.Dispatch<
      React.SetStateAction<"" | "active" | "inactive">
    >;
  }) => {
    setTimeout(() => {
      setClickElementListId((prev) => {
        // console.log(
        //   "prev : ",
        //   prev,
        //   ", selected: ",
        //   selected,
        //   ", table body: ",
        //   tableBody
        // );
        if (["", "none"].includes(prev[0])) {
          prev.splice(0, 1);
        }

        if (fullDataRef.current) {
          prev = multiSelectHandler(selected, prev);
        } else {
          prev = singleSelectHandler(selected, prev);
        }

        // console.log("prev from outside: ", prev);

        return [...prev];
      });
      setTimeout(() => {
        if (
          clickElementListId.includes("all") &&
          theadCheckboxSetSelectedRef.current &&
          fullDataRef.current
        ) {
          theadCheckboxSetSelectedRef.current("inactive");
          if (selected.setClickState) selected.setClickState("inactive");
          // console.log(
          //   "full data ref: ",
          //   fullDataRef.current,
          //   ", selected: ",
          //   selected
          // );
          const selectedIndexes = fullDataRef.current
            .map((element) => {
              // console.log("from map, element id: ", element.uuid, ', selected id: ', );
              return element.uuid === selected.clickedElementId
                ? undefined
                : element.uuid;
            })
            .filter((trow) => {
              // console.log("from filter, trow: ", trow);
              return trow !== undefined;
            }) as string[];

          // console.log("selected indexes: ", selectedIndexes);

          clickElementListRef.current = [];
          selectedIndexes.forEach((selectedIndex) => {
            if (fullDataRef.current) {
              const bodyElement = fullDataRef.current.find(
                (element) => element.uuid === selectedIndex
              );
              clickElementListRef.current.push({
                elementId: bodyElement.uuid,
                invoiceNfe: bodyElement.file_nfe,
                invoiceXml: bodyElement.file_xml,
                paymentSlipFile: bodyElement.file_boleto,
              });
            }
          });
          setClickElementListId(selectedIndexes);
        }
      });
    });
  };

  const handleFetchInvoices = () => {
    const searchString = handlePopulateQuerystring();

    const tempLaunchStatus = parseInt(
      searchString.get("launch_status") as string
    );
    currEntryStatusRef.current =
      tempLaunchStatus !== 40
        ? (tempLaunchStatus as TInvoiceEntryStatus)
        : undefined;
    selectedEndDateRef.current = searchString.has("end_date")
      ? (searchString.get("end_date") as string)
      : undefined;
    selectedStartDateRef.current = searchString.has("start_date")
      ? (searchString.get("start_date") as string)
      : undefined;
    payablesRef.current = searchString.has("has_accounts_payable")
      ? Boolean(searchString.get("has_accounts_payable"))
      : undefined;
    goodsEntryRef.current = searchString.has("has_goods_receipt")
      ? Boolean(searchString.get("has_goods_receipt"))
      : undefined;

    if (
      selectedOrganizationRef.current &&
      selectedOrganizationRef.current.uuid /* &&
      localCurrentEntryStatus */ &&
      cancelTokenSource.current
    ) {
      let entryStatus1, entryStatus2, entryStatus3;
      switch (currEntryStatusRef.current) {
        case 10:
          entryStatus1 = 20;
          entryStatus2 = 30;
          break;
        case 20:
          entryStatus1 = 10;
          entryStatus2 = 30;
          break;
        case 30:
          entryStatus1 = 10;
          entryStatus2 = 20;
          break;
        default:
          entryStatus1 = 10;
          entryStatus2 = 20;
          entryStatus3 = 30;
          break;
      }

      dispatch(
        fetchInvoices({
          organizationUuid: selectedOrganizationRef.current.uuid,
          launch_status: entryStatus1 as TInvoiceEntryStatus,
          dateIssueEnd: selectedEndDateRef.current,
          dateIssueStart: selectedStartDateRef.current,
          has_accounts_payable: payablesRef.current,
          has_goods_receipt: goodsEntryRef.current,
          cancelToken: cancelTokenSource.current.token,
        })
      );
      dispatch(
        fetchInvoices({
          organizationUuid: selectedOrganizationRef.current.uuid,
          launch_status: entryStatus2 as TInvoiceEntryStatus,
          dateIssueEnd: selectedEndDateRef.current,
          dateIssueStart: selectedStartDateRef.current,
          has_accounts_payable: payablesRef.current,
          has_goods_receipt: goodsEntryRef.current,
          cancelToken: cancelTokenSource.current.token,
        })
      );

      if (currEntryStatusRef.current === undefined) {
        dispatch(
          fetchInvoices({
            organizationUuid: selectedOrganizationRef.current.uuid,
            launch_status: entryStatus3 as TInvoiceEntryStatus,
            dateIssueEnd: selectedEndDateRef.current,
            dateIssueStart: selectedStartDateRef.current,
            has_accounts_payable: payablesRef.current,
            has_goods_receipt: goodsEntryRef.current,
            cancelToken: cancelTokenSource.current.token,
          })
        );
      }

      setTimeout(() => {
        if (
          selectedOrganizationRef.current &&
          selectedOrganizationRef.current.uuid &&
          cancelTokenSource.current
        )
          dispatch(
            fetchInvoices({
              organizationUuid: selectedOrganizationRef.current.uuid,
              launch_status: currEntryStatusRef.current,
              dateIssueEnd: selectedEndDateRef.current,
              dateIssueStart: selectedStartDateRef.current,
              has_accounts_payable: payablesRef.current,
              has_goods_receipt: goodsEntryRef.current,
              page:
                currPageRef.current && !isNaN(currPageRef.current)
                  ? currPageRef.current
                  : undefined,
              cancelToken: cancelTokenSource.current?.token,
            })
          ).then(() => {
            {
              selectedOrganizationRef.current &&
                apiService
                  .getInvoiceUpdatedAt(
                    selectedOrganizationRef.current.uuid,
                    (cancelTokenSource.current as CancelTokenSource).token
                  )
                  .then((response) => {
                    dispatch(
                      setInvoicesUpdatedAt({
                        responseStatus: response.status,
                        date: response.data.updated_at,
                      })
                    );
                  });
            }
          });
      }, 50);
    }
  };

  const handleDownloadFileBatchOperation = (
    variant: "xml" | "nfe" | "paymentSlip"
  ) => {
    const querystrings = handleParseQuerystrings(window.location.search);

    const organizationId = querystrings.organization_id[0];

    cancelTokenSource.current = axios.CancelToken.source();

    switch (variant) {
      case "xml":
        if (cancelTokenSource.current) {
          setTimeout(() => {
            apiService
              .getInvoicesCompressedFilesDownloadLink(
                {
                  option: "xml",
                  organizationUuid: organizationId,
                  itemsUuid: clickElementListRef.current
                    .filter((element) => element.invoiceXml !== null)
                    .map((element) => element.elementId),
                },
                cancelTokenSource.current!.token
              )
              .then((response) => {
                dispatch(
                  setToastState({
                    variant: "loading",
                    shouldShow: false,
                    message: { title: "", description: "" },
                  })
                );

                window.open(response.data.url, "_blank");
              })
              .catch((_e) => {
                dispatch(
                  setToastState({
                    variant: "loading",
                    shouldShow: false,
                    message: { title: "", description: "" },
                  })
                );

                setTimeout(() => {
                  dispatch(
                    setToastState({
                      variant: "error",
                      shouldShow: true,
                      message: {
                        title: "Erro",
                        description: "Não foi possível concluir a ação",
                      },
                    })
                  );
                }, 100);
              });
          }, 1500);
        }

        // clickElementListRef.current.forEach((element) => {
        //   if (element.invoiceXml) window.open(element.invoiceXml, "_blank");
        // });
        break;
      case "nfe":
        if (cancelTokenSource.current) {
          dispatch(
            setToastState({
              variant: "loading",
              message: {
                title: "Compactando...",
                description:
                  "Após compactar os arquivos, o download começará automaticamente",
              },
              shouldShow: true,
            })
          );
          setTimeout(() => {
            apiService
              .getInvoicesCompressedFilesDownloadLink(
                {
                  option: "darf",
                  organizationUuid: organizationId,
                  itemsUuid: clickElementListRef.current
                    .filter((element) => element.invoiceNfe !== null)
                    .map((element) => element.elementId),
                },
                cancelTokenSource.current!.token
              )
              .then((response) => {
                dispatch(
                  setToastState({
                    variant: "loading",
                    shouldShow: false,
                    message: { title: "", description: "" },
                  })
                );

                window.open(response.data.url, "_blank");
              })
              .catch((_e) => {
                dispatch(
                  setToastState({
                    variant: "loading",
                    shouldShow: false,
                    message: { title: "", description: "" },
                  })
                );

                setTimeout(() => {
                  dispatch(
                    setToastState({
                      variant: "error",
                      shouldShow: true,
                      message: {
                        title: "Erro",
                        description: "Não foi possível concluir a ação",
                      },
                    })
                  );
                }, 100);
              });
          }, 1500);
        }

        // clickElementListRef.current.forEach((element) => {
        //   if (element.invoiceNfe) window.open(element.invoiceNfe, "_blank");
        // });
        break;
      case "paymentSlip":
        if (cancelTokenSource.current) {
          dispatch(
            setToastState({
              variant: "loading",
              message: {
                title: "Compactando...",
                description:
                  "Após compactar os arquivos, o download começará automaticamente",
              },
              shouldShow: true,
            })
          );
          setTimeout(() => {
            apiService
              .getPaymentSlipsCompressedFilesDownloadLink(
                {
                  organizationUuid: organizationId,
                  itemsUuid: clickElementListRef.current
                    .filter((element) => element.paymentSlipFile !== undefined)
                    .map((element) => element.elementId),
                },
                cancelTokenSource.current!.token
              )
              .then((response) => {
                dispatch(
                  setToastState({
                    variant: "loading",
                    shouldShow: false,
                    message: { title: "", description: "" },
                  })
                );

                window.open(response.data.url, "_blank");
              })
              .catch((_e) => {
                dispatch(
                  setToastState({
                    variant: "loading",
                    shouldShow: false,
                    message: { title: "", description: "" },
                  })
                );

                setTimeout(() => {
                  dispatch(
                    setToastState({
                      variant: "error",
                      shouldShow: true,
                      message: {
                        title: "Erro",
                        description: "Não foi possível concluir a ação",
                      },
                    })
                  );
                }, 100);
              });
          }, 1500);
        }
        // clickElementListRef.current.forEach((element) => {
        //   if (element.paymentSlipFile)
        //     window.open(element.paymentSlipFile, "_blank");
        // });
        break;
    }
  };

  const handleSettingManifestationBatchOperation = () => {
    cancelTokenSource.current = axios.CancelToken.source();

    const localSelectOrganization = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ORGANIZATION
    ) as TOrganization;
    selectedOrganizationRef.current = localSelectOrganization;

    const localCurrentPage = parseInt(
      handleGetDataFromLocalStorage(localStorageConstants.CURRENT_PAGE)
    );
    currPageRef.current = localCurrentPage;

    let manifestation: TInvoiceManifestastionStatus;
    switch (selected) {
      case "Confirma\u00E7\u00E3o da opera\u00E7\u00E3o (Confirmada)":
        manifestation = 20;
        break;
      case "Ci\u00EAncia da emiss\u00E3o (Ciente)":
        manifestation = 10;
        break;
      case "Desconhecimento da opera\u00E7\u00E3o (Desconhecida)":
        manifestation = 30;
        break;
      case "Operação não realizada (Recusada)":
        manifestation = 40;
        break;
    }

    Promise.allSettled(
      clickElementListRef.current.map(
        (element) =>
          new Promise((resolve, reject) => {
            const fullElement = fullDataRef.current?.find(
              (data) => element.elementId === data.uuid
            );

            if (fullElement.manifestation === null) {
              apiService
                .updateInvoiceManifestation(
                  element.elementId,
                  manifestation,
                  (cancelTokenSource.current as CancelTokenSource).token,
                  justificationText
                )
                .then((response) => resolve(response))
                .catch((e) => reject(e));
            } else {
              reject("");
            }
          })
      )
    )
      .then(() => {
        handleFetchInvoices();
      })
      .catch((e: any) => {
        if (e !== "") {
          console.error("error: ", e);
          dispatch(
            setToastState({
              variant: "error",
              shouldShow: true,
              message: {
                title: "Erro ao fazer manifestação",
                description:
                  "Ocorreu um erro ao fazer manifestação, tente novamente",
              },
            })
          );
        }
      })
      .finally(() => {
        setSelected(
          "Confirmação da operação (Confirmada)" as TManifestationStatusOptions
        );
        if (onAction) onAction();
      });

    if (theadCheckboxSetSelectedRef.current)
      theadCheckboxSetSelectedRef.current("inactive");
    setClickElementListId(["none"]);
    clickElementListRef.current = [];
    setShouldShowManifestationModal(false);
  };

  const handlePaginationBackwardState = () => {
    // setActivePageIndex((prev) => {
    //   const value = prev - 1;
    //   footer?.pagination?.onClick(value + 1);
    //   return value;
    // });
    if (footer?.customPagination?.buttonBackward) {
      footer.customPagination.buttonBackward.onClick();
    }
  };

  const handlePaginationForwardState = () => {
    // setActivePageIndex((prev) => {
    //   const value = prev + 1;
    //   footer?.pagination?.onClick(value + 1);
    //   return value;
    // });
    if (footer?.customPagination?.buttonForward) {
      footer.customPagination.buttonForward.onClick();
    }
  };

  const handleRenderFooterCustomPaginationButtons = () => {
    if (footer?.customPagination) {
      return footer.customPagination.pages.map((page, index) => (
        <Button
          key={index}
          icon="onlyIcon"
          iconType="string"
          variant="secondary"
          text={`${page.value}`}
          onClick={() => {
            page.onClick();
          }}
          className={
            page.value === footer.customPagination?.currentPage ? "active" : ""
          }
        />
      ));
    }
    return <></>;
  };

  useEffect(() => {
    if (selectType.type === "multiSelect") {
      fullDataRef.current = selectType.fullData;

      // console.log(
      //   "full data ref: ",
      //   fullDataRef.current,
      //   ", select type full data: ",
      //   selectType.fullData,
      //   ", select type initial data: ",
      //   selectType.initialSelectedState
      // );

      if (
        selectType.initialSelectedState &&
        selectType.initialSelectedState.length > 0 &&
        clickElementListId[0] !== undefined &&
        clickElementListId[0].length === 0
      ) {
        const ids = selectType.initialSelectedState.map(
          (initialSelectedState) => initialSelectedState.uuid as string
        );

        clickElementListRef.current = fullDataRef.current
          .map((element) => {
            return {
              elementId: element.uuid,
              invoiceNfe: element.file_nfe,
              invoiceXml: element.file_xml,
              paymentSlipFile: element.file_boleto,
            };
          })
          .filter((element) => ids.includes(element.elementId));

        // console.log("setting intial state: ", ids);

        setClickElementListId(ids);
      }
    }
  }, [selectType.fullData, selectType.initialSelectedState]);

  useEffect(() => {
    // console.log(
    //   "table body has been updated, click element list id: ",
    //   clickElementListId,
    //   ", click element list ref: ",
    //   clickElementListRef.current
    // );

    if (clickElementListId.length === 0 && clickElementListRef.current[0]) {
      const rescuedValue = forceSelectElementsRef.current.find(
        (element) =>
          element.elementId === clickElementListRef.current[0].elementId
      );
      if (rescuedValue) {
        setClickElementListId([rescuedValue.elementId]);
        setTimeout(() => {
          rescuedValue.setSelectElement("active");
        }, 20);
      }
    }

    if (clickElementListId.includes("all")) {
      if (forceSelectElementsRef.current)
        forceSelectElementsRef.current.forEach((element) => {
          if (element.isClicked !== "active")
            element.setSelectElement("active");
        });
    } else if (clickElementListId.includes("")) {
    } else if (clickElementListId.length > 0) {
      setTimeout(() => {
        // console.log(
        //   "force select elements ref: ",
        //   forceSelectElementsRef.current
        // );
        if (forceSelectElementsRef.current)
          forceSelectElementsRef.current.forEach((element) => {
            if (clickElementListId.includes(element.elementId)) {
              element.setSelectElement("active");
            }
          });
      });
    }
  }, [tableBody]);

  useEffect(() => {
    if (clickElementListId.includes("none")) {
      if (forceSelectElementsRef.current)
        forceSelectElementsRef.current.forEach((element) => {
          if (element.isClicked !== "") element.setSelectElement("");
        });
    }
    // console.log(
    //   "click element list id: ",
    //   clickElementListId,
    //   ", click element list ref: ",
    //   clickElementListRef.current
    // );
  }, [clickElementListId]);

  useEffect(() => {
    setShouldDecreaseHeight(false);
    setTimeout(() => {
      const bodyElement = document.getElementsByTagName("body")[0];
      if (bodyElement) {
        if (bodyElement.scrollHeight > bodyElement.clientHeight) {
          setShouldDecreaseHeight(true);
        }
      }
    }, 500);

    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  return (
    <Container id={tableContainerId}>
      {search && (
        <Search
          variant="default"
          placeholder={search.placeholder}
          id={search.id}
          onSearch={search.onSearch}
        />
      )}

      {selectType.type === "multiSelect" &&
        !disableBatchOperations &&
        clickElementListId.length > 0 &&
        clickElementListId[0].length > 0 &&
        !clickElementListId.includes("none") && (
          <BatchOperationsContainer
            key={
              clickElementListRef.current.length +
              "-batchOperationContainer-" +
              clickElementListId.length
            }
          >
            <div className="selectedInfoArea">
              <p>
                {clickElementListRef.current.length} selecionado
                {clickElementListRef.current.length === 1 ? "" : "s"}
              </p>
            </div>
            {window.location.pathname.includes("invoices") && (
              <>
                <Button
                  variant="white"
                  icon="noIcon"
                  text="Baixar XML"
                  disabled={
                    !clickElementListRef.current.some(
                      (element) => element.invoiceXml !== null
                    )
                  }
                  onClick={() => {
                    handleDownloadFileBatchOperation("xml");
                  }}
                />
                <Button
                  variant="white"
                  icon="noIcon"
                  text="Baixar DANFE"
                  disabled={
                    !clickElementListRef.current.some(
                      (element) => element.invoiceNfe !== null
                    )
                  }
                  onClick={() => {
                    handleDownloadFileBatchOperation("nfe");
                  }}
                />
                <Button
                  variant="white"
                  icon="noIcon"
                  text="Alterar status manifestação"
                  onClick={() => {
                    setShouldShowManifestationModal(true);
                  }}
                  disabled={
                    !clickElementListRef.current.some(
                      (element) =>
                        fullDataRef.current?.find(
                          (data) => element.elementId === data.uuid
                        ).manifestation === null
                    )
                  }
                />
              </>
            )}
            {window.location.pathname.includes("payment-slip") && (
              <>
                <Button
                  variant="white"
                  icon="noIcon"
                  text="Baixar Boleto"
                  disabled={
                    !clickElementListRef.current.some(
                      (element) => element.paymentSlipFile !== null
                    )
                  }
                  onClick={() => {
                    handleDownloadFileBatchOperation("paymentSlip");
                  }}
                />
              </>
            )}
            {shouldShowManifestationModal && (
              <ManifestationModal
                $shouldshow={shouldShowManifestationModal ? "show" : "hide"}
              >
                <h2>Alterar status manifestação</h2>

                <Dropdown
                  button={{
                    variant: "unlabeled",
                    placeholder: "",
                    labels: ["Confirmação da operação (Confirmada)"],
                  }}
                  menu={{
                    variant: "unlabeled",
                    options: [
                      "Confirmação da operação (Confirmada)",
                      "Ciência da emissão (Ciente)",
                      "Desconhecimento da operação (Desconhecida)",
                      "Operação não realizada (Recusada)",
                    ],
                    search: false,
                    forceSelectOptionsByIndex: [0],
                    onSelect(index) {
                      setSelected(index as TManifestationStatusOptions);
                    },
                  }}
                />
                {[
                  "Desconhecimento da operação (Desconhecida)",
                  "Operação não realizada (Recusada)",
                ].includes(selected) && (
                  <TextAreaContainer
                    $placeholder="Justificativa (mínimo de 15 caracteres) "
                    $justificationText={justificationText}
                    role="button"
                    onClick={() => {
                      if (textAreaRef.current !== null)
                        textAreaRef.current.focus();
                    }}
                  >
                    <TextArea
                      ref={textAreaRef}
                      minLength={15}
                      maxLength={TEXT_AREA_MAX_lENGTH}
                      onInput={handleTextAreaOnInput}
                    />
                    <span className="mandatory">&nbsp;*</span>
                  </TextAreaContainer>
                )}
                <FilterArea>
                  <div className="buttonsArea">
                    <Button
                      variant="secondary"
                      icon="noIcon"
                      text="Cancelar"
                      onClick={() => {
                        setShouldShowManifestationModal(false);
                        setSelected("Confirmação da operação (Confirmada)");
                      }}
                    />
                    <Button
                      disabled={
                        [
                          "Desconhecimento da operação (Desconhecida)",
                          "Operação não realizada (Recusada)",
                        ].includes(selected) &&
                        (justificationText === undefined ||
                          justificationText.length < 15)
                      }
                      variant="primary"
                      icon="checkmark"
                      iconPosition="left"
                      text="Salvar alteração"
                      onClick={handleSettingManifestationBatchOperation}
                    />
                  </div>
                </FilterArea>
              </ManifestationModal>
            )}
          </BatchOperationsContainer>
        )}

      <TableContainer
        $isBatchOperationsContainerVisible={
          selectType.type === "multiSelect" &&
          !disableBatchOperations &&
          clickElementListId.length > 0 &&
          clickElementListId[0].length > 0 &&
          !clickElementListId.includes("none")
        }
        id={tableId}
      >
        <THead
          onSelect={(clickStatus, setSelected) => {
            theadCheckboxSetSelectedRef.current = setSelected;

            switch (clickStatus) {
              case "all":
                forceSelectElementsRef.current.forEach((element) => {
                  element.setSelectElement("active");
                });

                const ids: string[] = [];
                selectedRowsRef.current = [];
                if (fullDataRef.current) {
                  clickElementListRef.current = fullDataRef.current
                    .map((element) => {
                      if (!ids.includes(element.uuid)) {
                        selectedRowsRef.current.push({
                          clickedElementId: element.uuid,
                          isChecked: true,
                        });
                        ids.push(element.uuid);
                        return {
                          elementId: element.uuid,
                          invoiceNfe: element.file_nfe,
                          invoiceXml: element.file_xml,
                          paymentSlipFile: element.file_boleto,
                        };
                      }
                      return undefined;
                    })
                    .filter(
                      (element) => element !== undefined
                    ) as typeof clickElementListRef.current;
                  // console.log(
                  //   "full data ref: ",
                  //   fullDataRef.current,
                  //   ", clicked element list: ",
                  //   clickElementListRef.current
                  // );
                }
                setClickElementListId(["all", ...ids]);

                if (onSelect) onSelect([{ elementId: "all" }]);
                break;
              case "none":
                setClickElementListId(["none"]);
                clickElementListRef.current = [];
                selectedRowsRef.current = [];
                if (onSelect) onSelect([{ elementId: "none" }]);
                break;
            }
          }}
          selectType={
            emptyState?.isEmptyState ? { type: "noSelect" } : selectType
          }
          tableHead={tableHead}
          gridTemplateColumns={(() => {
            const result = columns.tableHead.width
              .map((width) => width)
              .join(" ");
            return result;
          })()}
        />
        {emptyState?.isEmptyState ? (
          <EmptyStateTBody className="emptyState">
            <tr>
              <td colSpan={tableHead.value.length + 2}>
                <div>
                  <SVG src={emptyStateSVG} />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={tableHead.value.length + 2}>
                <div>
                  <h2>{emptyState.title}</h2>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={tableHead.value.length + 2}>
                <div>
                  <p>{emptyState.description}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={tableHead.value.length + 2}>
                <div></div>
              </td>
            </tr>
          </EmptyStateTBody>
        ) : (
          <TBody
            tbodyRef={tbodyRef}
            gridTemplateColumns={columns.tableBody.width
              .map((width) => width)
              .join(" ")}
            // setSelectedRows={setSelectedRows}
            hasFooter={(footer && "hasFooter") || "noFooter"}
            forceSelectElementRef={forceSelectElementsRef}
            onRowSelect={handleOnSelectRow}
            selectType={selectType}
            tableBody={tableBody}
            borderColor={borderColor}
            row={row}
            secondRow={secondRow}
          />
        )}
        {!emptyState?.isEmptyState && footer && (
          <>
            <TableFooter
              $isFixedAtTheBottom={footer.customPagination?.isFixedAtTheBottom}
              $customStyles={footer?.customStyle}
            >
              <tr>
                <td colSpan={tableHead.value.length + 3}>
                  <div id={tfootDataId} className="footerDataContainer">
                    <div className="leftSideFooterDataContainer">
                      {footer.leftSideDescription && (
                        <>
                          <p>Exibir </p>
                          <Dropdown
                            button={{
                              variant: "unlabeled",
                              labels: (() => {
                                const querystring = handleParseQuerystrings(
                                  window.location.search
                                );

                                const options = ["100", "150", "200"];

                                const itemsPerPage = querystring.items_per_page;

                                if (
                                  querystring.items_per_page &&
                                  options.includes(itemsPerPage[0])
                                ) {
                                  return [
                                    options[options.indexOf(itemsPerPage[0])],
                                  ];
                                }
                                return [options[0]];
                              })(),
                              placeholder: "",
                            }}
                            menu={{
                              variant: "unlabeled",
                              options: ["100", "150", "200"],
                              forceSelectOptionsByIndex: (() => {
                                const querystring = handleParseQuerystrings(
                                  window.location.search
                                );

                                const options = ["100", "150", "200"];

                                const itemsPerPage = querystring.items_per_page;

                                if (
                                  querystring.items_per_page &&
                                  options.includes(itemsPerPage[0])
                                ) {
                                  return [options.indexOf(itemsPerPage[0])];
                                }
                                return [0];
                              })(),
                              onSelect(selected) {
                                if (footer.onSelectItemsPerPage)
                                  footer.onSelectItemsPerPage(
                                    parseInt(selected)
                                  );
                              },
                              search: false,
                            }}
                          />
                          <p> resultados por página</p>
                          {footer.customPagination &&
                            footer.customPagination.pages.length < 12 && (
                              <p>{footer.leftSideDescription}</p>
                            )}
                        </>
                      )}
                    </div>
                    <div className="rightSideFooterDataContainer">
                      {footer.description &&
                        footer.customPagination &&
                        footer.customPagination.pages.length < 6 && (
                          <p>{footer.description}</p>
                        )}
                      <Button
                        icon="onlyIcon"
                        iconType="angleLeft"
                        variant="secondary"
                        onClick={handlePaginationBackwardState}
                        // disabled={activePageIndex - 1 < 0}
                        disabled={
                          footer.customPagination?.buttonBackward?.disabled
                        }
                      />
                      {/* {handleRenderFooterPaginationButtons()} */}
                      {handleRenderFooterCustomPaginationButtons()}
                      <Button
                        icon="onlyIcon"
                        iconType="angleRight"
                        variant="secondary"
                        onClick={handlePaginationForwardState}
                        disabled={
                          // activePageIndex + 1 > initialFooterArray.length - 1
                          footer.customPagination?.buttonForward?.disabled
                        }
                      />
                    </div>
                  </div>
                </td>
              </tr>
              {footer.formButtons && (
                <tr>
                  <td
                    colSpan={tableHead.value.length + 3}
                    className="footerFormButtonContainer"
                  >
                    {footer.formButtons.buttons.map((buttonProps, index) => (
                      <Button key={index} {...buttonProps} />
                    ))}
                  </td>
                </tr>
              )}
            </TableFooter>
            {footer && footer.formButtons ? (
              <div style={{ height: "168px" }} />
            ) : (
              !shouldDecreaseHeight && <div style={{ height: "80px" }} />
            )}
          </>
        )}
      </TableContainer>
    </Container>
  );
};

export default TableGrid;
