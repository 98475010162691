import { /* useCallback, */ useEffect, useId, useRef, useState } from "react";
import {
  Container,
  SearchBarSection,
  SearchBarSectionSubcontainer,
} from "./styles";
import SearchBar from "../../../../../components/ui-v2/Inputs/Search";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import DropdownFilter from "../../../../../components/ui-v2/DropdownFilter";
import {
  /* Table , */ TCellNotTagMiscellaneous,
  TCellStyleVariants,
  TTableBody,
  TText,
} from "../../../../../components/ui-v2/TableGrid";
import {
  breakStringPreserveSpaces,
  cnpjMask,
  // handleFormatDateAndTime,
  handleSortQuerystring,
} from "../../../../../utils/formatters";

import {
  TInvoice,
  TInvoiceEntryStatus,
  TInvoiceManifestastionStatus,
  TPaymentSlip,
  setAttachedPaymentSlips,
  setInvoicesUpdatedAt,
  setPaymentSlipsUpdatedAt,
  setToastState,
} from "../../../../../redux/reducers/globalState";
import { useTypedDispatch } from "../../../../../hooks/useTypedDispatch";
import { fetchInvoices, fetchPaymentSlips } from "../../../../../redux/actions";
import {
  CALENDAR_CONTENT_OPTIONS,
  TCalendarMenuOptions,
} from "../../../../../components/ui-v2/DropdownFilter/Menu";
import { sub } from "date-fns/sub";
import { format } from "date-fns/format";
import { parse } from "date-fns/parse";
import {
  handleParseQuerystrings,
  handleParseToDateCalendarOptions,
  handlePopulateQuerystring,
  handleRevertTranslateCalendarOptions,
  handleSetDataToLocalStorage,
  handleSetEndDate,
  handleTranslateCalendarOptions,
} from "../../../../../utils/miscellaneous";
import { localStorageConstants } from "../../../../../utils/constants";
import TableGrid from "../../../../../components/ui-v2/TableGrid";
import apiService from "../../../../../services/api";
import { useNavigate } from "react-router-dom";
import { TButtonIconVariants, TButtonProps } from "../../../Button";
import { UilAngleDown, UilCopyAlt } from "@iconscout/react-unicons";
import AttachmentMenu from "../../../Menus/AttachmentMenu";
import { ROUTES } from "../../../../../router";
import Modal from "../..";
import Tooltip from "../../../Tooltip";
import axios, { CancelTokenSource } from "axios";

export type TLinkInvoiceProps = {
  footer: {
    text?: TText[];
    buttons: TButtonProps<TButtonIconVariants>[];
  };
  onClear: (clearFunction: () => void) => void;
};

const LinkInvoice: React.FC<TLinkInvoiceProps> = ({ footer, onClear }) => {
  const dispatch = useTypedDispatch();

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const {
    invoiceState,
    selectOrganization,
    selectedOrganizationWithPermissions,
    attachedPaymentSlips,
  } = useTypedSelector((state) => state.globalState.data);

  const startDateRef = useRef<string | undefined>();
  format(sub(new Date(), { days: 30 }), "yyyy-MM-dd");
  const endDateRef = useRef<string | undefined>();
  format(new Date(), "yyyy-MM-dd");
  const entryStatusRef = useRef<TInvoiceEntryStatus | undefined>(10);
  const payablesRef = useRef<boolean | undefined>();
  const goodsEntryRef = useRef<boolean | undefined>();
  const pageRef = useRef(1);
  const itemsPerPageRef = useRef(100);
  const searchRef = useRef<string | undefined>();

  const allInvoicesRef = useRef<TInvoice[]>([]);

  const searchBarContainerId = useId();

  const [selectedInvoiceRows, setSelectedInvoiceRows] = useState<TInvoice[]>(
    []
  );

  const [paymentSlipToBreakLink, setPaymentSlipToBreakLink] = useState<
    | {
        target: TPaymentSlip;
        origin: TInvoice;
      }
    | undefined
  >();

  const [shouldShowAttachmentMenu, setShouldShowAttachmentMenu] = useState("");

  const [shouldUpdateComponent, setShouldUpdateComponent] = useState(false);

  const [shouldRejectFilledEntryStatus /* setShouldRejectFilledEntryStatus */] =
    useState(false);

  const navigate = useNavigate();

  const entryStatusOptions = [
    "Sem lançamentos",
    "Contas a pagar lançada",
    "Entrada de mercadorias lançada",
  ];

  // const handleStringDateAndTime = (stringDate: string) => {
  //   if (stringDate?.length > 0) {
  //     const { formatDate, formatTime } = handleFormatDateAndTime(stringDate);
  //     return formatDate + " - " + formatTime;
  //   }
  // };

  const handleFormatStatusCell = (
    status: TInvoiceManifestastionStatus | null
  ) => {
    switch (status) {
      case 10:
        return { style: "blue", value: "Ciente", icon: "noIcon" };
      case 20:
        return { style: "green", value: "Confirmada", icon: "noIcon" };
      case 30:
        return { style: "gray", value: "Desconhecida", icon: "noIcon" };
      case 40:
        return { style: "red", value: "Recusada", icon: "noIcon" };
      case null:
        return { style: "default", value: " - " };
    }
  };

  const rowFormatter = (invoice: TInvoice, index: number) => {
    return [
      { style: "default", value: invoice.number ? invoice?.number : "-" },
      {
        style: "default",
        value: invoice.date_issue
          ? format(invoice?.date_issue, "dd/MM/yyyy")
          : "-",
      },
      {
        style: "miscellaneous",
        tooltip: invoice.issuer.name && {
          text: (() => {
            const values = breakStringPreserveSpaces(invoice.issuer.name, 24);

            return values.map((value, index) => ({
              style: "default",
              text: value,
              shouldBreakLine: index !== values.length - 1,
            }));
          })(),
          customTopPosition: index * 96 + 85,
          hasScrollableRef: true,
        },
        values: [
          {
            style: "bold",
            text: invoice.issuer.name ? invoice?.issuer.name : "-",
            shouldBreakLine: true,
          },
          {
            style: "default",
            text: invoice.issuer.document_number
              ? cnpjMask(invoice?.issuer.document_number)
              : "-",
            shouldBreakLine: false,
          },
        ],
      },
      {
        style: "default",
        value: invoice.amount ? "R$ " + invoice?.amount.replace(".", ",") : "-",
      },
      handleFormatStatusCell(invoice?.manifestation),
    ] as TTableBody<TCellStyleVariants>[];
  };

  const handleSecondRowLeftSideCustomNodeRender = (
    invoice: TInvoice,
    index: number
  ) => {
    return (
      <span>
        <Tooltip
          text={[{ text: "Copiar", style: "default", shouldBreakLine: false }]}
          customTopPosition={150 + 97 * index}
        >
          <button
            role="button"
            onClick={() => {
              navigator.clipboard.writeText(invoice.access_key).then(() => {
                dispatch(
                  setToastState({
                    variant: "success",
                    message: {
                      title: "Sucesso",
                      description: "Chave de acesso copiada",
                    },
                    shouldShow: true,
                  })
                );
              });
            }}
          >
            <UilCopyAlt />
          </button>
        </Tooltip>
      </span>
    );
  };

  const handleSecondRowRightSideCustomNodeRender = (
    invoice: TInvoice,
    rowIndex: number
  ) => {
    return invoice.boletos_exists ? (
      <span>
        <button
          role="button"
          onClick={() => {
            apiService
              .getInvoiceAttachedPaymentSlips(
                invoice.uuid,
                (cancelTokenSource.current as CancelTokenSource).token
              )
              .then((response: any) => {
                dispatch(setAttachedPaymentSlips(response.data));
                setShouldShowAttachmentMenu(`${rowIndex}`);
                // positionRef.current = document
                //   .getElementById("link-container-" + rowIndex)
                //   ?.getBoundingClientRect();
              });
          }}
        >
          Ver boletos <UilAngleDown />
        </button>

        <AttachmentMenu
          position={{ top: 214 + 100 * rowIndex - 3 * rowIndex + "px" }}
          shouldShowMenu={
            shouldShowAttachmentMenu === `${rowIndex}` &&
            attachedPaymentSlips.length > 0
          }
          onClickAway={() => {
            setShouldShowAttachmentMenu("");
          }}
          // items={attachedInvoices.map((item) =>
          //   test(item, rowIndex, secondRow)
          // )}
          items={attachedPaymentSlips.map((paymentSlip: TPaymentSlip) => {
            // console.log("payment slip label: ", paymentSlip.label);
            return {
              onDelete:
                paymentSlip.fintera_id && paymentSlip.fintera_id.length > 0
                  ? undefined
                  : () => {
                      setPaymentSlipToBreakLink({
                        target: paymentSlip,
                        origin: invoice,
                      });
                    },
              name: paymentSlip.label,
              searchParam: paymentSlip.bar_code,
              url:
                ROUTES.PAYMENT_SLIP +
                window.location.search +
                "&period=all-time",
            };
          })}
          // onAction={{
          //   callback: () => {
          //     setInvoiceToAttachLinks(invoice);
          //     setShouldOpenLinkPaymentSlipModal(true);
          //   },
          //   text: "Vincular boleto",
          // }}
        />
      </span>
    ) : (
      <span>
        <p>Nenhum boleto vinculado</p>
        {/* <button
          role="button"
          onClick={() => {
            setInvoiceToAttachLinks(invoice);
            setShouldOpenLinkPaymentSlipModal(true);
          }}
        >
          <UilLink />
          &nbsp;Vincular
        </button> */}
      </span>
    );
  };

  const secondRowFormatter = (invoice: TInvoice) => ({
    style: "miscellaneous",
    values: [
      {
        style: "default",
        text: "Chave de acesso: ",
        shouldBreakLine: false,
      },
      {
        style: "faded",
        text: invoice.access_key,
        shouldBreakLine: false,
      },
    ],
  });

  const handleCalendarOnSelect = (selected: string[]) => {
    const select = selected as TCalendarMenuOptions[];

    const searchString = handlePopulateQuerystring();

    if (CALENDAR_CONTENT_OPTIONS.includes(select[0])) {
      const tempStartDate = handleParseToDateCalendarOptions(select[0]);

      if (selectOrganization) {
        if (tempStartDate === undefined) {
          startDateRef.current = undefined;
          endDateRef.current = undefined;

          handleSetDataToLocalStorage(
            localStorageConstants.INVOICE_FILTER_START_DATE,
            undefined
          );

          handleSetDataToLocalStorage(
            localStorageConstants.INVOICE_FILTER_END_DATE,
            undefined
          );

          searchString.delete("start_date");
          searchString.delete("end_date");

          fetchInvoicesByAllEntryStatus();
          navigate("./" + handleSortQuerystring(searchString));
        } else {
          const formatTempStartDate = format(tempStartDate, "yyyy-MM-dd");
          if (formatTempStartDate !== startDateRef.current) {
            const formatEndDate = handleSetEndDate(select[0]);

            startDateRef.current = formatTempStartDate;

            endDateRef.current = formatEndDate;

            handleSetDataToLocalStorage(
              localStorageConstants.INVOICE_FILTER_START_DATE,
              formatTempStartDate
            );

            handleSetDataToLocalStorage(
              localStorageConstants.INVOICE_FILTER_END_DATE,
              formatEndDate
            );

            searchString.set(
              "period",
              handleTranslateCalendarOptions(
                select[0] as TCalendarMenuOptions
              ) as string
            );

            fetchInvoicesByAllEntryStatus(formatEndDate);
            navigate("./" + handleSortQuerystring(searchString));
          }
        }
      }
    } else {
      const dates = select[0].split(" - ");
      const tempStartDate = format(
        parse(dates[0], "dd/MM/yyyy", new Date()),
        "yyyy-MM-dd"
      );
      const tempEndDate = format(
        parse(dates[1], "dd/MM/yyyy", new Date()),
        "yyyy-MM-dd"
      );

      handleSetDataToLocalStorage(
        localStorageConstants.INVOICE_FILTER_START_DATE,
        tempStartDate
      );

      handleSetDataToLocalStorage(
        localStorageConstants.INVOICE_FILTER_END_DATE,
        tempEndDate
      );

      if (tempStartDate !== startDateRef.current)
        startDateRef.current = tempStartDate;
      if (tempEndDate !== endDateRef.current) endDateRef.current = tempEndDate;

      searchString.set("end_date", tempEndDate);
      searchString.set("start_date", tempStartDate);

      fetchInvoicesByAllEntryStatus();
      navigate("./" + handleSortQuerystring(searchString));
    }
  };

  const handleEntryStatusCustomFilter = (
    index: string,
    _selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
    selectDataRef: React.MutableRefObject<string[]>,
    prevSelectDataRef: React.MutableRefObject<string[]>,
    setShouldUpdate: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    let prevSelect = [];
    if (goodsEntryRef.current) {
      prevSelect.push("2");
    }
    if (payablesRef.current) {
      prevSelect.push("1");
    }
    if (payablesRef.current === false && goodsEntryRef.current === false) {
      prevSelect = ["0"];
    }

    prevSelectDataRef.current = prevSelect.join().split(",");

    setSelected((prev) => {
      let value = prev;
      if (value.includes(index)) {
        const elementToRemoveIndex = value.indexOf(index);
        value.splice(elementToRemoveIndex, 1);
      } else if (prevSelectDataRef.current.includes(index)) {
        const elementToRemoveIndex = prevSelectDataRef.current.indexOf(index);
        prevSelectDataRef.current.splice(elementToRemoveIndex, 1);
        value = [...prev, ...prevSelectDataRef.current];
      } else {
        value.push(index);
      }

      if (value.length > 1 && value.includes("0")) {
        if (index === "0") value = ["0"];
        else {
          value.splice(value.indexOf("0"), 1);
        }
      }

      if (value.includes("")) value.splice(0, 1);

      selectDataRef.current = value;
      setShouldUpdate(true);
      setTimeout(() => {
        setShouldUpdate(false);
      }, 20);

      return value;
    });
  };

  const handleEntryStatusOnAction = (
    selectDataRef: React.MutableRefObject<string[]>
  ) => {
    let hasPayable, hasGoods;

    const queryStrings = handleParseQuerystrings(window.location.search);

    const searchString = handlePopulateQuerystring();

    if (queryStrings.organization_id) {
      searchString.set("organization_id", queryStrings.organization_id[0]);
    }

    if (selectDataRef.current.length) {
      if (selectDataRef.current.includes("2")) {
        hasGoods = true;
      }
      if (selectDataRef.current.includes("1")) {
        hasPayable = true;
      }
      if (
        selectDataRef.current.includes("0") &&
        selectDataRef.current.length === 1
      ) {
        hasPayable = false;
        hasGoods = false;
      }
      payablesRef.current = hasPayable;
      goodsEntryRef.current = hasGoods;

      if (hasPayable !== undefined)
        searchString.set("has_accounts_payable", `${payablesRef.current}`);

      if (hasGoods !== undefined)
        searchString.set("has_goods_receipt", `${goodsEntryRef.current}`);

      if (entryStatusRef.current) {
        searchString.set("launch_status", `${entryStatusRef.current ?? 40}`);
      } /* else {
        searchString.delete("launch_status");
      } */

      navigate("./" + handleSortQuerystring(searchString));
    } else {
      payablesRef.current = undefined;
      goodsEntryRef.current = undefined;

      searchString.delete("has_accounts_payable");
      searchString.delete("has_goods_receipt");

      if (entryStatusRef.current) {
        searchString.set("launch_status", `${entryStatusRef.current ?? 40}`);
      } /* else {
        searchString.delete("launch_status");
      } */

      navigate("./" + handleSortQuerystring(searchString));
    }
    fetchInvoicesByAllEntryStatus();
  };

  const handleEntryStatusOnCancel = () => {
    // payablesRef.current = undefined;
    // goodsEntryRef.current = undefined;
    // setShouldRejectFilledEntryStatus(true);
    // navigate("./");
    // fetchInvoicesByAllEntryStatus();
  };

  const fetchPaymentSlipsByAllEntryStatus = async () => {
    if (selectOrganization) {
      // let entryStatus1, entryStatus2;

      // switch (entryStatusRef.current) {
      //   case 10:
      //     entryStatus1 = 20;
      //     entryStatus2 = 30;
      //     break;
      //   case 20:
      //     entryStatus1 = 10;
      //     entryStatus2 = 30;
      //     break;
      //   case 30:
      //     entryStatus1 = 20;
      //     entryStatus2 = 10;
      //     break;
      // }

      // await dispatch(
      //   fetchPaymentSlips({
      //     // page: pageRef.current,
      //     organizationUuid: selectOrganization.uuid,
      //     dateIssueStart: startDateRef.current,
      //     dateIssueEnd: endDateRef.current,
      //     launch_status: entryStatus1 as TInvoiceEntryStatus,
      //     has_accounts_payable: payablesRef.current,
      //     has_goods_receipt: goodsEntryRef.current,
      //     search: searchRef.current,
      //   })
      // );
      // await dispatch(
      //   fetchPaymentSlips({
      //     // page: pageRef.current,
      //     organizationUuid: selectOrganization.uuid,
      //     dateIssueStart: startDateRef.current,
      //     dateIssueEnd: endDateRef.current,
      //     launch_status: entryStatus2 as TInvoiceEntryStatus,
      //     has_accounts_payable: payablesRef.current,
      //     has_goods_receipt: goodsEntryRef.current,
      //     search: searchRef.current,
      //   })
      // );
      setShouldUpdateComponent(true);
      setTimeout(async () => {
        if (cancelTokenSource.current)
          await dispatch(
            fetchPaymentSlips({
              page: pageRef.current,
              organizationUuid: selectOrganization.uuid,
              dateIssueStart: startDateRef.current,
              dateIssueEnd: endDateRef.current,
              // launch_status: entryStatusRef.current,
              has_accounts_payable: payablesRef.current,
              has_goods_receipt: goodsEntryRef.current,
              search: searchRef.current,
              cancelToken: cancelTokenSource.current.token,
            })
          ).then(() => {
            apiService
              .getPaymentSlipUpdatedAt(
                selectOrganization.uuid,
                (cancelTokenSource.current as CancelTokenSource).token
              )
              .then((response) => {
                if (response) {
                  dispatch(
                    setPaymentSlipsUpdatedAt({
                      responseStatus: response.status,
                      date: response.data.updated_at,
                    })
                  );
                }
              });
          });
        setShouldUpdateComponent(false);
      }, 50);
    }
  };

  const fetchInvoicesByAllEntryStatus = async (endDate?: string) => {
    if (selectOrganization) {
      // let entryStatus1, entryStatus2;

      // switch (entryStatusRef.current) {
      //   case 10:
      //     entryStatus1 = 20;
      //     entryStatus2 = 30;
      //     break;
      //   case 20:
      //     entryStatus1 = 10;
      //     entryStatus2 = 30;
      //     break;
      //   case 30:
      //     entryStatus1 = 20;
      //     entryStatus2 = 10;
      //     break;
      // }

      // dispatch(
      //   fetchInvoices({
      //     // page: pageRef.current,
      //     organizationUuid: selectOrganization.uuid,
      //     dateIssueStart: startDateRef.current,
      //     dateIssueEnd: endDateRef.current,
      //     launch_status: entryStatus1 as TInvoiceEntryStatus,
      //     has_accounts_payable: payablesRef.current,
      //     has_goods_receipt: goodsEntryRef.current,
      //     search: searchRef.current,
      //   })
      // );
      // dispatch(
      //   fetchInvoices({
      //     // page: pageRef.current,
      //     organizationUuid: selectOrganization.uuid,
      //     dateIssueStart: startDateRef.current,
      //     dateIssueEnd: endDateRef.current,
      //     launch_status: entryStatus2 as TInvoiceEntryStatus,
      //     has_accounts_payable: payablesRef.current,
      //     has_goods_receipt: goodsEntryRef.current,
      //     search: searchRef.current,
      //   })
      // );
      setTimeout(() => {
        if (cancelTokenSource.current)
          dispatch(
            fetchInvoices({
              page: pageRef.current,
              per_page: itemsPerPageRef.current,
              organizationUuid: selectOrganization.uuid,
              dateIssueStart: startDateRef.current,
              dateIssueEnd: endDate ?? endDateRef.current,
              // launch_status: entryStatusRef.current,
              has_accounts_payable: payablesRef.current,
              has_goods_receipt: goodsEntryRef.current,
              search: searchRef.current,
              cancelToken: cancelTokenSource.current.token,
            })
          ).then(() => {
            apiService
              .getInvoiceUpdatedAt(
                selectOrganization.uuid,
                (cancelTokenSource.current as CancelTokenSource).token
              )
              .then((response) => {
                dispatch(
                  setInvoicesUpdatedAt({
                    responseStatus: response.status,
                    date: response.data.updated_at,
                  })
                );
              });
          });
      }, 50);
    }
  };

  const handleEntryStatusForceSelectOptions = () => {
    const queryStrings = handleParseQuerystrings(window.location.search);

    const hasPayableCondition =
      queryStrings.has_accounts_payable &&
      queryStrings.has_accounts_payable[0] === "true";

    const hasGoodsCondition =
      queryStrings.has_goods_receipt &&
      queryStrings.has_goods_receipt[0] === "true";

    const hasNotEntryCondition =
      queryStrings.has_accounts_payable &&
      queryStrings.has_accounts_payable[0] === "false" &&
      queryStrings.has_goods_receipt &&
      queryStrings.has_goods_receipt[0] === "false";

    if (hasNotEntryCondition) {
      payablesRef.current = false;
      goodsEntryRef.current = false;
      return [0];
    }

    if (hasPayableCondition && hasGoodsCondition) {
      payablesRef.current = true;
      goodsEntryRef.current = true;

      return [1, 2];
    }

    if (hasPayableCondition) {
      payablesRef.current = true;
      goodsEntryRef.current = undefined;

      return [1];
    }

    if (hasGoodsCondition) {
      goodsEntryRef.current = true;
      payablesRef.current = undefined;

      return [2];
    }

    if (queryStrings.start_date && queryStrings.end_date) {
      // const temp1 = parse(queryStrings.start_date[0], "dd/MM/yyyy", new Date());
      // const temp2 = parse(queryStrings.end_date[0], "dd/MM/yyyy", new Date());

      // startDateRef.current = format(temp1, "yyyy-MM-dd");
      // endDateRef.current = format(temp2, "yyyy-MM-dd");

      startDateRef.current = queryStrings.start_date[0];
      endDateRef.current = queryStrings.end_date[0];
    }

    // if (queryStrings.search) searchRef.current = queryStrings.search[0];

    return undefined;
  };

  const handlePeriodForceSelectOptions = () => {
    const queryStrings = handleParseQuerystrings(window.location.search);

    if (queryStrings.start_date && queryStrings.end_date) {
      // const temp1 = parse(queryStrings.start_date[0], "dd/MM/yyyy", new Date());
      // const temp2 = parse(queryStrings.end_date[0], "dd/MM/yyyy", new Date());

      // startDateRef.current = format(temp1, "yyyy-MM-dd");
      // endDateRef.current = format(temp2, "yyyy-MM-dd");

      startDateRef.current = queryStrings.start_date[0];
      endDateRef.current = queryStrings.end_date[0];

      return [
        queryStrings.start_date[0].replace(
          /(\d{4})-(\d{2})-(\d{2})/,
          "$3/$2/$1"
        ),
        queryStrings.end_date[0].replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1"),
      ];
    }

    const calendarOptions = [
      ...CALENDAR_CONTENT_OPTIONS.map((string) => string.toLowerCase()),
    ];

    if (
      queryStrings.period &&
      calendarOptions.includes(
        handleRevertTranslateCalendarOptions(
          queryStrings.period[0] as TCalendarMenuOptions
        ) as string
      )
    ) {
      return [
        calendarOptions.indexOf(
          handleRevertTranslateCalendarOptions(
            queryStrings.period[0] as TCalendarMenuOptions
          ) as string
        ),
      ];
    }

    return undefined;
  };

  const updateAllInvoicesRef =
    /* useCallback( */
    async (_entryStatus?: number) => {
      if (selectOrganization) {
        cancelTokenSource.current = axios.CancelToken.source();
        // console.log("entry status: ", entryStatusRef.current);
        await apiService
          .getAllInvoices(
            {
              organizationUuid: selectOrganization.uuid,
              // launch_status: entryStatus ?? entryStatusRef.current,
            },
            cancelTokenSource.current.token
          )
          .then(async (response) => {
            allInvoicesRef.current = response;

            fetchInvoicesByAllEntryStatus().then(() => {
              // setShouldUpdateComponent(true);
              // setTimeout(() => {
              //   // console.log(
              //   //   "updating component, all invoices ref: ",
              //   //   allInvoicesRef.current
              //   // );
              //   setShouldUpdateComponent(false);
              // }, 40);
            });
          });
      }
    }; /* ,
    [selectOrganization]
  ) */

  useEffect(() => {
    updateAllInvoicesRef();
    return () => {
      const searchString = handlePopulateQuerystring();
      if (pageRef.current) searchString.set("page", "1");
      navigate("./" + handleSortQuerystring(searchString));

      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  // useEffect(() => {
  //   updateAllInvoicesRef();
  // }, [updateAllInvoicesRef]);

  useEffect(() => {
    onClear(() => {
      setSelectedInvoiceRows([]);
      setShouldUpdateComponent(true);
      setTimeout(() => {
        setShouldUpdateComponent(false);
      }, 100);
    });
  }, [invoiceState, selectedOrganizationWithPermissions]);

  return (
    <Container>
      <SearchBarSection>
        <SearchBar
          // timeoutAmount={1000}
          onClear={() => {
            searchRef.current = undefined;
            if (selectOrganization) {
              const queryStrings = handleParseQuerystrings(
                window.location.search
              );
              const searchString = handlePopulateQuerystring();
              if (queryStrings.organization_id) {
                searchString.set(
                  "organization_id",
                  queryStrings.organization_id[0]
                );
              }
              searchString.delete("search");

              if (entryStatusRef.current) {
                searchString.set(
                  "launch_status",
                  `${entryStatusRef.current ?? 40}`
                );
              } /* else {
                searchString.delete("launch_status");
              } */

              navigate("./" + handleSortQuerystring(searchString));

              fetchInvoicesByAllEntryStatus();

              // dispatch(
              //   fetchInvoices({
              //     search: searchRef.current,
              //     // page: pageRef.current,
              //     organizationUuid: selectOrganization.uuid,
              //     dateIssueStart: startDateRef.current,
              //     dateIssueEnd: endDateRef.current,
              //     has_accounts_payable: payablesRef.current,
              //     has_goods_receipt: goodsEntryRef.current,
              //     launch_status: entryStatusRef.current,
              //   })
              // );
            }
          }}
          // $ref={(ref, setSearchString) => {
          //   const queryStrings = handleParseQuerystrings(
          //     window.location.search
          //   );
          //   if (queryStrings.search && ref.current) {
          //     ref.current.value = queryStrings.search[0];
          //     setSearchString(queryStrings.search[0]);
          //   }
          // }}
          onSearch={(searchPhrase) => {
            searchRef.current = searchPhrase;
            // setTimeout(() => {
            if (selectOrganization) {
              const queryStrings = handleParseQuerystrings(
                window.location.search
              );
              const searchString = handlePopulateQuerystring();
              if (queryStrings.organization_id) {
                searchString.set(
                  "organization_id",
                  queryStrings.organization_id[0]
                );
              }
              if (searchRef.current.length > 0) {
                searchString.set("search", searchRef.current);
              } else {
                searchString.delete("search");
              }

              if (entryStatusRef.current) {
                searchString.set(
                  "launch_status",
                  `${entryStatusRef.current ?? 40}`
                );
              } /* else {
                searchString.delete("launch_status");
              } */

              navigate("./" + handleSortQuerystring(searchString));

              fetchInvoicesByAllEntryStatus();

              // dispatch(
              //   fetchInvoices({
              //     search: searchRef.current,
              //     // page: pageRef.current,
              //     organizationUuid: selectOrganization.uuid,
              //     dateIssueStart: startDateRef.current,
              //     dateIssueEnd: endDateRef.current,
              //     has_accounts_payable: payablesRef.current,
              //     has_goods_receipt: goodsEntryRef.current,
              //     launch_status: entryStatusRef.current,
              //   })
              // );
            }
            // }, 500);
          }}
          variant="default"
          placeholder="Buscar por NF-e ou Emitente"
        />
        <SearchBarSectionSubcontainer id={searchBarContainerId}>
          <DropdownFilter
            buttonVariant="calendar"
            menuVariant="calendar"
            onSelect={handleCalendarOnSelect}
            // key={shouldRejectFilledEntryStatus + ""}
            forceSelectOptionsByIndex={(() => {
              const result = handlePeriodForceSelectOptions();
              return shouldRejectFilledEntryStatus ||
                (result && typeof result[0] === "string")
                ? []
                : (result as number[]);
            })()}
            filled={(() => {
              const result = handlePeriodForceSelectOptions();

              return result === undefined
                ? result
                : typeof result[0] === "string"
                ? (result as string[])
                : [CALENDAR_CONTENT_OPTIONS[result[0]]];
            })()}
          />
          <DropdownFilter
            buttonVariant="checkCircle"
            placeholder="Lançamentos"
            content={entryStatusOptions}
            menuVariant="unlabeled"
            search={false}
            customFilter={handleEntryStatusCustomFilter}
            onAction={handleEntryStatusOnAction}
            onCancel={handleEntryStatusOnCancel}
            hasBottomBar
            key={shouldRejectFilledEntryStatus + ""}
            forceSelectOptionsByIndex={(() => {
              const result = handleEntryStatusForceSelectOptions();
              return shouldRejectFilledEntryStatus ? undefined : result;
            })()}
            filled={(() => {
              const result = handleEntryStatusForceSelectOptions();
              return result === undefined
                ? result
                : [...result.toString().replaceAll(",", "")];
            })()}
          />
        </SearchBarSectionSubcontainer>
      </SearchBarSection>

      {invoiceState && (
        <TableGrid
          key={shouldUpdateComponent + "-invoices-tablegrid"}
          row="doubleRow"
          disableBatchOperations
          columns={{
            tableHead: {
              // 24px 80px 150px 200px 240px 200px 160px
              width: [`24px 80px 150px 200px 240px 200px 160px`],
            },
            tableBody: {
              width: [`24px 80px 150px 200px 240px 200px 160px`],
            },
          }}
          onSelect={(selected) => {
            if (
              selected.length === 1 &&
              ["all", "none"].includes(selected[0].elementId)
            ) {
              if (selected[0].elementId === "all") {
                setSelectedInvoiceRows(invoiceState.results);
              } else {
                setSelectedInvoiceRows([]);
              }
            } else {
              setSelectedInvoiceRows(
                invoiceState.results.filter((invoice) =>
                  selected.some((element) => element.elementId === invoice.uuid)
                )
              );
            }
          }}
          onAction={() => {
            window.location.reload();
          }}
          emptyState={{
            isEmptyState: invoiceState?.results.length === 0,
            title: "Nenhuma nota encontrada",
            description: "Altere os filtros e realize a busca novamente",
          }}
          secondRow={{
            params: invoiceState?.results.map((invoice, index) => ({
              data: secondRowFormatter(invoice) as TCellNotTagMiscellaneous,
              leftSideCustomNode: (
                <>{handleSecondRowLeftSideCustomNodeRender(invoice, index)}</>
              ),
              rightSideCustomNode: (
                <>{handleSecondRowRightSideCustomNodeRender(invoice, index)}</>
              ),
            })),
          }}
          selectType={{ type: "multiSelect", fullData: allInvoicesRef.current }}
          tableHead={{
            value: [
              { style: "noIcon", value: "Número" },
              { style: "noIcon", value: "Data emissão" },
              { style: "noIcon", value: "Emitente" },
              { style: "noIcon", value: "Valor" },
              {
                style: "noIcon",
                value: "Status manifestação",
              },
            ],
          }}
          tableBody={(invoiceState?.results ?? []).map(
            (invoice: TInvoice, index: number) => ({
              elementId: invoice.uuid,
              file_nfe: invoice.file_nfe,
              file_xml: invoice.file_xml,
              body: rowFormatter(invoice, index),
            })
          )}
          footer={{
            customStyle: {
              // disable: true,
              width: "1068px",
            },
            formButtons: {
              buttons: [
                (() => {
                  const cancelButton = { ...footer.buttons[0] };

                  cancelButton.onClick = () => {
                    if (footer.buttons[0].onClick) {
                      footer.buttons[0].onClick();
                      setSelectedInvoiceRows([]);
                      setShouldUpdateComponent(true);
                      setTimeout(() => {
                        setShouldUpdateComponent(false);
                      }, 100);
                    }
                  };
                  return cancelButton;
                })(),
                (() => {
                  const actionButton = { ...footer.buttons[1] };
                  if (selectedInvoiceRows.length === 0) {
                    actionButton.disabled = true;
                    actionButton.text = "Confirmar selecionado";
                  }

                  if (selectedInvoiceRows.length > 0) {
                    actionButton.disabled = false;
                    actionButton.text = "Confirmar selecionado";
                  }

                  if (selectedInvoiceRows.length > 1) {
                    actionButton.text = "Confirmar selecionados";
                  }

                  actionButton.onClick = () => {
                    if (footer.buttons[1].onClick) {
                      footer.buttons[1].onClick({
                        selectedInvoices: selectedInvoiceRows,
                        clearSelectedInvoices: () => {
                          setShouldUpdateComponent(true);
                          setTimeout(() => {
                            setShouldUpdateComponent(false);
                          }, 100);
                        },
                      });
                    }
                  };

                  return actionButton;
                })(),
              ],
            },
            // description: `Mostrando ${invoiceState.results.length} de ${invoiceState.count} resultados`,
            // pagination: {
            //   buttonsAmount: 30,
            //   onClick(activeIndex) {
            //     // console.log("active index: ", activeIndex);
            //   },
            // },
            leftSideDescription:
              " | Total de " +
              invoiceState.count +
              (invoiceState.count > 1
                ? " registros encontrados"
                : " registro encontrado"),
            // description:
            //   "Mostrando " +
            //   invoiceState.results.length +
            //   " de " +
            //   invoiceState.results.length +
            //   " resultado" +
            //   (invoiceState.results.length > 1 ? "s" : ""),
            onSelectItemsPerPage(amount) {
              const searchString = handlePopulateQuerystring();

              pageRef.current = 1;

              searchString.set("page", pageRef.current.toString());

              searchString.set("items_per_page", amount.toString());

              navigate("./" + handleSortQuerystring(searchString));

              if (selectOrganization && cancelTokenSource.current) {
                dispatch(
                  fetchInvoices({
                    search: searchRef.current,
                    page: pageRef.current,
                    per_page: amount,
                    organizationUuid: selectOrganization.uuid,
                    // launch_status: entryStatusRef.current,
                    dateIssueStart: startDateRef.current,
                    dateIssueEnd: endDateRef.current,
                    has_accounts_payable: payablesRef.current,
                    has_goods_receipt: goodsEntryRef.current,
                    cancelToken: cancelTokenSource.current.token,
                  })
                );
              }
            },
            customPagination: invoiceState
              ? {
                  isFixedAtTheBottom: true,
                  currentPage: `${invoiceState?.current_page}`,
                  buttonBackward: {
                    disabled: invoiceState.current_page === 1,
                    onClick() {
                      // if (invoiceState.current_page === 1) {
                      //   pageRef.current = undefined;
                      // } else {
                      pageRef.current = invoiceState.current_page - 1;
                      // }

                      const queryStrings = handleParseQuerystrings(
                        window.location.search
                      );

                      if (queryStrings.items_per_page) {
                        itemsPerPageRef.current = parseInt(
                          queryStrings.items_per_page[0]
                        );
                      }

                      handleSetDataToLocalStorage(
                        localStorageConstants.CURRENT_PAGE,
                        pageRef.current
                      );

                      if (
                        selectOrganization &&
                        invoiceState.current_page !== 1 &&
                        cancelTokenSource.current
                      ) {
                        const searchString = handlePopulateQuerystring();
                        if (pageRef.current)
                          searchString.set("page", pageRef.current.toString());
                        navigate("./" + handleSortQuerystring(searchString));

                        dispatch(
                          fetchInvoices({
                            search: searchRef.current,
                            page: pageRef.current,
                            per_page: itemsPerPageRef.current,
                            organizationUuid: selectOrganization.uuid,
                            // launch_status: entryStatusRef.current,
                            dateIssueStart: startDateRef.current,
                            dateIssueEnd: endDateRef.current,
                            has_accounts_payable: payablesRef.current,
                            has_goods_receipt: goodsEntryRef.current,
                            cancelToken: cancelTokenSource.current.token,
                          })
                        );
                      }
                    },
                  },
                  buttonForward: {
                    disabled:
                      invoiceState.current_page === invoiceState.pages.length,
                    onClick() {
                      if (
                        invoiceState.current_page === invoiceState.pages.length
                      ) {
                        pageRef.current = invoiceState.current_page;
                      } else {
                        pageRef.current = invoiceState.current_page + 1;
                      }

                      const queryStrings = handleParseQuerystrings(
                        window.location.search
                      );

                      if (queryStrings.items_per_page) {
                        itemsPerPageRef.current = parseInt(
                          queryStrings.items_per_page[0]
                        );
                      }

                      handleSetDataToLocalStorage(
                        localStorageConstants.CURRENT_PAGE,
                        pageRef.current
                      );

                      if (
                        selectOrganization &&
                        invoiceState.current_page !==
                          invoiceState.pages.length &&
                        cancelTokenSource.current
                      ) {
                        const searchString = handlePopulateQuerystring();
                        if (pageRef.current)
                          searchString.set("page", pageRef.current.toString());
                        navigate("./" + handleSortQuerystring(searchString));

                        dispatch(
                          fetchInvoices({
                            search: searchRef.current,
                            page: pageRef.current,
                            per_page: itemsPerPageRef.current,
                            organizationUuid: selectOrganization.uuid,
                            // launch_status: entryStatusRef.current,
                            dateIssueStart: startDateRef.current,
                            dateIssueEnd: endDateRef.current,
                            has_accounts_payable: payablesRef.current,
                            has_goods_receipt: goodsEntryRef.current,
                            cancelToken: cancelTokenSource.current.token,
                          })
                        );
                      }
                    },
                  },
                  pages: invoiceState.pages.map((page) => {
                    return {
                      value: Object.keys(page)[0],
                      onClick() {
                        let temp =
                          Object.values(page)[0].match(/.*?page=(\d+)&per_/);

                        if (temp !== null && temp[1]) {
                          pageRef.current = parseInt(temp[1]);
                        } else {
                          pageRef.current = 1;
                        }

                        const queryStrings = handleParseQuerystrings(
                          window.location.search
                        );

                        if (queryStrings.items_per_page) {
                          itemsPerPageRef.current = parseInt(
                            queryStrings.items_per_page[0]
                          );
                        }

                        handleSetDataToLocalStorage(
                          localStorageConstants.CURRENT_PAGE,
                          pageRef.current
                        );

                        if (selectOrganization && cancelTokenSource.current) {
                          const searchString = handlePopulateQuerystring();
                          if (pageRef.current)
                            searchString.set(
                              "page",
                              pageRef.current.toString()
                            );
                          navigate("./" + handleSortQuerystring(searchString));

                          dispatch(
                            fetchInvoices({
                              search: searchRef.current,
                              page: pageRef.current,
                              per_page: itemsPerPageRef.current,
                              organizationUuid: selectOrganization.uuid,
                              // launch_status: entryStatusRef.current,
                              dateIssueStart: startDateRef.current,
                              dateIssueEnd: endDateRef.current,
                              has_accounts_payable: payablesRef.current,
                              has_goods_receipt: goodsEntryRef.current,
                              cancelToken: cancelTokenSource.current.token,
                            })
                          );
                        }
                      },
                    };
                  }),
                }
              : undefined,
          }}
        />
      )}

      <Modal
        key={paymentSlipToBreakLink?.target.uuid}
        title="Remover vínculo"
        icon={{ type: "breakLink", color: "red" }}
        main={{
          style: "textOnly",
          content: [
            {
              text: "Tem certeza que deseja remover o vínculo com este boleto?",
              style: "default",
              shouldBreakLine: false,
            },
          ],
        }}
        footer={{
          style: "default",
          buttons: [
            {
              variant: "secondary",
              icon: "noIcon",
              text: "Cancelar",
              onClick() {
                setPaymentSlipToBreakLink(undefined);
              },
            },
            {
              variant: "red",
              iconPosition: "left",
              icon: "checkmark",
              text: "Sim, remover",
              onClick() {
                if (paymentSlipToBreakLink) {
                  apiService
                    .detachPaymentSlipFromInvoice(
                      paymentSlipToBreakLink.origin.uuid,
                      paymentSlipToBreakLink.target.uuid,
                      (cancelTokenSource.current as CancelTokenSource).token
                    )
                    .then(() => {
                      setPaymentSlipToBreakLink(undefined);
                      dispatch(
                        setToastState({
                          variant: "success",
                          shouldShow: true,
                          message: {
                            title: "Sucesso",
                            description: "Vínculo removido",
                          },
                        })
                      );
                      fetchInvoicesByAllEntryStatus();
                      fetchPaymentSlipsByAllEntryStatus();
                    })
                    .catch(() => {
                      setPaymentSlipToBreakLink(undefined);
                      dispatch(
                        setToastState({
                          variant: "error",
                          shouldShow: true,
                          message: {
                            title: "Erro",
                            description:
                              "Não foi possível remover o vínculo, tente novamente",
                          },
                        })
                      );
                    });
                }
              },
            },
          ],
        }}
        shouldOpen={paymentSlipToBreakLink !== undefined}
        onClose={() => {
          setPaymentSlipToBreakLink(undefined);
        }}
      />
    </Container>
  );
};

export default LinkInvoice;
