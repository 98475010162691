// import { ReactNode } from "react";
import styled, { css } from "styled-components";

import {
  UilCheck,
  UilArrowRight,
  UilPlus,
  UilSignin,
  UilEllipsisV,
  UilPen,
  UilTrash,
  UilTimes,
  UilEye,
  UilEyeSlash,
  UilSync,
  UilAngleLeftB,
  UilAngleLeft,
  UilAngleRightB,
  UilAngleRight,
  UilImport,
  UilDollarSign,
  UilLink,
  UilSearch,
  UilTimesCircle,
  UilUpload,
  UilLayerGroup,
  UilExchange,
} from "@iconscout/react-unicons";

import NFEicon from "../../assets/images/nfe.svg";
import XMLicon from "../../assets/images/xml.svg";
// import goodsExpressBlueIcon from "../../assets/images/goodsCreated.svg";
import goodsExpressBlueAndClockIcon from "../../assets/images/goodsStocksEntering.svg";
import goodsExpressRedIcon from "../../assets/images/goodsFailed.svg";
import goodsExpressGreenIcon from "../../assets/images/goodsStocksEntered.svg";

import { fullRotate } from "../../utils/animations";
import SVG from "../ui/SVG";
import Tooltip from "./Tooltip";
import { TText } from "./TableGrid";
import Spinner from "./Spinner";
import { useRef } from "react";
import { Property } from "csstype";

const ACTION_BUTTONS = [
  "nfe",
  "xml",
  "payableOrange",
  "payableGreen",
  "goodsReceiptOrange",
  "goodsReceiptGreen",
  "goodsExpressBlue",
  "goodsExpressBlueAndClock",
  "goodsExpressRed",
  "goodsExpressGreen",
  "arrowRight",
  "exchange",
];

const Container = styled.button<{
  $variant: TVariants;
  $status: "enabled" | "disabled";
  $icon: TButtonIconVariants;
  $iconType?: TWithIconVariants | "string";
  $isLoading?: boolean;
}>`
  border-radius: 8px;
  padding: ${({ $icon, $variant }) =>
    $icon === "onlyIcon"
      ? `8px`
      : $variant === "primary"
      ? `9px 16px`
      : $variant === "secondary"
      ? "7px 16px"
      : "8px 16px"};

  font-family: var(--font-paragraph-bold);
  font-size: ${({ $iconType }) => ($iconType === "string" ? "12px" : "16px")};
  line-height: 22.4px;

  ${({ $icon, $iconType }) =>
    $icon === "onlyIcon" && $iconType === "string"
      ? "width: 32px; height: 32px;"
      : "height: fit-content;"}

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: ${({ $iconType }) =>
    $iconType &&
    !ACTION_BUTTONS.includes($iconType) &&
    `0px 2px 6px 0px rgba(57, 60, 77, 0.15)`};

  svg {
    width: ${({ $icon, $iconType }) =>
      $icon === "onlyIcon"
        ? $iconType &&
          [
            "nfe",
            "xml",
            "goodsExpressBlue",
            "goodsExpressBlueAndClock",
            "goodsExpressRed",
            "goodsExpressGreen",
          ].includes($iconType)
          ? "20px"
          : "14px"
        : "20px"};
    height: ${({ $icon, $iconType }) =>
      $icon === "onlyIcon"
        ? $iconType &&
          [
            "nfe",
            "xml",
            "goodsExpressBlue",
            "goodsExpressBlueAndClock",
            "goodsExpressRed",
            "goodsExpressGreen",
          ].includes($iconType)
          ? "20px"
          : "14px"
        : "20px"};
    margin-right: ${({ $icon }) => ($icon === "onlyIcon" ? "unset" : "5px")};

    ${({ $isLoading, $variant, $icon }) =>
      $isLoading &&
      css`
        path {
          fill: ${["primary", "white", "green", "orange", "red"].includes(
            $variant
          )
            ? $icon !== "sync" && "var(--color-light-0)"
            : $variant === "secondary"
            ? "var(--color-dark-blue-600)"
            : $variant === "outline"
            ? "var(--color-main-rose)"
            : $variant === "danger"
            ? "var(--color-aux-danger-700)"
            : undefined};
        }
        animation: ${fullRotate} 1.3s linear infinite;
      `}
  }

  ${({ $variant, $icon, $iconType }) => {
    let variantConfig;
    switch ($variant) {
      case "primary":
        variantConfig =
          "background: var(--color-gradient-rose);" +
          "color: var(--color-light-0);";
        break;
      case "secondary":
        if ($iconType && ACTION_BUTTONS.includes($iconType)) {
          switch ($iconType) {
            case "nfe":
              variantConfig =
                `background: var(--color-blue-100);` +
                `border-radius: 100%;` +
                `padding: 8px;` +
                `border: 2px solid transparent;`;
              break;
            case "xml":
              variantConfig =
                `background: var(--color-blue-100);` +
                `border-radius: 100%;` +
                `padding: 8px;` +
                `border: 2px solid transparent;`;
              break;
            case "payableGreen":
              variantConfig =
                `background: var(--color-aux-success-100);` +
                `border-radius: 100%;` +
                `svg {fill: var(--color-aux-success-700); width: 20px; height: 23px;}` +
                `padding: 6px 8px;` +
                `border: 2px solid transparent;`;
              break;
            case "payableOrange":
              variantConfig =
                `background: var(--color-aux-warning-100);` +
                `border-radius: 100%;` +
                `svg {fill: var(--color-aux-warning-700); width: 20px; height: 23px;}` +
                `padding: 6px 8px;` +
                `border: 2px solid transparent;`;
              break;
            case "goodsReceiptGreen":
              variantConfig =
                `background: var(--color-aux-success-100);` +
                `border-radius: 100%;` +
                `svg {fill: var(--color-aux-success-700); width: 20px; height: 23px;}` +
                `padding: 6px 8px;` +
                `border: 2px solid transparent;`;
              break;
            case "goodsReceiptOrange":
              variantConfig =
                `background: var(--color-aux-warning-100);` +
                `border-radius: 100%;` +
                `svg {fill: var(--color-aux-warning-700); width: 20px; height: 23px;}` +
                `padding: 6px 8px;` +
                `border: 2px solid transparent;`;
              break;
            case "goodsExpressBlue":
              variantConfig =
                `background: var(--color-blue-100);` +
                `border-radius: 100%;` +
                `svg {fill: var(--color-main-blue); width: 23px; height: 22px;}` +
                `padding: 6px;` +
                `border: 2px solid transparent;`;
              break;
            case "goodsExpressBlueAndClock":
              variantConfig =
                `background: var(--color-blue-100);` +
                `border-radius: 100%;` +
                `svg {fill: var(--color-blue-800); width: 23px; height: 22px;}` +
                `padding: 6px;` +
                `border: 2px solid transparent;`;
              break;
            case "goodsExpressRed":
              variantConfig =
                `background: var(--color-aux-danger-100);` +
                `border-radius: 100%;` +
                `svg {fill: var(--color-aux-danger-700); width: 23px; height: 22px;}` +
                `padding: 6px;` +
                `border: 2px solid transparent;`;
              break;
            case "goodsExpressGreen":
              variantConfig =
                `background: var(--color-aux-success-100);` +
                `border-radius: 100%;` +
                `svg {fill: var(--color-aux-success-700); width: 23px; height: 22px;}` +
                `padding: 6px;` +
                `border: 2px solid transparent;`;
              break;
            case "exchange":
              variantConfig =
                `background: var(--color-aux-warning-100);` +
                `border-radius: 100%;` +
                `svg {fill: var(--color-aux-warning-700); width: 22px; height: 22px;}` +
                `padding: 3px;` +
                `border: 2px solid transparent;`;
              break;
            case "arrowRight":
              variantConfig =
                `background: red;` +
                `border: none;` +
                `svg {fill: var(--color-dark-blue-400); width: 22px; height: 22px;}`;
              break;
          }
        } else {
          variantConfig =
            "background: var(--color-light-0);" +
            "color: var(--color-dark-blue-600);";
          variantConfig +=
            $icon === "onlyIcon"
              ? "border: 1px solid var(--color-dark-blue-300);"
              : "border: 1px solid var(--color-dark-blue-600);";
        }
        break;
      case "outline":
        variantConfig =
          "background: var(--color-light-0);" +
          "color: var(--color-main-rose);";
        variantConfig +=
          $icon === "onlyIcon"
            ? "border: 1px solid var(--color-dark-blue-300);"
            : "border: 1px solid var(--color-main-rose);";
        break;
      case "danger":
        variantConfig =
          "background: var(--color-light-0);" +
          "color: var(--color-aux-danger-700);" +
          "border: 1px solid var(--color-aux-danger-700);";
        break;
      case "white":
        variantConfig =
          "background: transparent;" +
          "border-radius: 4px;" +
          "padding: 16px 8px;" +
          "color: var(--color-light-0);";
        break;
      case "green":
        variantConfig =
          "background: var(--color-gradient-aux-success);" +
          "color: var(--color-light-0);";
        break;
      case "orange":
        variantConfig =
          "background: var(--color-gradient-aux-warning);" +
          "color: var(--color-light-0);";
        break;
      case "red":
        variantConfig =
          "background: var(--color-gradient-aux-danger);" +
          "color: var(--color-light-0);";
        break;
    }
    return variantConfig;
  }}

  &:disabled {
    pointer-events: none;

    ${({ $variant, $icon, $iconType }) => {
      let variantConfig;
      switch ($variant) {
        case "primary":
          variantConfig =
            "background: linear-gradient(hsla(341, 100%, 60%, 0.3), hsla(342, 70%, 52%, 0.3));";
          break;
        case "secondary":
          if ($iconType && ACTION_BUTTONS.includes($iconType)) {
            switch ($iconType) {
              case "nfe":
                variantConfig =
                  `background: var(--color-light-100);` +
                  `svg.nfe > * {fill: var(--color-dark-blue-300)}`;
                break;
              case "xml":
                variantConfig =
                  `background: var(--color-light-100);` +
                  `svg.xml > * {fill: var(--color-dark-blue-300)}`;
                break;
              case "goodsReceiptGreen":
              case "goodsReceiptOrange":
              case "goodsExpressBlue":
              case "goodsExpressRed":
              case "goodsExpressBlueAndClock":
              case "goodsExpressGreen":
                variantConfig =
                  `background: var(--color-light-100);` +
                  `svg {fill: var(--color-dark-blue-300);}`;
                break;
              case "payableGreen":
              case "payableOrange":
                variantConfig =
                  `background: var(--color-light-100);` +
                  `svg {fill: var(--color-dark-blue-300);}`;
                break;
              case "arrowRight":
                variantConfig =
                  `background: transparent;` +
                  `border: none;` +
                  `svg {fill: var(--color-dark-blue-400);}`;
                break;
            }
          } else {
            variantConfig = "color: var(--color-dark-blue-400);";
            variantConfig +=
              $icon === "onlyIcon"
                ? "border: 1px solid var(--color-dark-blue-300);"
                : "border-color: var(--color-dark-blue-400);";
          }
          break;
        case "outline":
          variantConfig = "color: var(--color-rose-500);";
          variantConfig +=
            $icon === "onlyIcon"
              ? "border: 1px solid var(--color-dark-blue-300);"
              : "border-color: var(--color-rose-500);";
          break;
        case "danger":
          variantConfig =
            "color: hsla(0, 71%, 55%, 0.5);" +
            "border-color: hsla(0, 71%, 55%, 0.5);";
          break;
        case "white":
          variantConfig = "color: hsla(0, 0%, 100%, 0.5);";
          break;
        case "green":
          variantConfig =
            "background: linear-gradient(180deg, #2CF193 0%, #0DBC68 100%)";
          break;
        case "orange":
          variantConfig =
            "background: linear-gradient(180deg, #FFB84E 0%, #F29914 100%);";
          break;
        case "red":
          variantConfig =
            "background: linear-gradient(180deg, #FF5454 0%, #DE3939 100%);          ";
          break;
      }
      return variantConfig;
    }}
  }

  &:hover {
    ${({ $variant, $iconType }) => {
      let variantConfig;
      switch ($variant) {
        case "primary":
          variantConfig =
            "background: linear-gradient(hsla(341, 100%, 60%, 0.9), hsla(342, 70%, 52%, 0.9));";
          break;
        case "secondary":
          if ($iconType && ACTION_BUTTONS.includes($iconType)) {
            switch ($iconType) {
              case "nfe":
                variantConfig =
                  `border: 2px solid var(--color-main-blue);` +
                  `background: var(--color-blue-100);`;
                break;
              case "xml":
                variantConfig =
                  `border: 2px solid var(--color-main-blue);` +
                  `background: var(--color-blue-100);`;
                break;
              case "payableGreen":
                variantConfig =
                  `background: var(--color-aux-success-100);` +
                  `border: 2px solid var(--color-aux-success-700);`;
                break;
              case "payableOrange":
                variantConfig =
                  `background: var(--color-aux-warning-100);` +
                  `border: 2px solid var(--color-aux-warning-700);`;
                break;
              case "goodsReceiptGreen":
                variantConfig =
                  `background: var(--color-aux-success-100);` +
                  `border: 2px solid var(--color-aux-success-700);`;
                break;
              case "goodsReceiptOrange":
                variantConfig =
                  `background: var(--color-aux-warning-100);` +
                  `border: 2px solid var(--color-aux-warning-700);`;
                break;
              case "goodsExpressBlue":
                variantConfig =
                  `background: var(--color-blue-100);` +
                  `border: 2px solid var(--color-main-blue);`;
                break;
              case "goodsExpressBlueAndClock":
                variantConfig =
                  `background: var(--color-blue-100);` +
                  `border: 2px solid var(--color-blue-800);`;
                break;
              case "goodsExpressRed":
                variantConfig =
                  `background: var(--color-aux-danger-100);` +
                  `border: 2px solid var(--color-aux-danger-700);`;
                break;
              case "goodsExpressGreen":
                variantConfig =
                  `background: var(--color-aux-success-100);` +
                  `border: 2px solid var(--color-aux-success-700);`;
                break;
              case "arrowRight":
                variantConfig = `background: transparent;` + `border: none;`;
                break;
            }
          } else {
            variantConfig = "background: var(--color-light-100);";
          }
          break;
        case "outline":
          variantConfig = "background: var(--color-light-100);";
          break;
        case "danger":
          variantConfig = "background: var(--color-light-100);";
          break;
        case "white":
          variantConfig = "background: hsla(0, 0%, 96%, 0.15);";
          break;
        case "green":
          variantConfig =
            "background: linear-gradient(180deg, #2CF193 0%, #0DBC68 100%)," +
            "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));";
          break;
        case "orange":
          variantConfig =
            "background: linear-gradient(0deg, #F29914, #F29914)," +
            "linear-gradient(180deg, #FFB84E 0%, #F29914 100%)," +
            "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))";
          break;

        case "red":
          variantConfig =
            "background: linear-gradient(0deg, #DE3939, #DE3939)," +
            "linear-gradient(180deg, #FF5454 0%, #DE3939 100%)," +
            "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));";
          break;
      }
      return variantConfig;
    }}
  }

  &:active {
    ${({ $variant, $iconType }) => {
      let variantConfig;
      switch ($variant) {
        case "primary":
          variantConfig = "background: var(--color-rose-800);";
          break;
        case "secondary":
          if ($iconType && ACTION_BUTTONS.includes($iconType)) {
            switch ($iconType) {
              case "nfe":
                variantConfig =
                  `border: 2px solid var(--color-main-blue);` +
                  `background: linear-gradient(0deg, var(--color-blue-100),#4141411A)`;
                break;
              case "xml":
                variantConfig =
                  `border: 2px solid var(--color-main-blue);` +
                  `background: linear-gradient(0deg, var(--color-blue-100),#4141411A)`;
                break;
              case "payableGreen":
                variantConfig = `background: linear-gradient(0deg, var(--color-aux-success-100),#4141411A)`;
                `border: 2px solid var(--color-aux-success-700);`;
                break;
              case "payableOrange":
                variantConfig = `background: linear-gradient(0deg, var(--color-aux-warning-100),#4141411A)`;
                `border: 2px solid var(--color-aux-warning-700);`;
                break;
              case "goodsReceiptGreen":
                variantConfig = `background: linear-gradient(0deg, var(--color-aux-success-100),#4141411A)`;
                `border: 2px solid var(--color-aux-success-700);`;
                break;
              case "goodsReceiptOrange":
                variantConfig = `background: linear-gradient(0deg, var(--color-aux-warning-100),#4141411A)`;
                `border: 2px solid var(--color-aux-warning-700);`;
                break;
            }
          } else {
            variantConfig = "background: var(--color-light-200);";
          }
          break;

        case "outline":
          variantConfig = "background: var(--color-light-200);";
          break;
        case "danger":
          variantConfig = "background: var(--color-light-200);";
          break;
        case "white":
          variantConfig = "background: hsla(0, 0%, 4%, 0.25);";
          break;
        case "green":
          variantConfig = "background: var(--color-aux-success-800);";
          break;
        case "orange":
          variantConfig = "background: var(--color-aux-warning-800);";
          break;
        case "red":
          variantConfig = "background: var(--color-aux-danger-800);";
          break;
      }
      return variantConfig;
    }}
  }
`;

type TVariants =
  | "primary"
  | "secondary"
  | "outline"
  | "danger"
  | "white"
  | "green"
  | "orange"
  | "red";

export type TWithIconVariants =
  | "checkmark"
  | "arrowRight"
  | "plus"
  | "logout"
  | "ellipsisV"
  | "pen"
  | "trash"
  | "times"
  | "timesCircle"
  | "eye"
  | "eyeSlash"
  | "sync"
  | "angleLeftB"
  | "angleLeft"
  | "angleRightB"
  | "angleRight"
  | "nfe"
  | "xml"
  | "goodsReceiptOrange"
  | "payableOrange"
  | "goodsReceiptGreen"
  | "payableGreen"
  | "goodsExpressBlue"
  | "goodsExpressBlueAndClock"
  | "goodsExpressRed"
  | "goodsExpressGreen"
  | "link"
  | "search"
  | "upload"
  | "layerGroup"
  | "brokenLink"
  | "exchange"
  | "import";

export type TButtonIconVariants = "noIcon" | "onlyIcon" | TWithIconVariants;

type TWithIcon = {
  loading?: boolean;
  icon: TWithIconVariants;
  iconType?: never;
  iconPosition: "left" | "right";
  text: string;
};

type TNoIcon = {
  loading?: boolean;
  icon: "noIcon";
  iconType?: never;
  iconPosition?: never;
  text: string;
};

type TOnlyIcon<T extends TWithIconVariants | "string"> = T extends "string"
  ? {
      loading?: boolean;
      icon: "onlyIcon";
      iconPosition?: never;

      iconType: "string";
      text: string;
    }
  : {
      loading?: boolean;
      icon: "onlyIcon";
      iconPosition?: never;

      iconType: TWithIconVariants;
      text?: never;
    };

type TCommonProps = {
  id?: string;
  className?: string;
  onClick?: (props?: any) => void;
  onBlur?: (e: React.FocusEvent<HTMLButtonElement, Element>) => "show" | "hide";
  // children?: ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
  variant: TVariants;
  isLoading?: boolean;
  tooltip?: {
    size?: "default" | "large";
    text: TText[];
    customTextAlignment?: Property.TextAlign;
    customTopPosition?: number;
    scrollableRef?: React.MutableRefObject<HTMLTableSectionElement | null>;
  };
  role?: "button" | "submit";
};

export type TButtonProps<Icon extends TButtonIconVariants> =
  Icon extends "noIcon"
    ? TNoIcon & TCommonProps
    : Icon extends "onlyIcon"
    ? TOnlyIcon<TWithIconVariants | "string"> & TCommonProps
    : TWithIcon & TCommonProps;

const Button: React.FC<TButtonProps<TButtonIconVariants>> = ({
  // children,
  style,
  disabled,
  variant,
  onClick,
  onBlur,
  icon,
  iconType,
  iconPosition,
  text,
  id,
  className,
  isLoading,
  tooltip,
  role,
}: TButtonProps<TButtonIconVariants>) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const buttonText = text ?? "";

  const handleIconPositionLeft = (a: JSX.Element, b: string) => (
    <>
      {a}
      {icon !== "onlyIcon" && <>&nbsp;</>}
      {b}
    </>
  );
  const handleIconPositionRight = (a: string, b: JSX.Element) => (
    <>
      {a}
      {icon !== "onlyIcon" && <>&nbsp;</>}
      {b}
    </>
  );

  const switchCaseHandler = (icon: TWithIconVariants | "string") => {
    if (isLoading) {
      return icon === "sync" ? <UilSync /> : <Spinner className="spinner" />;
    } else {
      switch (icon) {
        case "checkmark":
          return <UilCheck />;
        case "arrowRight":
          return <UilArrowRight />;
        case "plus":
          return <UilPlus />;
        case "logout":
          return <UilSignin style={{ transform: "scaleX(-1)" }} />;
        case "ellipsisV":
          return <UilEllipsisV />;
        case "pen":
          return <UilPen />;
        case "trash":
          return <UilTrash />;
        case "times":
          return <UilTimes />;
        case "timesCircle":
          return <UilTimesCircle />;
        case "eye":
          return <UilEye />;
        case "eyeSlash":
          return <UilEyeSlash />;
        case "sync":
          return <UilSync />;
        case "angleLeftB":
          return <UilAngleLeftB />;
        case "angleLeft":
          return <UilAngleLeft />;
        case "angleRightB":
          return <UilAngleRightB />;
        case "angleRight":
          return <UilAngleRight />;
        case "string":
          return;
        case "nfe":
          return <SVG className="nfe" src={NFEicon} />;
        case "xml":
          return <SVG className="xml" src={XMLicon} />;
        case "goodsReceiptOrange":
          return <UilImport />;
        case "goodsReceiptGreen":
          return <UilImport />;
        case "goodsExpressBlue":
          return <UilImport />;
        case "goodsExpressBlueAndClock":
          return (
            <SVG className="goodsExpress" src={goodsExpressBlueAndClockIcon} />
          );
        case "goodsExpressRed":
          return <SVG className="goodsExpress" src={goodsExpressRedIcon} />;
        case "goodsExpressGreen":
          return <SVG className="goodsExpress" src={goodsExpressGreenIcon} />;
        case "payableOrange":
          return <UilDollarSign />;
        case "payableGreen":
          return <UilDollarSign />;
        case "link":
          return <UilLink />;
        case "search":
          return <UilSearch />;
        case "upload":
          return <UilUpload />;
        case "import":
          return <UilImport />;
        case "layerGroup":
          return <UilLayerGroup />;
        case "exchange":
          return <UilExchange />;
      }
    }
  };

  const renderContentByPickedIcon = () => {
    let iconElement;
    if (icon === "onlyIcon") {
      iconElement = switchCaseHandler(iconType);
    } else if (icon !== "noIcon") {
      iconElement = switchCaseHandler(icon);
    }
    return iconPosition === "left"
      ? handleIconPositionLeft(iconElement as JSX.Element, buttonText)
      : handleIconPositionRight(buttonText, iconElement as JSX.Element);
  };

  const onClickHandler = (props?: any) => {
    buttonRef.current?.focus();
    if (onClick) onClick(props);
  };

  return tooltip && tooltip.text.length > 0 ? (
    <Tooltip
      size={tooltip.size}
      text={tooltip.text}
      customTopPosition={tooltip.customTopPosition}
      customTextAlignment={tooltip.customTextAlignment}
      scrollabeRef={tooltip.scrollableRef}
    >
      <Container
        ref={buttonRef}
        $isLoading={isLoading}
        $iconType={iconType}
        $icon={icon}
        $status={disabled ? "disabled" : "enabled"}
        id={id}
        className={className}
        $variant={variant}
        onClick={onClickHandler}
        onBlur={onBlur}
        // className={variant && variant}
        role="button"
        style={style && style}
        disabled={disabled || isLoading}
      >
        {/* {children && children} */}
        {renderContentByPickedIcon()}
      </Container>
    </Tooltip>
  ) : (
    <Container
      ref={buttonRef}
      $isLoading={isLoading}
      $iconType={iconType}
      $icon={icon}
      $status={disabled ? "disabled" : "enabled"}
      id={id}
      className={className}
      $variant={variant}
      onClick={onClickHandler}
      onBlur={onBlur}
      // className={variant && variant}
      role={role ?? "button"}
      style={style && style}
      disabled={disabled || isLoading}
    >
      {/* {children && children} */}
      {renderContentByPickedIcon()}
    </Container>
  );
};

export default Button;
