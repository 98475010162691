import styled from "styled-components";
import BreadCrumbs from "../../../components/ui-v2/BreadCrumbs";
import { ROUTES } from "../../../router";
import Search from "../../../components/ui-v2/Inputs/Search";
import DropdownFilter from "../../../components/ui-v2/DropdownFilter";
import Button, { TWithIconVariants } from "../../../components/ui-v2/Button";
import {
  /* Table,  */ TCellStyleVariants,
  TTableBody,
} from "../../../components/ui-v2/Table";
import { useNavigate } from "react-router-dom";
import apiService from "../../../services/api";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  TMembership,
  TMembershipData,
  TMembershipRole,
  TMembershipStatus,
} from "../../../redux/reducers/memberships";
import {
  decideMembershipRole,
  decideMembershipStatus,
  handleGetDataFromLocalStorage,
  handleParseQuerystrings,
  handlePopulateQuerystring,
  handleSetDataToLocalStorage,
} from "../../../utils/miscellaneous";
import Modal from "../../../components/ui-v2/Modal";
import { useEffect, useRef, useState } from "react";
import { TUserData } from "../../../redux/reducers/userData";
import { localStorageConstants } from "../../../utils/constants";
import { TAccount } from "../../../redux/reducers/account";
import { useTypedDispatch } from "../../../hooks/useTypedDispatch";
import {
  setMembershipsState,
  setToastState,
} from "../../../redux/reducers/globalState";
import { handleSortQuerystring } from "../../../utils/formatters";
import TableGrid, { TCellButton } from "../../../components/ui-v2/TableGrid";
import axios, { CancelTokenSource } from "axios";
// import { fetchMembershipsData } from "../../../redux/actions";

const Container = styled.main`
  position: relative;

  & > h1 {
    font-family: var(--font-title-bold);
    font-size: 28px;
    color: var(--color-text-title);
    margin-top: 10px;
  }

  & > *:nth-child(1) {
    margin-top: 20px;
  }

  & > button {
    position: absolute;
    top: 0;
    right: 0;
  }

  tr {
    td:nth-child(8) {
      width: 10px;
    }
    td:nth-child(9) {
      button {
        border-color: var(--color-aux-danger-700);
        svg {
          color: var(--color-aux-danger-700);
        }
      }
    }
  }
`;

const ContentContainer = styled.div`
  margin-top: 20px;

  & > *:first-child {
    width: 353px;
  }

  & > *:not(& > *:first-child) {
    margin-left: 16px;
  }

  display: flex;
  align-items: center;
`;

const Users: React.FC = () => {
  const navigate = useNavigate();

  const dispatch = useTypedDispatch();

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const membershipData = useTypedSelector(
    (state) => state.globalState.data.membershipData
  );

  const organizationIdRef = useRef("");

  // const [statusFilter, setStatusFilter] = useState<TMembershipStatus[]>([]);
  // const [roleFilter, setRoleFilter] = useState<TMembershipRole[]>([]);
  const qsRoleRef = useRef<TMembershipRole[]>([]);
  const qsStatusRef = useRef<TMembershipStatus[]>([]);

  const [memberToDelete, setMemberToDelete] = useState<TMembership | null>(
    null
  );
  // const [members, setMembers] = useState<TMembership[]>([]);
  const [currentUser, setCurrentUser] = useState<TUserData | null>(null);
  const [selectAccount, setSelectAccount] = useState<TAccount | null>(null);

  const [shouldRejectFilledStatus, setShouldRejectFilledStatus] =
    useState(false);
  const [shouldRejectFilledAccessProfile, setShouldRejectFilledAccessProfile] =
    useState(false);

  const pageRef = useRef<number | undefined>();

  const [shouldOpenModal, setShouldOpenModal] = useState(false);

  const handleNameColumn = (member: TMembership) => {
    if (member.user?.uuid === currentUser?.uuid) {
      handleSetDataToLocalStorage("member", member);

      return {
        values: [
          {
            style: "default",
            text: member.user?.first_name + " " + member.user?.last_name,
            shouldBreakLine: false,
          },
          {
            style: "faded",
            text: " (Minha conta)",
            shouldBreakLine: false,
          },
        ],
        style: "miscellaneous",
      };
    }

    if (!member.user?.first_name || member.user?.first_name === "") {
      return { value: "Sem nome", style: "faded" };
    }

    return {
      value: member.user?.first_name + " " + member.user?.last_name,
      style: "default",
    };
  };

  const handleEmailColumn = (member: TMembership) => ({
    value: member.email ?? member.user.email ?? "-",
    style: "default",
  });

  const handleAccessProfileColumn = (member: TMembership) => {
    switch (decideMembershipRole(member.role)) {
      case "Apenas leitura":
        return { value: "Apenas leitura", style: "darkGray", icon: "noIcon" };
      case "Colaborador":
        return { value: "Colaborador", style: "darkGray", icon: "noIcon" };
      case "Administrador":
        return { value: "Administrador", style: "blue", icon: "noIcon" };
    }
  };

  const handleOrganizationsColumn = (member: TMembership) => ({
    value: member.organizations_count,
    style: "bold",
  });

  const handleStatusColumn = (member: TMembership) => {
    switch (decideMembershipStatus(member.status)) {
      case "pending":
        return { value: "Pendente", style: "orange", icon: "noIcon" };
      case "active":
        return { value: "ativo", style: "green", icon: "noIcon" };
      case "disabled":
        return { value: "Inativo", style: "red", icon: "noIcon" };
    }
  };

  const handleActionsColumns = (member: TMembership, index: number) => {
    if (currentUser?.uuid === member.user?.uuid) {
      return [
        {
          icon: "pen",
          style: "button",
          onClick() {
            localStorage.setItem("member", JSON.stringify(member));
            navigate(
              ROUTES.SETTINGS_USERS_EDIT +
                member.uuid +
                "/?organization_id=" +
                organizationIdRef.current
            );
          },
          tooltip: {
            text: [
              { text: "Editar", shouldBreakLine: false, style: "default" },
            ],
            customTopPosition: 179 + 57 * index,
          },
        } as TCellButton<TWithIconVariants>,
      ];
    }
    return [
      {
        icon: "pen",
        style: "button",
        onClick() {
          localStorage.setItem("member", JSON.stringify(member));
          navigate(
            ROUTES.SETTINGS_USERS_EDIT +
              member.uuid +
              "/?organization_id=" +
              organizationIdRef.current
          );
        },
        tooltip: {
          text: [{ text: "Editar", shouldBreakLine: false, style: "default" }],
          customTopPosition: 179 + 57 * index,
        },
      } as TCellButton<TWithIconVariants>,
      {
        icon: "trash",
        style: "button",
        onClick() {
          if (memberToDelete === null || memberToDelete.uuid !== member.uuid) {
            setMemberToDelete(member);
            setShouldOpenModal(true);
          }
        },
        tooltip: {
          text: [{ text: "Excluir", shouldBreakLine: false, style: "default" }],
          customTopPosition: 179 + 57 * index,
        },
      } as TCellButton<TWithIconVariants>,
    ];
  };

  function modalOnCancelHandler() {
    setShouldOpenModal(false);
  }

  function modalOnSuccessHandler() {
    setShouldOpenModal(false);

    if (memberToDelete) {
      apiService
        .deleteMember(
          memberToDelete.uuid,
          (cancelTokenSource.current as CancelTokenSource).token
        )
        .then(() => {
          if (selectAccount)
            apiService
              .getMemberships(
                selectAccount.uuid,
                (cancelTokenSource.current as CancelTokenSource).token
              )
              .then((response) => {
                const membershipsResponse = response.data as TMembershipData;
                dispatch(
                  setMembershipsState({
                    membershipData: membershipsResponse,
                  })
                );
              });
          dispatch(
            setToastState({
              variant: "success",
              shouldShow: true,
              message: {
                title: "Sucesso",
                description:
                  (memberToDelete.email ??
                    memberToDelete.user.first_name +
                      memberToDelete.user.last_name) +
                  " teve o acesso removido",
              },
            })
          );
        })
        .catch(() => {
          dispatch(
            setToastState({
              variant: "error",
              shouldShow: true,
              message: {
                title: "Erro",
                description:
                  "Não foi possível remover o acesso de " +
                    memberToDelete.email ??
                  memberToDelete.user.first_name +
                    memberToDelete.user.last_name,
              },
            })
          );
        });
    }
  }

  const fetchAndSetData = async () => {
    const localUser = handleGetDataFromLocalStorage(
      localStorageConstants.USER_DATA
    ) as TUserData;
    if (localUser) setCurrentUser(localUser);

    const localSelectAccount = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ACCOUNT
    ) as TAccount;

    const queryStrings = handleParseQuerystrings(window.location.search);

    cancelTokenSource.current = axios.CancelToken.source();

    if (queryStrings.organization_id) {
      organizationIdRef.current = queryStrings.organization_id[0];
    }

    if (localSelectAccount) {
      handleFetchDataByQueryParams(localSelectAccount);
      setSelectAccount(localSelectAccount);
    }
  };

  const handleFilterStatus = (_selected: string, allSelect: string[]) => {
    if (selectAccount) {
      const selectOptions = allSelect.map((select) => {
        switch (select) {
          case "1":
            return 1;
          case "2":
            return 2;
          case "3":
            return 0;
          case "0":
            return 3;
        }
      });

      const queryStrings = handleParseQuerystrings(window.location.search);

      const searchString = handlePopulateQuerystring();

      // let qsStatus: TMembershipStatus[] = [];
      // let qsAccessProfile: TMembershipRole[] = [];

      if (queryStrings.organization_id) {
        searchString.set("organization_id", queryStrings.organization_id[0]);
      }

      let status: (TMembershipStatus | 3 | undefined)[] = [];

      if (selectOptions.length > 1) {
        if (selectOptions[1] === 0) {
          status = [selectOptions[1], selectOptions[0]];
        } else {
          status = selectOptions.sort((a, b) => {
            if (a && b) return a - b;
            return 0;
          });
        }
      } else if (selectOptions[0] !== undefined) {
        status.push(selectOptions[0]);
      }

      searchString.delete("status");
      if (status[0] !== undefined) {
        status.forEach((value) => {
          searchString.append("status", `${value}`);
        });
      }

      navigate("./" + handleSortQuerystring(searchString));

      handleFetchDataByQueryParams();

      // dispatch(
      //   fetchMembershipsData({
      //     uuid: selectAccount.uuid,
      //     status: selectOptions as TMembershipStatus[],
      //     role: roleFilter,
      //   })
      // );
      // if (!selectOptions.includes(undefined)) {
      //   setStatusFilter(selectOptions as TMembershipStatus[]);
      // } else setStatusFilter([]);
    }
  };

  const handleFilterRole = (_selected: string, allSelect: string[]) => {
    if (selectAccount) {
      const selectOptions = allSelect.map((select) => {
        switch (select) {
          case "1":
            return 30;
          case "2":
            return 20;
          case "3":
            return 10;
          case "0":
            return 40;
        }
      });

      const queryStrings = handleParseQuerystrings(window.location.search);

      const searchString = handlePopulateQuerystring();

      // let qsStatus: TMembershipStatus[] = [];
      // let qsAccessProfile: TMembershipRole[] = [];

      if (queryStrings.organization_id) {
        searchString.set("organization_id", queryStrings.organization_id[0]);
      }

      let role: (TMembershipRole | 40 | undefined)[] = [];

      if (selectOptions.length > 1) {
        role = selectOptions.sort((a, b) => {
          if (a && b) return a - b;
          return 0;
        });
      } else if (selectOptions[0] !== undefined) {
        role.push(selectOptions[0]);
      }

      searchString.delete("role");
      if (role[0] !== undefined) {
        role.forEach((value) => {
          searchString.append("role", `${value}`);
        });
      }

      navigate("./" + handleSortQuerystring(searchString));

      handleFetchDataByQueryParams();

      // dispatch(
      //   fetchMembershipsData({
      //     uuid: selectAccount.uuid,
      //     role: selectOptions as TMembershipRole[],
      //     status: statusFilter,
      //   })
      // );
      // if (!selectOptions.includes(undefined)) {
      //   setRoleFilter(selectOptions as TMembershipRole[]);
      // } else setRoleFilter([]);
    }
  };

  const handleGetQueryParams = () => {
    const queryStrings = handleParseQuerystrings(window.location.search);

    let qsAccessProfile: TMembershipRole[] = [];
    let qsStatus: TMembershipStatus[] = [];

    if (queryStrings.role && queryStrings.role[0] !== "40") {
      queryStrings.role.map((accessProfile) =>
        qsAccessProfile.push(parseInt(accessProfile) as TMembershipRole)
      );
      qsRoleRef.current = qsAccessProfile;
    } else {
      qsRoleRef.current = [];
    }

    if (queryStrings.status && queryStrings.status[0] !== "3") {
      queryStrings.status.map((status) =>
        qsStatus.push(parseInt(status) as TMembershipStatus)
      );

      qsStatusRef.current = qsStatus;
    } else {
      qsStatusRef.current = [];
    }
  };

  const handleOnClear = () => {
    const queryStrings = handleParseQuerystrings(window.location.search);

    const searchString = handlePopulateQuerystring();

    if (queryStrings.organization_id)
      searchString.set("organization_id", queryStrings.organization_id[0]);

    searchString.delete("search");

    navigate("./" + handleSortQuerystring(searchString));

    handleFetchDataByQueryParams();
  };

  const handleOnSearch = (searchPhrase: string) => {
    const queryStrings = handleParseQuerystrings(window.location.search);

    const searchString = handlePopulateQuerystring();

    if (queryStrings.organization_id)
      searchString.set("organization_id", queryStrings.organization_id[0]);

    if (searchPhrase.length > 0) searchString.set("search", searchPhrase);
    else searchString.delete("search");

    navigate("./" + handleSortQuerystring(searchString));

    handleFetchDataByQueryParams();
  };

  const handleFetchDataByQueryParams = async (account?: TAccount) => {
    const acc = account ?? selectAccount;
    handleGetQueryParams();

    if (acc) {
      const membershipsResponse = await apiService.getMemberships(
        acc.uuid,
        (cancelTokenSource.current as CancelTokenSource).token,
        undefined,
        qsRoleRef.current.length > 0 ? qsRoleRef.current : undefined,
        qsStatusRef.current.length > 0 ? qsStatusRef.current : undefined
      );
      
      if (membershipsResponse) {
        dispatch(
          setMembershipsState({
            membershipData: membershipsResponse.data as TMembershipData,
          })
        );
      }
    }
  };

  const handleAccessProfileCustomFilter = (
    index: string,
    _selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
    selectDataRef: React.MutableRefObject<string[]>,
    prevSelectDataRef: React.MutableRefObject<string[]>,
    setShouldUpdate: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    handleGetQueryParams();
    let prevSelect = [];

    const queryStrings = handleParseQuerystrings(window.location.search);

    if (queryStrings.role && queryStrings.role[0] === "40") {
      prevSelect = [0];
    } else {
      prevSelect = qsRoleRef.current.map((role) => {
        switch (role) {
          case 10:
            return 3;
          case 20:
            return 2;
          case 30:
            return 1;
          default:
            return 0;
        }
      });
    }

    prevSelectDataRef.current = prevSelect.join().split(",");

    setSelected((prev) => {
      let value = prev;
      if (value.includes(index)) {
        const elementToRemoveIndex = value.indexOf(index);
        value.splice(elementToRemoveIndex, 1);
      } else if (prevSelectDataRef.current.includes(index)) {
        const elementToRemoveIndex = prevSelectDataRef.current.indexOf(index);
        prevSelectDataRef.current.splice(elementToRemoveIndex, 1);
        value = [...prev, ...prevSelectDataRef.current];
      } else {
        value.push(index);
      }

      if (value.length > 1 && value.includes("0")) {
        if (index === "0") value = ["0"];
        else value.splice(value.indexOf("0"), 1);
      }

      if (value.length === 3) {
        value = ["0"];
      }

      if (value.includes("")) value.splice(0, 1);

      selectDataRef.current = value;
      setShouldUpdate(true);
      setTimeout(() => {
        setShouldUpdate(false);
      }, 20);

      return value;
    });
  };

  const handleAccessProfileOnAction = (
    selectDataRef: React.MutableRefObject<string[]>
  ) => {
    handleFilterRole("", selectDataRef.current);
  };

  const handleAccessProfileOnCancel = () => {
    setShouldRejectFilledAccessProfile(true);
    handleFilterRole("", []);
  };

  const handleAccessProfileForceSelectOptions = () => {
    const queryStrings = handleParseQuerystrings(window.location.search);
    if (queryStrings.role) {
      return queryStrings.role.map((role) => {
        switch (role as "10" | "20" | "30" | "40") {
          case "10":
            return 3;
          case "20":
            return 2;
          case "30":
            return 1;
          case "40":
            return 0;
        }
      });
    }
    return undefined;
  };

  const handleStatusCustomFilter = (
    index: string,
    _selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
    selectDataRef: React.MutableRefObject<string[]>,
    prevSelectDataRef: React.MutableRefObject<string[]>,
    setShouldUpdate: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    handleGetQueryParams();
    let prevSelect = [];

    const queryStrings = handleParseQuerystrings(window.location.search);

    if (queryStrings.role && queryStrings.role[0] === "40") {
      prevSelect = [0];
    } else {
      prevSelect = qsStatusRef.current.map((status) => {
        switch (status) {
          case 0:
            return 3;
          case 1:
            return 1;
          case 2:
            return 2;
          default:
            return 0;
        }
      });
    }

    prevSelectDataRef.current = prevSelect.join().split(",");

    setSelected((prev) => {
      let value = prev;
      if (value.includes(index)) {
        const elementToRemoveIndex = value.indexOf(index);
        value.splice(elementToRemoveIndex, 1);
      } else if (prevSelectDataRef.current.includes(index)) {
        const elementToRemoveIndex = prevSelectDataRef.current.indexOf(index);
        prevSelectDataRef.current.splice(elementToRemoveIndex, 1);
        value = [...prev, ...prevSelectDataRef.current];
      } else {
        value.push(index);
      }

      if (value.length > 1 && value.includes("0")) {
        if (index === "0") value = ["0"];
        else value.splice(value.indexOf("0"), 1);
      }

      if (value.length === 3) {
        value = ["0"];
      }

      if (value.includes("")) value.splice(0, 1);

      selectDataRef.current = value;
      setShouldUpdate(true);
      setTimeout(() => {
        setShouldUpdate(false);
      }, 20);

      return value;
    });
  };

  const handleStatusOnAction = (
    selectDataRef: React.MutableRefObject<string[]>
  ) => {
    handleFilterStatus("", selectDataRef.current);
  };

  const handleStatusOnCancel = () => {
    setShouldRejectFilledStatus(true);
    handleFilterStatus("", []);
  };

  const handleStatusForceSelectOptions = () => {
    const queryStrings = handleParseQuerystrings(window.location.search);
    if (queryStrings.status) {
      return queryStrings.status.map((status) => {
        switch (status as "0" | "1" | "2" | "3") {
          case "0":
            return 3;
          case "1":
            return 1;
          case "2":
            return 2;
          case "3":
            return 0;
        }
      });
    }
    return undefined;
  };

  useEffect(() => {
    fetchAndSetData();
    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  return (
    <Container>
      <BreadCrumbs
        names={["Início", "Configurações", "Usuários"]}
        urls={[ROUTES.HOME, ROUTES.SETTINGS, ROUTES.SETTINGS_USERS]}
      />
      <h1>Gerenciar usuários</h1>
      <Button
        variant="primary"
        icon="plus"
        iconPosition="left"
        text="Novo usuário"
        onClick={() => {
          navigate(
            ROUTES.SETTINGS_USERS_CREATE +
              "?organization_id=" +
              organizationIdRef.current
          );
        }}
      />
      <ContentContainer>
        <Search
          onClear={handleOnClear}
          $ref={(ref, setSearchString) => {
            const queryStrings = handleParseQuerystrings(
              window.location.search
            );
            if (queryStrings.search && ref.current) {
              ref.current.value = queryStrings.search[0];
              setSearchString(queryStrings.search[0]);
            }
          }}
          variant="default"
          placeholder="Buscar usuários"
          onSearch={handleOnSearch}
        />
        <DropdownFilter
          menuVariant="unlabeled"
          buttonVariant="checkCircle"
          placeholder="Status"
          search={false}
          content={["Todos", "Ativo", "Inativo", "Pendente"]}
          customFilter={handleStatusCustomFilter}
          onAction={handleStatusOnAction}
          onCancel={handleStatusOnCancel}
          hasBottomBar
          key={shouldRejectFilledStatus + "-status"}
          forceSelectOptionsByIndex={(() => {
            const result = handleStatusForceSelectOptions();
            return shouldRejectFilledStatus ? undefined : result;
          })()}
          filled={(() => {
            const result = handleStatusForceSelectOptions();
            return result === undefined
              ? result
              : [...result.toString().replaceAll(",", "")];
          })()}
        />
        <DropdownFilter
          menuVariant="unlabeled"
          buttonVariant="checkCircle"
          placeholder="Perfil de acesso"
          search={false}
          content={["Todos", "Administrador", "Colaborador", "Apenas leitura"]}
          customFilter={handleAccessProfileCustomFilter}
          onAction={handleAccessProfileOnAction}
          onCancel={handleAccessProfileOnCancel}
          hasBottomBar
          key={shouldRejectFilledAccessProfile + "-role"}
          forceSelectOptionsByIndex={(() => {
            const result = handleAccessProfileForceSelectOptions();
            return shouldRejectFilledAccessProfile ? undefined : result;
          })()}
          filled={(() => {
            const result = handleAccessProfileForceSelectOptions();
            return result === undefined
              ? result
              : [...result.toString().replaceAll(",", "")];
          })()}
        />
      </ContentContainer>

      <TableGrid
        selectType={{ type: "noSelect" }}
        columns={{
          tableHead: {
            width: [
              // 0px 80px 600px 500px 500px 250px 250px 50px 0px
              // 0px 80px 600px 500px 500px 250px 250px 60px 0px
              `${(0 * 10) / 258}vw ${(80 * 10) / 258}vw ${(600 * 10) / 258}vw ${
                (500 * 10) / 258
              }vw ${(500 * 10) / 258}vw ${(250 * 10) / 258}vw ${
                (250 * 10) / 258
              }vw min(50px, ${(80 * 10) / 258}vw) ${(0 * 10) / 258}vw`,
            ],
          },
          tableBody: {
            width: [
              `${(0 * 10) / 258}vw ${(80 * 10) / 258}vw ${(600 * 10) / 258}vw ${
                (500 * 10) / 258
              }vw ${(500 * 10) / 258}vw ${(250 * 10) / 258}vw ${
                (250 * 10) / 258
              }vw min(50px, ${(80 * 10) / 258}vw) ${(0 * 10) / 258}vw`,
            ],
          },
        }}
        tableHead={{
          value: [
            { value: "Nome", style: "noIcon" },
            { value: "E-mail", style: "noIcon" },
            { value: "Perfil de acesso", style: "noIcon" },
            { value: "Organizações", style: "noIcon" },
            { value: "Status", style: "noIcon" },
            { value: "Ações", style: "noIcon" },
          ],
        }}
        row="singleRow"
        emptyState={{
          isEmptyState: (membershipData?.results ?? []).length === 0,
          title: "Nenhum resultado encontrado",
          description: "Altere os filtros e realize a busca novamente",
        }}
        tableBody={(membershipData?.results ?? []).map((member, index) => ({
          elementId: "",
          file_nfe: null,
          file_xml: null,
          body: [
            handleNameColumn(member),
            handleEmailColumn(member),
            handleAccessProfileColumn(member),
            handleOrganizationsColumn(member),
            handleStatusColumn(member),
            ...handleActionsColumns(member, index),
          ] as TTableBody<TCellStyleVariants>[],
        }))}
        footer={{
          // pagination: {
          //   buttonsAmount: 30,
          //   onClick(activeIndex) {
          //     // console.log("active index: ", activeIndex);
          //   },
          // },
          customPagination: membershipData
            ? {
                isFixedAtTheBottom: true,
                currentPage: `${membershipData?.current_page}`,
                buttonBackward: {
                  disabled: membershipData.current_page === 1,
                  onClick() {
                    if (membershipData.current_page === 1) {
                      pageRef.current = undefined;
                    } else {
                      pageRef.current = membershipData.current_page - 1;
                    }

                    if (selectAccount && membershipData.current_page !== 1) {
                      apiService
                        .getMemberships(
                          selectAccount.uuid,
                          (cancelTokenSource.current as CancelTokenSource)
                            .token,
                          pageRef.current
                        )
                        .then((response) => {
                          dispatch(
                            setMembershipsState({
                              membershipData: response.data as TMembershipData,
                            })
                          );
                        });
                    }
                  },
                },
                buttonForward: {
                  disabled:
                    membershipData.current_page === membershipData.pages.length,
                  onClick() {
                    if (
                      membershipData.current_page ===
                      membershipData.pages.length
                    ) {
                      pageRef.current = membershipData.current_page;
                    } else {
                      pageRef.current = membershipData.current_page + 1;
                    }

                    if (
                      selectAccount &&
                      membershipData.current_page !==
                        membershipData.pages.length
                    ) {
                      apiService
                        .getMemberships(
                          selectAccount.uuid,
                          (cancelTokenSource.current as CancelTokenSource)
                            .token,
                          pageRef.current
                        )
                        .then((response) => {
                          dispatch(
                            setMembershipsState({
                              membershipData: response.data as TMembershipData,
                            })
                          );
                        });
                    }
                  },
                },
                pages: membershipData.pages.map((page) => {
                  return {
                    value: Object.keys(page)[0],
                    onClick() {
                      let temp = Object.values(page)[0].match(/.*?page=(\d+)/);

                      if (temp !== null && temp[1]) {
                        pageRef.current = parseInt(temp[1]);
                      } else {
                        pageRef.current = undefined;
                      }

                      if (selectAccount) {
                        apiService
                          .getMemberships(
                            selectAccount.uuid,
                            (cancelTokenSource.current as CancelTokenSource)
                              .token,
                            pageRef.current
                          )
                          .then((response) => {
                            dispatch(
                              setMembershipsState({
                                membershipData:
                                  response.data as TMembershipData,
                              })
                            );
                          });
                      }
                    },
                  };
                }),
              }
            : undefined,
        }}
      />
      <Modal
        title="Excluir acesso"
        icon={{ type: "exclamationTriangle", color: "red" }}
        main={{
          style: "textOnly",
          content: [
            {
              text: "Tem certeza que deseja excluir o acesso de ",

              style: "default",
              shouldBreakLine: false,
            },
            {
              text:
                memberToDelete?.email ??
                memberToDelete?.user.first_name +
                  " " +
                  memberToDelete?.user.last_name,
              style: "bold",
              shouldBreakLine: false,
            },
            { text: "?", style: "default", shouldBreakLine: true },
            // { text: "", style: "default", shouldBreakLine: true },
            // {
            //   text: "Os usuários que têm acesso a ela ",
            //   style: "default",
            //   shouldBreakLine: false,
            // },
            // { text: "não", style: "bold", shouldBreakLine: false },
            // {
            //   text: " poderão mais acessá-la.",
            //   style: "default",
            //   shouldBreakLine: true,
            // },
          ],
        }}
        footer={{
          style: "default",
          buttons: [
            {
              variant: "secondary",
              icon: "noIcon",
              text: "Cancelar",
              onClick() {
                modalOnCancelHandler();
              },
            },
            {
              variant: "red",
              iconPosition: "left",
              icon: "checkmark",
              text: "Sim, excluir",
              onClick() {
                modalOnSuccessHandler();
              },
            },
          ],
        }}
        shouldOpen={shouldOpenModal}
        onClose={modalOnCancelHandler}
      />
    </Container>
  );
};

export default Users;
