import styled from "styled-components";
import watermark from "../../assets/images/watermark.svg";
import logo from "../../assets/images/logo.svg";
import SVG from "../../components/ui/SVG";
import Button from "../../components/ui-v2/Button";
import apiService, { TApiError } from "../../services/api";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { errorHandler } from "../../utils/miscellaneous";
import axios, { CancelTokenSource } from "axios";
import { useEffect, useRef } from "react";

const Container = styled.main`
  position: relative;

  overflow: hidden;
  height: 100vh;

  svg#watermark {
    position: absolute;
    z-index: 0;
    width: calc(528vw / 14.4);
    height: calc(640vh / 8);
    left: 0;
  }

  svg#logo {
    position: absolute;
    z-index: 1;
    top: calc(250vh / 8);
    left: calc(461.68vw / 14.4);

    width: calc(518vw / 14.4);
    height: calc(109vh / 8);
  }

  @media (max-width: 565px) {
    display: none;
  }
`;

const LoginContainer = styled.div`
  position: absolute;
  top: calc(400vh / 8);
  left: calc(552vw / 14.4);
`;

const LoginSubcontainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: calc(338vw / 14.4);
  height: calc(128vh / 8);
  min-height: 120px;

  border-radius: calc(4vw / 14.4);
  border: calc(1vw / 14.4) solid var(--color-light-200);
  box-shadow: 0px calc(2vw / 14.4) calc(8vw / 14.4) calc(2vw / 14.4)
    rgba(0, 0, 0, 0.05);

  h2 {
    font-family: var(--font-title-default);
    font-size: calc(16vw / 14.4);
    margin-bottom: calc(16vw / 14.4);
  }

  button {
    height: calc(46vw / 14.4);
    width: calc(275vw / 14.4);
    font-size: calc(16vw / 14.4);

    border-radius: calc(8vw / 14.4);

    svg {
      width: calc(20vw / 14.4);
      height: calc(20vw / 14.4);
    }
  }
`;

const Login: React.FC = () => {
  // const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  // const [accountsCount, setAccountsCount] = useState<number | null>(null);

  // const { data: accounts } = useTypedSelector((state) => state.account);

  const handleLogin = () => {
    cancelTokenSource.current = axios.CancelToken.source();
    
    apiService
      .handleAuthenticateStep1(cancelTokenSource.current.token)
      .catch((e: TApiError) => {
        errorHandler(dispatch, e);
      });
  };

  useEffect(() => {
    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  // const fetchData = async () => {
  //   try {
  //     const localUserData = localStorage.getItem(
  //       localStorageConstants.USER_DATA
  //     );
  //     if (!localUserData) {
  //       const userDataResponse = (await apiService.getUserData()).data;
  //       handleSetDataToLocalStorage(
  //         localStorageConstants.USER_DATA,
  //         userDataResponse
  //       );
  //     }

  //     const localAccountCount = handleGetDataFromLocalStorage(
  //       localStorageConstants.ACCOUNTS_COUNT
  //     ) as number;
  //     if (!localAccountCount) {
  //       const accountsResponse = (await apiService.getAccounts())
  //         .data as TAccountData;

  //       handleSetDataToLocalStorage(
  //         localStorageConstants.ACCOUNTS_COUNT,
  //         accountsResponse.count
  //       );
  //       setAccountsCount(accountsResponse.count);
  //     } else {
  //       setAccountsCount(localAccountCount);
  //     }
  //   } catch (e) {
  //     errorHandler(dispatch, e as TApiError);
  //   }
  // };

  // useEffect(() => {
  //   const search = window.location.search;

  //   if (search.length > 0) {
  //     apiService
  //       .handleAuthenticateStep2(search)
  //       .then(() => {
  //         const authorization = localStorage.getItem(
  //           localStorageConstants.AUTHORIZATION
  //         );

  //         if (authorization) {
  //           fetchData();
  //         }
  //       })
  //       .catch((e: TApiError) => {
  //         errorHandler(dispatch, e);
  //       });
  //   }
  // }, []);

  // useEffect(() => {
  //   if (typeof accountsCount === 'number') {
  //     if (accountsCount > 1) {
  //       navigate(ROUTES.ACCOUNTS);
  //     } else {
  //       navigate(ROUTES.HOME);
  //     }
  //   }
  // }, [accountsCount]);

  return (
    <Container>
      <SVG id="watermark" src={watermark} />
      <SVG id="logo" src={logo} />
      <LoginContainer>
        <LoginSubcontainer>
          <h2>Clique para acessar o Portal Fiscal</h2>
          <Button
            variant="outline"
            icon="arrowRight"
            iconPosition="left"
            text="Entrar com Nexaas ID"
            onClick={handleLogin}
          />
        </LoginSubcontainer>
      </LoginContainer>
    </Container>
  );
};

export default Login;
