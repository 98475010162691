import { useState } from "react";
import styled from "styled-components";

const Container = styled.button`
  padding: 2px;
  border-radius: 40px;
  border: 2px solid transparent;

  &:hover {
    background: transparent;
  }

  &:focus {
    outline: none;
    border: 2px solid var(--color-main-blue);
  }
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 33px;
  height: 18px;

  & .background {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-light-200);
    transition: 0.4s;
    border-radius: 29px;

    &::before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      top: 2px;
      left: 4px;
      bottom: 4px;
      background: var(--color-light-0);
      transition: 0.4s;
      border-radius: 29px;
    }
  }
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .background {
    background: var(--color-main-blue);
  }

  &:focus + .background {
    box-shadow: 0 0 1px #2196f3;
  }

  &:checked + .background:before {
    transform: translateX(12px);
  }
`;

type TToggleSwitchProps = {
  onClick?: (isClicked: boolean) => void;
  id?: string;
  initialState?: boolean;
};

const ToggleSwitch: React.FC<TToggleSwitchProps> = ({
  onClick,
  id,
  initialState,
}) => {
  const [isClicked, setIsClicked] = useState(initialState ?? false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsClicked((prev) => {
      if (onClick) onClick(!prev);
      return !prev;
    });
  };

  return (
    <Container id={id} role="button" onClick={handleClick}>
      <Label>
        <Input type="checkbox" onChange={() => {}} checked={isClicked} />
        <span className="background"></span>
      </Label>
    </Container>
  );
};

export default ToggleSwitch;
