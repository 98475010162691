import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import GlobalStyles from "./theme/globalStyles.ts";
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { router } from "./router/index.tsx";
import { Provider } from "react-redux";
import store from "./redux/store.ts";
import AppInterceptor from "./AppInterceptor.tsx";
import * as Sentry from "@sentry/react";

Sentry.init({
  environment: window.location.href.includes("http://localhost")
    ? "local"
    : window.location.href.includes(".hml")
    ? "homologation"
    : "production",
  dsn: "https://4d436e4413fb1fc25600bbb746cdd43d@o4507114607607808.ingest.us.sentry.io/4507691131142144",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation: useLocation,
      useNavigationType: useNavigationType,
      createRoutesFromChildren: createRoutesFromChildren,
      matchRoutes: matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/portalfiscal\.hml\.nexaas\.com/,
    /^https:\/\/portalfiscal\.nexaas\.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <GlobalStyles />
    <Provider store={store}>
      <AppInterceptor />
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
