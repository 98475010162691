import styled from "styled-components";
// import { UilTimes } from "@iconscout/react-unicons";
import { useCallback, useEffect, useRef, useState } from "react";

const Container = styled.div`
  position: relative;

  & > p {
    position: absolute;
    color: var(--color-aux-danger-700);
    font-size: 10px;
    font-family: var(--font-paragraph-default);
  }
`;

const Subcontainer = styled.button<{
  $label: string;
  $onError: "hasError" | "noError";
  $inputString: string;
  $disabled: "disabled" | "enabled";
  $hasMandatoryStyle?: {
    onFocus: { marginLeft: string };
    default: { marginLeft: string };
  };
}>`
  position: relative;
  width: 280px;
  // height: max-content;
  height: 47px;
  padding: 14px 12px;

  border-radius: 8px;
  border: 1px solid
    ${({ $onError }) =>
      $onError === "hasError"
        ? `var(--color-aux-danger-700)`
        : `var(--color-dark-blue-300)`};

  background: ${({ $disabled }) =>
    $disabled === "disabled"
      ? `var(--color-light-100)`
      : `var(--color-light-0)`};

  cursor: ${({ $disabled }) => ($disabled === "enabled" ? "text" : "default")};

  ${({ $disabled }) =>
    $disabled === "disabled" &&
    `& > input {
    background: var(--color-light-100);
    color: var(--color-text-labels);
  }`}

  &::before {
    content: "${({ $label }) => $label}";
    font-family: var(--font-paragraph-default);

    position: absolute;
    top: 13px;
    text-align: left;
    // left: 22px;
    opacity: 1;
    color: var(--color-text-labels);
    ${({ $inputString, $disabled }) =>
      $inputString.length === 0
        ? `font-size: 14px;`
        : $disabled === "disabled"
        ? `font-size: 9px;
           top: 8px;`
        : `font-size: 9px;
           top: 8px;`}
    transition: font-size 0.15s ease-in-out, top 0.15s ease-in-out,
      color 0.15s ease-in-out;
  }

  &::after {
    content: "${({ $hasMandatoryStyle }) => $hasMandatoryStyle && "*"}";
    color: var(--color-aux-danger-700);

    position: absolute;
    top: 13px;
    left: ${({ $hasMandatoryStyle }) => $hasMandatoryStyle?.default.marginLeft};

    ${({ $inputString, $disabled, $hasMandatoryStyle }) =>
      $inputString.length === 0
        ? `font-size: 14px;`
        : $disabled === "disabled"
        ? `font-size: 9px;
           top: 8px;` + `left: ${$hasMandatoryStyle?.onFocus.marginLeft};`
        : `font-size: 9px;
           top: 8px;` + `left: ${$hasMandatoryStyle?.onFocus.marginLeft};`}
    transition: font-size 0.15s ease-in-out, top 0.15s ease-in-out,
      color 0.15s ease-in-out, left 0.15s ease-in-out;
  }

  ${({ $disabled }) =>
    $disabled !== "disabled" &&
    `&:hover {
    background: var(--color-light-0);
  }`}

  .clear {
    position: absolute;
    right: 12px;

    top: 12px;

    color: var(--color-text-labels);
    width: 18px;
    height: 18px;
    font-size: 14px;

    cursor: pointer;
    outline: none;
  }

  &:focus-within {
    border: 1px solid var(--color-main-blue);

    &::before {
      font-size: 9px;
      top: 8px;
      color: var(--color-main-blue);
    }

    &::after {
      font-size: 9px;
      top: 8px;
      left: ${({ $hasMandatoryStyle }) =>
        $hasMandatoryStyle?.onFocus.marginLeft};
    }

    .clear {
      // visibility: visible;
    }
  }

  .clear:focus {
    border: 1px solid var(--color-main-blue);
  }
`;

const Input = styled.input<{ $disabled: "enabled" | "disabled" }>`
  width: 100%;
  // margin-left: 10px;
  padding-top: 5px;
  border: 0;

  color: var(--color-text-paragraph);

  ${({ $disabled }) => $disabled === "disabled" && `cursor: default;`}

  &,
  &::placeholder {
    font-size: 14px;
  }

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: var(--color-text-labels);
  }
`;

type TLabeledInputProps = {
  id?: string;
  className?: string;
  placeholder: string;
  hasMandatoryStyle?: {
    onFocus: { marginLeft: string };
    default: { marginLeft: string };
  };
  onError?: { hasError: boolean; message: string };
  onChange?: (inputValue: string) => void;
  filled?: string;
  disabled?: boolean;
  onBlur?: (inputValue: string) => void;
  setData?: (
    setInputString: React.Dispatch<React.SetStateAction<string>>
  ) => void;
  customFilter?: (
    inputRef: HTMLInputElement,
    setInputString: React.Dispatch<React.SetStateAction<string>>
  ) => void;
};

const LabeledTextInput: React.FC<TLabeledInputProps> = ({
  id,
  className,
  placeholder,
  setData,
  onError,
  onChange,
  filled,
  disabled,
  onBlur,
  customFilter,
  hasMandatoryStyle,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputString, setInputString] = useState("");
  const [, /* shouldShowClearButton */ setShoulShowClearButton] =
    useState(false);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      if (!customFilter) {
        setInputString(e.target.value);
      } else {
        if (inputRef.current !== null)
          customFilter(inputRef.current, setInputString);
      }
      if (onChange) onChange(e.target.value);
    }
  };

  // const handleOnClick = () => {
  //   if (inputRef.current !== null) {
  //     inputRef.current.value = "";
  //     setInputString("");
  //   }
  // };

  const handleFocus = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShoulShowClearButton(true);
    inputRef.current?.focus();
  };

  const handleOnblur = (e: React.FocusEvent<HTMLButtonElement, Element>) => {
    e.preventDefault();
    setShoulShowClearButton(false);
    if (inputRef.current) {
      if (onBlur) onBlur(inputRef.current.value);
      inputRef.current.blur();
    }
  };

  const handleFilled = useCallback(() => {
    if (filled && inputRef.current) {
      setInputString(filled);
      inputRef.current.value = filled;
    }
  }, [filled]);

  useEffect(() => {
    if (setData) {
      setData(setInputString);
    }
  }, []);

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = inputString;
  }, [inputString]);

  useEffect(() => {
    handleFilled();
  }, [handleFilled]);

  return (
    <Container className={className}>
      <Subcontainer
        onBlur={handleOnblur}
        $disabled={disabled ? "disabled" : "enabled"}
        $onError={(onError?.hasError && "hasError") || "noError"}
        $label={placeholder}
        id={id && id}
        role="button"
        onClick={handleFocus}
        $inputString={inputString}
        disabled={disabled}
        $hasMandatoryStyle={hasMandatoryStyle}
      >
        <Input
          $disabled={disabled ? "disabled" : "enabled"}
          disabled={disabled}
          onChange={handleOnChange}
          ref={inputRef}
          type="text"
        />
        {/* {shouldShowClearButton && inputString.length > 0 && !filled && (
          <UilTimes
            onClick={handleOnClick}
            tabIndex={0}
            role="button"
            className="clear"
          />
        )} */}
      </Subcontainer>
      {onError && onError.hasError && <p>{onError.message}</p>}
    </Container>
  );
};

export default LabeledTextInput;
