import { UilExchange, UilTimesCircle } from "@iconscout/react-unicons";
import styled from "styled-components";

const SummaryCardContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div<{ $variant: TVariant }>`
  width: 100%;

  border-radius: 8px;
  padding: 16px;

  display: flex;
  align-items: center;

  & > * {
    margin-left: 10px;
  }

  ${({ $variant }) => {
    switch ($variant) {
      case "warning":
        return (
          `border: 1px solid var(--color-aux-warning-800);` +
          `background: var(--color-aux-warning-100);` +
          `svg{color: var(--color-aux-warning-700);}`
        );
      case "error":
        return (
          `border: 1px solid var(--color-aux-danger-800);` +
          `background: var(--color-aux-danger-100);` +
          `svg{color: var(--color-aux-danger-700);}`
        );
    }
  }}
`;

const ContentContainer = styled.div<{
  $variant: TVariant;
}>`
  max-height: calc(116px - 30px);
  overflow-y: auto;
  width: calc(100% - 30px);

  h1.title {
    font-size: 16px;
    font-family: var(--font-title-bold);
  }

  p {
    font-size: 14px;
    font-family: var(--font-paragraph-default);
    color: var(--color-text-paragraph);
  }

  a p {
    color: var(--color-text-link);
  }

  ${({ $variant }) => {
    switch ($variant) {
      case "warning":
        return `h1.title{color: var(--color-aux-warning-800);}`;
      case "error":
        return `h1.title{color: var(--color-aux-danger-800);}`;
    }
  }}
`;

type TVariant = "warning" | "error";

type TSummaryCardProps = {
  variant: TVariant;
  messages: string[];
};

const SummaryCard: React.FC<TSummaryCardProps> = ({ variant, messages }) => {
  const handleIcon = () => {
    switch (variant) {
      case "warning":
        return <UilExchange />;
      case "error":
        return <UilTimesCircle />;
    }
  };

  const handleTitle = () => {
    switch (variant) {
      case "warning":
        return "Fator de conversão";
      case "error":
        return "Corrija os erros para continuar";
    }
  };

  return (
    <SummaryCardContainer>
      <Container $variant={variant}>
        {handleIcon()}
        <ContentContainer $variant={variant}>
          <h1 className="title">{handleTitle()}</h1>

          {messages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </ContentContainer>
      </Container>
    </SummaryCardContainer>
  );
};

export default SummaryCard;
