import styled from "styled-components";
import Button from "./Button";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  align-items: center;

  a {
    color: var(--color-main-blue);
    font-family: var(--font-paragraph-default);
    font-size: 14px;
  }

  button {
    border: none;
    box-shadow: none;

    color: var(--color-text-labels);

    svg {
      height: 16px;
      width: 16px;
    }

    &:hover {
      // background: transparent;
    }
  }
`;

type AttachmentListItemProps = {
  text: string;
  // onClick?: () => void;
  localUrl?: string;
  onClear?: () => void;
};

const AttachmentListItem: React.FC<AttachmentListItemProps> = ({
  text,
  // onClick,
  localUrl,
  onClear,
}) => {
  return (
    <Container>
      <Link to={localUrl ?? ""} target="_blank">
        {text}
      </Link>
      <Button
        icon="onlyIcon"
        iconType="timesCircle"
        variant="outline"
        onClick={onClear}
      />
    </Container>
  );
};

export default AttachmentListItem;
