import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchAccounts } from "../../actions";

export type TAccount = {
  created_at: string;
  is_syncing: boolean;
  name: string;
  synced_at: string;
  updated_at: string;
  uuid: string;
};

export type TAccountData = {
  count: number;
  current_page: number;
  pages: { [key: number]: string }[];
  results: TAccount[];
};

type TInitialState = {
  loading: boolean;
  error: null | string;
  data: TAccountData;
};

const initialState: TInitialState = {
  loading: false,
  error: "",
  data: {
    count: -1,
    current_page: 0,
    pages: [],
    results: [],
  },
};

const accountDataSlice = createSlice({
  name: "account",
  initialState: initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<TAccountData>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccounts.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload ?? state.data;
      })
      .addCase(fetchAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "error";
      });
  },
});

export const { setAccount } = accountDataSlice.actions;
export default accountDataSlice.reducer;
