import styled from "styled-components";
import LabeledTextInput from "../../Inputs/LabeledTextInput";
import Calendar from "../../Inputs/Calendar";
import Check from "../../Inputs/Check";
import React, { useEffect, useRef, useState } from "react";
// import { useTypedDispatch } from "../../../../hooks/useTypedDispatch";
import {
  TInvoice,
  TPaymentSlip,
  // setToastState,
} from "../../../../redux/reducers/globalState";
import apiService from "../../../../services/api";
import Button, { TButtonIconVariants, TButtonProps } from "../../Button";
import {
  /* Table, */ TCellStyleVariants,
  TTableBody,
  TTableSelected,
} from "../../TableGrid";
import {
  convertNumberToBRL,
  handleSortQuerystring,
} from "../../../../utils/formatters";

import { TOrganization } from "../../../../redux/reducers/organizations";
import {
  handleGetDataFromLocalStorage,
  handleParseQuerystrings,
  handlePopulateQuerystring,
} from "../../../../utils/miscellaneous";
import { localStorageConstants } from "../../../../utils/constants";
import TableGrid from "../../TableGrid";
import { format } from "date-fns/format";
import axios, { CancelTokenSource } from "axios";
import { useNavigate } from "react-router-dom";

const Container = styled.form`
  & > p,
  label,
  b {
    font-size: 16px;
    color: var(--color-text-paragraph);
    font-family: var(--font-paragraph-default);
  }

  b {
    font-family: var(--font-paragraph-bold);
  }

  label {
    font-size: 14px;
  }

  table {
    thead tr:first-child {
      th b {
        font-size: 14px;
      }
    }

    thead tr:nth-child(2) {
      th b {
        font-size: 12px;
      }
    }
  }
`;

const RowContainer = styled.div`
  display: flex;

  margin-top: 16px;

  position: relative;

  h2 {
    font-family: var(--font-paragraph-bold);
    font-size: 21px;
    color: var(--color-text-paragraph);
  }

  &:nth-of-type(1) {
    align-items: center;

    .isPaidContainer {
      display: flex;
    }

    & > *:not(:last-child) {
      margin-right: 16px;
    }

    & > *:not(.isPaidContainer) > button,
    & > .calendar > button {
      width: 187px;
    }

    & > :nth-child(4) > button {
      width: 199px;
    }

    & > :last-child.calendar > button {
      width: calc(100% + 20px);
    }
  }

  &:nth-of-type(2) {
    & > *:not(:last-child) {
      margin-right: 16px;
    }

    & > div:first-child > button {
      width: 390px;
    }

    & > div:not(:first-child) > button {
      width: 292px;
    }
  }

  &:nth-of-type(5) {
    & > section {
      width: 100%;
      margin-top: 0px;
    }
  }
`;

const Footer = styled.footer<{ $shouldShowTable: boolean }>`
  margin-top: ${({ $shouldShowTable }) => ($shouldShowTable ? -2 : 74)}px;
  border-top: 1px solid var(--color-light-200);
  padding: 16px 0px;
  display: flex;
  justify-content: flex-end;

  .textContainer {
    b,
    p {
      font-size: 14px;
      color: var(--color-text-paragraph);
    }

    b {
      font-family: var(--font-paragraph-bold);
    }

    p {
      font-family: var(--font-paragraph-default);
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;

    & button:not(& button:last-child) {
      margin-right: 12px;
    }
  }
`;

export type TPayableObjectForm = {
  amount: string;
  category_id: number | null;
  classification_center_id: number | null;
  description: string;
  due_date: string;
  mode: null | "in_cash" | "recurrent" | "installments";
  supplier_id: number | null;

  paid_amount?: string;
  fine_amount?: string;
  interest_amount?: string;
  discount_amount?: string;
  payment_date?: string;
  period?:
    | "weekly"
    | "biweekly"
    | "monthly"
    | "bimonthly"
    | "quarterly"
    | "semiannual"
    | "annual";
  installments?: number;
  income_tax_relevant?: boolean;
};

type TLinkPayableProps = {
  invoice?: TInvoice;
  paymentSlip?: TPaymentSlip;
  footer: { buttons: TButtonProps<TButtonIconVariants>[] };
};

export type TPayableAccount = {
  id: number;
  due_date: string;
  amount: string;
  description: string;
  category: string;
  supplier: string;
  payment_date: string | null;
  payment_amount: string | null;
  mode: string;
};

export type TPayableAccountData = {
  count: number;
  current_page: number;
  pages: { [key: number]: string }[];
  results: TPayableAccount[];
};

const LinkPayable: React.FC<TLinkPayableProps> = ({
  invoice,
  paymentSlip,
  footer,
}) => {
  const [isPaid, setIsPaid] = useState(false);

  const [payableAccounts, setPayableAccounts] = useState<TPayableAccount[]>([]);

  const payableAccountDataRef = useRef<TPayableAccountData | null>(null);

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const [selectRow, setSelectRow] = useState<
    | (TTableSelected & {
        hasInstallments: boolean;
        selectPayable: TPayableAccount;
      })
    | null
  >(null);
  const [shouldShowTable, setShouldShowTable] = useState(false);
  const [shoulShowEmptyState, setShouldShowEmptyState] = useState(false);

  const [shouldShowDueDateError, setShouldShowDueDateError] = useState(false);

  // const dispatch = useTypedDispatch();

  const navigate = useNavigate();

  // const shouldSubmitRef = useRef(false);

  const categoryNameRef = useRef<string | undefined>();
  const descriptionRef = useRef<string | undefined>();
  const supplierNameRef = useRef<string | undefined>();

  const pageRef = useRef(1);
  const lastPageRef = useRef<number | undefined>();

  const selectOrganizationRef = useRef<TOrganization | null>(null);

  const submitObjectRef = useRef<TPayableObjectForm>({
    amount: "",
    category_id: null,
    classification_center_id: null,
    description: "",
    due_date: "",
    mode: null,
    supplier_id: null,

    paid_amount: undefined,
    fine_amount: undefined,
    interest_amount: undefined,
    discount_amount: undefined,
    payment_date: undefined,
    period: undefined,
    installments: undefined,
    income_tax_relevant: undefined,
  });

  const shouldGetPayableAccountsRef = useRef(false);

  // const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   if (submitObjectRef.current) {
  //     console.log(submitObjectRef.current);

  //     if (shouldSubmitRef.current) {
  //       apiService
  //         .createPayable(submitObjectRef.current)
  //         .then((_response) => {
  //           dispatch(
  //             setToastState({
  //               variant: "success",
  //               shouldShow: true,
  //               message: {
  //                 title: "Contas a pagar lançada",
  //                 description: "Clique aqui para acessar na Fintera",
  //                 link: { url: "https://www.google.com.br/" },
  //               },
  //             })
  //           );
  //         })
  //         .catch((_e) => {
  //           dispatch(
  //             setToastState({
  //               variant: "error",
  //               shouldShow: true,
  //               message: {
  //                 title: "Erro ao criar a entrada de mercadorias",
  //                 description:
  //                   "Não foi possível criar uma entrada de mercadorias a partir desta nota fiscal, tente novamente.",
  //               },
  //             })
  //           );
  //         });
  //     }
  //   }
  // };

  const preventSubmitDefaultBehavior = (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    // if (
    //   submitObjectRef.current &&
    //   submitObjectRef.current.amount === undefined
    // ) {
    //   submitObjectRef.current.amount = invoice.amount;
    // }
    // console.log(submitObjectRef.current);
  };

  // const fetchData = async () => {
  //   // await apiService.getAllPayableAccounts().then((response) => {
  //   //   allPayableAccountsRef.current = response;
  //   // });
  // };

  const handleGetPayableAccounts = (amount?: number) => {
    const queryStrings = handleParseQuerystrings(window.location.search);

    const searchStrings = handlePopulateQuerystring();

    let itemsPerPage = typeof amount === "number" ? amount : undefined;

    if (itemsPerPage === undefined && queryStrings.items_per_page) {
      itemsPerPage = parseInt(queryStrings.items_per_page[0]);
    }

    const localSelectOrganization = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ORGANIZATION
    ) as TOrganization;

    if (localSelectOrganization)
      selectOrganizationRef.current = localSelectOrganization;

    if (submitObjectRef.current.due_date.length === 0) {
      setShouldShowDueDateError(true);
      shouldGetPayableAccountsRef.current = false;
    } else if (shouldShowDueDateError) {
      setShouldShowDueDateError(false);
      shouldGetPayableAccountsRef.current = true;
    } else {
      shouldGetPayableAccountsRef.current = true;
    }

    if (shouldGetPayableAccountsRef.current) {
      if (!shouldShowTable) setShouldShowTable(true);
      if (payableAccounts.length > 0) setPayableAccounts([]);
      if (shoulShowEmptyState) setShouldShowEmptyState(false);

      cancelTokenSource.current = axios.CancelToken.source();

      searchStrings.set("page", pageRef.current.toString());

      searchStrings.set("items_per_page", (itemsPerPage ?? 1).toString());

      navigate("./" + handleSortQuerystring(searchStrings));

      apiService
        .getPayableAccounts(cancelTokenSource.current.token, {
          amount:
            submitObjectRef.current.amount.length > 0
              ? submitObjectRef.current.amount
              : undefined,
          due_date: submitObjectRef.current.due_date,
          paid_amount: isPaid ? submitObjectRef.current.paid_amount : undefined,
          payment_date: isPaid
            ? submitObjectRef.current.payment_date
            : undefined,

          category: categoryNameRef.current,
          description: descriptionRef.current,
          supplier_name: supplierNameRef.current,
          page: pageRef.current,
          per_page: itemsPerPage,
        })
        .then(async (response) => {
          if (response.data.results.length === 0) {
            setShouldShowEmptyState(true);
          }
          payableAccountDataRef.current = response.data;

          lastPageRef.current = Math.ceil(
            response.data.count / response.data.results.length
          );
          setPayableAccounts(response.data.results);
        })
        .catch(() => {
          setPayableAccounts([]);
          setShouldShowEmptyState(true);
        });
    }
  };

  const handleBodyRender = () => {
    return payableAccounts.length > 0
      ? payableAccounts.map(
          (payableAccount) =>
            ({
              elementId: `${payableAccount.id}`,
              file_nfe: null,
              file_xml: null,
              body: [
                {
                  style: "default",
                  showSkeleton: false,
                  value: payableAccount?.due_date.replace(
                    /(\d{4})-(\d{2})-(\d{2})/g,
                    "$3/$2/$1"
                  ),
                },
                {
                  style: "default",
                  showSkeleton: false,
                  value: convertNumberToBRL(Number(payableAccount?.amount)),
                },
                {
                  style: "default",
                  showSkeleton: false,
                  value: payableAccount?.description,
                },
                {
                  style: "default",
                  showSkeleton: false,
                  value: payableAccount?.category,
                },
                {
                  style: "default",
                  showSkeleton: false,
                  value: payableAccount?.supplier,
                },
                {
                  style: "default",
                  showSkeleton: false,
                  value:
                    payableAccount?.payment_date === null
                      ? " - "
                      : payableAccount.payment_date.replace(
                          /(\d{4})-(\d{2})-(\d{2})/g,
                          "$3/$2/$1"
                        ),
                },
                {
                  style: "default",
                  showSkeleton: false,
                  value:
                    payableAccount?.payment_amount === null
                      ? " - "
                      : convertNumberToBRL(
                          Number(payableAccount?.payment_amount)
                        ),
                },
              ] as TTableBody<TCellStyleVariants>[],
            } as {
              elementId: string;
              file_xml: null | string;
              file_nfe: null | string;
              body: TTableBody<TCellStyleVariants>[];
            })
        )
      : new Array(5).fill("").map(
          () =>
            ({
              elementId: "",
              file_nfe: null,
              file_xml: null,
              body: [
                { style: "default", value: "", showSkeleton: true },
                { style: "default", value: "", showSkeleton: true },
                { style: "default", value: "", showSkeleton: true },
                { style: "default", value: "", showSkeleton: true },
                { style: "default", value: "", showSkeleton: true },
                { style: "default", value: "", showSkeleton: true },
                { style: "default", value: "", showSkeleton: true },
              ] as TTableBody<TCellStyleVariants>[],
            } as {
              elementId: string;
              file_xml: null | string;
              file_nfe: null | string;
              body: TTableBody<TCellStyleVariants>[];
            })
        );
  };

  useEffect(() => {
    // fetchData();
    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  useEffect(() => {
    if (submitObjectRef.current.amount?.length === 0) {
      if (invoice) {
        submitObjectRef.current.amount = invoice?.amount;
      }
      if (paymentSlip) {
        submitObjectRef.current.amount = paymentSlip?.amount;
      }
    }
  }, [invoice, paymentSlip]);

  return (
    <Container onSubmit={preventSubmitDefaultBehavior}>
      <p>
        Busque a conta a pagar lançada na <b>Fintera</b> para vincular a
        {invoice === undefined ? ` esse boleto` : ` essa nota fiscal`}:
      </p>
      <RowContainer>
        <LabeledTextInput
          placeholder="Valor a pagar"
          filled={
            submitObjectRef.current.amount === ""
              ? "R$ " +
                (invoice?.amount ?? paymentSlip?.amount)?.replace(".", ",")
              : undefined
          }
          customFilter={(inputRef, setInputString) => {
            const value = inputRef.value;
            inputRef.value = value
              .replace(/\D/g, "")
              .slice(0, 10)
              .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
            setInputString(
              value
                .replace(/\D/g, "")
                .slice(0, 10)
                .replace(/^(\d+)(\d{2})$/, "R$ $1,$2")
            );
          }}
          onChange={(inputValue) => {
            submitObjectRef.current.amount = inputValue
              .replace(/\D/g, "")
              .replace(/(\d+)(\d{2})/g, "$1.$2");
          }}
          // onError={{
          //   hasError: shouldShowInstallmentsError,
          //   message: "Campo obrigatório",
          // }}
        />

        <Calendar
          className="calendar"
          placeholder="Vencimento"
          onSelect={(selectDate) => {
            submitObjectRef.current.due_date = selectDate.replace(
              /(\d{2})\/(\d{2})\/(\d{4})/g,
              "$3-$2-$1"
            );
          }}
          setDate={(setButtonSelectedDate, setDatePickerSelectedDate) => {
            submitObjectRef.current.due_date = format(new Date(), "yyyy-MM-dd");
            setButtonSelectedDate(format(new Date(), "dd/MM/yyyy"));
            setDatePickerSelectedDate({
              startDate: format(new Date(), "dd/MM/yyyy"),
            });
          }}
          onError={{
            hasError: shouldShowDueDateError,
            message: "Campo obrigatório",
          }}
        />
        <div className="isPaidContainer">
          <Check
            id="paid"
            onClick={() => {
              setIsPaid((before) => !before);
            }}
          />
          &nbsp;<label htmlFor="paid">Já paga</label>
        </div>

        {isPaid && (
          <>
            <LabeledTextInput
              placeholder="Valor pago"
              // filled={"R$ " + invoice?.amount}
              customFilter={(inputRef, setInputString) => {
                const value = inputRef.value;
                inputRef.value = value
                  .replace(/\D/g, "")
                  .slice(0, 10)
                  .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
                setInputString(
                  value
                    .replace(/\D/g, "")
                    .slice(0, 10)
                    .replace(/^(\d+)(\d{2})$/, "R$ $1,$2")
                );
              }}
              onChange={(inputValue) => {
                submitObjectRef.current.paid_amount = inputValue
                  .replace(/\D/g, "")
                  .replace(/(\d+)(\d{2})/g, "$1.$2");
              }}
              // onError={{
              //   hasError: shouldShowInstallmentsError,
              //   message: "Campo obrigatório",
              // }}
            />

            <Calendar
              className="calendar"
              placeholder="Data do pagamento"
              onSelect={(selectDate) => {
                submitObjectRef.current.payment_date = selectDate.replace(
                  /(\d{2})\/(\d{2})\/(\d{4})/g,
                  "$3-$2-$1"
                );
              }}
              setDate={(setButtonSelectedDate, setDatePickerSelectedDate) => {
                submitObjectRef.current.payment_date = format(
                  new Date(),
                  "yyyy-MM-dd"
                );
                setButtonSelectedDate(format(new Date(), "dd/MM/yyyy"));
                setDatePickerSelectedDate({
                  startDate: format(new Date(), "dd/MM/yyyy"),
                });
              }}
              // onError={{
              //   hasError: shouldShowDueDateError,
              //   message: "Campo obrigatório",
              // }}
            />
          </>
        )}
      </RowContainer>
      <RowContainer>
        <LabeledTextInput
          placeholder="Buscar por descrição"
          onChange={(inputValue) => {
            // submitObjectRef.current.fine_amount = inputValue;
            if (inputValue.length === 0) descriptionRef.current = undefined;
            else descriptionRef.current = inputValue;
          }}
        />
        <LabeledTextInput
          placeholder="Nome do Fornecedor"
          onChange={(inputValue) => {
            // submitObjectRef.current.fine_amount = inputValue;
            if (inputValue.length === 0) supplierNameRef.current = undefined;
            else supplierNameRef.current = inputValue;
          }}
        />
        <LabeledTextInput
          placeholder="Categoria"
          onChange={(inputValue) => {
            // submitObjectRef.current.fine_amount = inputValue;
            if (inputValue.length === 0) categoryNameRef.current = undefined;
            else categoryNameRef.current = inputValue;
          }}
        />
      </RowContainer>
      <RowContainer>
        <Button
          iconPosition="left"
          icon="search"
          variant="primary"
          text="Pesquisar contas a pagar"
          onClick={handleGetPayableAccounts}
        />
      </RowContainer>
      {shouldShowTable && (
        <>
          <RowContainer>
            <h2>Contas encontradas: </h2>
          </RowContainer>
          <RowContainer>
            <TableGrid
              columns={{
                tableHead: {
                  width: [`0 51px 93px 103px 180px 188px 184px 93px 126px 4px`],
                },
                tableBody: {
                  width: [`0 51px 93px 103px 180px 188px 184px 93px 126px 4px`],
                },
              }}
              row="singleRow"
              selectType={{
                type: "singleSelect",
              }}
              tableHead={{
                value: [
                  { style: "noIcon", value: "Vencimento" },
                  { style: "noIcon", value: "A pagar (R$)" },
                  { style: "noIcon", value: "Descrição" },
                  { style: "noIcon", value: "Categoria" },
                  { style: "noIcon", value: "Fornecedor" },
                  { style: "noIcon", value: "Pago em" },
                  { style: "noIcon", value: "Valor pago (R$)" },
                ],
              }}
              onSelect={(selected) => {
                const payableAccount = payableAccounts.find(
                  (element) => `${element.id}` === selected[0].elementId
                );
                if (!selected[0].isChecked) setSelectRow(null);
                else if (payableAccount) {
                  setSelectRow({
                    ...selected[0],
                    hasInstallments: payableAccount.mode === "installments",
                    selectPayable: payableAccount,
                  });
                }
              }}
              tableBody={handleBodyRender()}
              emptyState={{
                isEmptyState: shoulShowEmptyState,
                title: "Nenhuma conta encontrada",
                description: "Altere os filtros e realize a busca novamente",
              }}
              footer={{
                // pagination: {
                //   buttonsAmount: 30,
                //   onClick(activeIndex) {
                //     // console.log("active index: ", activeIndex);
                //   },
                // },
                leftSideDescription:
                  " | Total de " +
                  (payableAccountDataRef.current?.count ?? "-") +
                  ((payableAccountDataRef.current?.count ?? 0) > 1
                    ? " registros encontrados"
                    : " registro encontrado"),
                // description:
                //   "Mostrando " +
                //   (payableAccountDataRef.current?.results.length ?? "-") +
                //   " de " +
                //   (payableAccountDataRef.current?.results.length ?? "-") +
                //   " resultado" +
                //   ((payableAccountDataRef.current?.results.length ?? 0) > 1
                //     ? "s"
                //     : ""),
                onSelectItemsPerPage(amount) {
                  pageRef.current = 1;
                  handleGetPayableAccounts(amount);
                },
                customPagination:
                  payableAccountDataRef.current &&
                  payableAccountDataRef.current.pages.length > 0
                    ? {
                        currentPage: `${payableAccountDataRef.current?.current_page}`,
                        buttonBackward: {
                          disabled:
                            payableAccountDataRef.current.current_page === 1,
                          onClick() {
                            if (payableAccountDataRef.current) {
                              if (
                                payableAccountDataRef.current.current_page === 1
                              ) {
                                pageRef.current = 1;
                              } else {
                                pageRef.current =
                                  payableAccountDataRef.current.current_page -
                                  1;
                              }

                              handleGetPayableAccounts();
                            }
                          },
                        },
                        buttonForward: {
                          disabled:
                            payableAccountDataRef.current.current_page ===
                            payableAccountDataRef.current.pages.length,
                          onClick() {
                            if (
                              lastPageRef.current &&
                              payableAccountDataRef.current
                            ) {
                              if (
                                payableAccountDataRef.current.current_page >=
                                lastPageRef.current
                              ) {
                                pageRef.current =
                                  payableAccountDataRef.current.current_page;
                              } else {
                                pageRef.current =
                                  (
                                    payableAccountDataRef.current as TPayableAccountData
                                  ).current_page + 1;
                              }

                              handleGetPayableAccounts();
                            }
                          },
                        },
                        pages: payableAccountDataRef.current.pages.map(
                          (page) => {
                            return {
                              value: Object.keys(page)[0],
                              onClick() {
                                let temp = Object.values(
                                  page as {
                                    [key: number]: string;
                                  }
                                )[0].match(/.*?page=(\d+)&per_/);

                                if (temp !== null && temp[1]) {
                                  pageRef.current = parseInt(temp[1]);
                                } else {
                                  pageRef.current = 1;
                                }

                                handleGetPayableAccounts();
                              },
                            };
                          }
                        ),
                      }
                    : undefined,
              }}
            />
          </RowContainer>
        </>
      )}
      <Footer $shouldShowTable={shouldShowTable}>
        <div className="buttonsContainer">
          {footer.buttons?.map((buttonProps, index) => {
            const { onClick, ...props } = buttonProps;
            return (
              <Button
                key={index}
                role={index === 1 ? "submit" : undefined}
                {...props}
                onClick={() => {
                  if (onClick) {
                    if (selectRow) onClick(selectRow);
                    else onClick();
                  }
                }}
                disabled={index === 1 && selectRow === null}
              />
            );
          })}
        </div>
      </Footer>
    </Container>
  );
};

export default LinkPayable;
