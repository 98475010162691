import { createAsyncThunk } from "@reduxjs/toolkit";
import { /* TApiError,  */ TApiService } from "../services/api";
import { TUserData } from "./reducers/userData";
import { TAccount, TAccountData } from "./reducers/account";
import {
  TGlobalState,
  TInvoiceData,
  TInvoiceEntryStatus,
  TInvoiceManifestastionStatus,
  // TNexaasState,
  TPaymentSlipData,
  setInvoiceCounters,
  // setAllowedOrganizationsState,
  setInvoices,
  setMembershipsState,
  setPaymentSlipCounters,
  setPaymentSlips,
  setSelectedAccountState,
} from "./reducers/globalState";
// import { errorHandler } from "../utils/misc";
import { TOrganization, TOrganizationData } from "./reducers/organizations";
import { cnpjMask } from "../utils/formatters";
import {
  // TMembership,
  TMembershipData,
  TMembershipRole,
  TMembershipStatus,
} from "./reducers/memberships";
import { CancelToken } from "axios";

export const fetchUserData = createAsyncThunk<
  TUserData,
  // void,
  { cancelToken: CancelToken },
  { extra: { apiService: TApiService } }
>("data/fetchUserData", async (params, { extra /* dispatch */ }) => {
  const { apiService } = extra;
  try {
    const response = await apiService.getUserData(params.cancelToken);

    return response?.data;
  } catch (e) {
    // const error = e as TApiError;

    // errorHandler(dispatch, error, "Erro ao obter dados de usuário");

    console.error(e);

    throw e;
  }
});

export const fetchAccounts = createAsyncThunk<
  TAccountData,
  // void,
  { cancelToken: CancelToken },
  { extra: { apiService: TApiService } }
>("data/fetchAccounts", async (params, { extra, dispatch, getState }) => {
  const { apiService } = extra;
  const { globalState } = getState() as { globalState: { data: TGlobalState } };

  try {
    let account = localStorage.getItem("selectedAccount");
    if (account) {
      account = JSON.parse(account);
      const temp = account as any;
      dispatch(
        setSelectedAccountState({
          selectedAccount: temp as TAccount,
          lastAccessedAccounts: globalState.data.lastAccessedAccounts,
        })
      );
    }

    const response = await apiService.getAccounts(params.cancelToken);

    return response?.data;
  } catch (e) {
    // const error = e as TApiError;

    // errorHandler(dispatch, error, "Erro ao obter dados de conta");

    console.error(e);

    throw e;
  }
});

export const fetchMemberships = createAsyncThunk<
  TMembershipData,
  { uuid: string; organizations?: TOrganization[]; cancelToken: CancelToken },
  { extra: { apiService: TApiService } }
>(
  "data/fetchMemberships",
  async (params, { extra, /* dispatch, getState */ }) => {
    const { apiService } = extra;
    // const { /* userData, */ organizations } = getState() as {
    //   globalState: { data: TGlobalState };
    //   userData: { data: { user: TUserData } };
    //   organizations: { data: { results: TOrganization[] } };
    // };

    try {
      const response = await apiService.getMemberships(
        params.uuid,
        params.cancelToken
      );
      // console.log("orgs from membership fetch: ", organizations);
      // if (params.organizations) {
      //   const membership = response.data.results as TMembership[];
      //   const currentUser = membership.filter(
      //     (member) => member.user && member.user.uuid === userData.data.user.uuid
      //   )[0];

      //   const allowedOrganizations = params.organizations.filter((organization) =>
      //     currentUser.organizations.includes(organization.uuid)
      //   );
      //   setAllowedOrganizationsState({ allowedOrganizations });
      // }

      return response?.data;
    } catch (e) {
      // const error = e as TApiError;

      // errorHandler(dispatch, error, "Erro ao obter dados de filiação");

      console.error(e);

      throw e;
    }
  }
);

export const fetchOrganizations = createAsyncThunk<
  TOrganizationData,
  { uuid: string; fetchMembership?: boolean; cancelToken: CancelToken },
  { extra: { apiService: TApiService } }
>(
  "data/fetchOrganizations",
  async (params, { extra, dispatch /* getState */ }) => {
    const { apiService } = extra;
    // const { globalState } = getState() as { globalState: { data: TGlobalState } };

    try {
      const response = await apiService.getOrganizations(
        params.uuid,
        params.cancelToken
      );

      let responseResults = response.data.results as TOrganization[];
      responseResults = responseResults.map((organization) => {
        organization.cnpj = cnpjMask(organization.cnpj);
        return organization;
      });

      let selectedOrganization = localStorage.getItem("selectedOrganization");
      let lastAccessedOrgs = localStorage.getItem("lastAccessedOrganizations");
      let organization;

      if (selectedOrganization) {
        selectedOrganization = JSON.parse(selectedOrganization);
        organization = selectedOrganization as any;
        const localStorageOrg = organization as TOrganization;
        if (localStorageOrg.account.uuid !== params.uuid && lastAccessedOrgs) {
          // console.log("local storage and param are different");
          let temp = JSON.parse(lastAccessedOrgs);
          temp = temp as any;
          const localStorageLastAccessedOrgs = temp as TOrganization[];
          const localStorageSameAccountLastAccessedOrgs =
            localStorageLastAccessedOrgs.filter(
              (orgs) => orgs.account.uuid === params.uuid
            );
          organization =
            localStorageSameAccountLastAccessedOrgs[0] ?? responseResults[0];
        } else {
          organization = localStorageOrg;
        }
      } else {
        organization = responseResults[0];
      }

      // let lastAccessedOrganizations;
      // if (lastAccessedOrgs) {
      //   lastAccessedOrganizations = JSON.parse(
      //     lastAccessedOrgs
      //   ) as TOrganization[];
      // } else {
      //   lastAccessedOrganizations = globalState.data.lastAccessedOrganizations;
      // }

      const temp = organization as any;

      // dispatch(
      //   setSelectedOrganizationState({
      //     selectedOrganization: temp as TOrganization,
      //     lastAccessedOrganizations,
      //   })
      // );

      if (params.fetchMembership) {
        dispatch(
          fetchMemberships({
            uuid: (temp as TOrganization).account.uuid,
            organizations: responseResults,
            cancelToken: params.cancelToken,
          })
        );
      }

      response.data.results = responseResults;
      return response.data;
    } catch (e) {
      // const error = e as TApiError;

      // errorHandler(dispatch, error, "Erro ao obter dados de organizações");

      console.error(e);

      throw e;
    }
  }
);

export const fetchInvoices = createAsyncThunk<
  TInvoiceData,
  {
    organizationUuid: string;
    manifestation_status?: TInvoiceManifestastionStatus;
    launch_status?: TInvoiceEntryStatus;
    has_accounts_payable?: boolean;
    has_goods_receipt?: boolean;
    dateIssueEnd?: string;
    dateIssueStart?: string;
    page?: number;
    per_page?: number;
    search?: string;
    cancelToken: CancelToken;
    mock?: { type: "resolve"; nexaasState: number } | { type: "reject" };
  },
  { extra: { apiService: TApiService } }
>("data/fetchInvoices", async (params, { extra, dispatch }) => {
  const { apiService } = extra;

  apiService
    .getInvoiceCounters(
      {
        organizationUuid: params.organizationUuid,
        date_issue_after: params.dateIssueStart,
        date_issue_before: params.dateIssueEnd,
        has_accounts_payable: params.has_accounts_payable,
        has_goods_receipt: params.has_goods_receipt,
        // launch_status: params.launch_status,
        manifestation_status: params.manifestation_status,
        page: params.page,
        per_page: params.per_page,
        search: params.search,
      },
      params.cancelToken
    )
    .then((response) => {
      dispatch(setInvoiceCounters({ counters: response.data }));
    })
    .catch((e) => {
      throw e;
    });

  const response = await apiService
    .getInvoices(
      {
        organizationUuid: params.organizationUuid,
        date_issue_after: params.dateIssueEnd,
        date_issue_before: params.dateIssueStart,
        has_accounts_payable: params.has_accounts_payable,
        has_goods_receipt: params.has_goods_receipt,
        launch_status: params.launch_status,
        manifestation_status: params.manifestation_status,
        page: params.page,
        per_page: params.per_page,
        search: params.search,
      },
      params.cancelToken,
      // {
      //   type: "resolve",
      //   data: {
      //     current_page: 1,
      //     pages: [
      //       {
      //         "1": "http://localhost:8000/invoices/?date_issue_after=2024-06-25&date_issue_before=2024-07-25&launch_status=10&organization=b08faf86-da16-4368-8c77-997ae2f5914c&per_page=100",
      //       },
      //     ],
      //     count: 1,
      //     results: [
      //       {
      //         uuid: "fc2f6963-994e-48fe-93a7-04e6c3ab29f3",
      //         issuer: {
      //           name: "hello world",
      //           document_number: "01234567891234",
      //         },
      //         number: 645,
      //         date_issue: "2024-07-12T14:39:59Z",
      //         amount: "365.00",
      //         manifestation: 10,
      //         access_key: "6556534236543765",
      //         file_xml:
      //           "http://localhost:8000/media/invoices/fc2f6963-994e-48fe-93a7-04e6c3ab29f3/6556534236543765_645.xml",
      //         file_nfe: null,
      //         launch_status: 10,
      //         goods_receipt_url:
      //           params.mock?.type === "resolve"
      //             ? "https://www.google.com"
      //             : null,
      //         accounts_payable_url: null,
      //         boletos_exists: false,
      //         fintera_id: null,
      //         nexaas_state: (params.mock?.type === "resolve"
      //           ? params.mock.nexaasState
      //           : 10) as TNexaasState,
      //         nexaas_finished_at: null,
      //         invoice_type: 20,
      //         label: "teste",
      //       },
      //     ],
      //   },
      // }
    )
    .then((response) => {
      dispatch(
        setInvoices({
          invoices: response.data as TInvoiceData,
          entryStatus: params.launch_status,
        })
      );
      return response;
    })
    .catch(() => {
      return apiService
        .getInvoices(
          {
            organizationUuid: params.organizationUuid,
            date_issue_after: params.dateIssueEnd,
            date_issue_before: params.dateIssueStart,
            has_accounts_payable: params.has_accounts_payable,
            has_goods_receipt: params.has_goods_receipt,
            launch_status: params.launch_status,
            manifestation_status: params.manifestation_status,
            page: params.page && params.page > 0 ? params.page - 1 : undefined,
            per_page: params.per_page,
          },
          params.cancelToken
        )
        .catch((e) => {
          // const error = e as TApiError;

          // errorHandler(dispatch, error, "Erro ao obter dados de invoices");

          throw e;
        });
    });

  return response.data;
});

export const fetchPaymentSlips = createAsyncThunk<
  TPaymentSlipData,
  {
    organizationUuid: string;
    launch_status?: TInvoiceEntryStatus;
    has_accounts_payable?: boolean;
    has_goods_receipt?: boolean;
    dateIssueEnd?: string;
    dateIssueStart?: string;
    page?: number;
    per_page?: number;
    search?: string;
    cancelToken: CancelToken;
  },
  { extra: { apiService: TApiService } }
>("data/fetchPaymentSlips", async (params, { extra, dispatch }) => {
  const { apiService } = extra;

  apiService
    .getPaymentSlipCounters(
      {
        organizationUuid: params.organizationUuid,
        due_date_before: params.dateIssueStart,
        due_date_after: params.dateIssueEnd,
        has_accounts_payable: params.has_accounts_payable,
        // has_goods_receipt: params.has_goods_receipt,
        // launch_status: params.launch_status,
        page: params.page,
        per_page: params.per_page,
        search: params.search,
      },
      params.cancelToken
    )
    .then((response) => {
      dispatch(setPaymentSlipCounters({ counters: response.data }));
    })
    .catch((e) => {
      throw e;
    });

  const response = await apiService
    .getPaymentSlips(
      {
        organizationUuid: params.organizationUuid,
        due_date_before: params.dateIssueStart,
        due_date_after: params.dateIssueEnd,
        has_accounts_payable: params.has_accounts_payable,
        // has_goods_receipt: params.has_goods_receipt,
        launch_status: params.launch_status,
        page: params.page,
        per_page: params.per_page,
        search: params.search,
      },
      params.cancelToken
    )
    .then((response) => {
      // console.log(
      //   "response: ",
      //   response.data,
      //   ", launch status: ",
      //   params.launch_status
      // );
      dispatch(
        setPaymentSlips({
          paymentSlips: response.data as TPaymentSlipData,
          entryStatus: params.launch_status,
        })
      );
      return response;
    })
    .catch(() => {
      return apiService
        .getPaymentSlips(
          {
            organizationUuid: params.organizationUuid,
            due_date_before: params.dateIssueStart,
            due_date_after: params.dateIssueEnd,
            launch_status: params.launch_status,
            page: params.page,
            per_page: params.per_page,
            search: params.search,
          },
          params.cancelToken
        )
        .catch((e) => {
          // const error = e as TApiError;

          // errorHandler(dispatch, error, "Erro ao obter dados de invoices");

          throw e;
        });
    });

  return response.data;
});

export const fetchMembershipsData = createAsyncThunk<
  TMembershipData,
  {
    uuid: string;
    cancelToken: CancelToken;
    status?: TMembershipStatus[];
    role?: TMembershipRole[];
    page?: number;
  },
  { extra: { apiService: TApiService } }
>("data/fetchMembershipsData", async (params, { extra, dispatch }) => {
  const { apiService } = extra;

  try {
    const response = await apiService.getMemberships(
      params.uuid,
      params.cancelToken,
      params.page,
      params.role,
      params.status
    );

    dispatch(
      setMembershipsState({
        membershipData: response.data as TMembershipData,
      })
    );

    return response.data;
  } catch (e) {
    // const error = e as TApiError;

    // errorHandler(dispatch, error, "Erro ao obter dados de organizações");

    console.error(e);

    throw e;
  }
});
