import styled from "styled-components";
import { useEffect, useId, useRef, useState } from "react";
import { TVariants } from ".";
import Search from "../Inputs/Search";
import Button from "../Button";
import DatePicker from "../Inputs/PeriodDatePicker";

const Container = styled.menu<{
  $shouldshow: "show" | "hide";
  list: TList;
  $variant: TVariants;
  $searchId: string;
  $hasBottomBar?: boolean;
}>`
  ${({ $variant, $searchId }) =>
    $variant === "unlabeled" && $searchId.length > 0 && "width: 294px;"}

  ${({ $variant, $searchId, $hasBottomBar }) =>
    $variant !== "calendar" &&
    $searchId.length === 0 &&
    `box-sizing: border-box;` + `width:${$hasBottomBar ? "280px" : "100%"};`}

  padding: ${({ list }) => (list === "default" ? "16px" : "8px")};

  margin: 0;

  position: absolute;
  z-index: ${({ $shouldshow }) => ($shouldshow === "show" ? "2" : "-1")};
  transition: z-index 0.15s ease-in-out;

  background: transparent;

  & > button:nth-child(1) {
    width: 100%;
    // margin-bottom: 10px;
  }

  & > button:nth-child(1),
  & p.optGroup {
    visibility: ${({ $shouldshow }) =>
      $shouldshow === "show" ? "visible" : "hidden"};
    transition: visibility 0.1s ease-in-out;
  }

  & .optGroupContainer {
    position: relative;
    height: 31px;
  }

  & p.optGroup {
    position: absolute;
    z-index: 1;

    top: 25%;
    left: 12px;

    font-family: var(--font-paragraph-default);
    font-size: 12px;
    color: var(--color-dark-blue-400);
  }

  &::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    height: ${({ $shouldshow }) => ($shouldshow === "show" ? "100%" : "0%")};
    width: 100%;

    background: var(--color-light-0);
    border-radius: 8px;
    border: 1px solid var(--color-dark-blue-300);
    border-width: ${({ $shouldshow }) =>
      $shouldshow === "show" ? "1px" : "0px"};
    transition: height 0.2s ease-in-out;
  }
`;

const ItemButton = styled.button<{
  label?: string;
  $variant: TVariants;
  list: TList;
  $shouldshow: "show" | "hide";
}>`
  position: relative;

  cursor: pointer;

  width: 100%;
  height: 46px;

  // &:last-of-type {
  margin-bottom: ${({ list }) => (list === "default" ? "0px" : "5px")};
  // }
  ${({ list }) => list === "default" && `&:last-of-type { margin-bottom: 5px;}`}

  // padding: 12px;

  background: var(--color-light-0);

  border-radius: 8px;

  visibility: ${({ $shouldshow }) =>
    $shouldshow === "show" ? "visible" : "hidden"};
  transition: visibility 0.1s ease-in-out;

  ${({ label }) =>
    label &&
    `&::before{
      content:'${label}';
      font-size: 14px;
      font-family: var(--font-paragraph-bold);
      color: var(--color-text-paragraph);

      position: absolute;
      top: 7px;
      left: 12px;
    }`}

  p {
    ${({ $variant }) =>
      $variant === "unlabeled"
        ? `font-family: var(--font-paragraph-default);` +
          `color: var(--color-text-paragraph);` +
          `font-size: 14px;` +
          `top: 16px;`
        : $variant === "calendar"
        ? `font-family: var(--font-paragraph-default);` +
          `color: var(--color-text-paragraph);` +
          `font-size: 14px;` +
          `top: 14px;`
        : `font-family: var(--font-paragraph-default);` +
          `color: var(--color-text-labels);` +
          `font-size: 12px;` +
          `top: 26px;`};

    position: absolute;
    ${({ $variant }) => $variant !== "calendar" && `left: 12px`};
  }

  &::after {
    content: "";
    font-family: "unicons";
    font-size: 20px;
    position: absolute;
    right: 12px;
    top: 16px;
    color: var(--color-light-0);
    visibility: hidden;
  }

  &:hover {
    background: var(--color-light-100);
  }

  &.active,
  &:active {
    background: var(--color-main-blue);

    &::before,
    p {
      color: var(--color-light-0);
    }

    p {
      ${({ $variant }) =>
        $variant === "unlabeled" && "font-family: var(--font-paragraph-bold);"}
    }

    &::after {
      visibility: visible;
    }
  }
`;

const CalendarContainer = styled.div<{ $shouldShow: "hide" | "show" }>`
  position: relative;

  display: flex;
  justify-content: space-between;

  width: 460px;

  visibility: ${({ $shouldShow }) =>
    $shouldShow === "show" ? "visible" : "hidden"};

  transition: visibility 0.15s ease-in-out;

  box-shadow: 2px 4px 8px 0px rgba(57, 60, 77, 0.1);

  margin-bottom: 8px;

  // border-radius: 8px;

  strong {
    font-size: 14px;
    font-family: var(--font-paragraph-bold);
    color: var(--color-text-paragraph);
  }

  .optionsContainer {
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }

  .optionsTitleContainer {
    box-sizing: border-box;
    position: absolute;
    height: 40px;
    display: flex;
    align-items: center;
    margin-left: 6px;
    border: 1px solid var(--color-light-200);
    border-radius: 8px 0 0 0;
    top: 0px;
    left: -6px;
    z-index: 0;
    width: 100%;

    strong {
      padding-left: 6px;
    }
  }

  .optionsSubcontainer {
    box-sizing: border-box;
    width: 100%;
    max-height: 240px;
    margin-top: 40px;
    overflow-y: scroll;
    border: 1px solid var(--color-light-200);
    border-top: 0;
    border-radius: 0 0 0 8px;
  }

  #datePicker {
    position: relative;
    padding: 3px;
    border-radius: 0 8px 8px 0;
    border: 1px solid var(--color-light-200);
    border-left: 0;
  }
`;

const FilterArea = styled.div<{ $shouldshow: "show" | "hide" }>`
  position: relative;
  display: flex;
  justify-content: flex-end;

  padding-top: 12px;
  border-top: 1px solid var(--color-light-200);

  & > *:nth-child(2) {
    margin-left: 8px;
  }

  &,
  & > * {
    visibility: ${({ $shouldshow }) =>
      $shouldshow === "show" ? "visible" : "hidden"};
    transition: visibility 0.1s ease-in-out;
  }
`;

type TList = "default" | "filter";

type TCommonProps = {
  list: TList;
  content: string[];
  setSelectedDatesRef?: never;
  onClick: (
    selectedIndex: string,
    selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>
  ) => void;
  customFilter?: (
    selectedIndex: string,
    selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>
  ) => void;
  shouldShow: "show" | "hide";
  searchId?: string;
  hasBottomBar?: boolean;
  menuId?: string;
  optGroup?: string;
  onCancel?: (buttonId: string) => void;
  onAction?: (selected: string[], buttonId: string) => void;
  forceSelectOptionsByIndex?: number[];
  filled?: never;
};

type TCalendarProps = {
  list: "filter";
  content?: never;
  setSelectedDatesRef?: React.MutableRefObject<
    | React.Dispatch<
        React.SetStateAction<{
          startDate: string;
          endDate: string;
        }>
      >
    | undefined
  >;
  onClick: (
    selectedIndex: string,
    selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
    setShouldClearCalendar: React.Dispatch<React.SetStateAction<boolean>>,
    setSelectedDates?: React.Dispatch<
      React.SetStateAction<{
        startDate: string;
        endDate: string;
      }>
    >
  ) => void;
  customFilter?: never;
  shouldShow: "show" | "hide";
  searchId?: never;
  hasBottomBar?: never;
  menuId?: never;
  optGroup?: never;
  onCancel?: (buttonId: string) => void;
  onAction?: (selected: string[], buttonId: string) => void;
  forceSelectOptionsByIndex?: number[];
  filled?: string[];
};

type TCalendar = {
  variant: "calendar" | "calendar-slip";
  label?: never;
};

type TLabeled = {
  variant: "labeled";
  label: string[];
};

type TUnlabeled = {
  variant: "unlabeled";
  label?: never;
};

type TMenuProps<Variant extends TVariants> = Variant extends "labeled"
  ? TLabeled & TCommonProps
  : Variant extends "calendar"
  ? TCalendar & TCalendarProps
  : TUnlabeled & TCommonProps;

export type TCalendarMenuOptions =
  | "Últimos 30 dias"
  | "Todo o período"
  | "Hoje"
  | "Ontem"
  | "Últimos 7 dias"
  | "Últimos 14 dias"
  | "Últimos 60 dias"
  | "Últimos 90 dias"
  | "Esta semana"
  | "Semana passada"
  | "Este mês"
  | "Mês passado"
  | TCalendarMenuOptionsToLowerCase
  | TTranslatedCalendarMenuOptions;

type TCalendarMenuOptionsToLowerCase =
  | "últimos 30 dias"
  | "todo o período"
  | "hoje"
  | "ontem"
  | "últimos 7 dias"
  | "últimos 14 dias"
  | "últimos 60 dias"
  | "últimos 90 dias"
  | "esta semana"
  | "semana passada"
  | "este mês"
  | "mês passado";

export type TTranslatedCalendarMenuOptions =
  | "last-30-days"
  | "all-time"
  | "today"
  | "yesterday"
  | "last-7-days"
  | "last-14-days"
  | "last-60-days"
  | "last-90-days"
  | "this-week"
  | "last-week"
  | "this-month"
  | "last-month";

export type TCalendarSlipMenuOptions =
  | "Todo o período"
  | "Hoje"
  | "Ontem"
  | "Próximos 7 dias"
  | "Próximos 14 dias"
  | "Próximos 60 dias"
  | "Próximos 90 dias"
  | "Esta semana"
  | "Semana que vem"
  | "Este mês"
  | "Mês que vem"
  | TCalendarSlipMenuOptionsToLowerCase
  | TTranslatedCalendarSlipMenuOptions;

type TCalendarSlipMenuOptionsToLowerCase =
  | "todo o período"
  | "hoje"
  | "ontem"
  | "próximos 7 dias"
  | "próximos 14 dias"
  | "próximos 60 dias"
  | "próximos 90 dias"
  | "esta semana"
  | "semana que vem"
  | "este mês"
  | "mês que vem";

export type TTranslatedCalendarSlipMenuOptions =
  | "all-time"
  | "today"
  | "yesterday"
  | "next-7-days"
  | "next-14-days"
  | "next-60-days"
  | "next-90-days"
  | "this-week"
  | "next-week"
  | "this-month"
  | "next-month";

export const CALENDAR_CONTENT_OPTIONS = [
  "Últimos 30 dias",
  "Todo o período",
  "Hoje",
  "Ontem",
  "Últimos 7 dias",
  "Últimos 14 dias",
  "Últimos 60 dias",
  "Últimos 90 dias",
  "Esta semana",
  "Semana passada",
  "Este mês",
  "Mês passado",
];

export const CALENDAR_SLIP_CONTENT_OPTIONS = [
  "Todo o período",
  "Hoje",
  "Ontem",
  "Próximos 7 dias",
  "Próximos 14 dias",
  "Próximos 60 dias",
  "Próximos 90 dias",
  "Esta semana",
  "Semana que vem",
  "Este mês",
  "Mês que vem",
];

const Menu: React.FC<TMenuProps<TVariants>> = ({
  variant,
  list,
  content,
  label,
  onClick,
  customFilter,
  onCancel,
  onAction,
  shouldShow,
  searchId,
  hasBottomBar,
  menuId,
  optGroup,
  forceSelectOptionsByIndex,
  filled,
  setSelectedDatesRef,
}) => {
  const [selected, setSelected] = useState([
    variant.includes("calendar") && !forceSelectOptionsByIndex
      ? variant.includes("slip")
        ? CALENDAR_SLIP_CONTENT_OPTIONS[0]
        : CALENDAR_CONTENT_OPTIONS[0]
      : "",
  ]);

  const actionButtonId = useId();
  const cancelButtonId = useId();

  const forceSelectOptionsByIndexRef = useRef<number[] | undefined>(
    forceSelectOptionsByIndex
  );

  const [shouldClearCalendar, setShouldClearCalendar] = useState(false);

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel(cancelButtonId);
      // setSelected([""]);
    }
  };

  const calendarOptions =
    variant.includes("calendar") && variant.includes("slip")
      ? CALENDAR_SLIP_CONTENT_OPTIONS
      : CALENDAR_CONTENT_OPTIONS;

  const handleOnAction = () => {
    if (onAction) onAction(selected, actionButtonId);
    if (selected[0]?.length === 0) setSelected([calendarOptions[0]]);
  };

  // console.log("from menu, selected: ", selected);

  useEffect(() => {
    if (shouldClearCalendar) filled = [];
  }, [shouldClearCalendar]);

  return (
    <Container
      $searchId={searchId ?? ""}
      id={menuId}
      list={list}
      $shouldshow={shouldShow}
      $variant={variant.includes("calendar") ? "calendar" : variant}
      tabIndex={1}
      className={variant}
      $hasBottomBar={hasBottomBar}
    >
      {searchId && searchId.length > 0 && (
        <Search id={searchId} variant="small" />
      )}
      {optGroup && (
        <div className="optGroupContainer">
          <p className="optGroup">{optGroup}</p>
        </div>
      )}
      {variant.includes("calendar") && (
        <CalendarContainer $shouldShow={shouldShow}>
          <div className="optionsContainer">
            <div className="optionsTitleContainer">
              <strong>Selecionar período</strong>
            </div>
            <div className="optionsSubcontainer">
              {calendarOptions.map((value, index) => {
                return (
                  <ItemButton
                    list={list}
                    $shouldshow={shouldShow}
                    className={
                      (selected.includes(value) ||
                      forceSelectOptionsByIndexRef.current?.includes(index)
                        ? "active"
                        : "") + " calendar"
                    }
                    label={label && label[index]}
                    $variant={"calendar"}
                    key={index + "-selected-" + selected + shouldClearCalendar}
                    onClick={() => {
                      forceSelectOptionsByIndexRef.current = [];
                      // setShouldClearCalendar(true);
                      // setTimeout(() => {
                      //   setShouldClearCalendar(false);
                      // }, 10);
                      onClick(
                        `${index}`,
                        [value],
                        setSelected,
                        setShouldClearCalendar
                      );
                    }}
                  >
                    <p className="calendar">{value}</p>
                  </ItemButton>
                );
              })}
            </div>
          </div>
          <DatePicker
            key={
              (filled && filled.length) +
              " - " +
              shouldClearCalendar +
              selected.length +
              "-calendar-datepicker"
            }
            setSelectedDatesRef={setSelectedDatesRef}
            id="datePicker"
            onSelect={(result, setSelectedDates) => {
              // console.log("select from datepicker: ", result);
              onClick(
                ``,
                [result.startDate, result.endDate],
                setSelected,
                setShouldClearCalendar,
                setSelectedDates
              );
              forceSelectOptionsByIndexRef.current = [];
              setSelected([result.startDate + " - " + result.endDate]);
            }}
            shouldClearSelected={
              shouldClearCalendar /*&& selected[0].length === 0  ||
              CALENDAR_CONTENT_OPTIONS.includes(selected[0]) */
            }
            filled={
              selected.length === 2
                ? {
                    startDate: selected[1],
                    endDate: selected[0],
                  }
                : filled && filled.length === 2
                ? {
                    startDate: filled[1],
                    endDate: filled[0],
                  }
                : undefined
            }
          />
        </CalendarContainer>
      )}
      {variant !== "calendar" &&
        (content as string[])?.map((value, index) => {
          return (
            <ItemButton
              list={list}
              $shouldshow={shouldShow}
              className={
                forceSelectOptionsByIndexRef.current?.includes(index) ||
                selected.includes(`${index}`)
                  ? "active"
                  : ""
              }
              label={label && label[index]}
              $variant={variant}
              key={index}
              onClick={() => {
                forceSelectOptionsByIndexRef.current = [];
                if (customFilter) {
                  customFilter(`${index}`, selected, setSelected);
                } else {
                  (
                    onClick as (
                      selectedIndex: string,
                      selected: string[],
                      setSelected: React.Dispatch<
                        React.SetStateAction<string[]>
                      >
                    ) => void
                  )(`${index}`, selected, setSelected);
                }
              }}
            >
              <p>{value}</p>
            </ItemButton>
          );
        })}

      {(variant.includes("calendar") ||
        (searchId && searchId.length > 0) ||
        hasBottomBar) && (
        <FilterArea $shouldshow={shouldShow}>
          <Button
            id={cancelButtonId}
            variant="secondary"
            icon="noIcon"
            text="Cancelar"
            onClick={handleOnCancel}
            className="calendar"
          />
          <Button
            key={"action -" + selected + shouldClearCalendar}
            id={actionButtonId}
            onClick={handleOnAction}
            variant="primary"
            icon="noIcon"
            text="Aplicar filtro"
          />
        </FilterArea>
      )}
    </Container>
  );
};

export default Menu;
