import styled from "styled-components";
import { localStorageConstants } from "../../utils/constants";
import apiService, { TApiError } from "../../services/api";
import {
  errorHandler,
  handleGetDataFromLocalStorage,
  handleSetDataToLocalStorage,
} from "../../utils/miscellaneous";
import { TAccountData } from "../../redux/reducers/account";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { ROUTES } from "../../router";
import axios, { CancelTokenSource } from "axios";

const Container = styled.main``;

const Blank = function () {
  const [accountsCount, setAccountsCount] = useState<number | null>(null);

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const fetchData = async () => {
    try {
      const localUserData = localStorage.getItem(
        localStorageConstants.USER_DATA
      );
      if (!localUserData) {
        const userDataResponse = (
          await apiService.getUserData(
            (cancelTokenSource.current as CancelTokenSource).token
          )
        ).data;
        handleSetDataToLocalStorage(
          localStorageConstants.USER_DATA,
          userDataResponse
        );
      }

      const localAccountCount = handleGetDataFromLocalStorage(
        localStorageConstants.ACCOUNTS_COUNT
      ) as number;
      if (!localAccountCount) {
        const accountsResponse = (
          await apiService.getAccounts(
            (cancelTokenSource.current as CancelTokenSource).token
          )
        ).data as TAccountData;

        handleSetDataToLocalStorage(
          localStorageConstants.ACCOUNTS_COUNT,
          accountsResponse.count
        );
        setAccountsCount(accountsResponse.count);
      } else {
        setAccountsCount(localAccountCount);
      }
    } catch (e) {
      errorHandler(dispatch, e as TApiError);
    }
  };

  useEffect(() => {
    const search = window.location.search;

    if (search.length > 0) {
      cancelTokenSource.current = axios.CancelToken.source();

      apiService
        .handleAuthenticateStep2(search, cancelTokenSource.current.token)
        .then(() => {
          const authorization = localStorage.getItem(
            localStorageConstants.AUTHORIZATION
          );

          if (authorization) {
            fetchData();
          }
        })
        .catch((e: TApiError) => {
          errorHandler(dispatch, e);
        });
    }

    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  useEffect(() => {
    if (typeof accountsCount === "number") {
      if (accountsCount > 1) {
        navigate(ROUTES.ACCOUNTS);
      } else {
        navigate(ROUTES.HOME);
      }
    }
  }, [accountsCount]);

  return <Container></Container>;
};

export default Blank;
