import React, { useEffect, useRef, useState } from "react";
import apiService from "../../services/api";
import { handleGetDataFromLocalStorage } from "../../utils/miscellaneous";
import { localStorageConstants } from "../../utils/constants";
import { TAccount } from "../../redux/reducers/account";
import axios, { CancelTokenSource } from "axios";
// import styled from "styled-components";

// const Container = styled.div``;

type TInfiniteScrollerVariants = "organizations" | "accounts" | "nonPaginated";

export type InfiniteScrollerProps = {
  children: React.ReactNode[];
  spinnerId: string;
  spinnerRef?: HTMLElement | null;
  onIntersect: (data: any, elementsAmount: number) => void;
  itemsToRenderAmount: number;
  variant: TInfiniteScrollerVariants;
  searchParam?: string;
};

const InfiniteScroller: React.FC<InfiniteScrollerProps> = ({
  children,
  spinnerId,
  spinnerRef,
  onIntersect,
  variant,
  itemsToRenderAmount,
  searchParam,
}) => {
  const intersectionCounterRef = useRef(1);
  const contentToRenderRef = useRef<{ id: string; content: any }[]>([]);
  const elementsAmountRef = useRef(0);
  const lastPageRef = useRef(0);
  const nextPageRef = useRef<string | undefined | null>();

  const didFetchFinishRef = useRef(true);

  const [shouldLoadMore, setShouldLoadMore] = useState(true);

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const handleFetchedData = (response: any) => {
    if (response) {
      elementsAmountRef.current = response.data.count;

      contentToRenderRef.current =
        contentToRenderRef.current.length > 0
          ? [
              ...response.data.results.map((result: any) => ({
                id: result.uuid,
                content: result,
              })),
              ...contentToRenderRef.current.filter(
                (content) =>
                  !response.data.results.some(
                    (result: any) => result.uuid === content.id
                  )
              ),
            ]
          : response.data.results.map((result: any) => ({
              id: result.uuid,
              content: result,
            }));

      if (response.data.pages[intersectionCounterRef.current]) {
        nextPageRef.current = Object.values(
          response.data?.pages[intersectionCounterRef.current++]
        )[0] as string;
        setShouldLoadMore(true);
      } else {
        nextPageRef.current = null;
        setShouldLoadMore(false);
      }
    }
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    switch (variant) {
      case "organizations":
        const localSelectedAccount = handleGetDataFromLocalStorage(
          localStorageConstants.SELECT_ACCOUNT
        ) as TAccount;

        entries.map(async (entry) => {
          if (entry.isIntersecting) {
            if (
              didFetchFinishRef.current &&
              shouldLoadMore &&
              nextPageRef.current === undefined
            ) {
              elementsAmountRef.current = 0;
              didFetchFinishRef.current = false;

              await apiService
                .getOrganizations(
                  localSelectedAccount.uuid,
                  (cancelTokenSource.current as CancelTokenSource).token,
                  intersectionCounterRef.current,
                  searchParam
                )
                .then(handleFetchedData)
                .finally(() => {
                  didFetchFinishRef.current = true;

                  onIntersect(
                    [
                      ...contentToRenderRef.current.map(
                        (content) => content.content
                      ),
                    ],
                    elementsAmountRef.current
                  );
                });
            } else if (
              didFetchFinishRef.current &&
              shouldLoadMore &&
              nextPageRef.current
            ) {
              await apiService
                .getOrganizationsWithBackendUrl(
                  nextPageRef.current as string,
                  (cancelTokenSource.current as CancelTokenSource).token
                )
                .then(handleFetchedData)
                .finally(() => {
                  didFetchFinishRef.current = true;

                  onIntersect(
                    [
                      ...contentToRenderRef.current.map(
                        (content) => content.content
                      ),
                    ],
                    elementsAmountRef.current
                  );
                });
            } else {
              observer.disconnect();
            }
          }
        });
        break;

      case "accounts":
        entries.map(async (entry) => {
          if (entry.isIntersecting) {
            if (
              didFetchFinishRef.current &&
              shouldLoadMore &&
              nextPageRef.current === undefined
            ) {
              elementsAmountRef.current = 0;
              didFetchFinishRef.current = false;

              await apiService
                .getAccounts(
                  (cancelTokenSource.current as CancelTokenSource).token,
                  searchParam,
                  intersectionCounterRef.current
                )
                .then(handleFetchedData)
                .finally(() => {
                  didFetchFinishRef.current = true;

                  onIntersect(
                    [
                      ...contentToRenderRef.current.map(
                        (content) => content.content
                      ),
                    ],
                    elementsAmountRef.current
                  );
                });
            } else if (
              didFetchFinishRef.current &&
              shouldLoadMore &&
              nextPageRef.current
            ) {
              await apiService
                .getAccountsWithBackendUrl(
                  nextPageRef.current as string,
                  (cancelTokenSource.current as CancelTokenSource).token
                )
                .then(handleFetchedData)
                .finally(() => {
                  didFetchFinishRef.current = true;

                  onIntersect(
                    [
                      ...contentToRenderRef.current.map(
                        (content) => content.content
                      ),
                    ],
                    elementsAmountRef.current
                  );
                });
            } else {
              observer.disconnect();
            }
          }
        });
        break;

      case "nonPaginated":
        // console.log("from infinite scroller, searchParam: ", searchParam);
        entries.forEach(async (entry) => {
          if (entry.isIntersecting) {
            if (
              intersectionCounterRef.current <=
              children.length / itemsToRenderAmount
            ) {
              intersectionCounterRef.current += 1;
              new Promise<(number | React.ReactNode[])[]>((resolve) => {
                setTimeout(() => {
                  let result;

                  if (searchParam) {
                    const filtered = children.filter((string) => {
                      return (string as string)
                        .toLowerCase()
                        .includes(searchParam);
                    });
                    result = [
                      filtered.slice(
                        0,
                        itemsToRenderAmount * intersectionCounterRef.current
                      ),
                      filtered.length,
                    ];
                  } else {
                    result = [
                      children.slice(
                        0,
                        itemsToRenderAmount * intersectionCounterRef.current
                      ),
                      children.length,
                    ];
                  }
                  resolve(result);
                }, 10);
              }).then((result) => {
                onIntersect(result[0], result[1] as number);
              });
            } else {
              observer.disconnect();
            }
          }
        });
        break;
    }
  };

  const observer = new IntersectionObserver(handleIntersection, {
    threshold: 0.5,
  });

  const resetVariables = () => {
    // console.log("variables are being reset");
    contentToRenderRef.current = [];
    intersectionCounterRef.current = 1;
    lastPageRef.current = 0;
    nextPageRef.current = undefined;
  };

  useEffect(() => {
    let spinner = document.getElementById(spinnerId) ?? spinnerRef;
    if (variant === "organizations") {
      // console.log(
      //   "variant: ",
      //   variant,
      //   ", spinner id: ",
      //   spinnerId,
      //   ", spinner value: ",
      //   spinner
      // );
    }

    if (spinner) {
      cancelTokenSource.current = axios.CancelToken.source();

      if (variant === "nonPaginated" && children.length > itemsToRenderAmount) {
        observer.observe(spinner);
      } else if (["organizations", "accounts"].includes(variant)) {
        observer.observe(spinner);
      }
    } else {
      setTimeout(() => {
        spinner = document.getElementById(spinnerId) ?? spinnerRef;

        if (variant === "organizations") {
          // console.log(
          //   "from 2nd try, spinner id: ",
          //   spinnerId,
          //   ", spinner value: ",
          //   spinner,
          //   ", variant: ",
          //   variant
          // );
        }
        if (spinner) {
          if (
            variant === "nonPaginated" &&
            children.length > itemsToRenderAmount
          ) {
            observer.observe(spinner);
          } else if (["organizations", "accounts"].includes(variant)) {
            observer.observe(spinner);
          }
          resetVariables();
        }
      }, 50);
    }

    // if (searchParam) searchParamRef.current = searchParam;

    return () => {
      observer.disconnect();
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, [observer]);

  useEffect(() => {
    if (searchParam) {
      setTimeout(() => {
        resetVariables();
      });
    }
  }, [searchParam]);

  return <React.Fragment />;
};

export default InfiniteScroller;
