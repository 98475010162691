import { useTypedSelector } from "./hooks/useTypedSelector.ts";
import Toast from "./components/ui-v2/Toast.tsx";
import { useEffect } from "react";

const AppInterceptor: React.FC = () => {

  const { toast } = useTypedSelector(
    (state) => state.globalState.data
  );


  useEffect(() => {}, [toast]);

  return (
    <>
      {toast.shouldShow && (
        <Toast variant={toast.variant} message={toast.message} />
      )}
    </>
  );
};

export default AppInterceptor;
