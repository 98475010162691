import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import Tab from "../buttons/Tab";
import StringIcon from "../StringIcon";

const Container = styled.section`
  display: flex;
  border-bottom: 1px solid var(--color-light-200);

  margin-top: 21px;

  button {
    margin: 0 8px;
  }

  .box {
    background: var(--color-light-200);
    margin-left: 8px;
    width: 27px;
    min-width: 27px;
    height: 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: var(--color-dark-blue-400);
    }

    &.active {
      background: var(--color-rose-100);

      p {
        color: var(--color-main-rose);
      }
    }
  }
`;

type TProps = {
  children: ReactNode[];
  onClickItems: (() => void)[];
  initialActiveTabIndex?: number;
  stringIcon?: { index: number; value: string }[];
};

const TabsGroup: React.FC<TProps> = ({
  children,
  onClickItems,
  initialActiveTabIndex,
  stringIcon,
}: TProps) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveTabIndex ?? 0);

  useEffect(() => {
    if (initialActiveTabIndex && initialActiveTabIndex !== activeIndex)
      setActiveIndex(initialActiveTabIndex);
  }, [initialActiveTabIndex]);

  return (
    <Container key={`${activeIndex}`}>
      {children.map((child, index) => {
        return (
          <Tab
            key={index}
            onClick={() => {
              setActiveIndex(index);
              onClickItems[index]();
            }}
            active={activeIndex === index}
          >
            {child}

            {stringIcon &&
              stringIcon
                .filter((icon) => icon.index === index)
                .map((icon, key) => (
                  <StringIcon
                    key={key}
                    string={icon.value}
                    className={activeIndex === index ? "box active" : "box"}
                  />
                ))}
          </Tab>
        );
      })}
    </Container>
  );
};

export default TabsGroup;
