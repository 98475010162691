import axios, { AxiosError, CancelToken } from "axios";
import {
  TOrganization,
  TOrganizationData,
} from "../redux/reducers/organizations";
import {
  TMembershipRole,
  TMembershipStatus,
} from "../redux/reducers/memberships";
import {
  handleGetDataFromLocalStorage,
  handleParseQuerystrings,
  handleSetDataToLocalStorage,
} from "../utils/miscellaneous";
import { localStorageConstants } from "../utils/constants";
import { TAccount, TAccountData } from "../redux/reducers/account";
import { buildArrayToQueryStringFields } from "../utils/formatters";
import {
  TInvoice,
  TInvoiceData,
  TInvoiceEntryStatus,
  TInvoiceManifestastionStatus,
  TPaymentSlip,
  TPaymentSlipData,
} from "../redux/reducers/globalState";
import { TPayableObjectForm } from "../components/ui-v2/Modal/Forms/AddPayable";
import { TPayableObjectForm as TPayableObjectForm2 } from "../components/ui-v2/Modal/Forms/AddPaymentSlip";
import { TPayableAccount } from "../components/ui-v2/Modal/Forms/LinkPayable";
import {
  mockInvoiceResponse1,
  mockResponse,
  mockResponse2,
  mockResponse3,
} from "../utils/mocks";
import * as Sentry from "@sentry/react";
import { format } from "date-fns/format";

export type TApiErrorRaw = {
  response: { data: { detail: string }; status: number };
};

export type TApiError = {
  message: { title: string; description: string };
  status: number;
};

const createApiService = () => {
  const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL,
  });

  const INVALIDATE_STATUS_CONDITION = {
    withCredentials: true,
    validateStatus: (status: number) => status < 400,
  };

  axiosInstance.interceptors.request.use(
    (request) => {
      Sentry.setContext("request", {
        url: request.url,
        method: request.method,
      });

      const nonMandatoryAuthenticationRoutes = ["/login/"];
      if (!nonMandatoryAuthenticationRoutes.includes(request.url ?? "")) {
        const authorization = localStorage.getItem("Authorization");
        if (authorization) {
          request.headers["Authorization"] = authorization;
        }
        return request;
      }
      return request;
    },
    (error) => {
      if (!axios.isCancel(error)) {
        Sentry.captureException(error);

        console.error("error from request: ", error);
        return error;
      }
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      Sentry.setContext("response", {
        url: response.config.url,
        method: response.config.method,
      });

      return response;
    },
    (e: AxiosError) => {
      if (!axios.isCancel(e)) {
        const error = e as AxiosError;

        if (error.status && ![400].includes(error.status)) {
          Sentry.captureException(error);
        }

        return Promise.reject({
          message: {
            title: error.response?.statusText,
            description: error.message,
          },
          status: error.response?.status,
          response: { data: error.response?.data },
        });
      }
    }
  );

  const handleAuthenticateStep1 = async (cancelToken: CancelToken) => {
    const url = "/login/";
    return axiosInstance
      .get(url, { ...INVALIDATE_STATUS_CONDITION, cancelToken })
      .then((response) => {
        if (response.data.redirect) {
          window.location.href = response.data.redirect;
        }
        return response;
      })
      .catch((e) => e);
  };

  const handleAuthenticateStep2 = async (
    search: string,
    cancelToken: CancelToken
  ) => {
    const url = "/login/callback/" + search;

    return axiosInstance
      .get(url, { ...INVALIDATE_STATUS_CONDITION, cancelToken })
      .then((response) => {
        if (response.data?.Authorization) {
          const authorization = `${response.data?.Authorization}`;
          localStorage.setItem(
            localStorageConstants.AUTHORIZATION,
            authorization
          );
        }
        return response;
      })
      .catch((e) => {
        throw e;
      });
  };

  const getUserData = async (cancelToken: CancelToken) => {
    const url = "/users/me/";
    const response = await axiosInstance.get(url, { cancelToken });

    handleSetDataToLocalStorage(localStorageConstants.USER_DATA, response.data);

    return response;
  };

  const getAccountsWithBackendUrl = async (
    url: string,
    cancelToken: CancelToken
  ) => {
    const newUrl = new URL(url);
    const response = await axiosInstance.get(newUrl.pathname + newUrl.search, {
      cancelToken,
    });

    const accounts = response.data as TAccountData;

    const localSelectAccount = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ACCOUNT
    );

    if (!localSelectAccount && accounts) {
      if (accounts.results.length === 1) {
        handleSetDataToLocalStorage(
          localStorageConstants.SELECT_ACCOUNT,
          accounts.results[0]
        );
      }
    }

    return response;
  };

  const getAccounts = async (
    cancelToken: CancelToken,
    search?: string,
    page?: number,
    per_page?: number
  ) => {
    const url = "/accounts/";

    const accountsResponse = await axiosInstance.get(url, {
      cancelToken,
      params: {
        page,
        search: search && search.length > 0 ? search : undefined,
        per_page,
      },
    });

    const localSelectAccount = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ACCOUNT
    );

    if (!localSelectAccount && accountsResponse) {
      const accounts = accountsResponse.data as TAccountData;

      if (accounts.results.length === 1) {
        handleSetDataToLocalStorage(
          localStorageConstants.SELECT_ACCOUNT,
          accounts.results[0]
        );
      }
    }

    return accountsResponse;
  };

  const getAccount = async (uuid: string, cancelToken: CancelToken) => {
    const url = "/accounts/" + uuid;
    const response = await axiosInstance.get(url, { cancelToken });
    return response;
  };

  const synchronizeAccount = async (uuid: string, cancelToken: CancelToken) => {
    const url = "/accounts/" + uuid + "/synchronize/";
    const response = await axiosInstance.post(url, { cancelToken });

    return response;
  };

  const getOrganizations = async (
    accountUuid: string,
    cancelToken: CancelToken,
    page?: number,
    search?: string,
    per_page?: number
  ) => {
    const url = "/organizations/?account=" + accountUuid;

    const response = await axiosInstance.get(url, {
      cancelToken,
      params: { page: page, per_page: per_page, search: search },
    });

    handleSetDataToLocalStorage(
      localStorageConstants.ORGANIZATIONS_COUNT,
      (response?.data as TOrganizationData)?.count
    );

    return response;
  };

  const getOrganizationsWithBackendUrl = async (
    url: string,
    cancelToken: CancelToken
  ) => {
    const newUrl = new URL(url);
    const response = await axiosInstance.get(newUrl.pathname + newUrl.search, {
      cancelToken,
    });

    // handleSetDataToLocalStorage(
    //   localStorageConstants.ORGANIZATIONS_COUNT,
    //   (response.data as TOrganizationData).count
    // );

    return response;
  };

  const getOrganizationPermissions = async (
    organizationUuid: string,
    cancelToken: CancelToken
  ) => {
    const url = "organizations/" + organizationUuid + "/permissions/";

    const response = await axiosInstance.get(url, { cancelToken });

    return response;
  };

  const getAllOrganizations = async (
    selectAccount: TAccount,
    cancelToken: CancelToken,
    searchPhrase?: string
  ) => {
    // const selectAccount = handleGetDataFromLocalStorage(
    //   localStorageConstants.SELECT_ACCOUNT
    // ) as TAccount;

    const organizationsResponse = (
      await getOrganizations(
        selectAccount.uuid,
        cancelToken,
        undefined,
        searchPhrase
      )
    ).data;

    let currentPage = organizationsResponse.current_page;

    const orgArray: TOrganization[] = organizationsResponse.results;
    const lastPage = Math.ceil(
      organizationsResponse.count / organizationsResponse.results.length
    );

    while (currentPage++ < lastPage) {
      await apiService
        .getOrganizations(
          selectAccount.uuid,
          cancelToken,
          currentPage,
          searchPhrase
        )
        .then((response: { data: TOrganizationData }) => {
          response.data.results.forEach((value) => {
            orgArray.push(value);
          });
          return response;
        });
    }

    return orgArray;
  };

  const toggleOrganizationVisibility = async (
    organization: TOrganization,
    cancelToken: CancelToken
  ) => {
    const url = `/organizations/${organization.uuid}/visibility/`;

    const response = await axiosInstance.put(url, {
      cancelToken,
      is_active: !organization.is_active,
    });

    return response;
  };

  const filterOrganizationByVisibility = async (
    uuid: string,
    isActive: undefined | boolean,
    cancelToken: CancelToken,
    search?: string
  ) => {
    const url = "/organizations/";

    const response = await axiosInstance.get(url, {
      cancelToken,
      params: { account: uuid, is_active: isActive, search: search },
    });

    return response;
  };

  const getMemberships = async (
    uuid: string,
    cancelToken: CancelToken,
    page?: number,
    role?: (TMembershipRole | undefined)[],
    status?: (TMembershipStatus | undefined)[]
  ) => {
    const queryStrings = handleParseQuerystrings(window.location.search);

    let url = "/memberships/?account=" + uuid;
    if (role && role.length > 0 && !role.includes(undefined))
      url += buildArrayToQueryStringFields(role, "role");
    if (status && status.length > 0 && !status.includes(undefined))
      url += buildArrayToQueryStringFields(status, "status");

    const response = await axiosInstance.get(url, {
      cancelToken,
      params: {
        page: page,
        search: queryStrings.search ? queryStrings.search[0] : undefined,
      },
    });

    return response;
  };

  const createMember = async (
    member: {
      account: string;
      email: string;
      role: TMembershipRole;
      is_active: boolean;
      organizations: string[];
    },
    cancelToken: CancelToken
  ) => {
    const url = "/memberships/";

    const response = await axiosInstance.post(url, member, { cancelToken });

    return response;
  };

  const editMember = async (
    uuid: string,
    member: {
      account: string;
      email: string;
      role: TMembershipRole;
      is_active: boolean;
      organizations: string[];
    },
    cancelToken: CancelToken
  ) => {
    const url = "/memberships/" + uuid + "/";

    const response = await axiosInstance.put(
      url,
      {
        role: member.role,
        is_active: member.is_active,
      },
      { cancelToken }
    );

    return response;
  };

  const deleteMember = async (uuid: string, cancelToken: CancelToken) => {
    const url = "/memberships/" + uuid + "/";

    const response = await axiosInstance.delete(url, {
      params: { account: uuid },
      cancelToken,
    });

    return response;
  };

  const getInvoices = async (
    params: {
      organizationUuid: string;
      manifestation_status?: number;
      launch_status?: number;
      date_issue_before?: string;
      date_issue_after?: string;
      has_accounts_payable?: boolean;
      has_goods_receipt?: boolean;
      page?: number;
      search?: string;
      per_page?: number;
    },
    cancelToken: CancelToken,
    mock?: { type: "resolve"; data: TInvoiceData } | { type: "reject" }
  ) => {
    if (mock) {
      const response = new Promise((resolve, reject) => {
        setTimeout(() => {
          if (mock.type === "resolve") {
            resolve({ data: mock.data });
          } else {
            reject({
              status: 500,
              response: { data: { detail: "error test" } },
            });
          }
        }, 2000);
      });

      return response as PromiseLike<{
        data: TInvoiceData;
      }>;
    } else {
      const url = "/invoices/";

      const response = await axiosInstance.get(url, {
        cancelToken,
        params: {
          organization: params.organizationUuid,
          manifestation: params.manifestation_status,
          launch_status: params.launch_status,
          date_issue_before: "undefined".includes(params.date_issue_after ?? "")
            ? format(new Date(), "yyyy-MM-dd")
            : params.date_issue_after,
          date_issue_after: params.date_issue_before,
          has_goods_receipt: params.has_goods_receipt,
          has_accounts_payable: params.has_accounts_payable,
          page: params.page,
          search: params.search,
          per_page: params.per_page,
        },
      });

      return response;
    }
  };

  const getInvoicesCompressedFilesDownloadLink = async (
    params: {
      option: "xml" | "darf";
      itemsUuid: string[];
      organizationUuid: string;

      manifestation_status?: number;
      launch_status?: number;
      date_issue_before?: string;
      date_issue_after?: string;
      has_accounts_payable?: boolean;
      has_goods_receipt?: boolean;
      page?: number;
      search?: string;
      per_page?: number;
    },
    cancelToken: CancelToken
  ) => {
    const route = "/invoices/download/" + params.option + "/";

    const response = await axiosInstance.get(route, {
      cancelToken,
      params: {
        organization: params.organizationUuid,
        manifestation: params.manifestation_status,
        launch_status: params.launch_status,
        date_issue_before: params.date_issue_after,
        date_issue_after: params.date_issue_before,
        has_goods_receipt: params.has_goods_receipt,
        has_accounts_payable: params.has_accounts_payable,
        page: params.page,
        search: params.search,
        per_page: params.per_page,
        uuid: params.itemsUuid.toString(),
      },
    });

    return response;
  };

  const getAllInvoices = async (
    params: {
      organizationUuid: string;
      manifestation_status?: number;
      launch_status?: number;
      date_issue_before?: string;
      date_issue_after?: string;
      has_accounts_payable?: boolean;
      has_goods_receipt?: boolean;
      page?: number;
      per_page?: number;
      search?: string;
    },
    cancelToken: CancelToken
  ) => {
    const url = "/invoices/";

    const invoicesResponse = (
      await axiosInstance.get(url, {
        cancelToken,
        params: {
          organization: params.organizationUuid,
          manifestation: params.manifestation_status,
          launch_status: params.launch_status,
          date_issue_before: params.date_issue_after,
          date_issue_after: params.date_issue_before,
          has_goods_receipt: params.has_goods_receipt,
          has_accounts_payable: params.has_accounts_payable,
          page: params.page,
          per_page: params.per_page,
          search: params.search,
        },
      })
    ).data as TInvoiceData;

    let currentPage = invoicesResponse.current_page;

    const invoicesArray: TInvoice[] = invoicesResponse.results;
    const lastPage = Math.ceil(
      invoicesResponse.count / invoicesResponse.results.length
    );

    while (currentPage++ < lastPage) {
      await apiService
        .getInvoices({ ...params, page: currentPage }, cancelToken)
        .then((response: { data: TInvoiceData }) => {
          response.data.results.forEach((value) => {
            invoicesArray.push(value);
          });
          return response;
        });
    }

    return invoicesArray;
  };

  const getInvoice = async (
    invoiceId: string,
    cancelToken: CancelToken,
    mock?: { type: "resolve"; nexaasState: number } | { type: "reject" }
  ) => {
    if (mock) {
      const response = new Promise((resolve, reject) => {
        setTimeout(() => {
          if (mock.type === "resolve") {
            // console.log("from api, mock nexaas state: ", mock.nexaasState);
            resolve({
              data: {
                ...mockInvoiceResponse1.results[0],
                goods_receipt_url: "https://google.com.br",
                nexaas_state: mock.nexaasState,
                nexaas_finished_at:
                  mock.nexaasState == 60 ? "2019-08-24T14:15:22Z" : null,
              },
            });
          } else {
            reject({
              status: 500,
              response: { data: { detail: "error test" } },
            });
          }
        }, 2000);
      });

      return response;
    } else {
      const url = "/invoices/" + invoiceId + "/";

      const response = await axiosInstance.get(url, { cancelToken });

      return response;
    }
  };

  const getInvoiceUpdatedAt = async (
    organizationUuid: string,
    cancelToken: CancelToken
  ) => {
    const url = "/invoices/updated-at/";

    const response = await axiosInstance.get(url, {
      params: { organization: organizationUuid },
      cancelToken,
    });

    return response;
  };

  const updateInvoiceManifestation = async (
    uuid: string,
    manifestation: TInvoiceManifestastionStatus,
    cancelToken: CancelToken,
    justification?: string
  ) => {
    const url = "/invoices/" + uuid + "/manifestation/";

    const response = await axiosInstance.put(
      url,
      {
        manifestation,
        justification,
      },
      { cancelToken }
    );

    return response;
  };

  const updateInvoiceMarkAsFinished = async (
    uuid: string,
    cancelToken: CancelToken
  ) => {
    const url = "/invoices/" + uuid + "/finalize-launch/";

    const response = await axiosInstance.put(url, { cancelToken });

    return response;
  };

  const updateInvoiceMarkAsPending = async (
    uuid: string,
    cancelToken: CancelToken
  ) => {
    const url = "/invoices/" + uuid + "/hang-launch/";

    const response = await axiosInstance.put(url, { cancelToken });

    return response;
  };

  const getPaymentSlips = async (
    params: {
      organizationUuid: string;
      due_date_after?: string;
      due_date_before?: string;
      launch_status?: TInvoiceEntryStatus;
      has_accounts_payable?: boolean;
      has_goods_receipt?: boolean;
      page?: number;
      search?: string;
      per_page?: number;
    },
    cancelToken: CancelToken
  ) => {
    const url = "/boletos/";
    const temp = { ...params } as any;

    if ("undefined".includes(temp.due_date_after)) {
      temp.due_date_after = undefined;
    }

    if ("undefined".includes(temp.due_date_before)) {
      temp.due_date_before = undefined;
    }

    temp.organizationUuid = undefined;
    temp.organization = params.organizationUuid;

    const response = await axiosInstance.get(url, {
      params: {
        ...temp,
      },
      cancelToken,
    });

    return response;
  };

  const getInvoiceCounters = async (
    params: {
      organizationUuid: string;

      manifestation_status?: number;
      launch_status?: number;
      date_issue_before?: string;
      date_issue_after?: string;
      has_accounts_payable?: boolean;
      has_goods_receipt?: boolean;
      page?: number;
      per_page?: number;
      search?: string;
    },
    cancelToken: CancelToken
  ) => {
    const url = "/invoices/count/";

    const temp = { ...params } as any;

    if ("undefined".includes(temp.date_issue_before)) {
      temp.date_issue_before = format(new Date(), "yyyy-MM-dd");
    }

    temp.organizationUuid = undefined;
    temp.organization = params.organizationUuid;

    const response = await axiosInstance.get(url, {
      params: {
        ...temp,
      },
      cancelToken,
    });

    return response;
  };

  const getPaymentSlipCounters = async (
    params: {
      organizationUuid: string;

      due_date_after?: string;
      due_date_before?: string;
      has_accounts_payable?: boolean;
      has_goods_receipt?: boolean;
      page?: number;
      search?: string;
      per_page?: number;
    },
    cancelToken: CancelToken
  ) => {
    const url = "/boletos/count/";

    const temp = { ...params } as any;

    if ("undefined".includes(temp.due_date_after)) {
      temp.due_date_after = undefined;
    }

    if ("undefined".includes(temp.due_date_before)) {
      temp.due_date_before = undefined;
    }

    temp.organizationUuid = undefined;
    temp.organization = params.organizationUuid;

    const response = await axiosInstance.get(url, {
      params: {
        ...temp,
      },
      cancelToken,
    });

    return response;
  };

  const getPaymentSlipsCompressedFilesDownloadLink = async (
    params: {
      itemsUuid: string[];
      organizationUuid: string;

      manifestation_status?: number;
      launch_status?: number;
      date_issue_before?: string;
      date_issue_after?: string;
      has_accounts_payable?: boolean;
      has_goods_receipt?: boolean;
      page?: number;
      search?: string;
      per_page?: number;
    },
    cancelToken: CancelToken
  ) => {
    const route = "/boletos/download/";

    const response = await axiosInstance.get(route, {
      cancelToken,
      params: {
        organization: params.organizationUuid,
        manifestation: params.manifestation_status,
        launch_status: params.launch_status,
        date_issue_before: params.date_issue_after,
        date_issue_after: params.date_issue_before,
        has_goods_receipt: params.has_goods_receipt,
        has_accounts_payable: params.has_accounts_payable,
        page: params.page,
        search: params.search,
        per_page: params.per_page,
        uuid: params.itemsUuid.toString(),
      },
    });

    return response;
  };

  const getAllPaymentSlips = async (
    params: {
      organizationUuid: string;
      due_date_after?: string;
      due_date_before?: string;
      launch_status?: TInvoiceEntryStatus;
      has_accounts_payable?: boolean;
      has_goods_receipt?: boolean;
      page?: number;
      search?: string;
      per_page?: number;
    },
    cancelToken: CancelToken
  ) => {
    const url = "/boletos/";

    const temp = { ...params } as any;

    if ("undefined".includes(temp.due_date_after)) {
      temp.due_date_after = undefined;
    }

    if ("undefined".includes(temp.due_date_before)) {
      temp.due_date_before = undefined;
    }

    temp.organizationUuid = undefined;
    temp.organization = params.organizationUuid;

    const paymentSlipsResponse = (
      await axiosInstance.get(url, {
        params: {
          ...temp,
        },
        cancelToken,
      })
    )?.data as TPaymentSlipData;

    if (paymentSlipsResponse) {
      let currentPage = paymentSlipsResponse.current_page;

      const paymentSlipsArray: TPaymentSlip[] = paymentSlipsResponse.results;
      const lastPage = Math.ceil(
        paymentSlipsResponse.count / paymentSlipsResponse.results.length
      );

      while (currentPage++ < lastPage) {
        await apiService
          .getPaymentSlips({ ...params, page: currentPage }, cancelToken)
          .then((response: { data: TPaymentSlipData }) => {
            response.data.results.forEach((value) => {
              paymentSlipsArray.push(value);
            });
            return response;
          });
      }
      return paymentSlipsArray;
    }
    return [];
  };

  const getPaymentSlip = async (uuid: string, cancelToken: CancelToken) => {
    const url = "/boletos/" + uuid + "/";

    const response = await axiosInstance.get(url, { cancelToken });

    return response;
  };

  const getPaymentSlipAttachedInvoices = async (
    uuid: string,
    cancelToken: CancelToken
  ) => {
    const url = "/boletos/" + uuid + "/invoices/";

    const response = await axiosInstance.get(url, { cancelToken });

    return response;
  };

  const getInvoiceAttachedPaymentSlips = async (
    uuid: string,
    cancelToken: CancelToken
  ) => {
    const url = "/invoices/" + uuid + "/boletos/";

    const response = await axiosInstance.get(url, { cancelToken });

    return response;
  };

  const getPaymentSlipUpdatedAt = async (
    organizationUuid: string,
    cancelToken: CancelToken
  ) => {
    const url = "/boletos/updated-at/";

    const response = await axiosInstance.get(url, {
      cancelToken,
      params: {
        organization: organizationUuid,
      },
    });

    return response;
  };

  const deletePaymentSlip = async (uuid: string, cancelToken: CancelToken) => {
    const url = "/boletos/" + uuid + "/";

    const response = await axiosInstance.delete(url, { cancelToken });

    return response;
  };

  const updatePaymentSlipLaunchStatus = async (
    uuid: string,
    action: "decline" | "hang" | "finalize",
    cancelToken: CancelToken
  ) => {
    const url = "/boletos/" + uuid + "/" + action + "-launch/";

    const response = await axiosInstance.put(url, { cancelToken });

    return response;
  };

  const invoiceImportFiles = async (
    organizationUuid: string,
    formData: FormData,
    fileType: "danfe" | "xml",
    cancelToken: CancelToken
  ) => {
    const url =
      `/invoices/import-${fileType}/?organization=` + organizationUuid;

    const response = await axiosInstance.post(url, formData, { cancelToken });

    return response;
  };

  const paymentSlipImportPDF = async (
    organizationUuid: string,
    formData: FormData,
    cancelToken: CancelToken
  ) => {
    const url = "/boletos/import/?organization=" + organizationUuid;

    const response = await axiosInstance.post(url, formData, { cancelToken });

    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000);

    return response;
  };

  const detachInvoiceFromPaymentSlip = async (
    paymentSlipUuid: string,
    invoiceUuid: string,
    cancelToken: CancelToken
  ) => {
    const url = "/boletos/" + paymentSlipUuid + "/detach-invoice/";

    const response = await axiosInstance.put(
      url,
      { invoice: invoiceUuid },
      { cancelToken }
    );

    return response;
  };

  const attachInvoiceToPaymentSlip = async (
    paymentSlipUuid: string,
    invoiceUuid: string,
    cancelToken: CancelToken
  ) => {
    const url = "/boletos/" + paymentSlipUuid + "/attach-invoice/";

    const response = await axiosInstance.put(
      url,
      { invoice: invoiceUuid },
      { cancelToken }
    );

    return response;
  };

  const detachPaymentSlipFromInvoice = async (
    invoiceUuid: string,
    paymentSlipUuid: string,
    cancelToken: CancelToken
  ) => {
    const url = "/invoices/" + invoiceUuid + "/detach-boleto/";

    const response = await axiosInstance.put(
      url,
      { boleto: paymentSlipUuid },
      { cancelToken }
    );

    return response;
  };

  const attachPaymentSlipToInvoice = async (
    invoiceUuid: string,
    paymentSlipUuid: string,
    cancelToken: CancelToken
  ) => {
    const url = "/invoices/" + invoiceUuid + "/attach-boleto/";

    const response = await axiosInstance.put(
      url,
      { boleto: paymentSlipUuid },
      { cancelToken }
    );

    return response;
  };

  const getCategories = async (cancelToken: CancelToken) => {
    const { uuid: organizationId } = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ORGANIZATION
    ) as TOrganization;
    const url = "/fintera/" + organizationId + "/categories/";

    const response = await axiosInstance.get(url, { cancelToken });

    return response;
  };

  const getClassificationCenters = async (cancelToken: CancelToken) => {
    const { uuid: organizationId } = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ORGANIZATION
    ) as TOrganization;
    const url = "/fintera/" + organizationId + "/classification-centers/";

    const response = await axiosInstance.get(url, { cancelToken });

    return response;
  };

  const getSuppliers = async (cancelToken: CancelToken) => {
    const { uuid: organizationId } = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ORGANIZATION
    ) as TOrganization;
    const url = "/fintera/" + organizationId + "/suppliers/";

    const response = await axiosInstance.get(url, { cancelToken });

    return response;
  };

  const getInvoiceSupplier = async (
    organizationId: string,
    invoiceId: string,
    cancelToken: CancelToken
  ) => {
    const url =
      "/fintera/" + organizationId + "/invoice/" + invoiceId + "/supplier/";
    const response = await axiosInstance.get(url, { cancelToken });

    return response;
  };

  const getDepositAccounts = async (cancelToken: CancelToken) => {
    const { uuid: organizationId } = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ORGANIZATION
    ) as TOrganization;
    const url = "/fintera/" + organizationId + "/deposit-accounts/";

    const response = await axiosInstance.get(url, { cancelToken });

    return response;
  };

  const createInCashPayable = async (
    form: TPayableObjectForm | TPayableObjectForm2,
    cancelToken: CancelToken
  ) => {
    const { uuid: organizationId } = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ORGANIZATION
    ) as TOrganization;
    const url = "/fintera/" + organizationId + "/payable-accounts/in-cash/";

    const response = await axiosInstance.post(url, form, { cancelToken });

    return response;
  };

  const createRecurringPayable = async (
    form: TPayableObjectForm | TPayableObjectForm2,
    cancelToken: CancelToken
  ) => {
    const { uuid: organizationId } = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ORGANIZATION
    ) as TOrganization;
    const url = "/fintera/" + organizationId + "/payable-accounts/recurrent/";

    const response = await axiosInstance.post(url, form, { cancelToken });

    return response;
  };

  const createInstallmentsPayable = async (
    form: TPayableObjectForm | TPayableObjectForm2,
    cancelToken: CancelToken
  ) => {
    const { uuid: organizationId } = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ORGANIZATION
    ) as TOrganization;
    const url =
      "/fintera/" + organizationId + "/payable-accounts/installments/";

    const response = await axiosInstance.post(url, form, { cancelToken });

    return response;
  };

  const getPayableAccounts = async (
    cancelToken: CancelToken,
    params?: {
      amount?: string;
      category?: string;
      description?: string;
      due_date?: string;
      paid_amount?: string;
      payment_date?: string;
      supplier_name?: string;
      page?: number;
      per_page?: number;
    }
  ) => {
    const { uuid: organizationId } = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ORGANIZATION
    ) as TOrganization;
    const url = "/fintera/" + organizationId + "/payable-accounts/";

    const response = await axiosInstance.get(url, {
      params: { ...params },
      cancelToken,
    });

    return response;
  };

  const getAllPayableAccounts = async (
    cancelToken: CancelToken,
    params?: {
      amount?: string;
      category?: string;
      description?: string;
      due_date?: string;
      paid_amount?: string;
      payment_date?: string;
      supplier_name?: string;
      page?: number;
    }
  ) => {
    const payableAccountsResponse = (
      await getPayableAccounts(cancelToken, params)
    ).data;

    let currentPage = payableAccountsResponse.current_page;

    const payableArray: TPayableAccount[] = payableAccountsResponse.results;
    const lastPage = Math.ceil(
      payableAccountsResponse.pages.length /
        payableAccountsResponse.results.length
    );

    while (currentPage++ < lastPage) {
      await getPayableAccounts(cancelToken, { page: currentPage++ }).then(
        (response: { data: { results: TPayableAccount[] } }) => {
          response.data.results.forEach((value) => {
            payableArray.push(value);
          });
          return response;
        }
      );
    }

    return payableArray;
  };

  const setLinkInvoiceToPayable = async (
    invoiceId: string,
    documentToLinkId: string,
    cancelToken: CancelToken
  ) => {
    const url = "/invoices/" + invoiceId + "/attach-bill/";

    const response = await axiosInstance.put(
      url,
      {
        bill_id: documentToLinkId,
      },
      { cancelToken }
    );

    return response;
  };

  const setLinkPaymentSlipToPayable = async (
    paymentSlipId: string,
    documentToLinkId: string,
    cancelToken: CancelToken
  ) => {
    const url = "/boletos/" + paymentSlipId + "/attach-bill/";

    const response = await axiosInstance.put(
      url,
      {
        bill_id: documentToLinkId,
      },
      { cancelToken }
    );

    return response;
  };

  const getGoodsStock = async (
    organizationId: string,
    cancelToken: CancelToken
  ) => {
    const url = "/nexaas/" + organizationId + "/stocks/";

    const response = await axiosInstance.get(url, { cancelToken });

    return response;
  };

  const getCachedGoodsExpressModeStatus = async (
    organizationId: string,
    invoiceId: string,
    cancelToken: CancelToken
  ) => {
    const url =
      "/nexaas/" +
      organizationId +
      "/stocks/invoices/" +
      invoiceId +
      "/verify/";

    const response = await axiosInstance.get(url, { cancelToken });

    return response;
  };

  const getNonCachedGoodsExpressModeStatus = async (
    organizationId: string,
    invoiceId: string,
    cancelToken: CancelToken,
    mock?:
      | { type: "resolve"; model: "mock1" | "mock2" | "mock3" }
      | { type: "reject" }
  ) => {
    if (mock) {
      const response = new Promise((resolve, reject) => {
        setTimeout(() => {
          if (cancelToken.reason) {
            // console.log("from api, cancelling: ", cancelToken.reason.message);
            reject(new axios.Cancel("Request canceled by user"));
          }
          if (mock.type === "resolve") {
            switch (mock.model) {
              case "mock1":
                resolve({ data: mockResponse });
                break;
              case "mock2":
                resolve({ data: mockResponse2 });
                break;
              case "mock3":
                resolve({ data: mockResponse3 });
                break;
            }
          } else {
            reject({
              response: { data: { detail: "error test" } },
              status: 500,
            });
          }
        }, 5000);
      });

      return response;
    } else {
      const url =
        "/nexaas/" +
        organizationId +
        "/stocks/invoices/" +
        invoiceId +
        "/verify/";

      const response = await axiosInstance.post(url, { cancelToken });

      return response;
    }
  };

  const setGoods = async (
    organizationId: string,
    stockId: number,
    invoiceId: string,
    expressMode: boolean,
    cancelToken: CancelToken,
    mock?: "resolve" | "reject"
  ) => {
    if (mock) {
      const response = new Promise((resolve, reject) => {
        setTimeout(() => {
          if (mock === "resolve") {
            resolve({ data: { status: 201 } });
          } else {
            reject({
              status: 500,
              response: { data: { detail: "error test" } },
            });
          }
        }, 2000);
      });

      return response;
    } else {
      const url = "/nexaas/" + organizationId + "/stocks/invoices/";

      const response = await axiosInstance.post(
        url,
        {
          stock_id: stockId,
          invoice_id: invoiceId,
          express_mode: expressMode,
        },
        { cancelToken }
      );

      return response;
    }
  };

  const updateDefaultGoodsStockId = async (
    organizationId: string,
    stockId: number,
    cancelToken: CancelToken
  ) => {
    const url = "/organizations/" + organizationId + "/nexaas/stock/";

    const response = await axiosInstance.put(
      url,
      {
        default_nexaas_stock_id: stockId,
      },
      { cancelToken }
    );

    return response;
  };

  const createExpressGoodsStockEntry = async (
    organizationId: string,
    invoiceId: string,
    cancelToken: CancelToken,
    mock?: { type: "resolve" } | { type: "reject" }
  ) => {
    if (mock) {
      const response = new Promise((resolve, reject) => {
        setTimeout(() => {
          if (mock.type === "resolve") {
            resolve({});
          } else {
            reject({
              status: 500,
              response: { data: { detail: "error test" } },
            });
          }
        }, 3000);
      });

      return response;
    } else {
      const url =
        "/nexaas/" +
        organizationId +
        "/stocks/invoices/" +
        invoiceId +
        "/launch/";

      const response = await axiosInstance.post(url, { cancelToken });

      return response;
    }
  };

  const getOrganizationsByMembership = async (
    membershipUuid: string,
    cancelToken: CancelToken
  ) => {
    const route = "/memberships/" + membershipUuid + "/list-organizations/";

    const response = await axiosInstance.get(route, { cancelToken });

    return response;
  };

  const setOrganizationsByMembership = async (
    membershipUuid: string,
    organizationsUuid: string[],
    cancelToken: CancelToken
  ) => {
    const route = "/memberships/" + membershipUuid + "/organizations/";

    const response = await axiosInstance.put(
      route,
      {
        organizations: organizationsUuid,
      },
      { cancelToken }
    );

    return response;
  };

  const renamePaymentSlipLabel = async (
    paymentSlipUuid: string,
    label: string,
    cancelToken: CancelToken
  ) => {
    const route = "/boletos/" + paymentSlipUuid + "/edit-label/";

    const response = await axiosInstance.put(route, { label }, { cancelToken });

    return response;
  };

  const renameInvoiceLabel = async (
    invoiceUuid: string,
    label: string,
    cancelToken: CancelToken
  ) => {
    const route = "/invoices/" + invoiceUuid + "/edit-label/";

    const response = await axiosInstance.put(route, { label }, { cancelToken });

    return response;
  };

  return {
    handleAuthenticateStep1,
    handleAuthenticateStep2,
    getUserData,
    getAccountsWithBackendUrl,
    getAccounts,
    getAccount,
    synchronizeAccount,
    getMemberships,
    createMember,
    editMember,
    deleteMember,
    getOrganizations,
    getOrganizationsWithBackendUrl,
    getOrganizationPermissions,
    getAllOrganizations,
    toggleOrganizationVisibility,
    filterOrganizationByVisibility,
    getInvoices,
    getInvoicesCompressedFilesDownloadLink,
    getPaymentSlipsCompressedFilesDownloadLink,
    getInvoiceCounters,
    getPaymentSlipCounters,
    getAllInvoices,
    getInvoice,
    updateInvoiceManifestation,
    updateInvoiceMarkAsFinished,
    updateInvoiceMarkAsPending,
    getPaymentSlips,
    getPaymentSlip,
    getPaymentSlipAttachedInvoices,
    getInvoiceAttachedPaymentSlips,
    getAllPaymentSlips,
    deletePaymentSlip,
    updatePaymentSlipLaunchStatus,
    invoiceImportFiles,
    paymentSlipImportPDF,
    detachInvoiceFromPaymentSlip,
    attachInvoiceToPaymentSlip,
    detachPaymentSlipFromInvoice,
    attachPaymentSlipToInvoice,
    getCategories,
    getClassificationCenters,
    getSuppliers,
    getInvoiceSupplier,
    getDepositAccounts,
    createInCashPayable,
    createInstallmentsPayable,
    createRecurringPayable,
    getPayableAccounts,
    getAllPayableAccounts,
    setLinkInvoiceToPayable,
    setLinkPaymentSlipToPayable,
    getGoodsStock,
    getCachedGoodsExpressModeStatus,
    getNonCachedGoodsExpressModeStatus,
    createExpressGoodsStockEntry,
    setGoods,
    updateDefaultGoodsStockId,
    getInvoiceUpdatedAt,
    getPaymentSlipUpdatedAt,
    getOrganizationsByMembership,
    setOrganizationsByMembership,
    renamePaymentSlipLabel,
    renameInvoiceLabel,
  };
};

const apiService = createApiService();

export type TApiService = typeof apiService;

export default apiService;
