import styled from "styled-components";
import { UilAngleDown /* UilCalender */ } from "@iconscout/react-unicons";
import { useCallback, useId, useState } from "react";
import { TIconVariants } from ".";
import useDetectOutsideClick from "../../../hooks/useDetectOutsideClick";
// import { AppContext } from "../../../contexts/AppContext";

const Container = styled.button<{
  placeholder: string;
  $variant: TIconVariants;
  content: string;
  $isFiltered: "filter" | "nofilter";
}>`
  position: relative;

  cursor: pointer;

  height: 36px;

  ${({ $variant }) => $variant === "calendar" && "width: max-content"};

  background: var(--color-light-0);

  border-radius: 20px;
  border: 1px solid;
  border-color: ${({ content, $isFiltered }) =>
    content.length > 0 && content !== "0" && $isFiltered === "filter"
      ? `var(--color-main-blue)`
      : `var(--color-dark-blue-300)`};

  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  &::before {
    ${({ $variant }) => {
      switch ($variant) {
        case "calendar":
          return 'content:""; font-size: 18px';
        case "checkCircle":
          return 'content:""; font-size: 18px';
        case "eye":
          return 'content: ""; font-size: 18px;';
      }
    }};

    color: ${({ content, $isFiltered }) =>
      content.length > 0 && content !== "0" && $isFiltered === "filter"
        ? `var(--color-main-blue)`
        : `var(--color-text-labels)`};
    font-family: unicons;

    position: absolute;
    left: 16px;
    top: 0;

    height: 100%;

    display: flex;
    align-items: center;
  }

  &::after {
    content: "${({ placeholder }) => placeholder}";
    visibility: hidden;
    height: 10px;
    // padding: 0 20px;
    padding: 0
      ${({ content, $isFiltered, $variant, placeholder }) =>
        content.length > 0 &&
        content !== "0" &&
        $isFiltered === "filter" &&
        $variant !== "calendar"
          ? `40`
          : placeholder.includes("/")
          ? `33`
          : `30.5`}px;
    font-size: 14px;
    display: block;
    font-family: var(--font-paragraph-default);
  }

  p.placeholder {
    height: 100%;
    top: 0;

    display: flex;
    align-items: center;

    font-family: var(--font-paragraph-bold);
    color: ${({ content, $isFiltered }) =>
      content.length > 0 && content !== "0" && $isFiltered === "filter"
        ? `var(--color-main-blue)`
        : `var(--color-text-paragraph)`};
    font-size: 14px;

    position: absolute;
    left: 37px;
  }

  svg {
    position: absolute;
    right: 10px;
    top: 6px;
    color: ${({ content, $isFiltered }) =>
      content.length > 0 && content !== "0" && $isFiltered === "filter"
        ? `var(--color-main-blue)`
        : `var(--color-text-labels)`};

    transform: rotateZ(0deg);

    transition: transform 0.25s ease-in-out;
  }

  &:hover {
    background: var(--color-light-0);
  }

  &.active,
  &:active {
    border-color: var(--color-main-blue);

    svg {
      transform: rotateZ(180deg);
    }
  }
`;

const NumSelectedContainer = styled.div<{
  content: string;
  $isFiltered: "filter" | "nofilter";
}>`
  position: absolute;
  top: 0;
  right: 34px;
  height: 100%;

  display: flex;
  align-items: center;

  p {
    background: var(--color-main-blue);
    color: var(--color-light-0);
    font-size: 10px;
    font-family: var(--font-paragraph-bold);
    padding: 1px 4px;
    border-radius: 4px;
    visibility: ${({ content, $isFiltered }) =>
      content.length > 0 && content !== "0" && $isFiltered === "filter"
        ? "visible"
        : "hidden"};
  }
`;

type TButtonProps = {
  variant: TIconVariants;
  content: string;
  placeholder: string;
  onClick?: (
    setClickState: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  onBlur?: (/* e: React.FocusEvent<HTMLButtonElement, Element> */) =>
    | "show"
    | "hide";
  isFiltered: boolean;
  $ref?: React.MutableRefObject<HTMLButtonElement | null>;
  componentRef: React.MutableRefObject<HTMLDivElement | null>;
};

const Button: React.FC<TButtonProps> = ({
  variant,
  placeholder,
  content,
  onClick,
  onBlur,
  isFiltered,
  $ref,
  componentRef,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleOnClickButton = () => {
    setIsClicked(!isClicked);
    if (onClick) onClick(setIsClicked);
  };

  // console.log("is clicked has been updated: ", isClicked);

  useDetectOutsideClick({
    isClicked: isClicked,
    componentRef,
    onOutside() {
      setTimeout(() => {
        if (onBlur && onBlur() === "hide") {
          setIsClicked(false);
        }
      }, 30);
    },
  });

  const handleRenderButton = useCallback(() => {
    const buttonId = useId();

    // const handleOnBlur = (e: React.FocusEvent<HTMLButtonElement, Element>) => {
    //   if (variant === "calendar") {
    //     setTimeout(() => {
    //       if (onBlur && onBlur(e) === "hide") {
    //         setIsClicked(false);
    //       }
    //     }, 10);
    //   } else {
    //     if (onBlur && onBlur(e) === "hide") {
    //       setIsClicked(false);
    //     }
    //   }
    // };
    return (
      <Container
        id={buttonId}
        $variant={variant}
        role="button"
        // onBlur={handleOnBlur}
        onClick={handleOnClickButton}
        className={isClicked ? "active" : ""}
        placeholder={placeholder}
        $isFiltered={isFiltered ? "filter" : "nofilter"}
        content={content}
        ref={$ref}
      >
        <p className="placeholder">{placeholder}</p>
        {variant !== "calendar" && (
          <NumSelectedContainer
            $isFiltered={isFiltered ? "filter" : "nofilter"}
            content={content}
          >
            {isFiltered && <p>{content}</p>}
          </NumSelectedContainer>
        )}
        <UilAngleDown />
      </Container>
    );
  }, [isFiltered, content, isClicked]);

  return handleRenderButton();
};

export default Button;
