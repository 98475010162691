import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { TContent } from ".";
import {
  UilCheckCircle,
  UilDollarSign,
  UilEnter,
  UilImport,
  UilPen,
  UilTrash,
} from "@iconscout/react-unicons";
import Dropdown from "../Dropdown";
import Button from "../Button";
import {
  backendErrorMessageHandler,
  getQuerystringParams,
  handleGetDataFromLocalStorage,
} from "../../../utils/miscellaneous";
import { localStorageConstants } from "../../../utils/constants";
import {
  TInvoice,
  TInvoiceEntryStatus,
  TInvoiceManifestastionStatus,
  setToastState,
} from "../../../redux/reducers/globalState";
import apiService from "../../../services/api";
import { useTypedDispatch } from "../../../hooks/useTypedDispatch";
import { fetchInvoices } from "../../../redux/actions";
import axios, { CancelTokenSource } from "axios";

const Container = styled.menu<{ $shouldshow: "show" | "hide" }>`
  width: 248px;
  padding: 16px;

  margin: 0;

  position: absolute;
  z-index: ${({ $shouldshow }) => ($shouldshow === "show" ? "2" : "-1")};
  transition: z-index 0.15s ease-in-out;

  background: transparent;

  & button:nth-child(1),
  & p.optGroup {
    visibility: ${({ $shouldshow }) =>
      $shouldshow === "show" ? "visible" : "hidden"};
    transition: visibility 0.1s ease-in-out;
  }

  & .optGroupContainer {
    position: relative;
    height: 31px;
  }

  & p.optGroup {
    position: absolute;
    z-index: 1;

    top: 25%;
    left: 12px;

    font-family: var(--font-paragraph-default);
    font-size: 12px;
    color: var(--color-dark-blue-400);
  }

  &::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    height: ${({ $shouldshow }) => ($shouldshow === "show" ? "100%" : "0%")};
    width: 100%;

    background: var(--color-light-0);
    border-radius: 8px;
    border: 1px solid var(--color-dark-blue-300);
    border-width: ${({ $shouldshow }) =>
      $shouldshow === "show" ? "1px" : "0px"};
    transition: height 0.2s ease-in-out;
  }
`;

const ItemButton = styled.button<{
  $label?: string;
  $shouldshow: "show" | "hide";
}>`
  position: relative;

  cursor: pointer;

  width: 100%;
  padding: 8px 0;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  background: var(--color-light-0);

  border-radius: 8px;

  visibility: ${({ $shouldshow }) =>
    $shouldshow === "show" ? "visible" : "hidden"};
  transition: visibility 0.1s ease-in-out;

  p {
    font-family: var(--font-paragraph-default);
    color: var(--color-text-paragraph);
    font-size: 14px;
    text-align: left;
    margin-left: 8px;

    display: flex;
    align-items: center;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  &:hover {
    background: var(--color-light-200);
  }
`;

export const NextContainer = styled.menu<{ $shouldshow: "show" | "hide" }>`
  width: 400px;
  padding: 16px;

  margin: 0;

  position: absolute;
  z-index: ${({ $shouldshow }) => ($shouldshow === "show" ? "2" : "-1")};
  transition: z-index 0.15s ease-in-out;

  background: transparent;

  & button:nth-child(1),
  & p.optGroup {
    visibility: ${({ $shouldshow }) =>
      $shouldshow === "show" ? "visible" : "hidden"};
    transition: visibility 0.1s ease-in-out;
  }

  & .optGroupContainer {
    position: relative;
    height: 31px;
  }

  & p.optGroup {
    position: absolute;
    z-index: 1;

    top: 25%;
    left: 12px;

    font-family: var(--font-paragraph-default);
    font-size: 12px;
    color: var(--color-dark-blue-400);
  }

  & > h2 {
    position: relative;
    font-family: var(--font-paragraph-bold);
    font-size: 14px;
    color: var(--color-text-paragraph);
    margin-bottom: 12px;
  }

  & > div {
    width: 100%;
  }

  &::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    height: ${({ $shouldshow }) => ($shouldshow === "show" ? "100%" : "0%")};
    width: 100%;

    background: var(--color-light-0);
    border-radius: 8px;
    border: 1px solid var(--color-dark-blue-300);
    border-width: ${({ $shouldshow }) =>
      $shouldshow === "show" ? "1px" : "0px"};
    transition: height 0.2s ease-in-out;
  }

  & > div > div > button p.content {
    top: 13px;
  }
`;
export const FilterArea = styled.div`
  position: relative;

  border-top: 1px solid var(--color-light-200);
  margin-top: 12px;

  .buttonsArea {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: end;

    width: 100%;

    & > *:last-child {
      margin-left: 8px;
    }
  }
`;

export const TextArea = styled.textarea`
  padding-top: 18px;
  padding-left: 12px;

  position: relative;
  height: 67px;
  width: 378px;

  font-size: 14px;
  font-family: var(--font-paragraph-default);
  color: var(--color-text-paragraph);

  border-radius: 8px;
  border: none;

  outline: none;
  resize: none;
`;

export const TextAreaContainer = styled.div<{
  $placeholder: string;
  $justificationText?: string;
}>`
  position: relative;

  cursor: text;

  margin-top: 12px;
  margin-bottom: 30px;

  height: 92px;
  width: 390px;

  border-radius: 8px;
  border: 1px solid var(--color-dark-blue-300);

  box-shadow: 0px 1px 3px 0px #0000001a;

  &::before {
    content: "${({ $placeholder }) => $placeholder}";
    font-size: ${({ $justificationText }) =>
      $justificationText && $justificationText.length > 0 ? "9" : "14"}px;
    font-family: var(--font-paragraph-default);
    color: var(--color-text-labels);

    position: absolute;
    z-index: 1;

    top: ${({ $justificationText }) =>
      $justificationText && $justificationText.length > 0 ? "10" : "18"}px;
    left: 12px;

    transition: top 0.2s ease-in-out, font-size 0.2s ease-in-out;
  }

  &::after {
    content: "${({ $justificationText }) =>
      $justificationText && $justificationText.length} de 256 caracteres";

    position: absolute;
    z-index: 1;
    left: 0px;
    bottom: -15px;

    font-size: 10px;
    font-family: var(--font-paragraph-default);
    color: var(--color-text-labels);
  }

  &:focus-within {
    border: 1px solid var(--color-main-blue);

    &::before,
    .mandatory {
      font-size: 9px;
      top: 10px;
    }

    &::before {
      color: var(--color-main-blue);
    }

    .mandatory {
      left: 175px;
    }
  }

  .mandatory {
    position: absolute;
    ${({ $justificationText }) =>
      $justificationText && $justificationText.length > 0
        ? "font-size: 9px;" + "left: 175px;" + "top: 10px;"
        : "font-size: 14px;" + "left: 266px;" + "top: 18px;"}
    color: var(--color-aux-danger-700);

    transition: top 0.2s ease-in-out, left 0.2s ease-in-out,
      font-size 0.2s ease-in-out;
  }
`;

type TCommonProps = {
  list: "default" | "filter";
  content: TContent[];
  onClick?: (selectedIndex: string, selected: TContent[]) => void;
  shouldShow: "show" | "hide";
  setShouldShow: React.Dispatch<React.SetStateAction<"show" | "hide">>;
  optGroup?: string;
  setshouldShowNextMenu: React.Dispatch<React.SetStateAction<boolean>>;
  shouldShowNextMenu: boolean;
};

export type TManifestationStatusOptions =
  | "Confirmação da operação (Confirmada)"
  | "Ciência da emissão (Ciente)"
  | "Desconhecimento da operação (Desconhecida)"
  | "Operação não realizada (Recusada)";

type TMenuProps = TCommonProps;

const Menu: React.FC<TMenuProps> = ({
  content,
  onClick,
  shouldShow,
  optGroup,
  setShouldShow,
  setshouldShowNextMenu,
  shouldShowNextMenu,
}) => {
  const [selected, setSelected] = useState<TManifestationStatusOptions>(
    "Confirmação da operação (Confirmada)"
  );

  const dispatch = useTypedDispatch();

  const [justificationText, setJustificationText] = useState<
    string | undefined
  >();

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const TEXT_AREA_MAX_lENGTH = 256;

  const handleTextAreaOnInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setTimeout(() => {
      if (
        textAreaRef.current &&
        textAreaRef.current.value.length <= TEXT_AREA_MAX_lENGTH
      ) {
        setJustificationText((e.target as HTMLTextAreaElement).value);
      }
    }, 1000);
  };

  const handleFetchInvoices = () => {
    const querystrings = getQuerystringParams();

    if (cancelTokenSource.current) {
      dispatch(
        fetchInvoices({
          organizationUuid: querystrings.organizationId,
          launch_status: querystrings.launchStatus as TInvoiceEntryStatus,
          dateIssueEnd: querystrings.endDate,
          dateIssueStart: querystrings.startDate,
          has_accounts_payable: querystrings.hasPayable,
          has_goods_receipt: querystrings.hasGoods,
          cancelToken: cancelTokenSource.current.token,
          page: querystrings.page,
          per_page: querystrings.itemsPerPage,
          search: querystrings.search,
        })
      );
    }
  };

  const handleMenuOptionRender = (value: TContent) => {
    switch (value) {
      case "editStatus":
        return (
          <>
            <UilPen />
            &nbsp;Fazer manifestação
          </>
        );
      case "markAsFinished":
        return (
          <>
            <UilCheckCircle />
            &nbsp;Marcar como finalizada
          </>
        );
      case "markAsPending":
        return (
          <>
            <UilEnter />
            &nbsp;Marcar como pendente
          </>
        );
      case "downloadFile":
        return (
          <>
            <UilImport />
            &nbsp;Baixar arquivo
          </>
        );
      case "deletePaymentSlip":
        return (
          <>
            <UilTrash />
            &nbsp;Recusar boleto
          </>
        );
      case "openGoodsModal":
        return (
          <>
            <UilImport />
            &nbsp;Criar entrada de mercadorias
          </>
        );
      case "openPayableModal":
        return (
          <>
            <UilDollarSign />
            &nbsp;Criar/Vincular contas a pagar
          </>
        );
    }
  };

  useEffect(() => {
    return () => {
      setTimeout(() => {
        if (cancelTokenSource.current) cancelTokenSource.current.cancel();
      }, 500);
    };
  }, []);

  return (
    <>
      {!shouldShowNextMenu && (
        <Container $shouldshow={shouldShow}>
          {optGroup && (
            <div className="optGroupContainer">
              <p className="optGroup">{optGroup}</p>
            </div>
          )}
          {content.map((value, index) => {
            return (
              value && (
                <ItemButton
                  $shouldshow={shouldShow}
                  key={index}
                  onClick={() => {
                    if (value === "editStatus") {
                      setshouldShowNextMenu(true);
                      setTimeout(() => {
                        setShouldShow("show");
                      }, 20);
                    }
                    if (onClick) onClick(`${index}`, [value]);
                  }}
                >
                  <p>{handleMenuOptionRender(value)}</p>
                </ItemButton>
              )
            );
          })}
        </Container>
      )}
      {shouldShowNextMenu /* && shouldShow === "show" */ && (
        <NextContainer $shouldshow={shouldShowNextMenu ? "show" : "hide"}>
          <h2>Fazer manifestação</h2>

          <Dropdown
            button={{
              variant: "unlabeled",
              placeholder: "",
              labels: ["Confirmação da operação (Confirmada)"],
            }}
            menu={{
              variant: "unlabeled",
              options: [
                "Confirmação da operação (Confirmada)",
                "Ciência da emissão (Ciente)",
                "Desconhecimento da operação (Desconhecida)",
                "Operação não realizada (Recusada)",
              ],
              search: false,
              forceSelectOptionsByIndex: [0],
              onSelect(index) {
                setSelected(index as TManifestationStatusOptions);
              },
            }}
          />
          {[
            "Desconhecimento da operação (Desconhecida)",
            "Operação não realizada (Recusada)",
          ].includes(selected) && (
            <TextAreaContainer
              $placeholder="Justificativa (mínimo de 15 caracteres) "
              $justificationText={justificationText}
              role="button"
              onClick={() => {
                if (textAreaRef.current !== null) textAreaRef.current.focus();
              }}
            >
              <TextArea
                ref={textAreaRef}
                minLength={15}
                maxLength={TEXT_AREA_MAX_lENGTH}
                onInput={handleTextAreaOnInput}
              />
              <span className="mandatory">&nbsp;*</span>
            </TextAreaContainer>
          )}
          <FilterArea>
            <div className="buttonsArea">
              <Button
                variant="secondary"
                icon="noIcon"
                text="Cancelar"
                onClick={() => {
                  setShouldShow("hide");
                  setshouldShowNextMenu(false);
                  setSelected("Confirmação da operação (Confirmada)");
                }}
              />
              <Button
                disabled={
                  [
                    "Desconhecimento da operação (Desconhecida)",
                    "Operação não realizada (Recusada)",
                  ].includes(selected) &&
                  (justificationText === undefined ||
                    justificationText.length < 15)
                }
                variant="primary"
                icon="checkmark"
                iconPosition="left"
                text="Salvar alteração"
                onClick={() => {
                  const localSelectInvoice = handleGetDataFromLocalStorage(
                    localStorageConstants.SELECT_INVOICE
                  ) as TInvoice;

                  if (localSelectInvoice) {
                    let result;
                    switch (selected) {
                      case "Confirma\u00E7\u00E3o da opera\u00E7\u00E3o (Confirmada)":
                        result = 20;
                        break;
                      case "Ci\u00EAncia da emiss\u00E3o (Ciente)":
                        result = 10;
                        break;
                      case "Desconhecimento da opera\u00E7\u00E3o (Desconhecida)":
                        result = 30;
                        break;
                      case "Operação não realizada (Recusada)":
                        result = 40;
                        break;
                    }

                    cancelTokenSource.current = axios.CancelToken.source();

                    apiService
                      .updateInvoiceManifestation(
                        localSelectInvoice.uuid,
                        result as TInvoiceManifestastionStatus,
                        cancelTokenSource.current.token,
                        justificationText
                      )
                      .then(() => {
                        dispatch(
                          setToastState({
                            variant: "success",
                            shouldShow: true,
                            message: {
                              title: "Sucesso",
                              description: "A manifestação foi feita",
                            },
                          })
                        );
                        handleFetchInvoices();
                      })
                      .catch((e: any) => {
                        console.error("error: ", e);

                        const errorMessage = backendErrorMessageHandler(e);

                        dispatch(
                          setToastState({
                            variant: "error",
                            shouldShow: true,
                            message: {
                              title: "Erro ao fazer manifestação",
                              description:
                                errorMessage ??
                                "Ocorreu um erro ao fazer manifestação, tente novamente",
                            },
                          })
                        );
                      })
                      .finally(() => {
                        setSelected(
                          "Confirmação da operação (Confirmada)" as TManifestationStatusOptions
                        );
                      });
                  }

                  setShouldShow("hide");
                  setshouldShowNextMenu(false);
                  // onClick(selected, [selected], setSelected);
                }}
              />
            </div>
          </FilterArea>
        </NextContainer>
      )}
    </>
  );
};

export default Menu;
