import styled from "styled-components";
import Radio from "../../Inputs/Radio";
import LabeledTextInput from "../../Inputs/LabeledTextInput";
import Calendar from "../../Inputs/Calendar";
import Check from "../../Inputs/Check";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Dropdown from "../../Dropdown";
import SimpleSlider from "../../SimpleSlider";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useTypedDispatch } from "../../../../hooks/useTypedDispatch";
import {
  TInvoice,
  TPaymentSlip,
  setAllPaidElements,
  setToastState,
} from "../../../../redux/reducers/globalState";
import apiService from "../../../../services/api";
import Button, { TButtonIconVariants, TButtonProps } from "../../Button";
import { compareAsc } from "date-fns/compareAsc";
import { format } from "date-fns/format";
import {
  backendErrorMessageHandler,
  calculateInstallments,
  handleParseQuerystrings,
} from "../../../../utils/miscellaneous";
import { add } from "date-fns/add";
import axios, { CancelTokenSource } from "axios";
import { cnpjMask, cpfMask } from "../../../../utils/formatters";
import { parseISO } from "date-fns/parseISO";
// import { handleGetDataFromLocalStorage } from "../../../../utils/misc";
// import { localStorageConstants } from "../../../../utils/constants";
// import { TOrganization } from "../../../../redux/reducers/organizations";

const Container = styled.form`
  & > p,
  label,
  b {
    font-size: 16px;
    color: var(--color-text-paragraph);
    font-family: var(--font-paragraph-default);
  }

  b {
    font-family: var(--font-paragraph-bold);
  }

  label {
    font-size: 14px;
  }

  table {
    thead tr:first-child {
      th b {
        font-size: 14px;
      }
    }

    thead tr:nth-child(2) {
      th b {
        font-size: 12px;
      }
    }
  }
`;

const RowContainer = styled.div<{
  $hasModeError?: "hasError" | "noError";
  $isPaid?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;

  margin-top: 16px;

  position: relative;

  .radioContainer {
    display: flex;
    align-items: center;
  }

  &.short {
    margin-top: 8px;
  }

  &.paymentForm {
    margin-bottom: 12px;

    & > div:nth-child(2),
    & .large {
      & > button {
        width: 380px;
      }
      margin-left: 12px;
    }
  }

  &:first-of-type {
    width: 350px;

    justify-content: space-between;

    ${({ $hasModeError }) =>
      $hasModeError === "hasError" &&
      "border: 1px solid var(--color-aux-danger-700);"}

    p.errorMessage {
      position: absolute;
      bottom: -12px;
      visibility: visible;
      font-size: 10px;
      color: var(--color-aux-danger-700);

      visibility: ${({ $hasModeError }) =>
        $hasModeError === "hasError" ? "visible" : "hidden"};
    }
  }

  &:nth-of-type(2) {
    & > *:not(:last-child) {
      margin-right: 16px;
    }

    & > *:not(:last-child) > button,
    & > .calendar > button {
      width: 167px;
    }

    & > *:nth-child(3) {
      width: 300px;
    }

    & > *:last-child:not(.calendar) > button {
      width: 187px;
    }
  }

  &.description {
    margin-top: 24px;

    & > div {
      width: 100%;

      & > button {
        width: 100%;
      }
    }
  }

  & .pixTypeContainer {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    #PIX-bankInfo {
      margin-left: 12px;
    }
  }

  & .tedContainer {
    display: flex;
    justify-content: space-between;
    width: 380px;

    &.bankInfoError {
      & div:first-of-type {
        margin-left: 0px !important;
      }

      + p.errorMessage {
        visibility: visible;
      }

      border: 1px solid var(--color-aux-danger-700);
    }

    + p.errorMessage {
      position: absolute;
      bottom: 48px;
      left: 292px;
      visibility: hidden;
      font-size: 10px;
      color: var(--color-aux-danger-700);
    }

    & > div {
      &:not(.verificationDigit):not(.hifen) {
        &:not(:first-child) {
          margin-left: 12px;
        }

        &:first-child > button {
          width: 67px;
        }

        &:nth-child(2) > button {
          width: 80px;
        }

        & > button {
          width: 100px;
        }
      }

      &.verificationDigit {
        & > button {
          width: 47px;
        }
      }
    }

    .hifen {
      display: flex;
      align-items: center;
    }
  }

  &.paidContainer {
    flex-wrap: wrap;

    border-bottom: 1px solid var(--color-light-200);
    padding-bottom: 24px;

    & > div:not(div.calendar):not(:last-child) {
      width: 160px;
      margin-right: 12px;

      & > button:not(button#paid) {
        width: 100%;

        // &::before {
        //   font-size: 14px;
        // }
      }
    }

    & > .calendar {
      width: 200px;
      margin-right: 12px;

      & > button {
        width: 100%;

        & > p {
          font-size: 14px;
        }
      }
    }

    & > div:last-child:not(&.description > div) {
      width: 256px;
      margin-top: 8px;

      button p {
        font-size: 14px;
      }
    }
  }

  &:last-of-type {
    border-top: 1px solid var(--color-light-200);
    padding-top: 24px;
    margin-top: 24px;
    justify-content: space-between;

    div button {
      // height: 50px;
    }

    & div menu {
      max-height: 159px;
    }
  }
`;

const Footer = styled.footer`
  margin-top: 30px;
  border-top: 1px solid var(--color-light-200);
  padding: 24px 0px 16px;
  display: flex;
  justify-content: flex-end;

  .textContainer {
    b,
    p {
      font-size: 14px;
      color: var(--color-text-paragraph);
    }

    b {
      font-family: var(--font-paragraph-bold);
    }

    p {
      font-family: var(--font-paragraph-default);
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;

    & button:not(& button:last-child) {
      margin-right: 12px;
    }
  }
`;

export type TPayableObjectForm = {
  amount: string;
  mode: null | "in_cash" | "recurrent" | "installments";
  supplier_id: number | null;
  invoice_id: string;

  due_date: string;

  category_id?: number;
  classification_center_id?: number;
  description?: string;
  observation?: string;

  paid_amount?: string;
  payment_date?: string;
  expected_deposit_account_id?: number | null;
  income_tax_relevant?: boolean;

  fine_amount?: string;
  interest_amount?: string;
  discount_amount?: string;
  period?:
    | "weekly"
    | "biweekly"
    | "monthly"
    | "bimonthly"
    | "quarterly"
    | "semiannual"
    | "annual";
  installments?: number;
  paid_installments?: TPaidInstallments[];
  payment_information_attributes: TPaymentInformationAttributes;
};

export type TPaymentInformationAttributes = {
  payment_type: TPaymentFormVariants; // "BankBillet" | "TedTransfer" | "PixTransfer" | "PixPayment";
  beneficiary_name?: string;
  beneficiary_document_number?: string;

  beneficiary_bank_code?: string; // TedTransfer or PixTransfer only
  beneficiary_bank_agency?: string; // TedTransfer or PixTransfer only
  beneficiary_bank_agency_digit?: string; // TedTransfer or PixTransfer only
  beneficiary_bank_account_number?: string; // TedTransfer or PixTransfer only
  beneficiary_bank_account_digit?: string; // TedTransfer or PixTransfer only

  barcode?: string; // BankBillet only

  pix_type?: "key" | "bank_info";
  pix_key_type?: TPIXKeyType;
  pix_key?: string;
  qr_code?: string;
};

type TPIXKeyType = "Telefone" | "E-mail" | "CPF/CNPJ" | "Aleatória";

export type TPaidInstallments = {
  installment: number;
  nominal_amount: string;
  due_date: string;
  payment_date?: string;
  expected_deposit_account_id: number | null;
  paid_amount: string;
  fine_amount: string;
  interest_amount: string;
  discount_amount: string;
  errorMessage?: string;
};

type TAddPayableProps = {
  invoice: TInvoice;
  footer: { buttons: TButtonProps<TButtonIconVariants>[] };
};

type TPaymentFormVariants =
  | ""
  | "---"
  | "Boleto"
  | "TED"
  | "PIX - transferência"
  | "PIX - pagamento";

const AddPayable: React.FC<TAddPayableProps> = ({ invoice, footer }) => {
  const { selectOrganization } = useTypedSelector(
    (state) => state.globalState.data
  );

  const paymentDateErrorMessageRef = useRef("");
  const setPaidAmountRef = useRef<React.Dispatch<
    React.SetStateAction<string>
  > | null>(null);

  const attachedPaymentSlipsRef = useRef<TPaymentSlip[]>([]);

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const setPaidAmountRefs = useRef<
    React.Dispatch<React.SetStateAction<string>>[]
  >([]);

  // const tempRef = useRef<
  //   {
  //     payMethod?: string;
  //     paymentComplement?: string;
  //     TED?: {
  //       bank: string;
  //       branch: { code: string; vd: string };
  //       account: { code: string; vd: string };
  //     };
  //   }[]
  // >([]);

  const [installments, setInstallments] = useState(1);

  const installmentPaymentInfosRef = useRef<
    {
      payMethod: string;
      edited: boolean;
      data: TPaymentInformationAttributes;
    }[]
  >([]);

  const [pixType, setPixType] = useState<"key" | "bankInfo">("key");
  const [simpleSliderPixType, setSimpleSliderPixType] = useState<
    ("key" | "bankInfo")[]
  >([]);

  const [activeRadio, setActiveRadio] = useState("full");
  const [isPaid, setIsPaid] = useState(false);
  const [paidInstalments, setPaidInstallments] = useState<number[]>([]);
  const [categories, setCategories] = useState<
    { id: number; full_name: string }[]
  >([]);
  const [classificationCenters, setClassificationCenters] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  // const [suppliers, setSuppliers] = useState<{ id: number; name: string }[]>(
  //   []
  // );

  const [shouldComponentUpdate, setShouldComponentUpdate] = useState(false);

  const [supplier, setSupplier] = useState<
    | {
        id: number;
        name: string;
        cnpj: string;
      }
    | undefined
  >();

  const [depositAccounts, setDepositAccounts] = useState<
    { id: number; name: string }[]
  >([]);

  const [shouldShowModeError /* setShouldShowModeError */] = useState(false);
  const [shouldShowCategoryIdError /* setShouldShowCategoryIdError */] =
    useState(false);
  const [
    shouldShowClassificationCenterIdError,
    /* setShouldShowClassificationCenterIdError */
    ,
  ] = useState(false);
  const [shouldShowSupplierIdError /* setShouldShowSupplierIdError */] =
    useState(false);
  const [shouldShowDescriptionError /* setShouldShowDescriptionError */] =
    useState(false);
  const [shouldShowDueDateError, setShouldShowDueDateError] = useState(false);
  const [shouldShowPaymentDateError, setShouldShowPaymentDateError] =
    useState(false);
  const [shouldShowPeriodError, setShouldShowPeriodError] = useState(false);
  const [shouldShowInstallmentsError, setShouldShowInstallmentsError] =
    useState(false);

  const [installmentsErrorMessage, setInstallmentsErrorMessage] = useState<
    "Campo obrigatório" | "O campo deve ser maior que 1"
  >("Campo obrigatório");

  const [
    shouldShowInstallmentsAmountError,
    setShouldShowInstallmentsAmountError,
  ] = useState<boolean[]>([]);

  const [shouldShowBeneficiaryNameError, setShouldShowBeneficiaryNameError] =
    useState(false);
  const [
    shouldShowBeneficiaryDocumentError,
    setShouldShowBeneficiaryDocumentError,
  ] = useState(false);
  const [shouldShowBankInfoError, setShouldShowBankInfoError] = useState(false);
  const [shouldShowBarCodeError, setShouldShowBarCodeError] = useState(false);
  const [shouldShowPixKeyError, setShouldShowPixKeyError] = useState(false);
  const [shouldShowQRCodeError, setShouldShowQRCodeError] = useState(false);

  const [installmentsPaymentInfoErrors, setInstallmentsPaymentInfoErrors] =
    useState<
      {
        beneficiaryName: boolean;
        beneficiaryDocument: boolean;
        bankInfo: boolean;
        barcode: boolean;
        pixKey: boolean;
        qrcode: boolean;
      }[]
    >([]);

  const [backendErrorMessage, setBackendErrorMessage] = useState<{
    field: TPaymentInformationAttributes | null;
    value: string;
  }>({ field: null, value: "" });

  const [paymentForm, setPaymentForm] = useState<TPaymentFormVariants>("");
  const [paymentFormArray, setPaymentFormArray] = useState<
    TPaymentFormVariants[]
  >([]);
  pixType;
  const [pixKeyType, setPixKeyType] = useState<TPIXKeyType>("Telefone");

  const [simpleSliderPixKeyType, setSimpleSliderPixKeyType] = useState<
    TPIXKeyType[]
  >(["Telefone"]);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const allPaidElements = useTypedSelector(
    (state) => state.globalState.data.allPaidElements
  );

  const navigateToSlideByIndexRef = useRef<
    React.Dispatch<React.SetStateAction<number>> | undefined
  >();

  // const paidInstallmentsRef = useRef<TPA>(0);

  const dispatch = useTypedDispatch();

  const shouldSubmitRef = useRef(false);

  const submitObjectInitialState: TPayableObjectForm = {
    amount: "",
    due_date: "",
    mode: "in_cash" as const,
    invoice_id: "",
    supplier_id: null,

    category_id: undefined,
    classification_center_id: undefined,
    description: undefined,
    observation: undefined,
    expected_deposit_account_id: undefined,
    paid_amount: undefined,
    fine_amount: undefined,
    interest_amount: undefined,
    discount_amount: undefined,
    payment_date: undefined,
    period: undefined,
    installments: undefined,
    income_tax_relevant: undefined,
    paid_installments: undefined,
    payment_information_attributes: {
      payment_type: "",
      beneficiary_name: "",
      beneficiary_document_number: "",

      beneficiary_bank_code: undefined, // TedTransfer or PixTransfer only
      beneficiary_bank_agency: undefined, // TedTransfer or PixTransfer only
      beneficiary_bank_agency_digit: undefined, // TedTransfer or PixTransfer only
      beneficiary_bank_account_number: undefined, // TedTransfer or PixTransfer only
      beneficiary_bank_account_digit: undefined, // TedTransfer or PixTransfer only

      barcode: undefined, // BankBillet only

      pix_type: undefined,
      pix_key_type: undefined,
      pix_key: undefined,
      qr_code: undefined,
    },
  };

  const submitObjectRef = useRef<TPayableObjectForm>(submitObjectInitialState);

  const handleWhichApiCallByMode = (): Promise<any> => {
    try {
      const payInfoAttributes =
        submitObjectRef.current.payment_information_attributes.payment_type
          .length === 0
          ? undefined
          : submitObjectRef.current.payment_information_attributes;

      switch (submitObjectRef.current.mode) {
        case "in_cash":
          const tempInCash = { ...submitObjectRef.current } as any;
          const tempInCashPayInfoAttributes = {
            ...tempInCash.payment_information_attributes,
          };

          if (payInfoAttributes) {
            switch (payInfoAttributes.payment_type) {
              case "Boleto":
                tempInCashPayInfoAttributes.payment_type = "BankBillet";
                break;
              case "TED":
                tempInCashPayInfoAttributes.payment_type = "TedTransfer";
                break;
              case "PIX - transfer\u00EAncia":
                tempInCashPayInfoAttributes.payment_type = "PixTransfer";
                break;
              case "PIX - pagamento":
                tempInCashPayInfoAttributes.payment_type = "PixPayment";
                break;
              case "---":
                tempInCashPayInfoAttributes.payment_type = "";
            }

            switch (payInfoAttributes.pix_key_type as TPIXKeyType) {
              case "Telefone":
                tempInCashPayInfoAttributes.pix_key_type = "phone";
                break;
              case "E-mail":
                tempInCashPayInfoAttributes.pix_key_type = "email";
                break;
              case "CPF/CNPJ":
                tempInCashPayInfoAttributes.pix_key_type = "cpf_cnpj";
                break;
              case "Aleat\u00F3ria":
                tempInCashPayInfoAttributes.pix_key_type = "random";
                break;
            }
          }

          tempInCash.payment_information_attributes =
            payInfoAttributes !== undefined
              ? tempInCashPayInfoAttributes
              : undefined;

          console.log("full pay info: ", tempInCash);

          return apiService.createInCashPayable(
            tempInCash,
            (cancelTokenSource.current as CancelTokenSource).token
          );
        case "recurrent":
          const tempRecurrent = { ...submitObjectRef.current } as any;
          const tempRecurrentPayInfoAttributes = {
            ...tempRecurrent.payment_information_attributes,
          };
          if (payInfoAttributes) {
            switch (payInfoAttributes.payment_type) {
              case "Boleto":
                tempRecurrentPayInfoAttributes.payment_type = "BankBillet";
                break;
              case "TED":
                tempRecurrentPayInfoAttributes.payment_type = "TedTransfer";
                break;
              case "PIX - transfer\u00EAncia":
                tempRecurrentPayInfoAttributes.payment_type = "PixTransfer";
                break;
              case "PIX - pagamento":
                tempRecurrentPayInfoAttributes.payment_type = "PixPayment";
                break;
              case "---":
                tempRecurrentPayInfoAttributes.payment_type = "";
            }

            switch (payInfoAttributes.pix_key_type as TPIXKeyType) {
              case "Telefone":
                tempRecurrentPayInfoAttributes.pix_key_type = "phone";
                break;
              case "E-mail":
                tempRecurrentPayInfoAttributes.pix_key_type = "email";
                break;
              case "CPF/CNPJ":
                tempRecurrentPayInfoAttributes.pix_key_type = "cpf_cnpj";
                break;
              case "Aleat\u00F3ria":
                tempRecurrentPayInfoAttributes.pix_key_type = "random";
                break;
            }
          }

          tempRecurrent.payment_information_attributes =
            payInfoAttributes !== undefined
              ? tempRecurrentPayInfoAttributes
              : undefined;

          return apiService.createRecurringPayable(
            tempRecurrent,
            (cancelTokenSource.current as CancelTokenSource).token
          );
        case "installments":
          const temp = { ...submitObjectRef.current } as any;
          temp.amount = undefined;
          temp.discount_amount = undefined;
          temp.due_date = undefined;
          temp.fine_amount = undefined;
          temp.installments = undefined;
          temp.interest_amount = undefined;
          temp.mode = undefined;
          temp.paid_amount = undefined;
          temp.payment_date = undefined;
          temp.period = undefined;

          let tempPayInfoAttributes = [
            ...installmentPaymentInfosRef.current.map((object) => object.data),
          ] as any[];

          tempPayInfoAttributes.forEach((payInfo, index) => {
            handleSimpleSliderPaymentInformationByPaymentTypeChange(
              payInfo.payment_type,
              index
            );
          });

          tempPayInfoAttributes.forEach((payInfo, index) => {
            if (payInfo) {
              switch (payInfo.payment_type) {
                case "Boleto":
                  tempPayInfoAttributes[index].payment_type = "BankBillet";
                  break;
                case "TED":
                  tempPayInfoAttributes[index].payment_type = "TedTransfer";
                  break;
                case "PIX - transfer\u00EAncia":
                  tempPayInfoAttributes[index].payment_type = "PixTransfer";
                  break;
                case "PIX - pagamento":
                  tempPayInfoAttributes[index].payment_type = "PixPayment";
                  break;
                case "---":
                  tempPayInfoAttributes[index].payment_type = "";
              }

              switch (payInfo.pix_key_type as TPIXKeyType) {
                case "Telefone":
                  tempPayInfoAttributes[index].pix_key_type = "phone";
                  break;
                case "E-mail":
                  tempPayInfoAttributes[index].pix_key_type = "email";
                  break;
                case "CPF/CNPJ":
                  tempPayInfoAttributes[index].pix_key_type = "cpf_cnpj";
                  break;
                case "Aleat\u00F3ria":
                  tempPayInfoAttributes[index].pix_key_type = "random";
                  break;
              }
            }
          });

          temp.installments = submitObjectRef.current.paid_installments
            ? [
                ...submitObjectRef.current.paid_installments.map(
                  (installment, index) => ({
                    installment: installment.installment,
                    due_date: installment.due_date,
                    amount: installment.nominal_amount,

                    payment_date:
                      installment.paid_amount.length > 0
                        ? installment.payment_date
                        : undefined,
                    paid_amount:
                      installment.paid_amount.length > 0
                        ? installment.paid_amount
                        : "0",
                    fine_amount:
                      installment.fine_amount.length > 0
                        ? installment.fine_amount
                        : "0",
                    interest_amount:
                      installment.interest_amount.length > 0
                        ? installment.interest_amount
                        : "0",
                    discount_amount:
                      installment.discount_amount.length > 0
                        ? installment.discount_amount
                        : "0",
                    expected_deposit_id:
                      installment.expected_deposit_account_id,

                    payment_information_attributes:
                      tempPayInfoAttributes[index].payment_type === ""
                        ? undefined
                        : {
                            ...tempPayInfoAttributes[index],
                          },
                  })
                ),
              ]
            : [];
          temp.paid_installments = undefined;

          temp.payment_information_attributes = undefined;

          // const tempPayInfoAttributes = {
          //   ...temp.payment_information_attributes,
          // };

          // temp.payment_information_attributes =
          //   payInfoAttributes !== undefined ? tempPayInfoAttributes : undefined;

          // console.log("submit object: ", temp);

          return apiService.createInstallmentsPayable(
            temp,
            (cancelTokenSource.current as CancelTokenSource).token
          );
        default:
          throw new Error("invalid mode");
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (submitObjectRef.current) {
      if (submitObjectRef.current.amount?.length === 0) {
        submitObjectRef.current.amount = invoice.amount;
      }

      if (submitObjectRef.current.invoice_id.length === 0) {
        submitObjectRef.current.invoice_id = invoice.uuid;
      }

      // if (submitObjectRef.current.category_id === null) {
      //   setShouldShowCategoryIdError(true);
      //   // shouldSubmitRef.current = false;
      // } else if (shouldShowCategoryIdError) {
      //   // shouldSubmitRef.current = true;
      //   setShouldShowCategoryIdError(false);
      // } else {
      //   // shouldSubmitRef.current = true;
      // }

      // if (submitObjectRef.current.classification_center_id === null) {
      //   setShouldShowClassificationCenterIdError(true);
      //   // shouldSubmitRef.current = false;
      // } else if (shouldShowClassificationCenterIdError) {
      //   setShouldShowClassificationCenterIdError(false);
      //   // shouldSubmitRef.current = true;
      // } else {
      //   // shouldSubmitRef.current = true;
      // }

      // if (submitObjectRef.current.supplier_id === null) {
      //   setShouldShowSupplierIdError(true);
      //   // shouldSubmitRef.current = false;
      // } else if (shouldShowSupplierIdError) {
      //   setShouldShowSupplierIdError(false);
      //   // shouldSubmitRef.current = true;
      // } else {
      //   // shouldSubmitRef.current = true;
      // }

      // if (submitObjectRef.current.description.length === 0) {
      //   setShouldShowDescriptionError(true);
      //   // shouldSubmitRef.current = false;
      // } else if (shouldShowDescriptionError) {
      //   setShouldShowDescriptionError(false);
      //   // shouldSubmitRef.current = true;
      // } else {
      //   // shouldSubmitRef.current = true;
      // }

      if (
        submitObjectRef.current.due_date.length === 0 &&
        submitObjectRef.current.mode !== "installments"
      ) {
        setShouldShowDueDateError(true);
        // console.log("setting should submit to false 1");
        shouldSubmitRef.current = false;
        return;
      } else if (shouldShowDueDateError) {
        setShouldShowDueDateError(false);
        // console.log("setting should submit to true 1");
        shouldSubmitRef.current = true;
      } else {
        // console.log("setting should submit to true 2");
        shouldSubmitRef.current = true;
      }

      // if (submitObjectRef.current.mode === null) {
      //   setShouldShowModeError(true);
      //   // shouldSubmitRef.current = false;
      // } else if (shouldShowModeError) {
      //   setShouldShowModeError(false);
      //   // shouldSubmitRef.current = true;
      // } else {
      //   // shouldSubmitRef.current = true;
      // }

      if (
        ["recurring"].includes(submitObjectRef.current.mode ?? "") &&
        submitObjectRef.current.period === undefined
      ) {
        setShouldShowPeriodError(true);
        // console.log("setting should submit to false 2");
        shouldSubmitRef.current = false;
        return;
      } else if (shouldShowPeriodError) {
        setShouldShowPeriodError(false);
        // console.log("setting should submit to true 3");
        shouldSubmitRef.current = true;
      } else {
        // console.log("setting should submit to true 4");
        shouldSubmitRef.current = true;
      }

      if (
        submitObjectRef.current.mode === "installments" &&
        (isNaN(submitObjectRef.current.installments as number) ||
          Boolean(
            submitObjectRef.current.installments !== undefined &&
              submitObjectRef.current.installments < 2
          ))
      ) {
        if (isNaN(submitObjectRef.current.installments as number)) {
          setInstallmentsErrorMessage("Campo obrigatório");
        }
        if (
          Boolean(
            submitObjectRef.current.installments !== undefined &&
              submitObjectRef.current.installments < 2
          )
        ) {
          submitObjectRef.current.installments = 2;
          setInstallmentsErrorMessage("O campo deve ser maior que 1");
        }
        setShouldShowInstallmentsError(true);
        shouldSubmitRef.current = false;
        return;
        // console.log("setting should submit to false 3");
        return;
      } else if (shouldShowInstallmentsError) {
        setShouldShowInstallmentsError(false);
        // console.log("setting should submit to true 5");
        shouldSubmitRef.current = true;
      } else {
        // console.log("setting should submit to true 6");
        shouldSubmitRef.current = true;
      }

      if (
        submitObjectRef.current.mode === "installments" &&
        submitObjectRef.current.paid_installments &&
        submitObjectRef.current.paid_installments.some(
          (installment) => installment.nominal_amount.length === 0
        )
      ) {
        if (navigateToSlideByIndexRef.current) {
          const emptyInstallmentIndex =
            submitObjectRef.current.paid_installments.findIndex(
              (installment) => installment.nominal_amount.length === 0
            );
          if (emptyInstallmentIndex >= 0) {
            navigateToSlideByIndexRef.current(emptyInstallmentIndex + 1);
          }
        }
        setShouldShowInstallmentsAmountError(
          submitObjectRef.current.paid_installments.map(
            (installment) => installment.nominal_amount.length === 0
          )
        );
        shouldSubmitRef.current = false;
        return;
        // console.log("setting should submit to false 3");
        return;
      } else if (shouldShowInstallmentsAmountError.includes(true)) {
        setShouldShowInstallmentsAmountError([]);
        // console.log("setting should submit to true 5");
        shouldSubmitRef.current = true;
      } else {
        // console.log("setting should submit to true 6");
        shouldSubmitRef.current = true;
      }

      if (isPaid) {
        if (!submitObjectRef.current.payment_date) {
          paymentDateErrorMessageRef.current = "Campo obrigatório";
          setShouldShowPaymentDateError(true);

          // console.log("setting should submit to false 4");
          shouldSubmitRef.current = false;
          return;
        } else if (
          compareAsc(submitObjectRef.current.payment_date, new Date()) === 1
        ) {
          paymentDateErrorMessageRef.current = "Esta data não pode ser futura";
          setShouldShowPaymentDateError(true);
          // console.log("setting should submit to false 5");
          shouldSubmitRef.current = false;
          return;
        } else if (shouldShowPaymentDateError) {
          setShouldShowPaymentDateError(false);
          // console.log("setting should submit to true 7");
          shouldSubmitRef.current = true;
        } else {
          // console.log("setting should submit to true 8");
          shouldSubmitRef.current = true;
        }
      }

      // if (
      //   !(
      //     submitObjectRef.current.category_id === null ||
      //     submitObjectRef.current.classification_center_id === null ||
      //     submitObjectRef.current.supplier_id === null ||
      //     submitObjectRef.current.description.length === 0 ||
      //     submitObjectRef.current.due_date.length === 0 ||
      //     submitObjectRef.current.mode === null ||
      //     (["recurring", "installments"].includes(activeRadio) &&
      //       submitObjectRef.current.period === undefined) ||
      //     (activeRadio === "installments" &&
      //       submitObjectRef.current.installments === undefined) ||
      //     (isPaid &&
      //       submitObjectRef.current.payment_date &&
      //       submitObjectRef.current.payment_date.length === 0)
      //   )
      // ) {
      //   shouldSubmitRef.current = true;
      // } else {
      //   shouldSubmitRef.current = false;
      // }

      if (!submitObjectRef.current.discount_amount) {
        // submitObjectRef.current.discount_amount = "0";
      }
      if (!submitObjectRef.current.fine_amount) {
        // submitObjectRef.current.fine_amount = "0";
      }
      if (!submitObjectRef.current.interest_amount) {
        // submitObjectRef.current.interest_amount = "0";
      }

      // if (/* isPaid && */ activeRadio === "installments") {
      //   submitObjectRef.current.paid_amount = undefined;
      // }
      if (submitObjectRef.current.mode === "installments") {
        installmentPaymentInfosRef.current.map((payInfo, index) =>
          handleValidateSimpleSliderPaymentInformationAttributes(
            payInfo.data,
            index
          )
        );
      } else {
        handleValidatePaymentInformationAttributes();
      }

      if (shouldSubmitRef.current) {
        setIsButtonLoading(true);
        handleWhichApiCallByMode()
          .then((_response) => {
            apiService
              .getInvoice(
                submitObjectRef.current.invoice_id,
                (cancelTokenSource.current as CancelTokenSource).token
              )
              .then((response: any) => {
                if (footer.buttons[1].onClick) {
                  footer.buttons[1].onClick();
                }
                setIsButtonLoading(false);
                if (response?.data)
                  dispatch(
                    setToastState({
                      variant: "success",
                      shouldShow: true,
                      message: {
                        title: "Contas a pagar lançada",
                        description: "Clique aqui para acessar na Fintera",
                        link: { url: response.data.accounts_payable_url },
                      },
                    })
                  );
              });
          })
          .catch((e) => {
            const errorMessage = backendErrorMessageHandler(e);

            if (typeof errorMessage === "object") {
              const temp = errorMessage[0] as any;
              setBackendErrorMessage({
                field: temp as TPaymentInformationAttributes,
                value: errorMessage[1],
              });
            }

            shouldSubmitRef.current = false;
            setIsButtonLoading(false);

            dispatch(
              setToastState({
                variant: "error",
                shouldShow: true,
                message: {
                  title: "Erro ao lançar nota",
                  description:
                    typeof errorMessage === "string"
                      ? errorMessage
                      : "Ocorreu um erro ao lançar a nota como Contas a Pagar, tente novamente",
                },
              })
            );
          });
      }
    }
  };

  const handlePaymentInformationByPaymentTypeChange = (
    payType: TPaymentFormVariants
  ) => {
    const payInfoAttributes =
      submitObjectRef.current.payment_information_attributes;
    switch (payType) {
      case "Boleto":
        payInfoAttributes.beneficiary_bank_account_digit = undefined;
        payInfoAttributes.beneficiary_bank_account_number = undefined;
        payInfoAttributes.beneficiary_bank_agency = undefined;
        payInfoAttributes.beneficiary_bank_agency_digit = undefined;
        payInfoAttributes.beneficiary_bank_code = undefined;

        payInfoAttributes.pix_key = undefined;
        payInfoAttributes.pix_key_type = undefined;
        payInfoAttributes.pix_type = undefined;

        payInfoAttributes.qr_code = undefined;
        break;
      case "TED":
        payInfoAttributes.barcode = undefined;

        payInfoAttributes.pix_key = undefined;
        payInfoAttributes.pix_key_type = undefined;
        payInfoAttributes.pix_type = undefined;

        payInfoAttributes.qr_code = undefined;
        break;
      case "PIX - pagamento":
        payInfoAttributes.barcode = undefined;
        payInfoAttributes.beneficiary_document_number = undefined;
        payInfoAttributes.beneficiary_name = undefined;

        payInfoAttributes.pix_key = undefined;
        payInfoAttributes.pix_key_type = undefined;
        payInfoAttributes.pix_type = undefined;

        payInfoAttributes.beneficiary_bank_account_digit = undefined;
        payInfoAttributes.beneficiary_bank_account_number = undefined;
        payInfoAttributes.beneficiary_bank_agency = undefined;
        payInfoAttributes.beneficiary_bank_agency_digit = undefined;
        payInfoAttributes.beneficiary_bank_code = undefined;

        break;

      case "PIX - transfer\u00EAncia":
        payInfoAttributes.barcode = undefined;
        payInfoAttributes.qr_code = undefined;

        if (payInfoAttributes.pix_type === "bank_info") {
          payInfoAttributes.pix_key = undefined;
          payInfoAttributes.pix_key_type = undefined;
        }
        if (payInfoAttributes.pix_type === "key") {
          payInfoAttributes.beneficiary_bank_account_digit = undefined;
          payInfoAttributes.beneficiary_bank_account_number = undefined;
          payInfoAttributes.beneficiary_bank_agency = undefined;
          payInfoAttributes.beneficiary_bank_agency_digit = undefined;
          payInfoAttributes.beneficiary_bank_code = undefined;
        }

        break;
      case "---":
        payInfoAttributes.barcode = undefined;
        payInfoAttributes.beneficiary_bank_account_digit = undefined;
        payInfoAttributes.beneficiary_bank_account_number = undefined;
        payInfoAttributes.beneficiary_bank_agency = undefined;
        payInfoAttributes.beneficiary_bank_agency_digit = undefined;
        payInfoAttributes.beneficiary_bank_code = undefined;
        payInfoAttributes.beneficiary_document_number = undefined;
        payInfoAttributes.beneficiary_name = undefined;
        payInfoAttributes.payment_type = "---";
        payInfoAttributes.pix_key = undefined;
        payInfoAttributes.pix_key_type = undefined;
        payInfoAttributes.pix_type = undefined;
        payInfoAttributes.qr_code = undefined;
    }
  };

  const handleSimpleSliderPaymentInformationByPaymentTypeChange = (
    payType: TPaymentFormVariants,
    index: number
  ) => {
    const payInfoAttributes = installmentPaymentInfosRef.current[index].data;

    switch (payType) {
      case "Boleto":
        payInfoAttributes.beneficiary_bank_account_digit = undefined;
        payInfoAttributes.beneficiary_bank_account_number = undefined;
        payInfoAttributes.beneficiary_bank_agency = undefined;
        payInfoAttributes.beneficiary_bank_agency_digit = undefined;
        payInfoAttributes.beneficiary_bank_code = undefined;

        payInfoAttributes.pix_key = undefined;
        payInfoAttributes.pix_key_type = undefined;
        payInfoAttributes.pix_type = undefined;

        payInfoAttributes.qr_code = undefined;
        break;
      case "TED":
        payInfoAttributes.barcode = undefined;

        payInfoAttributes.pix_key = undefined;
        payInfoAttributes.pix_key_type = undefined;
        payInfoAttributes.pix_type = undefined;

        payInfoAttributes.qr_code = undefined;
        break;
      case "PIX - pagamento":
        payInfoAttributes.barcode = undefined;
        payInfoAttributes.beneficiary_document_number = undefined;
        payInfoAttributes.beneficiary_name = undefined;

        payInfoAttributes.pix_key = undefined;
        payInfoAttributes.pix_key_type = undefined;
        payInfoAttributes.pix_type = undefined;

        payInfoAttributes.beneficiary_bank_account_digit = undefined;
        payInfoAttributes.beneficiary_bank_account_number = undefined;
        payInfoAttributes.beneficiary_bank_agency = undefined;
        payInfoAttributes.beneficiary_bank_agency_digit = undefined;
        payInfoAttributes.beneficiary_bank_code = undefined;

        break;

      case "PIX - transfer\u00EAncia":
        payInfoAttributes.barcode = undefined;
        payInfoAttributes.qr_code = undefined;

        if (payInfoAttributes.pix_type === "bank_info") {
          payInfoAttributes.pix_key = undefined;
          payInfoAttributes.pix_key_type = undefined;
        }
        if (payInfoAttributes.pix_type === "key") {
          payInfoAttributes.beneficiary_bank_account_digit = undefined;
          payInfoAttributes.beneficiary_bank_account_number = undefined;
          payInfoAttributes.beneficiary_bank_agency = undefined;
          payInfoAttributes.beneficiary_bank_agency_digit = undefined;
          payInfoAttributes.beneficiary_bank_code = undefined;
        }

        break;
      case "---":
        payInfoAttributes.barcode = undefined;
        payInfoAttributes.beneficiary_bank_account_digit = undefined;
        payInfoAttributes.beneficiary_bank_account_number = undefined;
        payInfoAttributes.beneficiary_bank_agency = undefined;
        payInfoAttributes.beneficiary_bank_agency_digit = undefined;
        payInfoAttributes.beneficiary_bank_code = undefined;
        payInfoAttributes.beneficiary_document_number = undefined;
        payInfoAttributes.beneficiary_name = undefined;
        payInfoAttributes.payment_type = "---";
        payInfoAttributes.pix_key = undefined;
        payInfoAttributes.pix_key_type = undefined;
        payInfoAttributes.pix_type = undefined;
        payInfoAttributes.qr_code = undefined;

      // console.log("pay info attributes: ", payInfoAttributes);
    }
  };

  const handleValidatePaymentInformationAttributes = () => {
    const payInfoAttributes =
      submitObjectRef.current.payment_information_attributes;
    const tempDocument = payInfoAttributes.beneficiary_document_number?.replace(
      /\D/g,
      ""
    );

    if (
      payInfoAttributes.payment_type !== "PIX - pagamento" &&
      payInfoAttributes.payment_type.length > 0
    ) {
      if (tempDocument?.length !== 11 && tempDocument?.length !== 14) {
        setShouldShowBeneficiaryDocumentError(true);
        shouldSubmitRef.current = false;
        return;
      } else if (shouldShowBeneficiaryDocumentError) {
        setShouldShowBeneficiaryDocumentError(false);
        shouldSubmitRef.current = true;
      }

      if (payInfoAttributes.beneficiary_name?.length === 0) {
        setShouldShowBeneficiaryNameError(true);
        shouldSubmitRef.current = false;
        return;
      } else if (shouldShowBeneficiaryNameError) {
        setShouldShowBeneficiaryNameError(false);
        shouldSubmitRef.current = true;
      }
    }

    switch (payInfoAttributes.payment_type as TPaymentFormVariants) {
      case "Boleto":
        if (
          !payInfoAttributes.barcode ||
          payInfoAttributes.barcode.length === 0
        ) {
          setShouldShowBarCodeError(true);
          shouldSubmitRef.current = false;
          return;
        } else if (shouldShowBarCodeError) {
          setShouldShowBarCodeError(false);
          shouldSubmitRef.current = true;
        }
        break;
      case "TED":
        if (
          !payInfoAttributes.beneficiary_bank_account_digit ||
          payInfoAttributes.beneficiary_bank_account_digit.length === 0 ||
          !payInfoAttributes.beneficiary_bank_account_number ||
          payInfoAttributes.beneficiary_bank_account_number.length === 0 ||
          !payInfoAttributes.beneficiary_bank_agency ||
          payInfoAttributes.beneficiary_bank_agency.length === 0 ||
          !payInfoAttributes.beneficiary_bank_agency_digit ||
          payInfoAttributes.beneficiary_bank_agency_digit.length === 0 ||
          !payInfoAttributes.beneficiary_bank_code ||
          payInfoAttributes.beneficiary_bank_code.length === 0
        ) {
          setShouldShowBankInfoError(true);
          shouldSubmitRef.current = false;
          return;
        } else if (shouldShowBankInfoError) {
          setShouldShowBankInfoError(false);
          shouldSubmitRef.current = true;
        }

        break;
      case "PIX - transfer\u00EAncia":
        if (
          payInfoAttributes.pix_type === "bank_info" &&
          (!payInfoAttributes.beneficiary_bank_account_digit ||
            payInfoAttributes.beneficiary_bank_account_digit.length === 0 ||
            !payInfoAttributes.beneficiary_bank_account_number ||
            payInfoAttributes.beneficiary_bank_account_number.length === 0 ||
            !payInfoAttributes.beneficiary_bank_agency ||
            payInfoAttributes.beneficiary_bank_agency.length === 0 ||
            !payInfoAttributes.beneficiary_bank_agency_digit ||
            payInfoAttributes.beneficiary_bank_agency_digit.length === 0 ||
            !payInfoAttributes.beneficiary_bank_code ||
            payInfoAttributes.beneficiary_bank_code.length === 0)
        ) {
          setShouldShowBankInfoError(true);
          shouldSubmitRef.current = false;
          return;
        } else if (shouldShowBankInfoError) {
          setShouldShowBankInfoError(false);
          shouldSubmitRef.current = true;
        }

        if (
          payInfoAttributes.pix_type === "key" &&
          (!payInfoAttributes.pix_key || payInfoAttributes.pix_key.length === 0)
        ) {
          setShouldShowPixKeyError(true);
          shouldSubmitRef.current = false;
          return;
        } else if (shouldShowPixKeyError) {
          setShouldShowPixKeyError(false);
          shouldSubmitRef.current = true;
        }
        break;
      case "PIX - pagamento":
        if (
          !payInfoAttributes.qr_code ||
          payInfoAttributes.qr_code.length === 0
        ) {
          setShouldShowQRCodeError(true);
          shouldSubmitRef.current = false;
          return;
        } else if (shouldShowQRCodeError) {
          setShouldShowQRCodeError(false);
          shouldSubmitRef.current = true;
        }
        break;
      case "---":
        shouldSubmitRef.current = true;
        setShouldComponentUpdate(true);
        setTimeout(() => {
          setShouldComponentUpdate(false);
        }, 50);
        break;
    }
  };

  const handleValidateSimpleSliderPaymentInformationAttributes = (
    payInfo: TPaymentInformationAttributes,
    index: number
  ) => {
    const payInfoAttributes = payInfo;
    const tempDocument = payInfoAttributes.beneficiary_document_number?.replace(
      /\D/g,
      ""
    );

    if (
      payInfoAttributes.payment_type !== "PIX - pagamento" &&
      payInfoAttributes.payment_type.length > 0
    ) {
      if (tempDocument?.length !== 11 && tempDocument?.length !== 14) {
        if (navigateToSlideByIndexRef.current)
          navigateToSlideByIndexRef.current(index + 1);

        setInstallmentsPaymentInfoErrors((before) => {
          const temp = [...before];
          temp[index] = { ...temp[index], beneficiaryDocument: true };
          return temp;
        });
        shouldSubmitRef.current = false;
        return;
      } else if (installmentsPaymentInfoErrors[index].beneficiaryDocument) {
        setInstallmentsPaymentInfoErrors((before) => {
          const temp = [...before];
          temp[index] = { ...temp[index], beneficiaryDocument: false };
          return temp;
        });
        shouldSubmitRef.current = true;
      }

      if (payInfoAttributes.beneficiary_name?.length === 0) {
        if (navigateToSlideByIndexRef.current)
          navigateToSlideByIndexRef.current(index + 1);

        setInstallmentsPaymentInfoErrors((before) => {
          const temp = [...before];
          temp[index] = { ...temp[index], beneficiaryName: true };
          return temp;
        });
        shouldSubmitRef.current = false;
        return;
      } else if (installmentsPaymentInfoErrors[index].beneficiaryName) {
        setInstallmentsPaymentInfoErrors((before) => {
          const temp = [...before];
          temp[index] = { ...temp[index], beneficiaryName: false };
          return temp;
        });
        shouldSubmitRef.current = true;
      }
    }

    // console.log("index: ", index, ", pay info: ", payInfoAttributes);

    switch (payInfoAttributes.payment_type as TPaymentFormVariants) {
      case "Boleto":
        if (
          !payInfoAttributes.barcode ||
          payInfoAttributes.barcode.length === 0
        ) {
          if (navigateToSlideByIndexRef.current)
            navigateToSlideByIndexRef.current(index + 1);
          setInstallmentsPaymentInfoErrors((before) => {
            const temp = [...before];
            temp[index] = { ...temp[index], barcode: true };
            return temp;
          });
          shouldSubmitRef.current = false;
          return;
        } else if (installmentsPaymentInfoErrors[index].barcode) {
          setInstallmentsPaymentInfoErrors((before) => {
            const temp = [...before];
            temp[index] = { ...temp[index], barcode: false };
            return temp;
          });
          shouldSubmitRef.current = true;
        }
        break;
      case "TED":
        if (
          !payInfoAttributes.beneficiary_bank_account_digit ||
          payInfoAttributes.beneficiary_bank_account_digit.length === 0 ||
          !payInfoAttributes.beneficiary_bank_account_number ||
          payInfoAttributes.beneficiary_bank_account_number.length === 0 ||
          !payInfoAttributes.beneficiary_bank_agency ||
          payInfoAttributes.beneficiary_bank_agency.length === 0 ||
          !payInfoAttributes.beneficiary_bank_agency_digit ||
          payInfoAttributes.beneficiary_bank_agency_digit.length === 0 ||
          !payInfoAttributes.beneficiary_bank_code ||
          payInfoAttributes.beneficiary_bank_code.length === 0
        ) {
          if (navigateToSlideByIndexRef.current)
            navigateToSlideByIndexRef.current(index + 1);

          setInstallmentsPaymentInfoErrors((before) => {
            const temp = [...before];
            temp[index] = { ...temp[index], bankInfo: true };
            return temp;
          });
          shouldSubmitRef.current = false;
          return;
        } else if (installmentsPaymentInfoErrors[index].bankInfo) {
          setInstallmentsPaymentInfoErrors((before) => {
            const temp = [...before];
            temp[index] = { ...temp[index], bankInfo: false };
            return temp;
          });
          shouldSubmitRef.current = true;
        }

        break;
      case "PIX - transfer\u00EAncia":
        if (
          payInfoAttributes.pix_type === "bank_info" &&
          (!payInfoAttributes.beneficiary_bank_account_digit ||
            payInfoAttributes.beneficiary_bank_account_digit.length === 0 ||
            !payInfoAttributes.beneficiary_bank_account_number ||
            payInfoAttributes.beneficiary_bank_account_number.length === 0 ||
            !payInfoAttributes.beneficiary_bank_agency ||
            payInfoAttributes.beneficiary_bank_agency.length === 0 ||
            !payInfoAttributes.beneficiary_bank_agency_digit ||
            payInfoAttributes.beneficiary_bank_agency_digit.length === 0 ||
            !payInfoAttributes.beneficiary_bank_code ||
            payInfoAttributes.beneficiary_bank_code.length === 0)
        ) {
          if (navigateToSlideByIndexRef.current)
            navigateToSlideByIndexRef.current(index + 1);

          setInstallmentsPaymentInfoErrors((before) => {
            const temp = [...before];
            temp[index] = { ...temp[index], bankInfo: true };
            return temp;
          });
          shouldSubmitRef.current = false;
          return;
        } else if (installmentsPaymentInfoErrors[index].bankInfo) {
          setInstallmentsPaymentInfoErrors((before) => {
            const temp = [...before];
            temp[index] = { ...temp[index], bankInfo: true };
            return temp;
          });
          shouldSubmitRef.current = true;
        }

        if (
          payInfoAttributes.pix_type === "key" &&
          (!payInfoAttributes.pix_key || payInfoAttributes.pix_key.length === 0)
        ) {
          if (navigateToSlideByIndexRef.current)
            navigateToSlideByIndexRef.current(index + 1);

          setInstallmentsPaymentInfoErrors((before) => {
            const temp = [...before];
            temp[index] = { ...temp[index], pixKey: true };
            return temp;
          });
          shouldSubmitRef.current = false;
          return;
        } else if (installmentsPaymentInfoErrors[index].pixKey) {
          setInstallmentsPaymentInfoErrors((before) => {
            const temp = [...before];
            temp[index] = { ...temp[index], pixKey: false };
            return temp;
          });
          shouldSubmitRef.current = true;
        }
        break;
      case "PIX - pagamento":
        if (
          !payInfoAttributes.qr_code ||
          payInfoAttributes.qr_code.length === 0
        ) {
          if (navigateToSlideByIndexRef.current)
            navigateToSlideByIndexRef.current(index + 1);

          setInstallmentsPaymentInfoErrors((before) => {
            const temp = [...before];
            temp[index] = { ...temp[index], qrcode: true };
            return temp;
          });
          shouldSubmitRef.current = false;
          return;
        } else if (installmentsPaymentInfoErrors[index].qrcode) {
          setInstallmentsPaymentInfoErrors((before) => {
            const temp = [...before];
            temp[index] = { ...temp[index], qrcode: false };
            return temp;
          });
          shouldSubmitRef.current = true;
        }
        break;
      case "---":
        shouldSubmitRef.current = true;
        setShouldComponentUpdate(true);
        setTimeout(() => {
          setShouldComponentUpdate(false);
        }, 50);
        break;
    }
  };

  const handlePaymentFormComplement = (
    _index = 0,
    payForm: TPaymentFormVariants
  ) => {
    // console.log(
    //   "from handle payment complement, temp ref: ",
    //   tempRef.current,
    //   ", index: ",
    //   index
    // );
    switch (payForm) {
      case "Boleto":
        return (
          <>
            <LabeledTextInput
              placeholder="Linha digitável"
              hasMandatoryStyle={{
                default: { marginLeft: "110px" },
                onFocus: { marginLeft: "75px" },
              }}
              setData={(setInputString) => {
                // console.log(
                //   "setting numeric code line, temp ref: ",
                //   tempRef.current,
                //   ", index: ",
                //   index
                // );
                if (
                  submitObjectRef.current.payment_information_attributes.barcode
                ) {
                  setInputString(
                    submitObjectRef.current.payment_information_attributes
                      .barcode
                  );
                } else if (attachedPaymentSlipsRef.current.length > 0) {
                  submitObjectRef.current.payment_information_attributes.barcode =
                    attachedPaymentSlipsRef.current[0].bar_code;
                  setInputString(attachedPaymentSlipsRef.current[0].bar_code);
                }
              }}
              onChange={(inputValue) => {
                submitObjectRef.current.payment_information_attributes.barcode =
                  inputValue;
              }}
              customFilter={(inputRef, setInputString) => {
                const value = inputRef.value;
                inputRef.value = value.replace(/\D/g, "").slice(0, 47);
                setInputString(value.replace(/\D/g, "").slice(0, 47));
              }}
              onError={errorMessageHandler(shouldShowBarCodeError)}
            />
            <div style={{ marginTop: "12px", display: "flex" }}>
              <LabeledTextInput
                placeholder="CPF ou CNPJ"
                hasMandatoryStyle={{
                  default: { marginLeft: "103px" },
                  onFocus: { marginLeft: "70px" },
                }}
                setData={(setInputString) => {
                  if (
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_document_number &&
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_document_number.length > 0
                  ) {
                    const temp = submitObjectRef.current
                      .payment_information_attributes
                      .beneficiary_document_number as string;

                    if (temp.length > 11) {
                      setInputString(
                        cnpjMask(
                          submitObjectRef.current.payment_information_attributes
                            .beneficiary_document_number
                        )
                      );
                    } else {
                      setInputString(
                        cpfMask(
                          submitObjectRef.current.payment_information_attributes
                            .beneficiary_document_number
                        )
                      );
                    }
                  } else if (attachedPaymentSlipsRef.current.length > 0) {
                    submitObjectRef.current.payment_information_attributes.beneficiary_document_number =
                      attachedPaymentSlipsRef.current[0].payer_document;
                    setInputString(
                      submitObjectRef.current.payment_information_attributes
                        .beneficiary_document_number
                    );
                  }
                }}
                onChange={(inputValue) => {
                  submitObjectRef.current.payment_information_attributes.beneficiary_document_number =
                    inputValue.replace(/\D/g, "").slice(0, 14);
                }}
                customFilter={(inputRef, setInputString) => {
                  let value = inputRef.value;
                  value = value.replace(/\D/g, "").slice(0, 14);

                  if (value.length === 11) {
                    value = value.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{2})/,
                      "$1.$2.$3-$4"
                    );
                  } else if (value.length === 14) {
                    value = value.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      "$1.$2.$3/$4-$5"
                    );
                  }

                  inputRef.value = value;
                  setInputString(value);
                }}
                onError={errorMessageHandler(
                  shouldShowBeneficiaryDocumentError
                )}
              />
              <LabeledTextInput
                className="large"
                placeholder="Razão social ou nome"
                hasMandatoryStyle={{
                  default: { marginLeft: "158px" },
                  onFocus: { marginLeft: "105px" },
                }}
                setData={(setInputString) => {
                  if (
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_name &&
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_name.length > 0
                  ) {
                    setInputString(
                      submitObjectRef.current.payment_information_attributes
                        .beneficiary_name
                    );
                  } else if (attachedPaymentSlipsRef.current.length > 0) {
                    submitObjectRef.current.payment_information_attributes.beneficiary_name =
                      attachedPaymentSlipsRef.current[0].payer_name;

                    setInputString(
                      submitObjectRef.current.payment_information_attributes
                        .beneficiary_name
                    );
                  }
                }}
                onChange={(inputValue) => {
                  submitObjectRef.current.payment_information_attributes.beneficiary_name =
                    inputValue;
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value;
                  setInputString(value);
                }}
                onError={errorMessageHandler(shouldShowBeneficiaryNameError)}
              />
            </div>
          </>
        );
      case "TED":
        return (
          <>
            <div
              className={
                shouldShowBankInfoError
                  ? "tedContainer bankInfoError"
                  : "tedContainer"
              }
            >
              <LabeledTextInput
                placeholder="Banco"
                hasMandatoryStyle={{
                  default: { marginLeft: "54px" },
                  onFocus: { marginLeft: "40px" },
                }}
                setData={(setInputString) => {
                  if (
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_bank_code
                  ) {
                    setInputString(
                      submitObjectRef.current.payment_information_attributes
                        .beneficiary_bank_code
                    );
                  }
                }}
                onChange={(inputValue) => {
                  submitObjectRef.current.payment_information_attributes.beneficiary_bank_code =
                    inputValue;
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value.replace(/\D/g, "").slice(0, 3);
                  setInputString(value.replace(/\D/g, "").slice(0, 3));
                }}
                // onError={{
                //   hasError: shouldShowDescriptionError,
                //   message: "Campo obrigatório",
                // }}
              />
              <LabeledTextInput
                placeholder="Agência"
                hasMandatoryStyle={{
                  default: { marginLeft: "66px" },
                  onFocus: { marginLeft: "48px" },
                }}
                setData={(setInputString) => {
                  if (
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_bank_agency
                  ) {
                    setInputString(
                      submitObjectRef.current.payment_information_attributes
                        .beneficiary_bank_agency
                    );
                  }
                }}
                onChange={(inputValue) => {
                  submitObjectRef.current.payment_information_attributes.beneficiary_bank_agency =
                    inputValue;
                  // console.log(
                  //   "all stuff: ",
                  //   submitObjectRef.current,
                  //   ", agency: ",
                  //   submitObjectRef.current.payment_information_attributes,
                  //   submitObjectRef.current.payment_information_attributes
                  //     .beneficiary_bank_agency
                  // );
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value.replace(/\D/g, "").slice(0, 4);
                  setInputString(value.replace(/\D/g, "").slice(0, 4));
                }}
                // onError={{
                //   hasError: shouldShowDescriptionError,
                //   message: "Campo obrigatório",
                // }}
              />
              <div className="hifen">
                <span>-</span>
              </div>
              <LabeledTextInput
                className="verificationDigit"
                hasMandatoryStyle={{
                  default: { marginLeft: "32px" },
                  onFocus: { marginLeft: "26px" },
                }}
                placeholder="DV"
                setData={(setInputString) => {
                  if (
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_bank_agency_digit
                  ) {
                    setInputString(
                      submitObjectRef.current.payment_information_attributes
                        .beneficiary_bank_agency_digit
                    );
                  }
                }}
                onChange={(inputValue) => {
                  submitObjectRef.current.payment_information_attributes.beneficiary_bank_agency_digit =
                    inputValue;
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value.replace(/\D/g, "").slice(0, 1);
                  setInputString(value.replace(/\D/g, "").slice(0, 1));
                }}
                // onError={{
                //   hasError: shouldShowDescriptionError,
                //   message: "Campo obrigatório",
                // }}
              />
              <LabeledTextInput
                placeholder="Conta"
                hasMandatoryStyle={{
                  default: { marginLeft: "53px" },
                  onFocus: { marginLeft: "39px" },
                }}
                setData={(setInputString) => {
                  if (
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_bank_account_number
                  ) {
                    setInputString(
                      submitObjectRef.current.payment_information_attributes
                        .beneficiary_bank_account_number
                    );
                  }
                }}
                onChange={(inputValue) => {
                  submitObjectRef.current.payment_information_attributes.beneficiary_bank_account_number =
                    inputValue;
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value.replace(/\D/g, "").slice(0, 11);
                  setInputString(value.replace(/\D/g, "").slice(0, 11));
                }}
                // onError={{
                //   hasError: shouldShowDescriptionError,
                //   message: "Campo obrigatório",
                // }}
              />
              <div className="hifen">
                <span>-</span>
              </div>
              <LabeledTextInput
                className="verificationDigit"
                placeholder="DV"
                hasMandatoryStyle={{
                  default: { marginLeft: "32px" },
                  onFocus: { marginLeft: "26px" },
                }}
                setData={(setInputString) => {
                  if (
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_bank_account_digit
                  ) {
                    setInputString(
                      submitObjectRef.current.payment_information_attributes
                        .beneficiary_bank_account_digit
                    );
                  }
                }}
                onChange={(inputValue) => {
                  submitObjectRef.current.payment_information_attributes.beneficiary_bank_account_digit =
                    inputValue;
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value.replace(/\D/g, "").slice(0, 1);
                  setInputString(value.replace(/\D/g, "").slice(0, 1));
                }}
                // onError={{
                //   hasError: shouldShowDescriptionError,
                //   message: "Campo obrigatório",
                // }}
              />
            </div>
            <p className="errorMessage">Campos obrigatórios</p>

            <div
              className="tedContainerComplement"
              style={{ marginTop: "12px", display: "flex" }}
            >
              <LabeledTextInput
                placeholder="CPF ou CNPJ"
                hasMandatoryStyle={{
                  default: { marginLeft: "103px" },
                  onFocus: { marginLeft: "70px" },
                }}
                setData={(setInputString) => {
                  if (
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_document_number &&
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_document_number.length > 0
                  ) {
                    const temp = submitObjectRef.current
                      .payment_information_attributes
                      .beneficiary_document_number as string;

                    if (temp.length > 11) {
                      setInputString(
                        cnpjMask(
                          submitObjectRef.current.payment_information_attributes
                            .beneficiary_document_number
                        )
                      );
                    } else {
                      setInputString(
                        cpfMask(
                          submitObjectRef.current.payment_information_attributes
                            .beneficiary_document_number
                        )
                      );
                    }
                  } else if (attachedPaymentSlipsRef.current.length > 0) {
                    submitObjectRef.current.payment_information_attributes.beneficiary_document_number =
                      attachedPaymentSlipsRef.current[0].payer_document;
                    setInputString(
                      submitObjectRef.current.payment_information_attributes
                        .beneficiary_document_number
                    );
                  }
                }}
                onChange={(inputValue) => {
                  submitObjectRef.current.payment_information_attributes.beneficiary_document_number =
                    inputValue.slice(0, 14);
                }}
                customFilter={(inputRef, setInputString) => {
                  let value = inputRef.value;
                  value = value.replace(/\D/g, "").slice(0, 14);

                  if (value.length === 11) {
                    value = value.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{2})/,
                      "$1.$2.$3-$4"
                    );
                  } else if (value.length === 14) {
                    value = value.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      "$1.$2.$3/$4-$5"
                    );
                  }

                  inputRef.value = value;
                  setInputString(value);
                }}
                onError={errorMessageHandler(
                  shouldShowBeneficiaryDocumentError
                )}
              />
              <LabeledTextInput
                className="large"
                placeholder="Razão social ou nome"
                hasMandatoryStyle={{
                  default: { marginLeft: "158px" },
                  onFocus: { marginLeft: "105px" },
                }}
                setData={(setInputString) => {
                  if (
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_name &&
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_name.length > 0
                  ) {
                    setInputString(
                      submitObjectRef.current.payment_information_attributes
                        .beneficiary_name
                    );
                  } else if (attachedPaymentSlipsRef.current.length > 0) {
                    submitObjectRef.current.payment_information_attributes.beneficiary_name =
                      attachedPaymentSlipsRef.current[0].payer_name;

                    setInputString(
                      submitObjectRef.current.payment_information_attributes
                        .beneficiary_name
                    );
                  }
                }}
                onChange={(inputValue) => {
                  submitObjectRef.current.payment_information_attributes.beneficiary_name =
                    inputValue;
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value;
                  setInputString(value);
                }}
                onError={errorMessageHandler(shouldShowBeneficiaryNameError)}
              />
            </div>
          </>
        );

      case "PIX - transferência":
        if (!submitObjectRef.current.payment_information_attributes.pix_type)
          submitObjectRef.current.payment_information_attributes.pix_type =
            "key";
        return (
          <>
            <div style={{ width: "400px" }} />
            <div style={{ width: "280px", marginTop: "12px" }}>
              <h4 style={{ marginBottom: "3px" }}>Tipo de pix</h4>
              <div className="pixTypeContainer">
                <Radio
                  id="PIX-key"
                  isActive={pixType === "key" ? "active" : "inactive"}
                  onClick={() => {
                    submitObjectRef.current.payment_information_attributes.pix_type =
                      "key";
                    handlePaymentInformationByPaymentTypeChange(
                      "PIX - transferência"
                    );
                    setPixType("key");
                  }}
                />
                &nbsp;<label htmlFor="PIX-key">Chave</label>
                <Radio
                  id="PIX-bankInfo"
                  isActive={pixType === "bankInfo" ? "active" : "inactive"}
                  onClick={() => {
                    submitObjectRef.current.payment_information_attributes.pix_type =
                      "bank_info";
                    handlePaymentInformationByPaymentTypeChange(
                      "PIX - transferência"
                    );
                    setPixType("bankInfo");
                  }}
                />
                &nbsp;<label htmlFor="PIX-bankInfo">Dados bancários</label>
              </div>
            </div>
            {pixType === "bankInfo" && (
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <div
                  style={{
                    marginLeft: "12px",
                    border: shouldShowBankInfoError
                      ? "1px solid var(--color-aux-danger-700)"
                      : "",
                  }}
                  className={
                    shouldShowBankInfoError
                      ? "tedContainer bankInfoError"
                      : "tedContainer"
                  }
                >
                  <LabeledTextInput
                    placeholder="Banco"
                    hasMandatoryStyle={{
                      default: { marginLeft: "54px" },
                      onFocus: { marginLeft: "40px" },
                    }}
                    setData={(setInputString) => {
                      if (
                        submitObjectRef.current.payment_information_attributes
                          .beneficiary_bank_code
                      ) {
                        setInputString(
                          submitObjectRef.current.payment_information_attributes
                            .beneficiary_bank_code
                        );
                      }
                    }}
                    onChange={(inputValue) => {
                      submitObjectRef.current.payment_information_attributes.beneficiary_bank_code =
                        inputValue;
                    }}
                    customFilter={(inputRef, setInputString) => {
                      const value = inputRef.value;
                      inputRef.value = value.replace(/\D/g, "").slice(0, 3);
                      setInputString(value.replace(/\D/g, "").slice(0, 3));
                    }}
                    // onError={{
                    //   hasError: shouldShowDescriptionError,
                    //   message: "Campo obrigatório",
                    // }}
                  />
                  <LabeledTextInput
                    placeholder="Agência"
                    hasMandatoryStyle={{
                      default: { marginLeft: "66px" },
                      onFocus: { marginLeft: "48px" },
                    }}
                    setData={(setInputString) => {
                      if (
                        submitObjectRef.current.payment_information_attributes
                          .beneficiary_bank_agency
                      ) {
                        setInputString(
                          submitObjectRef.current.payment_information_attributes
                            .beneficiary_bank_agency
                        );
                      }
                    }}
                    onChange={(inputValue) => {
                      submitObjectRef.current.payment_information_attributes.beneficiary_bank_agency =
                        inputValue;
                    }}
                    customFilter={(inputRef, setInputString) => {
                      const value = inputRef.value;
                      inputRef.value = value.replace(/\D/g, "").slice(0, 4);
                      setInputString(value.replace(/\D/g, "").slice(0, 4));
                    }}
                    // onError={{
                    //   hasError: shouldShowDescriptionError,
                    //   message: "Campo obrigatório",
                    // }}
                  />
                  <div className="hifen">
                    <span>-</span>
                  </div>
                  <LabeledTextInput
                    className="verificationDigit"
                    hasMandatoryStyle={{
                      default: { marginLeft: "32px" },
                      onFocus: { marginLeft: "26px" },
                    }}
                    placeholder="DV"
                    setData={(setInputString) => {
                      if (
                        submitObjectRef.current.payment_information_attributes
                          .beneficiary_bank_agency_digit
                      ) {
                        setInputString(
                          submitObjectRef.current.payment_information_attributes
                            .beneficiary_bank_agency_digit
                        );
                      }
                    }}
                    onChange={(inputValue) => {
                      submitObjectRef.current.payment_information_attributes.beneficiary_bank_agency_digit =
                        inputValue;
                    }}
                    customFilter={(inputRef, setInputString) => {
                      const value = inputRef.value;
                      inputRef.value = value.replace(/\D/g, "").slice(0, 1);
                      setInputString(value.replace(/\D/g, "").slice(0, 1));
                    }}
                    // onError={{
                    //   hasError: shouldShowDescriptionError,
                    //   message: "Campo obrigatório",
                    // }}
                  />
                  <LabeledTextInput
                    placeholder="Conta"
                    hasMandatoryStyle={{
                      default: { marginLeft: "53px" },
                      onFocus: { marginLeft: "39px" },
                    }}
                    setData={(setInputString) => {
                      if (
                        submitObjectRef.current.payment_information_attributes
                          .beneficiary_bank_account_number
                      ) {
                        setInputString(
                          submitObjectRef.current.payment_information_attributes
                            .beneficiary_bank_account_number
                        );
                      }
                    }}
                    onChange={(inputValue) => {
                      submitObjectRef.current.payment_information_attributes.beneficiary_bank_account_number =
                        inputValue;
                    }}
                    customFilter={(inputRef, setInputString) => {
                      const value = inputRef.value;
                      inputRef.value = value.replace(/\D/g, "").slice(0, 11);
                      setInputString(value.replace(/\D/g, "").slice(0, 11));
                    }}
                    // onError={{
                    //   hasError: shouldShowDescriptionError,
                    //   message: "Campo obrigatório",
                    // }}
                  />
                  <div className="hifen">
                    <span>-</span>
                  </div>
                  <LabeledTextInput
                    className="verificationDigit"
                    placeholder="DV"
                    hasMandatoryStyle={{
                      default: { marginLeft: "32px" },
                      onFocus: { marginLeft: "26px" },
                    }}
                    setData={(setInputString) => {
                      if (
                        submitObjectRef.current.payment_information_attributes
                          .beneficiary_bank_account_digit
                      ) {
                        setInputString(
                          submitObjectRef.current.payment_information_attributes
                            .beneficiary_bank_account_digit
                        );
                      }
                    }}
                    onChange={(inputValue) => {
                      submitObjectRef.current.payment_information_attributes.beneficiary_bank_account_digit =
                        inputValue;
                    }}
                    customFilter={(inputRef, setInputString) => {
                      const value = inputRef.value;
                      inputRef.value = value.replace(/\D/g, "").slice(0, 1);
                      setInputString(value.replace(/\D/g, "").slice(0, 1));
                    }}
                    // onError={{
                    //   hasError: shouldShowDescriptionError,
                    //   message: "Campo obrigatório",
                    // }}
                  />
                </div>
                <p className="errorMessage">Campos obrigatórios</p>
              </div>
            )}
            {pixType === "key" && (
              <>
                <div style={{ width: "400px" }} />
                <Dropdown
                  button={{
                    variant: "labeled",
                    labels: [""],
                    placeholder: "Tipo de chave",
                    // onError: {
                    //   hasError: shouldShowCategoryIdError,
                    //   message: "Campo obrigatório",
                    // },
                    setData(setSelectedData) {
                      if (
                        submitObjectRef.current.payment_information_attributes
                          .pix_key_type
                      ) {
                        setSelectedData(
                          submitObjectRef.current.payment_information_attributes
                            .pix_key_type
                        );
                      } else {
                        submitObjectRef.current.payment_information_attributes.pix_key_type =
                          "Telefone";
                        setSelectedData("Telefone");
                      }
                    },
                  }}
                  menu={{
                    variant: "unlabeled",
                    options: ["Telefone", "E-mail", "CPF/CNPJ", "Aleatória"],
                    search: false,
                    onSelect(selected) {
                      submitObjectRef.current.payment_information_attributes.pix_key =
                        undefined;
                      submitObjectRef.current.payment_information_attributes.pix_key_type =
                        selected as TPIXKeyType;
                      setPixKeyType(selected as TPIXKeyType);
                    },
                  }}
                />
                <LabeledTextInput
                  key={pixKeyType}
                  className="large"
                  placeholder="Chave PIX"
                  hasMandatoryStyle={{
                    default: { marginLeft: "82px" },
                    onFocus: { marginLeft: "57px" },
                  }}
                  setData={(setInputString) => {
                    if (
                      submitObjectRef.current.payment_information_attributes
                        .pix_key
                    ) {
                      setInputString(
                        submitObjectRef.current.payment_information_attributes
                          .pix_key
                      );
                    } else {
                      setInputString("");
                    }
                  }}
                  onChange={(inputValue) => {
                    submitObjectRef.current.payment_information_attributes.pix_key =
                      inputValue;
                  }}
                  customFilter={(inputRef, setInputString) => {
                    let value = inputRef.value;

                    switch (
                      submitObjectRef.current.payment_information_attributes
                        .pix_key_type as TPIXKeyType
                    ) {
                      case "Telefone":
                        value = value.replace(/\D/g, "").slice(0, 11);
                        if (value.length === 11) {
                          value = value.replace(
                            /^(\d{2})(\d{5})(\d{4})/g,
                            "($1)$2-$3"
                          );
                        }
                        if (value.length === 10) {
                          value = value.replace(
                            /^(\d{2})(\d{4})(\d{4})/g,
                            "($1)$2-$3"
                          );
                        }
                        break;
                      case "CPF/CNPJ":
                        value = value.replace(/\D/g, "").slice(0, 14);

                        if (value.length === 11) {
                          value = value.replace(
                            /(\d{3})(\d{3})(\d{3})(\d{2})/,
                            "$1.$2.$3-$4"
                          );
                        } else if (value.length === 14) {
                          value = value.replace(
                            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                            "$1.$2.$3/$4-$5"
                          );
                        }
                        break;
                      case "E-mail":
                      case "Aleat\u00F3ria":
                    }
                    inputRef.value = value;
                    setInputString(value);
                  }}
                  onError={errorMessageHandler(shouldShowPixKeyError)}
                />
              </>
            )}
            <div
              className="tedContainerComplement"
              style={{ marginTop: "12px", display: "flex" }}
            >
              <LabeledTextInput
                placeholder="CPF ou CNPJ"
                hasMandatoryStyle={{
                  default: { marginLeft: "103px" },
                  onFocus: { marginLeft: "70px" },
                }}
                setData={(setInputString) => {
                  if (
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_document_number &&
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_document_number.length > 0
                  ) {
                    const temp = submitObjectRef.current
                      .payment_information_attributes
                      .beneficiary_document_number as string;

                    if (temp.length > 11) {
                      setInputString(
                        cnpjMask(
                          submitObjectRef.current.payment_information_attributes
                            .beneficiary_document_number
                        )
                      );
                    } else {
                      setInputString(
                        cpfMask(
                          submitObjectRef.current.payment_information_attributes
                            .beneficiary_document_number
                        )
                      );
                    }
                  } else if (attachedPaymentSlipsRef.current.length > 0) {
                    submitObjectRef.current.payment_information_attributes.beneficiary_document_number =
                      attachedPaymentSlipsRef.current[0].payer_document;
                    setInputString(
                      submitObjectRef.current.payment_information_attributes
                        .beneficiary_document_number
                    );
                  }
                }}
                onChange={(inputValue) => {
                  submitObjectRef.current.payment_information_attributes.beneficiary_document_number =
                    inputValue.slice(0, 14);
                }}
                customFilter={(inputRef, setInputString) => {
                  let value = inputRef.value;
                  value = value.replace(/\D/g, "").slice(0, 14);

                  if (value.length === 11) {
                    value = value.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{2})/,
                      "$1.$2.$3-$4"
                    );
                  } else if (value.length === 14) {
                    value = value.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      "$1.$2.$3/$4-$5"
                    );
                  }

                  inputRef.value = value;
                  setInputString(value);
                }}
                onError={errorMessageHandler(
                  shouldShowBeneficiaryDocumentError
                )}
              />
              <LabeledTextInput
                className="large"
                placeholder="Razão social ou nome"
                hasMandatoryStyle={{
                  default: { marginLeft: "158px" },
                  onFocus: { marginLeft: "105px" },
                }}
                setData={(setInputString) => {
                  if (
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_name &&
                    submitObjectRef.current.payment_information_attributes
                      .beneficiary_name.length > 0
                  ) {
                    setInputString(
                      submitObjectRef.current.payment_information_attributes
                        .beneficiary_name
                    );
                  } else if (attachedPaymentSlipsRef.current.length > 0) {
                    submitObjectRef.current.payment_information_attributes.beneficiary_name =
                      attachedPaymentSlipsRef.current[0].payer_name;

                    setInputString(
                      submitObjectRef.current.payment_information_attributes
                        .beneficiary_name
                    );
                  }
                }}
                onChange={(inputValue) => {
                  submitObjectRef.current.payment_information_attributes.beneficiary_name =
                    inputValue;
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value;
                  setInputString(value);
                }}
                onError={errorMessageHandler(shouldShowBeneficiaryNameError)}
              />
            </div>
          </>
        );
      case "PIX - pagamento":
        return (
          <LabeledTextInput
            placeholder="QR Code"
            hasMandatoryStyle={{
              default: { marginLeft: "72px" },
              onFocus: { marginLeft: "51px" },
            }}
            setData={(setInputString) => {
              if (
                submitObjectRef.current.payment_information_attributes.qr_code
              ) {
                setInputString(
                  submitObjectRef.current.payment_information_attributes.qr_code
                );
              }
            }}
            onChange={(inputValue) => {
              submitObjectRef.current.payment_information_attributes.qr_code =
                inputValue;
            }}
            customFilter={(inputRef, setInputString) => {
              const value = inputRef.value;
              inputRef.value = value;
              setInputString(value);
            }}
            onError={errorMessageHandler(shouldShowQRCodeError)}
          />
        );
      default:
        return <></>;
    }
  };

  const handleSimpleSliderPaymentFormComplement = (
    index: number,
    payForm: TPaymentFormVariants
  ) => {
    // console.log(
    //   "from handle payment complement, temp ref: ",
    //   installmentPaymentInfosRef.current,
    //   ", index: ",
    //   index,
    //   ", payform: ",
    //   payForm
    // );

    if (
      attachedPaymentSlipsRef.current.length > 0 &&
      installmentPaymentInfosRef.current.length > 0
    ) {
      installmentPaymentInfosRef.current.map((value, i) => {
        const temp = {
          ...value.data,
          beneficiary_name: attachedPaymentSlipsRef.current[0].payer_name,
          beneficiary_document_number:
            attachedPaymentSlipsRef.current[0].payer_document,
        };

        installmentPaymentInfosRef.current[i].data = temp;
      });

      // console.log(
      //   "installment payment infos ref: ",
      //   installmentPaymentInfosRef.current,
      //   ", index: ",
      //   index
      // );

      const temp = {
        ...installmentPaymentInfosRef.current[index].data,
        barcode:
          payForm === "Boleto" && attachedPaymentSlipsRef.current[index]
            ? attachedPaymentSlipsRef.current[index].bar_code
            : installmentPaymentInfosRef.current[index] &&
              installmentPaymentInfosRef.current[index].data.barcode
            ? installmentPaymentInfosRef.current[index].data.barcode
            : undefined,
      };
      installmentPaymentInfosRef.current[index].data = temp;
    }

    switch (payForm) {
      case "Boleto":
        return (
          <>
            <LabeledTextInput
              placeholder="Linha digitável"
              hasMandatoryStyle={{
                default: { marginLeft: "110px" },
                onFocus: { marginLeft: "75px" },
              }}
              setData={(setInputString) => {
                // console.log(
                //   "setting numeric code line, temp ref: ",
                //   installmentPaymentInfosRef.current[index].data.barcode,
                //   ", index: ",
                //   index
                // );
                if (installmentPaymentInfosRef.current[index].data.barcode) {
                  setInputString(
                    installmentPaymentInfosRef.current[index].data
                      .barcode as string
                  );
                }
              }}
              onChange={(inputValue) => {
                const temp = [...installmentPaymentInfosRef.current];

                const tempData = {
                  ...installmentPaymentInfosRef.current[index].data,
                };
                tempData.barcode = inputValue;

                temp[index] = {
                  edited: index !== 0,
                  payMethod:
                    installmentPaymentInfosRef.current[index].payMethod,
                  data: tempData,
                };

                installmentPaymentInfosRef.current = temp;
              }}
              customFilter={(inputRef, setInputString) => {
                const value = inputRef.value;
                inputRef.value = value.replace(/\D/g, "").slice(0, 47);
                setInputString(value.replace(/\D/g, "").slice(0, 47));
              }}
              onError={errorMessageHandler(
                installmentsPaymentInfoErrors[index].barcode
              )}
            />
            <div style={{ marginTop: "12px", display: "flex" }}>
              <LabeledTextInput
                placeholder="CPF ou CNPJ"
                hasMandatoryStyle={{
                  default: { marginLeft: "103px" },
                  onFocus: { marginLeft: "70px" },
                }}
                setData={(setInputString) => {
                  if (
                    installmentPaymentInfosRef.current[index].data
                      .beneficiary_document_number &&
                    (
                      installmentPaymentInfosRef.current[index].data
                        .beneficiary_document_number as string
                    ).length > 0
                  ) {
                    const temp = installmentPaymentInfosRef.current[index].data
                      .beneficiary_document_number as string;
                    if (temp.length > 11) {
                      setInputString(
                        cnpjMask(
                          installmentPaymentInfosRef.current[index].data
                            .beneficiary_document_number as string
                        )
                      );
                    } else {
                      setInputString(
                        cpfMask(
                          installmentPaymentInfosRef.current[index].data
                            .beneficiary_document_number as string
                        )
                      );
                    }
                  }
                }}
                onChange={(inputValue) => {
                  handleSimpleSliderPaymentFormComplementOnChange(
                    index,
                    "beneficiary_document_number",
                    inputValue.replace(/\D/g, "").slice(0, 14)
                  );
                }}
                customFilter={(inputRef, setInputString) => {
                  let value = inputRef.value;
                  value = value.replace(/\D/g, "").slice(0, 14);

                  if (value.length === 11) {
                    value = value.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{2})/,
                      "$1.$2.$3-$4"
                    );
                  } else if (value.length === 14) {
                    value = value.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      "$1.$2.$3/$4-$5"
                    );
                  }

                  inputRef.value = value;
                  setInputString(value);
                }}
                onError={errorMessageHandler(
                  installmentsPaymentInfoErrors[index].beneficiaryDocument
                )}
              />
              <LabeledTextInput
                className="large"
                placeholder="Razão social ou nome"
                hasMandatoryStyle={{
                  default: { marginLeft: "158px" },
                  onFocus: { marginLeft: "105px" },
                }}
                setData={(setInputString) => {
                  if (
                    installmentPaymentInfosRef.current[index].data
                      .beneficiary_name &&
                    (
                      installmentPaymentInfosRef.current[index].data
                        .beneficiary_name as string
                    ).length > 0
                  ) {
                    setInputString(
                      installmentPaymentInfosRef.current[index].data
                        .beneficiary_name as string
                    );
                  }
                }}
                onChange={(inputValue) => {
                  handleSimpleSliderPaymentFormComplementOnChange(
                    index,
                    "beneficiary_name",
                    inputValue
                  );
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value;
                  setInputString(value);
                }}
                onError={errorMessageHandler(
                  installmentsPaymentInfoErrors[index].beneficiaryName
                )}
              />
            </div>
          </>
        );
      case "TED":
        return (
          <>
            <div
              className={
                installmentsPaymentInfoErrors[index].bankInfo
                  ? "tedContainer bankInfoError"
                  : "tedContainer"
              }
            >
              <LabeledTextInput
                placeholder="Banco"
                hasMandatoryStyle={{
                  default: { marginLeft: "54px" },
                  onFocus: { marginLeft: "40px" },
                }}
                setData={(setInputString) => {
                  if (
                    installmentPaymentInfosRef.current[index].data
                      .beneficiary_bank_code
                  ) {
                    setInputString(
                      installmentPaymentInfosRef.current[index].data
                        .beneficiary_bank_code as string
                    );
                  }
                }}
                onChange={(inputValue) => {
                  handleSimpleSliderPaymentFormComplementOnChange(
                    index,
                    "beneficiary_bank_code",
                    inputValue
                  );
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value.replace(/\D/g, "").slice(0, 3);
                  setInputString(value.replace(/\D/g, "").slice(0, 3));
                }}
                // onError={{
                //   hasError: shouldShowDescriptionError,
                //   message: "Campo obrigatório",
                // }}
              />
              <LabeledTextInput
                placeholder="Agência"
                hasMandatoryStyle={{
                  default: { marginLeft: "66px" },
                  onFocus: { marginLeft: "48px" },
                }}
                setData={(setInputString) => {
                  if (
                    installmentPaymentInfosRef.current[index].data
                      .beneficiary_bank_agency
                  ) {
                    setInputString(
                      installmentPaymentInfosRef.current[index].data
                        .beneficiary_bank_agency as string
                    );
                  }
                }}
                onChange={(inputValue) => {
                  handleSimpleSliderPaymentFormComplementOnChange(
                    index,
                    "beneficiary_bank_agency",
                    inputValue
                  );

                  // console.log(
                  //   "all stuff: ",
                  //   submitObjectRef.current,
                  //   ", agency: ",
                  //   submitObjectRef.current.payment_information_attributes,
                  //   submitObjectRef.current.payment_information_attributes
                  //     .beneficiary_bank_agency
                  // );
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value.replace(/\D/g, "").slice(0, 4);
                  setInputString(value.replace(/\D/g, "").slice(0, 4));
                }}
                // onError={{
                //   hasError: shouldShowDescriptionError,
                //   message: "Campo obrigatório",
                // }}
              />
              <div className="hifen">
                <span>-</span>
              </div>
              <LabeledTextInput
                className="verificationDigit"
                hasMandatoryStyle={{
                  default: { marginLeft: "32px" },
                  onFocus: { marginLeft: "26px" },
                }}
                placeholder="DV"
                setData={(setInputString) => {
                  if (
                    installmentPaymentInfosRef.current[index].data
                      .beneficiary_bank_agency_digit
                  ) {
                    setInputString(
                      installmentPaymentInfosRef.current[index].data
                        .beneficiary_bank_agency_digit as string
                    );
                  }
                }}
                onChange={(inputValue) => {
                  handleSimpleSliderPaymentFormComplementOnChange(
                    index,
                    "beneficiary_bank_agency_digit",
                    inputValue
                  );
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value.replace(/\D/g, "").slice(0, 1);
                  setInputString(value.replace(/\D/g, "").slice(0, 1));
                }}
                // onError={{
                //   hasError: shouldShowDescriptionError,
                //   message: "Campo obrigatório",
                // }}
              />
              <LabeledTextInput
                placeholder="Conta"
                hasMandatoryStyle={{
                  default: { marginLeft: "53px" },
                  onFocus: { marginLeft: "39px" },
                }}
                setData={(setInputString) => {
                  if (
                    installmentPaymentInfosRef.current[index].data
                      .beneficiary_bank_account_number
                  ) {
                    setInputString(
                      installmentPaymentInfosRef.current[index].data
                        .beneficiary_bank_account_number as string
                    );
                  }
                }}
                onChange={(inputValue) => {
                  handleSimpleSliderPaymentFormComplementOnChange(
                    index,
                    "beneficiary_bank_account_number",
                    inputValue
                  );
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value.replace(/\D/g, "").slice(0, 11);
                  setInputString(value.replace(/\D/g, "").slice(0, 11));
                }}
                // onError={{
                //   hasError: shouldShowDescriptionError,
                //   message: "Campo obrigatório",
                // }}
              />
              <div className="hifen">
                <span>-</span>
              </div>
              <LabeledTextInput
                className="verificationDigit"
                placeholder="DV"
                hasMandatoryStyle={{
                  default: { marginLeft: "32px" },
                  onFocus: { marginLeft: "26px" },
                }}
                setData={(setInputString) => {
                  if (
                    installmentPaymentInfosRef.current[index].data
                      .beneficiary_bank_account_digit
                  ) {
                    setInputString(
                      installmentPaymentInfosRef.current[index].data
                        .beneficiary_bank_account_digit as string
                    );
                  }
                }}
                onChange={(inputValue) => {
                  handleSimpleSliderPaymentFormComplementOnChange(
                    index,
                    "beneficiary_bank_account_digit",
                    inputValue
                  );
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value.replace(/\D/g, "").slice(0, 1);
                  setInputString(value.replace(/\D/g, "").slice(0, 1));
                }}
                // onError={{
                //   hasError: shouldShowDescriptionError,
                //   message: "Campo obrigatório",
                // }}
              />
            </div>
            <p className="errorMessage">Campos obrigatórios</p>

            <div
              className="tedContainerComplement"
              style={{ marginTop: "12px", display: "flex" }}
            >
              <LabeledTextInput
                placeholder="CPF ou CNPJ"
                hasMandatoryStyle={{
                  default: { marginLeft: "103px" },
                  onFocus: { marginLeft: "70px" },
                }}
                setData={(setInputString) => {
                  if (
                    installmentPaymentInfosRef.current[index].data
                      .beneficiary_document_number &&
                    (
                      installmentPaymentInfosRef.current[index].data
                        .beneficiary_document_number as string
                    ).length > 0
                  ) {
                    const temp = installmentPaymentInfosRef.current[index].data
                      .beneficiary_document_number as string;
                    if (temp.length > 11) {
                      setInputString(
                        cnpjMask(
                          installmentPaymentInfosRef.current[index].data
                            .beneficiary_document_number as string
                        )
                      );
                    } else {
                      setInputString(
                        cpfMask(
                          installmentPaymentInfosRef.current[index].data
                            .beneficiary_document_number as string
                        )
                      );
                    }
                  }
                }}
                onChange={(inputValue) => {
                  handleSimpleSliderPaymentFormComplementOnChange(
                    index,
                    "beneficiary_document_number",
                    inputValue.slice(0, 14)
                  );
                }}
                customFilter={(inputRef, setInputString) => {
                  let value = inputRef.value;
                  value = value.replace(/\D/g, "").slice(0, 14);

                  if (value.length === 11) {
                    value = value.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{2})/,
                      "$1.$2.$3-$4"
                    );
                  } else if (value.length === 14) {
                    value = value.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      "$1.$2.$3/$4-$5"
                    );
                  }

                  inputRef.value = value;
                  setInputString(value);
                }}
                onError={errorMessageHandler(
                  installmentsPaymentInfoErrors[index].beneficiaryDocument
                )}
              />
              <LabeledTextInput
                className="large"
                placeholder="Razão social ou nome"
                hasMandatoryStyle={{
                  default: { marginLeft: "158px" },
                  onFocus: { marginLeft: "105px" },
                }}
                setData={(setInputString) => {
                  if (
                    installmentPaymentInfosRef.current[index].data
                      .beneficiary_name &&
                    (
                      installmentPaymentInfosRef.current[index].data
                        .beneficiary_name as string
                    ).length > 0
                  ) {
                    setInputString(
                      installmentPaymentInfosRef.current[index].data
                        .beneficiary_name as string
                    );
                  }
                }}
                onChange={(inputValue) => {
                  handleSimpleSliderPaymentFormComplementOnChange(
                    index,
                    "beneficiary_name",
                    inputValue
                  );
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value;
                  setInputString(value);
                }}
                onError={errorMessageHandler(
                  installmentsPaymentInfoErrors[index].beneficiaryName
                )}
              />
            </div>
          </>
        );

      case "PIX - transferência":
        if (!installmentPaymentInfosRef.current[index].data.pix_type) {
          installmentPaymentInfosRef.current[index].data.pix_type = "key";

          setTimeout(() => {
            setSimpleSliderPixType((before) => {
              let temp =
                before.length > 0
                  ? [...before]
                  : (Array.from({ length: installments }, () => "key") as (
                      | "key"
                      | "bankInfo"
                    )[]);

              if (index === 0) {
                temp = temp.map((_) => "key");
              } else {
                temp[index] = "key";
              }

              return temp;
            });
          }, 100);
        }

        return (
          <>
            <div style={{ width: "400px" }} />
            <div style={{ width: "280px", marginTop: "12px" }}>
              <h4 style={{ marginBottom: "3px" }}>Tipo de pix</h4>
              <div className="pixTypeContainer">
                <Radio
                  id="PIX-key"
                  isActive={
                    simpleSliderPixType[index] === "key" ? "active" : "inactive"
                  }
                  onClick={() => {
                    installmentPaymentInfosRef.current[index].data.pix_type =
                      "key";
                    handleSimpleSliderPaymentInformationByPaymentTypeChange(
                      "PIX - transferência",
                      index
                    );
                    setSimpleSliderPixType((before) => {
                      let temp =
                        before.length > 0
                          ? [...before]
                          : (Array.from(
                              { length: installments },
                              () => "key"
                            ) as ("key" | "bankInfo")[]);

                      if (index === 0) {
                        temp = temp.map((_) => "key");
                      } else {
                        temp[index] = "key";
                      }
                      return temp;
                    });
                  }}
                />
                &nbsp;<label htmlFor="PIX-key">Chave</label>
                <Radio
                  id="PIX-bankInfo"
                  isActive={
                    simpleSliderPixType[index] === "bankInfo"
                      ? "active"
                      : "inactive"
                  }
                  onClick={() => {
                    installmentPaymentInfosRef.current[index].data.pix_type =
                      "bank_info";
                    handleSimpleSliderPaymentInformationByPaymentTypeChange(
                      "PIX - transferência",
                      index
                    );
                    setSimpleSliderPixType((before) => {
                      let temp =
                        before.length > 0
                          ? [...before]
                          : (Array.from(
                              { length: installments },
                              () => "bankInfo"
                            ) as ("key" | "bankInfo")[]);

                      if (index === 0) {
                        temp = temp.map((_) => "bankInfo");
                      } else {
                        temp[index] = "bankInfo";
                      }
                      return temp;
                    });
                  }}
                />
                &nbsp;<label htmlFor="PIX-bankInfo">Dados bancários</label>
              </div>
            </div>
            {simpleSliderPixType[index] === "bankInfo" && (
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <div
                  style={{
                    marginLeft: "12px",
                    border: installmentsPaymentInfoErrors[index].bankInfo
                      ? "1px solid var(--color-aux-danger-700)"
                      : "",
                  }}
                  className={
                    installmentsPaymentInfoErrors[index].bankInfo
                      ? "tedContainer bankInfoError"
                      : "tedContainer"
                  }
                >
                  <LabeledTextInput
                    placeholder="Banco"
                    hasMandatoryStyle={{
                      default: { marginLeft: "54px" },
                      onFocus: { marginLeft: "40px" },
                    }}
                    setData={(setInputString) => {
                      if (
                        installmentPaymentInfosRef.current[index].data
                          .beneficiary_bank_code
                      ) {
                        setInputString(
                          installmentPaymentInfosRef.current[index].data
                            .beneficiary_bank_code as string
                        );
                      }
                    }}
                    onChange={(inputValue) => {
                      handleSimpleSliderPaymentFormComplementOnChange(
                        index,
                        "beneficiary_bank_code",
                        inputValue
                      );
                    }}
                    customFilter={(inputRef, setInputString) => {
                      const value = inputRef.value;
                      inputRef.value = value.replace(/\D/g, "").slice(0, 3);
                      setInputString(value.replace(/\D/g, "").slice(0, 3));
                    }}
                    // onError={{
                    //   hasError: shouldShowDescriptionError,
                    //   message: "Campo obrigatório",
                    // }}
                  />
                  <LabeledTextInput
                    placeholder="Agência"
                    hasMandatoryStyle={{
                      default: { marginLeft: "66px" },
                      onFocus: { marginLeft: "48px" },
                    }}
                    setData={(setInputString) => {
                      if (
                        installmentPaymentInfosRef.current[index].data
                          .beneficiary_bank_agency
                      ) {
                        setInputString(
                          installmentPaymentInfosRef.current[index].data
                            .beneficiary_bank_agency as string
                        );
                      }
                    }}
                    onChange={(inputValue) => {
                      handleSimpleSliderPaymentFormComplementOnChange(
                        index,
                        "beneficiary_bank_agency",
                        inputValue
                      );
                    }}
                    customFilter={(inputRef, setInputString) => {
                      const value = inputRef.value;
                      inputRef.value = value.replace(/\D/g, "").slice(0, 4);
                      setInputString(value.replace(/\D/g, "").slice(0, 4));
                    }}
                    // onError={{
                    //   hasError: shouldShowDescriptionError,
                    //   message: "Campo obrigatório",
                    // }}
                  />
                  <div className="hifen">
                    <span>-</span>
                  </div>
                  <LabeledTextInput
                    className="verificationDigit"
                    hasMandatoryStyle={{
                      default: { marginLeft: "32px" },
                      onFocus: { marginLeft: "26px" },
                    }}
                    placeholder="DV"
                    setData={(setInputString) => {
                      if (
                        installmentPaymentInfosRef.current[index].data
                          .beneficiary_bank_agency_digit
                      ) {
                        setInputString(
                          installmentPaymentInfosRef.current[index].data
                            .beneficiary_bank_agency_digit as string
                        );
                      }
                    }}
                    onChange={(inputValue) => {
                      handleSimpleSliderPaymentFormComplementOnChange(
                        index,
                        "beneficiary_bank_agency_digit",
                        inputValue
                      );
                    }}
                    customFilter={(inputRef, setInputString) => {
                      const value = inputRef.value;
                      inputRef.value = value.replace(/\D/g, "").slice(0, 1);
                      setInputString(value.replace(/\D/g, "").slice(0, 1));
                    }}
                    // onError={{
                    //   hasError: shouldShowDescriptionError,
                    //   message: "Campo obrigatório",
                    // }}
                  />
                  <LabeledTextInput
                    placeholder="Conta"
                    hasMandatoryStyle={{
                      default: { marginLeft: "53px" },
                      onFocus: { marginLeft: "39px" },
                    }}
                    setData={(setInputString) => {
                      if (
                        installmentPaymentInfosRef.current[index].data
                          .beneficiary_bank_account_number
                      ) {
                        setInputString(
                          installmentPaymentInfosRef.current[index].data
                            .beneficiary_bank_account_number as string
                        );
                      }
                    }}
                    onChange={(inputValue) => {
                      handleSimpleSliderPaymentFormComplementOnChange(
                        index,
                        "beneficiary_bank_account_number",
                        inputValue
                      );
                    }}
                    customFilter={(inputRef, setInputString) => {
                      const value = inputRef.value;
                      inputRef.value = value.replace(/\D/g, "").slice(0, 11);
                      setInputString(value.replace(/\D/g, "").slice(0, 11));
                    }}
                    // onError={{
                    //   hasError: shouldShowDescriptionError,
                    //   message: "Campo obrigatório",
                    // }}
                  />
                  <div className="hifen">
                    <span>-</span>
                  </div>
                  <LabeledTextInput
                    className="verificationDigit"
                    placeholder="DV"
                    hasMandatoryStyle={{
                      default: { marginLeft: "32px" },
                      onFocus: { marginLeft: "26px" },
                    }}
                    setData={(setInputString) => {
                      if (
                        installmentPaymentInfosRef.current[index].data
                          .beneficiary_bank_account_digit
                      ) {
                        setInputString(
                          installmentPaymentInfosRef.current[index].data
                            .beneficiary_bank_account_digit as string
                        );
                      }
                    }}
                    onChange={(inputValue) => {
                      handleSimpleSliderPaymentFormComplementOnChange(
                        index,
                        "beneficiary_bank_account_digit",
                        inputValue
                      );
                    }}
                    customFilter={(inputRef, setInputString) => {
                      const value = inputRef.value;
                      inputRef.value = value.replace(/\D/g, "").slice(0, 1);
                      setInputString(value.replace(/\D/g, "").slice(0, 1));
                    }}
                    // onError={{
                    //   hasError: shouldShowDescriptionError,
                    //   message: "Campo obrigatório",
                    // }}
                  />
                </div>
                <p className="errorMessage">Campos obrigatórios</p>
              </div>
            )}
            {simpleSliderPixType[index] === "key" && (
              <>
                <div style={{ width: "400px" }} />
                <Dropdown
                  button={{
                    variant: "labeled",
                    labels: [""],
                    placeholder: "Tipo de chave",
                    // onError: {
                    //   hasError: shouldShowCategoryIdError,
                    //   message: "Campo obrigatório",
                    // },
                    setData(setSelectedData) {
                      if (
                        installmentPaymentInfosRef.current[index].data
                          .pix_key_type
                      ) {
                        setSelectedData(
                          installmentPaymentInfosRef.current[index].data
                            .pix_key_type as string
                        );
                      } else {
                        installmentPaymentInfosRef.current[
                          index
                        ].data.pix_key_type = "Telefone";
                        setSelectedData("Telefone");
                      }
                    },
                  }}
                  menu={{
                    variant: "unlabeled",
                    options: ["Telefone", "E-mail", "CPF/CNPJ", "Aleatória"],
                    search: false,
                    onSelect(selected) {
                      const temp = [...installmentPaymentInfosRef.current];

                      const tempData = {
                        ...installmentPaymentInfosRef.current[index].data,
                      };
                      tempData.pix_key = undefined;
                      tempData.pix_key_type = selected as TPIXKeyType;
                      temp[index] = {
                        edited: index !== 0,
                        payMethod:
                          installmentPaymentInfosRef.current[index].payMethod,
                        data: tempData,
                      };

                      installmentPaymentInfosRef.current = temp;

                      setSimpleSliderPixKeyType((before) => {
                        const temp = [...before];
                        temp[index] = selected as TPIXKeyType;

                        return temp;
                      });
                    },
                  }}
                />
                <LabeledTextInput
                  key={simpleSliderPixKeyType[index]}
                  className="large"
                  placeholder="Chave PIX"
                  hasMandatoryStyle={{
                    default: { marginLeft: "82px" },
                    onFocus: { marginLeft: "57px" },
                  }}
                  setData={(setInputString) => {
                    if (
                      installmentPaymentInfosRef.current[index].data.pix_key
                    ) {
                      setInputString(
                        installmentPaymentInfosRef.current[index].data
                          .pix_key as string
                      );
                    } else {
                      setInputString("");
                    }
                  }}
                  onChange={(inputValue) => {
                    handleSimpleSliderPaymentFormComplementOnChange(
                      index,
                      "pix_key",
                      inputValue
                    );
                  }}
                  customFilter={(inputRef, setInputString) => {
                    let value = inputRef.value;

                    switch (
                      installmentPaymentInfosRef.current[index].data
                        .pix_key_type as TPIXKeyType
                    ) {
                      case "Telefone":
                        value = value.replace(/\D/g, "").slice(0, 11);
                        if (value.length === 11) {
                          value = value.replace(
                            /^(\d{2})(\d{5})(\d{4})/g,
                            "($1)$2-$3"
                          );
                        }
                        if (value.length === 10) {
                          value = value.replace(
                            /^(\d{2})(\d{4})(\d{4})/g,
                            "($1)$2-$3"
                          );
                        }
                        break;
                      case "CPF/CNPJ":
                        value = value.replace(/\D/g, "").slice(0, 14);

                        if (value.length === 11) {
                          value = value.replace(
                            /(\d{3})(\d{3})(\d{3})(\d{2})/,
                            "$1.$2.$3-$4"
                          );
                        } else if (value.length === 14) {
                          value = value.replace(
                            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                            "$1.$2.$3/$4-$5"
                          );
                        }
                        break;
                      case "E-mail":
                      case "Aleat\u00F3ria":
                    }
                    inputRef.value = value;
                    setInputString(value);
                  }}
                  onError={errorMessageHandler(
                    installmentsPaymentInfoErrors[index].pixKey
                  )}
                />
              </>
            )}
            <div
              className="tedContainerComplement"
              style={{ marginTop: "12px", display: "flex" }}
            >
              <LabeledTextInput
                placeholder="CPF ou CNPJ"
                hasMandatoryStyle={{
                  default: { marginLeft: "103px" },
                  onFocus: { marginLeft: "70px" },
                }}
                setData={(setInputString) => {
                  if (
                    installmentPaymentInfosRef.current[index].data
                      .beneficiary_document_number &&
                    (
                      installmentPaymentInfosRef.current[index].data
                        .beneficiary_document_number as string
                    ).length > 0
                  ) {
                    const temp = installmentPaymentInfosRef.current[index].data
                      .beneficiary_document_number as string;
                    if (temp.length > 11) {
                      setInputString(
                        cnpjMask(
                          installmentPaymentInfosRef.current[index].data
                            .beneficiary_document_number as string
                        )
                      );
                    } else {
                      setInputString(
                        cpfMask(
                          installmentPaymentInfosRef.current[index].data
                            .beneficiary_document_number as string
                        )
                      );
                    }
                  }
                }}
                onChange={(inputValue) => {
                  handleSimpleSliderPaymentFormComplementOnChange(
                    index,
                    "beneficiary_document_number",
                    inputValue.slice(0, 14)
                  );
                }}
                customFilter={(inputRef, setInputString) => {
                  let value = inputRef.value;
                  value = value.replace(/\D/g, "").slice(0, 14);

                  if (value.length === 11) {
                    value = value.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{2})/,
                      "$1.$2.$3-$4"
                    );
                  } else if (value.length === 14) {
                    value = value.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      "$1.$2.$3/$4-$5"
                    );
                  }

                  inputRef.value = value;
                  setInputString(value);
                }}
                onError={errorMessageHandler(
                  installmentsPaymentInfoErrors[index].beneficiaryDocument
                )}
              />
              <LabeledTextInput
                className="large"
                placeholder="Razão social ou nome"
                hasMandatoryStyle={{
                  default: { marginLeft: "158px" },
                  onFocus: { marginLeft: "105px" },
                }}
                setData={(setInputString) => {
                  if (
                    installmentPaymentInfosRef.current[index].data
                      .beneficiary_name &&
                    (
                      installmentPaymentInfosRef.current[index].data
                        .beneficiary_name as string
                    ).length > 0
                  ) {
                    setInputString(
                      installmentPaymentInfosRef.current[index].data
                        .beneficiary_name as string
                    );
                  }
                }}
                onChange={(inputValue) => {
                  handleSimpleSliderPaymentFormComplementOnChange(
                    index,
                    "beneficiary_name",
                    inputValue
                  );
                }}
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value;
                  inputRef.value = value;
                  setInputString(value);
                }}
                onError={errorMessageHandler(
                  installmentsPaymentInfoErrors[index].beneficiaryName
                )}
              />
            </div>
          </>
        );
      case "PIX - pagamento":
        return (
          <LabeledTextInput
            placeholder="QR Code"
            hasMandatoryStyle={{
              default: { marginLeft: "72px" },
              onFocus: { marginLeft: "51px" },
            }}
            setData={(setInputString) => {
              if (installmentPaymentInfosRef.current[index].data.qr_code) {
                setInputString(
                  installmentPaymentInfosRef.current[index].data
                    .qr_code as string
                );
              }
            }}
            onChange={(inputValue) => {
              handleSimpleSliderPaymentFormComplementOnChange(
                index,
                "qr_code",
                inputValue
              );
            }}
            customFilter={(inputRef, setInputString) => {
              const value = inputRef.value;
              inputRef.value = value;
              setInputString(value);
            }}
            onError={errorMessageHandler(
              installmentsPaymentInfoErrors[index].qrcode
            )}
          />
        );
      default:
        return <></>;
    }
  };

  const errorMessageHandler = (
    condition: boolean,
    message = "Campo obrigatório"
  ) => {
    return {
      hasError: condition || backendErrorMessage.field !== null,
      message:
        backendErrorMessage.field !== null
          ? backendErrorMessage.value
          : message,
    };
  };

  const handleSimpleSliderPaymentFormComplementOnChange = (
    index: number,
    field: string,
    value: string
  ) => {
    let temp = [...installmentPaymentInfosRef.current];

    if (index === 0 && !["barcode", "qr_code"].includes(field)) {
      temp = temp.reduce(
        (paymentInfoStack, currPaymentInfo) => {
          const temp2 = { ...currPaymentInfo } as any;

          if (paymentInfoStack.stop) {
            paymentInfoStack.result.push(currPaymentInfo);
          } else if (temp2.edited /* && index !== 0 */) {
            paymentInfoStack.result.push(currPaymentInfo);
            paymentInfoStack.stop = true;
          } else {
            // console.log("temp2: ", temp2);

            temp2.data[field] = value;

            paymentInfoStack.result.push(temp2);
          }

          return paymentInfoStack;
        },
        { result: [], stop: false } as {
          result: {
            edited: boolean;
            payMethod: string;
            data: TPaymentInformationAttributes;
          }[];
          stop: boolean;
        }
      ).result;

      // temp = temp.map((paymentInfo) => {
      //   const temp2 = { ...paymentInfo } as any;

      //   temp2.data[field] = value;

      //   return temp2;
      // });
    } else {
      const tempData = {
        ...installmentPaymentInfosRef.current[index].data,
      } as any;
      tempData[field] = value;

      temp[index] = {
        edited: index !== 0,
        payMethod: installmentPaymentInfosRef.current[index].payMethod,
        data: tempData,
      };
    }

    installmentPaymentInfosRef.current = temp;
  };

  const handleInstallmentsInitialState = () => {
    const installments = submitObjectRef.current.installments ?? 2;
    const paidInstallments = submitObjectRef.current.paid_installments;
    submitObjectRef.current.paid_installments = new Array(installments)
      .fill({})
      .map(
        (_, index) =>
          ({
            installment: index + 1,
            discount_amount: "",
            fine_amount: "",
            interest_amount: "",
            expected_deposit_account_id: null,
            nominal_amount: calculateInstallments(
              parseFloat(invoice.amount),
              installments
            )[index]?.toFixed(2),
            paid_amount: paidInstallments
              ? paidInstallments[index].paid_amount
              : undefined,
            payment_date: paidInstallments
              ? paidInstallments[index].payment_date
              : undefined,
            due_date: format(
              add(new Date(), handleInstallmentDueDateInitialState(index)),
              "yyyy-MM-dd"
            ),
          } as TPaidInstallments)
      );
  };

  const handleInstallmentDueDateInitialState = (index: number) => {
    switch (submitObjectRef.current.period) {
      case "weekly":
        return { weeks: 1 * index };
      case "biweekly":
        return { weeks: 2 * index };
      case "monthly":
        return { months: 1 * index };
      case "bimonthly":
        return { months: 2 * index };
      case "quarterly":
        return { months: 3 * index };
      case "semiannual":
        return { months: 6 * index };
      case "annual":
        return { years: 1 * index };
      default:
        return { months: 1 * index };
    }
  };

  const onCancelHandler = () => {
    if (footer.buttons[0].onClick) {
      footer.buttons[0].onClick();
    }
  };

  const preventSubmitDefaultBehavior = (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    if (
      submitObjectRef.current &&
      submitObjectRef.current.amount === undefined
    ) {
      submitObjectRef.current.amount = invoice.amount;
    }
    // console.log(submitObjectRef.current);
  };

  const simpleSliderChildren = Array.from(
    { length: installments },
    (_, index) =>
      React.cloneElement(
        <React.Fragment key={index + " - " + shouldComponentUpdate}>
          <RowContainer
            style={{ borderBottom: 0, marginBottom: 0, paddingBottom: 0 }}
            key={`slide1-${index}`}
            className="paidContainer"
          >
            <LabeledTextInput
              placeholder="Valor da parcela"
              hasMandatoryStyle={{
                onFocus: { marginLeft: "82px" },
                default: { marginLeft: "120px" },
              }}
              customFilter={(inputRef, setInputString) => {
                const value = inputRef.value;
                inputRef.value = value
                  .replace(/\D/g, "")
                  .slice(0, 10)
                  .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
                setInputString(
                  value
                    .replace(/\D/g, "")
                    .slice(0, 10)
                    .replace(/^(\d+)(\d{2})$/, "R$ $1,$2")
                );
              }}
              onChange={(inputValue) => {
                if (submitObjectRef.current.paid_installments) {
                  const temp = [...submitObjectRef.current.paid_installments];
                  temp[index] = {
                    ...submitObjectRef.current.paid_installments[index],
                    nominal_amount: inputValue
                      .replace(/\D/g, "")
                      .replace(/(\d+)(\d{2})/g, "$1.$2"),
                  };
                  submitObjectRef.current.paid_installments = temp;
                  if (paidInstalments.includes(index)) {
                    setTimeout(() => {
                      handlePaidInstallmentsPaidAmountUpdate(index);
                    }, 300);
                  }
                }
              }}
              setData={(setInputString) => {
                if (submitObjectRef.current.paid_installments) {
                  setInputString(
                    submitObjectRef.current.paid_installments[
                      index
                    ].nominal_amount?.replace(/(\d+).(\d+)/g, "R$ $1,$2") ?? ""
                  );
                }
              }}
              onError={errorMessageHandler(
                shouldShowInstallmentsAmountError[index] ?? false
              )}
            />
            <Calendar
              setDate={(setButtonSelectedDate, setDatePickerSelectedDates) => {
                const value = attachedPaymentSlipsRef.current[index]
                  ? format(
                      parseISO(attachedPaymentSlipsRef.current[index].due_date),
                      "dd/MM/yyyy"
                    )
                  : submitObjectRef.current.paid_installments &&
                    submitObjectRef.current.paid_installments[index]
                  ? format(
                      parseISO(
                        submitObjectRef.current.paid_installments[index]
                          .due_date
                      ),
                      "dd/MM/yyyy"
                    )
                  : format(new Date(), "dd/MM/yyyy");

                setButtonSelectedDate(value);
                setDatePickerSelectedDates({
                  startDate: value,
                });
              }}
              className="calendar"
              placeholder="Vencimento"
              onSelect={(selectDate) => {
                // dueDate
                if (submitObjectRef.current.paid_installments) {
                  const tempPaidInstallments = [
                    ...submitObjectRef.current.paid_installments,
                  ];
                  const newPaidInstallment = {
                    ...tempPaidInstallments[index],
                    due_date: selectDate.replace(
                      /(\d{2})\/(\d{2})\/(\d{4})/g,
                      "$3-$2-$1"
                    ),
                  } as TPaidInstallments;
                  tempPaidInstallments[index] = newPaidInstallment;

                  submitObjectRef.current.paid_installments =
                    tempPaidInstallments;

                  dispatch(
                    setAllPaidElements({
                      allPaidElements:
                        submitObjectRef.current.paid_installments,
                    })
                  );
                }
              }}
              // onError={{
              //   hasError:
              //     allPaidElements[index] &&
              //     allPaidElements[index].errorMessage !== undefined
              //       ? (allPaidElements[index].errorMessage as string).length > 0
              //       : false,
              //   message:
              //     (allPaidElements[index] &&
              //       allPaidElements[index].errorMessage) ??
              //     "",
              // }}
            />

            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                width: "unset",
              }}
            >
              <Check
                id="paid"
                initialState={paidInstalments.includes(index)}
                onClick={() => {
                  setPaidInstallments((before) => {
                    if (!before.includes(index)) {
                      if (
                        submitObjectRef.current.paid_installments &&
                        !submitObjectRef.current.paid_installments[index]
                          .payment_date
                      ) {
                        const tempPaidInstallments = [
                          ...submitObjectRef.current.paid_installments,
                        ];
                        const newPaidInstallment = {
                          ...tempPaidInstallments[index],
                          payment_date: format(new Date(), "yyyy-MM-dd"),
                        } as TPaidInstallments;
                        tempPaidInstallments[index] = newPaidInstallment;

                        submitObjectRef.current.paid_installments =
                          tempPaidInstallments;
                      }

                      if (
                        submitObjectRef.current.paid_installments &&
                        submitObjectRef.current.paid_installments[index]
                          .nominal_amount.length > 0
                      ) {
                        setTimeout(() => {
                          handlePaidInstallmentsPaidAmountUpdate(index);
                        }, 300);
                      }
                      return [...before, index];
                    } else {
                      if (submitObjectRef.current.paid_installments) {
                        const temp = {
                          ...submitObjectRef.current.paid_installments[index],
                          discount_amount: "0",
                          fine_amount: "0",
                          interest_amount: "0",
                          expected_deposit_account_id: null,
                          paid_amount: "",
                          payment_date: undefined,
                        };

                        const installments = [
                          ...submitObjectRef.current.paid_installments,
                        ];
                        installments[index] = temp;
                        submitObjectRef.current.paid_installments =
                          installments;
                      }
                      return before.filter(
                        (installment) => installment !== index
                      );
                    }
                  });
                }}
              />
              &nbsp;<label htmlFor="paid">Já paga</label>
            </div>
          </RowContainer>
          <RowContainer>
            <Dropdown
              button={{
                variant: "labeled",
                labels: [
                  depositAccounts.find(
                    (depositAccount) =>
                      submitObjectRef.current.paid_installments &&
                      submitObjectRef.current.paid_installments[index]
                        ?.expected_deposit_account_id === depositAccount.id
                  )?.name ?? "",
                ],
                placeholder: "Pagar via",
              }}
              menu={{
                options: depositAccounts.map(
                  (depositAccount) => depositAccount.name
                ),
                variant: "unlabeled",
                search: false,
                onSelect(selected) {
                  const result = depositAccounts.find(
                    (depositAccount) => depositAccount.name === selected
                  );
                  if (result && submitObjectRef.current.paid_installments) {
                    const tempPaidInstallments = [
                      ...submitObjectRef.current.paid_installments,
                    ];
                    const newPaidInstallment = {
                      ...tempPaidInstallments[index],
                      expected_deposit_account_id: result.id,
                    } as TPaidInstallments;
                    tempPaidInstallments[index] = newPaidInstallment;

                    submitObjectRef.current.paid_installments =
                      tempPaidInstallments;
                  }
                },
              }}
            />
          </RowContainer>
          <RowContainer
            $isPaid={isPaid}
            className="paymentForm"
            key={`${isPaid}-text`}
          >
            <Dropdown
              button={{
                variant: "labeled",
                labels: [""],
                placeholder: "Forma de pagamento",
                // onError: {
                //   hasError: shouldShowCategoryIdError,
                //   message: "Campo obrigatório",
                // },
                setData(setSelectedData) {
                  // console.log("setting pay form: ", tempRef.current);

                  if (
                    installmentPaymentInfosRef.current[index] &&
                    installmentPaymentInfosRef.current[index].payMethod
                  ) {
                    setTimeout(() => {
                      setSelectedData(
                        installmentPaymentInfosRef.current[index]
                          .payMethod as string
                      );
                    }, 100);
                  } else {
                    setTimeout(() => {
                      installmentPaymentInfosRef.current[index].payMethod = "";

                      setSelectedData("");
                    }, 100);
                  }
                },
              }}
              menu={{
                variant: "unlabeled",
                options: [
                  "---",
                  "Boleto",
                  "TED",
                  "PIX - transferência",
                  "PIX - pagamento",
                ],
                search: false,
                onSelect(selected) {
                  // console.log(
                  //   "setting temp ref, initial state value: ",
                  //   tempRef.current
                  // );

                  const selectedValue =
                    selected; /*  === "---" ? "" : selected */

                  // console.log("selected value: ", selectedValue);

                  if (selectedValue === "---") {
                    handleSimpleSliderPaymentInformationByPaymentTypeChange(
                      "---",
                      index
                    );
                  }

                  let temp = [...installmentPaymentInfosRef.current];

                  if (index === 0) {
                    temp = temp.reduce(
                      (paymentInfoStack, currPaymentInfo) => {
                        const temp2 = { ...currPaymentInfo } as any;

                        // console.log("from reduce, temp2: ", temp2);

                        if (paymentInfoStack.stop) {
                          paymentInfoStack.result.push(currPaymentInfo);
                        } else if (temp2.edited /* && index !== 0 */) {
                          paymentInfoStack.result.push(currPaymentInfo);
                          paymentInfoStack.stop = true;
                        } else {
                          temp2.payMethod = selectedValue;

                          temp2.data.payment_type = selectedValue;

                          paymentInfoStack.result.push(temp2);
                        }

                        return paymentInfoStack;
                      },
                      { result: [], stop: false } as {
                        result: {
                          edited: boolean;
                          payMethod: string;
                          data: TPaymentInformationAttributes;
                        }[];
                        stop: boolean;
                      }
                    ).result;

                    // temp = temp.map((paymentInfo) => {
                    //   paymentInfo.payMethod = selectedValue;

                    //   paymentInfo.data.payment_type =
                    //     selectedValue as TPaymentFormVariants;
                    //   return paymentInfo;
                    // });
                  } else {
                    const tempData = { ...temp[index].data };

                    tempData.payment_type =
                      selectedValue as TPaymentFormVariants;

                    temp[index] = {
                      edited: index !== 0,
                      payMethod: selectedValue,
                      data: tempData,
                    };
                  }

                  // console.log("temp: ", temp);

                  installmentPaymentInfosRef.current = temp;

                  setPaymentFormArray((_before) => {
                    // const tempPayForm = [...before];

                    // if (index === 0) {
                    //   temp = Array.from(
                    //     { length: temp.length },
                    //     () => selected as TPaymentFormVariants
                    //   );
                    // } else {
                    //   temp[index] = selected as TPaymentFormVariants;
                    // }
                    return temp.map((object) => object.data.payment_type);
                  });
                },
              }}
            />
            {handleSimpleSliderPaymentFormComplement(
              index,
              paymentFormArray[index]
            )}
          </RowContainer>
          {paidInstalments.includes(index) && (
            <RowContainer key={`slide2-${index}`} className="paidContainer">
              <LabeledTextInput
                placeholder="Multa"
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value
                    .replace(/\D/g, "")
                    .slice(0, 10)
                    .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
                  inputRef.value = value;
                  setInputString(value);
                }}
                onChange={(inputValue) => {
                  if (submitObjectRef.current.paid_installments) {
                    const tempPaidInstallments = [
                      ...submitObjectRef.current.paid_installments,
                    ];
                    const newPaidInstallment = {
                      ...tempPaidInstallments[index],
                      fine_amount: inputValue
                        .replace(/\D/g, "")
                        .replace(/(\d+)(\d{2})/g, "$1.$2"),
                    } as TPaidInstallments;
                    tempPaidInstallments[index] = newPaidInstallment;

                    submitObjectRef.current.paid_installments =
                      tempPaidInstallments;
                  }
                  setTimeout(() => {
                    handlePaidInstallmentsPaidAmountUpdate(index);
                  }, 500);
                }}
                setData={(setInputString) => {
                  if (submitObjectRef.current.paid_installments) {
                    setInputString(
                      submitObjectRef.current.paid_installments[
                        index
                      ].fine_amount?.replace(/(\d+).(\d+)/g, "R$ $1,$2") ?? ""
                    );
                  }
                }}
              />
              <LabeledTextInput
                placeholder="Juros"
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value
                    .replace(/\D/g, "")
                    .slice(0, 10)
                    .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
                  inputRef.value = value;
                  setInputString(value);
                }}
                onChange={(inputValue) => {
                  if (submitObjectRef.current.paid_installments) {
                    const tempPaidInstallments = [
                      ...submitObjectRef.current.paid_installments,
                    ];
                    const newPaidInstallment = {
                      ...tempPaidInstallments[index],
                      interest_amount: inputValue
                        .replace(/\D/g, "")
                        .replace(/(\d+)(\d{2})/g, "$1.$2"),
                    } as TPaidInstallments;
                    tempPaidInstallments[index] = newPaidInstallment;

                    submitObjectRef.current.paid_installments =
                      tempPaidInstallments;
                  }
                  setTimeout(() => {
                    handlePaidInstallmentsPaidAmountUpdate(index);
                  }, 500);
                }}
                setData={(setInputString) => {
                  if (submitObjectRef.current.paid_installments) {
                    setInputString(
                      submitObjectRef.current.paid_installments[
                        index
                      ].interest_amount?.replace(/(\d+).(\d+)/g, "R$ $1,$2") ??
                        ""
                    );
                  }
                }}
              />
              <LabeledTextInput
                placeholder="Desconto"
                customFilter={(inputRef, setInputString) => {
                  const value = inputRef.value
                    .replace(/\D/g, "")
                    .slice(0, 10)
                    .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
                  inputRef.value = value;
                  setInputString(value);
                }}
                onChange={(inputValue) => {
                  if (submitObjectRef.current.paid_installments) {
                    const tempPaidInstallments = [
                      ...submitObjectRef.current.paid_installments,
                    ];
                    const newPaidInstallment = {
                      ...tempPaidInstallments[index],
                      discount_amount: inputValue
                        .replace(/\D/g, "")
                        .replace(/(\d+)(\d{2})/g, "$1.$2"),
                    } as TPaidInstallments;
                    tempPaidInstallments[index] = newPaidInstallment;

                    submitObjectRef.current.paid_installments =
                      tempPaidInstallments;
                  }

                  setTimeout(() => {
                    handlePaidInstallmentsPaidAmountUpdate(index);
                  }, 500);
                }}
                setData={(setInputString) => {
                  if (submitObjectRef.current.paid_installments) {
                    setInputString(
                      submitObjectRef.current.paid_installments[
                        index
                      ].discount_amount?.replace(/(\d+).(\d+)/g, "R$ $1,$2") ??
                        ""
                    );
                  }
                }}
              />
              <Calendar
                setDate={(
                  setButtonSelectedDate,
                  setDatePickerSelectedDates
                ) => {
                  const value =
                    (submitObjectRef.current.paid_installments &&
                      submitObjectRef.current.paid_installments[
                        index
                      ].payment_date?.replace(
                        /(\d{4})-(\d{2})-(\d{2})/g,
                        "$3/$2/$1"
                      )) ??
                    format(new Date(), "dd/MM/yyyy");
                  setButtonSelectedDate(value);
                  setDatePickerSelectedDates({
                    startDate: value,
                  });
                }}
                className="calendar"
                placeholder="Data do pagamento"
                hasMandatoryStyle={{ marginLeft: "130px" }}
                onSelect={(selectDate) => {
                  // paymentDate
                  if (submitObjectRef.current.paid_installments) {
                    const tempPaidInstallments = [
                      ...submitObjectRef.current.paid_installments,
                    ];
                    const newPaidInstallment = {
                      ...tempPaidInstallments[index],
                      payment_date: selectDate.replace(
                        /(\d{2})\/(\d{2})\/(\d{4})/g,
                        "$3-$2-$1"
                      ),
                    } as TPaidInstallments;
                    tempPaidInstallments[index] = newPaidInstallment;

                    submitObjectRef.current.paid_installments =
                      tempPaidInstallments;

                    if (
                      compareAsc(
                        selectDate.replace(
                          /(\d{2})\/(\d{2})\/(\d{4})/g,
                          "$3-$2-$1"
                        ),
                        new Date()
                      ) === 1
                    ) {
                      const temp = [
                        ...submitObjectRef.current.paid_installments,
                      ];
                      temp[index].errorMessage =
                        "Esta data não pode ser futura";
                      // console.log("setting should submit to false 6");
                      shouldSubmitRef.current = false;

                      dispatch(setAllPaidElements({ allPaidElements: temp }));
                    } else if (
                      allPaidElements[index] &&
                      allPaidElements[index].errorMessage &&
                      (allPaidElements[index].errorMessage as string).length > 0
                    ) {
                      const temp = [
                        ...submitObjectRef.current.paid_installments,
                      ];
                      temp[index].errorMessage = undefined;
                      // console.log("setting should submit to true 9");
                      shouldSubmitRef.current = true;

                      dispatch(setAllPaidElements({ allPaidElements: temp }));
                    } else {
                      // console.log("setting should submit to true 10");
                      shouldSubmitRef.current = true;
                    }
                  }
                }}
                onError={errorMessageHandler(
                  allPaidElements[index] &&
                    allPaidElements[index].errorMessage !== undefined
                    ? (allPaidElements[index].errorMessage as string).length > 0
                    : false,
                  (allPaidElements[index] &&
                    allPaidElements[index].errorMessage) ??
                    ""
                )}
              />
              <LabeledTextInput
                placeholder="Total pago"
                disabled
                setData={(setInputString) => {
                  setPaidAmountRefs.current[index] = setInputString;

                  if (
                    submitObjectRef.current.paid_installments &&
                    submitObjectRef.current.paid_installments[index].paid_amount
                  )
                    setInputString(
                      parseFloat(
                        submitObjectRef.current.paid_installments[index]
                          .paid_amount
                      )
                        .toFixed(2)
                        .replace(/(\d+).(\d+)/g, "R$ $1,$2") ?? ""
                    );
                }}
              />
              <div style={{ display: "none" }}>
                <Dropdown
                  button={{
                    variant: "labeled",
                    labels: [
                      depositAccounts.find(
                        (depositAccount) =>
                          submitObjectRef.current.paid_installments &&
                          submitObjectRef.current.paid_installments[index]
                            ?.expected_deposit_account_id === depositAccount.id
                      )?.name ?? "",
                    ],
                    placeholder: "Pagar via",
                  }}
                  menu={{
                    options: depositAccounts.map(
                      (depositAccount) => depositAccount.name
                    ),
                    variant: "unlabeled",
                    search: false,
                    onSelect(selected) {
                      const result = depositAccounts.find(
                        (depositAccount) => depositAccount.name === selected
                      );
                      if (result && submitObjectRef.current.paid_installments) {
                        const tempPaidInstallments = [
                          ...submitObjectRef.current.paid_installments,
                        ];
                        const newPaidInstallment = {
                          ...tempPaidInstallments[index],
                          expected_deposit_account_id: result.id,
                        } as TPaidInstallments;
                        tempPaidInstallments[index] = newPaidInstallment;

                        submitObjectRef.current.paid_installments =
                          tempPaidInstallments;
                      }
                    },
                  }}
                />
              </div>
              {/* <Check
            id="paid"
            onClick={() => {
              setIsPaid((before) => !before);
              setTimeout(() => {
                if (submitObjectRef.current.mode === "installments") {
                  handleInitializeSetPaidInstallmentRefs(`${installments}`);
                }
              }, 50);
            }}
          />
          &nbsp;<label htmlFor="paid">Já paga</label> */}
            </RowContainer>
          )}
        </React.Fragment>
      )
  );

  const handlePaidAmountUpdate = useCallback(() => {
    // deve ser igual ao valor base mais juros e multa, menos o desconto
    let discountAmount = 0,
      fineAmount = 0,
      interestAmount = 0,
      baseline = parseFloat(invoice.amount);

    if (submitObjectRef.current.discount_amount) {
      discountAmount = parseFloat(submitObjectRef.current.discount_amount);
    }
    if (submitObjectRef.current.fine_amount) {
      fineAmount = parseFloat(submitObjectRef.current.fine_amount);
    }
    if (submitObjectRef.current.interest_amount) {
      interestAmount = parseFloat(submitObjectRef.current.interest_amount);
    }
    if (
      submitObjectRef.current.paid_amount &&
      parseFloat(submitObjectRef.current.paid_amount) !==
        baseline + fineAmount + interestAmount - discountAmount
    ) {
      submitObjectRef.current.paid_amount = `${
        baseline + fineAmount + interestAmount - discountAmount
      }`;

      if (setPaidAmountRef.current) {
        setPaidAmountRef.current(
          `${parseFloat(submitObjectRef.current.paid_amount).toFixed(2)}`
            .replace(/\D/g, "")
            .replace(/(\d+)(\d{2})/g, "R$ $1,$2")
        );
      }
    }
  }, []);

  const handlePaidInstallmentsPaidAmountUpdate = useCallback(
    (index: number) => {
      if (submitObjectRef.current.paid_installments) {
        // deve ser igual ao valor base mais juros e multa, menos o desconto
        // Campo \"paid_amount\" deve ser igual a \"amount\" + \"interest_amount\" + \"fine_amount\" - \"discount_amount\"
        let discountAmount = 0,
          fineAmount = 0,
          interestAmount = 0,
          baseline = parseFloat(
            submitObjectRef.current.paid_installments[index].nominal_amount
          );
        if (submitObjectRef.current.paid_installments[index].discount_amount) {
          discountAmount = parseFloat(
            submitObjectRef.current.paid_installments[index]
              .discount_amount as string
          );
        }
        if (submitObjectRef.current.paid_installments[index].fine_amount) {
          fineAmount = parseFloat(
            submitObjectRef.current.paid_installments[index]
              .fine_amount as string
          );
        }
        if (submitObjectRef.current.paid_installments[index].interest_amount) {
          interestAmount = parseFloat(
            submitObjectRef.current.paid_installments[index]
              .interest_amount as string
          );
        }

        // console.log(
        //   "base line: ",
        //   baseline,
        //   ", installments: ",
        //   submitObjectRef.current.paid_installments[index].installment
        // );
        const temp = { ...submitObjectRef.current.paid_installments[index] };
        const paidInstallments = [...submitObjectRef.current.paid_installments];
        paidInstallments[index] = {
          ...temp,
          paid_amount: parseFloat(
            `${baseline + fineAmount + interestAmount - discountAmount}`
          ).toFixed(2),
        };
        submitObjectRef.current.paid_installments = paidInstallments;

        if (setPaidAmountRefs.current) {
          // console.log(
          //   "setting paid amount value: ",
          //   submitObjectRef.current.paid_installments[index].paid_amount
          // );
          setPaidAmountRefs.current[index](
            `${parseFloat(
              submitObjectRef.current.paid_installments[index]
                .paid_amount as string
            ).toFixed(2)}`
              .replace(/\D/g, "")
              .replace(/(\d+)(\d{2})/g, "R$ $1,$2")
          );
        }
      }
    },
    []
  );

  const handleInitializeSetPaidInstallmentRefs = (installments: string) => {
    if (submitObjectRef.current.mode === "installments") {
      new Array(!isNaN(parseInt(installments)) ? parseInt(installments) : 2)
        .fill(undefined)
        .forEach((_, index) => {
          if (
            setPaidAmountRefs.current[index] &&
            submitObjectRef.current.paid_installments
          )
            setPaidAmountRefs.current[index](
              parseFloat(
                submitObjectRef.current.paid_installments[index].paid_amount
              )
                .toFixed(2)
                .replace(/(\d+).(\d+)/g, "R$ $1,$2") ?? ""
            );
        });
    }
  };

  const fetchData = async () => {
    const queryStrings = handleParseQuerystrings(window.location.search);

    cancelTokenSource.current = axios.CancelToken.source();

    apiService
      .getCategories(cancelTokenSource.current.token)
      .then((response) => {
        if (response?.data) setCategories(response.data);
      });
    apiService
      .getClassificationCenters(cancelTokenSource.current.token)
      .then((response) => {
        if (response?.data) setClassificationCenters(response.data);
      });
    // apiService.getSuppliers().then((response) => {
    //   setSuppliers(response.data);
    // });
    if (queryStrings.organization_id && invoice) {
      apiService
        .getInvoiceSupplier(
          queryStrings.organization_id[0],
          invoice.uuid,
          cancelTokenSource.current.token
        )
        .then((response: any) => {
          if (response?.data) setSupplier(response.data);
        });
    }
    apiService
      .getDepositAccounts(cancelTokenSource.current.token)
      .then((response) => {
        if (response?.data) setDepositAccounts(response.data);
      });
  };

  useEffect(() => {
    fetchData();

    const initialPaymentSlipState: TPaymentSlip = {
      uuid: "",
      number: "",
      bar_code: "",
      amount: "",
      payer_name: "",
      payer_document: "",
      provider_name: "",
      provider_document: "",
      process_date: "",
      due_date: "",
      source: "",
      launch_status: 10,
      invoices_exists: false,
      file_boleto: "",
      accounts_payable_url: null,
      fintera_id: null,
      label: "",
      user: {
        uuid: "",
        email: "",
        first_name: "",
        last_name: "",
      },
    };

    if (invoice && selectOrganization) {
      attachedPaymentSlipsRef.current = invoice.duplicates.map((duplicate) => ({
        ...initialPaymentSlipState,
        amount: duplicate.amount,
        due_date: duplicate.due_date,
        payer_document: selectOrganization.cnpj,
        payer_name: selectOrganization.company_name,
      }));
      if (cancelTokenSource.current) {
        apiService
          .getInvoiceAttachedPaymentSlips(
            invoice.uuid,
            cancelTokenSource.current.token
          )
          .then((response) => {
            if (response) {
              const paymentSlips = response.data as TPaymentSlip[];

              if (invoice.duplicates.length > 0 && paymentSlips.length > 0) {
                attachedPaymentSlipsRef.current =
                  attachedPaymentSlipsRef.current
                    .sort((a, b) =>
                      compareAsc(parseISO(a.due_date), parseISO(b.due_date))
                    )
                    .reduce(
                      (accumulated, attachedDocument) => {
                        const matchingDocument = paymentSlips.find(
                          (paymentSlip) => {
                            if (
                              paymentSlip.due_date ===
                                attachedDocument.due_date &&
                              paymentSlip.amount === attachedDocument.amount
                            ) {
                              return paymentSlip;
                            }
                          }
                        );
                        const result = matchingDocument ?? attachedDocument;

                        if (
                          !accumulated.result.some(
                            (element) =>
                              element.uuid.length > 0 &&
                              element.uuid === result.uuid
                          )
                        ) {
                          accumulated.result.push(result);
                        }

                        return accumulated;
                      },
                      { result: [] } as { result: TPaymentSlip[] }
                    ).result;
              } else if (paymentSlips.length > 0) {
                attachedPaymentSlipsRef.current = paymentSlips
                  .sort((a, b) =>
                    compareAsc(parseISO(a.due_date), parseISO(b.due_date))
                  )
                  .map((paymentSlip) => ({
                    ...paymentSlip,
                    payer_document: selectOrganization.cnpj,
                    payer_name: selectOrganization.company_name,
                  }));
              }

              if (invoice.duplicates.length > 1) {
                document.getElementById("installments")?.click();
              }
            }
          });
      }
      submitObjectRef.current.paid_amount = isPaid ? invoice.amount : undefined;

      setTimeout(() => {
        if (setPaidAmountRef.current)
          setPaidAmountRef.current(
            invoice.amount
              .replace(/\D/g, "")
              .replace(/(\d+)(\d{2})/g, "R$ $1,$2")
          );
      }, 500);
    }

    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  useEffect(() => {
    // console.log(
    //   "all paid elements has been updated: ",
    //   allPaidElements,
    //   ", submit object ref paid installments: ",
    //   submitObjectRef.current.paid_installments,
    //   ", should submit ref: ",
    //   shouldSubmitRef.current
    // );
  }, [allPaidElements]);

  useEffect(() => {
    const initialPaymentSlipState: TPaymentSlip = {
      uuid: "",
      number: "",
      bar_code: "",
      amount: "",
      payer_name: "",
      payer_document: "",
      provider_name: "",
      provider_document: "",
      process_date: "",
      due_date: "",
      source: "",
      launch_status: 10,
      invoices_exists: false,
      file_boleto: "",
      accounts_payable_url: null,
      fintera_id: null,
      label: "",
      user: {
        uuid: "",
        email: "",
        first_name: "",
        last_name: "",
      },
    };

    if (invoice && selectOrganization) {
      attachedPaymentSlipsRef.current = invoice.duplicates.map((duplicate) => ({
        ...initialPaymentSlipState,
        amount: duplicate.amount,
        due_date: duplicate.due_date,
        payer_document: selectOrganization.cnpj,
        payer_name: selectOrganization.company_name,
      }));
      if (cancelTokenSource.current) {
        apiService
          .getInvoiceAttachedPaymentSlips(
            invoice.uuid,
            cancelTokenSource.current.token
          )
          .then((response) => {
            if (response) {
              const paymentSlips = response.data as TPaymentSlip[];

              if (invoice.duplicates.length > 0 && paymentSlips.length > 0) {
                attachedPaymentSlipsRef.current =
                  attachedPaymentSlipsRef.current
                    .sort((a, b) =>
                      compareAsc(parseISO(a.due_date), parseISO(b.due_date))
                    )
                    .reduce(
                      (accumulated, attachedDocument) => {
                        const matchingDocument = paymentSlips.find(
                          (paymentSlip) => {
                            if (
                              paymentSlip.due_date ===
                                attachedDocument.due_date &&
                              paymentSlip.amount === attachedDocument.amount
                            ) {
                              return paymentSlip;
                            }
                          }
                        );
                        const result = matchingDocument ?? attachedDocument;

                        if (
                          !accumulated.result.some(
                            (element) =>
                              element.uuid.length > 0 &&
                              element.uuid === result.uuid
                          )
                        ) {
                          accumulated.result.push(result);
                        }

                        return accumulated;
                      },
                      { result: [] } as { result: TPaymentSlip[] }
                    ).result;
              } else if (paymentSlips.length > 0) {
                attachedPaymentSlipsRef.current = paymentSlips
                  .sort((a, b) =>
                    compareAsc(parseISO(a.due_date), parseISO(b.due_date))
                  )
                  .map((paymentSlip) => ({
                    ...paymentSlip,
                    payer_document: selectOrganization.cnpj,
                    payer_name: selectOrganization.company_name,
                  }));
              }
            }
          });
      }
      submitObjectRef.current.paid_amount = isPaid ? invoice.amount : undefined;

      setTimeout(() => {
        if (setPaidAmountRef.current)
          setPaidAmountRef.current(
            invoice.amount
              .replace(/\D/g, "")
              .replace(/(\d+)(\d{2})/g, "R$ $1,$2")
          );
      }, 500);
    }
  }, [invoice, isPaid, activeRadio]);

  return (
    <Container onSubmit={preventSubmitDefaultBehavior}>
      <p>
        Tem certeza que deseja lançar a Nota Fiscal nº {invoice?.number} como
        contas a pagar na <b>Fintera</b>?
      </p>
      <RowContainer
        $hasModeError={shouldShowModeError ? "hasError" : "noError"}
      >
        <p className="errorMessage">Campo obrigatório</p>
        <div className="radioContainer">
          <Radio
            id="full"
            isActive={activeRadio === "full" ? "active" : "inactive"}
            onClick={() => {
              setActiveRadio("full");
              submitObjectRef.current.mode = "in_cash";
              if (submitObjectRef.current.paid_installments)
                submitObjectRef.current.paid_installments = undefined;
              if (submitObjectRef.current.installments)
                submitObjectRef.current.installments = undefined;
              if (submitObjectRef.current.payment_date)
                submitObjectRef.current.payment_date = undefined;
              if (submitObjectRef.current.period)
                submitObjectRef.current.period = undefined;

              installmentPaymentInfosRef.current = [];

              setPaymentFormArray([]);
            }}
          />
          &nbsp;<label htmlFor="full">À vista</label>
        </div>
        <div className="radioContainer">
          <Radio
            id="recurring"
            isActive={activeRadio === "recurring" ? "active" : "inactive"}
            onClick={() => {
              setActiveRadio("recurring");
              submitObjectRef.current.mode = "recurrent";
            }}
          />
          &nbsp;<label htmlFor="recurring">Recorrente</label>
        </div>
        <div className="radioContainer">
          <Radio
            id="installments"
            isActive={activeRadio === "installments" ? "active" : "inactive"}
            onClick={() => {
              submitObjectRef.current.due_date = "";
              submitObjectRef.current.mode = "installments";

              // console.log(
              //   "attached payment slips ref: ",
              //   attachedPaymentSlipsRef.current
              // );

              const attachedPaymentSlipsInfos =
                attachedPaymentSlipsRef.current.reduce(
                  (accumulated, attachedPaymentSlip, index) => {
                    accumulated.installments = index + 1;
                    accumulated.sum += parseFloat(attachedPaymentSlip.amount);

                    if (
                      index === attachedPaymentSlipsRef.current.length - 1 &&
                      accumulated.sum < parseFloat(invoice.amount)
                    ) {
                      accumulated.installments += 1;
                      accumulated.quota =
                        parseFloat(invoice.amount) - accumulated.sum;
                      accumulated.sum = parseFloat(invoice.amount);
                    }

                    return accumulated;
                  },
                  { sum: 0, installments: 1, quota: 0 } as {
                    sum: number;
                    installments: number;
                    quota: number;
                  }
                );

              // console.log(
              //   "attached payment slips ref: ",
              //   attachedPaymentSlipsRef.current,
              //   "attached payment slip infos: ",
              //   attachedPaymentSlipsInfos
              // );

              submitObjectRef.current.installments =
                attachedPaymentSlipsRef.current.length > 1
                  ? attachedPaymentSlipsInfos.installments
                  : 2;

              submitObjectRef.current.paid_installments = new Array(
                submitObjectRef.current.installments
              )
                .fill({})
                .map(
                  (_, index) =>
                    ({
                      installment: index + 1,
                      discount_amount: "",
                      fine_amount: "",
                      interest_amount: "",
                      expected_deposit_account_id: null,
                      nominal_amount: attachedPaymentSlipsRef.current[index]
                        ? attachedPaymentSlipsRef.current[index].amount
                        : attachedPaymentSlipsInfos.quota.toFixed(2),
                      paid_amount: "",
                      payment_date: undefined,
                      due_date: attachedPaymentSlipsRef.current[index]
                        ? attachedPaymentSlipsRef.current[index].due_date
                        : format(
                            add(
                              new Date(),
                              handleInstallmentDueDateInitialState(index)
                            ),
                            "yyyy-MM-dd"
                          ),
                    } as TPaidInstallments)
                );

              installmentPaymentInfosRef.current = new Array(
                submitObjectRef.current.installments
              ).fill({
                edited: false,
                payMethod: "",
                data: {
                  payment_type: "",
                  beneficiary_name: "",
                  beneficiary_document_number: "",

                  beneficiary_bank_code: undefined, // TedTransfer or PixTransfer only
                  beneficiary_bank_agency: undefined, // TedTransfer or PixTransfer only
                  beneficiary_bank_agency_digit: undefined, // TedTransfer or PixTransfer only
                  beneficiary_bank_account_number: undefined, // TedTransfer or PixTransfer only
                  beneficiary_bank_account_digit: undefined, // TedTransfer or PixTransfer only

                  barcode: undefined, // BankBillet only

                  pix_type: undefined,
                  pix_key_type: undefined,
                  pix_key: undefined,
                  qr_code: undefined,
                },
              });

              setPaymentFormArray(
                new Array(submitObjectRef.current.installments).fill("")
              );

              setActiveRadio("installments");

              setInstallmentsPaymentInfoErrors(
                Array.from(
                  { length: submitObjectRef.current.installments },
                  () => ({
                    bankInfo: false,
                    barcode: false,
                    beneficiaryDocument: false,
                    beneficiaryName: false,
                    pixKey: false,
                    qrcode: false,
                  })
                )
              );

              setInstallments(submitObjectRef.current.installments);

              setShouldComponentUpdate(true);
              setTimeout(() => {
                setShouldComponentUpdate(false);
              }, 100);
            }}
          />
          &nbsp;<label htmlFor="installments">Parcelado</label>
        </div>
      </RowContainer>
      <RowContainer>
        <LabeledTextInput
          placeholder="Valor a pagar"
          filled={
            "R$ " +
            (invoice?.amount)
              .replace(/\D/g, "")
              .replace(/(\d+)(\d{2})/g, "$1,$2")
          }
          disabled
        />
        {activeRadio !== "installments" && (
          <Calendar
            key={attachedPaymentSlipsRef.current.length}
            setDate={(setButtonSelectedDate, setDatePickerSelectedDates) => {
              submitObjectRef.current.due_date =
                attachedPaymentSlipsRef.current.length > 0 &&
                attachedPaymentSlipsRef.current[0].due_date
                  ? attachedPaymentSlipsRef.current[0].due_date
                  : format(new Date(), "yyyy-MM-dd");

              const value =
                attachedPaymentSlipsRef.current.length > 0 &&
                attachedPaymentSlipsRef.current[0].due_date
                  ? attachedPaymentSlipsRef.current[0].due_date.replace(
                      /(\d{4})-(\d{2})-(\d{2})/g,
                      "$3/$2/$1"
                    )
                  : format(new Date(), "dd/MM/yyyy");

              setButtonSelectedDate(value);
              setDatePickerSelectedDates({
                startDate: value,
              });
            }}
            className="calendar"
            placeholder="Vencimento"
            onSelect={(selectDate) => {
              submitObjectRef.current.due_date = selectDate.replace(
                /(\d{2})\/(\d{2})\/(\d{4})/g,
                "$3-$2-$1"
              );
            }}
            onError={errorMessageHandler(shouldShowDueDateError)}
          />
        )}
        {["recurring", "installments"].includes(activeRadio) && (
          <Dropdown
            button={{
              placeholder: "Período",
              variant: "labeled",
              labels: [""],
              onError: {
                hasError: shouldShowPeriodError,
                message: "Campo obrigatório",
              },
              setData(setSelectedData) {
                submitObjectRef.current.period = "monthly";
                setTimeout(() => {
                  setSelectedData("Mensal");
                }, 50);
              },
            }}
            menu={{
              variant: "unlabeled",
              setSelectedIndex(setSelectedData) {
                setTimeout(() => {
                  setSelectedData(["2"]);
                }, 50);
              },
              options: [
                "Semanal",
                "Quinzenal",
                "Mensal",
                "Bimestral",
                "Trimestral",
                "Semestral",
                "Anual",
              ],
              search: false,
              onSelect(selected) {
                const result = selected as
                  | "Semanal"
                  | "Quinzenal"
                  | "Mensal"
                  | "Bimestral"
                  | "Trimestral"
                  | "Semestral"
                  | "Anual";
                let period;
                switch (result) {
                  case "Semanal":
                    period = "weekly";
                    break;
                  case "Quinzenal":
                    period = "biweekly";
                    break;
                  case "Mensal":
                    period = "monthly";
                    break;
                  case "Bimestral":
                    period = "bimonthly";
                    break;
                  case "Trimestral":
                    period = "quarterly";
                    break;
                  case "Semestral":
                    period = "semiannual";
                    break;
                  case "Anual":
                    period = "annual";
                    break;
                }
                submitObjectRef.current.period = period as
                  | "weekly"
                  | "biweekly"
                  | "monthly"
                  | "bimonthly"
                  | "quarterly"
                  | "semiannual"
                  | "annual";

                handleInstallmentsInitialState();
                setShouldComponentUpdate(true);
                setTimeout(() => {
                  setShouldComponentUpdate(false);
                }, 100);
              },
            }}
          />
        )}
        {activeRadio === "installments" && (
          <LabeledTextInput
            placeholder="Parcelas"
            hasMandatoryStyle={{
              onFocus: { marginLeft: "50px" },
              default: { marginLeft: "70px" },
            }}
            customFilter={(inputRef, setInputString) => {
              let value = inputRef.value
                .replace(/\D/g, "")
                .replace(/(\d{1,2}).*/g, "$1");

              // if (parseInt(value) < 2) {
              //   value = "2";
              // }

              if (isNaN(parseInt(value))) {
                submitObjectRef.current.installments = undefined;
              } else {
                submitObjectRef.current.paid_installments = new Array(
                  parseInt(value)
                )
                  .fill({})
                  .map(
                    (_, index) =>
                      ({
                        installment: index + 1,
                        discount_amount: "",
                        fine_amount: "",
                        interest_amount: "",
                        expected_deposit_account_id: null,
                        nominal_amount: attachedPaymentSlipsRef.current[index]
                          ? attachedPaymentSlipsRef.current[index].amount
                          : calculateInstallments(
                              parseFloat(invoice.amount),
                              parseInt(value)
                            )[index].toFixed(2),
                        paid_amount:
                          submitObjectRef.current.paid_installments &&
                          submitObjectRef.current.paid_installments[index]
                            ?.paid_amount
                            ? calculateInstallments(
                                parseFloat(invoice.amount),
                                parseInt(value)
                              )[index].toFixed(2)
                            : "",
                        payment_date:
                          (submitObjectRef.current.paid_installments &&
                            submitObjectRef.current.paid_installments[index]
                              ?.payment_date) ??
                          undefined,
                        due_date: attachedPaymentSlipsRef.current[index]
                          ? attachedPaymentSlipsRef.current[index].due_date
                          : format(
                              add(
                                new Date(),
                                handleInstallmentDueDateInitialState(index)
                              ),
                              "yyyy-MM-dd"
                            ),
                      } as TPaidInstallments)
                  );
                // setPaymentFormArray(
                //   new Array(!isNaN(parseInt(value)) ? parseInt(value) : 1)
                //     .fill({})
                //     .map(() => "Boleto")
                // );
                inputRef.value = value;
                setInputString(value);
              }
            }}
            onChange={(inputValue) => {
              if (!isNaN(parseInt(inputValue))) {
                setTimeout(() => {
                  submitObjectRef.current.installments = parseInt(inputValue);

                  const attachedPaymentSlipsInfos =
                    attachedPaymentSlipsRef.current.reduce(
                      (accumulated, attachedPaymentSlip, index) => {
                        accumulated.sum += parseFloat(
                          attachedPaymentSlip.amount
                        );

                        if (
                          index ===
                            attachedPaymentSlipsRef.current.length - 1 &&
                          accumulated.sum < parseFloat(invoice.amount)
                        ) {
                          accumulated.quota =
                            parseFloat(invoice.amount) - accumulated.sum;
                          accumulated.sum = parseFloat(invoice.amount);
                        }

                        return accumulated;
                      },
                      { sum: 0, quota: 0 } as {
                        sum: number;
                        quota: number;
                      }
                    );

                  handleInitializeSetPaidInstallmentRefs(inputValue);
                  submitObjectRef.current.paid_installments = new Array(
                    parseInt(inputValue)
                  )
                    .fill({})
                    .map(
                      (_, index) =>
                        ({
                          installment: index + 1,
                          discount_amount: "",
                          fine_amount: "",
                          interest_amount: "",
                          expected_deposit_account_id: null,
                          nominal_amount: attachedPaymentSlipsRef.current[index]
                            ? attachedPaymentSlipsRef.current[index].amount
                            : calculateInstallments(
                                attachedPaymentSlipsInfos.quota,
                                (submitObjectRef.current
                                  .installments as number) -
                                  attachedPaymentSlipsRef.current.length
                              )[
                                index - attachedPaymentSlipsRef.current.length
                              ].toFixed(2),
                          paid_amount:
                            submitObjectRef.current.paid_installments &&
                            submitObjectRef.current.paid_installments[index]
                              ?.paid_amount
                              ? calculateInstallments(
                                  parseFloat(invoice.amount),
                                  parseInt(inputValue)
                                )[index].toFixed(2)
                              : "",
                          payment_date:
                            (submitObjectRef.current.paid_installments &&
                              submitObjectRef.current.paid_installments[index]
                                ?.payment_date) ??
                            undefined,
                          due_date: attachedPaymentSlipsRef.current[index]
                            ? attachedPaymentSlipsRef.current[index].due_date
                            : format(
                                add(
                                  new Date(),
                                  handleInstallmentDueDateInitialState(index)
                                ),
                                "yyyy-MM-dd"
                              ),
                        } as TPaidInstallments)
                    );
                  // installmentPaymentInfosRef.current = new Array(
                  //   !isNaN(parseInt(inputValue)) ? parseInt(inputValue) : 1
                  // )
                  //   .fill({})
                  //   .map(() => ({}));

                  installmentPaymentInfosRef.current = new Array(
                    parseInt(inputValue)
                  )
                    .fill(null)
                    .map((_, index) => {
                      if (
                        attachedPaymentSlipsRef.current[index] &&
                        selectOrganization
                      ) {
                        return {
                          edited: false,
                          payMethod: "",
                          data: {
                            payment_type: "",
                            beneficiary_name: selectOrganization.company_name,
                            beneficiary_document_number:
                              selectOrganization.cnpj,

                            beneficiary_bank_code: undefined, // TedTransfer or PixTransfer only
                            beneficiary_bank_agency: undefined, // TedTransfer or PixTransfer only
                            beneficiary_bank_agency_digit: undefined, // TedTransfer or PixTransfer only
                            beneficiary_bank_account_number: undefined, // TedTransfer or PixTransfer only
                            beneficiary_bank_account_digit: undefined, // TedTransfer or PixTransfer only

                            barcode:
                              attachedPaymentSlipsRef.current[index].bar_code, // BankBillet only

                            pix_type: undefined,
                            pix_key_type: undefined,
                            pix_key: undefined,
                            qr_code: undefined,
                          },
                        };
                      }
                      return {
                        edited: false,
                        payMethod: "",
                        data: {
                          payment_type: "",
                          beneficiary_name: "",
                          beneficiary_document_number: "",

                          beneficiary_bank_code: undefined, // TedTransfer or PixTransfer only
                          beneficiary_bank_agency: undefined, // TedTransfer or PixTransfer only
                          beneficiary_bank_agency_digit: undefined, // TedTransfer or PixTransfer only
                          beneficiary_bank_account_number: undefined, // TedTransfer or PixTransfer only
                          beneficiary_bank_account_digit: undefined, // TedTransfer or PixTransfer only

                          barcode: undefined, // BankBillet only

                          pix_type: undefined,
                          pix_key_type: undefined,
                          pix_key: undefined,
                          qr_code: undefined,
                        },
                      };
                    });

                  // console.log(
                  //   "installment payment infos ref: ",
                  //   installmentPaymentInfosRef.current
                  // );

                  // installmentPaymentInfosRef.current = [
                  //   ...installmentPaymentInfosRef.current,
                  //   ...new Array(
                  //     parseInt(inputValue) -
                  //       installmentPaymentInfosRef.current.length
                  //   ).fill({
                  //     edited: false,
                  //     payMethod: "",
                  //     data: {
                  //       payment_type: "",
                  //       beneficiary_name: "",
                  //       beneficiary_document_number: "",

                  //       beneficiary_bank_code: undefined, // TedTransfer or PixTransfer only
                  //       beneficiary_bank_agency: undefined, // TedTransfer or PixTransfer only
                  //       beneficiary_bank_agency_digit: undefined, // TedTransfer or PixTransfer only
                  //       beneficiary_bank_account_number: undefined, // TedTransfer or PixTransfer only
                  //       beneficiary_bank_account_digit: undefined, // TedTransfer or PixTransfer only

                  //       barcode: undefined, // BankBillet only

                  //       pix_type: undefined,
                  //       pix_key_type: undefined,
                  //       pix_key: undefined,
                  //       qr_code: undefined,
                  //     },
                  //   }),
                  // ];

                  setPaymentFormArray(
                    new Array(parseInt(inputValue)).map((_, index) => {
                      if (paymentFormArray[index]) {
                        return paymentFormArray[index];
                      }
                      return "";
                    })
                  );

                  setInstallmentsPaymentInfoErrors(
                    Array.from({ length: parseInt(inputValue) }, () => ({
                      bankInfo: false,
                      barcode: false,
                      beneficiaryDocument: false,
                      beneficiaryName: false,
                      pixKey: false,
                      qrcode: false,
                    }))
                  );

                  setInstallments(parseInt(inputValue));
                }, 20);
              }
            }}
            filled={installments > 0 ? `${installments}` : undefined}
            onError={errorMessageHandler(
              shouldShowInstallmentsError,
              installmentsErrorMessage
            )}
          />
        )}
      </RowContainer>
      {activeRadio !== "installments" && (
        <RowContainer>
          <Dropdown
            button={{
              variant: "labeled",
              labels: [""],
              placeholder: "Pagar via",
            }}
            menu={{
              options: depositAccounts.map(
                (depositAccount) => depositAccount.name
              ),
              variant: "unlabeled",
              search: false,
              onSelect(selected) {
                const result = depositAccounts.find(
                  (depositAccount) => depositAccount.name === selected
                );
                if (result)
                  submitObjectRef.current.expected_deposit_account_id =
                    result.id;
              },
            }}
          />
        </RowContainer>
      )}
      {activeRadio !== "installments" && (
        <RowContainer
          $isPaid={isPaid}
          className="paymentForm"
          key={`${isPaid}-text`}
        >
          <Dropdown
            button={{
              variant: "labeled",
              labels: [""],
              placeholder: "Forma de pagamento",
              // onError: {
              //   hasError: shouldShowPaymentTypeError,
              //   message: "Campo obrigatório",
              // },
              setData(setSelectedData) {
                if (
                  submitObjectRef.current.payment_information_attributes
                    .payment_type
                ) {
                  setSelectedData(
                    submitObjectRef.current.payment_information_attributes
                      .payment_type
                  );
                }
                // else setSelectedData("Boleto");
              },
            }}
            menu={{
              variant: "unlabeled",
              options: [
                "---",
                "Boleto",
                "TED",
                "PIX - transferência",
                "PIX - pagamento",
              ],
              search: false,
              onSelect(selected) {
                // installmentPaymentInfosRef.current = [
                //   {
                //     payMethod: selected,
                //     paymentComplement:
                //       tempRef.current[0] && tempRef.current[0].paymentComplement
                //         ? tempRef.current[0].paymentComplement
                //         : undefined,
                //     TED:
                //       tempRef.current[0] && tempRef.current[0].TED
                //         ? tempRef.current[0].TED
                //         : {
                //             account: { code: "", vd: "" },
                //             bank: "",
                //             branch: { code: "", vd: "" },
                //           },
                //   },
                // ];

                const selectedValue = selected; /* === "---" ? "" : selected */

                submitObjectRef.current.payment_information_attributes.payment_type =
                  selectedValue as TPaymentFormVariants;

                handlePaymentInformationByPaymentTypeChange(
                  selectedValue as TPaymentFormVariants
                );

                setPaymentForm(selectedValue as TPaymentFormVariants);

                // const result = categories.find(
                //   (category) => category.full_name === selected
                // );
                // if (result) submitObjectRef.current.category_id = result.id;
              },
            }}
          />
          {handlePaymentFormComplement(0, paymentForm)}
        </RowContainer>
      )}

      {activeRadio !== "installments" && (
        <RowContainer className="short">
          <Check
            id="paid"
            initialState={isPaid}
            onClick={() => {
              setIsPaid((before) => {
                if (before) {
                  submitObjectRef.current.payment_date = undefined;
                  submitObjectRef.current.paid_amount = undefined;
                }
                return !before;
              });
            }}
          />
          &nbsp;<label htmlFor="paid">Já paga</label>
        </RowContainer>
      )}
      {activeRadio === "installments" && installments > 0 && (
        <SimpleSlider
          key={installments + "key"}
          style={{ marginTop: 16 }}
          navigateToIndex={(setState) => {
            navigateToSlideByIndexRef.current = setState;
          }}
          children={simpleSliderChildren}
          onBackward={() => {
            dispatch(
              setAllPaidElements({
                allPaidElements:
                  submitObjectRef.current.paid_installments ?? [],
              })
            );
          }}
          onForward={() => {
            dispatch(
              setAllPaidElements({
                allPaidElements:
                  submitObjectRef.current.paid_installments ?? [],
              })
            );
          }}
          setTitle={(currentIndex) => {
            return {
              title: [
                {
                  style: "bold",
                  text: `Parcela ${currentIndex}/${installments}`,
                  shouldBreakLine: false,
                },
              ],
            };
          }}
        />
      )}
      {isPaid && activeRadio !== "installments" && (
        <RowContainer className="paidContainer">
          <LabeledTextInput
            placeholder="Multa"
            customFilter={(inputRef, setInputString) => {
              const value = inputRef.value;
              inputRef.value = value
                .replace(/\D/g, "")
                .slice(0, 10)
                .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
              setInputString(
                value
                  .replace(/\D/g, "")
                  .slice(0, 10)
                  .replace(/^(\d+)(\d{2})$/, "R$ $1,$2")
              );
            }}
            onChange={(inputValue) => {
              submitObjectRef.current.fine_amount = inputValue
                .replace(/\D/g, "")
                .replace(/(\d+)(\d{2})/g, "$1.$2");

              setTimeout(() => {
                handlePaidAmountUpdate();
              }, 500);
            }}
          />
          <LabeledTextInput
            placeholder="Juros"
            customFilter={(inputRef, setInputString) => {
              const value = inputRef.value;
              inputRef.value = value
                .replace(/\D/g, "")
                .slice(0, 10)
                .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
              setInputString(
                value
                  .replace(/\D/g, "")
                  .slice(0, 10)
                  .replace(/^(\d+)(\d{2})$/, "R$ $1,$2")
              );
            }}
            onChange={(inputValue) => {
              submitObjectRef.current.interest_amount = inputValue
                .replace(/\D/g, "")
                .replace(/(\d+)(\d{2})/g, "$1.$2");

              setTimeout(() => {
                handlePaidAmountUpdate();
              }, 500);
            }}
          />
          <LabeledTextInput
            placeholder="Desconto"
            customFilter={(inputRef, setInputString) => {
              const value = inputRef.value;
              inputRef.value = value
                .replace(/\D/g, "")
                .slice(0, 10)
                .replace(/^(\d+)(\d{2})$/, "R$ $1,$2");
              setInputString(
                value
                  .replace(/\D/g, "")
                  .slice(0, 10)
                  .replace(/^(\d+)(\d{2})$/, "R$ $1,$2")
              );
            }}
            onChange={(inputValue) => {
              submitObjectRef.current.discount_amount = inputValue
                .replace(/\D/g, "")
                .replace(/(\d+)(\d{2})/g, "$1.$2");

              setTimeout(() => {
                handlePaidAmountUpdate();
              }, 500);
            }}
          />
          <Calendar
            key={activeRadio}
            className="calendar"
            hasMandatoryStyle={{ marginLeft: "130px" }}
            placeholder="Data do pagamento"
            onSelect={(selectDate) => {
              submitObjectRef.current.payment_date = selectDate.replace(
                /(\d{2})\/(\d{2})\/(\d{4})/g,
                "$3-$2-$1"
              );
              if (submitObjectRef.current.amount === undefined)
                submitObjectRef.current.amount = invoice.amount;
            }}
            onError={errorMessageHandler(
              shouldShowPaymentDateError,
              paymentDateErrorMessageRef.current
            )}
            setDate={(setButtonSelectedDate, setDatePickerSelectedDates) => {
              submitObjectRef.current.payment_date = format(
                new Date(),
                "yyyy-MM-dd"
              );
              // console.log("setting payment date: ", submitObjectRef.current);
              setButtonSelectedDate(format(new Date(), "dd/MM/yyyy"));
              setDatePickerSelectedDates({
                startDate: format(new Date(), "dd/MM/yyyy"),
              });
            }}
          />
          <LabeledTextInput
            placeholder="Total pago"
            setData={(setInputString) => {
              setPaidAmountRef.current = setInputString;
            }}
            disabled
          />
          <div style={{ display: "none" }}>
            <Dropdown
              button={{
                variant: "labeled",
                labels: [""],
                placeholder: "Pagar via",
              }}
              menu={{
                options: depositAccounts.map(
                  (depositAccount) => depositAccount.name
                ),
                variant: "unlabeled",
                search: false,
                onSelect(selected) {
                  const result = depositAccounts.find(
                    (depositAccount) => depositAccount.name === selected
                  );
                  if (result)
                    submitObjectRef.current.expected_deposit_account_id =
                      result.id;
                },
              }}
            />
          </div>
        </RowContainer>
      )}

      <RowContainer
        $isPaid={isPaid && activeRadio !== "installments"}
        className="description"
        key={`${isPaid}-description`}
      >
        <LabeledTextInput
          placeholder="Descrição"
          setData={(setInputString) => {
            if (submitObjectRef.current.description) {
              setInputString(submitObjectRef.current.description);
            }
          }}
          onChange={(inputValue) => {
            submitObjectRef.current.description = inputValue;
          }}
          onError={errorMessageHandler(shouldShowDescriptionError)}
        />
      </RowContainer>
      <RowContainer
        // $isPaid={isPaid && activeRadio !== "installments"}
        className="description"
      >
        <LabeledTextInput
          placeholder="Observação"
          setData={(setInputString) => {
            if (submitObjectRef.current.observation) {
              setInputString(submitObjectRef.current.observation);
            }
          }}
          onChange={(inputValue) => {
            submitObjectRef.current.observation = inputValue;
          }}
          onError={errorMessageHandler(shouldShowDescriptionError)}
        />
      </RowContainer>

      <RowContainer>
        <Check
          id="incomeTaxRelevant"
          onClick={(isClicked) => {
            submitObjectRef.current.income_tax_relevant = isClicked;
          }}
        />
        &nbsp;
        <label htmlFor="incomeTaxRelevant">
          Relevante para Imposto de Renda
        </label>
      </RowContainer>
      <RowContainer>
        <Dropdown
          button={{
            variant: "labeled",
            labels: [""],
            placeholder: "Categoria",
            onError: {
              hasError: shouldShowCategoryIdError,
              message: "Campo obrigatório",
            },
          }}
          menu={{
            variant: "unlabeled",
            options: categories
              .map((category) => category.full_name)
              .sort((a, b) => a.localeCompare(b)),
            search: true,
            onSelect(selected) {
              const result = categories.find(
                (category) => category.full_name === selected
              );
              if (result) submitObjectRef.current.category_id = result.id;
            },
          }}
        />
        <Dropdown
          button={{
            variant: "labeled",
            labels: [""],
            placeholder: "Fornecedor",
            onError: {
              hasError: shouldShowSupplierIdError,
              message: "Campo obrigatório",
            },
            setData(setSelectedData) {
              setTimeout(() => {
                if (supplier) {
                  setSelectedData(supplier.name);
                  submitObjectRef.current.supplier_id = supplier.id;
                }
                // setSelectedData("teste com nome bem grande"), 50;
              });
            },
            disabled: true,
          }}
          menu={{
            variant: "unlabeled",
            // options: suppliers.map((supplier) => supplier.name),
            options: [],
            search: true,
            // onSelect(selected) {
            //   const result = suppliers.find(
            //     (supplier) => supplier.name === selected
            //   );
            //   if (result) submitObjectRef.current.supplier_id = result.id;
            // },
          }}
        />
        <Dropdown
          button={{
            variant: "labeled",
            labels: [""],
            placeholder: "Centro de custo",
            onError: {
              hasError: shouldShowClassificationCenterIdError,
              message: "Campo obrigatório",
            },
          }}
          menu={{
            variant: "unlabeled",
            options: classificationCenters.map(
              (classificationCenter) => classificationCenter.name
            ),
            search: true,
            onSelect(selected) {
              const result = classificationCenters.find(
                (classificationCenter) => classificationCenter.name === selected
              );
              if (result)
                submitObjectRef.current.classification_center_id = result.id;
            },
          }}
        />
      </RowContainer>
      <Footer>
        <div className="buttonsContainer">
          {footer.buttons?.map((buttonProps, index) => {
            const { onClick, ...props } = buttonProps;
            return (
              <Button
                key={index}
                role={index === 1 ? "submit" : undefined}
                {...props}
                onClick={index === 0 ? onCancelHandler : onSubmitHandler}
                isLoading={index === 1 ? isButtonLoading : undefined}
              />
            );
          })}
        </div>
      </Footer>
    </Container>
  );
};

export default AddPayable;
