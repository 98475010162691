import styled from "styled-components";

const Container = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;

  border-radius: 8px;

  background: var(--color-gradient-rose);

  p {
    font-family: var(--font-paragraph-bold);
    color: var(--color-light-0);
    font-size: 16px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

type TProps = {
  string: string;
  className?: string;
};

const StringIcon: React.FC<TProps> = ({ string, className }: TProps) => {
  return (
    <Container className={className ?? ""}>
      <p>{string}</p>
    </Container>
  );
};

export default StringIcon;
