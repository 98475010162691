import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import { useTypedDispatch } from "../../../../hooks/useTypedDispatch";
import {
  TInvoice,
  setToastState,
} from "../../../../redux/reducers/globalState";
import apiService from "../../../../services/api";
import Button, { TButtonIconVariants, TButtonProps } from "../../Button";
import Dropdown from "../../Dropdown";
import {
  UilBrowser /* UilInfoCircle */,
  UilInfoCircle,
} from "@iconscout/react-unicons";
import {
  backendErrorMessageHandler,
  handleGetDataFromLocalStorage,
} from "../../../../utils/miscellaneous";
import { localStorageConstants } from "../../../../utils/constants";
import { TOrganization } from "../../../../redux/reducers/organizations";
import axios, { CancelTokenSource } from "axios";
import Tooltip from "../../Tooltip";
import ToggleSwitch from "../../Inputs/ToggleSwitch";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

const Container = styled.form`
  & > p,
  label,
  b {
    font-size: 16px;
    color: var(--color-text-paragraph);
    font-family: var(--font-paragraph-default);
  }

  b {
    font-family: var(--font-paragraph-bold);
  }

  label {
    font-size: 14px;
  }

  table {
    thead tr:first-child {
      th b {
        font-size: 14px;
      }
    }

    thead tr:nth-child(2) {
      th b {
        font-size: 12px;
      }
    }
  }
`;

const RowContainer = styled.div`
  display: flex;

  margin-top: 16px;

  position: relative;

  h2 {
    font-family: var(--font-paragraph-bold);
    font-size: 21px;
    color: var(--color-text-paragraph);
  }

  p.content {
    top: 13px;
  }

  &:nth-of-type(1),
  &:nth-of-type(3) {
    align-items: center;

    svg {
      color: var(--color-text-labels);
    }
  }

  &:nth-of-type(1) {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  &:nth-of-type(2) {
    & > div {
      width: 370px;
    }
  }

  &:nth-of-type(3) {
    label {
      display: flex;
      align-items: center;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &:nth-of-type(5) {
    & > section {
      width: 100%;
      margin-top: 0px;
    }
  }
`;

const Footer = styled.footer`
  margin-top: 24px;
  border-top: 1px solid var(--color-light-200);
  padding: 16px 0px;
  display: flex;
  justify-content: flex-end;

  .textContainer {
    b,
    p {
      font-size: 14px;
      color: var(--color-text-paragraph);
    }

    b {
      font-family: var(--font-paragraph-bold);
    }

    p {
      font-family: var(--font-paragraph-default);
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;

    & button:not(& button:last-child) {
      margin-right: 12px;
    }
  }
`;

export type TPayableObjectForm = {
  amount: string;
  category_id: number | null;
  classification_center_id: number | null;
  description: string;
  due_date: string;
  mode: null | "in_cash" | "recurrent" | "installments";
  supplier_id: number | null;

  paid_amount?: string;
  fine_amount?: string;
  interest_amount?: string;
  discount_amount?: string;
  payment_date?: string;
  period?:
    | "weekly"
    | "biweekly"
    | "monthly"
    | "bimonthly"
    | "quarterly"
    | "semiannual"
    | "annual";
  installments?: number;
  income_tax_relevant?: boolean;
};

type TGoodsProps = {
  invoice: TInvoice;
  footer: { buttons: TButtonProps<TButtonIconVariants>[] };
};

const Goods: React.FC<TGoodsProps> = ({ invoice, footer }) => {
  const dispatch = useTypedDispatch();

  const selectOrganization = useTypedSelector(
    (state) => state.globalState.data.selectOrganization
  );

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const isAutomatedRef = useRef(false);

  const [stocks, setStocks] = useState<{ id: number; name: string }[]>([]);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [selectStock, setSelectStock] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const submitObjectRef = useRef({
    stock_id: -1,
    invoice_id: "",
    organization_id: "",
  });

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (submitObjectRef.current) {
      if (selectStock !== null) {
        setIsButtonLoading(true);
        const { invoice_id, organization_id, stock_id } =
          submitObjectRef.current;
        apiService
          .setGoods(
            organization_id,
            stock_id,
            invoice_id,
            isAutomatedRef.current,
            (cancelTokenSource.current as CancelTokenSource).token
            // "resolve"
          )
          .then((response: any) => {
            if (response.status === 200) {
              dispatch(
                setToastState({
                  shouldShow: true,
                  message: {
                    description: "Este lançamento já foi efetuado",
                    title: "Lançamento já efetuado",
                  },
                  variant: "information",
                })
              );
            } else {
              if (isAutomatedRef.current) {
                if (footer.buttons[1].onClick) footer.buttons[1].onClick(true);
              } else {
                apiService
                  .getInvoice(
                    submitObjectRef.current.invoice_id,
                    (cancelTokenSource.current as CancelTokenSource).token
                  )
                  .then((response: any) => {
                    if (response) {
                      if (footer.buttons[1].onClick)
                        footer.buttons[1].onClick(false);
                      dispatch(
                        setToastState({
                          variant: "success",
                          shouldShow: true,
                          message: {
                            title: "Entrada de mercadorias criada",
                            description: "Clique aqui para acessar no Omni",
                            link: { url: response.data.goods_receipt_url },
                          },
                        })
                      );
                    }
                  });
              }
            }
          })
          .catch((e) => {
            const errorMessage = backendErrorMessageHandler(e);

            dispatch(
              setToastState({
                variant: "error",
                shouldShow: true,
                message: {
                  title: "Erro ao criar a entrada de mercadorias",
                  description:
                    typeof errorMessage === "string"
                      ? errorMessage
                      : "Não foi possível criar uma entrada de mercadorias a partir desta nota fiscal, tente novamente.",
                },
              })
            );
          })
          .finally(() => {
            setIsButtonLoading(false);
          });
      }
    }
  };

  const preventSubmitDefaultBehavior = (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    // console.log(submitObjectRef.current);
  };

  const fetchData = async () => {
    const localSelectOrganization = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ORGANIZATION
    ) as TOrganization;

    if (localSelectOrganization) {
      submitObjectRef.current.organization_id = localSelectOrganization.uuid;
      cancelTokenSource.current = axios.CancelToken.source();

      apiService
        .getGoodsStock(
          localSelectOrganization.uuid,
          cancelTokenSource.current.token
        )
        .then((response) => {
          if (response?.data) {
            setStocks(response.data);
          }
        });
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  return (
    <Container onSubmit={preventSubmitDefaultBehavior}>
      <p>
        Tem certeza que deseja criar uma Entrada de Mercadoria da Nota Fiscal nº
        {invoice?.number} no <b>Nexaas Omni</b>?
      </p>
      <RowContainer>
        <UilBrowser />
        &nbsp;<b>Selecione o estoque da entrada</b>
      </RowContainer>
      <RowContainer>
        <Dropdown
          key={stocks.length}
          button={{
            hasMandatoryStyle: true,
            variant: "unlabeled",
            labels: [""],
            placeholder: "Selecionar estoque",
            setData(setSelectedData) {
              if (
                invoice &&
                selectOrganization &&
                selectOrganization.default_nexaas_stock_id.length > 0 &&
                stocks.length > 0
              ) {
                const selectedStock =
                  stocks[
                    stocks.findIndex(
                      (element) =>
                        element.id ===
                        parseInt(selectOrganization.default_nexaas_stock_id)
                    )
                  ];

                submitObjectRef.current.invoice_id = invoice.uuid;

                submitObjectRef.current.stock_id = selectedStock.id;

                setSelectedData(selectedStock.name);

                setSelectStock(selectedStock);
              }
            },
          }}
          menu={{
            variant: "unlabeled",
            options: stocks.map((stock) => stock.name),
            search: false,
            onSelect(selected) {
              submitObjectRef.current.invoice_id = invoice.uuid;

              submitObjectRef.current.stock_id = stocks.filter(
                (stock) => stock.name === selected
              )[0].id;

              setSelectStock({
                id: submitObjectRef.current.stock_id,
                name: selected,
              });
            },
          }}
        />
      </RowContainer>
      <RowContainer style={{ alignItems: "center" }}>
        <ToggleSwitch
          id="automation-process-selector"
          onClick={() => {
            isAutomatedRef.current = !isAutomatedRef.current;
          }}
        />
        <label htmlFor="automation-process-selector">
          &nbsp;Finalizar entrada automaticamente &nbsp;
          <Tooltip
            customTopPosition={-70}
            text={[
              {
                text: "A entrada será realizada com a ",
                shouldBreakLine: true,
                style: "default",
              },
              {
                text: " quantidade total de todos os itens",
                shouldBreakLine: true,
                style: "default",
              },
              {
                text: " como descrito na nota",
                shouldBreakLine: false,
                style: "default",
              },
            ]}
          >
            <UilInfoCircle />
          </Tooltip>
        </label>
      </RowContainer>
      <Footer>
        <div className="buttonsContainer">
          {footer.buttons?.map((buttonProps, index) => {
            const { onClick, ...props } = buttonProps;
            return (
              <Button
                key={index}
                role={index === 1 ? "submit" : undefined}
                {...props}
                onClick={index === 0 ? onClick : onSubmitHandler}
                disabled={index === 1 && selectStock === null}
                isLoading={index === 1 && isButtonLoading}
              />
            );
          })}
        </div>
      </Footer>
    </Container>
  );
};

export default Goods;
