import styled from "styled-components";
import { capitalizeFirstLetter } from "../../utils/formatters";
import React from "react";
import { Link } from "react-router-dom";
import { handleParseQuerystrings } from "../../utils/miscellaneous";
import { ROUTES } from "../../router";
// import { ROUTES } from "../../../router";

const Container = styled.div`
  a,
  p {
    font-family: var(--font-paragraph-default);
    font-size: 16px;
  }

  a {
    color: var(--color-text-labels);
    cursor: pointer;
  }

  p {
    display: inline;
    cursor: default;
    color: var(--color-text-paragraph);
  }
`;

type TBreadCrumbsProps = {
  names: string[];
  urls: string[];
};

const BreadCrumbs: React.FC<TBreadCrumbsProps> = ({ names, urls }) => {
  const queryStrings = handleParseQuerystrings(window.location.search);
  let searchParam = "";
  if (queryStrings.organization_id)
    searchParam = "?organization_id=" + queryStrings.organization_id[0];

  return (
    <Container>
      <span>
        {names.map((path, index) => (
          <React.Fragment key={index}>
            {index === names.length - 1 ? (
              <p>{capitalizeFirstLetter(path)}</p>
            ) : (
              <>
                {urls[index] === ROUTES.HOME ? (
                  <a href={urls[index] + searchParam}>
                    {capitalizeFirstLetter(path)}
                    {" > "}
                  </a>
                ) : (
                  <Link to={urls[index] + searchParam}>
                    {capitalizeFirstLetter(path)}
                    {" > "}
                  </Link>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </span>
    </Container>
  );
};

export default BreadCrumbs;
