import styled from "styled-components";
import React, { useEffect /* useRef */, useState } from "react";
// import { useTypedDispatch } from "../../../../hooks/useTypedDispatch";
// import {
//   TInvoice,
//   setToastState,
// } from "../../../../redux/reducers/globalState";
// import apiService from "../../../../services/api";
import Button, { TButtonIconVariants, TButtonProps } from "../../Button";
import { TInvoice, TPaymentSlip } from "../../../../redux/reducers/globalState";
import LabeledTextInput from "../../Inputs/LabeledTextInput";
// import Dropdown from "../../Dropdown";

const Container = styled.form`
  & > p,
  label,
  b {
    font-size: 16px;
    color: var(--color-text-paragraph);
    font-family: var(--font-paragraph-default);
  }

  b {
    font-family: var(--font-paragraph-bold);
  }

  label {
    font-size: 14px;
  }

  & > div > button {
    width: 100%;
  }

  // table {
  //   thead tr:first-child {
  //     th b {
  //       font-size: 14px;
  //     }
  //   }

  //   thead tr:nth-child(2) {
  //     th b {
  //       font-size: 12px;
  //     }
  //   }
  // }
`;

const Footer = styled.footer`
  // margin-top: 74px;
  margin-top: 24px;
  border-top: 1px solid var(--color-light-200);
  padding: 16px 0px;
  display: flex;
  justify-content: flex-end;

  .textContainer {
    b,
    p {
      font-size: 14px;
      color: var(--color-text-paragraph);
    }

    b {
      font-family: var(--font-paragraph-bold);
    }

    p {
      font-family: var(--font-paragraph-default);
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;

    & button:not(& button:last-child) {
      margin-right: 12px;
    }
  }
`;

type TRenameDocumentProps = {
  data: TInvoice | TPaymentSlip;
  footer: { buttons: TButtonProps<TButtonIconVariants>[] };
};

const RenameDocument: React.FC<TRenameDocumentProps> = ({ data, footer }) => {
  const [inputValue, setInputValue] = useState("");

  const preventSubmitDefaultBehavior = (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    // if (
    //   submitObjectRef.current &&
    //   submitObjectRef.current.amount === undefined
    // ) {
    //   submitObjectRef.current.amount = invoice.amount;
    // }
    // console.log(submitObjectRef.current);
  };

  const fetchData = async () => {
    //
  };

  const handleOnClick = (onClick?: (props: any) => void) => {
    if (onClick) onClick(inputValue);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container onSubmit={preventSubmitDefaultBehavior}>
      <LabeledTextInput
        placeholder="Nome do arquivo"
        setData={(setInputString) => {
          setInputString(data.label);
        }}
        onChange={(value) => {
          setTimeout(() => {
            setInputValue(value);
          }, 100);
        }}
      />

      <Footer>
        <div className="buttonsContainer">
          {footer.buttons?.map((buttonProps, index) => {
            const { onClick, ...props } = buttonProps;
            return (
              <Button
                key={index}
                role={index === 1 ? "submit" : undefined}
                {...props}
                onClick={index === 0 ? onClick : () => handleOnClick(onClick)}
                disabled={index === 1 && inputValue.length === 0}
              />
            );
          })}
        </div>
      </Footer>
    </Container>
  );
};

export default RenameDocument;
