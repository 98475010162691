import { useEffect } from "react";

const Error = () => {
  useEffect(() => {
    const value: any = undefined;

    console.log("value: ", value.test);
  }, []);

  return <></>;
};

export default Error;
