export const REFERENCE_PAGE_WIDTH = 1440 / 100;

export const localStorageConstants = {
  AUTHORIZATION: "Authorization",
  LAST_ACCESSED_ORGANIZATIONS: "lastAccessedOrganizations",
  CURRENT_MEMBER: "currentMember",
  SELECT_ACCOUNT: "selectAccount",
  SELECT_ORGANIZATION: "selectOrganization",
  SELECT_INVOICE: "selectInvoice",
  CURRENT_ENTRY_STATUS: "currentEntryStatus",
  CURRENT_PAGE: "currentPage",
  USER_DATA: "userData",
  // ACCOUNTS: "accounts",
  ACCOUNTS_COUNT: "accountsCount",
  // ORGANIZATIONS: "organizations",
  ORGANIZATIONS_COUNT: "organizationsCount",
  MEMBERSHIPS: "memberships",
  ALLOWED_ORGANIZATIONS: "allowedOrganizations",
  CACHE_CLEAR_WHITE_LIST: [
    "Authorization",
    "selectAccount",
    "selectOrganization",
    "userData",
    "lastAccessedOrganizations",
  ],
  INVOICE_FILTER_START_DATE: "startDate",
  INVOICE_FILTER_END_DATE: "endDate",
};
