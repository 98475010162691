import styled from "styled-components";
import BreadCrumbs from "../../../components/ui-v2/BreadCrumbs";
import { ROUTES } from "../../../router";
import Search from "../../../components/ui-v2/Inputs/Search";
import DropdownFilter from "../../../components/ui-v2/DropdownFilter";
import {
  /* Button, */ TWithIconVariants,
} from "../../../components/ui-v2/Button";
import {
  TCellButton,
  /*  Table, */ TCellStyleVariants,
  TTableBody,
} from "../../../components/ui-v2/TableGrid";
import Modal from "../../../components/ui-v2/Modal";
import { useEffect, useRef, useState } from "react";
import {
  TTagColorVariants,
  TTagWithIconVariants,
} from "../../../components/ui-v2/Tag";
import {
  setOrganizationsState,
  setToastState,
} from "../../../redux/reducers/globalState";
import apiService from "../../../services/api";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  TOrganization,
  TOrganizationData,
} from "../../../redux/reducers/organizations";
// import { parse } from "date-fns/parse";
// import { format } from "date-fns/format";
import { TAccount } from "../../../redux/reducers/account";
import { useDispatch } from "react-redux";
import {
  handleGetDataFromLocalStorage,
  handleParseQuerystrings,
  handlePopulateQuerystring,
} from "../../../utils/miscellaneous";
import { localStorageConstants } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { cnpjMask, handleSortQuerystring } from "../../../utils/formatters";
import TableGrid from "../../../components/ui-v2/TableGrid";
import axios, { CancelTokenSource } from "axios";
import SetDefaultGoodsStockMenu from "../../../components/ui-v2/Menus/SetDefaultGoodsStockMenu";
// import useDetectOutsideClick from "../../../hooks/useDetectOutsideClick";

const Container = styled.main`
  position: relative;

  & > h1 {
    font-family: var(--font-title-bold);
    font-size: 28px;
    color: var(--color-text-title);
    margin-top: 10px;
  }

  & > *:nth-child(1) {
    margin-top: 20px;
  }

  tr {
    td:nth-child(8) {
      width: 10px;
    }
    td:nth-child(9) {
      button {
        border-color: var(--color-aux-danger-700);
        svg {
          color: var(--color-aux-danger-700);
        }
      }
    }
  }
`;

// const SyncButtonArea = styled.div`
//   display: flex;
//   flex-direction: column;

//   position: absolute;
//   top: 0;
//   right: 0;

//   p {
//     margin-top: 6px;
//     font-family: var(--font-paragraph-default);
//     font-size: 14px;
//     color: var(--color-text-labels);
//   }
// `;

const ContentContainer = styled.div`
  margin-top: 20px;

  & > *:first-child {
    width: 353px;
  }

  & > *:not(& > *:first-child) {
    margin-left: 16px;
  }

  display: flex;
  align-items: center;
`;

const Organizations: React.FC = () => {
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const { organizationData, selectOrganization } = useTypedSelector(
    (state) => state.globalState.data
  );

  const queryStrings = handleParseQuerystrings(window.location.search);
  const searchString = new URLSearchParams();

  // const [organizationsData, setOrganizationsData] =
  //   useState<TOrganizationData | null>(null);

  const [, /* selectOrganization */ setSelectOrganization] =
    useState<TOrganization | null>(null);

  const [selectAccount, setSelectAccount] = useState<TAccount | null>(null);

  // const [isAccountSyncing, setIsAccountSyncing] = useState(false);

  const navigate = useNavigate();

  const pageRef = useRef<number | undefined>();

  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [organizationToUpdate, setOrganizationToUpdate] =
    useState<TOrganization | null>(null);

  const [forceSelect, setForceSelect] = useState("");

  const [anchorDefaultGoodsStockMenu, setAnchorDefaultGoodsStockMenu] =
    useState<HTMLElement | null>(null);

  const componentRef = useRef(anchorDefaultGoodsStockMenu);

  // const [organizationsByVisibility, setOrganizationsByVisibility] = useState<
  //   TOrganization[] | null
  // >(null);

  const dispatch = useDispatch();

  // useDetectOutsideClick({
  //   isClicked: anchorDefaultGoodsStockMenu !== null,
  //   componentRef,
  //   onOutside() {
  //     // componentRef.current = null;
  //     // setAnchorDefaultGoodsStockMenu(null);
  //   },
  // });

  const TOAST_MESSAGES = [
    "A visibilidade da Organização foi alterada",
    "Não foi possível alterar a visibilidade da Organização, tente novamente",
  ];

  const visibleCell = {
    value: "Visível",
    style: "green" as TTagColorVariants,
    icon: "eye" as TTagWithIconVariants,
    iconPosition: "left" as "left" | "right",
  };

  const notVisibleCell = {
    value: "Oculta",
    style: "red" as TTagColorVariants,
    icon: "eyeSlash" as TTagWithIconVariants,
    iconPosition: "left" as "left" | "right",
  };

  // const handleSyncMessage = () => {
  //   if (selectAccount) {
  //     const parsedDate = parse(
  //       selectAccount.synced_at,
  //       "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
  //       new Date()
  //     );

  //     const syncDate = format(parsedDate, "dd/MM/yyyy");
  //     const syncTime = format(parsedDate, "HH:mm");

  //     return "Sincronizado em " + syncDate + " às " + syncTime;
  //   }
  // };

  // const handleSyncOrganizations = () => {
  //   apiService.getAccount(selectAccount?.uuid ?? "").then((response) => {
  //     if (response.status) {
  //       const account = response.data as TAccount;
  //       if (account.is_syncing) {
  //         setIsAccountSyncing(true);
  //       } else {
  //         apiService.synchronizeAccount(account.uuid).then((response) => {
  //           if (response.data.is_syncing) {
  //             setIsAccountSyncing(true);
  //             setInterval(() => {
  //               apiService
  //                 .getAccount(selectAccount?.uuid ?? "")
  //                 .then((response) => {
  //                   if (response.data.is_syncing === false) {
  //                     setIsAccountSyncing(false);
  //                   }
  //                 });
  //             }, 5000);
  //           }
  //         });
  //       }
  //     }
  //   });
  // };

  const onClickHandler = (organization: TOrganization) => {
    setOrganizationToUpdate(organization);
    setShouldOpenModal(true);
  };

  const modalOnSuccessHandler = () => {
    if (organizationToUpdate) {
      apiService
        .toggleOrganizationVisibility(
          organizationToUpdate,
          (cancelTokenSource.current as CancelTokenSource).token
        )
        .then((response) => {
          setShouldOpenModal(false);
          if (response && response.status === 200) {
            // dispatch(
            //   fetchOrganizations({ uuid: organizationToUpdate.account.uuid })
            // );
            if (selectAccount) {
              handleFetchDataByQueryParams(selectAccount);
            }
            dispatch(
              setToastState({
                message: { title: "Sucesso", description: TOAST_MESSAGES[0] },
                shouldShow: true,
                variant: "success",
              })
            );
          } else {
            // console.log("response: ", response);
          }
        })
        .catch(() => {
          setShouldOpenModal(false);
          dispatch(
            setToastState({
              message: { title: "Erro", description: TOAST_MESSAGES[1] },
              shouldShow: true,
              variant: "error",
            })
          );
        });
    }
  };

  const handleVisibilityFilter = (selected?: string, account?: TAccount) => {
    const acc = selectAccount ?? account;
    if (acc) {
      const queryStrings = handleParseQuerystrings(window.location.search);

      let visibility = undefined;

      if (selected === "Visível") {
        visibility = true;
      }

      if (selected === "Oculta") {
        visibility = false;
      }
      apiService
        .filterOrganizationByVisibility(
          acc.uuid,
          visibility,
          (cancelTokenSource.current as CancelTokenSource).token,
          queryStrings.search ? queryStrings.search[0] : undefined
        )
        .then((response) => {
          if (response && response.status === 200) {
            dispatch(
              setOrganizationsState({
                organizationData: response.data as TOrganizationData,
              })
            );
          }
        });
    }
  };

  const modalOnCancelHandler = () => {
    setShouldOpenModal(false);
  };

  const modalOnCloseHandler = () => {
    setShouldOpenModal(false);
  };

  const fetchOrganizations = async () => {
    const localSelectAccount = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ACCOUNT
    ) as TAccount;
    if (localSelectAccount) {
      cancelTokenSource.current = axios.CancelToken.source();
      // const organizationsResponse = (
      //   await apiService.getOrganizations(localSelectAccount.uuid)
      // ).data as TOrganizationData;
      // dispatch(
      //   setOrganizationsState({ organizations: organizationsResponse.results })
      // );
      handleFetchDataByQueryParams(localSelectAccount);
    }
  };

  const handleNavigate = (select: string) => {
    if (queryStrings.organization_id) {
      searchString.set("organization_id", queryStrings.organization_id[0]);
    }

    if (queryStrings.search) {
      searchString.set("search", queryStrings.search[0]);
    }

    switch (select) {
      case "Todos":
        searchString.delete("visibility");
        break;
      case "Vis\u00EDvel":
        searchString.set("visibility", "visible");
        break;
      case "Oculta":
        searchString.set("visibility", "hidden");
        break;
    }
    navigate("./" + handleSortQuerystring(searchString));
    handleFetchDataByQueryParams();
  };

  const handleOnClear = () => {
    const queryStrings = handleParseQuerystrings(window.location.search);
    const searchString = handlePopulateQuerystring();

    if (queryStrings.organization_id) {
      searchString.set("organization_id", queryStrings.organization_id[0]);
    }

    if (queryStrings.visibility) {
      searchString.set("visibility", queryStrings.visibility[0]);
    }

    searchString.delete("search");

    navigate("./" + handleSortQuerystring(searchString));
    handleFetchDataByQueryParams();
  };

  const handleOnSearch = (searchPhrase: string) => {
    if (queryStrings.organization_id) {
      searchString.set("organization_id", queryStrings.organization_id[0]);
    }

    if (queryStrings.visibility) {
      searchString.set("visibility", queryStrings.visibility[0]);
    }

    if (searchPhrase.length > 0) searchString.set("search", searchPhrase);
    else searchString.delete("search");

    navigate("./" + handleSortQuerystring(searchString));
    handleFetchDataByQueryParams();
  };

  const handleFetchDataByQueryParams = (account?: TAccount) => {
    const queryStrings = handleParseQuerystrings(window.location.search);
    if (queryStrings.visibility) {
      if (queryStrings.visibility[0] === "visible") {
        handleVisibilityFilter("Visível", selectAccount ?? account);
        setForceSelect("Visível");
      } else if (queryStrings.visibility[0] === "hidden") {
        handleVisibilityFilter("Oculta", selectAccount ?? account);
        setForceSelect("Oculta");
      } else {
        handleVisibilityFilter(undefined, selectAccount ?? account);
        setForceSelect("");
      }
    } else {
      handleVisibilityFilter(undefined, selectAccount ?? account);
      setForceSelect("");
    }
  };

  useEffect(() => {
    fetchOrganizations();

    const localSelectOrganization = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ORGANIZATION
    ) as TOrganization;
    if (localSelectOrganization) setSelectOrganization(localSelectOrganization);

    const localSelectAccount = handleGetDataFromLocalStorage(
      localStorageConstants.SELECT_ACCOUNT
    ) as TAccount;
    if (localSelectAccount) setSelectAccount(localSelectAccount);

    return () => {
      if (cancelTokenSource.current) cancelTokenSource.current.cancel();
    };
  }, []);

  // useEffect(() => {}, [useDetectOutsideClick]);

  return (
    <Container>
      <BreadCrumbs
        names={["Início", "Configurações", "Organizações"]}
        urls={[ROUTES.HOME, ROUTES.SETTINGS, ROUTES.SETTINGS_ORGANIZATIONS]}
      />
      <h1>Gerenciar organizações</h1>
      {/* <SyncButtonArea>
        <Button
          variant="outline"
          icon="sync"
          iconPosition="left"
          text={isAccountSyncing ? "Sincronizando" : "Sincronizar Organizações"}
          onClick={handleSyncOrganizations}
          isLoading={isAccountSyncing}
          disabled={isAccountSyncing}
        />
        <p>
          {selectAccount && !isAccountSyncing && <>{handleSyncMessage()}</>}
        </p>
      </SyncButtonArea> */}
      <ContentContainer>
        <Search
          onClear={handleOnClear}
          $ref={(ref, setSearchString) => {
            const queryStrings = handleParseQuerystrings(
              window.location.search
            );
            if (queryStrings.search && ref.current) {
              ref.current.value = queryStrings.search[0];
              setSearchString(queryStrings.search[0]);
            }
          }}
          variant="default"
          placeholder="Buscar Organizações"
          onSearch={handleOnSearch}
        />
        <DropdownFilter
          menuVariant="unlabeled"
          buttonVariant="eye"
          placeholder="Visibilidade"
          search={false}
          content={["Todos", "Visível", "Oculta"]}
          onSelect={handleNavigate}
          isSingleSelect
          forceSelectOptionsByIndex={[
            ["Todos", "Visível", "Oculta"].indexOf(forceSelect),
          ]}
          filled={
            ["Todos", "Visível", "Oculta"].includes(forceSelect)
              ? [forceSelect]
              : undefined
          }
          key={forceSelect.toString()}
        />
      </ContentContainer>

      <TableGrid
        key={organizationData && organizationData.results.length}
        columns={{
          tableHead: {
            width: [
              // 119px 0px 709px 709px 630px 119px
              `${(119 * 10) / 258}vw 0px ${(709 * 10) / 258}vw ${
                (709 * 10) / 258
              }vw ${(600 * 10) / 258}vw ${(119 * 10) / 258}vw`,
            ],
          },
          tableBody: {
            width: [
              `${(119 * 10) / 258}vw 0px ${(709 * 10) / 258}vw ${
                (709 * 10) / 258
              }vw ${(600 * 10) / 258}vw ${(119 * 10) / 258}vw`,
            ],
          },
        }}
        selectType={{ type: "noSelect" }}
        tableHead={{
          value: [
            { value: "Nome", style: "noIcon" },
            { value: "CNPJ", style: "noIcon" },
            {
              value: "Visibilidade",
              style: "icon",
              icon: "infoCircle",
              iconPosition: "right",
              iconColor: "--color-text-labels",
            },
            { value: "Ações", style: "noIcon" },
          ],
        }}
        row="singleRow"
        emptyState={{
          isEmptyState:
            organizationData !== null && organizationData.results.length === 0,
          title: "Nenhum resultado encontrado",
          description: "Altere os filtros ou sincronize as Organizações",
        }}
        tableBody={(organizationData?.results ?? []).map(
          (organization, index) => ({
            elementId: "",
            file_nfe: null,
            file_xml: null,
            body: [
              { value: organization.company_name, style: "default" },
              { value: cnpjMask(organization.cnpj), style: "default" },

              organization.is_active ? visibleCell : notVisibleCell,
              [
                {
                  style: "button",
                  icon: organization.is_active ? "eyeSlash" : "eye",
                  onClick() {
                    onClickHandler(organization);
                  },
                  tooltip: {
                    text: [
                      {
                        text: "Alterar visibilidade",
                        shouldBreakLine: false,
                        style: "default",
                      },
                    ],
                    customTopPosition: 179 + 57 * index,
                  },
                } as TCellButton<TWithIconVariants>,
                {
                  style: "button",
                  icon: "import",
                  id: "import" + index,
                  onClick(e: React.MouseEvent<HTMLElement>) {
                    if (anchorDefaultGoodsStockMenu) {
                      componentRef.current = null;
                      setAnchorDefaultGoodsStockMenu(null);
                    } else {
                      componentRef.current = e.currentTarget;
                      setAnchorDefaultGoodsStockMenu(e.currentTarget);
                    }
                  },
                  tooltip: {
                    text: [
                      {
                        text: "Alterar estoque padrão",
                        shouldBreakLine: false,
                        style: "default",
                      },
                    ],
                    customTopPosition: 179 + 57 * index,
                  },
                } as TCellButton<TWithIconVariants>,
              ],
            ] as TTableBody<TCellStyleVariants>[],
          })
        )}
        footer={{
          // pagination: {
          //   buttonsAmount: 30,
          //   onClick(activeIndex) {
          //     // console.log("active index: ", activeIndex);
          //   },
          // },
          customPagination: organizationData
            ? {
                isFixedAtTheBottom: true,
                currentPage: `${organizationData?.current_page}`,
                buttonBackward: {
                  disabled: organizationData.current_page === 1,
                  onClick() {
                    if (organizationData.current_page === 1) {
                      pageRef.current = undefined;
                    } else {
                      pageRef.current = organizationData.current_page - 1;
                    }

                    if (selectAccount && organizationData.current_page !== 1) {
                      apiService
                        .getOrganizations(
                          selectAccount.uuid,
                          (cancelTokenSource.current as CancelTokenSource)
                            .token,
                          pageRef.current
                        )
                        .then((response) => {
                          dispatch(
                            setOrganizationsState({
                              organizationData:
                                response.data as TOrganizationData,
                            })
                          );
                        });
                    }
                  },
                },
                buttonForward: {
                  disabled:
                    organizationData.current_page ===
                    organizationData.pages.length,
                  onClick() {
                    if (
                      organizationData.current_page ===
                      organizationData.pages.length
                    ) {
                      pageRef.current = organizationData.current_page;
                    } else {
                      pageRef.current = organizationData.current_page + 1;
                    }

                    if (
                      selectAccount &&
                      organizationData.current_page !==
                        organizationData.pages.length
                    ) {
                      apiService
                        .getOrganizations(
                          selectAccount.uuid,
                          (cancelTokenSource.current as CancelTokenSource)
                            .token,
                          pageRef.current
                        )
                        .then((response) => {
                          dispatch(
                            setOrganizationsState({
                              organizationData:
                                response.data as TOrganizationData,
                            })
                          );
                        });
                    }
                  },
                },
                pages: organizationData.pages.map((page) => {
                  return {
                    value: Object.keys(page)[0],
                    onClick() {
                      let temp = Object.values(page)[0].match(/.*?page=(\d+)/);

                      if (temp !== null && temp[1]) {
                        pageRef.current = parseInt(temp[1]);
                      } else {
                        pageRef.current = undefined;
                      }

                      if (selectAccount) {
                        apiService
                          .getOrganizations(
                            selectAccount.uuid,
                            (cancelTokenSource.current as CancelTokenSource)
                              .token,
                            pageRef.current
                          )
                          .then((response) => {
                            dispatch(
                              setOrganizationsState({
                                organizationData:
                                  response.data as TOrganizationData,
                              })
                            );
                          });
                      }
                    },
                  };
                }),
              }
            : undefined,
        }}
      />
      <Modal
        title="Alterar visibilidade"
        icon={{ type: "exclamationTriangle", color: "orange" }}
        main={{
          style: "textOnly",
          content: [
            {
              text: organizationToUpdate?.is_active
                ? "Tem certeza que deseja ocultar a Organização "
                : "Tem certeza que deseja deixar visível a Organização ",
              style: "default",
              shouldBreakLine: false,
            },
            {
              text: organizationToUpdate?.company_name ?? "",
              style: "bold",
              shouldBreakLine: false,
            },
            { text: "?", style: "default", shouldBreakLine: true },
            { text: "", style: "default", shouldBreakLine: true },
            {
              text: "Os usuários que têm acesso a ela ",
              style: "default",
              shouldBreakLine: false,
            },
            organizationToUpdate?.is_active
              ? {
                  text: "não",
                  style: "bold",
                  shouldBreakLine: false,
                }
              : {
                  text: "",
                  style: "default",
                  shouldBreakLine: false,
                },
            organizationToUpdate?.is_active
              ? {
                  text: " poderão mais acessá-la.",
                  style: "default",
                  shouldBreakLine: true,
                }
              : {
                  text: " poderão visualizá-la novamente.",
                  style: "default",
                  shouldBreakLine: true,
                },
          ],
        }}
        footer={{
          style: "default",
          buttons: [
            {
              variant: "secondary",
              icon: "noIcon",
              text: "Cancelar",
              onClick() {
                modalOnCancelHandler();
              },
            },
            {
              variant: "orange",
              iconPosition: "left",
              icon: "checkmark",
              text: "Sim, alterar",
              onClick() {
                modalOnSuccessHandler();
              },
            },
          ],
        }}
        shouldOpen={shouldOpenModal}
        onClose={modalOnCloseHandler}
      />
      {anchorDefaultGoodsStockMenu && selectOrganization && (
        <SetDefaultGoodsStockMenu
          onCancel={() => {
            componentRef.current = null;
            setAnchorDefaultGoodsStockMenu(null);
          }}
          selectOrganization={selectOrganization}
          style={{
            position: "absolute",
            top: anchorDefaultGoodsStockMenu.getBoundingClientRect().top - 60,
            left:
              anchorDefaultGoodsStockMenu.getBoundingClientRect().left -
              380 -
              15,
          }}
        />
      )}
    </Container>
  );
};

export default Organizations;
